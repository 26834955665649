import buildTextFieldComponent from './base/buildTextField';

const LABEL = 'Condition';
const FIELD_NAME = 'condition';
const PLACEHOLDER = 'Enter a condition';
const VALIDATION_MESSAGE = 'Please enter a condition';

function validateCondition(value: string | undefined, required: boolean): string[] | null {
	if (required && !value) {
		return [VALIDATION_MESSAGE];
	}

	return null;
}

const Condition = buildTextFieldComponent({
	fieldName: FIELD_NAME,
	label: LABEL,
	placeholder: PLACEHOLDER,
	validate: validateCondition,
	includeLabelForReadonlyField: true
});

export default Condition;
