import { SendInviteButton } from 'components/Buttons';
import { SendVideoVisitLinkForm } from 'components/Forms';
import { CardModal } from 'display';
import Strings from 'lib/strings';
import React, { useState } from 'react';

interface SendVideoVisitLinkModalProps {
	link: string;
}

function SendVideoVisitLinkModal({ link }: SendVideoVisitLinkModalProps): JSX.Element {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const closeModal = () => setIsOpen(false);
	const openModal = () => setIsOpen(true);

	const trigger = <SendInviteButton onClick={openModal} />;

	return (
		<CardModal open={isOpen} trigger={trigger} data-testid="sendVideoVisitLinkModal">
			<CardModal.Title>{Strings.Buttons.SEND_INVITE}</CardModal.Title>
			<CardModal.Description>
				{Strings.Sentences.SEND_AN_EMAIL_WITH_LINK}
			</CardModal.Description>
			<SendVideoVisitLinkForm onDone={closeModal} link={link} />
		</CardModal>
	);
}

export default SendVideoVisitLinkModal;
