import { DictationResults } from 'display';
import { createContext, useContext } from 'react';

interface DictationContextValue {
	isListening: boolean;
	isSocketOpen: boolean | undefined;
	startListening: () => void;
	stopListening: () => void;
	getFinalHTMLText: () => string;
	prepareForTranscribing: (text: string) => string;
	dictationResults?: DictationResults;
	hasDictationBeenUsed: boolean;
}

const EMPTY_DICTATION_CONTEXT = undefined;
const DictationContext = createContext<DictationContextValue | typeof EMPTY_DICTATION_CONTEXT>(
	EMPTY_DICTATION_CONTEXT
);

function useDictationContext(): DictationContextValue {
	const context = useContext(DictationContext);

	if (context === undefined) {
		throw new Error(
			'Dictation context can only be consumed if used within a DictationContextProvider. Make sure an instance of DictationContextProvider has been set upstream.'
		);
	}

	return context;
}

const DictationProvider = DictationContext.Provider;

export { DictationContext, DictationContextValue, DictationProvider, useDictationContext };
