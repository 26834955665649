import * as React from 'react';
import PropTypes from 'prop-types';

import { Form, Input } from 'display';

const noop = () => {};
const FIELD_NAME = 'password';
const LABEL = 'Your password';

function buildPasswordValidator(fieldName) {
	return function (data, required) {
		const value = data[fieldName];

		if (required && !value) {
			return {
				fields: [fieldName],
				messages: [`Please enter a password.`]
			};
		}

		return null;
	};
}

class Password extends React.Component {
	canEdit() {
		const { editableFields, fieldName } = this.props;

		return editableFields.includes(fieldName);
	}

	hasError() {
		const { errors, fieldName } = this.props;
		const { fields } = errors;

		return fields.includes(fieldName);
	}

	getValue() {
		const { data, fieldName } = this.props;

		return data[fieldName];
	}

	render() {
		const { fieldName, label, onChange } = this.props;

		return (
			<Form.Field error={this.hasError()}>
				<label htmlFor={fieldName}>{label}</label>
				<Input
					fluid
					id={fieldName}
					type="password"
					name={fieldName}
					onChange={onChange}
					value={this.getValue() || ''}
					data-testid={fieldName}
				/>
			</Form.Field>
		);
	}
}

Password.FIELD_NAME = FIELD_NAME;
Password.validate = buildPasswordValidator(FIELD_NAME);

Password.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	fieldName: FIELD_NAME,
	label: LABEL,
	onChange: noop
};

Password.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	fieldName: PropTypes.string,
	label: PropTypes.string,
	onChange: PropTypes.func
};

export default Password;
