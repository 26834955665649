import { Button } from '..';
import { Icon, Popup } from 'semantic-ui-react';
import { useDictationContext } from 'contexts/DictationContext';
import React from 'react';
import { richTextEditorButtonStyles } from './RichTextEditorButtonCSS';

interface StartButtonProps {
	hasMicrophonePermissions: boolean;
	onTranscribeStart: () => void;
}

export default function StartButton({
	hasMicrophonePermissions,
	onTranscribeStart
}: StartButtonProps) {
	const { startListening } = useDictationContext();

	function onStartClick() {
		onTranscribeStart();
		startListening();
	}

	return (
		<Popup
			content={
				hasMicrophonePermissions
					? 'Begin speech-to-text transcription'
					: 'To use the dictation feature, please allow your browser to use your microphone.'
			}
			trigger={
				<Button
					className={richTextEditorButtonStyles.button}
					icon
					disabled={!hasMicrophonePermissions}
					onClick={onStartClick}
					data-testid="microphoneButtonStart">
					<Icon className={richTextEditorButtonStyles.buttonIcon} name="microphone" />
				</Button>
			}
			size="small"
			mouseEnterDelay={500}
		/>
	);
}
