import * as React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { scrollToTop } from 'lib/scroll';

class ScrollToTop extends React.Component {
	componentDidUpdate(prevProps) {
		if (this.props.location.pathname !== prevProps.location.pathname) {
			scrollToTop();
		}
	}

	render() {
		return this.props.children;
	}
}

ScrollToTop.propTypes = {
	children: PropTypes.node.isRequired,
	location: PropTypes.shape({
		pathname: PropTypes.string.isRequired
	}).isRequired
};

export default withRouter(ScrollToTop);
