import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetEmailsRequest } from 'thriftgen/api_types';
import { GetEmailsRequestCamel, GetEmailsResponseCamel } from 'thriftgen/thriftCamelTypes';

async function getEmails(params: GetEmailsRequestCamel): Promise<GetEmailsResponseCamel> {
	const client = getCommonClient();
	const request = new GetEmailsRequest(objectCamelToSnake(params));
	const response = await client.get_emails(request);
	return objectSnakeToCamel(response) as GetEmailsResponseCamel;
}

export default getEmails;
