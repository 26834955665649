import * as React from 'react';

import { FilterField, FilterType } from './types';

import BooleanFilter from './controls/BooleanFilter';
import SearchFilter from './controls/SearchFilter';
import SelectFilter from './controls/SelectFilter';
import { Form } from 'display';

type FieldProps = Pick<FilterField, 'label' | 'attribute' | 'loading'> & {
	children: React.ReactNode;
};

function Field({ children, label, attribute, loading }: FieldProps): JSX.Element {
	return (
		<Form.Field name={attribute} disabled={loading}>
			<label>{label}</label>
			{children}
		</Form.Field>
	);
}

function renderFilter(filter: FilterField): JSX.Element {
	switch (filter.type) {
		case FilterType.BOOLEAN:
			return <BooleanFilter {...filter} />;
		case FilterType.SELECT:
			return <SelectFilter {...filter} />;
		case FilterType.SEARCH:
			return <SearchFilter {...filter} />;
	}
}

function Filter(filter: FilterField): JSX.Element {
	const { attribute, label, loading } = filter;

	return (
		<Field attribute={attribute} label={label} loading={loading}>
			{renderFilter(filter)}
		</Field>
	);
}

export default Filter;
