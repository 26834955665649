import ProcessingIterationLogEntry from './ProcessingIterationLogEntry';

/**
 * Represents the state of a finalized transcript from AWS Transcribe
 */
export default class FinalizedTextLogEntry {
	processingIterationLogEntries: Array<ProcessingIterationLogEntry>;
	textFromAmazon: string;
	processedTranscript: string;

	constructor() {
		this.textFromAmazon = '';
		this.processedTranscript = '';
		this.processingIterationLogEntries = [];
	}

	addProcessingIterationLogEntry(entry: ProcessingIterationLogEntry): void {
		this.processingIterationLogEntries.push(entry);
	}
}
