import { Form, Icon, IconProps, SemanticWIDTHS } from 'display';
import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import { buildLabel } from '../helpers';

interface BuildTextFieldProps {
	fieldName: string;
	icon?: IconProps['name'];
	label: string;
	width?: SemanticWIDTHS;
	placeholder?: string;
	optionalLabelName?: boolean;
	isDisabled?: (data: DataFormFieldComponentProps['data']) => boolean;
	validate?: (value: string, required: boolean) => null | string[];
	includeLabelForReadonlyField?: boolean;
}

function defaultValidate(): null {
	return null;
}

function buildTextFieldComponent<FieldName extends string>({
	fieldName,
	icon,
	label,
	width,
	placeholder,
	optionalLabelName = false,
	isDisabled = () => false,
	validate = defaultValidate,
	includeLabelForReadonlyField
}: BuildTextFieldProps): DataFormFieldComponent<FieldName> {
	function validateData(
		data: { [key: string]: string },
		required: boolean
	): ReturnType<DataFormFieldComponent['validate']> {
		const messages = validate(data[fieldName], required);
		return messages ? { fields: [fieldName], messages } : null;
	}

	function TextField({
		data,
		editableFields,
		errors,
		onChange,
		onBlur,
		labelOverride
	}: DataFormFieldComponentProps): JSX.Element | null {
		const value = data[fieldName];

		if (editableFields.includes(fieldName)) {
			return (
				<Form.Input
					error={errors.fields.includes(fieldName)}
					id={fieldName}
					label={buildLabel(labelOverride || label, optionalLabelName)}
					width={width}
					name={fieldName}
					onChange={onChange}
					placeholder={placeholder}
					disabled={isDisabled(data)}
					value={value || ''}
					onBlur={onBlur}
				/>
			);
		}

		if (value) {
			return (
				<Form.Field>
					{icon && <Icon name={icon} />}
					{includeLabelForReadonlyField && (
						<label>{buildLabel(labelOverride || label, optionalLabelName)}</label>
					)}
					<span>{value}</span>
				</Form.Field>
			);
		}

		return null;
	}

	TextField.FIELD_NAME = fieldName as FieldName;
	TextField.validate = validateData;
	TextField.displayName = `TextField-${fieldName}`;

	return TextField;
}

export default buildTextFieldComponent;
