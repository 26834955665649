import * as React from 'react';
import { MediaDeviceAccessError } from 'lib/mediaDevices';
import { Message, Paragraph } from 'display';

import BrowserPermissionDenied from './BrowserPermissionDenied';
import DeviceNotFound from './DeviceNotFound';
import FallbackError from './FallbackError';
import ScreenSelectionCanceled from './ScreenSelectionCanceled';
import ScreenSystemNotAllowed from './ScreenSystemNotAllowed';
import SourceNotReadable from './SourceNotReadable';
import WebcamSystemNotAllowed from './WebcamSystemNotAllowed';

interface MediaDeviceErrorProps {
	error: MediaDeviceAccessError | null;
}

function renderMediaDeviceErrorMessage(error: MediaDeviceAccessError | null): JSX.Element {
	if (!error) {
		return <FallbackError />;
	}

	switch (error) {
		case MediaDeviceAccessError.FALLBACK:
			return <FallbackError />;

		case MediaDeviceAccessError.BROWSER_PERMISSION_DENIED:
			return <BrowserPermissionDenied />;

		case MediaDeviceAccessError.SCREEN_SELECTION_CANCELED:
			return <ScreenSelectionCanceled />;

		case MediaDeviceAccessError.WEBCAM_SYSTEM_NOT_ALLOWED:
			return <WebcamSystemNotAllowed />;

		case MediaDeviceAccessError.SCREEN_SYSTEM_NOT_ALLOWED:
			return <ScreenSystemNotAllowed />;

		case MediaDeviceAccessError.SOURCE_NOT_READABLE:
			return <SourceNotReadable />;

		case MediaDeviceAccessError.DEVICE_NOT_FOUND:
			return <DeviceNotFound />;
	}
}

function MediaDeviceError({ error }: MediaDeviceErrorProps): JSX.Element {
	const message = renderMediaDeviceErrorMessage(error);

	return (
		<Message negative={true}>
			<Paragraph weight={Paragraph.Weight.BOLD} size={Paragraph.Size.T2}>
				Failed to initialize recorder
			</Paragraph>
			<Paragraph size={Paragraph.Size.T4}>{message}</Paragraph>
		</Message>
	);
}

export default MediaDeviceError;
