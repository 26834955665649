import * as React from 'react';

import { Message, Paragraph } from 'display';

function VideoProcessingMessage() {
	return (
		<Message info>
			<Message.Header>This video is being processed</Message.Header>
			<Paragraph>Please wait a few minutes and refresh the page for updates.</Paragraph>
		</Message>
	);
}

export default VideoProcessingMessage;
