import { DataTableColumn } from 'components/DataTable/DataTable';
import { DataTableCheckbox } from 'components/DataTable/DataTableEditableFields/DataTableCheckbox';
import { UserMutators } from 'hooks/queries/user/types';
import Strings from 'lib/strings';
import * as React from 'react';
import { Partner, User } from 'thriftgen/api_types';

interface BuildToggleAsAdminProps
	extends Pick<UserMutators, 'addAdminToPartner' | 'removeAdminFromPartner'> {
	partnerId: Partner['partner_id'];
	canEdit?: boolean;
}

const buildToggleAsAdmin = ({
	partnerId,
	addAdminToPartner,
	removeAdminFromPartner,
	canEdit
}: BuildToggleAsAdminProps): DataTableColumn<User> => ({
	header: Strings.Labels.ADMIN,
	render: ({ admin_for_partner_ids: adminForPartnerIds, user_id: userId }: User): JSX.Element => {
		const isAdmin = adminForPartnerIds.includes(partnerId);

		function handleClick() {
			const toggleFunction = isAdmin ? removeAdminFromPartner : addAdminToPartner;
			toggleFunction(partnerId, userId);
		}

		const dataTestId = `toggleAsAdmin-${userId}`;

		function renderCheckbox(): JSX.Element {
			return (
				<DataTableCheckbox
					onClick={handleClick}
					checked={isAdmin}
					dataTestId={dataTestId}
				/>
			);
		}

		function renderNonEditableLabel(): JSX.Element {
			return isAdmin ? <div>Yes</div> : <React.Fragment />;
		}

		return canEdit ? renderCheckbox() : renderNonEditableLabel();
	}
});

export { buildToggleAsAdmin };
