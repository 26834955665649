import { S3Client } from '@aws-sdk/client-s3';
import { Upload } from '@aws-sdk/lib-storage';
import { getUploadParams } from 'api/common';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import AWS from 'aws-sdk';
import { Feature, isFeatureEnabled } from 'lib/feature';

/*
 * We set some sensible defaults for uploads. QUEUE_SIZE 1 limits
 * the upload part concurrency to 1 part at a time. TIMEOUT 0 disables timeouts.
 * Both of this settings help significantly in low speed network conditions, and
 * the difference in more optimal conditions is hard to notice. If we notice that
 * upload speeds in more optimal conditions could be improved then we can define these
 * settings dynamically according the user's current network conditions.
 */
const TIMEOUT = 0;
const QUEUE_SIZE = 1;

interface UploadParams {
	body: string;
	mimeType: string;
	progressCallback: (loaded: number | undefined, total: number | undefined) => void;
}

if (location.hostname === 'localhost') {
	AWS.config.update({ s3ForcePathStyle: true });
}

export function upload({ body, mimeType, progressCallback }: UploadParams) {
	if (isFeatureEnabled(Feature.CWA_1384_AWS_SDK_UPGRADE)) {
		SitkaLogger.logMessage('using new aws', LogEventName.MISC);

		return new Promise((resolve, reject) => {
			getUploadParams()
				.then(async response => {
					const { credentials, bucket, region, endpoint, key } = response;

					const forcePathStyle = location.hostname === 'localhost';

					const s3Params = {
						httpOptions: {
							timeout: TIMEOUT
						},
						credentials,
						region,
						endpoint,
						forcePathStyle
					};

					const s3Client = new S3Client(s3Params);
					const uploader = new Upload({
						client: s3Client,

						params: {
							Key: key,
							Body: body,
							ContentType: mimeType,
							Bucket: bucket
						},

						queueSize: QUEUE_SIZE
					});

					if (progressCallback) {
						uploader.on('httpUploadProgress', event =>
							progressCallback(event.loaded, event.total)
						);
					}

					// !!!!!!IMPORTANT!!!!!!
					// We DO NOT want to wrap the following await call in a try/catch block - doing so will break video uploads
					// for users on spotty connections. The catch wrapping the parent promise will catch any errors
					// and trigger existing internet connection error handling that prevents problems from cascading.
					resolve(await uploader.done());
				})
				.catch(error => {
					SitkaLogger.logMessage(error);
					return reject(error);
				});
		});
	} else {
		SitkaLogger.logMessage('using old aws', LogEventName.MISC);

		return new Promise((resolve, reject) => {
			getUploadParams()
				.then(response => {
					const { credentials, bucket, region, endpoint, key } = response;
					const { accessKeyId, secretAccessKey, sessionToken } = credentials;

					const s3Params = {
						httpOptions: {
							timeout: TIMEOUT
						},
						secretAccessKey,
						accessKeyId,
						sessionToken,
						endpoint: endpoint ? new AWS.Endpoint(endpoint) : undefined
					};

					AWS.config.update({
						region
					});

					const s3 = new AWS.S3(s3Params);

					const uploader = s3.upload(
						{
							Key: key,
							Body: body,
							ContentType: mimeType,
							Bucket: bucket
						},
						{
							queueSize: QUEUE_SIZE
						},
						(err, data) => {
							if (err) {
								SitkaLogger.logMessage(err);
								return reject(err);
							}
							return resolve(data);
						}
					);
					if (progressCallback) {
						uploader.on('httpUploadProgress', event =>
							progressCallback(event.loaded, event.total)
						);
					}
				})
				.catch(error => {
					SitkaLogger.logMessage(error);
					return reject(error);
				});
		});
	}
}
