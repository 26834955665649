import { createContext, useContext } from 'react';
import { AlertProps } from '@mui/material/Alert';

interface OpenSnackbarProps {
	message: string;
	severity?: AlertProps['severity'];
}
interface SnackbarContextValue {
	openSnackbar: (openSnackbarProps: OpenSnackbarProps) => void;
}

const EMPTY_SNACKBAR_CONTEXT = undefined;
const SnackbarContext = createContext<SnackbarContextValue | typeof EMPTY_SNACKBAR_CONTEXT>(
	EMPTY_SNACKBAR_CONTEXT
);

export function useSnackbarContext(): SnackbarContextValue {
	const context = useContext(SnackbarContext);

	if (context === undefined) {
		throw new Error(
			'Snackbar context can only be consumed if used within a SnackbarContextProvider. Make sure an instance of SnackbarContextProvider has been set upstream.'
		);
	}

	return context;
}

export const SnackbarProvider = SnackbarContext.Provider;

export { SnackbarContext, SnackbarContextValue, OpenSnackbarProps };
