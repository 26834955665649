import * as React from 'react';

import {
	DataFormFieldComponent,
	DataFormFieldComponentProps
} from 'components/Forms/DataFormTypes';
import { Form, ImageUpload, ImageUploadPreview } from 'display';
interface BuildImageUploadProps {
	currentUrl?: string;
	emptyMessage: string;
	fieldName: string;
	label: string;
}

function buildImageUpload({
	currentUrl,
	emptyMessage,
	fieldName,
	label
}: BuildImageUploadProps): DataFormFieldComponent {
	function ImageUploadField({
		editableFields,
		onChange
	}: DataFormFieldComponentProps): JSX.Element | null {
		function onUpload(uploadKey: string): void {
			onChange(null as unknown as React.SyntheticEvent<Element, Event>, {
				name: fieldName,
				value: uploadKey
			});
		}

		if (editableFields.includes(fieldName)) {
			return (
				<Form.Field>
					<label>{label}</label>
					<ImageUpload
						id={fieldName}
						currentUrl={currentUrl}
						onImageUpload={onUpload}
						emptyMessage={emptyMessage}
					/>
				</Form.Field>
			);
		}

		return (
			<Form.Field>
				<label>{label}</label>
				<ImageUploadPreview src={currentUrl} emptyMessage={emptyMessage} />
			</Form.Field>
		);
	}

	ImageUploadField.FIELD_NAME = fieldName;
	ImageUploadField.validate = () => null;

	return ImageUploadField;
}

export default buildImageUpload;
