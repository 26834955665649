import * as React from 'react';

import { Grid } from 'display';

import { PrimaryPaneComponent, PrimaryPaneProps } from './types';

const PrimaryPane: PrimaryPaneComponent = ({ children }: PrimaryPaneProps): JSX.Element => {
	return (
		<Grid.Column computer="8" mobile="16">
			{children}
		</Grid.Column>
	);
};

export { PrimaryPane };
