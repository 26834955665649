import * as React from 'react';
import { func, number } from 'prop-types';
import { css } from 'emotion';
import { modularScale } from 'styles';
import { Button, Icon, Modal } from 'display';
import Strings from '../../lib/strings';

const styles = {
	countdown: css`
		font-size: ${modularScale(6)};
	`,
	content: css`
		text-align: center;
	`,
	countdownWrapper: css`
		vertical-align: center;
	`
};

class Countdown extends React.Component {
	constructor(props) {
		super(props);
		this.timer = null;
		this.state = {
			secondsLeft: props.countdownLength
		};
	}

	decrementCount = () => {
		this.timer = null;
		const secondsLeft = this.state.secondsLeft - 1;
		if (secondsLeft > 0) {
			this.setState({ secondsLeft });
			this.timer = setTimeout(this.decrementCount, 1000);
		} else {
			this.props.onComplete();
		}
	};

	componentDidMount() {
		this.timer = setTimeout(this.decrementCount, 1000);
	}

	componentWillUnmount() {
		if (this.timer) {
			clearTimeout(this.timer);
		}
	}

	render() {
		return (
			<Modal open>
				<Modal.Content className={styles.content}>
					<p>Recording will start in</p>
					<div className={styles.countdownWrapper}>
						<p className={styles.countdown}>{this.state.secondsLeft}</p>
					</div>
					<p>seconds</p>
					<Button.Secondary onClick={this.props.onCancel}>
						<Icon name="cancel" />
						<span>{Strings.Buttons.CANCEL}</span>
					</Button.Secondary>
				</Modal.Content>
			</Modal>
		);
	}
}

Countdown.defaultProps = {
	countdownLength: 5
};

Countdown.propTypes = {
	countdownLength: number,
	onComplete: func,
	onCancel: func
};

export default Countdown;
