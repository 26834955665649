import buildRadioFieldSet from 'components/Forms/Fields/base/buildRadioFieldSet';
import Strings from 'lib/strings';
import { DataFormFieldComponent } from '../DataFormTypes';

const FIELD_NAME = 'correctiveActionNeeded';
const LABEL = Strings.Labels.CORRECTIVE_ACTION_NEEDED;
const OPTIONS: Array<[string, string]> = [
	['true', 'Yes'],
	['false', 'No']
];

const CorrectiveActionNeeded: DataFormFieldComponent = buildRadioFieldSet({
	fieldName: FIELD_NAME,
	label: LABEL,
	options: OPTIONS,
	isInline: true
});

export default CorrectiveActionNeeded;
