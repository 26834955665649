import * as React from 'react';

import { Button } from 'display';
import { SEMANTIC_COLOR_STRINGS } from 'styles';

function PlayIconButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Icon
			backgroundColor={SEMANTIC_COLOR_STRINGS.PRIMARY}
			icon="play"
			iconInverted={true}
			onClick={onClick}
			title="Play"
		/>
	);
}

export default PlayIconButton;
