import * as React from 'react';

import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import buildTextFieldComponent from './base/buildTextField';
import EnablePatientCommunications from '../Fields/EnablePatientCommunications';

const FIELD_NAME = 'sharedPatientPageIntro';
const FIELD_LABEL = 'Patient message';
const FIELD_PLACEHOLDER = 'A new message has been shared with you. Please see below.';

function deriveDisabledStatus(data: DataFormFieldComponentProps['data']): boolean {
	return !data[EnablePatientCommunications.FIELD_NAME];
}

const PartnerPatientMessageElement: DataFormFieldComponent = buildTextFieldComponent({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	placeholder: FIELD_PLACEHOLDER,
	isDisabled: deriveDisabledStatus
});
function PartnerPatientMessage(props: DataFormFieldComponentProps): JSX.Element {
	const { data, ...rest } = props;

	function deriveEligibility(formData: DataFormFieldComponentProps['data']): string {
		if (!formData[EnablePatientCommunications.FIELD_NAME]) {
			return '';
		}
		return formData.sharedPatientPageIntro;
	}

	return (
		<PartnerPatientMessageElement
			key={FIELD_NAME}
			{...rest}
			data={{
				...data,
				sharedPatientPageIntro: deriveEligibility(data)
			}}
		/>
	);
}

PartnerPatientMessage.FIELD_NAME = FIELD_NAME;
PartnerPatientMessage.validate = () => null;

export default PartnerPatientMessage;
