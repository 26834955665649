import * as React from 'react';
import { connect } from 'react-redux';

import { selectLoggedIn } from 'store/auth';
import { AppState } from 'store/types';

import { LinkToLoginButton, LinkToLogoutButton } from 'components/Buttons';

interface StoreProps {
	isLoggedIn: boolean;
}

interface ConsumerProps {
	responsive?: boolean;
}

type LoginButtonProps = StoreProps & ConsumerProps;

function LoginButton({ isLoggedIn, responsive }: LoginButtonProps): JSX.Element | null {
	if (isLoggedIn) {
		return <LinkToLogoutButton responsive={responsive} />;
	} else {
		return <LinkToLoginButton responsive={responsive} />;
	}
}

const mapStateToProps = (store: AppState): StoreProps => {
	const isLoggedIn = selectLoggedIn(store);

	return {
		isLoggedIn
	};
};

export default connect(mapStateToProps)(LoginButton);
