import * as React from 'react';

import { Button, ButtonType, ButtonProps, Icon } from 'display';
import Strings from 'lib/strings';

type NextButtonProps = Pick<ButtonProps, 'disabled' | 'onClick' | 'onClickAsync'> & {
	buttonType?: ButtonType;
	withIcon?: boolean;
};

function NextButton({
	buttonType = ButtonType.PRIMARY,
	disabled = false,
	onClick,
	onClickAsync,
	withIcon = false
}: NextButtonProps): JSX.Element {
	const ButtonVariation = Button[buttonType];
	return (
		<ButtonVariation
			className="nextButton"
			disabled={disabled}
			onClick={onClick}
			onClickAsync={onClickAsync}
			size={Button.Size.TINY}>
			{Strings.Buttons.NEXT}
			{withIcon && <Icon name="chevron right" />}
		</ButtonVariation>
	);
}

export default NextButton;
