import axios from 'axios';
import browserScheduling from 'lib/browserScheduling';

import store from 'store/index';
import { setAppStale } from 'store/meta';
import { SitkaLogger } from 'lib/sitkaLogger';

const POLL_FREQUENCY_MS = 3600000;
const CURRENT_BUILD_VERSION = window.Sitka.buildVersion;
let intervalId: number;

async function checkBuildVersion(): Promise<void> {
	try {
		const response = await axios.get('/build-version.json', {
			headers: {
				'Content-Type': 'application/json'
			}
		});

		if (response && response.data && response.data.buildVersion !== CURRENT_BUILD_VERSION) {
			window.clearInterval(intervalId);
			store.dispatch(setAppStale(true));
		}
	} catch (error) {
		SitkaLogger.logMessage({
			error: `Failed to retrieve latest build version: ${error}`
		});
	}
}

function initiateVersionPolling(): void {
	intervalId = window.setInterval(
		() => browserScheduling.requestIdleCallback(checkBuildVersion),
		POLL_FREQUENCY_MS
	);
}

// Only initiate in production where we actually have a build version
if (window.Sitka && CURRENT_BUILD_VERSION !== '') {
	initiateVersionPolling();
}
