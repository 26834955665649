import {
	DataFormFieldComponent,
	DataFormFieldComponentProps
} from 'components/Forms/DataFormTypes';
import { Form } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import buildTextFieldComponent from '../base/buildTextField';
import ResendVerificationCode from './ResendVerificationCode';
import Strings from 'lib/strings';

const styles = {
	formGroup: css`
			display: flex;
			align-items: flex-end;
		}
	`
};

const FIELD_NAME = 'verificationCode';

function validate(value: string | undefined, required: boolean): string[] | null {
	if (required && !value) {
		return [Strings.Errors.VERIFICATION_CODE];
	}

	return null;
}

const VerificationCodeField: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	label: Strings.Labels.VERIFICATION_CODE,
	validate: validate,
	placeholder: Strings.Labels.VERIFICATION_CODE_PLACEHOLDER,
	width: 8
});

function VerificationCode(props: DataFormFieldComponentProps): JSX.Element {
	return (
		<Form.Group className={styles.formGroup}>
			<VerificationCodeField {...props} />
			<ResendVerificationCode />
		</Form.Group>
	);
}

VerificationCode.FIELD_NAME = FIELD_NAME;
VerificationCode.validate = VerificationCodeField.validate;
VerificationCode.displayName = `TextField-${FIELD_NAME}`;

export default VerificationCode;
