import * as React from 'react';

import { Specialty as AllSpecialties, SPECIALTY as SPECIALTY_LABELS } from 'thriftgen/api_types';
import getSupportedSpecialties from 'lib/getSupportedSpecialties';
import { translateThriftEnum } from 'lib/i18n';
import { compareAscending } from 'lib/sort';

function defaultRender(field) {
	return field;
}

function alwaysValid() {
	return null;
}

function buildBlurHandler({ target, fields, requiredFields, errors }) {
	const found = fields.filter(field => field.FIELD_NAME === target.name)[0];

	if (found) {
		return handleFoundErrors(found, target, requiredFields, errors);
	}

	return undefined;
}

function handleFoundErrors(found, target, requiredFields, errors) {
	const foundErrors = found.validate(
		{ [target.name]: target.value },
		requiredFields.includes(target.name)
	);

	if (errors && foundErrors) {
		return handleNewErrors(foundErrors, errors);
	} else if (errors) {
		return handleFilteredErrors(target, errors);
	}
}

function handleNewErrors(foundErrors, errors) {
	const fieldNameErrors = [...errors.fields];
	const messageErrors = [...errors.messages];
	if (!messageErrors.includes(foundErrors.messages[0])) {
		messageErrors.push(foundErrors.messages[0]);
	}
	if (!fieldNameErrors.includes(foundErrors.fields[0])) {
		fieldNameErrors.push(foundErrors.fields[0]);
	}

	return { fields: [...fieldNameErrors], messages: [...messageErrors] };
}

function handleFilteredErrors(target, errors) {
	const filteredFields = errors.fields.filter(e => e !== target.name);

	return { ...errors, fields: filteredFields };
}

function buildFieldVariation({ optionalLabelName, Field, label, render = defaultRender }) {
	function FieldComponent(props) {
		return render(<Field {...props} label={buildLabel(label, optionalLabelName)} />);
	}

	FieldComponent.FIELD_NAME = Field.FIELD_NAME;
	FieldComponent.SUB_FIELD_NAMES = Field.SUB_FIELD_NAMES;
	FieldComponent.validate = Field.validate;

	return FieldComponent;
}

function buildLabel(builtLabel, optionalLabelName) {
	if (optionalLabelName) {
		return builtLabel + ' (optional)';
	} else {
		return builtLabel;
	}
}

function getNoErrors() {
	return {
		fields: [],
		messages: []
	};
}

function getErrors(fieldModules, requiredFields, data) {
	const errors = getNoErrors();

	fieldModules.forEach(fieldModule => {
		const error = fieldModule.validate(data, requiredFields.includes(fieldModule.FIELD_NAME));

		if (error) {
			errors.fields = [...errors.fields, ...error.fields];
			errors.messages = [...errors.messages, ...error.messages];
		}
	});

	return errors;
}

function getFieldsAndSubfieldNames(fields) {
	return fields
		.map(field => {
			if (field.SUB_FIELD_NAMES) {
				return [...field.SUB_FIELD_NAMES, field.FIELD_NAME];
			}

			return field.FIELD_NAME;
		})
		.flat();
}

function getSpecialtyOptions() {
	return getSupportedSpecialties()
		.map(s => {
			const specialtyValue = AllSpecialties[s];

			return {
				key: specialtyValue,
				value: specialtyValue,
				text: translateThriftEnum(specialtyValue, SPECIALTY_LABELS)
			};
		})
		.sort((a, b) => {
			if (a.key === AllSpecialties.UNLISTED) {
				return 1;
			}
			if (b.key === AllSpecialties.UNLISTED) {
				return -1;
			}
			return compareAscending(a.text, b.text);
		});
}

const NO_ERRORS = {
	fields: [],
	messages: []
};

export {
	alwaysValid,
	buildBlurHandler,
	buildFieldVariation,
	buildLabel,
	getErrors,
	getFieldsAndSubfieldNames,
	getNoErrors,
	getSpecialtyOptions,
	NO_ERRORS
};
