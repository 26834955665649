export enum zIndex {
	L1 = 9999,
	L2 = 900,
	L3 = 800,
	L4 = 700,
	L5 = 600,
	L6 = 500,
	L7 = 400,
	L8 = 300,
	L9 = 200,
	L10 = 100
}

export enum zIndexLayer {
	MAX = zIndex.L1,
	GLOBAL = zIndex.L2,
	FIXED = zIndex.L3
}
