import * as React from 'react';

import { css } from 'emotion';
import Password from './Password';
import VerifyPassword from './VerifyPassword';

const styles = {
	control: css`
		align-self: flex-end;
	`
};
const FIELD_NAME = 'changePassword';
const OLD_PASSWORD_FIELD_NAME = 'oldPassword';
const NEW_PASSWORD_FIELD_NAME = 'password';

function buildChangePasswordValidator(PrimaryControl, VerifyControl) {
	return function (data) {
		let fields = [];
		let messages = [];

		const passwordErrors = PrimaryControl.validate(data);
		if (passwordErrors) {
			fields = fields.concat(passwordErrors.fields);
			messages = messages.concat(passwordErrors.messages);
		}

		const verifyPasswordErrors = VerifyControl.validate(data);
		if (verifyPasswordErrors) {
			fields = fields.concat(verifyPasswordErrors.fields);
			messages = messages.concat(verifyPasswordErrors.messages);
		}

		return {
			fields,
			messages
		};
	};
}

function ChangePassword(props) {
	return (
		<React.Fragment>
			<Password
				{...props}
				label="Your current password"
				fieldName={OLD_PASSWORD_FIELD_NAME}
			/>
			<Password {...props} label="Your new password" fieldName={NEW_PASSWORD_FIELD_NAME} />
			<VerifyPassword className={styles.control} {...props} />
		</React.Fragment>
	);
}

ChangePassword.FIELD_NAME = FIELD_NAME;
ChangePassword.SUB_FIELD_NAMES = [
	OLD_PASSWORD_FIELD_NAME,
	NEW_PASSWORD_FIELD_NAME,
	VerifyPassword.FIELD_NAME
];
ChangePassword.validate = buildChangePasswordValidator(Password, VerifyPassword);

export default ChangePassword;
