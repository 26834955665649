import { dispatchEvent, SitkaEvent } from './events';

import AudioMonitor from './AudioMonitor/AudioMonitor';
import { AudioIssueType } from './AudioMonitor/types';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

interface ConsumerProperties {
	streams: MediaStream[];
	mimeType: string;
	timeslice: number;
	onDataAvailable: (data: Blob) => void;
}

class MultiStreamRecorder extends AudioMonitor {
	private chunks: Blob[];
	private mimeType: string;
	private recorder: typeof window.MediaRecorder;
	private timeslice: number;

	constructor({ streams, mimeType, timeslice, onDataAvailable }: ConsumerProperties) {
		super();
		this.chunks = [];
		this.recorder = undefined;
		this.mimeType = mimeType;
		this.timeslice = timeslice;

		if (!window.MediaRecorder.isTypeSupported(mimeType)) {
			throw new Error(`MIME type "${mimeType}" is not supported!`);
		}

		this.recorder = new window.MediaRecorder(this.combineStreams(streams), {
			mimeType
		});

		this.getAudioTracks(this.recorder.stream);

		this.recorder.ondataavailable = ({ data }: { data: Blob }) => {
			this.chunks.push(data);
			this.dispatchWindowRecordingEvent();
			onDataAvailable(data);
		};

		this.pause = this.pause.bind(this);
		this.resume = this.resume.bind(this);
		this.start = this.start.bind(this);
		this.stop = this.stop.bind(this);
		this.dispatchWindowRecordingEvent = this.dispatchWindowRecordingEvent.bind(this);
	}

	public getStream(): MediaStream {
		return this.recorder.stream;
	}

	public pause(): Promise<void> {
		return new Promise<void>(resolve => {
			this.recorder.onpause = () => {
				resolve();
			};
			try {
				this.recorder.pause();
				this.pauseMonitoringAudio();
			} catch (e) {
				SitkaLogger.logMessage(
					`Failed to pause MediaRecorder: ${e.message}`,
					LogEventName.VIDEO
				);
			}
		});
	}

	public resume(): Promise<void> {
		return new Promise<void>(resolve => {
			this.recorder.onresume = () => {
				resolve();
			};
			try {
				this.recorder.resume();
				this.startMonitoringAudio();
			} catch (e) {
				SitkaLogger.logMessage(
					`Failed to resume MediaRecorder: ${e.message}`,
					LogEventName.VIDEO
				);
			}
		});
	}

	public start(): void {
		try {
			this.recorder.start(this.timeslice);
			this.startMonitoringAudio();
		} catch (e) {
			SitkaLogger.logMessage(
				`Failed to start MediaRecorder: ${e.message}`,
				LogEventName.VIDEO
			);
		}
	}

	public stop(): Promise<Blob> {
		return new Promise<Blob>((resolve, reject) => {
			this.recorder.onstop = () => {
				const blob = new Blob(this.chunks, { type: this.mimeType });
				if (this.isVideoAudible()) {
					resolve(blob);
				} else {
					reject(new Error(AudioIssueType.NO_AUDIO_CHANNEL));
				}
			};

			try {
				this.recorder.stop();
				this.stopMonitoringAudio();
			} catch (e) {
				SitkaLogger.logMessage(
					`Failed to stop MediaRecorder: ${e.message}`,
					LogEventName.VIDEO
				);
			}
		});
	}

	private combineStreams(streams: MediaStream[]): MediaStream {
		const combinedStream = new MediaStream();

		streams.forEach(stream => {
			stream.getTracks().forEach(track => {
				combinedStream.addTrack(track);
			});
		});

		return combinedStream;
	}

	private dispatchWindowRecordingEvent(): void {
		dispatchEvent(SitkaEvent.RECORDING);
	}
}

export default MultiStreamRecorder;
