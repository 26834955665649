import * as React from 'react';
import { PartnerCamel, PartnerMutationsCamel } from 'thriftgen/thriftCamelTypes';
import { updatePartner as updatePartnerRequest } from 'api/admin';

type UpdatePartnerMutator = (args: PartnerMutationsCamel) => Promise<void>;

interface PartnerContextMutators {
	updatePartner: UpdatePartnerMutator;
}

interface PartnerContextData {
	partner: PartnerCamel;
}

type PartnerContextValue = PartnerContextMutators & PartnerContextData;

const PartnerContext = React.createContext<PartnerContextValue | undefined>(undefined);

function usePartnerContext(): PartnerContextValue {
	const context = React.useContext(PartnerContext);

	if (context === undefined) {
		throw new Error('No partner has been set');
	}

	return context;
}

function buildPartnerMutators(
	partnerId: PartnerCamel['partnerId'],
	onUpdate: () => Promise<void>
): PartnerContextMutators {
	const updatePartner: UpdatePartnerMutator = args =>
		updatePartnerRequest({
			partnerId,
			mutations: args
		}).then(onUpdate);

	return {
		updatePartner
	};
}

export {
	buildPartnerMutators,
	usePartnerContext,
	PartnerContext,
	PartnerContextData,
	PartnerContextMutators,
	PartnerContextValue
};
