import { FilterField } from 'display';

export default (): FilterField => {
	return {
		label: 'User Status',
		attribute: 'is_active',
		values: [
			{
				label: 'Active',
				value: 'true'
			},
			{
				label: 'Inactive',
				value: 'false'
			}
		]
	};
};
