import {
	SpecialistResponseIntervention,
	SPECIALIST_RESPONSE_INTERVENTION
} from 'thriftgen/api_types.js';
import buildSelectField, { buildSelectFieldOptions } from './base/buildSelectField';

const FIELD_NAME = 'interventions';
const LABEL = 'Interventions (Select all that apply)';
const PLACEHOLDER = 'Select recommended intervention(s) for condition';
const VALIDATION_MESSAGE = 'Please enter an intervention.';

const options = buildSelectFieldOptions(
	SpecialistResponseIntervention,
	SPECIALIST_RESPONSE_INTERVENTION,
	false
);

const Interventions = buildSelectField({
	fieldName: FIELD_NAME,
	label: LABEL,
	placeholder: PLACEHOLDER,
	options,
	validationMessage: VALIDATION_MESSAGE,
	multiple: true
});

export default Interventions;
