import { updatePartner as sendUpdatePartner } from 'api/admin';
import { getUsers } from 'api/common';
import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { GetUsersRequestCamel, GetUsersResponseCamel, UserCamel } from 'thriftgen/thriftCamelTypes';
import useQuery from '../useQuery';
import { UserMutators, UsersQuery, UsersQueryValue } from './types';
import { responseInterface } from 'swr';
import { cloneDeep } from 'lodash';

function useUsersQuery(args: GetUsersRequestCamel): UsersQuery {
	const key = ['users', args];
	const { data, error, mutate } = useQuery<UsersQueryValue>(
		key,
		(_: string, params: GetUsersRequestCamel): Promise<GetUsersResponseCamel> =>
			getUsers(params).then(objectSnakeToCamel)
	);

	async function revalidate(): Promise<void> {
		await mutate();
	}

	const addAdminToPartner: UserMutators['addAdminToPartner'] = async (
		partnerId,
		userId
	): Promise<void> => {
		mutateUserById(data, userId, mutate, (user: UserCamel) => {
			user.adminForPartnerIds.push(partnerId);
		});

		sendUpdatePartner({
			partnerId,
			mutations: {
				adminIds: {
					toAdd: [userId]
				}
			}
		});
	};

	const removeAdminFromPartner: UserMutators['removeAdminFromPartner'] = async (
		partnerId,
		userId
	): Promise<void> => {
		mutateUserById(data, userId, mutate, (user: UserCamel) => {
			user.adminForPartnerIds = user.adminForPartnerIds.filter(
				adminPartnerId => adminPartnerId !== partnerId
			);
		});

		sendUpdatePartner({
			partnerId,
			mutations: {
				adminIds: {
					toRemove: [userId]
				}
			}
		});
	};

	const setPartnerMemberRoles: UserMutators['setPartnerMemberRoles'] = async (
		partnerId,
		userId,
		partnerMemberRoles
	): Promise<void> => {
		mutateUserById(data, userId, mutate, (user: UserCamel) => {
			user.partnerMemberRoles[partnerId] = partnerMemberRoles;
		});

		sendUpdatePartner({
			partnerId,
			mutations: {
				memberRoles: {
					[userId]: objectCamelToSnake(partnerMemberRoles)
				}
			}
		});
	};

	return {
		addAdminToPartner,
		error,
		pageMarkers: data ? data.pageMarkers : undefined,
		removeAdminFromPartner,
		setPartnerMemberRoles,
		revalidate,
		users: data ? data.users : undefined
	};
}

function mutateUserById(
	data: GetUsersResponseCamel | undefined,
	userId: string,
	mutate: responseInterface<UsersQueryValue, Error>['mutate'],
	updateFn: (user: UserCamel) => void
) {
	if (!data) {
		return;
	}
	const newData: GetUsersResponseCamel = cloneDeep(data) as GetUsersResponseCamel;
	const updatedUser = newData?.users.find(user => user.userId === userId);
	if (updatedUser) {
		updateFn(updatedUser);
	}
	mutate(newData, false);
}

export default useUsersQuery;
