import { RegexError, RegexSearchInfo } from './types';

export default class ProcessingStepLogEntry {
	errors: RegexError[];
	functionName: string;
	transcriptBefore: string;
	transcriptAfter: string;
	regexSearchInfo: RegexSearchInfo[] | undefined;

	constructor(
		functionName: string,
		transcriptBefore: string,
		transcriptAfter: string,
		regexSearchInfo: RegexSearchInfo[] | undefined,
		errors: Array<{ regex: string | RegExp; error: Error }>
	) {
		this.functionName = functionName;
		this.transcriptBefore = transcriptBefore;
		this.transcriptAfter = transcriptAfter;
		this.regexSearchInfo = regexSearchInfo;
		this.errors = errors;
	}
}
