import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import Link from 'components/Link';
import { Icon } from 'display';
import Strings from 'lib/strings';

function LinkToCaseQueueButton(): JSX.Element {
	return (
		<Link to={RouteUrls.buildUrl(RouteUrls.Key.MY_CASES)}>
			<Icon name="arrow left" />
			{` ${Strings.Buttons.RETURN_TO_MY_PATIENTS}`}
		</Link>
	);
}

export default LinkToCaseQueueButton;
