import { ManageConsultRequestAction } from 'api/common/manageConsult';
import { Button, ButtonType, CardModal } from 'display';
import { useLazyAsyncCall } from 'hooks/useLazyAsyncCall';
import { usePendoGuide } from 'hooks/usePendoGuide';
import { PendoGuide } from 'lib/pendo';
import * as React from 'react';
import { ConsultState } from 'thriftgen/api_types';
import './CloseConsultModalOverrides.css';
import Strings from 'lib/strings';

interface CloseConsultModalProps {
	consultId: string;
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
	manageConsult: (args: ManageConsultRequestAction) => Promise<void>;
}

export default function CloseConsultModal({
	consultId,
	open,
	onConfirm,
	onCancel,
	manageConsult
}: CloseConsultModalProps): JSX.Element {
	const ButtonVariationPrimary = Button[ButtonType.PRIMARY];
	const ButtonVariationTextPrimary = Button[ButtonType.TEXT_PRIMARY];

	const setGuideShown = usePendoGuide({
		guide: PendoGuide.CONSULT_SURVEY
	});

	const {
		// error,
		// loading,
		dispatch: dispatchManageConsult
	} = useLazyAsyncCall<ManageConsultRequestAction>(manageConsult);

	const onConfirmWrapper = () => {
		dispatchManageConsult({
			consultId,
			updates: { state: ConsultState.CLOSED_PCP_INITIATED }
		});
		setGuideShown(true);
		onConfirm();
	};

	return (
		<CardModal open={open} data-testid="CloseConsultModal">
			<CardModal.Title>{Strings.Modals.CLOSE_CONSULT_TITLE}</CardModal.Title>
			<CardModal.Description>
				{Strings.Modals.CLOSE_CONSULT_TEXT_PART_1}
				<br />
				<br />
				{Strings.Modals.CLOSE_CONSULT_TEXT_PART_2}
			</CardModal.Description>
			<Button.Group>
				<ButtonVariationTextPrimary onClick={onCancel} size={Button.Size.SMALL}>
					{Strings.Buttons.CANCEL}
				</ButtonVariationTextPrimary>
				<ButtonVariationPrimary onClick={onConfirmWrapper} size={Button.Size.SMALL}>
					{Strings.Buttons.CLOSE_CONSULT}
				</ButtonVariationPrimary>
			</Button.Group>
		</CardModal>
	);
}
