import * as React from 'react';

import { SpecialtyIllustrationProps } from '../illustrationTypes';

function PainMedicine({
	background,
	fillLight,
	fillDark,
	outline
}: SpecialtyIllustrationProps): JSX.Element {
	return (
		<svg viewBox="0 0 88 88" fill="none" xmlns="https://www.w3.org/2000/svg">
			<circle cx="44" cy="44" r="40" fill={background} />
			<path
				d="M10 7C10 5.89543 10.8954 5 12 5H20C21.1046 5 22 5.89543 22 7C22 8.10457 21.1046 9 20 9H12C10.8954 9 10 8.10457 10 7Z"
				fill={fillLight}
			/>
			<path d="M26 33H63V51H30C27.7909 51 26 49.2091 26 47V33Z" fill={fillDark} />
			<path
				d="M26 15C26 13.8954 26.8954 13 28 13C29.1046 13 30 13.8954 30 15V33H26V15Z"
				fill={fillDark}
			/>
			<circle cx="63" cy="70" r="7" fill={background} />
			<circle cx="63" cy="70" r="3" fill={fillLight} />
			<circle cx="30" cy="58" r="19" fill={background} />
			<circle cx="30" cy="58" r="6" fill={fillLight} />
			<path
				d="M37 26C37 24.8954 37.8954 24 39 24H53C54.1046 24 55 24.8954 55 26C55 27.1046 54.1046 28 53 28H39C37.8954 28 37 27.1046 37 26Z"
				fill={fillLight}
				stroke={outline}
				strokeWidth="2"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M63 66C60.7909 66 59 67.7909 59 70C59 72.2091 60.7909 74 63 74C65.2091 74 67 72.2091 67 70C67 67.7909 65.2091 66 63 66ZM61 70C61 68.8954 61.8954 68 63 68C64.1046 68 65 68.8954 65 70C65 71.1046 64.1046 72 63 72C61.8954 72 61 71.1046 61 70Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M30 51C26.134 51 23 54.134 23 58C23 61.866 26.134 65 30 65C33.866 65 37 61.866 37 58C37 54.134 33.866 51 30 51ZM25 58C25 55.2386 27.2386 53 30 53C32.7614 53 35 55.2386 35 58C35 60.7614 32.7614 63 30 63C27.2386 63 25 60.7614 25 58Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 4C10.3431 4 9 5.34315 9 7C9 8.65685 10.3431 10 12 10H20C21.3062 10 22.4175 9.16519 22.8293 8H25C26.1046 8 27 8.89543 27 10V12.1707C25.8348 12.5825 25 13.6938 25 15V38.6301C16.3739 40.8503 10 48.6808 10 58C10 69.0457 18.9543 78 30 78C41.0457 78 50 69.0457 50 58C50 55.9096 49.6793 53.8941 49.0844 52H62V62.0619C58.0537 62.554 55 65.9204 55 70C55 74.4183 58.5817 78 63 78C67.4183 78 71 74.4183 71 70C71 65.9204 67.9463 62.554 64 62.0619V52H66.007C67.3379 52 68.5096 52.8768 68.8851 54.1535L72.6184 66.8465C72.9939 68.1232 74.1656 69 75.4965 69H82C82.5523 69 83 68.5523 83 68C83 67.4477 82.5523 67 82 67H75.4965C75.0529 67 74.6623 66.7077 74.5371 66.2822L70.8039 53.5892C70.178 51.4613 68.2251 50 66.007 50H64V30C64 27.2386 61.7614 25 59 25H55.8293C55.4175 23.8348 54.3062 23 53 23H39C37.6938 23 36.5825 23.8348 36.1707 25H31V15C31 13.6938 30.1652 12.5825 29 12.1707V10C29 7.79086 27.2091 6 25 6H22.8293C22.4175 4.83481 21.3062 4 20 4H12ZM62 50V34H27V38.2235C27.9786 38.0763 28.9804 38 30 38C38.2013 38 45.2496 42.9364 48.3358 50H62ZM53 25C53.5523 25 54 25.4477 54 26C54 26.5523 53.5523 27 53 27H39C38.4477 27 38 26.5523 38 26C38 25.4477 38.4477 25 39 25H53ZM59 27H55.8293C55.4175 28.1652 54.3062 29 53 29H39C37.6938 29 36.5825 28.1652 36.1707 27H31V32H62V30C62 28.3431 60.6569 27 59 27ZM29 32V15C29 14.4477 28.5523 14 28 14C27.4477 14 27 14.4477 27 15V32H29ZM21 7C21 6.44772 20.5523 6 20 6H12C11.4477 6 11 6.44772 11 7C11 7.55228 11.4477 8 12 8H20C20.5523 8 21 7.55228 21 7ZM57 70C57 73.3137 59.6863 76 63 76C66.3137 76 69 73.3137 69 70C69 66.6863 66.31 64 63 64C59.69 64 57 66.6863 57 70ZM12 58C12 48.0589 20.0589 40 30 40C39.9411 40 48 48.0589 48 58C48 67.9411 39.9411 76 30 76C20.0589 76 12 67.9411 12 58Z"
				fill={outline}
			/>
		</svg>
	);
}

export default PainMedicine;
