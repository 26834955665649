import * as React from 'react';

import { SelectFilterField } from '../types';
import { Form, DropdownProps } from 'display';

function SelectFilter({
	multiple = false,
	placeholder = 'Select option',
	options = [],
	onChange,
	value
}: Omit<SelectFilterField, 'type'>): JSX.Element {
	function onSelectChange(_: React.SyntheticEvent<HTMLElement>, data: DropdownProps): void {
		onChange(data.value);
	}

	return (
		<Form.Select
			placeholder={placeholder}
			options={options}
			multiple={multiple}
			selectOnBlur={false}
			value={value as boolean | number | string | (boolean | number | string)[]}
			onChange={onSelectChange}
		/>
	);
}

export default SelectFilter;
