import { ManageConsultRequestAction } from 'api/common/manageConsult';
import { useConsultsContext } from 'contexts';
import { Button, ButtonType, CardModal, Message } from 'display';
import { useLazyAsyncCall } from 'hooks';
import * as React from 'react';
import { ConsultState } from 'thriftgen/api_types';
import Strings from '../../lib/strings';

interface DeleteDraftConsultModalProps {
	consultId: string;
	open: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

export default function DeleteDraftConsultModal({
	consultId,
	open,
	onConfirm,
	onCancel
}: DeleteDraftConsultModalProps): JSX.Element {
	const ButtonVariationPrimary = Button[ButtonType.PRIMARY];
	const ButtonVariationTextPrimary = Button[ButtonType.TEXT_PRIMARY];

	const { manageConsult } = useConsultsContext();

	const {
		dispatch: dispatchManageConsult,
		loading,
		error
	} = useLazyAsyncCall<ManageConsultRequestAction>(manageConsult);

	const onConfirmWrapper = () => {
		dispatchManageConsult({
			consultId,
			updates: { state: ConsultState.DRAFT_DELETED }
		})
			.promise()
			.then(() => {
				onConfirm();
			});
	};

	return (
		<CardModal open={open} data-testid="DeleteDraftConsultModal">
			<CardModal.Title>Are you sure?</CardModal.Title>
			<CardModal.Description>
				Are you sure you want to delete this draft consult?
				{error && <Message negative>{error.message}</Message>}
			</CardModal.Description>
			<Button.Group>
				<ButtonVariationTextPrimary
					onClick={onCancel}
					size={Button.Size.SMALL}
					disabled={loading || !open}>
					{Strings.Buttons.CANCEL}
				</ButtonVariationTextPrimary>
				<ButtonVariationPrimary
					onClick={onConfirmWrapper}
					size={Button.Size.SMALL}
					disabled={loading || !open}>
					{Strings.Buttons.DELETE_DRAFT}
				</ButtonVariationPrimary>
			</Button.Group>
		</CardModal>
	);
}
