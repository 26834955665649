import { css, keyframes } from 'emotion';

const pulse = keyframes`
		0% {
			box-shadow: 0 0 0 0 rgba(255, 0, 0, 0.7);
		}

		70% {
			box-shadow: 0 0 0 10px rgba(255, 0, 0, 0);
		}

		100% {
			box-shadow: 0 0 0 0 rgba(255, 0, 0, 0);
		}
	`;

export const richTextEditorButtonStyles = {
	button: css`
		margin: 0 !important;
	`,
	buttonIcon: css`
		padding: 0 8px !important;
		color: black;
	`,
	buttonWrapper: css`
		align-items: center;
		margin-bottom: 6px;
		position: relative;
		max-width: 35px;
		flex-wrap: wrap;
		border: 1px solid #f1f1f1 !important;
		padding: 5px 0 !important;
		min-width: 25px !important;
		height: 20px !important;
		border-radius: 2px !important;
		margin: 0 4px !important;
		display: flex !important;
		justify-content: center !important;
		align-items: center !important;
		cursor: pointer !important;
		background: white !important;
		text-transform: capitalize !important;

		&:hover {
			box-shadow: 1px 1px 0px #bfbdbd;
		}
	`,
	pulse: css`
		animation: ${pulse} 2s infinite;
	`,
	red: css`
		color: red;
	`,
	rightJustify: css`
		flex-direction: row-reverse;
	`
};
