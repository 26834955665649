import { translatePatientTreatmentState } from 'components/Forms/Fields/PatientTreatmentState/helpers';
import { translateSpecialty } from 'components/Forms/Fields/Specialty/helpers';
import { Grid, Text } from 'display';
import * as React from 'react';
import { ConsultCamel } from 'thriftgen/thriftCamelTypes';

interface ConsultSelectDetailsProps {
	specialty: ConsultCamel['specialty'];
	patientTreatmentState: ConsultCamel['patientTreatmentState'];
}

function ConsultSelectDetails({
	specialty,
	patientTreatmentState
}: ConsultSelectDetailsProps): JSX.Element | null {
	return (
		<Grid columns="two">
			<Grid.Row>
				<Grid.Column width={6}>
					<Text>
						Consult Type: <strong>{translateSpecialty(specialty)}</strong>
					</Text>
				</Grid.Column>
				<Grid.Column width={6}>
					<Text>
						Location:{' '}
						<strong>{translatePatientTreatmentState(patientTreatmentState)}</strong>
					</Text>
				</Grid.Column>
			</Grid.Row>
		</Grid>
	);
}

export default ConsultSelectDetails;
