import * as React from 'react';

import { Button, ButtonProps, ButtonType } from 'display';
import Strings from 'lib/strings';

interface JoinVideoVisitProps {
	buttonType?: ButtonType;
	disabled?: ButtonProps['disabled'];
	onClick: ButtonProps['onClick'];
}

function JoinVideoVisitButton({
	buttonType = ButtonType.PRIMARY,
	disabled = false,
	onClick
}: JoinVideoVisitProps): JSX.Element {
	const ButtonVariation = Button[buttonType];

	return (
		<ButtonVariation disabled={disabled} onClick={onClick}>
			{Strings.Buttons.JOIN_FAST_TRACK_CONSULT}
		</ButtonVariation>
	);
}

export default JoinVideoVisitButton;
