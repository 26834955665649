import * as React from 'react';
import { CheckboxProps, InputOnChangeData } from 'display';

interface DataFormErrors {
	fields: string[];
	messages: string[];
}

interface DataFormData<FieldType = any> {
	[key: string]: FieldType;
}

interface DataFormChangeData<FormData> {
	name: keyof FormData;
	value: FormData[keyof FormData];
}

type OnChange<FieldType> = (
	event: React.SyntheticEvent | null,
	data: DataFormChangeData<FieldType> | CheckboxProps | InputOnChangeData
) => void;

type OnBlur = (event: React.BaseSyntheticEvent) => void;

enum DataFormConditionalFieldAction {
	SHOW = 'show',
	HIDE = 'hide'
}

interface DataFormConditionalField {
	fieldName: DataFormFieldComponent['FIELD_NAME'];
	conditionalFieldName: DataFormFieldComponent['FIELD_NAME'];
	action: DataFormConditionalFieldAction;
	values: Array<string | number | boolean>;
}

interface DataFormFieldComponentProps<FieldType = any> {
	data: DataFormData<FieldType>;
	editableFields: string[];
	errors: DataFormErrors;
	onChange: OnChange<{ [key: string]: FieldType }>;
	onBlur?: OnBlur;
	labelOverride?: string;
	suffixComponent?: JSX.Element;
}

type DataFormFieldComponent<FieldName = string, FieldType = any> = React.ComponentType<
	DataFormFieldComponentProps<FieldType>
> & {
	validate: (data: DataFormData<FieldType>, required?: boolean) => DataFormErrors | null;
	FIELD_NAME: FieldName;
	SUB_FIELD_NAMES?: string[];
};

interface DataFormDataProps<FormData> {
	onChange: OnChange<FormData>;
	data: FormData;
	onBlur?: OnBlur;
}

export {
	DataFormChangeData,
	DataFormErrors,
	DataFormFieldComponentProps,
	DataFormFieldComponent,
	DataFormDataProps,
	DataFormConditionalField,
	DataFormConditionalFieldAction
};
