import Thrift from 'browser-thrift';

import AdminService from '../thriftgen/AdminService';
import AuthService from '../thriftgen/AuthService';
import CommonService from '../thriftgen/CommonService';
import AthenaService from '../thriftgen/AthenaService';
import { sitkaOktaAuth } from 'lib/okta';

/* client types */
const ADMIN = { service: AdminService, path: '/api/admin' };
const AUTH = { service: AuthService, path: '/api/auth' };
const COMMON = { service: CommonService, path: '/api/common' };
const ATHENA = { service: AthenaService, path: '/api/athena' };

const OKTA_ACCESS_TOKEN = 'OktaAccessToken';

function getPort() {
	if (window.location.port) {
		return window.location.port;
	}

	switch (window.location.protocol) {
		case 'https:':
			return '443';
		default:
			return '80';
	}
}

function getOktaHeaders() {
	const oktaHeaders = {};
	const accessToken = sitkaOktaAuth.getAccessToken();
	if (accessToken) {
		oktaHeaders[OKTA_ACCESS_TOKEN] = accessToken;
	}
	return oktaHeaders;
}

function getClient(
	clientType,
	customHeaders = {},
	hostname = window.location.hostname,
	port = getPort()
) {
	const headers = {
		...customHeaders,
		'Content-Type': 'application/x-thrift'
	};

	const connection = Thrift.createXHRConnection(hostname, port, {
		transport: Thrift.TBufferedTransport,
		protocol: Thrift.TBinaryProtocol,
		path: clientType.path,
		headers: headers,
		https: window.location.protocol === 'https:'
	});
	return Thrift.createXHRClient(clientType.service, connection);
}

export function getAuthClient() {
	return getClient(AUTH);
}

export function getAdminClient() {
	return getClient(ADMIN, getOktaHeaders());
}

export function getCommonClient() {
	return getClient(COMMON, getOktaHeaders());
}

export function getAthenaClient() {
	return getClient(ATHENA, getOktaHeaders());
}
