import { DataTableColumn } from 'components/DataTable/DataTable';
import { buildHalfWidthMobileColumn } from 'components/DataTable/helpers';
import { ConsultComposite } from 'hooks';
import * as React from 'react';
import TimeToRespondDisplay from '../components/TimeToRespondDisplay';

const header = 'Time To Respond';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: (consultComposite: ConsultComposite) => (
		<TimeToRespondDisplay consultComposite={consultComposite} />
	),
	renderMobile: (consultComposite: ConsultComposite) =>
		buildHalfWidthMobileColumn(
			header,
			<TimeToRespondDisplay consultComposite={consultComposite} />
		),
	sortKeys: ['is_closed', 'has_response', 'sla_time_if_unresponded_else_updated_at']
});
