import { useDictationContext } from 'contexts/DictationContext';
import React from 'react';
import { StartButton, StopButton } from '.';

interface MicrophoneButtonProps {
	onTranscribeStart: () => void;
	onTranscribeStop: () => void;
	hasMicrophonePermissions: boolean;
}

export default function MicrophoneButton({
	onTranscribeStart,
	onTranscribeStop,
	hasMicrophonePermissions
}: MicrophoneButtonProps) {
	const { isListening } = useDictationContext();

	return !isListening ? (
		<StartButton
			hasMicrophonePermissions={hasMicrophonePermissions}
			onTranscribeStart={onTranscribeStart}
		/>
	) : (
		<StopButton onTranscribeStop={onTranscribeStop} />
	);
}
