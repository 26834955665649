import * as React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'display';
import { getDateMonths } from 'lib/date';

const MONTHS = getDateMonths();
const FIELD_NAME = 'month';

function hasError({ fields }) {
	return fields.includes(FIELD_NAME);
}

function getValue(data) {
	return data[FIELD_NAME];
}

function BirthMonth({ onChange, data, errors }) {
	return (
		<Form.Select
			id={FIELD_NAME}
			error={hasError(errors)}
			search
			fluid
			label="Month of birth"
			placeholder="5"
			options={MONTHS}
			name={FIELD_NAME}
			value={getValue(data)}
			onChange={onChange}
			selectOnBlur={false}
		/>
	);
}

BirthMonth.FIELD_NAME = FIELD_NAME;

BirthMonth.defaultProps = {
	data: {},
	errors: {
		fields: []
	}
};

BirthMonth.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.shape({
		[FIELD_NAME]: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	})
};

export default BirthMonth;
