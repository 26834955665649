import * as React from 'react';

import { SpecialtyIllustrationProps } from '../illustrationTypes';

function Otolaryngology({
	background,
	fillLight,
	fillDark,
	outline
}: SpecialtyIllustrationProps): JSX.Element {
	return (
		<svg viewBox="0 0 88 88" fill="none" xmlns="https://www.w3.org/2000/svg">
			<circle cx="44" cy="44" r="40" fill={fillLight} />
			<mask
				id="mask0"
				mask-type="alpha"
				maskUnits="userSpaceOnUse"
				x="4"
				y="4"
				width="80"
				height="80">
				<circle cx="44" cy="44" r="40" fill={fillLight} />
			</mask>
			<g mask="url(#mask0)">
				<path
					d="M62.5 24.5C64.6667 23 73 14.5 71.5 3.5L2.5 4.5L2.5 79L44 87C46 77.5 44.9905 77.6576 44 68C42 48.5 58 27.6154 62.5 24.5Z"
					fill={background}
				/>
				<path
					d="M14 60C14 57.7909 15.7909 56 18 56H26C28.2091 56 30 57.7909 30 60V94C30 95.1046 29.1046 96 28 96H16C14.8954 96 14 95.1046 14 94V60Z"
					fill={fillLight}
				/>
				<path
					d="M24.7626 27.9736C29.5799 7.31479 55.5246 9.08285 62.5992 25.7629L63.1847 64.963C61.7525 69.8568 59.6344 74.2277 54.3433 74.2277C47 74.2277 39.9449 65.1375 35.0985 58.9924C27.8704 49.8276 21.6995 41.1094 24.7626 27.9736Z"
					fill={fillLight}
				/>
				<path
					d="M54.4994 36L53.4994 42.5C52.9993 43 52.8993 44.9 56.4993 48.5C60.0993 52.1 58.9993 58.3333 57.9993 61L56.4993 63.5L52.9994 65L48.9993 63.5L43.9993 57C42.5 55.5 40.5603 56.3558 36.9998 51.5C32.9671 46 31.5001 34 35.4994 29.5C39.4638 25.0391 40.9997 22.5 46.4994 22L52.9994 25L54.9994 30.5L54.4994 36Z"
					fill={fillLight}
				/>
				<path
					d="M43.0001 50.5C38.9888 44.9843 38.4139 38.241 42.9999 33C46.4999 29 55.9999 28.5 54.4994 34.5001L53.4995 42.5C53.7908 45.8496 55.257 45.9177 56.4998 49C59.9272 57.5 47.0001 56.0001 43.0001 50.5Z"
					fill={fillDark}
				/>
			</g>
			<path
				d="M42.8273 11.866C50.3716 11.8136 58.1653 15.7527 62.3289 22.9791C63.2031 22.2433 64.3779 21.1165 65.5086 19.7221C67.1062 17.7518 68.54 15.3399 68.9825 12.8265C69.0782 12.2826 69.5968 11.9193 70.1407 12.015C70.6846 12.1108 71.0479 12.6293 70.9522 13.1732C70.4264 16.1598 68.7631 18.8839 67.062 20.9817C65.7077 22.6519 64.2816 23.9836 63.2662 24.7973C63.353 24.987 63.4376 25.1786 63.5196 25.3722C63.7353 25.8806 63.4979 26.4676 62.9895 26.6833C62.4811 26.8989 61.8941 26.6616 61.6784 26.1531C61.5242 25.7894 61.3604 25.4331 61.1877 25.0844L61.1769 25.0691C61.1191 24.9856 61.0758 24.8964 61.0464 24.8044C57.3987 17.7207 50.0047 13.8162 42.8412 13.8659C35.2992 13.9183 28.033 18.3511 25.7363 28.2005C22.7899 40.8356 28.6558 49.2087 35.8835 58.373L36.0862 58.6301C38.4802 61.6664 41.3333 65.285 44.4634 68.1801C47.7094 71.1824 51.067 73.2275 54.3431 73.2275C56.657 73.2275 58.2326 72.2955 59.4315 70.8039C60.672 69.2604 61.5158 67.1045 62.2248 64.6819C62.3799 64.1519 62.9354 63.8479 63.4654 64.0031C63.9955 64.1582 64.2994 64.7137 64.1443 65.2437C63.421 67.7149 62.4897 70.1913 60.9903 72.0568C59.4494 73.9741 57.3204 75.2275 54.3431 75.2275C51.1306 75.2275 48.0587 73.6677 45.2691 71.4948C46.556 78.9259 45.6469 83.5838 45.1681 85.3124C45.0207 85.8446 44.4698 86.1566 43.9375 86.0092C43.4053 85.8618 43.0933 85.3109 43.2407 84.7786C43.6882 83.1628 44.7355 77.9653 42.8075 69.3701C39.6778 66.4164 36.8469 62.8256 34.5479 59.9093L34.3131 59.6115C27.0845 50.446 20.6087 41.3828 23.7885 27.7463C26.3091 16.9368 34.4239 11.9243 42.8273 11.866Z"
				fill={outline}
			/>
			<path
				d="M54.1151 29.8802C53.2916 29.2914 52.2971 28.9251 51.2427 28.7714C48.8021 28.4158 45.9255 29.1503 43.5908 30.8582C43.1451 31.1843 43.048 31.8099 43.3741 32.2557C43.7002 32.7015 44.3259 32.7985 44.7716 32.4724C46.7268 31.0422 49.0833 30.4779 50.9543 30.7505C52.7879 31.0177 54.0312 32.0371 54.2601 33.7687C54.2646 33.8034 54.271 33.8375 54.2789 33.8708C54.0377 35.1273 53.5837 36.3098 53.1582 37.4181L53.066 37.6585C52.4097 39.3731 51.8221 41.2004 52.0914 43.2064C52.3648 45.2421 53.491 47.2901 55.8767 49.5505C56.2776 49.9304 56.9105 49.9133 57.2904 49.5124C57.6702 49.1115 57.6532 48.4786 57.2522 48.0987C55.0733 46.0342 54.2667 44.3776 54.0736 42.9402C53.8766 41.4729 54.2889 40.0583 54.9339 38.3734C54.9719 38.2741 55.0111 38.1723 55.0512 38.0682C55.6793 36.4387 56.5277 34.2376 56.448 31.7957C56.3604 29.1154 55.1722 26.27 51.8232 23.4925C49.2618 21.3683 45.537 20.9505 42.23 21.9929C38.8946 23.0443 35.8513 25.6173 34.672 29.6475C34.5169 30.1775 34.8209 30.733 35.351 30.8881C35.881 31.0432 36.4364 30.7392 36.5915 30.2091C37.5602 26.8986 40.0493 24.7774 42.8313 23.9004C45.6419 23.0144 48.6195 23.4339 50.5464 25.032C52.5903 26.7271 53.6389 28.3613 54.1151 29.8802Z"
				fill={outline}
			/>
			<path
				d="M37.8136 50.9186C37.4926 50.4692 36.8681 50.3651 36.4186 50.6861C35.9692 51.0071 35.8651 51.6317 36.1861 52.0811C37.5459 53.9847 38.9398 54.9484 40.2083 55.6631C40.5346 55.8469 40.8347 56.0068 41.1136 56.1553C41.9563 56.6043 42.6063 56.9505 43.2048 57.545C44.1543 58.4881 44.6487 59.3 45.2069 60.2629L45.3081 60.4377C45.8523 61.3788 46.4776 62.4601 47.6053 63.804C48.9909 65.4554 51.291 66.2122 53.4669 65.9716C55.6826 65.7266 57.8687 64.4384 58.9706 61.8965C59.1902 61.3897 58.9575 60.8009 58.4508 60.5812C57.944 60.3616 57.3552 60.5943 57.1355 61.101C56.3509 62.9113 54.8324 63.8085 53.2471 63.9838C51.6218 64.1635 50.0243 63.5755 49.1374 62.5185C48.1234 61.3101 47.5723 60.3575 47.0336 59.4262L46.9372 59.2598C46.3552 58.2559 45.755 57.2591 44.6142 56.126C43.7861 55.3034 42.7932 54.7774 41.893 54.3005C41.6502 54.1719 41.4142 54.0469 41.19 53.9206C40.0907 53.3013 38.9539 52.515 37.8136 50.9186Z"
				fill={outline}
			/>
			<rect
				x="19"
				y="50"
				width="6"
				height="6"
				fill={background}
				stroke={outline}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M11 42C11 38.6863 13.6863 36 17 36H29C30.1046 36 31 36.8954 31 38V48C31 49.1046 30.1046 50 29 50H17C13.6863 50 11 47.3137 11 44V42Z"
				fill={fillLight}
				stroke={outline}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				d="M31 37C31 37 35.5 39.6 38.5 40C40.2176 40.2863 42.4268 40.4086 44.0017 40.4609C45.1057 40.4976 46 41.3954 46 42.5V43.5C46 44.6046 45.1057 45.5024 44.0017 45.5391C42.4268 45.5914 40.2176 45.7137 38.5 46C35.5 46.5 31 49 31 49V37Z"
				fill={fillLight}
				stroke={outline}
				strokeWidth="2"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M10 42C10 38.134 13.134 35 17 35H29C29.9061 35 30.7183 35.4017 31.2684 36.0367C31.3484 36.059 31.4263 36.0914 31.5001 36.1341L31.4999 36.341C31.8159 36.8162 32 37.3866 32 38V48C32 48.6225 31.8104 49.2007 31.4858 49.68L31.4856 49.8742L31.4855 49.8742C31.416 49.9128 31.3431 49.9425 31.2684 49.9633C30.7183 50.5983 29.9061 51 29 51H26V55C28.7614 55 31 57.2386 31 60V82.5C31 83.0523 30.5523 83.5 30 83.5C29.4477 83.5 29 83.0523 29 82.5V60C29 58.3431 27.6569 57 26 57H25H19H18C16.3431 57 15 58.3431 15 60V72C15 72.5523 14.5523 73 14 73C13.4477 73 13 72.5523 13 72V60C13 57.2386 15.2386 55 18 55V51H17C13.134 51 10 47.866 10 44V42ZM30 48V38C30 37.4477 29.5523 37 29 37H17C14.2386 37 12 39.2386 12 42V44C12 46.7614 14.2386 49 17 49H19H25H29C29.5523 49 30 48.5523 30 48ZM20 51H24V55H20V51Z"
				fill={outline}
			/>
		</svg>
	);
}

export default Otolaryngology;
