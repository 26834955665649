import { ReportEventParams } from 'api/common';
import { DocumentDataAsset } from 'lib/documentAssets';
import { createContext, useContext } from 'react';

export interface FileViewerModalContextValue {
	modalTitle: string;
	documents: DocumentDataAsset[];
	defaultDocument?: DocumentDataAsset;
	onDocumentChange?: (selection: DocumentDataAsset) => void;
	reportEvent: (args: ReportEventParams) => Promise<void>;
	setDefaultDocumentById: (id?: DocumentDataAsset['id']) => void;
}

const EMPTY_DOCUMENT_ASSET_VIEWER_CONTEXT = undefined;

const FileViewerModalContext = createContext<
	FileViewerModalContextValue | typeof EMPTY_DOCUMENT_ASSET_VIEWER_CONTEXT
>(EMPTY_DOCUMENT_ASSET_VIEWER_CONTEXT);

export function useFileViewerModalContext(): FileViewerModalContextValue {
	const context = useContext(FileViewerModalContext);

	if (context === undefined) {
		throw new Error(
			'File Viewer Modal context can only be consumed if used within a FileViewerModalProvider. Make sure an instance of FileViewerModalProvider has been set upstream.'
		);
	}

	return context;
}

export const FileViewerModalProvider = FileViewerModalContext.Provider;

export { FileViewerModalContext };
