import * as React from 'react';
import { Button, ButtonType } from 'display';
import RouteUrls from 'routing/RouteUrls';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

interface LinkToAddUserLicenseButtonProps {
	userId: string;
}

function LinkToAddUserLicenseButton({ userId }: LinkToAddUserLicenseButtonProps): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.OUTLINE}
			link={RouteUrls.buildUrl(RouteUrls.Key.USER_LICENSE_NEW, { userId })}
			text={Strings.Buttons.ADD_LICENSE}
			size={Button.Size.TINY}
			classNames="addLicenseButton"
		/>
	);
}

export default LinkToAddUserLicenseButton;
