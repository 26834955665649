import { UnauthenticatedRoute } from 'components/Routing/renderers';
import RouteRegistry from 'routing/RouteRegistry';
import { ConfigurablePageParts } from 'routing/types';
import RouteUrls from 'routing/RouteUrls';

const unauthenticatedRoutes = new RouteRegistry({
	renderer: UnauthenticatedRoute,
	routes: {
		[RouteUrls.Key.INBOUND]: {
			canSafelyReload: false,
			enableTelemetry: false,
			exact: false,
			path: '/in',
			template: () => import('pages/InboundPage'),
			pageOptions: {
				excludeParts: [
					ConfigurablePageParts.MENU,
					ConfigurablePageParts.FOOTER,
					ConfigurablePageParts.NAVBAR
				]
			}
		},
		[RouteUrls.Key.LOGIN]: {
			pageOptions: {
				excludeParts: [ConfigurablePageParts.MENU]
			},
			template: () => import('pages/OktaLoginPage'),
			enableTelemetry: false,
			path: '/login'
		},
		[RouteUrls.Key.OKTA_LOGIN]: {
			template: () => import('pages/OktaLoginPage'),
			enableTelemetry: false,
			path: '/okta-login',
			pageOptions: {
				excludeParts: [ConfigurablePageParts.MENU]
			}
		},
		[RouteUrls.Key.OKTA_CALLBACK]: {
			template: () => import('pages/OktaCallbackPage'),
			enableTelemetry: false,
			path: '/okta-callback',
			pageOptions: {
				excludeParts: [ConfigurablePageParts.MENU]
			}
		},
		[RouteUrls.Key.PATIENT_SESSION_EXPIRATION]: {
			template: () => import('pages/PatientSessionExpiration'),
			enableTelemetry: false,
			path: '/patient-session-expiration',
			pageOptions: {
				excludeParts: [
					ConfigurablePageParts.MENU,
					ConfigurablePageParts.FOOTER,
					ConfigurablePageParts.NAVBAR
				]
			}
		}
	}
});

export default unauthenticatedRoutes;
