import * as React from 'react';

import { Paragraph } from 'display';

function ConsultRequestSuggestions(): JSX.Element {
	return (
		<div>
			<Paragraph>
				To complete your consult request, click the camera button and record a video
				message.
			</Paragraph>
			<Paragraph>Effective requests often include the following:</Paragraph>
			<ul>
				<li>Reason for consult</li>
				<li>Symptom characteristics and duration</li>
				<li>Past and current treatments, medications</li>
				<li>Allergies</li>
				<li>Pertinent physical findings</li>
			</ul>
		</div>
	);
}

export default ConsultRequestSuggestions;
