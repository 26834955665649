import { DataTableColumn } from 'components/DataTable/DataTable';
import { User } from 'thriftgen/api_types';
import Strings from 'lib/strings';

export default (): DataTableColumn<User> => ({
	header: Strings.Labels.WEEKLY_CONSULTS_ASSIGNED,

	render: ({ specialist_data }: User) => {
		const { consults_assigned_this_week } = specialist_data;
		return consults_assigned_this_week ?? 0;
	}
});
