import { CardModal } from 'display';
import * as React from 'react';
import { OkayButton } from 'components/Buttons';
import { Table } from 'semantic-ui-react';

type DictationTipsModalProps = {
	isOpen: boolean;
	closeModal: () => void;
};

export default function DictationTipsModal(props: DictationTipsModalProps): JSX.Element {
	function close(): void {
		props.closeModal();
	}

	const commandsAndDescArray = [
		{
			commands: ['new paragraph', 'next paragraph', 'new line', 'next line'],
			desc: 'Create a new paragraph'
		},
		{
			commands: ['number 1', 'number 2', 'number X'],
			desc: "Add 'X).' on a new line"
		},
		{ commands: ['open parenthesis/parentheses', 'left parenthesis/parentheses'], desc: '(' },
		{ commands: ['close parenthesis/parentheses', 'right parenthesis/parentheses'], desc: ')' },
		{ commands: ['hyphen', 'dash'], desc: '-' },
		{ commands: ['comma'], desc: ',' },
		{ commands: ['semicolon'], desc: ';' },
		{ commands: ['colon'], desc: ':' },
		{ commands: ['apostrophe'], desc: "'" },
		{ commands: ['ampersand'], desc: '&' },
		{ commands: ['percent'], desc: '%' },
		{ commands: ['question mark'], desc: '?' },
		{ commands: ['exclamation mark', 'exclamation point'], desc: '!' },
		{ commands: ['backslash'], desc: '\\' },
		{ commands: ['forward slash', 'slash'], desc: '/' },
		{ commands: ['ellipsis'], desc: '...' },
		{ commands: ['period'], desc: '.' }
	];

	const commandTable = () => {
		return commandsAndDescArray.map((item, index) => {
			return (
				<Table.Row key={index}>
					<Table.Cell style={{ textAlign: 'center' }}>
						{item.commands.map((command, index) => {
							return (
								<div key={index}>
									<span>{command}</span>
								</div>
							);
						})}
					</Table.Cell>
					<Table.Cell style={{ textAlign: 'center' }}>{item.desc}</Table.Cell>
				</Table.Row>
			);
		});
	};

	const getContent = () => {
		return (
			<Table compact="very" style={{ fontSize: 'small', bottom: 'unset' }}>
				<Table.Header>
					<Table.Row>
						<Table.HeaderCell style={{ textAlign: 'center' }}>Command</Table.HeaderCell>
						<Table.HeaderCell style={{ textAlign: 'center' }}>Result</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>{commandTable()}</Table.Body>
			</Table>
		);
	};

	return (
		<React.Fragment>
			<CardModal open={props.isOpen} data-testid="dictationTipsModal">
				<div>
					<CardModal.Title>Dictation Tips</CardModal.Title>
					<div>
						<p>
							When dictating, be sure to speak clearly into your microphone and
							minimize background noise as much as possible.
						</p>
						<p>We currently support the following commands:</p>
						{getContent()}
					</div>
					<div style={{ display: 'flex' }}>
						<OkayButton onClick={close} />
					</div>
				</div>
			</CardModal>
		</React.Fragment>
	);
}
