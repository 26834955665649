import { translateThriftEnum } from 'lib/i18n';
import { Specialty, SPECIALTY } from 'thriftgen/api_types';
import { FIELD_NAME, SpecialtyFieldProps } from './types';

function getValue(data: SpecialtyFieldProps['data']): Specialty {
	return data[FIELD_NAME];
}

function translateSpecialty(
	specialty: Specialty | null,
	emptySpecialtyPlaceholder = 'Unspecified'
): string {
	if (!specialty) {
		return emptySpecialtyPlaceholder;
	}

	return translateThriftEnum(specialty, SPECIALTY);
}

export { getValue, translateSpecialty };
