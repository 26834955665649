import * as React from 'react';

import { css, cx } from 'emotion';
import { Divider, Segment } from 'display';
import Paragraph from '../Paragraph';
import TileLabel from './TileLabel';
import { borderRadius, colors, mediaQueries } from 'styles';

enum AttributeType {
	/**
	 * Adds "new" color treatment to the tile header.
	 * Also provides a label background.
	 */
	ATTENTION = 'attention',
	/**
	 * Adds "neutral" color treatment to the tile header.
	 * Also provides a label background.
	 */
	MUTE = 'mute',
	/**
	 * Adds "orange" color treatment to the tile header.
	 * Also provides a label background.
	 */
	STALE = 'stale'
}

interface AttributeProps {
	attribute: string;
	value?: React.ReactNode;
}

interface TileProps {
	action?: React.ReactNode;
	attributes: AttributeProps[];
	header: React.ReactNode;
	label?: string | null;
	type?: AttributeType | null;
}

const styles = {
	action: css`
		display: block;
		padding: 0.5em 1em 1em;
	`,
	attribute: css`
		& > div {
			margin-bottom: 0.5em;
		}

		&:not(:last-child) {
			margin-right: 3em;
		}

		${mediaQueries.mobile} {
			margin-bottom: 1em;
		}
	`,
	attributes: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: start;

		${mediaQueries.desktop} {
			width: 75%;
		}

		width: 100%;
	`,
	header: css`
		border-radius: ${borderRadius.l1} !important;
		margin-bottom: 0 !important;
	`,
	attention: {
		divider: css`
			background-color: ${colors.new['200']} !important;
			border-color: ${colors.new['200']} !important;
		`,
		header: css`
			background-color: ${colors.new['50']} !important;
		`,
		label: css`
			background-color: ${colors.new['200']} !important;
		`
	},
	mute: {
		divider: undefined,
		header: css`
			background-color: ${colors.neutral['25']} !important;
		`,
		label: undefined
	},
	stale: {
		// TODO: Switch these colors to one of our existing hues.
		divider: css`
			background-color: #ebdfd3 !important;
			border-color: #ebdfd3 !important;
		`,
		header: css`
			background-color: #f8f3ef !important;
		`,
		label: css`
			background-color: #ebdfd3 !important;
		`
	},
	tile: css`
		border-radius: 4px;
		background-color: ${colors.white};
		box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.1);
		&:not(:last-child) {
			margin-bottom: 2em;
		}
	`,
	row: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		padding: 1em;
	`
};

function Attribute({ attribute, value = '-' }: AttributeProps): JSX.Element {
	return (
		<div className={styles.attribute}>
			<Paragraph size={Paragraph.Size.T4} weight={Paragraph.Weight.BOLD}>
				{attribute}
			</Paragraph>
			<Paragraph size={Paragraph.Size.T4}>{value}</Paragraph>
		</div>
	);
}

function Tile({
	action = null,
	attributes,
	header,
	label = null,
	type = null
}: TileProps): JSX.Element {
	let dividerStyles = '';
	let headerStyles = styles.header;
	let labelStyles = '';

	if (type) {
		dividerStyles = cx(dividerStyles, styles[type].divider);
		headerStyles = cx(headerStyles, styles[type].header);
		labelStyles = cx(labelStyles, styles[type].label);
	}

	return (
		<section className={styles.tile}>
			<Segment basic={true} className={headerStyles}>
				{header}
				{label !== null && <TileLabel className={labelStyles}>{label}</TileLabel>}
			</Segment>
			<Divider fitted={true} className={dividerStyles} />
			<div className={styles.row}>
				<div className={styles.attributes}>
					{attributes.map(({ attribute, value }) => (
						<Attribute attribute={attribute} value={value} key={attribute} />
					))}
				</div>
				{action !== null && <div className={`${styles.action}`}>{action}</div>}
			</div>
		</section>
	);
}

Tile.TYPES = AttributeType;

export default Tile;
export { TileProps };
