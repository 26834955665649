import * as React from 'react';
import UploadContext, { UploadContextValue } from 'contexts/UploadContext';

interface CountProviderProps {
	value?: UploadContextValue;
	children: React.ReactNode;
}

function UploadContextProvider({ value, ...props }: CountProviderProps): JSX.Element {
	return <UploadContext.Provider value={{ uploader: value }} {...props} />;
}

export default UploadContextProvider;
