import Email from './Email';
import { buildFieldVariation } from '../helpers';

const LABEL = 'Patient email';

export default buildFieldVariation({
	Field: Email,
	label: LABEL,
	optionalLabelName: true
});
