import { State, STATE } from 'thriftgen/api_types.js';
import buildSelectField, { buildSelectFieldOptions } from './base/buildSelectField';

const FIELD_NAME = 'state';
const LABEL = 'State';
const PLACEHOLDER = 'Select state';
const VALIDATION_MESSAGE = 'Please enter a state.';

const options = buildSelectFieldOptions(State, STATE);

const StateField = buildSelectField({
	fieldName: FIELD_NAME,
	label: LABEL,
	placeholder: PLACEHOLDER,
	options,
	validationMessage: VALIDATION_MESSAGE
});

export default StateField;
