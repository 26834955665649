import * as React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'display';
import { getDateYears } from 'lib/date';

const YEARS = getDateYears();
const FIELD_NAME = 'year';

function hasError({ fields }) {
	return fields.includes(FIELD_NAME);
}

function getValue(data) {
	return data[FIELD_NAME];
}

function BirthYear({ onChange, data, errors }) {
	return (
		<Form.Select
			id={FIELD_NAME}
			error={hasError(errors)}
			search
			fluid
			label="Year of birth"
			placeholder="1970"
			options={YEARS}
			name={FIELD_NAME}
			value={getValue(data)}
			onChange={onChange}
			selectOnBlur={false}
		/>
	);
}

BirthYear.FIELD_NAME = FIELD_NAME;

BirthYear.defaultProps = {
	data: {},
	errors: {
		fields: []
	}
};

BirthYear.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.shape({
		[FIELD_NAME]: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	})
};

export default BirthYear;
