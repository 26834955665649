/* eslint-disable react/prop-types */
import * as React from 'react';
import { css, cx } from 'emotion';

import { borderRadius, mediaQueries } from 'styles';

const styles = {
	video: css`
		background-color: #000000;
		border-radius: ${borderRadius.l1};
		display: block;

		// Crops the video playback if size
		// exceeds 3/4 the viewport's height
		max-height: 50vh;
		${mediaQueries.desktop} {
			max-height: 75vh;
		}
		overflow: auto;
		-webkit-overflow-scrolling: touch;
		width: 100%;
	`
};

type VideoProps = React.DetailedHTMLProps<
	React.VideoHTMLAttributes<HTMLVideoElement>,
	HTMLVideoElement
> & {
	setRef?: (instance: HTMLVideoElement | null) => void;
};

function Video({
	autoPlay = false,
	className,
	controls = true,
	muted = false,
	poster,
	setRef,
	src,
	...rest
}: VideoProps): JSX.Element {
	return (
		<video
			autoPlay={autoPlay}
			className={cx(styles.video, className)}
			controls={controls}
			muted={muted}
			poster={poster}
			ref={setRef}
			src={src}
			playsInline={true}
			{...rest}
		/>
	);
}

export default Video;
