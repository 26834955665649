import Link from 'components/Link';
import { SemanticICONS } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { css } from 'emotion';
import { colors } from 'styles';
import { useUserContext } from 'contexts';

const styles = {
	link: css`
		color: ${colors.greyDark} !important;
		&hover {
			color: ${colors.greyDark} !important;
		}
	`
};

interface ViewPartnerDetailsActionProps {
	data: TimeframeRestriction;
	children: React.ReactNode;
}

function ViewPartnerDetailsAction({
	data,
	children
}: ViewPartnerDetailsActionProps): JSX.Element | null {
	const { user } = useUserContext();

	const { start_date: startDate, end_date: endDate } = data;
	const link = RouteUrls.buildUrl(RouteUrls.Key.USER_TIMEFRAME_RESTRICTION_EDIT, {
		userId: user.user_id,
		startDate,
		endDate
	});
	return (
		<Link to={link} className={styles.link}>
			{children}
		</Link>
	);
}

ViewPartnerDetailsAction.ICON = 'pencil' as SemanticICONS;
ViewPartnerDetailsAction.TITLE = 'Edit';

export default ViewPartnerDetailsAction;
