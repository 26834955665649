//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./api_types');
//HELPER FUNCTIONS AND STRUCTURES

var AuthService_check_token_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CheckTokenRequest(args.request);
    }
  }
};
AuthService_check_token_args.prototype = {};
AuthService_check_token_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CheckTokenRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_check_token_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_check_token_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_check_token_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CheckTokenResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_check_token_result.prototype = {};
AuthService_check_token_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CheckTokenResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_check_token_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_check_token_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_get_partner_info_for_token_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetPartnerInfoForTokenRequest(args.request);
    }
  }
};
AuthService_get_partner_info_for_token_args.prototype = {};
AuthService_get_partner_info_for_token_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetPartnerInfoForTokenRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_get_partner_info_for_token_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_get_partner_info_for_token_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_get_partner_info_for_token_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetPartnerInfoForTokenResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_get_partner_info_for_token_result.prototype = {};
AuthService_get_partner_info_for_token_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetPartnerInfoForTokenResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_get_partner_info_for_token_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_get_partner_info_for_token_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_create_account_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreateAccountRequest(args.request);
    }
  }
};
AuthService_create_account_args.prototype = {};
AuthService_create_account_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreateAccountRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_create_account_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_create_account_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_create_account_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.LoginResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_create_account_result.prototype = {};
AuthService_create_account_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.LoginResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_create_account_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_create_account_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_enable_2fa_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.Enable2FARequest(args.request);
    }
  }
};
AuthService_enable_2fa_args.prototype = {};
AuthService_enable_2fa_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.Enable2FARequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_enable_2fa_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_enable_2fa_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_enable_2fa_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Enable2FAResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_enable_2fa_result.prototype = {};
AuthService_enable_2fa_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Enable2FAResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_enable_2fa_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_enable_2fa_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_generate_2fa_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.Generate2FARequest(args.request);
    }
  }
};
AuthService_generate_2fa_args.prototype = {};
AuthService_generate_2fa_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.Generate2FARequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_generate_2fa_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_generate_2fa_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_generate_2fa_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.Generate2FAResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_generate_2fa_result.prototype = {};
AuthService_generate_2fa_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.Generate2FAResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_generate_2fa_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_generate_2fa_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_login_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.LoginRequest(args.request);
    }
  }
};
AuthService_login_args.prototype = {};
AuthService_login_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.LoginRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_login_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_login_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_login_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.LoginResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_login_result.prototype = {};
AuthService_login_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.LoginResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_login_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_login_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_oidc_login_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.OidcLoginRequest(args.request);
    }
  }
};
AuthService_oidc_login_args.prototype = {};
AuthService_oidc_login_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.OidcLoginRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_oidc_login_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_oidc_login_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_oidc_login_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.LoginResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_oidc_login_result.prototype = {};
AuthService_oidc_login_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.LoginResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_oidc_login_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_oidc_login_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_logout_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.LogoutRequest(args.request);
    }
  }
};
AuthService_logout_args.prototype = {};
AuthService_logout_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.LogoutRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_logout_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_logout_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_logout_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.LogoutResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_logout_result.prototype = {};
AuthService_logout_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.LogoutResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_logout_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_logout_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_password_reset_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.PasswordResetRequest(args.request);
    }
  }
};
AuthService_password_reset_args.prototype = {};
AuthService_password_reset_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.PasswordResetRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_password_reset_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_password_reset_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_password_reset_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.PasswordResetResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_password_reset_result.prototype = {};
AuthService_password_reset_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.PasswordResetResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_password_reset_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_password_reset_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_redeem_token_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.RedeemTokenRequest(args.request);
    }
  }
};
AuthService_redeem_token_args.prototype = {};
AuthService_redeem_token_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.RedeemTokenRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_redeem_token_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_redeem_token_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_redeem_token_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.RedeemTokenResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_redeem_token_result.prototype = {};
AuthService_redeem_token_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.RedeemTokenResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_redeem_token_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_redeem_token_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_resend_expired_token_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ResendExpiredTokenRequest(args.request);
    }
  }
};
AuthService_resend_expired_token_args.prototype = {};
AuthService_resend_expired_token_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ResendExpiredTokenRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_resend_expired_token_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_resend_expired_token_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_resend_expired_token_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ResendExpiredTokenResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_resend_expired_token_result.prototype = {};
AuthService_resend_expired_token_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ResendExpiredTokenResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_resend_expired_token_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_resend_expired_token_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_send_token_verification_code_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.SendTokenVerificationCodeRequest(args.request);
    }
  }
};
AuthService_send_token_verification_code_args.prototype = {};
AuthService_send_token_verification_code_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.SendTokenVerificationCodeRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_send_token_verification_code_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_send_token_verification_code_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_send_token_verification_code_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.SendTokenVerificationCodeResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_send_token_verification_code_result.prototype = {};
AuthService_send_token_verification_code_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.SendTokenVerificationCodeResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_send_token_verification_code_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_send_token_verification_code_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_submit_contact_info_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.SubmitContactInfoRequest(args.request);
    }
  }
};
AuthService_submit_contact_info_args.prototype = {};
AuthService_submit_contact_info_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.SubmitContactInfoRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_submit_contact_info_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_submit_contact_info_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_submit_contact_info_result = function(args) {
  this.success = null;
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.SubmitContactInfoResponse(args.success);
    }
  }
};
AuthService_submit_contact_info_result.prototype = {};
AuthService_submit_contact_info_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.SubmitContactInfoResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_submit_contact_info_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_submit_contact_info_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_validate_login_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.ValidateLoginRequest(args.request);
    }
  }
};
AuthService_validate_login_args.prototype = {};
AuthService_validate_login_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.ValidateLoginRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_validate_login_args.prototype.write = function(output) {
  output.writeStructBegin('AuthService_validate_login_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthService_validate_login_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.ValidateLoginResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AuthService_validate_login_result.prototype = {};
AuthService_validate_login_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.ValidateLoginResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AuthService_validate_login_result.prototype.write = function(output) {
  output.writeStructBegin('AuthService_validate_login_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AuthServiceClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
AuthServiceClient.prototype = {};
AuthServiceClient.prototype.seqid = function() { return this._seqid; };
AuthServiceClient.prototype.new_seqid = function() { return this._seqid += 1; };

AuthServiceClient.prototype.check_token = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_check_token(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_check_token(request);
  }
};

AuthServiceClient.prototype.send_check_token = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_check_token_args(params);
  try {
    output.writeMessageBegin('check_token', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_check_token = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_check_token_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('check_token failed: unknown result');
};

AuthServiceClient.prototype.get_partner_info_for_token = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_partner_info_for_token(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_partner_info_for_token(request);
  }
};

AuthServiceClient.prototype.send_get_partner_info_for_token = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_get_partner_info_for_token_args(params);
  try {
    output.writeMessageBegin('get_partner_info_for_token', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_get_partner_info_for_token = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_get_partner_info_for_token_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_partner_info_for_token failed: unknown result');
};

AuthServiceClient.prototype.create_account = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_create_account(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_create_account(request);
  }
};

AuthServiceClient.prototype.send_create_account = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_create_account_args(params);
  try {
    output.writeMessageBegin('create_account', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_create_account = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_create_account_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('create_account failed: unknown result');
};

AuthServiceClient.prototype.enable_2fa = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_enable_2fa(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_enable_2fa(request);
  }
};

AuthServiceClient.prototype.send_enable_2fa = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_enable_2fa_args(params);
  try {
    output.writeMessageBegin('enable_2fa', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_enable_2fa = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_enable_2fa_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('enable_2fa failed: unknown result');
};

AuthServiceClient.prototype.generate_2fa = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_generate_2fa(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_generate_2fa(request);
  }
};

AuthServiceClient.prototype.send_generate_2fa = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_generate_2fa_args(params);
  try {
    output.writeMessageBegin('generate_2fa', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_generate_2fa = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_generate_2fa_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('generate_2fa failed: unknown result');
};

AuthServiceClient.prototype.login = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_login(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_login(request);
  }
};

AuthServiceClient.prototype.send_login = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_login_args(params);
  try {
    output.writeMessageBegin('login', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_login = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_login_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('login failed: unknown result');
};

AuthServiceClient.prototype.oidc_login = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_oidc_login(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_oidc_login(request);
  }
};

AuthServiceClient.prototype.send_oidc_login = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_oidc_login_args(params);
  try {
    output.writeMessageBegin('oidc_login', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_oidc_login = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_oidc_login_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('oidc_login failed: unknown result');
};

AuthServiceClient.prototype.logout = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_logout(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_logout(request);
  }
};

AuthServiceClient.prototype.send_logout = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_logout_args(params);
  try {
    output.writeMessageBegin('logout', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_logout = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_logout_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('logout failed: unknown result');
};

AuthServiceClient.prototype.password_reset = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_password_reset(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_password_reset(request);
  }
};

AuthServiceClient.prototype.send_password_reset = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_password_reset_args(params);
  try {
    output.writeMessageBegin('password_reset', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_password_reset = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_password_reset_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('password_reset failed: unknown result');
};

AuthServiceClient.prototype.redeem_token = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_redeem_token(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_redeem_token(request);
  }
};

AuthServiceClient.prototype.send_redeem_token = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_redeem_token_args(params);
  try {
    output.writeMessageBegin('redeem_token', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_redeem_token = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_redeem_token_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('redeem_token failed: unknown result');
};

AuthServiceClient.prototype.resend_expired_token = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_resend_expired_token(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_resend_expired_token(request);
  }
};

AuthServiceClient.prototype.send_resend_expired_token = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_resend_expired_token_args(params);
  try {
    output.writeMessageBegin('resend_expired_token', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_resend_expired_token = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_resend_expired_token_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('resend_expired_token failed: unknown result');
};

AuthServiceClient.prototype.send_token_verification_code = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_send_token_verification_code(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_send_token_verification_code(request);
  }
};

AuthServiceClient.prototype.send_send_token_verification_code = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_send_token_verification_code_args(params);
  try {
    output.writeMessageBegin('send_token_verification_code', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_send_token_verification_code = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_send_token_verification_code_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('send_token_verification_code failed: unknown result');
};

AuthServiceClient.prototype.submit_contact_info = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_submit_contact_info(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_submit_contact_info(request);
  }
};

AuthServiceClient.prototype.send_submit_contact_info = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_submit_contact_info_args(params);
  try {
    output.writeMessageBegin('submit_contact_info', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_submit_contact_info = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_submit_contact_info_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('submit_contact_info failed: unknown result');
};

AuthServiceClient.prototype.validate_login = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_validate_login(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_validate_login(request);
  }
};

AuthServiceClient.prototype.send_validate_login = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AuthService_validate_login_args(params);
  try {
    output.writeMessageBegin('validate_login', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AuthServiceClient.prototype.recv_validate_login = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AuthService_validate_login_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('validate_login failed: unknown result');
};
var AuthServiceProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
AuthServiceProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
AuthServiceProcessor.prototype.process_check_token = function(seqid, input, output) {
  var args = new AuthService_check_token_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.check_token.length === 1) {
    Q.fcall(this._handler.check_token.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_check_token_result({success: result});
      output.writeMessageBegin("check_token", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_check_token_result(err);
        output.writeMessageBegin("check_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("check_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.check_token(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_check_token_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("check_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("check_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_get_partner_info_for_token = function(seqid, input, output) {
  var args = new AuthService_get_partner_info_for_token_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_partner_info_for_token.length === 1) {
    Q.fcall(this._handler.get_partner_info_for_token.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_get_partner_info_for_token_result({success: result});
      output.writeMessageBegin("get_partner_info_for_token", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_get_partner_info_for_token_result(err);
        output.writeMessageBegin("get_partner_info_for_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_partner_info_for_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_partner_info_for_token(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_get_partner_info_for_token_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_partner_info_for_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_partner_info_for_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_create_account = function(seqid, input, output) {
  var args = new AuthService_create_account_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.create_account.length === 1) {
    Q.fcall(this._handler.create_account.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_create_account_result({success: result});
      output.writeMessageBegin("create_account", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_create_account_result(err);
        output.writeMessageBegin("create_account", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_account", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.create_account(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_create_account_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("create_account", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_account", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_enable_2fa = function(seqid, input, output) {
  var args = new AuthService_enable_2fa_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.enable_2fa.length === 1) {
    Q.fcall(this._handler.enable_2fa.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_enable_2fa_result({success: result});
      output.writeMessageBegin("enable_2fa", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_enable_2fa_result(err);
        output.writeMessageBegin("enable_2fa", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("enable_2fa", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.enable_2fa(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_enable_2fa_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("enable_2fa", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("enable_2fa", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_generate_2fa = function(seqid, input, output) {
  var args = new AuthService_generate_2fa_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.generate_2fa.length === 1) {
    Q.fcall(this._handler.generate_2fa.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_generate_2fa_result({success: result});
      output.writeMessageBegin("generate_2fa", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_generate_2fa_result(err);
        output.writeMessageBegin("generate_2fa", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("generate_2fa", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.generate_2fa(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_generate_2fa_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("generate_2fa", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("generate_2fa", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_login = function(seqid, input, output) {
  var args = new AuthService_login_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.login.length === 1) {
    Q.fcall(this._handler.login.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_login_result({success: result});
      output.writeMessageBegin("login", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_login_result(err);
        output.writeMessageBegin("login", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("login", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.login(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_login_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("login", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("login", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_oidc_login = function(seqid, input, output) {
  var args = new AuthService_oidc_login_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.oidc_login.length === 1) {
    Q.fcall(this._handler.oidc_login.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_oidc_login_result({success: result});
      output.writeMessageBegin("oidc_login", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_oidc_login_result(err);
        output.writeMessageBegin("oidc_login", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("oidc_login", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.oidc_login(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_oidc_login_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("oidc_login", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("oidc_login", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_logout = function(seqid, input, output) {
  var args = new AuthService_logout_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.logout.length === 1) {
    Q.fcall(this._handler.logout.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_logout_result({success: result});
      output.writeMessageBegin("logout", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_logout_result(err);
        output.writeMessageBegin("logout", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("logout", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.logout(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_logout_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("logout", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("logout", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_password_reset = function(seqid, input, output) {
  var args = new AuthService_password_reset_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.password_reset.length === 1) {
    Q.fcall(this._handler.password_reset.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_password_reset_result({success: result});
      output.writeMessageBegin("password_reset", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_password_reset_result(err);
        output.writeMessageBegin("password_reset", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("password_reset", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.password_reset(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_password_reset_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("password_reset", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("password_reset", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_redeem_token = function(seqid, input, output) {
  var args = new AuthService_redeem_token_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.redeem_token.length === 1) {
    Q.fcall(this._handler.redeem_token.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_redeem_token_result({success: result});
      output.writeMessageBegin("redeem_token", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_redeem_token_result(err);
        output.writeMessageBegin("redeem_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("redeem_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.redeem_token(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_redeem_token_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("redeem_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("redeem_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_resend_expired_token = function(seqid, input, output) {
  var args = new AuthService_resend_expired_token_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.resend_expired_token.length === 1) {
    Q.fcall(this._handler.resend_expired_token.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_resend_expired_token_result({success: result});
      output.writeMessageBegin("resend_expired_token", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_resend_expired_token_result(err);
        output.writeMessageBegin("resend_expired_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resend_expired_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.resend_expired_token(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_resend_expired_token_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("resend_expired_token", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("resend_expired_token", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_send_token_verification_code = function(seqid, input, output) {
  var args = new AuthService_send_token_verification_code_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.send_token_verification_code.length === 1) {
    Q.fcall(this._handler.send_token_verification_code.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_send_token_verification_code_result({success: result});
      output.writeMessageBegin("send_token_verification_code", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_send_token_verification_code_result(err);
        output.writeMessageBegin("send_token_verification_code", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("send_token_verification_code", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.send_token_verification_code(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_send_token_verification_code_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("send_token_verification_code", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("send_token_verification_code", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_submit_contact_info = function(seqid, input, output) {
  var args = new AuthService_submit_contact_info_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.submit_contact_info.length === 1) {
    Q.fcall(this._handler.submit_contact_info.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_submit_contact_info_result({success: result});
      output.writeMessageBegin("submit_contact_info", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
      output.writeMessageBegin("submit_contact_info", Thrift.MessageType.EXCEPTION, seqid);
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.submit_contact_info(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined')) {
        result_obj = new AuthService_submit_contact_info_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("submit_contact_info", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("submit_contact_info", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AuthServiceProcessor.prototype.process_validate_login = function(seqid, input, output) {
  var args = new AuthService_validate_login_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.validate_login.length === 1) {
    Q.fcall(this._handler.validate_login.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AuthService_validate_login_result({success: result});
      output.writeMessageBegin("validate_login", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AuthService_validate_login_result(err);
        output.writeMessageBegin("validate_login", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("validate_login", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.validate_login(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AuthService_validate_login_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("validate_login", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("validate_login", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
