import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';
import { fontWeight, modularScale } from 'styles/';

import * as ACTION_STATUS from 'store/actionStatus';

import { Message } from 'display';

const styles = {
	message: css`
		font-weight: ${fontWeight.bold};
		font-size: ${modularScale(0)};
	`
};

function StatusMessage(props) {
	const { status, message, ...restProps } = props;
	let messageColor = {};

	switch (status) {
		case ACTION_STATUS.SUCCESS:
			messageColor.positive = true;
			break;
		case ACTION_STATUS.FAILURE:
			messageColor.negative = true;
			break;
		default:
			messageColor.info = true;
	}

	return (
		<Message
			size="small"
			{...messageColor}
			{...restProps}
			content={<p className={styles.message}>{message}</p>}
		/>
	);
}

StatusMessage.propTypes = {
	status: PropTypes.string.isRequired,
	message: PropTypes.string
};

export default StatusMessage;
