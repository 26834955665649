import * as React from 'react';

import OktaSecurityProvider from './OktaSecurityProvider';
import OktaSecurityConsumer from './OktaSecurityConsumer';

interface OktaSecurityProps {
	children: React.ReactNode;
}

function OktaSecurity({ children }: OktaSecurityProps): JSX.Element {
	return (
		<OktaSecurityProvider>
			<OktaSecurityConsumer>{children}</OktaSecurityConsumer>
		</OktaSecurityProvider>
	);
}

export default OktaSecurity;
