import ProcessingStepLogEntry from './ProcessingStepLogEntry';
import { RegexError } from './types';

export default class ProcessingIterationLogEntry {
	errors: Array<{ name: string; errors: RegexError[] }>;
	processingStepLogEntries: { processingStepLogEntries: Array<ProcessingStepLogEntry> };
	textFromAmazon: string;
	processedTranscript: string;

	constructor() {
		this.textFromAmazon = '';
		this.processedTranscript = '';
		this.processingStepLogEntries = { processingStepLogEntries: [] };
		this.errors = [];
	}

	addStepLogEntry(entry: ProcessingStepLogEntry) {
		if (entry.errors.length > 0) {
			this.errors.push({
				name: entry.functionName,
				errors: entry.errors
			});
		}

		this.processingStepLogEntries.processingStepLogEntries.push(entry);
	}
}
