import { DataFormFieldComponent } from '../DataFormTypes';
import buildTextFieldComponent from './base/buildTextField';

const FIELD_NAME = 'providerNpi';
const FIELD_LABEL = 'NPI number';

const NPI_REGEX = /^\d{10}$/;

function validateNPI(value: string | undefined, required: boolean): string[] | null {
	if ((required && !value) || (value && !NPI_REGEX.test(value))) {
		return ['Please provide a valid 10 digit NPI number.'];
	}

	return null;
}

const NpiNumber: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	validate: validateNPI,
	placeholder: '1234567890'
});

export default NpiNumber;
