import { PageDisplayOptions, ConfigurablePageParts } from 'routing/types';

function isPartExcluded(
	pageOptions: PageDisplayOptions = {},
	part: ConfigurablePageParts
): boolean {
	const { excludeParts = [] } = pageOptions;
	return excludeParts.includes(part);
}

function isPartIncluded(
	pageOptions: PageDisplayOptions = {},
	part: ConfigurablePageParts
): boolean {
	return !isPartExcluded(pageOptions, part);
}

export { isPartIncluded };
