import { LogoutRequestCamel } from 'thriftgen/thriftCamelTypes';
import { objectCamelToSnake } from 'lib/objectConverter';
import { sitkaOktaAuth } from 'lib/okta';
import { getAuthClient } from 'lib/ThriftClient';
import { LogoutRequest, LogoutResponse } from 'thriftgen/api_types';
import { SitkaLogger } from 'lib/sitkaLogger';

export default function logout(camelRequest: LogoutRequestCamel): Promise<LogoutResponse> {
	const client = getAuthClient();
	const request: LogoutRequest = objectCamelToSnake(camelRequest);
	SitkaLogger.logMessage('logout ' + JSON.stringify(request));

	return client
		.logout(request)
		.catch((err: Error) => {
			SitkaLogger.logMessage('logout error: ' + err.name + ' - ' + err.message);
		})
		.finally(async (response: LogoutResponse): Promise<LogoutResponse> => {
			await sitkaOktaAuth.signOut({
				postLogoutRedirectUri: window.location.origin + '/'
			});
			return response;
		});
}
