import * as React from 'react';
import moment from 'moment';

import MaskedInput from 'react-text-mask';

import { DataFormErrors, DataFormFieldComponentProps } from '../../DataFormTypes';
import { isValidDate, normalizeBirthDate, conformValue } from './helpers';
import { Form, Icon } from 'semantic-ui-react';

const FIELD_NAME = 'dateOfBirth';
const FIELD_LABEL = 'Birthdate';
const PLACEHOLDER_BIRTHDATE = 'mm/dd/yyyy';
const ERROR_INVALID = 'Please enter a valid date of birth.';
const ERROR_FUTURE = 'Birth date cannot be in the future.';
const ERROR_TOO_OLD = 'Birth date cannot be more than 120 years old.';
const birthdateMask: Array<RegExp | string> = [
	/[0,1]/,
	/[0-9]/,
	'/',
	/[0-3]/,
	/[0-9]/,
	'/',
	/[1,2]/,
	/[0-9]/,
	/[0-9]/,
	/[0-9]/
];

function validate(data: DataFormFieldComponentProps['data']): DataFormErrors | null {
	const dob: string = normalizeBirthDate(data[FIELD_NAME]);
	const errors: string[] = [];

	if (!isValidDate(dob)) {
		errors.push(ERROR_INVALID);
	}

	if (moment().diff(moment(dob, 'MM/DD/YYYY'), 'years') > 120) {
		errors.push(ERROR_TOO_OLD);
	}

	if (moment().diff(moment(dob, 'MM/DD/YYYY')) <= 0) {
		errors.push(ERROR_FUTURE);
	}

	if (errors.length > 0) {
		return {
			fields: [FIELD_NAME],
			messages: errors
		};
	}

	return null;
}

function CombinedDateOfBirth({
	data,
	editableFields,
	errors,
	onChange,
	onBlur
}: DataFormFieldComponentProps): JSX.Element | null {
	const value: string = normalizeBirthDate(data[FIELD_NAME]);

	function onChangeHandler({ currentTarget }: React.BaseSyntheticEvent): void {
		if (isValidDate(currentTarget.value)) {
			onChange(currentTarget, {
				name: FIELD_NAME,
				value: conformValue(currentTarget.value)
			});
		}
	}

	if (editableFields.includes(FIELD_NAME)) {
		return (
			<Form.Field error={errors.fields.includes(FIELD_NAME)}>
				<label htmlFor={FIELD_NAME}>{FIELD_LABEL}</label>
				<Form.Input id={FIELD_NAME} name={FIELD_NAME} width={8}>
					<MaskedInput
						mask={birthdateMask}
						guide={true}
						id={FIELD_NAME}
						name={FIELD_NAME}
						placeholder={PLACEHOLDER_BIRTHDATE}
						type="text"
						onChange={onChangeHandler}
						value={value || ''}
						onBlur={onBlur}
					/>
				</Form.Input>
			</Form.Field>
		);
	}

	if (value) {
		return (
			<Form.Field>
				<Icon name="calendar outline" />
				<span>{value}</span>
			</Form.Field>
		);
	}

	return null;
}

CombinedDateOfBirth.FIELD_NAME = FIELD_NAME;
CombinedDateOfBirth.displayName = `TextField-${FIELD_NAME}`;
CombinedDateOfBirth.validate = validate;

export default CombinedDateOfBirth;
