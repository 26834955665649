import * as React from 'react';
import { TimeframeRestriction } from 'thriftgen/api_types';
import DataTable, { DataTableColumn } from '../DataTable';
import { userTimeframeRestrictionColumns } from './columns';

interface UserTimeframeRestrictionProps {
	columns?: Array<DataTableColumn<TimeframeRestriction>>;
	emptyMessage?: string;
	userTimeframeRestrictions: TimeframeRestriction[];
}

const COLUMNS = [
	userTimeframeRestrictionColumns.buildStartDate(),
	userTimeframeRestrictionColumns.buildEndDate(),
	userTimeframeRestrictionColumns.buildActions()
];
const EMPTY_MESSAGE = 'No dates at this time.';

function UserTimeframeRestrictionTable({
	columns = COLUMNS,
	emptyMessage = EMPTY_MESSAGE,
	userTimeframeRestrictions
}: UserTimeframeRestrictionProps): JSX.Element {
	return (
		<DataTable<TimeframeRestriction>
			emptyMessage={emptyMessage}
			columns={columns}
			data={userTimeframeRestrictions}
			data-testid="TimeframeRestrictionTable"
		/>
	);
}

UserTimeframeRestrictionTable.Columns = userTimeframeRestrictionColumns;
export default UserTimeframeRestrictionTable;
