import * as React from 'react';
import { css } from 'emotion';
import { getFullDateTime } from 'lib/date';
import { fontSize } from 'styles';

export interface TimestampProps {
	timestamp: number;
}

const styles = {
	timestamp: css`
		font-size: ${fontSize[1]};
		color: #707070;
	`
};

function Timestamp({ timestamp }: TimestampProps): JSX.Element {
	return <span className={styles.timestamp}>{getFullDateTime(timestamp)}</span>;
}

export default Timestamp;
