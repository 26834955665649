import * as React from 'react';

import { FilterField, FilterValue, OnChange, OnRemove } from './types';
import { isEmptyQuery } from './helpers';

import Filter from './Filter';
import { Form, Segment } from 'display';
import { css } from 'emotion';
import { mediaQueries } from '../../styles';

interface FilterMenuWithActionsProps {
	actions: Array<JSX.Element>;
	filters: Array<Omit<FilterField, 'onChange'>>;
	onRemove: OnRemove;
	onChange: OnChange;
}

const styles = {
	segmentWrapper: css`
		display: flex;
		justify-content: space-between;

		${mediaQueries.mobileAndTablet} {
			flex-direction: column-reverse;
		}
	`,
	searchBar: css`
		width: 50%;

		${mediaQueries.mobileAndTablet} {
			width: 100%;
		}
	`,
	actionsWrapper: css`
		align-self: flex-end;
		margin-bottom: 1em;

		&:has(a:first-child:nth-last-child(1)) {
			justify-content: flex-end;
		}

		a:last-child {
			margin-left: 1em;
		}

		${mediaQueries.mobile} {
			align-self: center;
			width: 100%;
			display: flex;
			justify-content: space-between;
		}
	`
};

function FilterMenuWithActions({
	filters,
	actions,
	onRemove,
	onChange
}: FilterMenuWithActionsProps): JSX.Element {
	function renderFilter({ onChange: _onChange, ...fieldRest }: FilterField): React.ReactNode {
		function changeFilter(changeValue: FilterValue): void {
			if (isEmptyQuery(fieldRest.type, changeValue)) {
				onRemove(fieldRest.attribute);
			} else if (changeValue !== undefined) {
				onChange(fieldRest.attribute, changeValue);
			}
		}

		return <Filter key={fieldRest.attribute} onChange={changeFilter} {...fieldRest} />;
	}

	return (
		<Segment basic={true} className={styles.segmentWrapper} id={'SEGMENT'}>
			<Form className={styles.searchBar} type="">
				{filters.map(renderFilter)}
			</Form>
			<div className={styles.actionsWrapper} id={'actions'}>
				{actions}
			</div>
		</Segment>
	);
}

export default FilterMenuWithActions;
export { FilterMenuWithActionsProps };
