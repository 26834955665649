import { DataTableColumn } from 'components/DataTable/DataTable';
import { buildHalfWidthMobileColumn } from 'components/DataTable/helpers';
import { ConsultComposite } from 'hooks';
import { getAbbreviatedDate } from 'lib/date';
import * as React from 'react';

const header = 'Request Date';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ requestDate }: ConsultComposite) => <div>{getAbbreviatedDate(requestDate)}</div>,
	renderMobile: ({ requestDate }: ConsultComposite) =>
		buildHalfWidthMobileColumn(header, getAbbreviatedDate(requestDate)),
	sortKeys: ['request_date']
});
