import * as React from 'react';

import {
	CaseCamel,
	ManageCaseRequestCamel,
	PartnerCamel,
	UserCamel,
	UserInfoCamel
} from 'thriftgen/thriftCamelTypes';
import { ReportEventParams } from 'api/common';

interface CaseComposite extends CaseCamel {
	partner: PartnerCamel;
	patient: UserCamel;
}

interface CaseContextData {
	theCase: CaseComposite;
}

interface CaseContextMutators {
	manageCase: (args: ManageCaseRequestCamel) => Promise<void>;
	reportEvent: (args: ReportEventParams) => Promise<void>;
	revalidate: () => Promise<void>;
	updatePatient: (user: UserInfoCamel) => Promise<void>;
}

type CaseContextValue = CaseContextData & CaseContextMutators;

const CaseContext = React.createContext<CaseContextValue | undefined>(undefined);

function useCaseContext(): CaseContextValue {
	const context = React.useContext(CaseContext);

	if (context === undefined) {
		throw new Error('No case has been set');
	}

	return context;
}

export { CaseComposite, CaseContext, CaseContextData, CaseContextMutators, useCaseContext };
