import * as React from 'react';

import { Message, Paragraph } from 'display';

function FaceVideoLoadError(): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Failed to load playback</Message.Header>
			<Paragraph>
				There was en error trying to load your webcam&apos;s stream. Please try again or
				contact support at{' '}
				<a href="mailto:support@trustsitka.com">support@trustsitka.com</a> if you need help.
			</Paragraph>
		</Message>
	);
}

export default FaceVideoLoadError;
