import { DataTableColumn } from 'components/DataTable/DataTable';
import { ConsultComposite } from 'hooks';
import Strings from 'lib/strings';
import { getLastNameFirstName } from 'lib/userHelpers';
import * as React from 'react';

const header = 'Reviewer';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ reviewer }) => {
		let displayValue;
		if (reviewer) {
			displayValue = getLastNameFirstName(reviewer);
		} else {
			displayValue = Strings.PLACEHOLDERS.NO_VALUE;
		}
		return <div>{displayValue}</div>;
	}
});
