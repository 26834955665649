import { getEmails } from 'api/common';
import { infrequentRefresh } from 'hooks/queries/configs';
import { responseInterface } from 'swr';
import {
	GetEmailsRequestCamel,
	GetEmailsResponseCamel,
	PaginationMarkersCamel
} from 'thriftgen/thriftCamelTypes';
import useQuery from '../useQuery';

type EmailsQueryResult = GetEmailsResponseCamel;

type UseEmailsQuery = {
	data: EmailsQueryResult | undefined;
	error: responseInterface<GetEmailsResponseCamel, Error>['error'];
	pageMarkers: PaginationMarkersCamel | undefined;
	revalidate: () => Promise<void>;
};

function useEmailsQuery(args: GetEmailsRequestCamel): UseEmailsQuery {
	const key = ['emails', args];
	const r = useQuery<EmailsQueryResult>(
		key,
		(_: string, params: GetEmailsRequestCamel) => {
			const response = getEmails(params);
			return response;
		},
		infrequentRefresh
	);

	const { data, error, mutate } = r;

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		data,
		pageMarkers: data?.pageMarkers,
		error,
		revalidate
	};
}

export { UseEmailsQuery, useEmailsQuery };
