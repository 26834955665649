import * as React from 'react';
import { CaseContext, CaseContextData, CaseContextMutators } from 'contexts';

function CaseContextProvider({
	children,
	data,
	mutators
}: {
	children: React.ReactNode;
	data: CaseContextData;
	mutators: CaseContextMutators;
}): JSX.Element {
	return (
		<CaseContext.Provider
			value={{
				...data,
				...mutators
			}}>
			{children}
		</CaseContext.Provider>
	);
}

export default CaseContextProvider;
