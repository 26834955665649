import * as React from 'react';
import PropTypes from 'prop-types';

import { Form, Icon } from 'display';

// eslint-disable-next-line no-useless-escape
const emailRegex =
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const noop = () => {};
const FIELD_NAME = 'email';

function validate(data, required) {
	const email = data[FIELD_NAME];

	if ((required && !email) || (email && email.length > 0 && !emailRegex.test(email))) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please enter a valid email address.']
		};
	}

	return null;
}

class Email extends React.Component {
	static FIELD_NAME = FIELD_NAME;
	static validate = validate;

	canEdit() {
		const { editableFields } = this.props;

		return editableFields.includes(FIELD_NAME);
	}

	hasError() {
		const { errors } = this.props;
		const { fields } = errors;

		return fields.includes(FIELD_NAME);
	}

	getValue() {
		const { data } = this.props;

		return data[FIELD_NAME];
	}

	render() {
		const { alwaysShow, label, onBlur, onChange } = this.props;
		const value = this.getValue();

		if (this.canEdit()) {
			return (
				<Form.Input
					error={this.hasError()}
					fluid
					id={FIELD_NAME}
					label={label}
					placeholder="vivien.thomas@louisiana.com"
					name={FIELD_NAME}
					onBlur={onBlur}
					onChange={onChange}
					value={value || ''}
				/>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<Icon name="mail" />
					<a href={`mailto:${value}`}>{value}</a>
				</Form.Field>
			);
		}

		if (alwaysShow) {
			return (
				<Form.Field>
					<Icon name="mail" />
					<span>[none provided]</span>
				</Form.Field>
			);
		}

		return null;
	}
}

Email.defaultProps = {
	alwaysShow: false,
	data: {},
	editableFields: [],
	errors: {
		fields: [],
		messages: []
	},
	label: 'Email',
	onBlur: noop,
	onChange: noop
};

Email.propTypes = {
	alwaysShow: PropTypes.bool,
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string),
		messages: PropTypes.arrayOf(PropTypes.string)
	}),
	label: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func
};

export default Email;
