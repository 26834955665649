import { transformToThriftFilter } from 'api/helpers';
import { UsersLoadError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';
import { usePaginationContext } from 'contexts/PaginationContext';
import { Loader } from 'display';
import { Parameters } from 'hooks/pagination';
import * as React from 'react';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { sendGetUsers } from 'store/users';
import { GetUsersResponse } from 'thriftgen/api_types';
import { GetUsersRequestCamel } from 'thriftgen/thriftCamelTypes';

interface RequestUsersProps {
	children: JSX.Element;
	defaultFilters?: Parameters['filters'];
	sendGetUsers: (args?: GetUsersRequestCamel) => Promise<GetUsersResponse>;
}

function RequestUsers({
	children,
	defaultFilters = [],
	request,
	sendGetUsers
}: RequestUsersProps & WithApiCallProps): JSX.Element {
	const { status, message } = request.data;
	const { parameters, setNeighborPageCursors } = usePaginationContext();

	React.useEffect(() => {
		const { pageCursor, pageSize, filters, sorts } = parameters;

		const requestFilters = [...defaultFilters, ...filters].map(transformToThriftFilter);

		sendGetUsers({
			pageParams: {
				pageCursor: pageCursor ?? undefined,
				pageSize,
				filters: requestFilters,
				sorts
			}
		}).then(({ page_markers }) =>
			setNeighborPageCursors({
				prevPageCursor: page_markers.prev_page_cursor,
				nextPageCursor: page_markers.next_page_cursor
			})
		);
	}, [parameters]);

	switch (status) {
		case FAILURE:
			return <UsersLoadError message={message} />;

		case SUCCESS:
			return children;

		case INACTIVE:
		case IN_PROGRESS:
		default:
			return <Loader active={true} />;
	}
}

export default withApiCall({ sendGetUsers }, RequestUsers);
