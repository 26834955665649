const breakpoints = {
	mobile: 500,
	desktop: 800
};

const isDesktopViewportWidth = (): boolean => document.body.clientWidth >= breakpoints.desktop;

const mediaQueries = {
	desktop: `@media (min-width: ${breakpoints.desktop}px)`,
	mobile: `@media (min-width: 0px) and (max-width: ${breakpoints.mobile}px)`,
	tablet: `@media (min-width: ${breakpoints.mobile}px) and (max-width: ${breakpoints.desktop}px)`,
	mobileAndTablet: `@media (min-width: 0px) and (max-width: 991px)`
};

export { breakpoints, isDesktopViewportWidth, mediaQueries };
