import * as React from 'react';
import { SUPPORT_EMAIL } from 'lib/supportContacts';

import { Anchor, Message, Paragraph } from 'display';

function DocumentUnavailableMessage(): JSX.Element {
	return (
		<Message info={true}>
			<Message.Header>Unavailable document</Message.Header>
			<Paragraph>
				This document is not currently viewable. Please try again later or contact support
				at <Anchor href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</Anchor>.
			</Paragraph>
		</Message>
	);
}

export default DocumentUnavailableMessage;
