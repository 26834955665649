import { useDictationContext } from 'contexts/DictationContext';
import { cx } from 'emotion';
import React from 'react';
import { Icon, Popup } from 'semantic-ui-react';
import { Button } from '..';
import { richTextEditorButtonStyles } from './RichTextEditorButtonCSS';

interface StopButtonProps {
	onTranscribeStop: () => void;
}

export default function StopButton({ onTranscribeStop }: StopButtonProps): JSX.Element {
	const { isListening, stopListening } = useDictationContext();

	function onStopClick() {
		stopListening();
		onTranscribeStop();
	}

	return (
		<Popup
			content={'Stop transcribing'}
			trigger={
				<Button
					className={cx({ [richTextEditorButtonStyles.button]: true })}
					icon
					onClick={onStopClick}
					data-testid="microphoneButtonStop">
					<Icon
						className={cx(
							{ [richTextEditorButtonStyles.buttonIcon]: true },
							{ [richTextEditorButtonStyles.red]: isListening }
						)}
						name="microphone"
					/>
				</Button>
			}
			size="small"
			mouseEnterDelay={500}
		/>
	);
}
