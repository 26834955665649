import { DataFormFieldComponent } from '../DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const FIELD_NAME = 'isAdmin';
const LABEL = 'Admin';

const AdminField: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default AdminField;
