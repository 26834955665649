import { GetTeamsRequestCamel, GetTeamsResponseCamel } from 'thriftgen/thriftCamelTypes';
import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetTeamsRequest } from 'thriftgen/api_types';

async function getTeams(params: GetTeamsRequestCamel): Promise<GetTeamsResponseCamel> {
	const client = getCommonClient();

	const request = new GetTeamsRequest(objectCamelToSnake(params));

	const response = await client.get_teams(request);

	return objectSnakeToCamel(response);
}

export default getTeams;
