import * as React from 'react';
import { css, cx } from 'emotion';

import { colors, borderRadius } from 'styles';
import { AssetListItemThumbnailProps, AssetListItemThumbnailData } from './types';

const styles = {
	icon: css`
		margin: auto;
	`,
	img: css`
		border-radius: ${borderRadius.l1};
		height: 100%;
		object-fit: cover;
		width: 100%;
	`,
	base: css`
		background-color: ${colors.white};
		border-radius: ${borderRadius.l1};
		display: flex;
		flex: 0 0 auto;
		margin-right: 0.5em;
		width: 2.5em;
		height: 2.5em;
		position: relative;

		svg {
			height: 100%;
			width: auto;
		}
	`,
	new: css`
		background-color: ${colors.new['200']};
		border: ${borderRadius.l1} solid ${colors.new['200']};
		&:after {
			background-color: ${colors.new['800']};
			border-radius: ${borderRadius.circle};
			border: 3px solid ${colors.neutral['25']};
			content: '';
			display: block;
			height: 1em;
			position: absolute;
			right: -9px;
			top: -9px;
			width: 1em;
		}
	`,
	processing: css`
		background-color: ${colors.neutral[50]};
	`
};

function isAssetThumbnailData(
	thumbnail: AssetListItemThumbnailData | JSX.Element
): thumbnail is AssetListItemThumbnailData {
	return Object.prototype.hasOwnProperty.call(thumbnail, 'icon');
}

function AssetListItemThumbnail({ thumbnail }: AssetListItemThumbnailProps): JSX.Element {
	if (isAssetThumbnailData(thumbnail)) {
		const { icon, image, isNew, isProcessing } = thumbnail;

		return (
			<div
				data-testid="AssetListItemThumbnail"
				className={cx(styles.base, {
					[styles.processing]: isProcessing,
					[styles.new]: isNew
				})}>
				{image ? (
					<img
						alt="File thumbnail"
						className={styles.img}
						src={image}
						crossOrigin="anonymous" // https://github.com/trustsitka/sitka/issues/4115
					/>
				) : (
					<img alt="File icon" className={styles.icon} src={icon} />
				)}
			</div>
		);
	}

	return <div className={styles.base}>{thumbnail}</div>;
}

export default AssetListItemThumbnail;
