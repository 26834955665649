import User from 'components/admin/User/User';
import userColumns from 'components/DataTable/UserTable/columns';
import { UserSelectOnChangeData } from 'components/UserSelect/types';
import UserSelect from 'components/UserSelect/UserSelect';
import { Form } from 'display';
import { css } from 'emotion';
import { Parameters } from 'hooks/pagination';
import { getUserFullNameAndTitle } from 'lib/userHelpers';
import { isArray } from 'lodash';
import * as React from 'react';
import { User as UserThrift } from 'thriftgen/api_types';
import { StringSetMutationCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponentProps } from '../../DataFormTypes';

const styles = {
	staff: css`
		padding: 0 1rem;
		margin: 0 0;
	`
};

type StaffListProps = DataFormFieldComponentProps<StringSetMutationCamel> & {
	fieldName: string;
	filters: Parameters['filters'];
	label: string;
};

const COLUMNS = [userColumns.buildEmail()];

function StaffList({
	data,
	editableFields,
	errors,
	fieldName,
	filters,
	label,
	onChange
}: StaffListProps): JSX.Element | null {
	function canEdit(): boolean {
		return editableFields.includes(fieldName);
	}

	function hasError(): boolean {
		const { fields } = errors;
		return fields.includes(fieldName);
	}

	function getValue(): string[] {
		return data[fieldName].toAdd ?? [];
	}

	const value = getValue();

	const onUserChange = (_: React.SyntheticEvent, eventData: UserSelectOnChangeData): void => {
		if (!isArray(eventData.value)) {
			eventData.value = [eventData.value];
		}
		onChange(_, { name: fieldName, value: { toAdd: eventData.value, toRemove: [] } });
	};

	if (canEdit()) {
		return (
			<Form.Field error={hasError()}>
				<label htmlFor={fieldName}>{label}</label>
				<UserSelect
					columns={COLUMNS}
					requestFilters={filters}
					name={fieldName}
					onChange={onUserChange}
					value={value}
					multiple={true}
				/>
			</Form.Field>
		);
	}

	return (
		<Form.Field>
			<label>{label}</label>
			<ul className={styles.staff}>
				{value.map((id: UserThrift['user_id']) => (
					<li key={id}>
						<User userId={id} render={getUserFullNameAndTitle} />
					</li>
				))}
			</ul>
		</Form.Field>
	);
}

export default StaffList;
