import { Button } from 'display';
import Strings from 'lib/strings';
import * as React from 'react';

function RemoveSpecialistButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Icon
			icon="trash alternate outline"
			className="removeSpecialistButton"
			onClick={onClick}
			title={Strings.Buttons.REMOVE_SPECIALIST}
		/>
	);
}

export default RemoveSpecialistButton;
