import * as React from 'react';

import { Paragraph } from 'display';

function SpecialtyNotListedMessage(): JSX.Element {
	return (
		<Paragraph>
			<strong>Please complete your consult</strong> and our Chief Medical Officer will find
			you the appropriate specialist.
		</Paragraph>
	);
}

export default SpecialtyNotListedMessage;
