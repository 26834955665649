import * as React from 'react';
import Strings from 'lib/strings';
import RejectedFilesErrorMessage from './RejectedFilesErrorMessage';

interface FileInputRejectedFilesProps {
	rejectedExtensionFiles: File[];
	rejectedMimeTypeFiles: File[];
}

function FileInputRejectedFiles({
	rejectedExtensionFiles,
	rejectedMimeTypeFiles
}: FileInputRejectedFilesProps): JSX.Element {
	return (
		<React.Fragment>
			<RejectedFilesErrorMessage
				rejectedFilesList={rejectedExtensionFiles}
				errorMessage={Strings.Errors.REJECTED_FILE_EXTENSION}
			/>
			<RejectedFilesErrorMessage
				rejectedFilesList={rejectedMimeTypeFiles}
				errorMessage={Strings.Errors.REJECTED_FILE_MIME_TYPE}
			/>
		</React.Fragment>
	);
}

export default FileInputRejectedFiles;
