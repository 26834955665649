import * as React from 'react';
import STATES from 'lib/statesList';
import { DropdownProps, Form } from 'display';

const FIELD_NAME = 'state';
const LABEL = 'State';

interface StateFieldProps {
	data: {
		[FIELD_NAME]: string;
		[key: string]: unknown;
	};
	editableFields: string[];
	errors: {
		fields: string[];
	};
	onChange: (event: React.SyntheticEvent<HTMLInputElement>, data: DropdownProps) => void;
}

function getOptions() {
	return Object.keys(STATES).map(key => ({
		key,
		value: key,
		text: STATES[key]
	}));
}

function getValue(data: StateFieldProps['data']): string {
	return data[FIELD_NAME];
}

function hasError(errors: StateFieldProps['errors']): boolean {
	return errors.fields.includes(FIELD_NAME);
}

function canEdit(editableFields: StateFieldProps['editableFields']): boolean {
	return editableFields.includes(FIELD_NAME);
}

function StateProvince({
	editableFields,
	errors = { fields: [] },
	data,
	onChange
}: StateFieldProps): JSX.Element | null {
	const value = getValue(data);

	if (canEdit(editableFields)) {
		return (
			<Form.Select
				id={FIELD_NAME}
				error={hasError(errors)}
				search={true}
				fluid={true}
				label={LABEL}
				placeholder="California"
				name={FIELD_NAME}
				options={getOptions()}
				value={value}
				onChange={onChange}
			/>
		);
	}

	if (value) {
		return (
			<Form.Field>
				<label>{LABEL}</label>
				<span>{STATES[value]}</span>
			</Form.Field>
		);
	}

	return null;
}

StateProvince.FIELD_NAME = FIELD_NAME;

export default StateProvince;
