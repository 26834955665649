import { Button, DropdownProps, Form, Heading, Icon, InputOnChangeData } from 'display';
import { css } from 'emotion';
import noop from 'lib/noop';
import * as React from 'react';
import { DataFormChangeData, DataFormErrors } from '../DataFormTypes';
import Condition from './Condition';
import Interventions from './Interventions';

const FIELD_NAME = 'specialistResponseAssessment';
const LABEL = 'Assessment Plan and Recommendations';
const ASSESSMENT_LIMIT = 3;

const styles = {
	additionalConditionsButton: css`
		font-weight: normal !important;
	`,
	assessmentFields: css`
		margin-bottom: 1em;
	`,
	removeButton: css`
		text-align: right;
	`
};
interface SpecialistResponseAssessmentProps {
	data: {
		[FIELD_NAME]?: Partial<SpecialistResponseAssessment>[];
	};
	editableFields: string[];
	errors: {
		fields: string[];
		messages: string[];
	};
	onChange: (
		event: React.SyntheticEvent,
		data: DataFormChangeData<{ [key: string]: Partial<SpecialistResponseAssessment>[] }>
	) => void;
}

function SpecialistResponseAssessmentGroup({
	data,
	errors,
	editableFields,
	onChange = noop
}: SpecialistResponseAssessmentProps): JSX.Element | null {
	const value = data[FIELD_NAME] || [];
	const canEdit = editableFields.includes(FIELD_NAME);

	function canRemoveCondition(): boolean {
		return value.length > 1 && canEdit;
	}

	function handleAdditionalConditionClick(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>
	) {
		const newValue = [...value];
		newValue.push({ order: value.length + 1 });
		onChange(event, { name: FIELD_NAME, value: newValue });
	}

	function handleRemoveConditionClick(
		event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
		index: number
	) {
		const newValue = [...value];
		newValue.splice(index, 1);
		onChange(event, { name: FIELD_NAME, value: newValue });
	}

	function getInterventionDataByIndex(index: number) {
		return { interventions: value[index].interventions };
	}

	function handleInterventionDataChange(
		event: React.SyntheticEvent<HTMLElement, Event>,
		dropdownProps: DropdownProps,
		index: number
	) {
		const newValue = [...value];
		if (dropdownProps.value) {
			newValue[index].interventions = dropdownProps.value as SpecialistResponseIntervention[];
		}
		onChange(event, { name: FIELD_NAME, value: newValue });
	}

	function getConditionDataByIndex(index: number) {
		return { condition: value[index].specialist_response_condition };
	}

	function handleConditionDataChange(
		event: React.SyntheticEvent<HTMLElement, Event>,
		input: InputOnChangeData,
		index: number
	) {
		const newValue = [...value];
		newValue[index].specialist_response_condition = input?.value;

		onChange(event, { name: FIELD_NAME, value: newValue });
	}

	function getFieldErrorsByIndex(fieldName: string, index: number): DataFormErrors {
		return {
			fields: errors.fields.map(field =>
				field === `${fieldName}${index}` ? fieldName : field
			),
			messages: errors.messages
		};
	}

	function renderAssessmentFields(index: number): JSX.Element {
		return (
			<div key={index} className={styles.assessmentFields}>
				<Condition
					data={getConditionDataByIndex(index)}
					editableFields={canEdit ? ['condition'] : []}
					errors={getFieldErrorsByIndex(Condition.FIELD_NAME, index)}
					onChange={(
						event: React.SyntheticEvent<HTMLElement, Event>,
						input: InputOnChangeData
					) => {
						handleConditionDataChange(event, input, index);
					}}
					labelOverride={`Condition #${index + 1}`}
				/>
				<Interventions
					data={getInterventionDataByIndex(index)}
					editableFields={canEdit ? ['interventions'] : []}
					errors={getFieldErrorsByIndex(Interventions.FIELD_NAME, index)}
					onChange={(
						event: React.SyntheticEvent<HTMLElement, Event>,
						dropdownProps: DropdownProps
					) => {
						handleInterventionDataChange(event, dropdownProps, index);
					}}
				/>
				{canRemoveCondition() && (
					<div className={styles.removeButton}>
						<Button.Icon
							onClick={event => handleRemoveConditionClick(event, index)}
							icon="trash"
							data-testid="removeAssessmentGroup"
						/>
					</div>
				)}
			</div>
		);
	}

	return (
		<Form.Field>
			<Heading as={Heading.Type.H3} size={Heading.Size.T4}>
				{LABEL}
			</Heading>
			{value && (
				<>
					{value.map((_, index) => renderAssessmentFields(index))}
					{value.length < ASSESSMENT_LIMIT && canEdit && (
						<Button.Text
							size={Button.Size.SMALL}
							onClick={handleAdditionalConditionClick}
							className={styles.additionalConditionsButton}>
							<Icon name="plus" />
							Additional Condition
						</Button.Text>
					)}
				</>
			)}
		</Form.Field>
	);
}

function validate(
	data: SpecialistResponseAssessmentProps['data'],
	required: boolean
): DataFormErrors | null {
	const nameField = data[FIELD_NAME];
	const emptyErrors: DataFormErrors = { fields: [], messages: [] };
	let groupErrors: DataFormErrors = { ...emptyErrors };
	if (required) {
		if (nameField) {
			nameField.forEach((assessment, index) => {
				const isConditionValid =
					Condition.validate(
						{
							[`${Condition.FIELD_NAME}`]: assessment.specialist_response_condition
						},
						required
					) || emptyErrors;

				const isInterventionsValid =
					Interventions.validate(
						{ [`${Interventions.FIELD_NAME}`]: assessment.interventions },
						required
					) || emptyErrors;

				const newGroupErrorFields = [
					...isConditionValid.fields,
					...isInterventionsValid.fields
				].map(fieldName => `${fieldName}${index}`);

				if (newGroupErrorFields.length) {
					groupErrors = {
						fields: [...groupErrors.fields, ...newGroupErrorFields],
						messages: ['Please enter complete assessments.']
					};
				}
			});
			return groupErrors;
		} else {
			return {
				fields: [FIELD_NAME],
				messages: ['Please enter an assessment.']
			};
		}
	}
	return null;
}

SpecialistResponseAssessmentGroup.FIELD_NAME = FIELD_NAME;
SpecialistResponseAssessmentGroup.validate = validate;

export default SpecialistResponseAssessmentGroup;
