import { Form } from 'display';
import { translateThriftEnum } from 'lib/i18n';
import * as React from 'react';
import { Specialty, SPECIALTY } from 'thriftgen/api_types';
import { getSpecialtyOptions } from '../helpers';
import { FIELD_NAME, getValue, hasError } from './helpers';
import { EditSpecialtiesFieldProps } from './types';

const options = getSpecialtyOptions();

function EditSpecialtiesField({ data, errors, onChange }: EditSpecialtiesFieldProps): JSX.Element {
	function clear() {
		onChange(null as never, { name: FIELD_NAME, value: [] });
	}

	React.useEffect(() => {
		return clear;
	}, []);

	return (
		<Form.Select
			data-testid="editSpecialtiesField"
			error={hasError(errors)}
			id={FIELD_NAME}
			label="Specialties"
			multiple={true}
			name={FIELD_NAME}
			onChange={onChange}
			options={options}
			placeholder={translateThriftEnum(Specialty.ORTHOPEDICS, SPECIALTY)}
			search={true}
			value={getValue(data)}
		/>
	);
}

export { EditSpecialtiesField };
