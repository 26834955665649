import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import Strings from 'lib/strings';
import moment from 'moment';
import * as React from 'react';
import { colors } from 'styles';
import { ConsultStateSimplified } from 'thriftgen/api_types';

const styles = {
	overdue: css`
		color: ${colors.problem.base};
		text-transform: uppercase;
	`
};

interface TimeToRespondDisplayProps {
	consultComposite: ConsultComposite;
}

function TimeToRespondDisplay({ consultComposite }: TimeToRespondDisplayProps): JSX.Element {
	const { slaExpiresAt, simplifiedState, hasResponse } = consultComposite;

	function canShowTimeToRespond(): boolean {
		const statesWithResponses = [
			ConsultStateSimplified.CLOSED,
			ConsultStateSimplified.RESPONDED
		];
		if (hasResponse || statesWithResponses.includes(simplifiedState)) {
			return false;
		} else {
			return true;
		}
	}

	function getRemainingTime(): JSX.Element | string {
		if (!slaExpiresAt) {
			return Strings.PLACEHOLDERS.NO_VALUE;
		}

		const expirationTime = moment().diff(moment.unix(slaExpiresAt));

		if (expirationTime < 0) {
			const remainingTimeMinutes = Math.floor(
				moment.duration(expirationTime).asMinutes() * -1
			);
			const remainingTimeHours = Math.floor(remainingTimeMinutes / 60);
			return remainingTimeHours >= 1
				? `${remainingTimeHours} hrs`
				: `${remainingTimeMinutes} mins`;
		} else {
			return <div className={styles.overdue}>Overdue</div>;
		}
	}

	return (
		<React.Fragment>
			{canShowTimeToRespond() ? getRemainingTime() : Strings.PLACEHOLDERS.NO_VALUE}
		</React.Fragment>
	);
}

export default TimeToRespondDisplay;
