import React, { useState } from 'react';
import { WebViewerInstance } from '@pdftron/webviewer';
import type { WebViewerVideoInstance } from '@pdftron/webviewer-video/types/src';
import noop from 'lib/noop';

import { PdfViewerAsset, VideoControlsOptions } from './types';
import { loadDocument, setupWebViewer } from './helpers';
import { dataIsVideo, loadVideo, setupVideoViewer } from './videoHelpers';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

function logWebViewerError(error: Error, document: PdfViewerAsset): void {
	SitkaLogger.logMessage(
		{
			error,
			params: {
				consultDataId: document.id
			}
		},
		LogEventName.WEB_VIEWER
	);
}

interface PdfViewerState {
	error: Error | null;
	isLoading: boolean;
	unloadDocumentCallback: (() => void) | null;
	unloadVideoCallback: (() => void) | null;
	videoViewer: WebViewerVideoInstance | null;
	webViewer: WebViewerInstance | null;
}

interface UseWebViewerProps {
	document: PdfViewerAsset;
	onDocumentLoaded: () => void;
	onDocumentLoadError: () => void;
	videoEventsHandlers: VideoControlsOptions;
}

interface UseWebviewerReturn {
	container: React.RefObject<HTMLDivElement>;
	error: Error | null;
	isLoading: boolean;
}

interface UnloadCallback {
	unloadDocumentCallback: (() => void) | null;
	unloadVideoCallback: (() => void) | null;
}

function useWebViewer({
	document,
	onDocumentLoaded,
	onDocumentLoadError,
	videoEventsHandlers
}: UseWebViewerProps): UseWebviewerReturn {
	const container: React.RefObject<HTMLDivElement> = React.createRef();
	const [
		{ error, isLoading, videoViewer, webViewer, unloadDocumentCallback, unloadVideoCallback },
		setState
	] = useState<PdfViewerState>({
		error: null,
		isLoading: true,
		unloadDocumentCallback: null,
		unloadVideoCallback: null,
		videoViewer: null,
		webViewer: null
	});

	React.useEffect(() => {
		if (!webViewer || !videoViewer) {
			setState(state => ({
				...state,
				isLoading: true
			}));
			return;
		}

		function unloadAssets() {
			try {
				if (unloadVideoCallback) {
					unloadVideoCallback();
				}
				if (unloadDocumentCallback) {
					unloadDocumentCallback();
				}
			} catch (e) {
				noop();
			} finally {
				setState(state => ({
					//resetting error from the last document
					...state,
					error: null
				}));
			}
		}

		unloadAssets();
		webViewer.UI.closeDocument().then(async () => {
			try {
				const callbackObj: UnloadCallback = {
					unloadDocumentCallback: null,
					unloadVideoCallback: null
				};
				setState(state => ({
					...state,
					isLoading: true
				}));
				if (dataIsVideo(document)) {
					callbackObj.unloadVideoCallback = await loadVideo({
						data: document,
						eventHandlers: videoEventsHandlers,
						onLoad: onDocumentLoaded,
						videoViewer,
						webViewer
					});
				} else {
					callbackObj.unloadDocumentCallback = await loadDocument({
						data: document,
						onError: onDocumentLoadError,
						onLoad: onDocumentLoaded,
						webViewer
					});
				}
				setState(state => ({
					...state,
					isLoading: false,
					error: null,
					...callbackObj
				}));
			} catch (e) {
				setState(state => ({
					...state,
					error: e,
					isLoading: false
				}));
				logWebViewerError(e, document);
				onDocumentLoadError();
			}
		});

		return unloadAssets;
	}, [
		document,
		onDocumentLoadError,
		onDocumentLoaded,
		videoEventsHandlers,
		videoViewer,
		webViewer
	]);

	React.useEffect(() => {
		const containerRef = container.current;
		setupWebViewer(containerRef as HTMLElement)
			.then(async (webViewerInstance: WebViewerInstance) => {
				const videoViewerInstance = await setupVideoViewer(webViewerInstance);

				setState(state => ({
					...state,
					isLoading: false,
					webViewer: webViewerInstance,
					videoViewer: videoViewerInstance
				}));
				webViewerInstance.UI.setTheme('light');
			})
			.catch((e: Error) => {
				logWebViewerError(e, document);
				setState(state => ({
					...state,
					isLoading: false,
					error: e
				}));
			});
	}, []);

	return {
		container,
		isLoading,
		error
	};
}

export default useWebViewer;
