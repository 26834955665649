import { isUserAdminForPartner } from 'lib/userHelpers';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { Partner, User } from 'thriftgen/api_types';
import PartnerTable from './PartnerTable';

interface StoreProps {
	isSitkaOperations: boolean;
}
interface ConsumerProps {
	user: User;
	partners: Partner[];
}

type UserPartnerAssociationsTableProps = StoreProps & ConsumerProps;

function sortByUserAdminStatus(user: User, partners: Partner[]): Partner[] {
	return partners.sort((a, b) => {
		const isUserAdminA = isUserAdminForPartner(user, a.partner_id);
		const isUserAdminB = isUserAdminForPartner(user, b.partner_id);

		if (isUserAdminA === isUserAdminB) {
			if (a.name && b.name) {
				return a.name.localeCompare(b.name);
			}

			return 0;
		}

		if (isUserAdminA) {
			return -1;
		}

		return 1;
	});
}

function UserPartnerAssociationsTable({
	user,
	partners,
	isSitkaOperations
}: UserPartnerAssociationsTableProps): JSX.Element {
	const columns = [
		PartnerTable.Columns.buildName(),
		PartnerTable.Columns.buildIsAdmin(user),
		PartnerTable.Columns.buildActions({ isSitkaOperations })
	];

	return (
		<PartnerTable
			partners={sortByUserAdminStatus(user, partners)}
			columns={columns}
			emptyMessage="No partner associations exist."
		/>
	);
}

const mapStoreToProps = (store: AppState): StoreProps => {
	const actor = selectAuthUser(store);
	return {
		isSitkaOperations: actor.is_sitka_operations
	};
};

export default connect(mapStoreToProps)(UserPartnerAssociationsTable);
