import { ManageConsultRequestAction } from 'api/common/manageConsult';
import * as React from 'react';
import { Consult, Partner, User } from 'thriftgen/api_types';

interface ConsultsContextData {
	consults: Consult[];
	partners: Partner[];
	users: User[];
}

interface ConsultsContextMutators {
	revalidate: () => Promise<void>;
	manageConsult: (args: ManageConsultRequestAction) => Promise<void>;
}

type ConsultContextValue = ConsultsContextData & ConsultsContextMutators;

const ConsultsContext = React.createContext<ConsultContextValue | undefined>(undefined);

function useConsultsContext(): ConsultContextValue {
	const context = React.useContext(ConsultsContext);

	if (context === undefined) {
		throw new Error('No consults have been set');
	}

	return context;
}

export { ConsultsContext, ConsultsContextData, ConsultsContextMutators, useConsultsContext };
