import withApiCall from 'components/withApiCall/withApiCall';
import { Loader } from 'display';
import * as React from 'react';
import { connect } from 'react-redux';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { SendGetUserAction } from 'store/actionTypes';
import { AppState } from 'store/types';
import { selectUsersById, sendGetUser } from 'store/users';
import { GetUserResponse, User } from 'thriftgen/api_types';
import UserNameAndAvatar from '../../User/UserNameAndAvatar';
import { getLastNameFirstName } from '../../../lib/userHelpers';
import { useEffect } from 'react';

interface ConsumerProps {
	textFunc: (user: User) => string;
	userId: User['user_id'];
	withLoader: boolean;
}

interface DispatchProps {
	sendGetUser: (args: SendGetUserAction) => Promise<GetUserResponse>;
}

interface StateProps {
	user: User;
}

type UserProps = ConsumerProps & DispatchProps & StateProps & WithApiCallProps;

function render(user: User, textFunc?: (user: User) => string): JSX.Element {
	if (!textFunc) {
		return <UserNameAndAvatar user={user} displayNameFunc={getLastNameFirstName} />;
	} else {
		return <UserNameAndAvatar user={user} displayNameFunc={textFunc} />;
	}
}

function UserComponent({ user, textFunc, userId, withLoader, sendGetUser, request }: UserProps) {
	useEffect(() => {
		if (!user && userId) {
			sendGetUser({ userId });
		}
	}, [user, userId, sendGetUser]);

	const { status } = request.data;

	switch (status) {
		case FAILURE:
			return <span>User not found</span>;

		case IN_PROGRESS:
			if (withLoader) {
				return <Loader active={true} />;
			} else {
				return null;
			}

		case INACTIVE:
		case SUCCESS:
		default:
			if (user) {
				return render(user, textFunc);
			} else {
				return null;
			}
	}
}

const mapStateToProps = (store: AppState, ownProps: ConsumerProps): StateProps => ({
	user: selectUsersById(store)[ownProps.userId]
});

export default withApiCall({ sendGetUser }, connect(mapStateToProps)(UserComponent));
