import { getAuthClient } from 'lib/ThriftClient';
import { SendSendTokenVerificationCodeAction } from 'store/actionTypes';
import { SendTokenVerificationCodeResponse } from 'thriftgen/api_types';

function sendTokenVerificationCode({
	tokenId
}: SendSendTokenVerificationCodeAction): Promise<SendTokenVerificationCodeResponse> {
	const client = getAuthClient();

	return client.send_token_verification_code({ token_id: tokenId });
}

sendTokenVerificationCode.onSuccess = () => 'An authentication code has been sent to your email.';

export default sendTokenVerificationCode;
