import { FilterField } from 'display';
import { translateThriftEnum } from 'lib/i18n';
import { Partner, Specialty, SPECIALTY } from 'thriftgen/api_types';

export default (partners?: Partner[]): FilterField => {
	const specialtyTypeFilter: FilterField = {
		label: 'Specialty Type',
		attribute: 'specialty',
		values: []
	};

	let specialties: Specialty[] = [];
	partners?.forEach(partner => {
		specialties = specialties.concat(partner.specialties);
	});

	const specialtiesSet = new Set([...specialties]);

	specialtiesSet.forEach(specialty => {
		specialtyTypeFilter.values.push({
			label: translateThriftEnum(specialty, SPECIALTY),
			value: specialty
		});
	});

	const lastItem = translateThriftEnum(Specialty.UNLISTED, SPECIALTY);
	specialtyTypeFilter.values.sort((a, b) => {
		if (a.label === lastItem) {
			return 1;
		}
		if (b.label === lastItem) {
			return -1;
		}
		return a.label < b.label ? -1 : 1;
	});

	return specialtyTypeFilter;
};
