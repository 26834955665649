import { CaseComposite } from 'contexts';
import { Button, ButtonType, CardModal } from 'display';
import { css } from 'emotion';
import { getReadableBirthDate } from 'lib/date';
import * as React from 'react';
import Strings from '../../lib/strings';

const styles = {
	patientDemographicsLabel: css`
		font-weight: bold;
		display: inline-block;
		width: 100px;
	`
};

interface DuplicateCaseModalProps {
	duplicateCase?: CaseComposite;
	onConfirm: () => void;
	onCancel: () => void;
}

export default function DuplicateCaseModal({
	duplicateCase,
	onConfirm,
	onCancel
}: DuplicateCaseModalProps): JSX.Element {
	const ButtonVariationPrimary = Button[ButtonType.PRIMARY];
	const ButtonVariationTextPrimary = Button[ButtonType.TEXT_PRIMARY];

	const onConfirmWrapper = () => {
		onConfirm();
	};

	const patientDemographicsFields = [
		{
			displayName: 'First Name',
			attribute: 'firstName'
		},
		{
			displayName: 'Last Name',
			attribute: 'lastName'
		},
		{
			displayName: 'Patient ID',
			attribute: 'patientExternalId'
		},
		{
			displayName: 'Birth Date',
			attribute: 'dateOfBirth',
			formatter: (field: CaseComposite['patient']['dateOfBirth']) =>
				field ? getReadableBirthDate(field.year, field.month, field.day) : ''
		}
	];

	const isOpen = !!duplicateCase;

	return (
		<CardModal open={isOpen} data-testid="DuplicateCaseModal">
			<CardModal.Title>A record already exists for this patient</CardModal.Title>
			<CardModal.Description>
				Please confirm the patient&apos;s information to create a new consult.
				<br />
				{duplicateCase &&
					patientDemographicsFields.map(patientDemographicsField => (
						<div key={patientDemographicsField.attribute}>
							<span className={styles.patientDemographicsLabel}>
								{patientDemographicsField.displayName}:
							</span>{' '}
							{patientDemographicsField.formatter
								? patientDemographicsField.formatter(
										duplicateCase?.patient[patientDemographicsField.attribute]
								  )
								: duplicateCase?.patient[patientDemographicsField.attribute]}
						</div>
					))}
			</CardModal.Description>
			<Button.Group>
				<ButtonVariationTextPrimary onClick={onCancel} size={Button.Size.SMALL}>
					{Strings.Buttons.CANCEL}
				</ButtonVariationTextPrimary>
				<ButtonVariationPrimary onClick={onConfirmWrapper} size={Button.Size.SMALL}>
					Confirm and Request
				</ButtonVariationPrimary>
			</Button.Group>
		</CardModal>
	);
}
