import Link from 'components/Link';
import { Button } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Case, Consult } from 'thriftgen/api_types';

import Strings from 'lib/strings';

interface LinkToEditCasePatientDetailsButtonProps {
	caseId: Case['case_id'];
	consultId?: never;
	onClick?: () => void;
}

interface LinkToEditConsultPatientDetailsButtonProps {
	caseId?: never;
	consultId: Consult['consult_id'];
	onClick?: () => void;
}

type LinkToEditPatientDetailsButtonProps =
	| LinkToEditCasePatientDetailsButtonProps
	| LinkToEditConsultPatientDetailsButtonProps;

function LinkToEditPatientDetailsButton({
	caseId,
	consultId,
	onClick
}: LinkToEditPatientDetailsButtonProps): JSX.Element {
	function getLink(): string {
		if (caseId) {
			return RouteUrls.buildUrl(RouteUrls.Key.CASE_EDIT_PATIENT, { caseId });
		} else if (consultId) {
			return RouteUrls.buildUrl(RouteUrls.Key.CONSULT_EDIT_PATIENT, { consultId });
		}

		throw new Error('LinkToEditPatientDetailsButton requires a caseId or consultId');
	}

	return (
		<Link to={getLink()}>
			<Button.TextPrimary size={Button.Size.TINY} onClick={onClick}>
				{Strings.Buttons.EDIT_PATIENT_DETAILS}
			</Button.TextPrimary>
		</Link>
	);
}

export default LinkToEditPatientDetailsButton;
