import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { User } from 'thriftgen/api_types';
import LinkToDetailPageButton from './LinkToDetailPageButton';

interface LinkToUserDetailButtonProps {
	userId: User['user_id'];
}

function LinkToUserDetailButton({ userId }: LinkToUserDetailButtonProps): JSX.Element {
	return (
		<LinkToDetailPageButton
			fluid={true}
			link={RouteUrls.buildUrl(RouteUrls.Key.USER_DETAIL, { userId })}
		/>
	);
}

export default LinkToUserDetailButton;
