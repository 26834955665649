import RouteLoader from 'components/Routing/RouteLoader';
import * as React from 'react';
import RouteUrls, { RouteKey } from './RouteUrls';
import {
	Registry,
	RegistryConfig,
	RouteComponent,
	RouteDefinition,
	RouteDefinitionMap
} from './types';

function normalizeRoutes(routesMap: RouteDefinitionMap): RouteComponent[] {
	return Object.keys(routesMap).map(key => {
		const route: RouteDefinition = routesMap[key];
		const {
			canSafelyReload = false,
			enableTelemetry,
			exact = true,
			path,
			pageOptions = {},
			restrictedTo = null,
			isDisabled = () => false,
			routes = {}
		} = route;

		/*
		 ** We use RouterLoader as a wrapper between the component and react-loadable.
		 ** We use react-loadable to load routes lazily/async.
		 */

		RouteUrls.addUrlBuilder(key as RouteKey, path);

		if (restrictedTo !== null) {
			RouteUrls.setUrlRestriction(key as RouteKey, restrictedTo);
		}

		RouteUrls.setIsDisabled(key as RouteKey, isDisabled);

		if (!enableTelemetry) {
			RouteUrls.pathsWithTelemetryDisabled.push(path);
		}

		return {
			canSafelyReload,
			enableTelemetry,
			exact,
			pageOptions,
			path,
			shouldRestrict: () => !RouteUrls.canAccessUrl(key as RouteKey),
			routes: normalizeRoutes(routes),
			template: RouteLoader({
				loader: route.template
			})
		};
	});
}

class RouteRegistry implements Registry {
	public renderer: Registry['renderer'];
	public routes: Registry['routes'];

	constructor({ renderer, routes }: RegistryConfig) {
		this.renderer = renderer;
		this.routes = normalizeRoutes(routes);

		this.renderRoute = this.renderRoute.bind(this);
	}

	public render(): React.ReactNode {
		return Object.keys(this.routes).map(key => this.renderRoute(this.routes[key]));
	}

	private renderRoute(route: RouteComponent): React.ReactNode {
		const { exact, path, routes, template, ...rest } = route;
		const { renderer: Renderer } = this;

		const render = (url: string): React.ReactNode => {
			return (
				<Renderer
					exact={exact}
					key={url}
					path={url}
					template={template}
					routes={routes.map(this.renderRoute)}
					{...rest}
				/>
			);
		};

		if (Array.isArray(path)) {
			return path.map(render);
		} else {
			return render(path);
		}
	}
}

export default RouteRegistry;
