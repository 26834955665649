import * as React from 'react';
import { FlagProvider } from '@unleash/proxy-client-react';
import UnleashConsumer from './UnleashConsumer';

const UNLEASH_REFRESH_INTERVAL = 180000; // Every 30 mins

const config = {
	url: process.env.REACT_APP_UNLEASHED_PROXY_URL || '',
	clientKey: process.env.REACT_APP_UNLEASHED_PROXY_CLIENT_KEY || '',
	refreshInterval: UNLEASH_REFRESH_INTERVAL,
	appName: 'sitka-web',
	environment: process.env.REACT_APP_SITKA_ENV
};

function UnleashProvider() {
	if (process.env.REACT_APP_DISABLE_UNLEASH === 'true') {
		return null;
	}
	return (
		<FlagProvider config={config}>
			<UnleashConsumer />
		</FlagProvider>
	);
}

export default UnleashProvider;
