import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetConsultsRequest, GetConsultsResponse, PaginationParameters } from 'thriftgen/api_types';
import { GetConsultsRequestCamel } from 'thriftgen/thriftCamelTypes';

function getConsults(params: GetConsultsRequestCamel): GetConsultsResponse {
	const client = getCommonClient();
	const request = new GetConsultsRequest({
		page_params: new PaginationParameters(objectCamelToSnake(params.pageParams)),
		include_clinical_review: params.includeClinicalReview
	});
	return client.get_consults(request);
}

export default getConsults;
