import { CheckboxProps, Form } from 'display';
import { translateThriftEnum } from 'lib/i18n';
import * as React from 'react';
import { RedactedReason, REDACTED_REASON } from 'thriftgen/api_types';
import {
	DataFormErrors,
	DataFormFieldComponent,
	DataFormFieldComponentProps
} from '../DataFormTypes';

const FIELD_NAME = 'redactedReason';

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
const OPTIONS: Array<[RedactedReason, string]> = Object.values(RedactedReason)
	// We'll deprecate this option after the form exists.
	.filter(reason => reason !== RedactedReason.UNKNOWN)
	.map(reason => {
		return [reason, translateThriftEnum(reason, REDACTED_REASON)];
	});

const RedactedReasonField: DataFormFieldComponent = ({
	data,
	errors,
	onChange
}: DataFormFieldComponentProps): JSX.Element => {
	const value = data[FIELD_NAME];
	const hasError = errors.fields.includes(FIELD_NAME);

	function onValueChange(
		event: React.FormEvent<HTMLInputElement>,
		eventData: CheckboxProps
	): void {
		if (eventData.checked) {
			onChange(event, {
				name: FIELD_NAME,
				value: Number(eventData.value)
			});
		}
	}

	return (
		<Form.Group grouped={true}>
			{OPTIONS.map(([option, label]) => {
				const id = `${FIELD_NAME}_${option}`;
				return (
					<Form.Radio
						id={id}
						label={label}
						name={FIELD_NAME}
						key={id}
						value={option}
						checked={value === option}
						onChange={onValueChange}
						error={hasError}
					/>
				);
			})}
		</Form.Group>
	);
};

RedactedReasonField.FIELD_NAME = FIELD_NAME;
RedactedReasonField.validate = (
	data: DataFormFieldComponentProps['data'],
	required: boolean
): DataFormErrors | null => {
	const value = data[FIELD_NAME];

	if (required && !value) {
		return {
			fields: [FIELD_NAME],
			messages: ['Must select redaction reason.']
		};
	}

	return null;
};

export default RedactedReasonField;
