import * as React from 'react';

import { CancelButton } from 'components/Buttons';
import { Paragraph } from 'display';

import Launcher from './Launcher';
import InstallBrowser from './InstallBrowser';

interface ScreenRecorderSetupProps {
	onSetupCancel: React.MouseEventHandler;
	onSetupComplete: React.MouseEventHandler;
}

interface ScreenRecorderSetupState {
	isSetupComplete: boolean;
}

const DESCRIPTION =
	'Our screen capture feature enables you record yourself annotating and reviewing medical data while responding to messages.';
const TITLE = 'Get set up for screen capture';

class ScreenRecorderSetup extends React.PureComponent<
	ScreenRecorderSetupProps,
	ScreenRecorderSetupState
> {
	constructor(props: ScreenRecorderSetupProps) {
		super(props);

		this.state = {
			isSetupComplete: false
		};

		this.onExtensionInstall = this.onExtensionInstall.bind(this);
	}

	public render(): JSX.Element {
		const props = {
			actions: this.renderActionsForSetup(),
			content: this.renderContent(),
			title: TITLE
		};

		return <Launcher {...props} />;
	}

	private onExtensionInstall(): void {
		this.setState({ isSetupComplete: true });
	}

	private renderActionsForSetup(): JSX.Element[] {
		const { onSetupCancel } = this.props;

		return [<CancelButton key="cancel" onClick={onSetupCancel} />];
	}

	private renderContent(): JSX.Element {
		return (
			<React.Fragment>
				<Paragraph size={Paragraph.Size.T4}>{DESCRIPTION}</Paragraph>
				<InstallBrowser />
			</React.Fragment>
		);
	}
}

export default ScreenRecorderSetup;
