import * as React from 'react';
import { css } from 'emotion';
import { Container } from './semantic';

const styles = {
	container: css`
		background-color: white;
		padding: 1em;
	`
};

interface ContainerMainContentProps {
	children: React.ReactNode;
}

function PageContent({ children }: ContainerMainContentProps): JSX.Element {
	return (
		<Container>
			<div className={styles.container}>{children}</div>
		</Container>
	);
}

export default PageContent;
