import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { CreateVideoVisitRequest, CreateVideoVisitResponse } from 'thriftgen/api_types';
import {
	CreateVideoVisitRequestCamel,
	CreateVideoVisitResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function createVideoVisit(
	request: CreateVideoVisitRequestCamel = {}
): Promise<CreateVideoVisitResponseCamel> {
	const client = getCommonClient();
	const thriftRequest = new CreateVideoVisitRequest(objectCamelToSnake(request));
	const response: CreateVideoVisitResponse = await client.create_video_visit(thriftRequest);
	return objectSnakeToCamel(response);
}

export default createVideoVisit;
