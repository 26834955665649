import { parse } from 'query-string';
import { ConsultData } from 'thriftgen/api_types';
import getDataAssets from './getDataAssets';
import { DocumentDataAsset } from './types';

function getAssetFromSearchQuery<Asset extends DocumentDataAsset>(
	assets: Asset[],
	search: string
): Asset | undefined {
	const query = parse(search);
	const dataId = typeof query.id === 'string' ? query.id : undefined;
	return assets.find(({ id }) => dataId === id);
}

function getAssets({ consultData }: { consultData: ConsultData[] }): DocumentDataAsset[] {
	return getDataAssets(consultData);
}

export default getAssets;
export { getAssetFromSearchQuery };
