import { SendButton, TriggerRecorderIconButton } from 'components/Buttons';
import { Button, Form, RichTextEditor, RichTextEditorContentValues } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import { DataFormErrors } from '../DataFormTypes';

interface MessageData {
	richTextEditorContentValues: RichTextEditorContentValues;
}

interface MessageProps {
	data: MessageData;
	errors: DataFormErrors;
	onChange: (content: RichTextEditorContentValues) => void;
	onSubmit: () => Promise<void>;
	placeholder?: string;
}

const FIELD_NAME = 'richTextEditorContentValues';

const styles = {
	formContainer: css`
		padding-bottom: 0;
		padding-top: 1em;
	`,
	editorContainer: css`
		margin-bottom: 0.5em !important;
	`,
	sendButtonContainer: css`
		text-align: right;
	`
};

function hasError({ fields }: DataFormErrors): boolean {
	return fields.includes(FIELD_NAME);
}

function getValue(data: MessageData): RichTextEditorContentValues {
	return data[FIELD_NAME];
}

function Message({
	placeholder = 'Type a message',
	onChange,
	data,
	errors,
	onSubmit
}: MessageProps): JSX.Element {
	return (
		<Form className={styles.formContainer}>
			<Form.Field>
				<TriggerRecorderIconButton />
			</Form.Field>
			<br />
			<Form.Field className={styles.editorContainer}>
				<RichTextEditor
					placeholder={placeholder}
					onChange={onChange}
					richTextEditorContentValues={getValue(data)}
					hasError={hasError(errors)}
				/>
			</Form.Field>
			<Form.Field className={styles.sendButtonContainer}>
				<SendButton size={Button.Size.MINI} onClickAsync={onSubmit} />
			</Form.Field>
		</Form>
	);
}

Message.FIELD_NAME = FIELD_NAME;
Message.validate = (markdown: string, required: boolean): DataFormErrors | null => {
	if (required && !markdown) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please enter a message.']
		};
	}

	return null;
};

export default Message;
