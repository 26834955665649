import * as React from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { sendUpdateUserWithToast } from 'store/users';
import { UpdateUserResponse } from 'thriftgen/api_types';
import { UpdateUserRequestCamel, UserInfoCamel } from 'thriftgen/thriftCamelTypes';
import { Location, PhoneNumber, TextingPermission, UserActorFullName } from '../Fields';
import UserEditor from './UserEditor';

interface ConsumerProps {
	onDone: () => void;
}

interface StoreProps {
	actor: ReturnType<typeof selectAuthUser>;
}

interface DispatchProps {
	updateUser: (data: UpdateUserRequestCamel) => Promise<UpdateUserResponse>;
}

type EditMyAccountFormProps = StoreProps & DispatchProps & ConsumerProps;

const FIELDS = [UserActorFullName, PhoneNumber, TextingPermission, Location];

function EditMyAccountForm({ actor, onDone, updateUser }: EditMyAccountFormProps): JSX.Element {
	function onSubmit(data: UserInfoCamel): Promise<void> {
		return updateUser({
			userId: actor.user_id,
			updates: data
		}).then(onDone);
	}
	return <UserEditor onSubmit={onSubmit} onCancel={onDone} user={actor} fields={FIELDS} />;
}

const mapStoreToProps = (store: AppState): StoreProps => ({
	actor: selectAuthUser(store)
});

export default connect(mapStoreToProps, {
	updateUser: sendUpdateUserWithToast
})(EditMyAccountForm);
