import { DataFormErrors } from 'components/Forms/DataFormTypes';
import { SpecialtyNotListedMessage } from 'components/Messages';
import { Label, SelectGroupItem } from 'display';
import { css, cx } from 'emotion';
import * as React from 'react';
import { borderRadius, colors } from 'styles';
import { Specialty } from 'thriftgen/api_types';
import {
	transformSpecialtiesToSelectGroupItems,
	transformSpecialtyToSelectGroupItem
} from '../PrettySpecialties/helpers';
import { ChipSpecialtiesProps, SelectedSpecialty } from './types';

const styles = {
	header: css`
		font-size: 1.5rem;
	`,
	chip: {
		base: css`
			border-radius: ${borderRadius.l3}!important;
			padding: 0.9rem !important;
			font-size: 0.8rem !important;
			font-weight: normal !important;
		`,
		selected: css`
			background-color: ${colors.primary['50']}!important;
			color: ${colors.primary['500']}!important;
		`,
		unselected: css`
			background-color: ${colors.info['50']}!important;
			color: ${colors.info['500']}!important;
		`,
		unchosen: css`
			background-color: ${colors.info['50']}!important;
			color: ${colors.info['900']}!important;
		`
	}
};

const SPECIALTY_TITLE = 'Pick a specialty';
const FIELD_NAME = 'specialty';
const UNLISTED_FALLBACK: SelectGroupItem = transformSpecialtyToSelectGroupItem(Specialty.UNLISTED);

function getValue(data: ChipSpecialtiesProps['data']): SelectedSpecialty {
	return data[FIELD_NAME];
}

function validate(data: ChipSpecialtiesProps['data'], required: boolean): DataFormErrors | null {
	const errors: DataFormErrors = {
		fields: [],
		messages: []
	};
	if (required && data[FIELD_NAME] === undefined) {
		errors.fields.push(FIELD_NAME);
		errors.messages.push('Please select a specialty.');
		return errors;
	}
	return null;
}

function ChipSpecialties({
	editableFields,
	data,
	onChange,
	specialties
}: ChipSpecialtiesProps): JSX.Element | null {
	const specialtyGroup: SelectGroupItem[] = transformSpecialtiesToSelectGroupItems(specialties);

	const specialtyChips = specialtyGroup.map(specialty => {
		return (
			<Label
				className={cx(styles.chip.base, {
					[styles.chip.selected]: getValue(data) === specialty.value,
					[styles.chip.unchosen]: getValue(data) !== specialty.value
				})}
				as="a"
				key={specialty.value}
				onClick={() => onSelected(specialty.value)}>
				{specialty.label}
			</Label>
		);
	});

	const NoResults = (
		<React.Fragment>
			<Label
				className={cx(styles.chip.base, {
					[styles.chip.selected]: getValue(data) === UNLISTED_FALLBACK.value,
					[styles.chip.unchosen]: getValue(data) !== UNLISTED_FALLBACK.value
				})}
				as="a"
				key={UNLISTED_FALLBACK.value}
				onClick={() => onSelected(UNLISTED_FALLBACK.value)}>
				{UNLISTED_FALLBACK.label}
			</Label>
			<SpecialtyNotListedMessage />
		</React.Fragment>
	);

	function onSelected(selected: number): void {
		onChange(null as never, { name: FIELD_NAME, value: selected });
	}

	if (editableFields.includes(FIELD_NAME)) {
		if (specialties.length) {
			return (
				<React.Fragment>
					<h2 className={styles.header}>{SPECIALTY_TITLE}</h2>
					<Label.Group>{specialtyChips}</Label.Group>
				</React.Fragment>
			);
		} else {
			return (
				<React.Fragment>
					<h2 className={styles.header}>{SPECIALTY_TITLE}</h2>
					{NoResults}
				</React.Fragment>
			);
		}
	}

	return null;
}

ChipSpecialties.FIELD_NAME = FIELD_NAME;
ChipSpecialties.validate = validate;

export default ChipSpecialties;
