import * as React from 'react';

import { Message, Paragraph } from 'display';

interface ConsultCreationErrorProps {
	message: string;
}

function ConsultCreationError({ message }: ConsultCreationErrorProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>
				An error was encountered while creating the consultation.
			</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export { ConsultCreationError };
