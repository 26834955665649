import * as React from 'react';
import PropTypes from 'prop-types';

import InputWrapper from './InputWrapper';

// These attributes are string based, not booleans.
const DIR_ATTRIBUTES = {
	webkitdirectory: 'true',
	mozdirectory: 'true',
	msdirectory: 'true',
	odirectory: 'true',
	directory: 'true'
};

function FolderInput({ id, inputProps, onFileChange, ...rest }) {
	if (id === undefined) {
		throw new Error('FolderInput must take a unique id');
	}

	return (
		<InputWrapper htmlFor={id} {...rest}>
			<input
				id={id}
				name={id}
				onChange={({ target }) => onFileChange(Array.from(target.files))}
				type="file"
				{...DIR_ATTRIBUTES}
				{...inputProps}
			/>
		</InputWrapper>
	);
}

FolderInput.isInputDirSupported = function isInputDirSupported() {
	const input = document.createElement('input');
	return (
		'webkitdirectory' in input ||
		'mozdirectory' in input ||
		'odirectory' in input ||
		'msdirectory' in input ||
		'directory' in input
	);
};

FolderInput.defaultProps = {
	inputProps: {},
	label: 'Upload folder'
};

FolderInput.propTypes = {
	id: PropTypes.string.isRequired,
	inputProps: PropTypes.object,
	label: PropTypes.string,
	onFileChange: PropTypes.func.isRequired
};

export default FolderInput;
