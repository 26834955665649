import {
	DataFormConditionalField,
	DataFormConditionalFieldAction,
	DataFormErrors,
	DataFormFieldComponent
} from './DataFormTypes';
import { SMAResponseFormData } from './SMAResponseFormSubsection';

function validate<DataType>(
	fields: DataFormFieldComponent[],
	requiredFields: string[],
	data: DataType
): DataFormErrors | null {
	const formErrors = fields.reduce(
		(allErrors: DataFormErrors, field: DataFormFieldComponent): DataFormErrors => {
			const fieldErrors = field.validate(data, requiredFields.includes(field.FIELD_NAME));

			if (fieldErrors) {
				return {
					fields: [...allErrors.fields, ...fieldErrors.fields],
					messages: [...allErrors.messages, ...fieldErrors.messages]
				};
			}

			return allErrors;
		},
		{ fields: [], messages: [] }
	);

	if (formErrors.fields.length > 0 || formErrors.messages.length > 0) {
		return formErrors;
	}

	return null;
}

function determineDisplayFields(
	conditionalFields: DataFormConditionalField[],
	data: SMAResponseFormData[],
	fields: DataFormFieldComponent[]
): DataFormFieldComponent[] {
	const fieldsToHide = conditionalFields.map(conditionalField => {
		const fieldData = data[conditionalField.fieldName];
		const fieldDataMatches = conditionalField.values.includes(fieldData);
		const isShowConditionMet =
			conditionalField.action === DataFormConditionalFieldAction.SHOW && !fieldDataMatches;
		const isHideConditionMet =
			conditionalField.action === DataFormConditionalFieldAction.HIDE && fieldDataMatches;
		if (isShowConditionMet || isHideConditionMet) {
			return conditionalField.conditionalFieldName;
		}
		return;
	});
	const fieldsToDisplay = fields.filter(field => !fieldsToHide.includes(field.FIELD_NAME));

	return fieldsToDisplay;
}

export { validate, determineDisplayFields };
