import * as React from 'react';
import PropTypes from 'prop-types';
import MaskedInput from 'react-text-mask';
import { phoneRegex } from 'lib/regex';

import { Form, Icon, PhoneNumber } from 'display';

const noop = () => {};
const FIELD_NAME = 'phoneNumber';
const phoneNumberMask = [
	'(',
	/[1-9]/,
	/\d/,
	/\d/,
	')',
	' ',
	/\d/,
	/\d/,
	/\d/,
	'-',
	/\d/,
	/\d/,
	/\d/,
	/\d/
];

function validate(data, required) {
	const phoneNumber = data[FIELD_NAME];

	if (
		(required && !phoneNumber) ||
		(phoneNumber && phoneNumber.length > 0 && !phoneRegex.test(phoneNumber))
	) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please enter a valid phone number.']
		};
	}

	return null;
}

class PhoneNumberField extends React.Component {
	static FIELD_NAME = FIELD_NAME;
	static validate = validate;

	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	canEdit() {
		const { editableFields } = this.props;

		return editableFields.includes(FIELD_NAME);
	}

	conformValue(value) {
		return value.replace(/\D+/g, '');
	}

	getValue() {
		const { data } = this.props;

		return data[FIELD_NAME];
	}

	hasError() {
		const { errors } = this.props;
		const { fields } = errors;

		return fields.includes(FIELD_NAME);
	}

	onChange({ currentTarget }) {
		this.props.onChange(null, {
			name: FIELD_NAME,
			value: this.conformValue(currentTarget.value)
		});
	}

	render() {
		const { alwaysShow, label } = this.props;
		const value = this.getValue();

		if (this.canEdit()) {
			return (
				<Form.Field error={this.hasError()}>
					<label htmlFor={FIELD_NAME}>{label}</label>
					<Form.Input id={FIELD_NAME} name={FIELD_NAME} width={8}>
						<MaskedInput
							mask={phoneNumberMask}
							guide={true}
							id={FIELD_NAME}
							name={FIELD_NAME}
							placeholder="(800) 867-5309"
							type="text"
							onChange={this.onChange}
							onBlur={this.props.onBlur}
							value={value || ''}
						/>
					</Form.Input>
				</Form.Field>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<Icon name="phone" />
					<PhoneNumber number={value} />
				</Form.Field>
			);
		}

		if (alwaysShow) {
			return (
				<Form.Field>
					<Icon name="phone" />
					<span>[none provided]</span>
				</Form.Field>
			);
		}

		return null;
	}
}

PhoneNumberField.defaultProps = {
	alwaysShow: false,
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	label: 'Phone number',
	onChange: noop
};

PhoneNumberField.propTypes = {
	alwaysShow: PropTypes.bool,
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	label: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func
};

export default PhoneNumberField;
