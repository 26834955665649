import * as React from 'react';
import { Partner } from 'thriftgen/api_types';
import DataTable, { DataTableColumn } from '../DataTable';
import { partnerColumns } from './columns';

interface PartnerTableProps {
	columns?: Array<DataTableColumn<Partner>>;
	emptyMessage?: string;
	partners: Partner[];
}

const COLUMNS = [partnerColumns.buildName(), partnerColumns.buildLinkToDetail()];
const EMPTY_MESSAGE = 'No partners found at this time.';

function PartnerTable({
	columns = COLUMNS,
	emptyMessage = EMPTY_MESSAGE,
	partners
}: PartnerTableProps): JSX.Element {
	return (
		<DataTable<Partner>
			emptyMessage={emptyMessage}
			columns={columns}
			data={partners}
			data-testid="PartnerTable"
		/>
	);
}

PartnerTable.Columns = partnerColumns;
export default PartnerTable;
