import { ExternalDocumentSettings } from 'components/ConsultationMediaInput';
import {
	Button,
	Checkbox,
	CheckboxProps,
	Divider,
	Header,
	Loader,
	Message,
	Tooltip,
	Grid
} from 'display';
import { css } from 'emotion';
import useAthenaGetPatientDocumentListQuery from 'hooks/queries/useAthenaGetPatientDocumentListQuery';
import { SitkaLogger } from 'lib/sitkaLogger';
import * as React from 'react';
import { colors } from 'styles';
import { IntegrationType } from 'thriftgen/api_types';
import { AthenaDocumentCamel, ExternalDocumentCamel } from 'thriftgen/thriftCamelTypes';
import Paragraph from '../Paragraph';
import Strings from 'lib/strings';
import { isFeatureEnabled, Feature } from 'lib/feature';

const styles = {
	documentListWrapper: css`
		background-color: ${colors.white};}
		padding: 1em;`,
	documentListItem: css`
		padding: 0 0.5em;
	`,
	documentListHeader: css`
		margin-bottom: 0.5em !important;
	`
};

interface FileInputAthenaProps {
	onDocumentChange: (documents: ExternalDocumentCamel[]) => void;
	selectedDocuments: ExternalDocumentCamel[];
	externalDocumentSettings: ExternalDocumentSettings;
}

function FileInputAthena({
	onDocumentChange,
	selectedDocuments,
	externalDocumentSettings
}: FileInputAthenaProps): JSX.Element {
	const { documents, error, revalidate } = useAthenaGetPatientDocumentListQuery(
		externalDocumentSettings.athenaSettings
	);

	const attachableDocuments = React.useMemo((): AthenaDocumentCamel[] => {
		let deduplicatedDocuments = documents;
		externalDocumentSettings.athenaSettings.previouslyAttachedDocuments.forEach(
			previouslyAttachedDocument => {
				deduplicatedDocuments = deduplicatedDocuments?.filter(
					deduplicatedDocument =>
						!(
							deduplicatedDocument.type === previouslyAttachedDocument.documentType &&
							deduplicatedDocument.documentId ===
								+previouslyAttachedDocument.documentId
						)
				);
			}
		);
		return deduplicatedDocuments || [];
	}, [documents, externalDocumentSettings.athenaSettings.previouslyAttachedDocuments]);

	function retryDocumentRetrieval() {
		revalidate();
	}

	if (error) {
		SitkaLogger.logMessage(error);
		return (
			<Message negative>
				<div>{Strings.Errors.ATHENA_FILE_INPUT_ERROR}</div>
				<Button.Text onClick={retryDocumentRetrieval}>
					{Strings.Errors.ATHENA_FILE_INPUT_TRY_AGAIN}
				</Button.Text>
			</Message>
		);
	}

	if (!documents) {
		return <Loader active inline />;
	}

	function handleChange(athenaDoc: AthenaDocumentCamel, data: CheckboxProps): void {
		let newSelectedDocuments: ExternalDocumentCamel[];
		if (data.checked) {
			newSelectedDocuments = [
				...selectedDocuments,
				{
					integrationType: IntegrationType.ATHENA,
					athenaDoc: athenaDoc
				}
			];
		} else {
			newSelectedDocuments = selectedDocuments.filter(
				({ athenaDoc }) => data.value && athenaDoc.documentId !== +data.value
			);
		}
		onDocumentChange(newSelectedDocuments);
	}

	function isDocumentSelected(documentId: number): boolean {
		return !!selectedDocuments.filter(({ athenaDoc }) => +athenaDoc.documentId === +documentId)
			.length;
	}

	function renderCheckbox(athenaDocument: AthenaDocumentCamel): JSX.Element {
		return (
			<div key={athenaDocument.documentId} className={styles.documentListItem}>
				<Checkbox
					checked={isDocumentSelected(athenaDocument.documentId)}
					id={athenaDocument.documentId}
					label={athenaDocument.description}
					name={`${athenaDocument.documentId}`}
					value={`${athenaDocument.documentId}`}
					onChange={(_: React.SyntheticEvent, data: CheckboxProps) =>
						handleChange(athenaDocument, data)
					}
				/>
				<Divider />
			</div>
		);
	}

	function renderHelpPopoverTrigger(): JSX.Element {
		return (
			<span>
				<Button.Icon icon="question circle" />
				<Button.Text icon="question circle" size={'mini'}>
					{Strings.Labels.ATHENA_FILE_INPUT_HELP_LABEL}
				</Button.Text>
			</span>
		);
	}

	function renderHelpPopover(): JSX.Element {
		return (
			<React.Fragment>
				{isFeatureEnabled(Feature.CWA_1537_ATHENA_ATTACHMENT_HELP) && (
					<Tooltip trigger={renderHelpPopoverTrigger()}>
						{Strings.Sentences.ATHENA_FILE_INPUT_HELP_CONTENT.map(sentence => (
							<Paragraph key={sentence}>{sentence}</Paragraph>
						))}
					</Tooltip>
				)}
			</React.Fragment>
		);
	}

	return (
		<div className={styles.documentListWrapper}>
			{attachableDocuments.length > 0 ? (
				<React.Fragment>
					<Grid columns="two" className={styles.documentListHeader}>
						<Grid.Row>
							<Grid.Column width={8}>
								<Header>{Strings.Labels.ATHENA_FILE_INPUT_INSTRUCTIONS}</Header>
							</Grid.Column>
							<Grid.Column width={8} textAlign={'right'}>
								{renderHelpPopover()}
							</Grid.Column>
						</Grid.Row>
					</Grid>

					{attachableDocuments.map(document => renderCheckbox(document))}
				</React.Fragment>
			) : (
				<div>{Strings.Labels.ATHENA_FILE_INPUT_EMPTY}</div>
			)}
		</div>
	);
}

export default FileInputAthena;
