import * as React from 'react';
import { Security } from '@okta/okta-react';
import { sitkaOktaAuth } from 'lib/okta';
import { useHistory } from 'react-router-dom';
import { toRelativeUrl } from '@okta/okta-auth-js';

interface OktaSecurityProviderProps {
	children: JSX.Element;
}

// This component sets up Okta's Security component
// which is responsible for interacting with Okta's hosted login page
function OktaSecurityProvider({ children }: OktaSecurityProviderProps): JSX.Element {
	const history = useHistory();
	const restoreOriginalUri = async (_oktaAuth: unknown, originalUri: string) => {
		history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
	};

	return (
		<Security restoreOriginalUri={restoreOriginalUri} oktaAuth={sitkaOktaAuth}>
			{children}
		</Security>
	);
}

export default OktaSecurityProvider;
