import { apiCallerWithToast, apiCaller } from 'store/requests';
import { getConsults, manageConsult } from 'api/common';

import { handleIncomingConsult, handleIncomingConsults } from './consults.actions';

export const sendGetConsults = apiCaller({
	apiCall: getConsults,
	action: handleIncomingConsults
});

export const sendManageConsult = apiCaller({
	apiCall: manageConsult,
	action: handleIncomingConsult
});

export const sendManageConsultWithToast = apiCallerWithToast({
	apiCall: manageConsult,
	action: handleIncomingConsult
});
