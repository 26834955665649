import {
	PaginationFilterPopoverProps,
	PaginationFilterPopover
} from 'components/PaginationFilterPopover';
import * as React from 'react';

function ParameterFilterPopoverControls({ filters }: PaginationFilterPopoverProps): JSX.Element {
	return <PaginationFilterPopover filters={filters} />;
}

export default ParameterFilterPopoverControls;
