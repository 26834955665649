import { Specialty, SPECIALTY, SPECIALTIES_INACTIVE } from 'thriftgen/api_types.js';
import buildSelectField, { buildSelectFieldOptions } from './base/buildSelectField';

const FIELD_NAME = 'referralSpecialty';
const LABEL = 'Select specialty for referral';
const PLACEHOLDER = 'Select a specialty';
const VALIDATION_MESSAGE = 'Please select a specialty.';

let options = buildSelectFieldOptions(Specialty, SPECIALTY);
options = options.filter(({ value }) => value && !SPECIALTIES_INACTIVE.includes(+value));

const ReferralSpecialty = buildSelectField({
	fieldName: FIELD_NAME,
	label: LABEL,
	placeholder: PLACEHOLDER,
	options,
	validationMessage: VALIDATION_MESSAGE
});

export default ReferralSpecialty;
