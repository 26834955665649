import { LogoutButton, StaySignedInButton } from 'components/Buttons';
import { Button, CardModal, Message } from 'display';
import { css } from 'emotion';
import { millisecondsToMinutesAndSeconds } from 'lib/date';
import React, { useState } from 'react';
import { colors } from 'styles';

interface SessionExpiryModalProps {
	errorMessage: string | null;
	onContinue: () => Promise<void>;
	onLogout: () => Promise<void>;
	millisecondsLeft: number;
	open: boolean;
}

const styles = {
	title: css`
		color: ${colors.primary.base};
	`
};

interface DisabledControls {
	logout: boolean;
	staySignedIn: boolean;
}

function SessionExpiryModal({
	errorMessage,
	millisecondsLeft,
	onContinue,
	onLogout,
	open
}: SessionExpiryModalProps): JSX.Element {
	const [isDisabled, setIsDisabled] = useState<DisabledControls>({
		logout: false,
		staySignedIn: false
	});

	const disableLogout = () =>
		setIsDisabled(current => ({
			...current,
			logout: true
		}));

	const enableLogout = () =>
		setIsDisabled(current => ({
			...current,
			logout: false
		}));

	const disableStaySignedIn = () =>
		setIsDisabled(current => ({
			...current,
			staySignedIn: true
		}));

	const enableStaySignedIn = () =>
		setIsDisabled(current => ({
			...current,
			staySignedIn: false
		}));

	function onStaySignedInClick(): Promise<void> {
		disableLogout();
		return onContinue().then(enableLogout).catch(enableLogout);
	}

	function onLogoutClick(): Promise<void> {
		disableStaySignedIn();
		return onLogout().then(enableStaySignedIn).catch(enableStaySignedIn);
	}

	return (
		<CardModal open={open} data-testid="sessionExpiryModal">
			<CardModal.Title size={CardModal.Title.Size.T1}>
				<span className={styles.title}>
					{millisecondsToMinutesAndSeconds(millisecondsLeft)}
				</span>
			</CardModal.Title>
			<CardModal.Description>
				Your session is about to end.
				<br />
				<br />
				To continue working, select &ldquo;Stay signed in&rdquo;. Otherwise you will be
				logged out automatically.
			</CardModal.Description>
			{errorMessage !== null && <Message negative content={errorMessage} />}
			<Button.Group>
				<LogoutButton disabled={isDisabled.logout} onClickAsync={onLogoutClick} />
				<StaySignedInButton
					disabled={isDisabled.staySignedIn}
					onClickAsync={onStaySignedInClick}
				/>
			</Button.Group>
		</CardModal>
	);
}

export default SessionExpiryModal;
