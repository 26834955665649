import { DictationResults } from './types';
import ProcessingStepLogEntry from './TranscriptionLogger/ProcessingStepLogEntry';
import { Matcher, RegexError, RegexSearchInfo } from './TranscriptionLogger/types';
import TranscriptionLogger from './TranscriptionLogger/TranscriptionLogger';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import Strings from 'lib/strings';

enum Patterns {
	EMPTY_STRING = '""'
}

export default class TranscriptionProcessorService {
	completeProcessedText: string;
	completeTextFromAmazon: string;
	// keep preexisting text separate to avoid processing text more than once
	preExistingText: string;
	transcriptionLogger: TranscriptionLogger;

	constructor() {
		this.completeProcessedText = '';
		this.completeTextFromAmazon = '';
		this.preExistingText = '';
		this.transcriptionLogger = new TranscriptionLogger();
	}

	public prepareForTranscribing = (existingText: string): string => {
		this.completeProcessedText = '';
		this.completeTextFromAmazon = '';

		this.transcriptionLogger.prepLoggerForTranscribing();

		const updatedExistingText = this.removeDisclaimer(existingText);
		this.preExistingText = updatedExistingText === '<p></p>' ? '' : updatedExistingText;

		this.transcriptionLogger.setSessionLogPreExistingText(this.preExistingText);

		return this.preExistingText;
	};

	/**
	 * Takes a DictationResults object and feeds the transcription through the functions defined in executeFunctions,
	 * then returns a new DictationResults object with the updated transcript
	 * @param dictationResults
	 */
	public processText = (dictationResults: DictationResults): DictationResults => {
		const { transcript, isPartial } = dictationResults;

		this.transcriptionLogger.handleNewProcessingIteration(transcript);

		const processedText = this.processTextBlock(transcript);

		this.transcriptionLogger.processingIterationLogEntry.processedTranscript = processedText;

		if (!isPartial && transcript !== '') {
			this.completeTextFromAmazon += transcript + ' ';
			this.completeProcessedText += processedText + ' ';

			this.transcriptionLogger.handleNewFinalizedTextBlock(transcript, processedText);

			return {
				transcript: this.preExistingText + ' ' + this.completeProcessedText + ' ',
				isPartial
			};
		} else {
			return {
				transcript: this.preExistingText + ' ' + this.completeProcessedText + processedText,
				isPartial
			};
		}
	};

	public getFinalizedText = () => {
		this.transcriptionLogger.transcriptionSessionLog.completeTextFromAmazon =
			this.completeTextFromAmazon;
		this.transcriptionLogger.transcriptionSessionLog.completeProcessedText =
			this.completeProcessedText;

		SitkaLogger.logMessage(
			{ transcriptionLog: JSON.stringify(this.transcriptionLogger.getLog()) },
			LogEventName.TRANSCRIPTION_LOG
		);

		return this.addDisclaimer(this.preExistingText + this.completeProcessedText);
	};

	processTextBlock = (transcript: string): string => {
		return this.functionReducer(
			transcript,
			this.filterOutWords,
			this.removeExtraSpaces,
			this.replaceAWSPunctuationWithPlaceholder,
			this.removePuncPlaceholderAndFixCapitalization,
			this.handleAcronyms,
			this.fixAbbreviations,
			this.replaceSymbolText,
			this.addSpacesAfterPunctuation,
			this.removeSpacesBetweenPunctuation,
			this.capitalizeAfterPunctuation,
			this.removeExtraSpaces,
			this.processNewParagraphs,
			this.processNumberedParagraphs,
			this.fixCapitalizations
		);
	};

	functionReducer = (
		transcript: string,
		...funcs: { (transcript: string): string }[]
	): string => {
		return funcs.reduce((accumulator, func) => {
			return func(accumulator);
		}, transcript);
	};

	/**
	 * Takes a transcript and an array of Matcher objects and applies the search and replace values
	 * defined in each Matcher object to the transcript one by one
	 * @param {string} transcript - text to transform
	 * @param { Matcher[] } matchersArray - array of Matcher objects defining search and replace values
	 */
	performFindAndReplaceOperations = (transcript: string, matchersArray: Matcher[]) => {
		let updatedTranscript = transcript;
		const searches = [] as RegexSearchInfo[];
		const errors: RegexError[] = [];

		matchersArray.forEach(matcherItem => {
			matcherItem.searchValues.forEach(searchValue => {
				const matches = transcript.matchAll(searchValue);
				const matchesArray = [...matches];
				if (matchesArray.length > 0) {
					searches.push({
						regex: searchValue.toString(),
						replaceValue: matcherItem.replaceValue,
						matches: matchesArray
					});
				}

				matchesArray.forEach(match => {
					try {
						updatedTranscript = updatedTranscript.replace(
							match[0],
							Function('match', `return ${matcherItem.replaceValue}`)(match)
						);
					} catch (e) {
						errors.push({ regex: searchValue.toString(), error: e.toString() });
						SitkaLogger.logMessage(
							{ error: e, regex: searchValue },
							LogEventName.DICTATION
						);
					}
				});
			});
		});

		return { searches, updatedTranscript, errors };
	};

	/**
	 * Filters out words from the transcript that are most likely from background noise
	 * @example
	 * filterOutWords("Uh this is a test mhm okay period and this is um still a test yeah. oh")
	 * // returns 'this is a test period and this is still a test .'
	 * @param { string } transcript
	 * @returns { string }
	 */
	filterOutWords = (transcript: string): string => {
		const matchersArray = [
			{
				searchValues: [/\b *(um|uh|oh|okay|mhm|yeah)/gim],
				replaceValue: Patterns.EMPTY_STRING
			}
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'filterOutWords',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	// We can't simply strip out punctuation in one step, then convert all letters to lowercase to fix
	// Amazon's resulting incorrect capitalizations. Instead of relying on clever, convoluted, and slow regex lookaheads
	// we can simply replace AWS punctuation with a unique placeholder string in this step and then
	// use that placeholder in a later step to identify the incorrect capitalizations and correct them.
	/**
	 * Replaces AWS's punctuation with a unique placeholder string for future processing, except for punctuation in numbers
	 * @example
	 * replaceAWSPunctuationWithPlaceholder('this, is a test! 20,000 with weird: punctuation that * shouldn't. be there')
	 * // returns 'this &#169; is a test &#169; 20,000 with weird &#169; punctuation that  &#169; shouldn't &#169; be there'
	 * @param { string } transcript
	 * @returns { string }
	 */
	replaceAWSPunctuationWithPlaceholder = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/(?!\d)[.,&!;:*"?](?!\d)/gim], replaceValue: '" &#169;"' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'replaceAWSPunctuationWithPlaceholder',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 *
	 * Looks for a placeholder value and the first letter after the placeholder, removes the placeholder,
	 * and lowercases that first letter (excepting acronyms)
	 * @example
	 * removePuncPlaceholderAndFixCapitalization('this &#169; is a test &#169; with weird &#169; punctuation that  &#169; shouldn't &#169; BE there')
	 * // returns 'this Is a test With weird Punctuation that  Shouldn't BE there'
	 * @param { string } transcript
	 * @returns { string }
	 */
	removePuncPlaceholderAndFixCapitalization = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/ &#169;( \w)(?![A-Z])/gm], replaceValue: 'match[1].toLowerCase()' },
			{ searchValues: [/ &#169;/gim], replaceValue: Patterns.EMPTY_STRING }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'removePuncPlaceholderAndFixCapitalization',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Removes all groups of more than one space character down to a single space character
	 * @example
	 * removeExtraSpaces("this  is    a test!")
	 * // returns "this is a test!"
	 * @param { string } transcript
	 * @returns { string }
	 */
	removeExtraSpaces = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/ {2,}/gim], replaceValue: '" "' },
			{ searchValues: [/^ (\w)/gim], replaceValue: 'match[0]' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'removeExtraSpaces',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Replaces text representations of symbols with their corresponding symbols and appropriate white space
	 * @example
	 * replaceSymbolText("this open parenthesis is close parenthesis a test period can you get me food comma a drink dash a dr. pepper please dash comma and a plate question mark");
	 * // returns "this (is) a test. can you get me food, a drink - a Dr. pepper please -, and a plate?"
	 * @param { string } transcript
	 * @returns { string }
	 */
	replaceSymbolText = (transcript: string): string => {
		// ORDER IS IMPORTANT!!!
		const matchersArray = [
			{ searchValues: [/\bi /gm], replaceValue: '"I "' },
			{ searchValues: [/\bi'm\b/gm], replaceValue: '"I\'m"' },
			{ searchValues: [/\b(open|left) +parenthes[ie]s */gim], replaceValue: '"("' },
			{ searchValues: [/\b *(close|right) +parenthes[ie]s/gim], replaceValue: '")"' },
			{ searchValues: [/\b(hyphen|dash)\b/gim], replaceValue: '"-"' },
			{ searchValues: [/\b *comma/gim], replaceValue: '","' },
			{ searchValues: [/\b *s[ei]mi ?colon/gim], replaceValue: '";"' },
			{ searchValues: [/\b *colon/gim], replaceValue: '":"' },
			{ searchValues: [/\b *apostrophe */gim], replaceValue: '"\'"' },
			{ searchValues: [/\bampersand/gim], replaceValue: '"&"' },
			{ searchValues: [/\b *percent/gim], replaceValue: '"%"' },
			{ searchValues: [/\b *question +mark/gim], replaceValue: '"?"' },
			{ searchValues: [/\b *exclamation +(point|mark)+/gim], replaceValue: '"!"' },
			{
				searchValues: [/\b *backslash */gim],
				replaceValue: '"\\\\"' //four are needed here, two escaped sets because the Function later removes one pair
			},
			{ searchValues: [/\b *forward slash */gim, /\b *slash */gim], replaceValue: '"/"' },
			{ searchValues: [/\b *ellipsis/gim], replaceValue: '"..."' },
			{ searchValues: [/\b *period/gim], replaceValue: '"."' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'replaceSymbolText',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	processNumberedParagraphs = (transcript: string): string => {
		const replaceValuePrefix = this.completeProcessedText === '' ? '' : '</br>';
		let returnVal;

		if (/number/im.test(transcript)) {
			// only bother iterating through all this if the word "number" appears somewhere in the text
			const matchersArray = [
				{
					searchValues: [/number (\d+)/gim],
					replaceValue: '`' + replaceValuePrefix + '${match[1]}).' + '`'
				},
				{ searchValues: [/number one/gim], replaceValue: `"${replaceValuePrefix}1)."` },
				{ searchValues: [/number two/gim], replaceValue: `"${replaceValuePrefix}2)."` },
				{ searchValues: [/number three/gim], replaceValue: `"${replaceValuePrefix}3)."` },
				{ searchValues: [/number four/gim], replaceValue: `"${replaceValuePrefix}4)."` },
				{ searchValues: [/number five/gim], replaceValue: `"${replaceValuePrefix}5)."` },
				{ searchValues: [/number six/gim], replaceValue: `"${replaceValuePrefix}6)."` },
				{ searchValues: [/number seven/gim], replaceValue: `"${replaceValuePrefix}7)."` },
				{ searchValues: [/number eight/gim], replaceValue: `"${replaceValuePrefix}8)."` },
				{ searchValues: [/number nine/gim], replaceValue: `"${replaceValuePrefix}9)."` }
			] as Matcher[];

			const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
				transcript,
				matchersArray
			);

			this.transcriptionLogger.addStepLogEntry(
				new ProcessingStepLogEntry(
					'processNumberedParagraphs',
					transcript,
					updatedTranscript,
					searches,
					errors
				)
			);

			returnVal = updatedTranscript;
		} else {
			returnVal = transcript;
		}

		return returnVal;
	};

	fixAbbreviations = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/\bdr(\.)/gim], replaceValue: '"Dr."' },
			{ searchValues: [/\bms(\.)/gim], replaceValue: '"Ms."' },
			{ searchValues: [/\bmrs(\.)/gim], replaceValue: '"Mrs."' },
			{ searchValues: [/\bmr(\.)/gim], replaceValue: '"Mr."' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'fixAbbreviations',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Converts "new|next paragraph|line" to line break character that will automatically be turned into
	 * paragraph tags wrapping the text following the line break
	 * @example
	 * processNewParagraph("The next paragraphpatient needsnew paragraph help new line");
	 * // returns "The</br>patient needs</br>help</br>"
	 * @param { string } transcript
	 * @returns { string }
	 */
	processNewParagraphs = (transcript: string): string => {
		const matchersArray = [
			{
				searchValues: [/ ?(?:new|next) (?:line(s)?|paragraph(s)?)\.? ?/gim],
				replaceValue: '"</br>"'
			}
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'processNewParagraphs',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Capitalizes letters after punctuation characters in the transcript
	 * @example
	 * capitalizeAfterPunctuation("can you see this? yes I can. can you?");
	 * // returns "can you see this? Yes I can. Can you?"
	 * @param { string } transcript
	 * @returns { string }
	 */
	capitalizeAfterPunctuation = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/[.?!] ([a-z])/gm], replaceValue: 'match[0].toUpperCase()' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'capitalizeAfterPunctuation',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Fixes various situations where letters aren't otherwise being capitalized properly
	 * @example
	 * fixCapitalizations("- this is a test");
	 * // returns "- This is a test"
	 * @example
	 * fixCapitalizations("this is a test");
	 * // returns "This is a test"
	 * @example
	 * fixCapitalizations("</br>- this is a test");
	 * // returns "</br>- This is a test"
	 * @example
	 * fixCapitalizations("</br>this is a test");
	 * // returns "</br>This is a test"
	 * @param { string } transcript
	 * @returns { string }
	 */
	fixCapitalizations = (transcript: string): string => {
		const matchersArray = [
			{
				searchValues: [/^(- [a-z])/gm, /^([a-z])/gm, /\).( \w)/gim],
				replaceValue: `match[0].toUpperCase()`
			},
			{
				searchValues: [/(<\/br>)(- [a-z])/gm, /(<\/br>)([a-z])/gm],
				replaceValue: `match[1] + match[2].toUpperCase()`
			}
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'fixCapitalizations',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Adds spaces between punctuation characters and letters in the transcript
	 * @example
	 * addSpacesAfterPunctuation("this is a test.hello");
	 * // returns "this is a test. hello"
	 * @param { string } transcript
	 * @returns { string }
	 */
	addSpacesAfterPunctuation = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/([?.!)])(\S)/gm], replaceValue: '`${match[1]} ${match[2]}`' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'addSpacesAfterPunctuation',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Removes spaces between punctuation in the transcript
	 * @example
	 * removeSpacesAfterPunctuation("this is a test. ! ? hello");
	 * // returns "this is a test.!? hello"
	 * @param { string } transcript
	 * @returns { string }
	 */
	removeSpacesBetweenPunctuation = (transcript: string): string => {
		const matchersArray = [
			{ searchValues: [/([?.!()]) (?=[?.!()])/gim], replaceValue: 'match[1]' }
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'removeSpacesBetweenPunctuation',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Searches in the transcript for series of single letters with spaces between them,
	 * removes the spaces, and capitalizes the letters
	 * @example
	 * handleAcronyms("history of g e r d atypical chest pain, but isn't a case of h T N period");
	 * // returns "history of GERD atypical chest pain, but isn't a case of HTN period"
	 * @param { string } transcript
	 * @returns { string }
	 */
	handleAcronyms = (transcript: string): string => {
		const matchersArray = [
			{
				searchValues: [/\b(([a-z][ ,!?.)]){2,})[a-z]$/gim],
				replaceValue: '`${match[0].replaceAll(" ", "").toUpperCase()}`'
			},
			{
				searchValues: [/(\w )(([a-z][ ,!?.)]){2,})/gim],
				replaceValue: '`${match[1]}${match[2].replaceAll(" ", "").toUpperCase()} `'
			}
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'handleAcronyms',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};

	/**
	 * Given a boolean, returns the disclaimer text string in either string form or RegExp form
	 * @param { boolean } inRegexForm
	 * @returns { string | RegExp}
	 */
	getDisclaimerAddendum = (inRegexForm = false): string | RegExp => {
		const disclaimerAddendumText = Strings.Sentences.DICTATION_DISCLAIMER;

		if (inRegexForm) {
			return new RegExp(`<p><strong>\\${disclaimerAddendumText}</strong></p>`, 'gmi');
		} else {
			return `<p><strong>${disclaimerAddendumText}</strong></p>`;
		}
	};

	/**
	 * Adds disclaimer text to the transcript text
	 * @param { string } transcript
	 * @returns { string }
	 */
	addDisclaimer = (transcript: string): string => {
		const updatedTranscript = transcript + this.getDisclaimerAddendum();

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'addDisclaimer',
				transcript,
				updatedTranscript,
				undefined,
				[]
			)
		);

		return updatedTranscript;
	};

	/**
	 * Removes the dictation disclaimer from the editor text
	 * @param { string } transcript
	 */
	removeDisclaimer = (transcript: string): string => {
		const matchersArray = [
			{
				searchValues: [this.getDisclaimerAddendum(true)],
				replaceValue: Patterns.EMPTY_STRING
			}
		] as Matcher[];

		const { searches, updatedTranscript, errors } = this.performFindAndReplaceOperations(
			transcript,
			matchersArray
		);

		this.transcriptionLogger.addStepLogEntry(
			new ProcessingStepLogEntry(
				'removeDisclaimer',
				transcript,
				updatedTranscript,
				searches,
				errors
			)
		);

		return updatedTranscript;
	};
}
