import * as React from 'react';

import { Image } from 'display';

import { SitkaLogo } from 'components/Svgs';
import { usePartnerContext } from 'contexts';

function WhitelabelLogo(): JSX.Element {
	const { partner } = usePartnerContext();

	if (partner.logoUrl) {
		return (
			<Image
				src={partner.logoUrl}
				alt={`${partner.name} logo`}
				datatest-id="whitelabelLogo"
			/>
		);
	}

	return <SitkaLogo />;
}

export default WhitelabelLogo;
