import * as React from 'react';

import { Button, ButtonProps, ButtonType } from 'display';
import Strings from 'lib/strings';

interface DisableButtonProps {
	buttonType?: ButtonType;
	disabled?: ButtonProps['disabled'];
	onClick: ButtonProps['onClick'];
}

function DisableButton({
	buttonType = ButtonType.PRIMARY,
	disabled = false,
	onClick
}: DisableButtonProps): JSX.Element {
	const ButtonVariation = Button[buttonType];

	return (
		<ButtonVariation disabled={disabled} onClick={onClick}>
			{Strings.Buttons.DISABLE}
		</ButtonVariation>
	);
}

export { DisableButton };
