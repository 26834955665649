import { getCommonClient } from 'lib/ThriftClient';
import { SendGetPartnersAction } from 'store/actionTypes';
import { GetPartnersRequest, GetPartnersResponse } from 'thriftgen/api_types';

export default function getPartners(params: SendGetPartnersAction = {}): GetPartnersResponse {
	const { partnerIds = [] } = params;

	const client = getCommonClient();
	const request = new GetPartnersRequest({
		partner_ids: partnerIds
	});

	return client.get_partners(request);
}
