import * as React from 'react';

import Text, { TextProps } from './Text';

type ParagraphProps = TextProps;

const defaultProps = {
	weight: Text.Weight.REGULAR,
	size: Text.Size.T3
};

function Paragraph(props: ParagraphProps & Readonly<typeof defaultProps>): JSX.Element {
	return <Text as="p" {...props} />;
}

Paragraph.defaultProps = defaultProps;
Paragraph.Size = Text.Size;
Paragraph.Weight = Text.Weight;

export default Paragraph;
