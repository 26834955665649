import { NonNullableFilterValue } from 'components/Filters/types';
import { sanitizeTags } from 'lib/stringConverter';
import { Filter } from 'hooks/pagination';
import { Range } from 'thriftgen/api_types';
import { FilterCamel } from 'thriftgen/thriftCamelTypes';

function isArrayOfType<T>(array: unknown[], typeAssertion: string): array is T[] {
	return (
		Array.isArray(array) &&
		array.length > 0 &&
		array.every(item => typeof item === typeAssertion)
	);
}

function isRange(value: NonNullableFilterValue): boolean {
	return (
		value &&
		!Array.isArray(value) &&
		(Object.prototype.hasOwnProperty.call(value, 'range_start') ||
			Object.prototype.hasOwnProperty.call(value, 'range_end'))
	);
}

function determineFilterArrayType(attribute: string, value: unknown[]): FilterCamel | void {
	if (isArrayOfType<string>(value, 'string')) {
		return {
			attribute,
			valuesString: value
		};
	}

	if (isArrayOfType<number>(value, 'number')) {
		return {
			attribute,
			valuesInt: value
		};
	}

	if (isArrayOfType<boolean>(value, 'boolean')) {
		let valueBool: boolean | undefined = value[0];
		// This is a workaround, our boolean filters do not allow multiple values to be passed, but
		// our UI allows for multiple boolean checkboxes to be checked.  Since filtering by no filters is
		// effectively the same as filtering by all filters, we can safely remove the filter when both
		// boolean values are checked
		if (value.length === 2 && value.includes(true) && value.includes(false)) {
			valueBool = undefined;
		}
		return {
			attribute,
			valueBool
		};
	}
}

function transformToThriftFilter({ attribute, value }: Filter): FilterCamel {
	switch (typeof value) {
		case 'boolean':
			return {
				attribute,
				valueBool: value
			};
		case 'string':
			return {
				attribute,
				valuesString: [sanitizeTags(value)]
			};
		case 'number':
			return {
				attribute,
				valuesInt: [value]
			};
		case 'object':
			if (value instanceof Range || isRange(value)) {
				return {
					attribute,
					range: {
						rangeStart: (value as Range).range_start,
						rangeEnd: (value as Range).range_end
					}
				};
			} else {
				const arrayCamelFilter = determineFilterArrayType(attribute, value);
				if (arrayCamelFilter) {
					return arrayCamelFilter;
				}
			}
	}

	throw new Error(`Failed to transform ${attribute} to thrift filter`);
}

export { isRange, transformToThriftFilter };
