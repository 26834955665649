import * as React from 'react';

import { SpecialtyIllustrationProps } from '../illustrationTypes';

function HematologyOncology({
	background,
	fillLight,
	fillDark,
	outline
}: SpecialtyIllustrationProps): JSX.Element {
	return (
		<svg viewBox="0 0 88 88" fill="none" xmlns="https://www.w3.org/2000/svg">
			<circle cx="44" cy="44" r="40" fill={background} />
			<ellipse
				cx="51.0001"
				cy="68.9999"
				rx="10"
				ry="5"
				transform="rotate(16 51.0001 68.9999)"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M45.3608 64.6354C43.4887 64.9846 42.5686 65.7522 42.3487 66.5192C42.1288 67.2862 42.5022 68.4248 43.9048 69.713C45.2582 70.9559 47.3599 72.1174 49.8975 72.845C52.4351 73.5726 54.833 73.7014 56.6393 73.3645C58.5114 73.0154 59.4315 72.2477 59.6514 71.4807C59.8714 70.7137 59.4979 69.5751 58.0953 68.2869C56.742 67.044 54.6402 65.8826 52.1026 65.1549C49.565 64.4273 47.1672 64.2985 45.3608 64.6354ZM44.9941 62.6693C47.1656 62.2643 49.8826 62.4377 52.6539 63.2324C55.4252 64.0271 57.8212 65.3197 59.4482 66.8139C61.0258 68.2629 62.1152 70.1445 61.574 72.032C61.0327 73.9194 59.1118 74.9379 57.006 75.3306C54.8345 75.7357 52.1176 75.5622 49.3462 74.7675C46.5749 73.9729 44.1789 72.6802 42.552 71.186C40.9743 69.737 39.885 67.8554 40.4262 65.9679C40.9674 64.0805 42.8883 63.062 44.9941 62.6693Z"
				fill={outline}
			/>
			<ellipse
				cx="50.8521"
				cy="68.6559"
				rx="5.5"
				ry="2"
				transform="rotate(16 50.8521 68.6559)"
				fill={fillDark}
			/>
			<ellipse cx="60" cy="62" rx="10" ry="5" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M53.3761 59.3589C51.6728 60.2106 51 61.2021 51 62C51 62.7979 51.6728 63.7894 53.3761 64.6411C55.0197 65.4629 57.3602 66 60 66C62.6398 66 64.9803 65.4629 66.6239 64.6411C68.3272 63.7894 69 62.7979 69 62C69 61.2021 68.3272 60.2106 66.6239 59.3589C64.9803 58.5371 62.6398 58 60 58C57.3602 58 55.0197 58.5371 53.3761 59.3589ZM52.4817 57.57C54.4575 56.5822 57.117 56 60 56C62.883 56 65.5425 56.5822 67.5183 57.57C69.4342 58.528 71 60.0365 71 62C71 63.9635 69.4342 65.472 67.5183 66.43C65.5425 67.4178 62.883 68 60 68C57.117 68 54.4575 67.4178 52.4817 66.43C50.5658 65.472 49 63.9635 49 62C49 60.0365 50.5658 58.528 52.4817 57.57Z"
				fill={outline}
			/>
			<ellipse cx="59.7629" cy="61.7101" rx="5.5" ry="2" fill={fillDark} />
			<ellipse
				cx="60.9999"
				cy="55"
				rx="10"
				ry="5"
				transform="rotate(-8 60.9999 55)"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M54.0729 53.3065C52.5047 54.387 51.9764 55.4625 52.0875 56.2526C52.1985 57.0427 53.0028 57.931 54.8081 58.5373C56.55 59.1224 58.9424 59.3285 61.5566 58.9611C64.1707 58.5937 66.4137 57.7361 67.9269 56.6936C69.4951 55.6132 70.0234 54.5376 69.9123 53.7475C69.8013 52.9574 68.997 52.0691 67.1917 51.4628C65.4498 50.8778 63.0574 50.6716 60.4432 51.039C57.8291 51.4064 55.5861 52.264 54.0729 53.3065ZM52.9383 51.6596C54.7573 50.4063 57.3099 49.4597 60.1649 49.0585C63.0198 48.6572 65.7345 48.8636 67.8285 49.5669C69.8591 50.2489 71.6196 51.5247 71.8928 53.4692C72.1661 55.4136 70.8255 57.1253 69.0615 58.3406C67.2425 59.5938 64.6899 60.5404 61.8349 60.9417C58.98 61.3429 56.2653 61.1365 54.1713 60.4333C52.1407 59.7512 50.3802 58.4754 50.107 56.531C49.8337 54.5865 51.1743 52.8749 52.9383 51.6596Z"
				fill={outline}
			/>
			<ellipse
				cx="60.7248"
				cy="54.746"
				rx="5.5"
				ry="2"
				transform="rotate(-8 60.7248 54.746)"
				fill={fillDark}
			/>
			<ellipse
				cx="54.5984"
				cy="21.3431"
				rx="10"
				ry="5"
				transform="rotate(8 54.5984 21.3431)"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M48.4066 17.8058C46.6013 18.4121 45.797 19.3004 45.686 20.0905C45.575 20.8806 46.1032 21.9562 47.6714 23.0366C49.1846 24.0791 51.4276 24.9367 54.0417 25.3041C56.6559 25.6715 59.0483 25.4654 60.7902 24.8803C62.5955 24.274 63.3998 23.3857 63.5108 22.5956C63.6219 21.8055 63.0936 20.73 61.5254 19.6495C60.0122 18.607 57.7692 17.7494 55.1551 17.382C52.5409 17.0146 50.1485 17.2208 48.4066 17.8058ZM47.7698 15.9099C49.8638 15.2066 52.5785 15.0002 55.4334 15.4014C58.2884 15.8027 60.841 16.7493 62.6601 18.0026C64.424 19.2179 65.7646 20.9295 65.4914 22.874C65.2181 24.8184 63.4576 26.0942 61.427 26.7762C59.333 27.4795 56.6183 27.6859 53.7634 27.2847C50.9084 26.8834 48.3558 25.9368 46.5368 24.6836C44.7728 23.4683 43.4322 21.7566 43.7055 19.8122C43.9787 17.8677 45.7392 16.5919 47.7698 15.9099Z"
				fill={outline}
			/>
			<ellipse
				cx="54.404"
				cy="21.023"
				rx="5.5"
				ry="2"
				transform="rotate(8 54.404 21.023)"
				fill={fillDark}
			/>
			<ellipse cx="67" cy="34" rx="10" ry="5" transform="rotate(-12 67 34)" fill="white" />
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M59.972 32.7939C58.483 33.9811 58.031 35.0908 58.1969 35.8713C58.3628 36.6517 59.2271 37.4817 61.0703 37.9606C62.8487 38.4227 65.2498 38.4615 67.8319 37.9127C70.4141 37.3638 72.5917 36.3518 74.0285 35.2063C75.5175 34.0191 75.9695 32.9093 75.8036 32.1289C75.6377 31.3484 74.7734 30.5184 72.9302 30.0395C71.1518 29.5774 68.7508 29.5386 66.1686 30.0875C63.5865 30.6363 61.4088 31.6483 59.972 32.7939ZM58.7252 31.2301C60.4524 29.853 62.9328 28.7306 65.7528 28.1312C68.5728 27.5318 71.2952 27.5483 73.4332 28.1038C75.5065 28.6425 77.3516 29.7924 77.7599 31.713C78.1681 33.6337 76.9502 35.4347 75.2753 36.7701C73.5481 38.1472 71.0677 39.2695 68.2477 39.869C65.4277 40.4684 62.7053 40.4519 60.5673 39.8964C58.4941 39.3577 56.6489 38.2077 56.2406 36.2871C55.8324 34.3665 57.0503 32.5654 58.7252 31.2301Z"
				fill={outline}
			/>
			<ellipse
				cx="67"
				cy="34"
				rx="5.5"
				ry="2"
				transform="rotate(-12 67 34)"
				fill={fillDark}
			/>
			<ellipse
				cx="53.716"
				cy="37.6606"
				rx="10"
				ry="5"
				transform="rotate(4 53.716 37.6606)"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M47.2927 34.5639C45.5341 35.2946 44.7938 36.2369 44.7381 37.0328C44.6825 37.8287 45.2845 38.8648 46.9243 39.8332C48.5065 40.7676 50.8038 41.4667 53.4372 41.6509C56.0706 41.835 58.4429 41.4624 60.1397 40.7573C61.8983 40.0266 62.6386 39.0844 62.6943 38.2884C62.75 37.4925 62.148 36.4564 60.5082 35.488C58.926 34.5536 56.6287 33.8545 53.9953 33.6703C51.3618 33.4862 48.9896 33.8588 47.2927 34.5639ZM46.5253 32.717C48.5651 31.8693 51.2588 31.4741 54.1348 31.6752C57.0107 31.8763 59.6232 32.6426 61.5252 33.7659C63.3697 34.8552 64.8264 36.4692 64.6894 38.4279C64.5525 40.3867 62.8853 41.7822 60.9072 42.6042C58.8673 43.4519 56.1737 43.8471 53.2977 43.646C50.4217 43.4449 47.8093 42.6786 45.9072 41.5553C44.0628 40.466 42.6061 38.852 42.743 36.8933C42.88 34.9345 44.5472 33.539 46.5253 32.717Z"
				fill={outline}
			/>
			<ellipse
				cx="53.716"
				cy="37.6606"
				rx="5.5"
				ry="2"
				transform="rotate(4 53.716 37.6606)"
				fill={fillDark}
			/>
			<ellipse
				cx="73.7164"
				cy="49.6606"
				rx="10"
				ry="5"
				transform="rotate(16 73.7164 49.6606)"
				fill="white"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M68.0771 45.296C66.205 45.6452 65.285 46.4129 65.065 47.1799C64.8451 47.9468 65.2185 49.0854 66.6211 50.3736C67.9745 51.6166 70.0763 52.778 72.6138 53.5057C75.1514 54.2333 77.5493 54.3621 79.3556 54.0252C81.2278 53.676 82.1478 52.9083 82.3677 52.1413C82.5877 51.3744 82.2142 50.2358 80.8116 48.9476C79.4583 47.7046 77.3565 46.5432 74.8189 45.8156C72.2814 45.0879 69.8835 44.9591 68.0771 45.296ZM67.7104 43.3299C69.8819 42.9249 72.5989 43.0984 75.3702 43.893C78.1415 44.6877 80.5376 45.9804 82.1645 47.4746C83.7422 48.9235 84.8315 50.8051 84.2903 52.6926C83.749 54.5801 81.8281 55.5985 79.7224 55.9913C77.5508 56.3963 74.8339 56.2228 72.0626 55.4282C69.2912 54.6335 66.8952 53.3408 65.2683 51.8466C63.6906 50.3977 62.6013 48.5161 63.1425 46.6286C63.6837 44.7411 65.6046 43.7227 67.7104 43.3299Z"
				fill={outline}
			/>
			<ellipse
				cx="73.8356"
				cy="49.3574"
				rx="5.5"
				ry="2"
				transform="rotate(16 73.8356 49.3574)"
				fill={fillDark}
			/>
			<rect x="14" y="11" width="24" height="11" rx="2" fill={fillDark} />
			<path
				d="M17 60H35V69C35 73.9706 30.9706 78 26 78C21.0294 78 17 73.9706 17 69V60Z"
				fill={fillDark}
			/>
			<path d="M17 32H35V60H17V32Z" fill={fillLight} />
			<path
				d="M30 47C30 49.2091 28.2091 51 26 51C23.7909 51 22 49.2091 22 47C22 44 26 40 26 40C26 40 30 44 30 47Z"
				fill={fillDark}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M26 40L26.7071 39.2929L26.7089 39.2947L26.7121 39.2979L26.7228 39.3088L26.7607 39.3474C26.793 39.3805 26.8392 39.4282 26.8972 39.4893C27.0131 39.6115 27.1768 39.7875 27.3724 40.0075C27.7625 40.4464 28.2845 41.0661 28.8087 41.7869C29.3309 42.5048 29.869 43.3419 30.2798 44.2149C30.6857 45.0774 31 46.043 31 47C31 49.7615 28.7614 52 26 52C23.2386 52 21 49.7615 21 47C21 46.043 21.3143 45.0774 21.7202 44.2149C22.131 43.3419 22.6691 42.5048 23.1913 41.7869C23.7155 41.0661 24.2375 40.4464 24.6276 40.0075C24.8232 39.7875 24.9869 39.6115 25.1028 39.4893C25.1608 39.4282 25.207 39.3805 25.2393 39.3474L25.2772 39.3088L25.2879 39.2979L25.2911 39.2947L25.2929 39.2929L26 40ZM24.8087 42.9632C25.2324 42.3807 25.6578 41.8666 26 41.4751C26.3422 41.8666 26.7676 42.3807 27.1913 42.9632C27.6691 43.6202 28.131 44.3457 28.4702 45.0665C28.8143 45.7977 29 46.4571 29 47C29 48.6569 27.6569 50 26 50C24.3431 50 23 48.6569 23 47C23 46.4571 23.1857 45.7977 23.5298 45.0665C23.869 44.3457 24.3309 43.6202 24.8087 42.9632Z"
				fill={outline}
			/>
			<path
				d="M26 40C26.7071 39.2929 26.7071 39.2929 26.7071 39.2929L26 38.5858L25.2929 39.2929C25.2929 39.2929 25.2929 39.2929 26 40Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13 13C13 11.3431 14.3431 10 16 10H36C37.6569 10 39 11.3431 39 13V20C39 21.6569 37.6569 23 36 23V69C36 74.5228 31.5228 79 26 79C20.4772 79 16 74.5228 16 69L16 23C14.3431 23 13 21.6569 13 20V13ZM18 23H34V31H18V23ZM16 12C15.4477 12 15 12.4477 15 13V20C15 20.5523 15.4477 21 16 21H17V12H16ZM22 21H19V12H22V21ZM28 21H24V12H28V21ZM33 21H30V12H33V21ZM36 21H35V12H36C36.5523 12 37 12.4477 37 13V20C37 20.5523 36.5523 21 36 21ZM18 33V59H34V33H18ZM18 61V69C18 73.4183 21.5817 77 26 77C30.4183 77 34 73.4183 34 69V61H18Z"
				fill={outline}
			/>
		</svg>
	);
}

export default HematologyOncology;
