import { getEditableFields } from 'api/common';
import useQuery from 'swr';
import { GetEditableFieldsResponse } from 'thriftgen/api_types';
import { GetEditableFieldsRequestCamel } from 'thriftgen/thriftCamelTypes';

type UseEditableFieldsQueryProps = Partial<GetEditableFieldsRequestCamel>;

interface EditableFieldsQuery {
	editableFields: string[] | undefined;
	error: Error | undefined;
}

function useEditableFieldsQuery(props: UseEditableFieldsQueryProps): EditableFieldsQuery {
	const keyValues = Object.entries(props).map(item => item.join('/'));
	const key = `editableFields/${keyValues}`;
	const { data, error } = useQuery<GetEditableFieldsResponse>(key, () =>
		getEditableFields(props)
	);

	return {
		editableFields: data ? data.editable_fields : undefined,
		error
	};
}

export default useEditableFieldsQuery;
export { EditableFieldsQuery, UseEditableFieldsQueryProps };
