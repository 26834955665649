import { DataTableColumn } from 'components/DataTable/DataTable';
import { buildHalfWidthMobileColumn } from 'components/DataTable/helpers';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { translateThriftEnum } from 'lib/i18n';
import * as React from 'react';
import { colors } from 'styles';
import { ConsultStateSimplified, CONSULT_STATE_SIMPLIFIED } from 'thriftgen/api_types';

function getStatusName(state: ConsultStateSimplified): string {
	return translateThriftEnum(state, CONSULT_STATE_SIMPLIFIED);
}

function getStatusStyle(state: ConsultStateSimplified): string {
	if (state === ConsultStateSimplified.PENDING_REQUESTOR_ACTION) {
		return css`
			color: ${colors.problem.base};
		`;
	}
	return css``;
}

const header = 'Status';

function formatStatus(state: ConsultStateSimplified): JSX.Element {
	return <div className={getStatusStyle(state)}>{getStatusName(state)}</div>;
}

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ simplifiedState }: ConsultComposite) => formatStatus(simplifiedState),
	renderMobile: ({ simplifiedState }: ConsultComposite) =>
		buildHalfWidthMobileColumn(header, formatStatus(simplifiedState)),
	sortKeys: ['simplified_state']
});
