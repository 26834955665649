import { isRange } from 'api/helpers';
import { usePaginationContext } from 'contexts/PaginationContext';
import { FilterField, Icon, Label } from 'display';
import { getAbbreviatedDate } from 'lib/date';
import * as React from 'react';
import { Range } from 'thriftgen/api_types';

interface PaginationFilterPopoverSelectedItemsProps {
	filters: FilterField[];
}

function PaginationFilterPopoverSelectedItems({
	filters
}: PaginationFilterPopoverSelectedItemsProps): JSX.Element {
	const { parameters, removeFilterValue } = usePaginationContext();

	function renderSelectedFilterLabel(attribute: string, value: string | number): string {
		const NOT_FOUND_LABEL = 'Filter Not Found';
		const currentFilter = filters.find(filter => filter.attribute === attribute);
		if (currentFilter) {
			const currentFilterValue = currentFilter.values.find(
				filterValues => filterValues.value === value
			);
			if (currentFilterValue) {
				return currentFilterValue.label;
			}
		}
		return NOT_FOUND_LABEL;
	}

	function renderSelectedDateFilterLabel(attribute: string, value: string): string {
		const NOT_FOUND_LABEL = 'Filter Not Found';
		const baseFilter = filters.find(filter => filter.attribute === attribute);
		if (baseFilter) {
			return value;
		}
		return NOT_FOUND_LABEL;
	}

	function generateDateChipLabel(key: string, formattedDate: string, filterLabel: string) {
		if (key === 'range_start') {
			return `${formattedDate} <= ${filterLabel}`;
		} else {
			return `${filterLabel} <= ${formattedDate}`;
		}
	}

	return (
		<React.Fragment>
			{parameters.filters.map(setFilter => {
				// since both search and popover filters are contained in parameters.filters, we need to filter
				// out the search filters as the content of search bars should not appear as filter pills
				const filter = filters.find(filter => filter.attribute === setFilter.attribute);
				if (filter) {
					let renderLabelText: (filter: string, value: string | number) => string;
					let labelItems: Array<string | number> = [];
					let onClick: (attribute: string, value: string | number) => void;
					if (setFilter.value instanceof Range || isRange(setFilter.value)) {
						Object.keys(setFilter.value as Range).forEach(key => {
							if (setFilter.value[key]) {
								const formattedDate = getAbbreviatedDate(setFilter.value[key]);
								const labelString = generateDateChipLabel(
									key,
									formattedDate,
									filter.label
								);
								labelItems.push(labelString);
							}
						});
						onClick = (attribute: string, value: string | number): void => {
							removeFilterValue(attribute, value);
						};
						renderLabelText = renderSelectedDateFilterLabel;
					} else {
						labelItems = setFilter.value as Array<string | number>;
						onClick = (attribute: string, value: string | number): void => {
							removeFilterValue(attribute, value);
						};
						renderLabelText = renderSelectedFilterLabel;
					}
					return labelItems.map(item => (
						<Label
							key={`${filter.attribute}-${item}`}
							as="a"
							onClick={() => {
								onClick(filter.attribute, item);
							}}>
							{renderLabelText(filter.attribute, item)}
							<Icon name="delete" />
						</Label>
					));
				} else {
					return <React.Fragment key={setFilter.attribute}></React.Fragment>;
				}
			})}
		</React.Fragment>
	);
}

export default PaginationFilterPopoverSelectedItems;
