import { DataTableColumn } from 'components/DataTable/DataTable';
import { Partner, User } from 'thriftgen/api_types';

const buildIsAdmin = (user: User): DataTableColumn<Partner> => ({
	header: 'Admin',
	render: ({ partner_id }: Partner): string | null =>
		user.admin_for_partner_ids.includes(partner_id) ? 'Yes' : null
});

export { buildIsAdmin };
