import * as React from 'react';
import { Message } from 'display';
import { css } from 'emotion';

const styles = {
	rejectedFilesList: css`
		text-align: left;
	`
};

interface RejectedFilesErrorMessageProps {
	rejectedFilesList: File[];
	errorMessage: string;
}

function RejectedFilesErrorMessage({
	rejectedFilesList,
	errorMessage
}: RejectedFilesErrorMessageProps): JSX.Element {
	return (
		<React.Fragment>
			{rejectedFilesList.length ? (
				<Message negative={true} className={styles.rejectedFilesList}>
					<div>{errorMessage}</div>
					<ul>
						{rejectedFilesList.map(rejectedFile => (
							<li key={rejectedFile.name}>{rejectedFile.name}</li>
						))}
					</ul>
				</Message>
			) : (
				<React.Fragment />
			)}
		</React.Fragment>
	);
}

export default RejectedFilesErrorMessage;
