import ErrorModal from 'components/Modals/ErrorModal';
import ModalPortal from 'components/Modals/ModalPortal';
import { useConsultContext } from 'contexts';
import { SemanticICONS } from 'display';
import { useLazyAsyncCall } from 'hooks';
import { DocumentDataAsset } from 'lib/documentAssets';
import * as React from 'react';

interface DocumentRedactActionProps {
	data: DocumentDataAsset;
	modalPortalId: string;
	children: React.ReactNode;
}

function DocumentRedactAction({
	data,
	modalPortalId,
	children
}: DocumentRedactActionProps): JSX.Element | null {
	const { consult, restoreConsultData } = useConsultContext();
	const { dispatch: sendRestoreConsultData, error, reset } = useLazyAsyncCall(restoreConsultData);

	const errorMessage = error ? error.message : undefined;
	const errorHeading = `Failed to restore attachment`;

	function onClick(): Promise<void> {
		return sendRestoreConsultData({
			consultId: consult.consult_id,
			consultDataId: data.id
		}).promise();
	}

	return (
		<React.Fragment>
			<ModalPortal id={modalPortalId}>
				<ErrorModal
					open={error !== undefined}
					content={errorMessage}
					heading={errorHeading}
					onCancel={reset}
				/>
			</ModalPortal>
			<div onClick={onClick}>{children}</div>
		</React.Fragment>
	);
}

DocumentRedactAction.ICON = 'redo' as SemanticICONS;
DocumentRedactAction.TITLE = 'Restore';

export default DocumentRedactAction;
