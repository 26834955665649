import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

function ViewMyMessagesButton({ onClickAsync }: Pick<ButtonProps, 'onClickAsync'>): JSX.Element {
	return (
		<Button.Primary onClickAsync={onClickAsync}>
			{Strings.Buttons.VIEW_MY_MESSAGES}
		</Button.Primary>
	);
}

export default ViewMyMessagesButton;
