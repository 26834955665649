import * as React from 'react';
import { css } from 'emotion';

const styles = {
	action: css`
		margin-left: 0.5em;
	`
};

function AssetListItemAction({ action }: { action: React.ReactNode }): JSX.Element {
	return (
		<div
			data-testid="AssetListItemAction"
			className={styles.action}
			style={{ visibility: action ? undefined : 'hidden' }}>
			{action}
		</div>
	);
}

export default AssetListItemAction;
