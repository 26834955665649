import { Token } from '@okta/okta-auth-js';
import { EXPIRE_EARLY_BUFFER } from 'lib/okta/OktaConfigHelpers';

const FIVE_MINUTES_MS = 300000;
const INTERVAL_RATIO = 0.2;

function deriveActivityIntervalFromToken(token: Token): number {
	// Okta sets expiry time in UTC seconds
	const tokenExpiry = new Date(token.expiresAt * 1000);
	const sessionLength = tokenExpiry.getTime() - new Date().getTime();
	const interval = Math.floor(sessionLength * INTERVAL_RATIO);

	// 5 minutes will be the default for any interval
	// greater than 5 minutes.
	return interval < FIVE_MINUTES_MS ? interval : FIVE_MINUTES_MS;
}

const getTokenExpiryLocaleTime = (token: Token): string =>
	new Date(token.expiresAt * 1000).toLocaleTimeString();

const getTokenTimeToLive = ({ expiresAt }: Token): number => {
	const remainingTime = new Date((expiresAt - EXPIRE_EARLY_BUFFER) * 1000);
	return remainingTime.getTime() - new Date().getTime();
};

export { deriveActivityIntervalFromToken, getTokenExpiryLocaleTime, getTokenTimeToLive };
