import * as React from 'react';
import { ConsultContext, ConsultContextData, ConsultContextMutators } from 'contexts';
import { useEditableFieldsQuery } from 'hooks/queries';

function ConsultContextProvider({
	children,
	data,
	mutators
}: {
	children: React.ReactNode;
	data: Omit<ConsultContextData, 'getEditableFields'>;
	mutators: ConsultContextMutators;
}): JSX.Element {
	const editableFieldsQuery = useEditableFieldsQuery({ consultId: data.consult.consult_id });

	return (
		<ConsultContext.Provider
			value={{
				...data,
				...mutators,
				getEditableFields: () => editableFieldsQuery
			}}>
			{children}
		</ConsultContext.Provider>
	);
}

export default ConsultContextProvider;
