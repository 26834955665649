import { Box, CardMedia, Typography } from '@mui/material';
import React from 'react';
import { css } from 'emotion';
import { Divider } from 'display';

const tileStyles = {
	bioContent: css`
		overflow: auto;
	`,
	largeAvatar: css`
		height: 100%;
	`,
	rightSide: css`
		display: flex;
		flex-direction: column;
		flex-basis: fit-content;

		& > .divider {
			margin: 0.5em 0;
		}
	`,
	leftSide: css`
		flex-basis: fit-content;
		max-width: 200px;
		margin-right: 2em;
	`,
	wrapper: css`
		display: flex;
	`
};

interface SMAUserBioInfoPopupContentProps {
	displayName: string;
	specialistBioInfo: SpecialistBioInfo;
	user: User;
}

function SMAUserBioInfoPopupContent({
	displayName,
	specialistBioInfo,
	user
}: SMAUserBioInfoPopupContentProps) {
	function getSpecialtyBioInfoForDisplay() {
		if (specialistBioInfo) {
			const displayMap: SpecialistBioInfo = {
				specialty: 'Specialty',
				med_school: 'Medical School',
				residency: 'Residency',
				fellowship: 'Fellowship',
				board_certification: 'Board Certification',
				fun_facts: 'Fun Facts'
			};

			return Object.keys(displayMap).map(key => {
				return specialistBioInfo[key] ? (
					<div key={key}>
						<strong>{displayMap[key]}: </strong>
						{specialistBioInfo[key]}
					</div>
				) : (
					<></>
				);
			});
		} else {
			return <></>;
		}
	}

	return (
		<div className={tileStyles.wrapper} data-testid={'popupBody'}>
			{user.specialist_data?.profile_pic_url && (
				<div className={tileStyles.leftSide}>
					<CardMedia
						component="img"
						src={user.specialist_data.profile_pic_url}
						alt={displayName}
						className={tileStyles.largeAvatar}
						data-testid="profilePhoto"
					/>
				</div>
			)}
			<Box className={tileStyles.rightSide}>
				<Typography variant="h6" component="h2">
					{displayName}
				</Typography>
				<Divider />
				<div className={tileStyles.bioContent} data-testid="bioContent">
					{getSpecialtyBioInfoForDisplay()}
				</div>
			</Box>
		</div>
	);
}

export default SMAUserBioInfoPopupContent;
