import { Button } from 'display';
import { isFastTrackConsult } from 'lib/consultHelpers';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Consult } from 'thriftgen/api_types';

interface StartFastTrackConsultButtonProps {
	consult: Consult;
}

function StartFastTrackConsultButton({
	consult
}: StartFastTrackConsultButtonProps): JSX.Element | null {
	function onClick() {
		const linkUrl = RouteUrls.buildUrl(RouteUrls.Key.VIDEO_VISIT_NEW, {
			consultId: consult.consult_id
		});
		const fastTrackWindow = window.open(linkUrl, '_blank');
		if (fastTrackWindow) {
			fastTrackWindow.focus();
		}
	}

	if (isFastTrackConsult(consult)) {
		return <Button.Primary onClick={onClick}>Start Fast Track Consult</Button.Primary>;
	} else {
		return null;
	}
}

export default StartFastTrackConsultButton;
