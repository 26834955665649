import * as React from 'react';

import { getValue, translateSpecialty } from './helpers';
import { DisplaySpecialtyFieldProps, FIELD_NAME } from './types';
import { Form, Icon } from 'display';

function DisplaySpecialtyField({ data }: DisplaySpecialtyFieldProps): JSX.Element {
	const value = getValue(data);

	return (
		<Form.Field data-testid="displaySpecialtyField">
			<Icon name="doctor" />
			{` `}
			{translateSpecialty(value)}
		</Form.Field>
	);
}

DisplaySpecialtyField.FIELD_NAME = FIELD_NAME;
DisplaySpecialtyField.validate = () => null;

export { DisplaySpecialtyField };
