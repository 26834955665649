import useSWR, { responseInterface } from 'swr';
import { fetcherFn, keyInterface, ConfigInterface } from 'swr/dist/types';
import { handleInvalidRequestErrorCodes, getErrorMessage } from 'api/errorHandling';

import store from 'store/index';

type ErrorLike = Error & {
	message?: string;
};

function useQuery<Data = any>(
	key: keyInterface,
	fn?: fetcherFn<Data>,
	config?: ConfigInterface<Data, Error>
): responseInterface<Data, Error> {
	const payload = useSWR<Data, ErrorLike>(key, fn, config);

	if (payload.error) {
		handleInvalidRequestErrorCodes(payload.error, store.dispatch);
		payload.error.message = getErrorMessage(payload.error);
	}

	return payload;
}

export default useQuery;
