// eslint-disable-next-line no-useless-escape
const reUnderscoreLowercase = /(\_[a-z])/g;
const reCapital = /([A-Z])/g;

export function snakeToCamel(arg: string): string {
	return arg.replace(reUnderscoreLowercase, $1 => {
		return $1.toUpperCase().replace('_', '');
	});
}

export function camelToSnake(arg: string): string {
	return arg.replace(reCapital, $1 => {
		return '_' + $1.toLowerCase();
	});
}

export function sanitizeTags(arg: string): string {
	return arg.replace(/</g, '&lt;').replace(/>/g, '&gt;');
}

export function capitalize(arg: string): string {
	if (!arg) {
		return arg;
	}

	return arg.replace(arg[0], arg[0].toUpperCase());
}

export function capitalizeFirstLetter(string: string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
}
