import * as React from 'react';

import { Button, Icon } from 'display';
import Link from 'components/Link';
import Strings from 'lib/strings';
import RouteUrls from 'routing/RouteUrls';

interface LinkToEditButtonProps {
	userId: string;
}

function LinkToAddUnavailabilityDatesButton({ userId }: LinkToEditButtonProps): JSX.Element {
	return (
		<Link to={RouteUrls.buildUrl(RouteUrls.Key.USER_TIMEFRAME_RESTRICTION_NEW, { userId })}>
			<Button.Outline className="editButton" size={Button.Size.TINY}>
				<Icon name="plus" />
				{Strings.Buttons.ADD_UNAVAILABILITY_DATES}
			</Button.Outline>
		</Link>
	);
}

export default LinkToAddUnavailabilityDatesButton;
