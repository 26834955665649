import withApiCall from 'components/withApiCall/withApiCall';
import { Loader, Text } from 'display';
import * as React from 'react';
import { connect } from 'react-redux';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { SendGetPartnersAction } from 'store/actionTypes';
import { selectPartnerById, sendGetPartners } from 'store/admin';
import { AppState } from 'store/types';
import { GetPartnersResponse, Partner } from 'thriftgen/api_types';

type PartnerRenderer = (partner: Partner) => JSX.Element;

interface ConsumerProps {
	partnerId: Partner['partner_id'];
	render: PartnerRenderer;
}

interface DispatchProps {
	sendGetPartners: (args: SendGetPartnersAction) => Promise<GetPartnersResponse>;
}

interface StoreProps {
	partner: Partner;
}

type PartnerComponentProps = ConsumerProps & DispatchProps & StoreProps & WithApiCallProps;

function defaultRender(partner: Partner): JSX.Element {
	return <span data-testid="renderPartner">{partner.name}</span>;
}

function PartnerComponent({
	partner,
	partnerId,
	render = defaultRender,
	request,
	sendGetPartners: getPartners
}: PartnerComponentProps) {
	React.useEffect((): void => {
		if (!partner) {
			getPartners({ partnerIds: [partnerId] });
		}
	}, [getPartners, partner, partnerId]);

	switch (request.data.status) {
		case FAILURE:
			return <Text>Partner not found</Text>;

		case IN_PROGRESS:
			return <Loader active={true} data-testid="renderPartner-loader" />;

		case INACTIVE:
		case SUCCESS:
		default:
			if (partner) {
				return render(partner);
			} else {
				return null;
			}
	}
}

const mapStateToProps = (store: AppState, ownProps: ConsumerProps): StoreProps => ({
	partner: selectPartnerById(store, { id: ownProps.partnerId })
});

export default withApiCall({ sendGetPartners }, connect(mapStateToProps)(PartnerComponent));
