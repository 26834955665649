import FinalizedTextLogEntry from './FinalizedTextLogEntry';

export default class TranscriptionSessionLog {
	completeLogData: { finalizedTextBlockLogEntries: Array<FinalizedTextLogEntry> };
	completeTextFromAmazon: string;
	completeProcessedText: string;
	preExistingText: string;

	constructor() {
		this.preExistingText = '';
		this.completeTextFromAmazon = '';
		this.completeProcessedText = '';
		this.completeLogData = { finalizedTextBlockLogEntries: [] };
	}

	setPreExistingText(preExistingText: string) {
		this.preExistingText = preExistingText;
	}

	addFinalizedTextBlockLogEntry(entry: FinalizedTextLogEntry) {
		this.completeLogData.finalizedTextBlockLogEntries.push(entry);
	}
}
