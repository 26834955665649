import * as React from 'react';

import { SpecialtyIllustrationProps } from '../illustrationTypes';

function WoundVascular({
	background,
	fillLight,
	fillDark,
	outline
}: SpecialtyIllustrationProps): JSX.Element {
	return (
		<svg viewBox="0 0 88 88" fill="none" xmlns="https://www.w3.org/2000/svg">
			<path
				d="M84 44C84 66.0914 66.0914 84 44 84C21.9086 84 4 66.0914 4 44C4 21.9086 21.9086 4 44 4C66.0914 4 84 21.9086 84 44Z"
				fill={background}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M73.5319 28.0005C74.0839 28.0181 74.5171 28.4799 74.4995 29.0319C74.2519 36.7951 74.497 42.6909 74.7458 48.6758L74.7492 48.7558C74.9993 54.7717 75.2508 60.8838 74.9996 69.0308C74.9825 69.5829 74.5212 70.0166 73.9692 69.9995C73.4172 69.9825 72.9835 69.5212 73.0005 68.9692C73.2493 60.9 73.0008 54.8499 72.7509 48.8388L72.747 48.7446C72.4982 42.7617 72.2506 36.8049 72.5005 28.9681C72.5181 28.4161 72.9799 27.9829 73.5319 28.0005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M77.5319 27.7505C78.0839 27.7681 78.5171 28.2299 78.4995 28.7819C78.2519 36.5451 78.497 42.4409 78.7458 48.4258L78.7492 48.5058C78.9993 54.5217 79.2508 60.6338 78.9996 68.7808C78.9825 69.3329 78.5212 69.7666 77.9692 69.7495C77.4172 69.7325 76.9835 69.2712 77.0005 68.7192C77.2493 60.65 77.0008 54.5999 76.7509 48.5888L76.747 48.4946C76.4982 42.5117 76.2506 36.5549 76.5005 28.7181C76.5181 28.1661 76.9799 27.7329 77.5319 27.7505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M69.5319 28.2505C70.0839 28.2681 70.5171 28.7299 70.4995 29.2819C70.2519 37.0451 70.497 42.9409 70.7458 48.9258L70.7492 49.0058C70.9993 55.0217 71.2508 61.1338 70.9996 69.2808C70.9825 69.8329 70.5212 70.2666 69.9692 70.2495C69.4172 70.2325 68.9835 69.7712 69.0005 69.2192C69.2493 61.15 69.0008 55.0999 68.7509 49.0888L68.747 48.9946C68.4982 43.0117 68.2506 37.0549 68.5005 29.2181C68.5181 28.6661 68.9799 28.2329 69.5319 28.2505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M65.5319 28.5005C66.0839 28.5181 66.5171 28.9799 66.4995 29.5319C66.2519 37.2951 66.497 43.1909 66.7458 49.1758L66.7492 49.2558C66.9993 55.2717 67.2508 61.3838 66.9996 69.5308C66.9825 70.0829 66.5212 70.5166 65.9692 70.4995C65.4172 70.4825 64.9835 70.0212 65.0005 69.4692C65.2493 61.4 65.0008 55.3499 64.7509 49.3388L64.747 49.2446C64.4982 43.2617 64.2506 37.3049 64.5005 29.4681C64.5181 28.9161 64.9799 28.4829 65.5319 28.5005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M61.5319 28.7505C62.0839 28.7681 62.5171 29.2299 62.4995 29.7819C62.2519 37.5451 62.497 43.4409 62.7458 49.4258L62.7492 49.5058C62.9993 55.5217 63.2508 61.6338 62.9996 69.7808C62.9825 70.3329 62.5212 70.7666 61.9692 70.7495C61.4172 70.7325 60.9835 70.2712 61.0005 69.7192C61.2493 61.65 61.0008 55.5999 60.7509 49.5888L60.747 49.4946C60.4982 43.5117 60.2506 37.5549 60.5005 29.7181C60.5181 29.1661 60.9799 28.7329 61.5319 28.7505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M57.5319 29.0005C58.0839 29.0181 58.5171 29.4799 58.4995 30.0319C58.2519 37.7951 58.497 43.6909 58.7458 49.6758L58.7492 49.7558C58.9993 55.7717 59.2508 61.8838 58.9996 70.0308C58.9825 70.5829 58.5212 71.0166 57.9692 70.9995C57.4172 70.9825 56.9835 70.5212 57.0005 69.9692C57.2493 61.9 57.0008 55.8499 56.7509 49.8388L56.747 49.7446C56.4982 43.7617 56.2506 37.8049 56.5005 29.9681C56.5181 29.4161 56.9799 28.9829 57.5319 29.0005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M53.5319 29.2505C54.0839 29.2681 54.5171 29.7299 54.4995 30.2819C54.2519 38.0451 54.497 43.9409 54.7458 49.9258L54.7492 50.0058C54.9993 56.0217 55.2508 62.1338 54.9996 70.2808C54.9825 70.8329 54.5212 71.2666 53.9692 71.2495C53.4172 71.2325 52.9835 70.7712 53.0005 70.2192C53.2493 62.15 53.0008 56.0999 52.7509 50.0888L52.747 49.9946C52.4982 44.0117 52.2506 38.0549 52.5005 30.2181C52.5181 29.6661 52.9799 29.2329 53.5319 29.2505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M49.5319 29.5005C50.0839 29.5181 50.5171 29.9799 50.4995 30.5319C50.2519 38.2951 50.497 44.1909 50.7458 50.1758L50.7492 50.2558C50.9993 56.2717 51.2508 62.3838 50.9996 70.5308C50.9825 71.0829 50.5212 71.5166 49.9692 71.4995C49.4172 71.4825 48.9835 71.0212 49.0005 70.4692C49.2493 62.4 49.0008 56.3499 48.7509 50.3388L48.747 50.2446C48.4982 44.2617 48.2506 38.3049 48.5005 30.4681C48.5181 29.9161 48.9799 29.4829 49.5319 29.5005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M45.5319 29.7505C46.0839 29.7681 46.5171 30.2299 46.4995 30.7819C46.2519 38.5451 46.497 44.4409 46.7458 50.4258L46.7492 50.5058C46.9993 56.5217 47.2508 62.6338 46.9996 70.7808C46.9825 71.3329 46.5212 71.7666 45.9692 71.7495C45.4172 71.7325 44.9835 71.2712 45.0005 70.7192C45.2493 62.65 45.0008 56.5999 44.7509 50.5888L44.747 50.4946C44.4982 44.5117 44.2506 38.5549 44.5005 30.7181C44.5181 30.1661 44.9799 29.7329 45.5319 29.7505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M41.5319 30.0005C42.0839 30.0181 42.5171 30.4799 42.4995 31.0319C42.2519 38.7951 42.497 44.6909 42.7458 50.6758L42.7492 50.7558C42.9993 56.7717 43.2508 62.8838 42.9996 71.0308C42.9825 71.5829 42.5212 72.0166 41.9692 71.9995C41.4172 71.9825 40.9835 71.5212 41.0005 70.9692C41.2493 62.9 41.0008 56.8499 40.7509 50.8388L40.747 50.7446C40.4982 44.7617 40.2506 38.8049 40.5005 30.9681C40.5181 30.4161 40.9799 29.9829 41.5319 30.0005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M37.5319 30.2505C38.0839 30.2681 38.5171 30.7299 38.4995 31.2819C38.2519 39.0451 38.497 44.9409 38.7458 50.9258L38.7492 51.0058C38.9993 57.0217 39.2508 63.1338 38.9996 71.2808C38.9825 71.8329 38.5212 72.2666 37.9692 72.2495C37.4172 72.2325 36.9835 71.7712 37.0005 71.2192C37.2493 63.15 37.0008 57.0999 36.7509 51.0888L36.747 50.9946C36.4982 45.0117 36.2506 39.0549 36.5005 31.2181C36.5181 30.6661 36.9799 30.2329 37.5319 30.2505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M33.5319 30.5005C34.0839 30.5181 34.5171 30.9799 34.4995 31.5319C34.2519 39.2951 34.497 45.1909 34.7458 51.1758L34.7492 51.2558C34.9993 57.2717 35.2508 63.3838 34.9996 71.5308C34.9825 72.0829 34.5212 72.5166 33.9692 72.4995C33.4172 72.4825 32.9835 72.0212 33.0005 71.4692C33.2493 63.4 33.0008 57.3499 32.7509 51.3388L32.747 51.2446C32.4982 45.2617 32.2506 39.3049 32.5005 31.4681C32.5181 30.9161 32.9799 30.4829 33.5319 30.5005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M29.5319 30.7505C30.0839 30.7681 30.5171 31.2299 30.4995 31.7819C30.2519 39.5451 30.497 45.4409 30.7458 51.4258L30.7492 51.5058C30.9993 57.5217 31.2508 63.6338 30.9996 71.7808C30.9825 72.3329 30.5212 72.7666 29.9692 72.7495C29.4172 72.7325 28.9835 72.2712 29.0005 71.7192C29.2493 63.65 29.0008 57.5999 28.7509 51.5888L28.747 51.4946C28.4982 45.5117 28.2506 39.5549 28.5005 31.7181C28.5181 31.1661 28.9799 30.7329 29.5319 30.7505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.5319 31.0005C26.0839 31.0181 26.5171 31.4799 26.4995 32.0319C26.2519 39.7951 26.497 45.6909 26.7458 51.6758L26.7492 51.7558C26.9993 57.7717 27.2508 63.8838 26.9996 72.0308C26.9825 72.5829 26.5212 73.0166 25.9692 72.9995C25.4172 72.9825 24.9835 72.5212 25.0005 71.9692C25.2493 63.9 25.0008 57.8499 24.7509 51.8388L24.747 51.7446C24.4982 45.7617 24.2506 39.8049 24.5005 31.9681C24.5181 31.4161 24.9799 30.9829 25.5319 31.0005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M21.5319 31.2505C22.0839 31.2681 22.5171 31.7299 22.4995 32.2819C22.2519 40.0451 22.497 45.9409 22.7458 51.9258L22.7492 52.0058C22.9993 58.0217 23.2508 64.1338 22.9996 72.2808C22.9825 72.8329 22.5212 73.2666 21.9692 73.2495C21.4172 73.2325 20.9835 72.7712 21.0005 72.2192C21.2493 64.15 21.0008 58.0999 20.7509 52.0888L20.747 51.9946C20.4982 46.0117 20.2506 40.0549 20.5005 32.2181C20.5181 31.6661 20.9799 31.2329 21.5319 31.2505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M17.5319 31.5005C18.0839 31.5181 18.5171 31.9799 18.4995 32.5319C18.2519 40.2951 18.497 46.1909 18.7458 52.1758L18.7492 52.2558C18.9993 58.2717 19.2508 64.3838 18.9996 72.5308C18.9825 73.0829 18.5212 73.5166 17.9692 73.4995C17.4172 73.4825 16.9835 73.0212 17.0005 72.4692C17.2493 64.4 17.0008 58.3499 16.7509 52.3388L16.747 52.2446C16.4982 46.2617 16.2506 40.3049 16.5005 32.4681C16.5181 31.9161 16.9799 31.4829 17.5319 31.5005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M13.5319 31.7505C14.0839 31.7681 14.5171 32.2299 14.4995 32.7819C14.2519 40.5451 14.497 46.4409 14.7458 52.4258L14.7492 52.5058C14.9993 58.5217 15.2508 64.6338 14.9996 72.7808C14.9825 73.3329 14.5212 73.7666 13.9692 73.7495C13.4172 73.7325 12.9835 73.2712 13.0005 72.7192C13.2493 64.65 13.0008 58.5999 12.7509 52.5888L12.747 52.4946C12.4982 46.5117 12.2506 40.5549 12.5005 32.7181C12.5181 32.1661 12.9799 31.7329 13.5319 31.7505Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M9.13542 32.0005C9.68743 32.0181 10.1206 32.4799 10.103 33.0319C9.85541 40.7951 10.1005 46.6909 10.3494 52.6758L10.3527 52.7558C10.6028 58.7717 10.8543 64.8838 10.6031 73.0308C10.586 73.5829 10.1247 74.0166 9.57272 73.9995C9.0207 73.9825 8.587 73.5212 8.60402 72.9692C8.85282 64.9 8.6043 58.8499 8.3544 52.8388L8.35049 52.7446C8.10174 46.7617 7.85408 40.8049 8.10405 32.9681C8.12166 32.4161 8.58342 31.9829 9.13542 32.0005Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.9518 44.2662C24.7667 45.1202 13.491 45.9105 4.50917 45.9929C3.95691 45.998 3.50511 45.5544 3.50004 45.0021C3.49498 44.4499 3.93857 43.9981 4.49083 43.993C13.4069 43.9112 24.6146 43.1258 36.8136 42.2709C38.4289 42.1577 40.0617 42.0433 41.7088 41.9291C55.7747 40.9542 70.8636 40 85 40C85.5523 40 86 40.4477 86 41C86 41.5523 85.5523 42 85 42C70.9407 42 55.9146 42.9493 41.8471 43.9244C40.2015 44.0384 38.5686 44.1529 36.9518 44.2662Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M84.1646 36.9996C84.1678 37.5518 83.7226 38.0021 83.1703 38.0052C68.9812 38.0859 53.9047 39.0822 39.8847 40.0609C38.8974 40.1298 37.9152 40.1986 36.9387 40.2671C24.0708 41.169 12.2139 42 3 42C2.44772 42 2 41.5523 2 41C2 40.4477 2.44772 40 3 40C12.1401 40 23.9278 39.1739 36.8268 38.27C37.7936 38.2022 38.7668 38.134 39.7455 38.0657C53.7605 37.0874 68.8972 36.0864 83.159 36.0053C83.7113 36.0021 84.1615 36.4473 84.1646 36.9996Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.9441 36.2667C24.0732 37.1688 12.2144 38 3 38C2.44772 38 2 37.5523 2 37C2 36.4477 2.44772 36 3 36C12.1397 36 23.9254 35.1741 36.8214 34.2704C38.0887 34.1816 39.3667 34.092 40.6539 34.0024C55.0187 33.0026 70.51 32 85 32C85.5523 32 86 32.4477 86 33C86 33.5523 85.5523 34 85 34C70.5884 34 55.1616 34.9974 40.7928 35.9976C39.501 36.0875 38.2176 36.1775 36.9441 36.2667Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.9441 48.2667C24.0732 49.1688 12.2144 50 3 50C2.44772 50 2 49.5523 2 49C2 48.4477 2.44772 48 3 48C12.1397 48 23.9254 47.1741 36.8214 46.2704C38.0887 46.1816 39.3667 46.092 40.6539 46.0024C55.0187 45.0026 70.51 44 85 44C85.5523 44 86 44.4477 86 45C86 45.5523 85.5523 46 85 46C70.5884 46 55.1616 46.9974 40.7928 47.9976C39.501 48.0875 38.2176 48.1775 36.9441 48.2667Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.9441 52.2667C24.0732 53.1688 12.2144 54 3 54C2.44772 54 2 53.5523 2 53C2 52.4477 2.44772 52 3 52C12.1397 52 23.9254 51.1741 36.8214 50.2704C38.0887 50.1816 39.3667 50.092 40.6539 50.0024C55.0187 49.0026 70.51 48 85 48C85.5523 48 86 48.4477 86 49C86 49.5523 85.5523 50 85 50C70.5884 50 55.1616 50.9974 40.7928 51.9976C39.501 52.0875 38.2176 52.1775 36.9441 52.2667Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.9441 56.2667C24.0732 57.1688 12.2144 58 3 58C2.44772 58 2 57.5523 2 57C2 56.4477 2.44772 56 3 56C12.1397 56 23.9254 55.1741 36.8214 54.2704C38.0887 54.1816 39.3667 54.092 40.6539 54.0024C55.0187 53.0026 70.51 52 85 52C85.5523 52 86 52.4477 86 53C86 53.5523 85.5523 54 85 54C70.5884 54 55.1616 54.9974 40.7928 55.9976C39.501 56.0875 38.2176 56.1775 36.9441 56.2667Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M84.3712 56.9991C84.374 57.5514 83.9286 58.0013 83.3763 58.0041C69.1617 58.0761 54.0457 59.0727 39.9866 60.0538C38.9651 60.125 37.9492 60.1963 36.9394 60.267C24.0711 61.1689 12.214 62 3 62C2.44772 62 2 61.5523 2 61C2 60.4477 2.44772 60 3 60C12.1401 60 23.9275 59.174 36.8262 58.27C37.8268 58.1999 38.8341 58.1293 39.8473 58.0586C53.9016 57.0779 69.0782 56.0765 83.3662 56.0042C83.9185 56.0014 84.3684 56.4468 84.3712 56.9991Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M36.9504 64.2663C24.6244 65.1302 13.2285 65.9289 4.19585 65.9955C3.64358 65.9996 3.19258 65.5552 3.1885 65.0029C3.18443 64.4506 3.62883 63.9996 4.1811 63.9956C13.1462 63.9295 24.473 63.1357 36.815 62.2708C38.359 62.1626 39.9188 62.0533 41.492 61.9442C55.6197 60.964 70.791 60 85 60C85.5523 60 86 60.4477 86 61C86 61.5523 85.5523 62 85 62C70.8684 62 55.7602 62.9591 41.6304 63.9394C40.0576 64.0485 38.4967 64.1579 36.9504 64.2663Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M83.7178 65.001C83.7217 65.5533 83.2771 66.0042 82.7248 66.008C68.5913 66.1073 53.6003 67.1029 39.6649 68.0762C38.7511 68.14 37.8418 68.2037 36.9374 68.2671C24.0702 69.169 12.2138 69.9999 3 69.9999C2.44772 69.9999 2 69.5522 2 68.9999C2 68.4477 2.44772 67.9999 3 67.9999C12.1402 67.9999 23.9284 67.1739 36.8281 66.2699C37.7221 66.2072 38.6214 66.1442 39.5255 66.081C53.4557 65.1081 68.506 64.1078 82.7108 64.0081C83.2631 64.0042 83.7139 64.4488 83.7178 65.001Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M57.8803 24.9305L58.7447 19.8272C59.3827 16.0612 56.9374 12.4621 53.2012 11.668L53.079 11.642C49.2975 10.8382 45.5804 13.2521 44.7766 17.0337C43.9728 20.8152 46.3867 24.5323 50.1682 25.3361L55.5916 26.4888L50.8608 55.073C50.8235 55.1809 50.8056 55.2926 50.8061 55.4034L47.2482 76.9012C47.158 77.4461 47.5266 77.9609 48.0715 78.051C48.6163 78.1412 49.1312 77.7726 49.2213 77.2277L52.5608 57.0501L54.457 58.2351L49.4938 77.2856C49.3546 77.82 49.675 78.3662 50.2094 78.5054C50.7439 78.6446 51.29 78.3243 51.4292 77.7898L64.3117 28.3424L64.8404 28.4547C64.8435 28.4554 64.8465 28.456 64.8495 28.4566L69.7313 29.4943C73.5128 30.2981 77.2299 27.8841 78.0337 24.1026C78.8375 20.3211 76.4236 16.604 72.6421 15.8002L72.5198 15.7742C68.7837 14.9801 65.0859 17.2734 64.1369 20.9733L62.8509 25.9872L62.3218 25.8747C61.7816 25.7599 61.2506 26.1047 61.1358 26.645C61.0209 27.1852 61.3658 27.7162 61.906 27.831L62.3534 27.9261L56.9715 48.5835L54.2441 46.8792L57.5499 26.905L57.9938 26.9993C58.534 27.1141 59.065 26.7693 59.1798 26.2291C59.2947 25.6889 58.9498 25.1578 58.4096 25.043L57.8803 24.9305ZM55.459 24.416C55.4644 24.417 55.4698 24.4181 55.4751 24.4193L55.9223 24.5143L56.7728 19.4932C57.2317 16.7843 55.4728 14.1955 52.7854 13.6243L52.6632 13.5983C49.9621 13.0242 47.307 14.7484 46.7329 17.4495C46.1588 20.1506 47.883 22.8056 50.5841 23.3798L55.459 24.416ZM53.8904 49.0166L56.3316 50.542C56.3687 50.5652 56.4066 50.5856 56.4453 50.6034L54.9854 56.2069L52.9145 54.9128L53.8904 49.0166ZM66.0742 21.4702L64.8089 26.4033L70.1471 27.538C72.8482 28.1121 75.5033 26.3879 76.0774 23.6868C76.6515 20.9857 74.9273 18.3306 72.2262 17.7565L72.104 17.7305C69.4166 17.1593 66.7568 18.8089 66.0742 21.4702Z"
				fill={outline}
			/>
			<path
				d="M25.4989 46.9148C25.4267 46.0895 26.0372 45.362 26.8625 45.2898L33.8358 44.6797C34.6611 44.6075 35.3886 45.218 35.4608 46.0433L37.7705 72.4424C37.963 74.6432 36.335 76.5833 34.1343 76.7758L32.1419 76.9501C29.9412 77.1427 28.0011 75.5147 27.8085 73.314L25.4989 46.9148Z"
				fill={fillDark}
			/>
			<path
				d="M25.7542 38.36L33.7237 37.6628L33.2101 31.7924C33.2101 31.7924 32.902 28.2701 32.3884 22.3997C31.3794 10.8674 26.4659 7.10599 24.1241 5.95622C23.5246 5.6619 22.9364 6.15241 22.9946 6.81768L25.7542 38.36Z"
				fill={fillLight}
			/>
			<path
				d="M24.8016 38.9453C24.7294 38.12 25.3399 37.3925 26.1651 37.3203L33.1385 36.7102C33.9638 36.638 34.6913 37.2484 34.7635 38.0737L34.8507 39.0699C34.9229 39.8952 34.3124 40.6227 33.4871 40.6949L26.5138 41.305C25.6885 41.3772 24.9609 40.7668 24.8887 39.9415L24.8016 38.9453Z"
				fill={background}
			/>
			<path
				d="M25.1502 42.9301C25.078 42.1048 25.6885 41.3773 26.5137 41.3051L33.4871 40.695C34.3124 40.6228 35.0399 41.2333 35.1121 42.0585L35.1993 43.0547C35.2715 43.88 34.661 44.6075 33.8357 44.6797L26.8624 45.2898C26.0371 45.362 25.3095 44.7516 25.2373 43.9263L25.1502 42.9301Z"
				fill={background}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M23.8052 39.0321C23.7339 38.2172 24.062 37.4593 24.6274 36.9521L21.9986 6.90456C21.9418 6.25559 22.1995 5.63064 22.6945 5.24103C23.2081 4.83672 23.9164 4.73986 24.565 5.0583C27.2519 6.37751 32.3485 10.4668 33.3848 22.3122L34.5894 36.0811C35.2339 36.4826 35.6882 37.1717 35.7595 37.9863L35.8467 38.9825C35.8957 39.5431 35.7557 40.0768 35.4795 40.5204C35.8285 40.9093 36.0591 41.4105 36.1081 41.9711L36.1953 42.9673C36.2443 43.528 36.1044 44.0616 35.8281 44.5052C36.1772 44.894 36.4077 45.3952 36.4568 45.9559L38.7664 72.355C39.0071 75.106 36.9721 77.5311 34.2212 77.7718L32.2288 77.9461C29.4779 78.1868 27.0527 76.1518 26.8121 73.4009L24.5024 47.0017C24.4534 46.4411 24.5934 45.9075 24.8696 45.4639C24.5205 45.075 24.29 44.5739 24.241 44.0132L24.1538 43.017C24.1047 42.4563 24.2448 41.9227 24.521 41.4791C24.1719 41.0902 23.9414 40.589 23.8923 40.0283L23.8052 39.0321ZM33.7541 43.6828L33.7484 43.6833L26.775 44.2934L26.7693 44.2939C26.4966 44.3147 26.2572 44.1121 26.2333 43.8389L26.1462 42.8427C26.1221 42.5676 26.3256 42.3251 26.6007 42.301L33.5741 41.6909C33.8492 41.6669 34.0917 41.8704 34.1158 42.1455L34.2029 43.1416C34.2268 43.4148 34.0263 43.6559 33.7541 43.6828ZM26.9437 46.2863C26.6715 46.3132 26.4709 46.5542 26.4948 46.8274L28.8045 73.2266C28.9489 74.8772 30.404 76.0981 32.0545 75.9537L34.0469 75.7794C35.6974 75.635 36.9184 74.1799 36.774 72.5293L34.4644 46.1302C34.4405 45.857 34.2011 45.6544 33.9284 45.6752L33.9227 45.6757L26.9493 46.2858L26.9437 46.2863ZM32.5535 35.7572L26.5763 36.2801L24.017 7.02755C26.1631 8.21284 30.462 11.8519 31.3924 22.4866L32.5535 35.7572ZM26.2521 38.3162C25.977 38.3402 25.7735 38.5827 25.7976 38.8578L25.8847 39.854C25.9088 40.1291 26.1513 40.3326 26.4264 40.3085L33.3998 39.6985C33.6748 39.6744 33.8783 39.4319 33.8543 39.1568L33.7671 38.1606C33.7431 37.8855 33.5005 37.682 33.2254 37.7061L26.2521 38.3162Z"
				fill={outline}
			/>
			<path
				d="M39 6L40.0313 8.78706C40.2339 9.3345 40.6655 9.76612 41.2129 9.96869L44 11L41.2129 12.0313C40.6655 12.2339 40.2339 12.6655 40.0313 13.2129L39 16L37.9687 13.2129C37.7661 12.6655 37.3345 12.2339 36.7871 12.0313L34 11L36.7871 9.96869C37.3345 9.76612 37.7661 9.3345 37.9687 8.78706L39 6Z"
				fill={fillLight}
			/>
			<path
				d="M17 20L17.7612 22.0572C17.9638 22.6046 18.3954 23.0362 18.9428 23.2388L21 24L18.9428 24.7612C18.3954 24.9638 17.9638 25.3954 17.7612 25.9428L17 28L16.2388 25.9428C16.0362 25.3954 15.6046 24.9638 15.0572 24.7612L13 24L15.0572 23.2388C15.6046 23.0362 16.0362 22.6046 16.2388 22.0572L17 20Z"
				fill={fillLight}
			/>
		</svg>
	);
}

export default WoundVascular;
