import * as React from 'react';

import { CancelButton } from 'components/Buttons';
import { ButtonType, DialogModal, Message } from 'display';

interface ErrorModalProps {
	content?: string;
	heading: string;
	onCancel: () => void;
	open: boolean;
}

function ErrorModal({ content, heading, open, onCancel }: ErrorModalProps): JSX.Element {
	return (
		<DialogModal open={open} data-testid="errorModal">
			<Message negative={true}>
				<Message.Header>{heading}</Message.Header>
				<Message.Content>{content}</Message.Content>
			</Message>
			<DialogModal.Actions>
				<CancelButton buttonType={ButtonType.PRIMARY} onClick={onCancel} />
			</DialogModal.Actions>
		</DialogModal>
	);
}

export default ErrorModal;
