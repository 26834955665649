import * as React from 'react';

import { Button, CardModal } from 'display';
import { DismissButton, DisableButton } from 'components/Buttons';

interface DisablePatientCommunicationsModalProps {
	onCancel: () => void;
	onConfirm: () => void;
	open: boolean;
}

function DisablePatientCommunicationsModal({
	onCancel,
	onConfirm,
	open
}: DisablePatientCommunicationsModalProps): JSX.Element {
	return (
		<CardModal open={open} data-testid="disablePatientCommunicationsModal">
			<CardModal.Title>Disable vMessages and sharing of vConsults?</CardModal.Title>
			<CardModal.Description>
				By disabling these features, any sharing links previously sent to patients will be
				disabled and are non-recoverable.
			</CardModal.Description>
			<Button.Group>
				<DismissButton onClick={onCancel} />
				<DisableButton onClick={onConfirm} />
			</Button.Group>
		</CardModal>
	);
}

export { DisablePatientCommunicationsModal, DisablePatientCommunicationsModalProps };
