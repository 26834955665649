import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent } from '../DataFormTypes';

const FIELD_NAME = 'textMessagePermission';
const LABEL = 'Send text message updates';

const TextingPermission: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default TextingPermission;
