import * as React from 'react';
import { css, cx } from 'emotion';

import Button from '../shims/Button';

interface InputWrapperProps {
	button?: boolean;
	buttonStyle?: typeof Button.variations[keyof typeof Button.variations];
	children: React.ReactNode;
	className?: string;
	htmlFor: string;
	label: string;
}

const styles = {
	wrapper: css`
		// Hides the default file input
		& input {
			width: 0.1px;
			height: 0.1px;
			opacity: 0;
			overflow: hidden;
			position: absolute;
			z-index: -1;
		}
	`
};

function getLabelClassNames({
	button,
	buttonStyle,
	className
}: Pick<InputWrapperProps, 'button' | 'buttonStyle' | 'className'>): string {
	const classNames: string[] = [];

	if (button && buttonStyle) {
		classNames.push('ui', 'button', buttonStyle, Button.responsiveStyling);
	}

	if (className) {
		classNames.push(className);
	}

	return cx(...classNames);
}

function InputWrapper({
	button = true,
	buttonStyle = Button.variations.primary,
	className,
	children,
	htmlFor,
	label
}: InputWrapperProps): JSX.Element {
	return (
		<div className={styles.wrapper}>
			<label
				className={getLabelClassNames({
					button,
					buttonStyle,
					className
				})}
				htmlFor={htmlFor}>
				{label}
			</label>
			{children}
		</div>
	);
}

export default InputWrapper;
export { InputWrapperProps };
