import Link from 'components/Link';
import { SemanticICONS } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { css } from 'emotion';
import { colors } from 'styles';

const styles = {
	link: css`
		color: ${colors.greyDark} !important;
		&hover {
			color: ${colors.greyDark} !important;
		}
	`
};

interface ViewPartnerDetailsActionProps {
	data: Partner;
	children: React.ReactNode;
}

function ViewPartnerDetailsAction({
	data,
	children
}: ViewPartnerDetailsActionProps): JSX.Element | null {
	const { partner_id: partnerId } = data;
	const link = RouteUrls.buildUrl(RouteUrls.Key.PARTNER_DETAIL, { partnerId });
	return (
		<Link to={link} className={styles.link}>
			{children}
		</Link>
	);
}

ViewPartnerDetailsAction.ICON = 'eye' as SemanticICONS;
ViewPartnerDetailsAction.TITLE = 'View Partner Details';

export default ViewPartnerDetailsAction;
