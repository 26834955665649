import { DataFormFieldComponent } from '../DataFormTypes';
import buildFieldGroup from './base/buildFieldGroup';
import buildCheckboxField from './base/buildCheckboxField';
import { DailyAvailability as DailyAvailabilityType } from 'thriftgen/api_types';
import { capitalizeFirstLetter } from 'lib/stringConverter';

const FIELD_NAME = 'dailyAvailability';
const FIELD_LABEL = 'Select days that the specialist is available to respond';

const DailyAvailabilityTypeObject = new DailyAvailabilityType();
const dayFields = Object.keys(DailyAvailabilityTypeObject).map(day => {
	const field: DataFormFieldComponent = buildCheckboxField({
		fieldName: day,
		label: capitalizeFirstLetter(day)
	});
	return field;
});

const DailyAvailability: DataFormFieldComponent = buildFieldGroup({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	fields: dayFields,
	groupProps: { grouped: true }
});

export default DailyAvailability;
