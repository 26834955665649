import { buildIsAdmin } from './buildIsAdmin';
import { buildLinkToDetail } from './buildLinkToDetail';
import { buildName } from './buildName';
import { buildActions } from './buildActions';

const partnerColumns = {
	buildIsAdmin,
	buildLinkToDetail,
	buildName,
	buildActions
};

export { partnerColumns };
