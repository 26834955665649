import { useReducer, useEffect } from 'react';
import bindActionCreators from 'lib/bindActionCreators';

import { PaginationContextValue } from 'contexts/PaginationContext';

import { getFilter, parameterActionCreators, parametersReducer } from './parameters';

import { hasNextPage, hasPrevPage, markersReducer, markerActionCreators } from './markers';

import {
	Filter,
	MarkerActionCreators,
	MarkerActions,
	MarkersReducer,
	ParameterActionCreators,
	ParameterActions,
	ParametersReducer,
	Parameters
} from './types';

function usePagination(
	pageSize?: Parameters['pageSize'],
	storageKey?: string,
	initialFilters?: Filter[]
): PaginationContextValue {
	const initialState = {
		filters: initialFilters || [],
		sorts: [],
		pageSize,
		pageCursor: null
	};

	let localState;
	if (storageKey) {
		const localStateStorage = localStorage.getItem(storageKey);
		if (localStateStorage) {
			localState = JSON.parse(localStateStorage);
		}
	}

	const [parameters, parametersDispatch] = useReducer<ParametersReducer>(
		parametersReducer,
		localState || initialState
	);

	useEffect(() => {
		if (storageKey) {
			localStorage.setItem(storageKey, JSON.stringify(parameters));
		}
	}, [storageKey, parameters]);

	const [markers, markersDispatch] = useReducer<MarkersReducer>(markersReducer, {
		nextPageCursor: null,
		prevPageCursor: null
	});

	const parameterMethods = bindActionCreators<ParameterActionCreators, ParameterActions>(
		parametersDispatch,
		parameterActionCreators
	);
	const markerMethods = bindActionCreators<MarkerActionCreators, MarkerActions>(
		markersDispatch,
		markerActionCreators
	);

	const setNextPage = () => parameterMethods.setPageCursor(markers.nextPageCursor);
	const setPrevPage = () => parameterMethods.setPageCursor(markers.prevPageCursor);

	return {
		parameters,
		...parameterMethods,
		...markerMethods,
		getFilter: (attribute: string) => getFilter(parameters, attribute),
		hasNextPage: () => hasNextPage(markers),
		hasPrevPage: () => hasPrevPage(markers),
		setNextPage,
		setPrevPage
	};
}

export default usePagination;
