import * as React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'display';
import { getDaysInMonth } from 'lib/date';

const FIELD_NAME = 'day';

function hasError({ fields }) {
	return fields.includes(FIELD_NAME);
}

function getOptions({ year = 1, month = 1 }) {
	return Array.from(Array(getDaysInMonth(year, month)), (_, index) => {
		const day = index + 1;

		return {
			key: day,
			text: day,
			value: day
		};
	});
}

function getValue(data) {
	return data[FIELD_NAME];
}

function BirthDay({ onChange, data, errors }) {
	return (
		<Form.Select
			id={FIELD_NAME}
			error={hasError(errors)}
			search
			fluid
			label="Day of birth"
			placeholder="8"
			name={FIELD_NAME}
			options={getOptions(data)}
			value={getValue(data)}
			onChange={onChange}
			selectOnBlur={false}
		/>
	);
}

BirthDay.FIELD_NAME = FIELD_NAME;

BirthDay.defaultProps = {
	data: {},
	errors: {
		fields: []
	}
};

BirthDay.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.shape({
		[FIELD_NAME]: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	})
};

export default BirthDay;
