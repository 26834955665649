import {
	handleSendGetPartnersResponse,
	handleSinglePartnerResponse
} from 'store/admin/admin.actions';
import { apiCallerWithToast, apiCaller } from 'store/requests';
import { createPartner } from 'api/admin';
import { getPartners } from 'api/common';

const sendCreatePartnerWithToast = apiCallerWithToast({
	apiCall: createPartner,
	action: handleSinglePartnerResponse
});

const sendGetPartners = apiCaller({
	apiCall: getPartners,
	action: handleSendGetPartnersResponse
});

export { sendCreatePartnerWithToast, sendGetPartners };
