import { DataTableColumn } from 'components/DataTable/DataTable';
import { Tag } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import * as React from 'react';
import { mediaQueries } from 'styles';
import { ConsultStateSimplified } from 'thriftgen/api_types';

const styles = {
	newMessageIcon: css`
		position: absolute;
		top: 14px;
		right: 0;

		${mediaQueries.desktop} {
			position: static;
		}
	`
};

export default (): DataTableColumn<ConsultComposite> => ({
	render: ({ simplifiedState }: ConsultComposite) =>
		simplifiedState === ConsultStateSimplified.NOT_RESPONDED && (
			<div className={styles.newMessageIcon}>
				<Tag.New>New</Tag.New>
			</div>
		)
});
