import { ApiMethod, ApiResponse } from 'api/types';
import { ThunkAction } from 'store/types';
import * as ACTION_STATUS from 'store/actionStatus';

import { CLEAR_REQUEST, REGISTER_REQUEST, UPDATE_REQUEST } from './requests.reducers';

import {
	ApiCallerOptions,
	ClearRequestAction,
	RegisterRequestAction,
	Request,
	UpdateRequestAction
} from './requests.types';

import { handleInvalidRequestErrorCodes, getErrorMessage, OFFLINE_ERROR } from 'api/errorHandling';

const TOAST_TIMEOUT = 5000;

export function registerRequest({ id, toast }: Request): RegisterRequestAction {
	return {
		type: REGISTER_REQUEST,
		id,
		toast
	};
}

export function clearRequest(id: Request['id']): ClearRequestAction {
	return {
		type: CLEAR_REQUEST,
		id
	};
}

export function updateRequest({ id, message, status }: Request): UpdateRequestAction {
	return {
		type: UPDATE_REQUEST,
		id,
		message,
		status
	};
}

function clearRequestWithTimeout(id: Request['id'], delay: number): ThunkAction<void> {
	return dispatch => {
		window.setTimeout(() => {
			dispatch(clearRequest(id));
		}, delay);
	};
}

function normalizeApiParameters(apiCallParameters: unknown[]): unknown | unknown[] {
	if (apiCallParameters.length === 1) {
		return apiCallParameters[0];
	}

	return apiCallParameters;
}

function getSuccessMessage(
	onSuccess: ApiMethod['onSuccess'],
	request: unknown[],
	response: ApiResponse
): string | null {
	if (onSuccess !== undefined) {
		return onSuccess({ request: normalizeApiParameters(request), response });
	}

	return null;
}

function executeRequest(
	id: Request['id'],
	apiCallerOpts: ApiCallerOptions,
	...actionArgs: unknown[]
): ThunkAction<Promise<ApiResponse>> {
	const { apiCall, action, middleware } = apiCallerOpts;

	return async dispatch => {
		dispatch(
			updateRequest({
				id,
				status: ACTION_STATUS.IN_PROGRESS
			})
		);

		try {
			const apiCallParameters = middleware
				? [dispatch(middleware(...actionArgs))]
				: actionArgs;
			const response = await apiCall(...apiCallParameters);
			const message = getSuccessMessage(apiCall.onSuccess, apiCallParameters, response);

			if (action) {
				dispatch(action(response, ...actionArgs));
			}

			dispatch(
				updateRequest({
					id,
					status: ACTION_STATUS.SUCCESS,
					message
				})
			);

			return Promise.resolve(response);
		} catch (error) {
			handleInvalidRequestErrorCodes(error, dispatch);
			const errorMessage = getErrorMessage(error);

			dispatch(
				updateRequest({
					id,
					status: ACTION_STATUS.FAILURE,
					message: errorMessage
				})
			);

			if (errorMessage === OFFLINE_ERROR) {
				return Promise.resolve();
			} else {
				return Promise.reject({ error: errorMessage });
			}
		}
	};
}

export function apiCaller(
	apiCallerOpts: ApiCallerOptions
): (id: Request['id'], actionArgs: unknown) => ThunkAction<Promise<ApiResponse>> {
	return (id: Request['id'], ...actionArgs: unknown[]): ThunkAction<Promise<ApiResponse>> =>
		dispatch => {
			return dispatch(executeRequest(id, apiCallerOpts, ...actionArgs));
		};
}

export function apiCallerWithToast(
	apiCallerOpts: ApiCallerOptions
): (actionArgs?: unknown) => ThunkAction<Promise<ApiResponse>> {
	return (...actionArgs: unknown[]): ThunkAction<Promise<ApiResponse>> =>
		dispatch => {
			const id = Symbol(apiCallerOpts.apiCall.name);

			dispatch(
				registerRequest({
					id,
					status: ACTION_STATUS.INACTIVE,
					toast: true
				})
			);

			const request = dispatch(executeRequest(id, apiCallerOpts, ...actionArgs));

			request
				.then(() => {
					dispatch(clearRequestWithTimeout(id, TOAST_TIMEOUT));
				})
				.catch(() => {
					dispatch(clearRequestWithTimeout(id, TOAST_TIMEOUT));
				});

			return request;
		};
}
