import * as React from 'react';

import { Message, Paragraph } from 'display';

function DocumentNotViewableMessage(): JSX.Element {
	return (
		<Message info={true}>
			<Message.Header>Unsupported document</Message.Header>
			<Paragraph>This document type is not viewable.</Paragraph>
		</Message>
	);
}

export default DocumentNotViewableMessage;
