import * as React from 'react';
import { UserCamel } from 'thriftgen/thriftCamelTypes';

import { getReadableBirthDate, yearsSinceDate } from 'lib/date';

import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = UserCamel['dateOfBirth'] | null;
type DisplayDateOfBirthComponent = DataFormFieldComponent<string, DataType>;
type DisplayDateOfBirthComponentProps = DataFormFieldComponentProps<DataType>;

function getFieldContent(data: DisplayDateOfBirthComponentProps['data']): string | null {
	const dob = data[DisplayDateOfBirth.FIELD_NAME] as unknown as UserCamel['dateOfBirth'];

	if (dob) {
		const { day, month, year } = dob;

		return `${getReadableBirthDate(year, month, day)} (${yearsSinceDate(
			year,
			month,
			day
		)} years old)`;
	}

	return null;
}

const DisplayDateOfBirth: DisplayDateOfBirthComponent = ({
	data
}: DisplayDateOfBirthComponentProps): JSX.Element => {
	return <ReadOnlyField label="Birthday">{getFieldContent(data)}</ReadOnlyField>;
};

DisplayDateOfBirth.FIELD_NAME = 'dateOfBirth';
DisplayDateOfBirth.validate = alwaysValid;

export default DisplayDateOfBirth;
