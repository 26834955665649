import { SitkaLogger } from 'lib/sitkaLogger';
import { ConsultDataType } from 'thriftgen/api_types';
import {
	ConsultDataCamel,
	ConversationCamel,
	ConversationMessageCamel,
	UserCamel
} from 'thriftgen/thriftCamelTypes';
import { MissingContentError, MissingSenderError, UnsupportedContentError } from './errors';
import {
	CanonicalMessage,
	ConversationComposite,
	ConversationMessageComposite,
	MessageComposite,
	MessageType
} from './types';

function isConversationMessageComposite(
	message: MessageComposite
): message is ConversationMessageComposite {
	return message.error === null;
}

function getMessageType({ dataType, consultDataId }: ConsultDataCamel): MessageType {
	if (dataType === ConsultDataType.SPECIALIST_RESPONSE) {
		return 'specialist response';
	} else if (dataType === ConsultDataType.VIDEO) {
		return 'video';
	} else if (dataType === ConsultDataType.TEXT) {
		return 'text';
	} else {
		throw new UnsupportedContentError(
			`Received unexpected content type ${dataType} for message data ${consultDataId}`
		);
	}
}

function getContent({ content, messageId }: ConversationMessageCamel): ConsultDataCamel {
	if (!content) {
		throw new MissingContentError(`Missing content for message ${messageId}`);
	}

	return content;
}

function getSender(conversationMessage: ConversationMessageCamel, users: UserCamel[]): UserCamel {
	const sender = users.find(({ userId }) => userId === conversationMessage.senderId);

	if (!sender) {
		throw new MissingSenderError(`Missing sender for message ${conversationMessage.messageId}`);
	}

	return sender;
}

function buildCanonicalMessage(
	conversationMessage: ConversationMessageCamel,
	users: UserCamel[]
): CanonicalMessage {
	const content = getContent(conversationMessage);
	return {
		...conversationMessage,
		content,
		error: null,
		sharedFrom: null,
		sender: getSender(conversationMessage, users),
		messageType: getMessageType(content)
	};
}

function buildConversationMessageComposite(
	conversationMessage: ConversationMessageCamel,
	users: UserCamel[]
): ConversationMessageComposite {
	if (conversationMessage.sharedFrom !== null) {
		return {
			...conversationMessage,
			content: null,
			error: null,
			sender: getSender(conversationMessage, users),
			// We do not support infinite shared message chains so we stop it here.
			sharedFrom: buildCanonicalMessage(conversationMessage.sharedFrom, users)
		};
	} else {
		return buildCanonicalMessage(conversationMessage, users);
	}
}

function buildConversationComposite(
	conversation: ConversationCamel,
	users: UserCamel[]
): ConversationComposite {
	return {
		...conversation,
		messages: conversation.messages.map(message => {
			try {
				return buildConversationMessageComposite(message, users);
			} catch (error) {
				SitkaLogger.logMessage(error);
				return {
					...message,
					error
				};
			}
		})
	};
}

function transformConversationsToComposite(
	conversations: ConversationCamel[],
	users: UserCamel[]
): ConversationComposite[] {
	return conversations.map(conversation => buildConversationComposite(conversation, users));
}

function updateConversationInConversations(
	updatedConversation: ConversationCamel,
	conversations: ConversationCamel[]
): ConversationCamel[] {
	return conversations.map((conversation: ConversationCamel) => {
		if (conversation.conversationId === updatedConversation.conversationId) {
			return updatedConversation;
		}

		return conversation;
	});
}

function updateUsersInConversations(
	updatedUsers: UserCamel[],
	loadedUsers: UserCamel[]
): UserCamel[] {
	const usersById = {};

	[...loadedUsers, ...updatedUsers].forEach(user => (usersById[user.userId] = user));

	return Object.values(usersById);
}

export {
	isConversationMessageComposite,
	transformConversationsToComposite,
	updateConversationInConversations,
	updateUsersInConversations
};
