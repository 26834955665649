import BaseUploader from './BaseUploader';
import axios from 'axios';

function executeUpload({ onComplete, onError, onProgress }) {
	const uploadInstance = axios.put(this.presignedUrl, this.content, {
		headers: {
			'Content-Type': 'application/octet-stream'
		},
		onUploadProgress: ({ loaded, total }) => {
			this.uploaded = loaded;
			this.total = total;
			onProgress();
		}
	});

	uploadInstance.catch(onError);
	Promise.all([uploadInstance]).then(onComplete);

	return uploadInstance;
}

class PresignedUploader extends BaseUploader {
	constructor(props) {
		super(props);

		this.emitComplete = this.emitComplete.bind(this);
		this.emitError = this.emitError.bind(this);
		this.emitProgress = this.emitProgress.bind(this);
		this.uploadInstance = this.uploadInstance.bind(this);
	}

	emitComplete() {
		this.removeUploadEventListeners([
			PresignedUploader.EVENTS.BEFORE_UNLOAD,
			PresignedUploader.EVENTS.PROGRESS
		]);
		this.emit(PresignedUploader.EVENTS.COMPLETE);
	}

	retry() {
		this.registerBeforeUnloadListener();
		this.uploads.forEach(this.uploadInstance);
	}

	upload(presignedUrl, content) {
		this.emit(PresignedUploader.EVENTS.START);
		this.registerBeforeUnloadListener();
		const instance = {
			presignedUrl,
			content,
			total: content.size
		};

		this.uploadInstance(instance);
		this.uploads.push(instance);
	}

	uploadInstance(instance) {
		instance.uploaded = 0;
		instance.upload = executeUpload.call(instance, {
			onComplete: this.emitComplete,
			onError: this.emitError,
			onProgress: this.emitProgress
		});
	}
}

export default PresignedUploader;
