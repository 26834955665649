import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

interface PostMessageButtonProps {
	disabled?: ButtonProps['disabled'];
	onClick: ButtonProps['onClick'];
}

function PostMessageButton({ disabled = false, onClick }: PostMessageButtonProps): JSX.Element {
	return (
		<Button.Primary disabled={disabled} onClick={onClick} loading={disabled}>
			{Strings.Buttons.POST_MESSAGE}
		</Button.Primary>
	);
}

export default PostMessageButton;
