import { getAdminClient } from 'lib/ThriftClient';
import { UpdatePartnerRequestCamel, UpdatePartnerResponseCamel } from 'thriftgen/thriftCamelTypes';
import { transformMutations } from './partnerHelpers';

function updatePartner({
	partnerId,
	mutations
}: UpdatePartnerRequestCamel): Promise<UpdatePartnerResponseCamel> {
	const client = getAdminClient();
	const request = {
		partner_id: partnerId,
		mutations: transformMutations(mutations)
	};

	return client.update_partner(request);
}

export default updatePartner;
