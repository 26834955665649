import { updateConsultSummary } from 'api/admin';
import * as React from 'react';
import { ConsultSummaryCamel, UpdateConsultSummaryRequestCamel } from 'thriftgen/thriftCamelTypes';

type UpdateDestinationMutator = (
	args: Pick<UpdateConsultSummaryRequestCamel, 'consultId' | 'destination'>
) => Promise<void>;
type UpdateStatusMutator = (
	args: Pick<UpdateConsultSummaryRequestCamel, 'consultId' | 'status'>
) => Promise<void>;
type SendSummaryMutator = (
	args: Pick<UpdateConsultSummaryRequestCamel, 'consultId'>
) => Promise<void>;

interface ConsultSummariesContextMutators {
	updateDestination: UpdateDestinationMutator;
	updateStatus: UpdateStatusMutator;
	sendSummary: SendSummaryMutator;
	revalidate: () => Promise<void>;
}

interface ConsultSummariesContextData {
	consultSummaries: ConsultSummaryCamel[];
}

type ConsultSummariesContextValue = ConsultSummariesContextMutators & ConsultSummariesContextData;

const ConsultSummariesContext = React.createContext<ConsultSummariesContextValue | undefined>(
	undefined
);

function useConsultSummariesContext(): ConsultSummariesContextValue {
	const context = React.useContext(ConsultSummariesContext);

	if (context === undefined) {
		throw new Error('No consult summaries have been set');
	}

	return context;
}

function buildConsultSummariesMutators(
	revalidate: () => Promise<void>
): ConsultSummariesContextMutators {
	const updateDestination: UpdateDestinationMutator = ({ consultId, destination }) =>
		updateConsultSummary({
			consultId,
			destination
		}).then(revalidate);

	const updateStatus: UpdateStatusMutator = ({ consultId, status }) =>
		updateConsultSummary({
			consultId,
			status
		}).then(revalidate);

	const sendSummary: SendSummaryMutator = ({ consultId }) =>
		updateConsultSummary({
			consultId,
			sendSummary: true
		}).then(revalidate);

	return {
		updateDestination,
		updateStatus,
		sendSummary,
		revalidate
	};
}

export {
	buildConsultSummariesMutators,
	useConsultSummariesContext,
	ConsultSummariesContext,
	ConsultSummariesContextData,
	ConsultSummariesContextMutators
};
