import { DataFormFieldComponentProps } from 'components/Forms/DataFormTypes';
import { State } from 'thriftgen/api_types';

const FIELD_NAME = 'patientTreatmentState';

type PatientTreatmentStateFieldProps = DataFormFieldComponentProps<State>;
interface DisplayPatientTreatmentStateFieldProps {
	data: PatientTreatmentStateFieldProps['data'];
}

export { DisplayPatientTreatmentStateFieldProps, FIELD_NAME };
