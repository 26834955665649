import * as React from 'react';
import { css } from 'emotion';

import { Heading } from 'display';

const styles = {
	header: css`
		display: flex;
		flex-direction: row;
		align-items: baseline;
		justify-content: space-between;
	`
};

function DocumentSidebarHeader(): JSX.Element {
	return (
		<div className={styles.header}>
			<Heading as={Heading.Type.H2} size={Heading.Size.T4}>
				Documents
			</Heading>
		</div>
	);
}

export default DocumentSidebarHeader;
