import * as React from 'react';

import { Message, Paragraph } from 'display';

interface UsersLoadErrorProps {
	message: string;
}

function UsersLoadError({ message }: UsersLoadErrorProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Unable to load users.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default UsersLoadError;
