import { usePatientPasswordlessAuthContext } from 'contexts/PatientPasswordlessAuthContext';
import { Button } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { SendSendTokenVerificationCodeAction } from 'store/actionTypes';
import { sendSendTokenVerificationCode } from 'store/auth';
import { AppState } from 'store/types';
import { SendTokenVerificationCodeResponse } from 'thriftgen/api_types';

const styles = {
	requestNewCodeButton: css`
		font-size: 1em !important;
		font-weight: normal !important;
	`
};

interface DispatchProps {
	sendSendTokenVerificationCode: (
		data: SendSendTokenVerificationCodeAction
	) => Promise<SendTokenVerificationCodeResponse>;
}

function ResendVerificationCode(props: DispatchProps): JSX.Element {
	const { tokenId } = usePatientPasswordlessAuthContext();

	async function resendCode() {
		await props.sendSendTokenVerificationCode({
			tokenId
		});
	}

	return (
		<div>
			Didn&rsquo;t get a code?{' '}
			<Button.TextPrimary
				onClick={resendCode}
				size={Button.Size.SMALL}
				className={styles.requestNewCodeButton}>
				Request a new one
			</Button.TextPrimary>
			.
		</div>
	);
}

const mapDispatchToProps = (dispatch: ThunkDispatch<AppState, void, AnyAction>): DispatchProps => ({
	sendSendTokenVerificationCode: (data: SendSendTokenVerificationCodeAction) =>
		dispatch(sendSendTokenVerificationCode(data))
});

export default connect(null, mapDispatchToProps)(ResendVerificationCode);
