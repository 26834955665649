import * as React from 'react';

import { Button, ButtonProps } from 'display';

function YesButton({ onClickAsync }: ButtonProps): JSX.Element {
	return (
		<Button.Primary fluid={true} onClickAsync={onClickAsync}>
			Yes
		</Button.Primary>
	);
}

export default YesButton;
