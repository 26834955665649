import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Partner } from 'thriftgen/api_types';
import LinkToDetailPageButton from './LinkToDetailPageButton';

interface LinkToPartnerDetailButtonProps {
	partnerId: Partner['partner_id'];
}

function LinkToPartnerDetailButton({ partnerId }: LinkToPartnerDetailButtonProps): JSX.Element {
	return (
		<LinkToDetailPageButton
			fluid={true}
			link={RouteUrls.buildUrl(RouteUrls.Key.PARTNER_DETAIL, { partnerId })}
		/>
	);
}

export default LinkToPartnerDetailButton;
