import * as React from 'react';
import { UnauthenticatedRoute } from './renderers';
import RouteLoader from './RouteLoader';

const NotFound = RouteLoader({
	loader: () => import('pages/NotFound/NotFound')
});

function NotFoundRoute(): JSX.Element {
	return <UnauthenticatedRoute canSafelyReload={true} template={NotFound} />;
}

export default NotFoundRoute;
