import { ReportEventParams } from 'api/common';
import {
	ManageConsultRequestAction,
	ManageConsultUpdateConsultDataAction
} from 'api/common/manageConsult';
import { EditableFieldsQuery } from 'hooks/queries/useEditableFieldsQuery';
import * as React from 'react';
import { Consult, GetConsultResponse, Partner, User } from 'thriftgen/api_types';
import { UserInfoCamel } from 'thriftgen/thriftCamelTypes';
interface ConsultContextData {
	consult: Consult;
	partner: Partner;
	users: User[];
	getEditableFields: () => EditableFieldsQuery;
}

interface ConsultContextMutators {
	revalidate: () => Promise<void>;
	manageConsult: (args: ManageConsultRequestAction) => Promise<void>;
	reportEvent: (args: ReportEventParams) => Promise<void>;
	updatePatient: (updatedUser: UserInfoCamel) => Promise<void>;
	updateConsultWithoutRevalidation: (args: GetConsultResponse) => Promise<void>;
	redactConsultData: (args: ManageConsultUpdateConsultDataAction) => Promise<void>;
	restoreConsultData: (args: ManageConsultUpdateConsultDataAction) => Promise<void>;
}

type ConsultContextValue = ConsultContextData & ConsultContextMutators;

const ConsultContext = React.createContext<ConsultContextValue | undefined>(undefined);

function useConsultContext(): ConsultContextValue {
	const context = React.useContext(ConsultContext);

	if (context === undefined) {
		throw new Error('No consult has been set');
	}

	return context;
}

export { ConsultContext, ConsultContextData, ConsultContextMutators, useConsultContext };
