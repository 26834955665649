import * as React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'display';
import { css } from 'emotion';

const styles = {
	label: css`
		line-height: 1.5;
	`
};

const noop = () => {};
const FIELD_NAME = 'termsAndConditionsConsent';

function buildTermsAndConditionsValidator(fieldName) {
	return function (data) {
		const value = data[fieldName];

		if (!value) {
			return {
				fields: [fieldName],
				messages: ['You must accept the terms and conditions to continue.']
			};
		}

		return null;
	};
}

function ConsentLabel() {
	return (
		<label className={styles.label}>
			I understand and agree to the{` `}
			<a href="/terms.html" target="_blank">
				Terms and Conditions.
			</a>
		</label>
	);
}

class TermsAndConditions extends React.Component {
	constructor(props) {
		super(props);

		this.onChange = this.onChange.bind(this);
	}

	canEdit() {
		const { editableFields, fieldName } = this.props;

		return editableFields.includes(fieldName);
	}

	hasError() {
		const { errors, fieldName } = this.props;
		const { fields } = errors;

		return fields.includes(fieldName);
	}

	getValue() {
		const { data, fieldName } = this.props;

		return data[fieldName];
	}

	onChange(event, eventData) {
		this.props.onChange(event, { ...eventData, value: eventData.checked });
	}

	render() {
		return (
			<Form.Checkbox
				checked={this.getValue()}
				error={this.hasError()}
				id={FIELD_NAME}
				label={<ConsentLabel />}
				name={FIELD_NAME}
				onChange={this.onChange}
				data-testid={FIELD_NAME}
			/>
		);
	}
}

TermsAndConditions.FIELD_NAME = FIELD_NAME;
TermsAndConditions.validate = buildTermsAndConditionsValidator(FIELD_NAME);

TermsAndConditions.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	fieldName: FIELD_NAME,
	onChange: noop
};

TermsAndConditions.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	fieldName: PropTypes.string,
	onChange: PropTypes.func
};

export default TermsAndConditions;
