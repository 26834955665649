import * as React from 'react';
import PropTypes from 'prop-types';
import {
	UserCredential as UserCredentialType,
	USER_CREDENTIAL as USER_CREDENTIAL_TRANSLATIONS
} from 'thriftgen/api_types';
import { translateThriftEnum } from 'lib/i18n';
import noop from 'lib/noop';
import { getUserCredentials } from 'lib/userHelpers';

import { Form } from 'display';

const OPTIONS = Object.values(UserCredentialType).map(value => ({
	key: value,
	value: value,
	text: translateThriftEnum(value, USER_CREDENTIAL_TRANSLATIONS)
}));

const FIELD_NAME = 'credentials';
const LABEL = 'Credentials';

function validate(data, required) {
	const value = data[FIELD_NAME];

	if (required) {
		if (Array.isArray(value) && value.length > 0) {
			return null;
		}

		return {
			fields: [FIELD_NAME],
			messages: ['Please select at least one credential.']
		};
	}

	return null;
}

class Credential extends React.Component {
	static FIELD_NAME = FIELD_NAME;
	static validate = validate;

	canEdit() {
		const { editableFields } = this.props;
		return editableFields.includes(FIELD_NAME);
	}

	hasError() {
		const { errors } = this.props;
		const { fields } = errors;

		return fields.includes(FIELD_NAME);
	}

	getValue() {
		const { data } = this.props;

		return data[FIELD_NAME];
	}

	render() {
		const { onChange } = this.props;
		const value = this.getValue();

		if (this.canEdit()) {
			return (
				<Form.Select
					error={this.hasError()}
					search
					label={LABEL}
					placeholder={translateThriftEnum(
						UserCredentialType.MD,
						USER_CREDENTIAL_TRANSLATIONS
					)}
					name={FIELD_NAME}
					options={OPTIONS}
					value={value}
					onChange={onChange}
					id={FIELD_NAME}
					multiple={true}
					width={4}
				/>
			);
		}

		if (value && value.length) {
			return (
				<Form.Field>
					<label>{LABEL}</label>
					<span>{getUserCredentials({ credentials: value })}</span>
				</Form.Field>
			);
		}

		return null;
	}
}

Credential.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	onChange: noop
};

Credential.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	onChange: PropTypes.func
};

export default Credential;
