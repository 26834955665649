import { CheckboxProps, Form } from 'display';
import noop from 'lib/noop';
import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';

interface BuildCheckboxFieldProps {
	onChangeWrapper?: (newValue: boolean) => Promise<void>;
	fieldName: string;
	label: string | JSX.Element;
	isDisabled?: (data: DataFormFieldComponentProps['data']) => boolean;
	isInverted?: boolean;
	validate?: (value: string, required: boolean) => null | string[];
}

function defaultValidate(): null {
	return null;
}

function buildCheckboxField({
	fieldName,
	label,
	isDisabled = () => false,
	isInverted = false,
	onChangeWrapper,
	validate = defaultValidate
}: BuildCheckboxFieldProps): DataFormFieldComponent {
	function validateData(
		data: { [key: string]: string },
		required: boolean
	): ReturnType<DataFormFieldComponent['validate']> {
		const messages = validate(data[fieldName], required);
		return messages ? { fields: [fieldName], messages } : null;
	}
	function CheckboxField({
		data,
		editableFields,
		errors = {
			fields: [],
			messages: []
		},
		onChange = noop
	}: DataFormFieldComponentProps): JSX.Element | null {
		function getCheckboxValue(inValue: boolean): boolean {
			if (isInverted) {
				return !inValue;
			}

			return inValue;
		}

		const value = getCheckboxValue(data[fieldName]);
		const hasError = errors.fields.includes(fieldName);
		const canEdit = editableFields.includes(fieldName);

		function onValueChange(
			event: React.SyntheticEvent<HTMLInputElement>,
			eventData: CheckboxProps
		): void {
			const newValue = getCheckboxValue(!!eventData.checked);

			if (onChangeWrapper) {
				onChangeWrapper(newValue)
					.then(() => {
						onChange(event, { name: fieldName, value: newValue });
					})
					.catch(noop);
			} else {
				onChange(event, { name: fieldName, value: newValue });
			}
		}

		if (canEdit) {
			return (
				<Form.Checkbox
					id={fieldName}
					name={fieldName}
					label={label}
					onChange={onValueChange}
					checked={value}
					error={hasError}
					disabled={isDisabled(data)}
				/>
			);
		}

		if (value !== undefined) {
			return (
				<Form.Field inline={true}>
					<label>{label}</label>
					<span>{value ? 'Yes' : 'No'}</span>
				</Form.Field>
			);
		}

		return null;
	}

	CheckboxField.FIELD_NAME = fieldName;
	CheckboxField.validate = validateData;
	CheckboxField.displayName = `CheckboxField-${fieldName}`;

	return CheckboxField;
}

export default buildCheckboxField;
