import React, { useState } from 'react';
import FileInputRejectedFiles from './FileInputRejectedFiles';
import { validateFiles } from './helpers';
import InputWrapper, { InputWrapperProps } from './InputWrapper';

type InputProps = Exclude<
	React.InputHTMLAttributes<HTMLInputElement>,
	'id' | 'name' | 'type' | 'onChange' | 'value'
>;

interface FileInputProps {
	id: string;
	label?: string;
	onFileChange: (files: File[]) => void;
	className?: InputWrapperProps['className'];
	button?: InputWrapperProps['button'];
	buttonStyle?: InputWrapperProps['buttonStyle'];
	rejectedExtensionParentList?: File[];
	rejectedMimeTypeParentList?: File[];
}

function FileInput({
	button,
	buttonStyle,
	className,
	id,
	label = 'Upload files',
	multiple = true,
	onFileChange,
	accept,
	rejectedExtensionParentList,
	rejectedMimeTypeParentList,
	...inputProps
}: FileInputProps & InputProps): JSX.Element {
	const [rejectedExtensionFiles, setRejectedExtensionFiles] = useState<File[]>([]);
	const [rejectedMimeTypeFiles, setRejectedMimeTypeFiles] = useState<File[]>([]);

	React.useEffect(() => {
		if (rejectedExtensionParentList !== undefined) {
			setRejectedExtensionFiles(rejectedExtensionParentList);
		}
	}, [rejectedExtensionParentList]);

	React.useEffect(() => {
		if (rejectedMimeTypeParentList !== undefined) {
			setRejectedMimeTypeFiles(rejectedMimeTypeParentList);
		}
	}, [rejectedMimeTypeParentList]);

	if (id === undefined) {
		throw new Error('FileInput must take a unique id');
	}

	async function onChange(event: React.ChangeEvent<HTMLInputElement>): Promise<void> {
		const { accepted, rejectedExtension, rejectedMimeType } = await validateFiles(
			event.target.files,
			accept
		);
		setRejectedExtensionFiles(rejectedExtension);
		setRejectedMimeTypeFiles(rejectedMimeType);
		onFileChange(accepted);
	}

	return (
		<InputWrapper
			htmlFor={id}
			label={label}
			className={className}
			button={button}
			buttonStyle={buttonStyle}>
			<input
				id={id}
				data-testid={id}
				multiple={multiple}
				name={id}
				onChange={onChange}
				type="file"
				value=""
				accept={accept}
				{...inputProps}
			/>
			<FileInputRejectedFiles
				rejectedExtensionFiles={rejectedExtensionFiles}
				rejectedMimeTypeFiles={rejectedMimeTypeFiles}
			/>
		</InputWrapper>
	);
}

export default FileInput;
