import { getClinicalReview } from 'api/common';
import useQuery from 'swr';
import {
	GetClinicalReviewRequestCamel,
	GetClinicalReviewResponseCamel
} from 'thriftgen/thriftCamelTypes';

interface GetClinicalReviewQuery {
	data: GetClinicalReviewResponseCamel | undefined;
	error: Error | undefined;
	revalidate: () => Promise<void>;
}

function useGetClinicalReviewQuery(props: GetClinicalReviewRequestCamel): GetClinicalReviewQuery {
	const keyValues = Object.entries(props).map(item => item.join('/'));
	const key = `getClinicalReview/${keyValues}`;

	const { data, error, mutate } = useQuery<GetClinicalReviewResponseCamel>(key, () => {
		const response = getClinicalReview(props);
		return response;
	});

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		data,
		error,
		revalidate
	};
}

export default useGetClinicalReviewQuery;
