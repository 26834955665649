import {
	ConsultsContext,
	ConsultsContextData,
	ConsultsContextMutators
} from 'contexts/ConsultsContext';
import * as React from 'react';

function ConsultsContextProvider({
	children,
	data,
	mutators
}: {
	children: React.ReactNode;
	data: ConsultsContextData;
	mutators: ConsultsContextMutators;
}): JSX.Element {
	return (
		<ConsultsContext.Provider
			value={{
				...data,
				...mutators
			}}>
			{children}
		</ConsultsContext.Provider>
	);
}

export default ConsultsContextProvider;
