import * as React from 'react';

interface AnchorProps {
	children: React.ReactNode;
	href: string;
	target?: string;
	rel?: string;
	className?: string;
}

const DEFAULT_TARGET = '_blank';
const DEFAULT_REL = 'noopener noreferrer';

function Anchor({
	children,
	href,
	target = DEFAULT_TARGET,
	rel = DEFAULT_REL,
	className
}: AnchorProps): JSX.Element {
	return (
		<a href={href} target={target} rel={rel} className={className}>
			{children}
		</a>
	);
}

export default Anchor;
