import * as React from 'react';
import { css } from 'emotion';

import { Menu, Tab as SemanticTab } from 'display';

const height = css`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

interface TabBuilderProps {
	children: JSX.Element | JSX.Element[];
	label: string;
	classNames?: string;
}

interface TabsProps {
	tabs: TabType[];
}

interface TabType {
	menuItem: string | JSX.Element;
	render: () => JSX.Element;
}

interface TabsComponent {
	(props: TabsProps): JSX.Element;

	buildTab: (props: TabBuilderProps) => TabType;
}

const Tabs: TabsComponent = Object.assign(
	({ tabs }: TabsProps): JSX.Element => <SemanticTab className={height} panes={tabs} />,
	{
		buildTab: ({ label, children, classNames }: TabBuilderProps): TabType => {
			return {
				menuItem: (
					<Menu.Item key={label} className={classNames}>
						{label}
					</Menu.Item>
				),
				render: () => <SemanticTab.Pane className={height}>{children}</SemanticTab.Pane>
			};
		}
	}
);

export { Tabs, TabType, TabBuilderProps };
