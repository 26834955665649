import * as React from 'react';

import { buildNameValidator, FullName } from './FullName';
import UserActorCredential from './UserActorCredential';
import UserActorFirstName from './UserActorFirstName';
import UserActorLastName from './UserActorLastName';
import UserActorTitle from './UserActorTitle';

const FIELD_NAME = 'specialistFullName';

function SpecialistFullName(props) {
	return (
		<FullName
			{...props}
			Credential={UserActorCredential}
			fieldName={FIELD_NAME}
			FirstName={UserActorFirstName}
			LastName={UserActorLastName}
			Title={UserActorTitle}
		/>
	);
}

SpecialistFullName.FIELD_NAME = FIELD_NAME;
SpecialistFullName.SUB_FIELD_NAMES = [
	UserActorCredential.FIELD_NAME,
	UserActorFirstName.FIELD_NAME,
	UserActorLastName.FIELD_NAME,
	UserActorTitle.FIELD_NAME
];
SpecialistFullName.validate = buildNameValidator(
	UserActorFirstName.FIELD_NAME,
	UserActorLastName.FIELD_NAME
);

export default SpecialistFullName;
