import { generatePath } from 'react-router-dom';
import store from 'store/index';
import { RouteKey, RouteRestriction } from './types';

interface UrlParams {
	[key: string]: string | number | boolean;
}

type UrlBuilder = (params?: UrlParams) => string;

interface UrlProperties {
	builder: UrlBuilder;
	restriction: RouteRestriction;
	isDisabled?: () => boolean;
}

type UrlMap = {
	[key in RouteKey]: UrlProperties;
};

const ANYONE_CAN_ACCESS: RouteRestriction = () => true;

function initiateList(): UrlMap {
	const aggregator = {} as UrlMap;

	function createRouteEntry(): Partial<UrlProperties> {
		return {
			restriction: ANYONE_CAN_ACCESS,
			isDisabled: () => false
		};
	}

	return Object.values(RouteKey).reduce(
		(list, key) => ({ ...list, [key]: createRouteEntry() }),
		aggregator
	);
}

class RouteUrls {
	public static readonly Key = RouteKey;

	public static addUrlBuilder(key: RouteKey, staticPath: string): void {
		if (RouteUrls.isUrlBuilderDefined(key)) {
			throw new Error(`RouteUrls: A URL builder has already been generated for "${key}"`);
		}

		RouteUrls.list[key].builder = (params?: UrlParams): string =>
			generatePath(staticPath, params);
	}

	public static buildUrl(key: RouteKey, routeParams?: UrlParams, endFragment?: string): string {
		const { builder } = RouteUrls.list[key];

		if (builder === undefined) {
			throw new Error(`RouteUrls: No URL builder registered for "${key}"`);
		}

		const url = builder(routeParams);

		if (typeof endFragment === 'string') {
			return url + endFragment;
		}

		return url;
	}

	public static canAccessUrl(key: RouteKey): boolean {
		const state = store.getState();
		const { restriction, isDisabled } = RouteUrls.list[key];

		let isRouteDisabled = false;
		if (isDisabled && isDisabled()) {
			isRouteDisabled = true;
		}

		return (
			!isRouteDisabled &&
			restriction({
				auth: state.auth
			})
		);
	}

	public static isUrlBuilderDefined(key: RouteKey): boolean {
		return Boolean(RouteUrls.list[key].builder);
	}

	public static setUrlRestriction(key: RouteKey, restriction: RouteRestriction): void {
		RouteUrls.list[key].restriction = restriction;
	}

	public static setIsDisabled(key: RouteKey, isDisabled: () => boolean): void {
		RouteUrls.list[key].isDisabled = isDisabled;
	}

	private static list: UrlMap = initiateList();
	public static pathsWithTelemetryDisabled: string[] = [];
}

export default RouteUrls;
export { RouteKey, UrlParams };
