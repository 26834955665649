import { DataTableColumn } from 'components/DataTable/DataTable';
import { ConsultComposite } from 'hooks';
import Strings from 'lib/strings';
import * as React from 'react';

const header = 'Action Needed';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ clinicalReview }) => {
		let displayValue;
		if (clinicalReview) {
			displayValue = clinicalReview.correctiveActionNeeded ? 'Yes' : 'No';
		} else {
			displayValue = Strings.PLACEHOLDERS.NO_VALUE;
		}
		return <div>{displayValue}</div>;
	}
});
