import {
	ActionComponent,
	ActionMenuDropdown,
	RemoveUserFromPartnerAction,
	ViewPartnerDetailsAction
} from 'components/ActionMenuDropdown';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { css } from 'emotion';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { mediaQueries } from 'styles';

const styles = {
	actionMenuDropdownWrapper: css`
		float: right;
		${mediaQueries.mobile} {
			float: none;
		}
	`
};

interface BuildActionsProps {
	isSitkaOperations: boolean;
}

const buildActions = ({ isSitkaOperations }: BuildActionsProps): DataTableColumn<Partner> => ({
	render: (partner: Partner) => {
		const actionComponents: ActionComponent[] = [];

		if (RouteUrls.canAccessUrl(RouteUrls.Key.PARTNER_DETAIL)) {
			actionComponents.push(ViewPartnerDetailsAction);
		}

		if (isSitkaOperations) {
			actionComponents.push(RemoveUserFromPartnerAction);
		}

		return (
			<div className={styles.actionMenuDropdownWrapper}>
				<ActionMenuDropdown actionComponents={actionComponents} data={partner} />
			</div>
		);
	}
});

export { buildActions };
