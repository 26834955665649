import * as React from 'react';

import { Message, Paragraph } from 'display';

function CasesLoadError({ message }: { message: string }): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Unable to load cases.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default CasesLoadError;
