import { Dispatch } from 'react';

function bindActionCreators<ActionCreators, Actions>(
	dispatch: Dispatch<Actions>,
	actionCreators: ActionCreators
): ActionCreators {
	const list = {};

	Object.keys(actionCreators).forEach(key => {
		list[key] = (...args: any[]): void => dispatch(actionCreators[key](...args));
	});

	return list as ActionCreators;
}

export default bindActionCreators;
