import * as React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'display';

const noop = () => {};
const FIELD_NAME = 'lastName';

function validate(data, required) {
	const nameField = data[FIELD_NAME];

	if (required && !nameField) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please enter a last name.']
		};
	}

	return null;
}

class LastName extends React.Component {
	static FIELD_NAME = FIELD_NAME;
	static validate = validate;

	canEdit() {
		const { editableFields } = this.props;

		return editableFields.includes(FIELD_NAME);
	}

	hasError() {
		const { errors } = this.props;
		const { fields } = errors;

		return fields.includes(FIELD_NAME);
	}

	getValue() {
		const { data } = this.props;

		return data[FIELD_NAME];
	}

	render() {
		const { label, onBlur, onChange } = this.props;
		const value = this.getValue();

		if (this.canEdit()) {
			return (
				<Form.Input
					error={this.hasError()}
					id={FIELD_NAME}
					label={label}
					placeholder="Thomas"
					name={FIELD_NAME}
					onBlur={onBlur}
					onChange={onChange}
					value={value || ''}
				/>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<label>{label}</label>
					<span>{value}</span>
				</Form.Field>
			);
		}

		return null;
	}
}

LastName.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	label: 'Last name',
	onBlur: noop,
	onChange: noop
};

LastName.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	label: PropTypes.string.isRequired,
	onBlur: PropTypes.func,
	onChange: PropTypes.func
};

export default LastName;
