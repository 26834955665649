import * as React from 'react';

import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import EnablePatientCommunications from '../Fields/EnablePatientCommunications';

const FIELD_NAME = 'createCasesSitka';
const LABEL = 'Create vMessages in Sitka';

function deriveDisabledStatus(data: DataFormFieldComponentProps['data']): boolean {
	return !data[EnablePatientCommunications.FIELD_NAME];
}

const CreateCasesSitkaElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL,
	isDisabled: deriveDisabledStatus
});
function CreateCasesSitka(props: DataFormFieldComponentProps): JSX.Element {
	const { data, ...rest } = props;

	function deriveEligibility(formData: DataFormFieldComponentProps['data']): boolean {
		if (!formData[EnablePatientCommunications.FIELD_NAME]) {
			return false;
		}
		return formData[FIELD_NAME];
	}

	return (
		<CreateCasesSitkaElement
			key={FIELD_NAME}
			{...rest}
			data={{
				...data,
				[FIELD_NAME]: deriveEligibility(data)
			}}
		/>
	);
}

CreateCasesSitka.FIELD_NAME = FIELD_NAME;
CreateCasesSitka.validate = () => null;

export default CreateCasesSitka;
