import * as React from 'react';
import { css } from 'emotion';

import { Form, Text } from 'display';

const styles = {
	label: css`
		padding-right: 0.5em;
	`
};

interface ReadOnlyFieldProps {
	children: React.ReactNode;
	label: string;
}

const DEFAULT_CHILDREN = '(none)';

function ReadOnlyField({ children, label }: ReadOnlyFieldProps): JSX.Element {
	children = children || DEFAULT_CHILDREN;

	return (
		<Form.Field>
			<Text className={styles.label}>{label}</Text>
			<Text weight={Text.Weight.BOLD}>{children}</Text>
		</Form.Field>
	);
}

export default ReadOnlyField;
