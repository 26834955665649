import * as React from 'react';
import { css } from 'emotion';
import { DocumentDataAsset, isDocumentDataViewable } from 'lib/documentAssets';

import DocumentAssetItem from './DocumentAssetItem';
import { borderRadius, colors } from 'styles';

interface DocumentAssetSelectionProps {
	documents: DocumentDataAsset[];
	disabled: boolean;
	onSelect: (asset: DocumentDataAsset) => void;
	selectedDocument: DocumentDataAsset | undefined;
}

const styles = {
	container: css`
		label {
			display: block;
		}

		& input[type='radio']:not(:checked):not(:disabled) + label:hover {
			background-color: ${colors.primary['50']};
			border-radius: ${borderRadius.l1};
			cursor: pointer;
		}

		& input[type='radio']:checked + label {
			background-color: ${colors.primary['100']};
			border-radius: ${borderRadius.l1};
			cursor: unset;
		}

		& input[type='radio']:disabled + label {
			cursor: not-allowed;
			color: ${colors.neutral['200']};
		}
	`
};

function DocumentAssetSelection({
	disabled,
	documents,
	onSelect,
	selectedDocument
}: DocumentAssetSelectionProps): JSX.Element {
	function renderAsset(asset: DocumentDataAsset): JSX.Element {
		const isChecked = selectedDocument !== undefined && selectedDocument.id === asset.id;
		const onChange = () => onSelect(asset);
		const documentIsUnviewable = !isDocumentDataViewable(asset);
		const shouldDisableInput = disabled || !asset.isReady || documentIsUnviewable;
		const subtitle = documentIsUnviewable
			? {
					text: 'Not viewable'
			  }
			: undefined;

		return (
			<div className={styles.container} key={asset.id} data-testid={`input-${asset.id}`}>
				<input
					type="radio"
					checked={isChecked}
					onChange={onChange}
					name="assetListItem"
					id={asset.id}
					hidden={true}
					disabled={shouldDisableInput}
				/>
				<label htmlFor={asset.id}>
					<DocumentAssetItem asset={asset} subtitle={subtitle} />
				</label>
			</div>
		);
	}

	return <form>{documents.map(renderAsset)}</form>;
}

export default DocumentAssetSelection;
