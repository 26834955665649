import { CancelButton, SaveButton } from 'components/Buttons';
import { EditableFieldsError } from 'components/Messages';
import { ButtonType, Loader, Message } from 'display';
import useEditableFieldsQuery from 'hooks/queries/useEditableFieldsQuery';
import { snakeToCamel } from 'lib/stringConverter';
import React, { useMemo } from 'react';
import { User } from 'thriftgen/api_types';
import { DataFormFieldComponent } from '../DataFormTypes';
import DataFormWrapper from '../DataFormWrapper';
import { UserInfoCamel } from 'thriftgen/thriftCamelTypes';
import SMA_BIOS_CONDITIONAL_FIELDS from 'components/Forms/Fields/SMABiosConditionalFields';

interface UserEditorProps {
	onSubmit: (args: UserInfoCamel) => Promise<void>;
	onCancel: () => void;
	fields: Array<DataFormFieldComponent | React.ComponentType | React.FC>;
	requiredFields?: string[];
	user: User;
	error?: Error;
}

function UserEditor({
	user,
	fields,
	onCancel,
	onSubmit,
	requiredFields,
	error
}: UserEditorProps): JSX.Element {
	const { user_id: userId } = user;
	const { editableFields, error: editableFieldsError } = useEditableFieldsQuery({
		userId
	});

	const renderCancel = () => (
		<CancelButton buttonType={ButtonType.SECONDARY} onClick={onCancel} />
	);

	const flattenSpecialistData = useMemo((): User => {
		let specialistData = user.specialist_data;

		if (specialistData) {
			specialistData = {
				...user.specialist_data,
				...user.specialist_data.specialist_bio_info
			};

			delete specialistData.specialist_bio_info;
		}

		const result: User = {
			...user,
			...specialistData
		};

		delete result.specialist_data;

		return result;
	}, [user]);

	if (editableFieldsError) {
		return <EditableFieldsError message={editableFieldsError.message} />;
	}

	if (editableFields) {
		return (
			<React.Fragment>
				<DataFormWrapper
					data={flattenSpecialistData}
					editableFields={editableFields.map(snakeToCamel)}
					conditionalFields={SMA_BIOS_CONDITIONAL_FIELDS}
					requiredFields={requiredFields}
					fields={fields}
					onSubmit={onSubmit}
					CancelControl={renderCancel}
					SubmitControl={SaveButton}
				/>
				{error && <Message negative={true}>{error.message}</Message>}
			</React.Fragment>
		);
	}

	return <Loader active={true} />;
}

export default UserEditor;
