import { GetCaseRequestCamel, GetCaseResponseCamel } from 'thriftgen/thriftCamelTypes';
import { getCommonClient } from 'lib/ThriftClient';
import { objectSnakeToCamel } from 'lib/objectConverter';
import { GetCaseRequest } from 'thriftgen/api_types';

async function getCase({ caseId }: GetCaseRequestCamel): Promise<GetCaseResponseCamel> {
	const client = getCommonClient();
	const request = new GetCaseRequest({
		case_id: caseId
	});

	const response = await client.get_case(request);
	return objectSnakeToCamel(response);
}

export default getCase;
