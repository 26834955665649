import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Route } from 'react-router-dom';

import AuthWrapper from 'components/Routing/AuthWrapper';
import RestrictedWrapper from 'components/Routing/RestrictedWrapper';
import AppPage from 'components/Page/AppPage';
import ForceReload from 'components/Routing/ForceReload';
import { RouteRendererProps } from 'routing/types';

function AuthenticatedRoute(routeProps: RouteRendererProps): JSX.Element {
	const {
		canSafelyReload,
		exact,
		path,
		pageOptions,
		routes,
		shouldRestrict = () => false,
		template: Template
	} = routeProps;

	function renderRoute(routeRenderProps: RouteComponentProps): JSX.Element {
		function renderTemplate(): JSX.Element {
			if (routes && routes.length) {
				return <Template {...routeRenderProps} routes={routes} />;
			}

			return (
				<AppPage authenticated={true} pageOptions={pageOptions}>
					<Template {...routeRenderProps} />
				</AppPage>
			);
		}

		function renderRestrictedWrapper(): JSX.Element {
			return <RestrictedWrapper restricted={shouldRestrict()} render={renderTemplate} />;
		}

		return (
			<ForceReload canSafelyReload={canSafelyReload}>
				<AuthWrapper
					history={routeRenderProps.history}
					location={routeRenderProps.location}
					render={renderRestrictedWrapper}
				/>
			</ForceReload>
		);
	}

	return <Route exact={exact} path={path} render={renderRoute} />;
}

export default AuthenticatedRoute;
