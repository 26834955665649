import * as React from 'react';
import { css } from 'emotion';
import browserScheduling from 'lib/browserScheduling';
import { getPointerEvents, getPointerEventsHandlers } from 'lib/featureDetection';

import { zIndexLayer } from 'styles';

const styles = {
	float: css`
		cursor: move;
		position: absolute;
		z-index: ${zIndexLayer.GLOBAL};
	`
};

const pointerEvents = getPointerEvents();
const pointerEventsHandlers = getPointerEventsHandlers();

enum InitialPosition {
	BOTTOM_LEFT,
	BOTTOM_RIGHT,
	TOP_LEFT,
	TOP_RIGHT
}

function setInitialPosition(initialPosition: InitialPosition, element: HTMLDivElement): void {
	switch (initialPosition) {
		case InitialPosition.TOP_RIGHT:
			element.style.right = '0';
			element.style.top = '0';
			break;
		case InitialPosition.TOP_LEFT:
			element.style.left = '0';
			element.style.top = '0';
			break;
		case InitialPosition.BOTTOM_RIGHT:
			element.style.right = '0';
			element.style.bottom = '0';
			break;
		case InitialPosition.BOTTOM_LEFT:
		default:
			element.style.left = '0';
			element.style.bottom = '0';
			break;
	}
}

interface ConsumerProps {
	active?: boolean;
	initialPosition?: InitialPosition;
	children: React.ReactNode;
}

interface DragAroundState {
	deltaX: number;
	deltaY: number;
	startX: number;
	startY: number;
}

type DefaultProps = Readonly<typeof DragAround.defaultProps>;
type DragAroundProps = ConsumerProps & DefaultProps;

class DragAround extends React.Component<DragAroundProps, DragAroundState> {
	public static InitialPosition = InitialPosition;
	public static defaultProps = {
		active: true
	};

	private ref: React.RefObject<HTMLDivElement>;

	constructor(props: DragAroundProps) {
		super(props);
		this.ref = React.createRef();

		this.state = {
			deltaX: 0,
			deltaY: 0,
			startX: 0,
			startY: 0
		};

		this.attachEventListeners = this.attachEventListeners.bind(this);
		this.drag = this.drag.bind(this);
		this.initiateDrag = this.initiateDrag.bind(this);
		this.setPosition = this.setPosition.bind(this);
		this.stopDrag = this.stopDrag.bind(this);
		this.updateRefPosition = this.updateRefPosition.bind(this);
	}

	public componentWillUnmount(): void {
		this.removeEventListeners();
	}

	public componentDidMount(): void {
		const { active } = this.props;

		if (active) {
			this.setDefaultPosition();
		}
	}

	public componentDidUpdate(prevProps: DragAroundProps): void {
		if (!prevProps.active && this.props.active) {
			this.setDefaultPosition();
		}
	}

	public render(): JSX.Element {
		const { active, children } = this.props;

		const props = {
			className: active ? styles.float : undefined,
			ref: this.ref,
			[pointerEventsHandlers.down]: active ? this.initiateDrag : undefined
		};

		return <div {...props}>{children}</div>;
	}

	private attachEventListeners() {
		document.documentElement.addEventListener(pointerEvents.up, this.stopDrag);
		document.documentElement.addEventListener(pointerEvents.move, this.drag);
	}

	private drag(event: any): void {
		const element = this.ref.current;

		if (element) {
			const X = event.clientX;
			const Y = event.clientY;

			this.setState(
				state => ({
					deltaX: state.startX - X,
					deltaY: state.startY - Y,
					startX: X,
					startY: Y
				}),
				this.setPosition
			);
		}
	}

	private initiateDrag(event: React.MouseEvent<HTMLDivElement, MouseEvent>): void {
		this.setState(
			{
				startX: event.clientX,
				startY: event.clientY
			},
			this.attachEventListeners
		);
	}

	private removeEventListeners(): void {
		document.documentElement.removeEventListener(pointerEvents.up, this.stopDrag);
		document.documentElement.removeEventListener(pointerEvents.move, this.drag);
	}

	private setDefaultPosition(): void {
		const element = this.ref.current;
		const { initialPosition } = this.props;

		if (element && initialPosition) {
			setInitialPosition(initialPosition, element);
		}
	}

	private setPosition(): void {
		browserScheduling.requestAnimationFrame(this.updateRefPosition);
	}

	private stopDrag(): void {
		this.removeEventListeners();
	}

	private updateRefPosition(): void {
		const element = this.ref.current;
		if (element) {
			const { offsetTop, offsetLeft } = element;
			const { deltaY, deltaX } = this.state;

			element.style.top = offsetTop - deltaY + 'px';
			element.style.left = offsetLeft - deltaX + 'px';
		}
	}
}

export default DragAround;
