import { OktaAuthOptions } from '@okta/okta-auth-js';

// Okta tells us when a token will expire 5 minutes and 30 seconds
// before the actual expiry. At that point we show a 5 minute countdown. The 30
// seconds of headroom prevents any race condition between the eventual
// logout request and the session actually expiring.
const EXPIRE_EARLY_BUFFER = 30;
const EXPIRE_EARLY_SECONDS = 330;
const EXPIRE_EARLY_COUNTDOWN = EXPIRE_EARLY_SECONDS - EXPIRE_EARLY_BUFFER;
const EXPIRE_COUNTDOWN_MILLISECONDS = EXPIRE_EARLY_COUNTDOWN * 1000;

function OktaConfigHelpers(env: string): OktaAuthOptions {
	const BASE_OKTA_CONFIG: OktaAuthOptions = {
		redirectUri: `${window.location.origin.toString()}/okta-callback`,
		scopes: ['openid', 'profile', 'email', 'groups', 'offline_access'],
		pkce: true,
		tokenManager: {
			autoRenew: false,
			autoRemove: false,
			expireEarlySeconds: EXPIRE_EARLY_SECONDS,
			storage: 'localStorage'
		}
	};

	switch (env) {
		case 'LOCAL': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oa2oz4sqsis9HZOM1d6',
				issuer: 'https://sitka-dev.oktapreview.com/oauth2/default'
			};
		}
		case 'SANDBOX': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oa5mieu44KpPiY2L1d7',
				issuer: 'https://sitka-sand.oktapreview.com/oauth2/default'
			};
		}
		case 'STAGING': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oad2bg8Jf8M4dQsk1d5',
				issuer: 'https://login-stage.trustsitka.com/oauth2/default'
			};
		}
		case 'STAGE': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oa9ickg0EDYk81RS1d6',
				issuer: 'https://login-stage.trustsitka.com/oauth2/default'
			};
		}
		case 'UAT': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oa5micnhf4eeVHPU1d7',
				issuer: 'https://sitka-uat.oktapreview.com/oauth2/default'
			};
		}
		case 'PROD': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oa2atf7byPpUAYK54h6',
				issuer: 'https://login.trustsitka.com/oauth2/default'
			};
		}
		case 'PRODV2': {
			return {
				...BASE_OKTA_CONFIG,
				clientId: '0oabvdvuqHuqPVqc14h6',
				issuer: 'https://login.trustsitka.com/oauth2/default'
			};
		}
		default: {
			throw new Error(`Okta config not set for env=${env}`);
		}
	}
}

export { OktaConfigHelpers, EXPIRE_COUNTDOWN_MILLISECONDS, EXPIRE_EARLY_BUFFER };
