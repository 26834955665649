import * as React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'display';

const FIELD_NAME = 'insurance_carrier';

function hasError({ fields }) {
	return fields.includes(FIELD_NAME);
}

function getValue(data) {
	return data[FIELD_NAME];
}

function InsuranceCarrier({ onChange, data, errors }) {
	return (
		<Form.Input
			error={hasError(errors)}
			id={FIELD_NAME}
			label="Current medical malpractice insurance carrier"
			name={FIELD_NAME}
			onChange={onChange}
			placeholder="Acme Insurance"
			value={getValue(data) || ''}
		/>
	);
}

InsuranceCarrier.FIELD_NAME = FIELD_NAME;
InsuranceCarrier.validate = (data, required) => {
	const value = getValue(data);

	if (required && !value) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please provide your insurance carrier.']
		};
	}

	return null;
};

InsuranceCarrier.defaultProps = {
	data: {},
	errors: {
		fields: []
	}
};

InsuranceCarrier.propTypes = {
	onChange: PropTypes.func.isRequired,
	data: PropTypes.shape({
		[FIELD_NAME]: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
	}),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	})
};

export default InsuranceCarrier;
