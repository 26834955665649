import BaseUploader from './BaseUploader';
import { upload } from 'lib/aws';
import { compareAscending } from 'lib/sort';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import { backOff } from 'exponential-backoff';
import { reject, resolve } from 'promise';

async function executeUpload({ onProgress, onError }) {
	const backOffOptions = {
		numOfAttempts: 5,
		startingDelay: 3000, //delay between 1st and 2nd attempt
		timeMultiple: 4.2488, //co-efficient for time between attempts - given 5 attempts and a 3 second delay for the first retry, this ensures all 5 attempts are 300 seconds
		retry: (error, attemptNum) => {
			const msg = `Upload attempt ${attemptNum}/5 failed: ${error}`;
			SitkaLogger.logMessage(msg, LogEventName.UPLOAD);
			return true;
		}
	};
	try {
		return await backOff(() => {
			return upload({
				body: this.content,
				mimeType: this.content.type,
				progressCallback: (uploaded, total) => {
					this.uploaded = uploaded;
					this.total = total;
					onProgress();
				}
			});
		}, backOffOptions);
	} catch (error) {
		onError(error);
		SitkaLogger.logMessage(error);
		throw new Error(error);
	}
}

class StreamUploader extends BaseUploader {
	constructor(props) {
		super(props);
		this.completed = false;
		this.uploadError = null;
		this.emitError = this.emitError.bind(this);
		this.emitProgress = this.emitProgress.bind(this);
		this.uploadPart = this.uploadPart.bind(this);
		this.upload = this.upload.bind(this);
	}

	addUploadCompleteListener(callback) {
		/**
		 * If upload complete has already been triggered, then we simply execute the
		 * callback immediately.
		 */
		if (this.completed) {
			callback(this.getKeys());
		} else {
			this.addEventListener(StreamUploader.EVENTS.COMPLETE, callback);
		}
	}

	async end() {
		if (this.completed) {
			return;
		}

		try {
			await Promise.all(this.getUploads());
			const keys = this.getKeys();
			/**
			 * StreamUploader behavior is highly async because there are multiple
			 * promises involved, and the amount of promises is not deterministic. We use
			 * the ended flag to ensure that we do not resolve and emit multiple times.
			 */
			this.completed = true;
			this.uploadError = null;
			this.removeUploadEventListeners([
				StreamUploader.EVENTS.PROGRESS,
				StreamUploader.EVENTS.BEFORE_UNLOAD
			]);

			if (keys && keys.length > 0) {
				this.emit(StreamUploader.EVENTS.COMPLETE, keys);
				SitkaLogger.logMessage('Upload ended', LogEventName.UPLOAD);
			} else {
				const noKeysError = 'No media keys found.';
				this.emitError(noKeysError);
			}
		} catch (error) {
			this.uploadError = error;
			this.emitError(`An error occurred during one of the uploads: ${error}`);
			SitkaLogger.logMessage(error);
		}
	}

	getKeys() {
		return this.uploads
			.sort((a, b) => compareAscending(a.partNumber, b.partNumber))
			.map(({ key }) => key);
	}

	retry() {
		this.registerBeforeUnloadListener();
		this.uploads.forEach(this.uploadPart);
		this.end();
	}

	upload(content) {
		if (this.uploads.length === 0) {
			this.emit(StreamUploader.EVENTS.START);
		}

		this.registerBeforeUnloadListener();
		const partNumber = this.uploadCount++;

		const part = {
			content,
			partNumber,
			total: content.size
		};

		this.uploadPart(part);
		this.uploads.push(part);
	}

	uploadPart(part) {
		SitkaLogger.logMessage(
			JSON.stringify({
				event: `uploading part ${part.partNumber}`,
				totalSize: part.total,
				type: part.content.type
			}),
			LogEventName.UPLOAD
		);

		part.uploaded = 0;
		part.upload = executeUpload.call(part, {
			onError: this.emitError,
			onProgress: this.emitProgress
		});
		part.upload
			.then(({ Key }) => {
				part.key = Key;
				resolve(Key);
			})
			.catch(error => {
				reject(error);
			});
	}
}

export default StreamUploader;
