import { Checkbox, CheckboxProps } from 'display';
import * as React from 'react';

interface DataTableCheckboxProps {
	label?: string;
	dataTestId?: string;
	onClick: () => void;
	checked: CheckboxProps['Checked'];
}

function DataTableCheckbox({
	label,
	dataTestId,
	onClick,
	checked
}: DataTableCheckboxProps): JSX.Element {
	return <Checkbox data-testid={dataTestId} onClick={onClick} label={label} checked={checked} />;
}

export { DataTableCheckbox, DataTableCheckboxProps };
