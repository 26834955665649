import buildSectionHeadingField from './base/buildSectionHeadingField';

const FIELD_NAME = 'sectionHeadingPCPManagement';
const LABEL = 'PCP Management of Next Steps';

const SectionHeadingPCPManagement = buildSectionHeadingField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default SectionHeadingPCPManagement;
