import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Consult } from 'thriftgen/api_types';
import LinkToDetailPageButton from './LinkToDetailPageButton';

interface LinkToConsultDetailButtonProps {
	consultId: Consult['consult_id'];
}

function LinkToConsultDetailButton({ consultId }: LinkToConsultDetailButtonProps): JSX.Element {
	return (
		<LinkToDetailPageButton
			link={RouteUrls.buildUrl(RouteUrls.Key.CONSULT_DETAIL, { consultId })}
		/>
	);
}

export default LinkToConsultDetailButton;
