import * as React from 'react';
import PropTypes from 'prop-types';
import * as ACTION_STATUS from 'store/actionStatus';
import { Progress, Message, Button } from 'display';
import filesize from 'filesize';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

function getInitialState(uploadError) {
	return {
		progress: {
			uploaded: 0,
			total: 0
		},
		error: uploadError,
		status: uploadError ? ACTION_STATUS.FAILURE : ACTION_STATUS.INACTIVE
	};
}

function getProgressText(uploaded, total) {
	return `Uploading (${filesize(uploaded)} / ${filesize(total)})`;
}

const TOTAL_DEFAULT = 1000;

class Uploader extends React.Component {
	constructor(props) {
		super(props);
		this.state = getInitialState(props.uploadError);

		this.handleRetry = this.handleRetry.bind(this);
		this.handleUploadProgress = this.handleUploadProgress.bind(this);
		this.handleUploadError = this.handleUploadError.bind(this);
	}

	componentDidMount() {
		this.props.uploader.addUploadProgressListener(this.handleUploadProgress);
		this.props.uploader.addUploadErrorListener(this.handleUploadError);
	}

	handleUploadError(error) {
		this.setState(state => ({
			...state,
			error,
			status: ACTION_STATUS.FAILURE
		}));
		SitkaLogger.logMessage(error);
	}

	handleUploadProgress(progress) {
		this.setState(state => ({
			...state,
			progress,
			status: ACTION_STATUS.IN_PROGRESS
		}));
	}

	handleRetry() {
		this.setState(getInitialState());
		this.props.uploader.retry();
		SitkaLogger.logMessage('retrying', LogEventName.UPLOAD);
	}

	renderProgress() {
		const { uploaded, total } = this.state.progress;

		/* Total size may not be known upfront so we fall back to a default
		value in the meantime to 1) avoid showing NaN when trying to divide 0 by 0,
		and 2) avoid showing active progress while the total value is yet to be known.  */
		const isTotalSizeKnown = total > 0;

		return (
			<Progress
				value={uploaded}
				total={total || TOTAL_DEFAULT}
				active={isTotalSizeKnown}
				progress="percent"
				precision={1}
				indicating>
				{isTotalSizeKnown ? getProgressText(uploaded, total) : null}
			</Progress>
		);
	}

	renderRetry() {
		return (
			<React.Fragment>
				<p>If you wish to try uploading once again, click on the button below.</p>
				<Button.Primary onClick={this.handleRetry}>Retry upload</Button.Primary>
			</React.Fragment>
		);
	}

	render() {
		const { status, error } = this.state;

		if (status === ACTION_STATUS.FAILURE) {
			return (
				<Message negative>
					<Message.Header>Upload Failed</Message.Header>
					<p>{error && 'An error occurred while uploading the data.'}</p>
					{this.renderRetry()}
				</Message>
			);
		}

		return this.renderProgress();
	}
}

Uploader.defaultProps = {
	uploadError: null,
	onUploadRetry: null
};

Uploader.propTypes = {
	uploadError: PropTypes.object,
	onUploadRetry: PropTypes.func,
	uploader: PropTypes.shape({
		addUploadErrorListener: PropTypes.func.isRequired,
		addUploadProgressListener: PropTypes.func.isRequired,
		retry: PropTypes.func.isRequired
	}).isRequired
};

export default Uploader;
