import { getConsultSummaries } from 'api/admin';
import useQuery from 'swr';
import {
	GetConsultSummariesResponseCamel,
	GetConsultSummariesRequestCamel
} from 'thriftgen/thriftCamelTypes';

interface ConsultSummariesQuery {
	data: GetConsultSummariesResponseCamel | undefined;
	error: Error | undefined;
	revalidate: () => Promise<void>;
}

function useConsultSummariesQuery(args: GetConsultSummariesRequestCamel): ConsultSummariesQuery {
	const key = ['consultSummaries', args];
	const { data, error, mutate } = useQuery<GetConsultSummariesResponseCamel>(key, () => {
		const response = getConsultSummaries(args);
		return response;
	});

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		data,
		error,
		revalidate
	};
}

export default useConsultSummariesQuery;
export { ConsultSummariesQuery };
