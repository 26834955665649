import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const FIELD_NAME = 'isSupport';
const LABEL = 'Support';

const SupportElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

function Support(props: DataFormFieldComponentProps): JSX.Element {
	return <SupportElement key={FIELD_NAME} {...props} />;
}

Support.FIELD_NAME = FIELD_NAME;
Support.validate = () => null;

export default Support;
