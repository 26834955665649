import * as React from 'react';

import { Button } from 'display';
import { SEMANTIC_COLOR_STRINGS } from 'styles';

function PauseIconButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Icon
			backgroundColor={SEMANTIC_COLOR_STRINGS.WHITE}
			icon="pause"
			color={SEMANTIC_COLOR_STRINGS.PRIMARY}
			onClick={onClick}
			title="Pause"
		/>
	);
}

export default PauseIconButton;
