const characterInputRegex = /[-/\\^$*+?.()|[\]{}]/g;
const characterInputReplacement = '\\$&';
const escapeRegex = (str: string) => str.replace(characterInputRegex, characterInputReplacement);

function buildInputRegex(str: string, flags = 'g'): RegExp {
	return new RegExp(escapeRegex(str), flags);
}

const phoneRegex =
	/^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/;

export { buildInputRegex, phoneRegex };
