import * as React from 'react';
import noop from 'lib/noop';

import { DisplaySpecialtyField } from '../Specialty';
import type { DisplaySpecialtiesFieldProps } from './types';
import { getValue } from './helpers';
import { Form } from 'display';

function DisplaySpecialtiesField({ data }: DisplaySpecialtiesFieldProps): JSX.Element {
	const specialties = getValue(data);

	return (
		<Form.Field data-testid="displaySpecialtiesField">
			<label>Specialties</label>
			{specialties.map(specialty => (
				<DisplaySpecialtyField
					data={{ [DisplaySpecialtyField.FIELD_NAME]: specialty }}
					key={`specialty-${specialty}`}
					onChange={noop}
				/>
			))}
		</Form.Field>
	);
}

export { DisplaySpecialtiesField, DisplaySpecialtiesFieldProps };
