import * as React from 'react';

import { css, cx } from 'emotion';

const styles = {
	content: css`
		border: none;
		width: 100%;
	`
};

interface StyledIframeProps {
	id?: string;
	src: string;
}

class StyledIframe extends React.Component<StyledIframeProps> {
	public render(): React.ReactNode {
		const { id, src } = this.props;

		if (src) {
			return (
				<iframe className={cx(styles.content)} src={src} id={id}>
					Iframes are not supported in your browser
				</iframe>
			);
		}

		return null;
	}
}

export default StyledIframe;
