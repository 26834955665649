import { EditableFieldsError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';
import { PartnerContext, PartnerContextValue } from 'contexts/PartnerContext';
import { Loader } from 'display';
import { SitkaLogger } from 'lib/sitkaLogger';
import { snakeToCamel } from 'lib/stringConverter';
import * as React from 'react';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { SendGetEditableFieldsAction } from 'store/actionTypes';
import { sendGetEditableFields } from 'store/users';
import { GetEditableFieldsResponse } from 'thriftgen/api_types';
import { PartnerMutationsCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from '../DataFormTypes';
import { PartnerForm } from './PartnerForm';

interface ConsumerProps {
	fields: DataFormFieldComponent[];
	onDone(): void;
}

interface EditPartnerFormState {
	editableFields: string[];
}

interface WithApiCallDispatchProps {
	sendGetEditableFields(args: SendGetEditableFieldsAction): Promise<GetEditableFieldsResponse>;
}

type EditPartnerFormProps = ConsumerProps & WithApiCallProps & WithApiCallDispatchProps;

function getInitialState(): EditPartnerFormState {
	return {
		editableFields: []
	};
}

class EditPartnerForm extends React.PureComponent<EditPartnerFormProps, EditPartnerFormState> {
	static contextType = PartnerContext;
	public context!: React.ContextType<typeof PartnerContext>;

	constructor(props: EditPartnerFormProps) {
		super(props);

		this.handleGetEditableFieldsResponse = this.handleGetEditableFieldsResponse.bind(this);
		this.submit = this.submit.bind(this);

		this.state = getInitialState();
	}

	public componentDidMount(): void {
		const { partner } = this.getContext();

		this.props
			.sendGetEditableFields({ partnerId: partner.partnerId })
			.then(this.handleGetEditableFieldsResponse)
			.catch(SitkaLogger.logMessage);
	}

	public render() {
		const { fields, request, onDone } = this.props;
		const { partner } = this.getContext();
		const { message, status } = request.data;
		const { editableFields } = this.state;

		switch (status) {
			case FAILURE:
				return <EditableFieldsError message={message} />;

			case SUCCESS:
				return (
					<PartnerForm
						consumerEditableFields={editableFields}
						fields={fields}
						onCancel={onDone}
						onSubmit={this.submit}
						partner={partner}
					/>
				);

			case INACTIVE:
			case IN_PROGRESS:
			default:
				return <Loader active={true} />;
		}
	}

	private getContext(): PartnerContextValue {
		if (this.context) {
			return this.context;
		}

		throw new Error('Partner context not set.');
	}

	private handleGetEditableFieldsResponse({
		editable_fields: editableFields
	}: GetEditableFieldsResponse): void {
		if (editableFields) {
			this.setState(state => ({
				editableFields: [...state.editableFields, ...editableFields.map(snakeToCamel)]
			}));
		}
	}

	private submit(data: PartnerMutationsCamel): Promise<void> {
		const { updatePartner } = this.getContext();

		return updatePartner(data).then(this.props.onDone);
	}
}

export default withApiCall({ sendGetEditableFields }, EditPartnerForm);
