import LinkButton from 'components/Buttons/LinkButtons/LinkButton';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { Button, ButtonType } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { getConsultLink, isDraftConsult } from 'lib/consultHelpers';
import * as React from 'react';
import ConsultTableActionsDropdown from '../components/ConsultTableActionsDropdown';
import Strings from 'lib/strings';

const styles = {
	actionsDropdownMobile: css`
		position: absolute;
		top: 10px;
		right: 0px;
	`,
	viewButton: css`
		.ui.button {
			text-transform: uppercase;
		}
	`
};

export default (): DataTableColumn<ConsultComposite> => ({
	render: consultComposite => <ConsultTableActionsDropdown consultComposite={consultComposite} />,
	renderMobile: (consultComposite: ConsultComposite) => {
		const ButtonText = isDraftConsult(consultComposite)
			? Strings.Buttons.EDIT
			: Strings.Buttons.VIEW;
		return (
			<div>
				<div className={styles.actionsDropdownMobile}>
					<ConsultTableActionsDropdown consultComposite={consultComposite} />
				</div>
				<div className={styles.viewButton}>
					<LinkButton
						buttonType={ButtonType.TEXT_PRIMARY}
						text={ButtonText}
						size={Button.Size.TINY}
						link={getConsultLink(consultComposite)}
					/>
				</div>
			</div>
		);
	}
});
