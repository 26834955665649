import * as React from 'react';

import { SUPPORT_EMAIL } from 'lib/supportContacts';
import { Message } from 'display';

function VideoTooShortError(): JSX.Element {
	return (
		<Message negative={true}>
			The video could not be saved because it is not long enough or encountered an issue.
			Please try recording again and ensure your video is more than 1 second. If you continue
			to experience this issue, email <a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>
		</Message>
	);
}

export { VideoTooShortError };
