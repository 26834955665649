import Link from 'components/Link';
import { Button, Icon } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import Strings from 'lib/strings';

interface LinkToAddConsultDataButtonProps {
	consultId: string;
}

function LinkToAddConsultDataButton({ consultId }: LinkToAddConsultDataButtonProps): JSX.Element {
	return (
		<Link to={RouteUrls.buildUrl(RouteUrls.Key.CONSULT_ADD_DATA, { consultId })}>
			<Button.Outline className="addConsultDataButton" size={Button.Size.TINY}>
				<Icon name="plus" />
				{Strings.Buttons.ADD_DOCUMENTS}
			</Button.Outline>
		</Link>
	);
}

export default LinkToAddConsultDataButton;
