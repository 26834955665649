import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'display/';
import Link from 'components/Link';

function TakeMeBackButton({ to }) {
	return (
		<Link to={to}>
			<Button.Primary>Take me back</Button.Primary>
		</Link>
	);
}

TakeMeBackButton.defaultProps = {
	to: '/'
};

TakeMeBackButton.propTypes = {
	to: PropTypes.string
};

export default TakeMeBackButton;
