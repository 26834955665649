import { getConversations } from 'api/common';
import { ObjectType } from 'thriftgen/api_types';
import {
	ConversationCamel,
	GetConversationsRequestCamel,
	ManageConversationResponseCamel,
	UserCamel
} from 'thriftgen/thriftCamelTypes';
import useQuery from '../useQuery';
import {
	transformConversationsToComposite,
	updateConversationInConversations,
	updateUsersInConversations
} from './helpers';
import type { ConversationsQuery } from './types';

interface ConversationQueryValue {
	conversations: ConversationCamel[];
	users: UserCamel[];
}

function useConversationsQuery({
	parentId,
	parentType
}: GetConversationsRequestCamel): ConversationsQuery {
	const key = ['conversations', parentId, parentType];
	const { data, error, mutate } = useQuery<ConversationQueryValue>(key, () =>
		getConversations({ parentType, parentId })
	);
	const conversations = data
		? transformConversationsToComposite(data.conversations, data.users)
		: undefined;

	async function revalidate(): Promise<void> {
		await mutate();
	}

	async function updateConversation(response: ManageConversationResponseCamel): Promise<void> {
		await mutate(
			(currentData: ConversationQueryValue) =>
				Promise.resolve({
					...currentData,
					users: updateUsersInConversations(response.users, currentData.users),
					conversations: updateConversationInConversations(
						response.conversation,
						currentData.conversations
					)
				}),
			false
		);
	}

	return {
		conversations,
		error,
		updateConversation,
		revalidate
	};
}

const ParentType = ObjectType;
export { ConversationsQuery, useConversationsQuery, ParentType };
