import * as React from 'react';
import { css } from 'emotion';
import { getSharedToMessageByConversationId } from 'lib/conversationHelpers';

import { useCaseContext } from 'contexts';
import { ConversationMessageComposite } from 'hooks';

import ShareConsultMessageModal from './ShareConsultMessageModal';
import UnshareConsultMessageModal from './UnshareConsultMessageModal';

import { Paragraph, Timestamp, Text } from 'display';
import { colors } from 'styles';
import { isTextMessage } from 'lib/conversationMessageHelpers';

const styles = {
	container: (patientCommunicationsEnabled: boolean) => css`
		${patientCommunicationsEnabled &&
		`
			align-items: center;
			margin: 1em 2em;
			display: flex;
			padding-bottom: 1em;
			& > *:first-child {
				margin-right: 1em;
			}
		`}
	`
};

interface PatientConsultMessageSharingProps {
	message: ConversationMessageComposite;
}

function PatientConsultMessageSharing({
	message
}: PatientConsultMessageSharingProps): JSX.Element | null {
	const { theCase } = useCaseContext();
	const sharedMessage = getSharedToMessageByConversationId(
		message,
		theCase.patientConversationId
	);
	const patientCommunicationsEnabled = theCase.partner.enablePatientCommunications;
	const isUnsharedRedactedMessage = !sharedMessage && message.isRedacted;

	if (isTextMessage(message) || isUnsharedRedactedMessage) {
		return null;
	}

	function renderFooter(): JSX.Element | null {
		if (sharedMessage) {
			return patientCommunicationsEnabled ? (
				<UnshareConsultMessageModal sharedMessageId={sharedMessage.messageId} />
			) : (
				<Paragraph>
					Shared on <Timestamp timestamp={sharedMessage.createdAt} />
				</Paragraph>
			);
		} else {
			return patientCommunicationsEnabled ? (
				<ShareConsultMessageModal messageId={message.messageId} />
			) : null;
		}
	}

	return (
		<div className={styles.container(patientCommunicationsEnabled)}>
			{patientCommunicationsEnabled && (
				<Text size={Text.Size.T4} color={colors.neutral['300']}>
					Patient sharing
				</Text>
			)}
			{renderFooter()}
		</div>
	);
}

export default PatientConsultMessageSharing;
