import { getUserFullNameAndTitleAndCredentials } from 'lib/userHelpers';
import * as React from 'react';
import { User } from 'thriftgen/api_types';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = User;
type DisplayPatientNameComponent = DataFormFieldComponent<string, DataType>;
type DisplayPatientNameComponentProps = DataFormFieldComponentProps<DataType>;

const FIELD_NAME = 'specialist';

function getValue(data: DisplayPatientNameComponentProps['data']): DataType {
	return data[FIELD_NAME];
}

const DisplaySpecialistName: DisplayPatientNameComponent = ({
	data
}: DisplayPatientNameComponentProps): JSX.Element => {
	const value = getValue(data);
	const content = value ? getUserFullNameAndTitleAndCredentials(value) : null;

	return <ReadOnlyField label="Name">{content}</ReadOnlyField>;
};

DisplaySpecialistName.FIELD_NAME = FIELD_NAME;
DisplaySpecialistName.validate = alwaysValid;

export default DisplaySpecialistName;
