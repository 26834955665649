import { useConsultSummariesContext } from 'contexts/ConsultSummariesContext';
import { useSnackbarContext } from 'contexts';
import { Button } from 'display';
import { useLazyAsyncCall } from 'hooks';
import { SitkaLogger } from 'lib/sitkaLogger';
import Strings from 'lib/strings';
import * as React from 'react';
import { ConsultSummaryCamel, UpdateConsultSummaryRequestCamel } from 'thriftgen/thriftCamelTypes';

interface SendInviteButtonProps {
	consultId: ConsultSummaryCamel['consultId'];
}

function SendConsultSummaryButton({ consultId }: SendInviteButtonProps): JSX.Element {
	const { sendSummary } = useConsultSummariesContext();
	const { dispatch: dispatchSendSummary } =
		useLazyAsyncCall<UpdateConsultSummaryRequestCamel>(sendSummary);
	const { openSnackbar } = useSnackbarContext();

	async function handleClick(): Promise<void> {
		try {
			await dispatchSendSummary({ consultId }).promise();
			openSnackbar({ message: Strings.SnackbarMessages.CONSULT_SUMMARY_RESEND_SUCCESS });
		} catch (e) {
			SitkaLogger.logMessage(e);
			openSnackbar({ message: Strings.SnackbarMessages.CONSULT_SUMMARY_RESEND_ERROR });
		}
	}

	return <Button.Primary onClickAsync={handleClick}>{Strings.Buttons.SEND}</Button.Primary>;
}

export default SendConsultSummaryButton;
