import * as React from 'react';
import { css } from 'emotion';
import { RecordingStreamData } from 'lib/mediaDevices';
import MultiStreamRecorder from 'lib/MultiStreamRecorder';

import browserScheduling from 'lib/browserScheduling';
import AudioEventMessage from 'components/Recorder/AudioEventMessage';
import { AudioIssueType } from 'lib/AudioMonitor/types';

import { RecordingMouseEvents, RecordingOption, RecordingProps, RecordingStatus } from './types';

import Countdown from './Countdown';
import FaceVideo from './FaceVideo';
import RecorderActionBar from './RecorderActionBar';
import { VideoWithActions } from 'display';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

const styles = {
	minimized: css`
		width: 18rem;
		height: auto;
	`,
	messageContainer: css`
		position: relative;
	`
};

type RecorderLoaderProps = Pick<RecordingStreamData, 'streams'> &
	Pick<RecordingMouseEvents, 'onOpenSettings'> & {
		recorder: MultiStreamRecorder;
		recordingOption: RecordingOption;
	};

interface RecorderState {
	prerecordCountdown: boolean;
	status: RecordingStatus;
	audioAlert: AudioIssueType | null;
}

type RecorderProps = RecordingProps & RecorderLoaderProps;

class Recorder extends React.PureComponent<RecorderProps, RecorderState> {
	constructor(props: RecorderProps) {
		super(props);

		this.state = {
			prerecordCountdown: false,
			status: RecordingStatus.IDLE,
			audioAlert: null
		};

		this.onCountdownCanceled = this.onCountdownCanceled.bind(this);
		this.onCountdownComplete = this.onCountdownComplete.bind(this);

		this.pauseRecording = this.pauseRecording.bind(this);
		this.record = this.record.bind(this);
		this.resumeRecording = this.resumeRecording.bind(this);

		this.dismissAudioAlert = this.dismissAudioAlert.bind(this);
		this.startCountdown = this.startCountdown.bind(this);
		this.startRecording = this.startRecording.bind(this);
		this.stopRecording = this.stopRecording.bind(this);
		this.setAudioAlert = this.setAudioAlert.bind(this);
	}

	public render(): JSX.Element {
		const { prerecordCountdown, audioAlert } = this.state;
		const { minimized } = this.props;

		return (
			<React.Fragment>
				{prerecordCountdown && this.renderCountdown()}
				<div className={minimized ? styles.minimized : undefined}>
					<VideoWithActions>
						<VideoWithActions.Video>
							<div className={styles.messageContainer}>
								{!audioAlert || audioAlert === AudioIssueType.CLEAN_AUDIO ? null : (
									<AudioEventMessage
										audioIssue={audioAlert}
										onDismiss={this.dismissAudioAlert}
									/>
								)}
								{this.renderVideo()}
							</div>
						</VideoWithActions.Video>
						<VideoWithActions.Actions>
							{this.renderActionBar()}
						</VideoWithActions.Actions>
					</VideoWithActions>
				</div>
			</React.Fragment>
		);
	}

	public componentDidMount(): void {
		this.props.recorder.addAudioIssueEventListener(this.setAudioAlert);
	}

	public componentDidUpdate(_: RecorderProps, prevState: RecorderState): void {
		const prevStatus = prevState.status;
		const currentStatus = this.state.status;

		if (prevStatus === RecordingStatus.PAUSED && currentStatus === RecordingStatus.RECORDING) {
			browserScheduling.requestAnimationFrame(this.props.recorder.resume);
		}
	}

	private dismissAudioAlert(): void {
		this.setState(state => ({
			...state,
			audioAlert: null
		}));
	}

	private setAudioAlert(audioIssue: AudioIssueType) {
		this.setState(state => ({
			...state,
			audioAlert: audioIssue
		}));
	}

	private onCountdownCanceled(): void {
		this.setState(state => ({
			...state,
			prerecordCountdown: false
		}));
	}

	private onCountdownComplete(): void {
		this.setState(
			state => ({
				...state,
				prerecordCountdown: false
			}),
			this.record
		);
	}

	private pauseRecording(): void {
		this.props.recorder.pause().then(() => {
			this.setState(state => ({
				...state,
				status: RecordingStatus.PAUSED
			}));
		});
	}

	private record(): void {
		this.setState(
			state => ({
				...state,
				status: RecordingStatus.RECORDING
			}),
			this.startRecording
		);
	}

	private resumeRecording(): void {
		this.setState(state => ({
			...state,
			status: RecordingStatus.RECORDING
		}));
	}

	private startRecording(): void {
		try {
			this.props.recorder.start();
			this.props.onRecordingStart(this.props.recordingOption);
			SitkaLogger.logMessage('recording started', LogEventName.VIDEO);
		} catch (e) {
			SitkaLogger.logMessage(
				`Failed to start MultiStreamRecorder: ${e.message}`,
				LogEventName.VIDEO
			);
		}
	}

	private startCountdown(): void {
		this.setState(state => ({
			...state,
			prerecordCountdown: true
		}));
	}

	private async stopRecording(): Promise<void> {
		SitkaLogger.logMessage('recording stopped', LogEventName.VIDEO);
		return this.props.recorder
			.stop()
			.then(this.props.onRecordingEnd)
			.catch(error => {
				this.props.onRecordingEnd(null, error);
				SitkaLogger.logMessage(
					`Failed to stop MultiStreamRecorder: ${error.message}`,
					LogEventName.VIDEO
				);
			});
	}

	private renderActionBar(): JSX.Element {
		const { status } = this.state;
		const { minimized, onRecorderMinimize, onOpenSettings } = this.props;

		return (
			<RecorderActionBar
				minimized={minimized}
				onMinimize={onRecorderMinimize}
				onPause={this.pauseRecording}
				onResume={this.resumeRecording}
				onOpenSettings={onOpenSettings}
				onStart={this.startCountdown}
				onStop={this.stopRecording}
				status={status}
			/>
		);
	}

	private renderCountdown(): JSX.Element {
		return (
			<Countdown
				countdownLength={3}
				onComplete={this.onCountdownComplete}
				onCancel={this.onCountdownCanceled}
			/>
		);
	}

	private renderVideo(): JSX.Element {
		const { status } = this.state;
		const { streams } = this.props;

		return <FaceVideo status={status} stream={streams.webcam} />;
	}
}

export default Recorder;
