import * as React from 'react';

import City from './City';
import StateProvince from './StateProvince';
import { InputProps, Form, DropdownProps } from 'display';

const FIELD_NAME = 'location';

interface LocationFieldProps {
	data: {
		city: string;
		state: string;
		[key: string]: unknown;
	};
	editableFields: string[];
	errors: {
		fields: string[];
		messages: string[];
	};
	onChange: (
		event: React.SyntheticEvent<HTMLInputElement>,
		data: InputProps | DropdownProps
	) => void;
}

function getValue<K extends keyof LocationFieldProps['data']>(
	data: LocationFieldProps['data'],
	fieldName: K
): LocationFieldProps['data'][K] {
	return data[fieldName];
}

function validate(
	data: LocationFieldProps['data'],
	required: boolean
): null | LocationFieldProps['errors'] {
	const fields = [];

	if (required && !getValue(data, StateProvince.FIELD_NAME)) {
		fields.push(StateProvince.FIELD_NAME);
	}

	if (required && !getValue(data, City.FIELD_NAME)) {
		fields.push(City.FIELD_NAME);
	}

	if (fields.length > 0) {
		return {
			fields,
			messages: ['Please enter a valid city and state.']
		};
	}

	return null;
}

function Location(props: LocationFieldProps): JSX.Element {
	return (
		<Form.Group widths="equal">
			<City {...props} />
			<StateProvince {...props} />
		</Form.Group>
	);
}

Location.FIELD_NAME = FIELD_NAME;
Location.SUB_FIELD_NAMES = [City.FIELD_NAME, StateProvince.FIELD_NAME];
Location.validate = validate;

export default Location;
