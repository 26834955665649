import * as React from 'react';
import { Redirect } from 'react-router-dom';

import { RequestAuthUser } from 'components/Requestors';

interface RestrictedWrapperProps {
	render: () => JSX.Element;
	restricted: boolean;
}
function RestrictedWrapper({ render, restricted }: RestrictedWrapperProps): JSX.Element {
	if (restricted) {
		return (
			<RequestAuthUser blocking={true}>
				<Redirect to="/not-found" />
			</RequestAuthUser>
		);
	}

	return render();
}

export default RestrictedWrapper;
