import { DocumentDataAsset } from 'lib/documentAssets';

enum LoadingStatus {
	LOADED,
	PENDING,
	SKIPPED
}

interface DocumentToLoad {
	readonly id: DocumentDataAsset['id'];
}

interface LoadedDocument extends DocumentToLoad {
	content: File;
	readonly source: DocumentDataAsset['presigned_url'];
	status: LoadingStatus.LOADED;
}

interface SkippedDocument extends DocumentToLoad {
	content: null;
	readonly source: DocumentDataAsset['presigned_url'] | null;
	status: LoadingStatus.SKIPPED;
}

interface PendingDocument extends DocumentToLoad {
	content: null;
	readonly source: DocumentDataAsset['presigned_url'];
	status: LoadingStatus.PENDING;
}

type LoadableDocument = LoadedDocument | SkippedDocument | PendingDocument;
type DocumentSource = LoadableDocument['content'] | LoadableDocument['source'];

interface UseDocumentLoadingResult {
	getDocumentSource: (documentId: DocumentDataAsset['id']) => DocumentSource;
}

interface UseDocumentLoadingOptions {
	skipTiffImagePreload?: boolean;
	skipVideoPreload?: boolean;
}

export {
	DocumentSource,
	PendingDocument,
	SkippedDocument,
	LoadedDocument,
	LoadableDocument,
	LoadingStatus,
	UseDocumentLoadingOptions,
	UseDocumentLoadingResult
};
