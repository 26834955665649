import { DropdownItemProps, Form } from 'display';
import noop from 'lib/noop';
import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import { translateThriftEnum } from 'lib/i18n';
import { compareAscending } from 'lib/sort';
import { isArray } from 'lodash';

interface BuildSelectFieldProps {
	options: DropdownItemProps[];
	fieldName: string;
	label: string | JSX.Element;
	placeholder?: string;
	validate?: (value: string | string[], required: boolean) => null | string[];
	validationMessage?: string;
	multiple?: boolean;
}

function buildSelectField({
	fieldName,
	label,
	options,
	placeholder,
	validate,
	multiple = false,
	validationMessage = 'Please enter a value'
}: BuildSelectFieldProps): DataFormFieldComponent {
	function defaultValidate(value: string | string[], required: boolean): null | string[] {
		if (required && ((multiple && !value?.length) || (!multiple && !value))) {
			return [validationMessage];
		}

		return null;
	}

	function validateData(
		data: { [key: string]: string },
		required: boolean
	): ReturnType<DataFormFieldComponent['validate']> {
		const validateFunction = validate || defaultValidate;
		const messages = validateFunction(data[fieldName], required);
		return messages ? { fields: [fieldName], messages } : null;
	}

	function SelectField({
		data,
		editableFields,
		errors = {
			fields: [],
			messages: []
		},
		onChange = noop,
		labelOverride
	}: DataFormFieldComponentProps): JSX.Element | null {
		const defaultValue = multiple ? [] : null;
		const value = data[fieldName] || defaultValue;
		const hasError = errors.fields.includes(fieldName);
		const canEdit = editableFields.includes(fieldName);

		if (canEdit) {
			return (
				<Form.Select
					data-testid={fieldName}
					error={hasError}
					id={fieldName}
					label={labelOverride || label}
					multiple={multiple}
					name={fieldName}
					onChange={onChange}
					options={options}
					placeholder={placeholder}
					search={true}
					value={value}
				/>
			);
		}

		if (value !== undefined && value !== null) {
			const displayValues = options
				.filter(option => {
					if (isArray(value)) {
						return value.includes(option.key);
					} else {
						return value === option.key;
					}
				})
				.map(option => option.text);
			return (
				<Form.Field>
					<label>{label}</label>
					<span>{displayValues.join(', ')}</span>
				</Form.Field>
			);
		}

		return null;
	}

	SelectField.FIELD_NAME = fieldName;
	SelectField.validate = validateData;
	SelectField.displayName = `SelectField-${fieldName}`;

	return SelectField;
}

function buildSelectFieldOptions(
	selectValues: { [key: number]: string },
	translations: { [k: number]: { [k: number]: string } },
	isSorted: boolean = true
): DropdownItemProps[] {
	const options = Object.keys(selectValues).map(selectValue => {
		const v = selectValues[selectValue];

		return {
			key: v,
			value: v,
			text: translateThriftEnum(v, translations)
		};
	});
	if (isSorted) {
		options.sort((a, b) => {
			return compareAscending(a.text, b.text);
		});
	}

	return options;
}
export default buildSelectField;
export { buildSelectFieldOptions };
