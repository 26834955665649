import { getReadableBirthDate, yearsSinceDate } from 'lib/date';
import { translateThriftEnum } from 'lib/i18n';
import {
	DateOfBirth,
	Partner,
	SPECIALTY,
	Specialty,
	User,
	UserTitle,
	USER_CREDENTIAL,
	USER_TITLE
} from 'thriftgen/api_types';
import { UserCamel } from 'thriftgen/thriftCamelTypes';
import { getUniqueElements } from './getUniqueElements';
import { objectSnakeToCamel } from './objectConverter';

const NO_NAME_FALLBACK = 'No name';
const hasTitle = (title: User['title']) => title !== null && title !== UserTitle.NONE;

enum Role {
	SMA,
	SITKA_OPERATIONS,
	PARTNER_MEMBER,
	PATIENT
}

function getRoleReadableForm(role: Role): string {
	switch (role) {
		case Role.SMA:
			return 'SMA';
		case Role.SITKA_OPERATIONS:
			return 'Sitka Operations';
		case Role.PATIENT:
			return 'Patient';
		case Role.PARTNER_MEMBER:
			return 'Partner Member';
		default:
			throw new Error(`Unknown role ${role}`);
	}
}

function getRolesFromListInReadableForm(user: User, roles: Role[]): string {
	const result: Role[] = roles.filter((role: Role): boolean => {
		switch (role) {
			case Role.SMA:
				return user.is_sitka_medical_associate;
			case Role.SITKA_OPERATIONS:
				return user.is_sitka_operations;
			case Role.PATIENT:
				return isUserPatient(user);
			case Role.PARTNER_MEMBER:
				return isUserAdmin(user) || isUserMember(user);
		}
	});

	if (result.length === 0) {
		return '(none)';
	}

	return result.map(getRoleReadableForm).join(', ');
}

function getAllRolesInReadableForm(user: User): string {
	return getRolesFromListInReadableForm(user, [
		Role.SITKA_OPERATIONS,
		Role.SMA,
		Role.PARTNER_MEMBER,
		Role.PATIENT
	]);
}

function getFamiliarAddressName({
	first_name: firstName,
	last_name: lastName,
	title
}: User): string {
	if (!firstName && !lastName) {
		return NO_NAME_FALLBACK;
	}

	if (hasTitle(title)) {
		if (lastName) {
			return `${translateThriftEnum(title, USER_TITLE)} ${lastName}`;
		}

		return `${translateThriftEnum(title, USER_TITLE)} ${firstName}`;
	}

	if (firstName) {
		return firstName;
	}

	return lastName;
}

function getFirstNameLastName(user: User | UserCamel): string {
	const firstName = isCamel(user) ? user.firstName : user.first_name;
	const lastName = isCamel(user) ? user.lastName : user.last_name;

	if (firstName && lastName) {
		return `${firstName} ${lastName}`;
	}

	if (lastName) {
		return lastName;
	}

	if (firstName) {
		return firstName;
	}

	return NO_NAME_FALLBACK;
}

function getLastNameFirstName(user: User | UserCamel): string {
	const firstName = isCamel(user) ? user.firstName : user.first_name;
	const lastName = isCamel(user) ? user.lastName : user.last_name;

	if (lastName && firstName) {
		return `${lastName}, ${firstName}`;
	}

	if (lastName) {
		return lastName;
	}

	if (firstName) {
		return firstName;
	}

	return NO_NAME_FALLBACK;
}

function getPatientName(user: User | UserCamel | undefined): string {
	if (user) {
		const userCamel = isCamel(user) ? user : objectSnakeToCamel(user);
		const { firstName, lastName } = userCamel;

		if (firstName && lastName) {
			return `${firstName} ${lastName}`;
		}
	}

	return 'Patient name being added';
}

function getDoctorName(
	user: User | UserCamel | undefined,
	includeFirstNameWithTitle = false
): string {
	if (user) {
		const displayTitles: UserTitle[] = [UserTitle.DR];

		const userCamel = isCamel(user) ? user : objectSnakeToCamel(user);
		const { title, firstName, lastName } = userCamel;

		let name = '';

		if (displayTitles.includes(title)) {
			name += `${translateThriftEnum(title, USER_TITLE)} `;
		}

		if (!displayTitles.includes(title) || includeFirstNameWithTitle) {
			name += `${firstName} `;
		}

		name += lastName;

		return name;
	}

	return '';
}

function getTranslatedUserSpecialties(user: User): string {
	const specialties = user.specialties.map((specialty: Specialty): string =>
		translateThriftEnum(specialty, SPECIALTY)
	);
	return specialties.join(', ');
}

function getUniquePartnerAssociations(user: User): string[] {
	const { admin_for_partner_ids, patient_for_partner_ids, member_for_partner_ids } = user;

	return getUniqueElements([
		...admin_for_partner_ids,
		...patient_for_partner_ids,
		...member_for_partner_ids
	]);
}

function getUserById(users: User[], id: User['user_id']): User | undefined {
	return users.find(({ user_id }) => user_id === id);
}

function getUserCredentials(user: User | UserCamel): string {
	const credentials = isCamel(user) ? user.credentials : user.credentials;
	const readable = credentials.map(cred => translateThriftEnum(cred, USER_CREDENTIAL));
	return readable.join(', ');
}

function getUserFullNameAndTitle(user: User | UserCamel): string {
	const firstName = isCamel(user) ? user.firstName : user.first_name;
	const lastName = isCamel(user) ? user.lastName : user.last_name;

	if (!firstName && !lastName) {
		return NO_NAME_FALLBACK;
	}

	const fullName: string = [firstName, lastName].filter(Boolean).join(' ');

	if (hasTitle(user.title)) {
		return `${translateThriftEnum(user.title, USER_TITLE)} ${fullName}`;
	}

	return fullName;
}

function getUserFullNameAndTitleAndCredentials(user: User | UserCamel): string {
	const base = getUserFullNameAndTitle(user);

	if (base === NO_NAME_FALLBACK || !hasCredentials(user)) {
		return base;
	}

	return `${base}, ${getUserCredentials(user)}`;
}

function hasContactInformation(user: User | UserCamel): boolean {
	const { email } = user;
	return !!email;
}

function hasCredentials(user: User | UserCamel): boolean {
	const { credentials } = user;
	return Array.isArray(credentials) && credentials.length > 0;
}

function hasName({ first_name: firstName, last_name: lastName }: User): boolean {
	return !!firstName && !!lastName;
}

function hasRoles(user: User): boolean {
	return (
		isUserAdmin(user) ||
		isUserMember(user) ||
		isUserPatient(user) ||
		user.is_sitka_medical_associate ||
		user.is_sitka_operations
	);
}

function isCamel(user: User | UserCamel): user is UserCamel {
	return 'firstName' in user;
}

function isUserAdmin(user: User): boolean {
	return user.admin_for_partner_ids.length > 0;
}

function isUserAdminForPartner(user: User, partnerId: Partner['partner_id']): boolean {
	if (user.is_sitka_operations) {
		return true;
	}
	return user.admin_for_partner_ids.includes(partnerId);
}

function isUserMember(user: User): boolean {
	return user.member_for_partner_ids.length > 0;
}

function isUserPatient(user: User): boolean {
	return user.patient_for_partner_ids.length > 0;
}

function isUserPatientForPartner(user: User, partnerId: Partner['partner_id']): boolean {
	return user.patient_for_partner_ids.includes(partnerId);
}

function isUserForPartner(user: User, partnerId: Partner['partner_id']): boolean {
	const { admin_for_partner_ids, patient_for_partner_ids, member_for_partner_ids } = user;

	const partnersForActor = [
		...admin_for_partner_ids,
		...patient_for_partner_ids,
		...member_for_partner_ids
	];

	return partnersForActor.includes(partnerId);
}

function isUserSitkaOperations(user: User): boolean {
	return user.is_sitka_operations;
}

function isUserSitkaMedicalAssociate(user: User): boolean {
	return user.is_sitka_medical_associate;
}

function formatDateOfBirth(DOB: DateOfBirth): string {
	return `${getReadableBirthDate(DOB.year, DOB.month, DOB.day)} (${yearsSinceDate(
		DOB.year,
		DOB.month,
		DOB.day
	)} years old)`;
}

function canViewClinicalData(user: User): boolean {
	return isUserSitkaOperations(user) || isUserSitkaMedicalAssociate(user);
}

export {
	formatDateOfBirth,
	getAllRolesInReadableForm,
	getFamiliarAddressName,
	getFirstNameLastName,
	getLastNameFirstName,
	getPatientName,
	getDoctorName,
	getRoleReadableForm,
	getRolesFromListInReadableForm,
	getTranslatedUserSpecialties,
	getUniquePartnerAssociations,
	getUserById,
	getUserCredentials,
	getUserFullNameAndTitle,
	getUserFullNameAndTitleAndCredentials,
	hasContactInformation,
	hasName,
	hasRoles,
	isUserAdmin,
	isUserAdminForPartner,
	isUserMember,
	isUserPatient,
	isUserPatientForPartner,
	isUserForPartner,
	isUserSitkaOperations,
	isUserSitkaMedicalAssociate,
	Role,
	canViewClinicalData
};
