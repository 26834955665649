import { USER_FILTERS } from 'api/customFilters';
import { FilterType } from './types';
import Strings from 'lib/strings';

const USER_SEARCH_FILTER = {
	attribute: USER_FILTERS.USER_NAME,
	placeholder: Strings.Labels.SEARCH_USER_PLACEHOLDER,
	type: FilterType.SEARCH
};

export { USER_SEARCH_FILTER };
