import { css } from 'emotion';
import { mediaQueries, colors } from 'styles';

// Some styling of buttons also live in web/src/semantic/src/themes/sitka/elements/button.overrides.

// TODO: Update this to refer to styles/colors when hues and colors have
// been consolidated.

const styles = {
	base: css`
		transition: all 250ms ease-in-out !important;

		&:focus,
		&:active {
			box-shadow: 0px 0px 0px 4px ${colors.primary.base};
		}
	`,
	iconBackgroundColor: css`
		padding: 0.5em !important;
	`,
	responsive: css`
		width: 100%;

		${mediaQueries.desktop} {
			width: auto;
		}
	`,
	variations: {
		destructive: css`
			color: ${colors.white} !important;
			background-color: ${colors.problem['500']} !important;

			&:hover {
				background-color: ${colors.problem['400']} !important;
			}
		`,
		minimal: css`
			background-color: ${colors.white} !important;
			color: ${colors.primary.base} !important;

			&:hover {
				background-color: ${colors.primary['25']} !important;
			}
		`,
		outline: css`
			background-color: ${colors.white} !important;
			border: 1px solid !important;
			color: ${colors.primary.base} !important;

			&:hover {
				background-color: ${colors.primary['25']} !important;
			}
		`,
		primary: css`
			color: ${colors.white} !important;
			background-color: ${colors.primary.base} !important;

			&:hover {
				background-color: ${colors.primary['400']} !important;
			}

			&:focus,
			&:active {
				box-shadow: 0px 0px 0px 4px ${colors.primary['400']};
			}
		`,
		secondary: css`
			background-color: ${colors.primary['100']} !important;
			color: ${colors.primary['700']} !important;

			&:hover {
				background-color: ${colors.primary['50']} !important;
			}
		`
	}
};

export default styles;
