import { ACTION_TYPES } from './admin.reducers';

export function setPartners(partners) {
	return {
		type: ACTION_TYPES.SET_PARTNERS,
		partners
	};
}

export function handleSendGetPartnersResponse(response) {
	return dispatch => {
		dispatch(setPartners(response.partners));
	};
}

export function handleSinglePartnerResponse(response) {
	return dispatch => {
		dispatch(setPartners([response.partner]));
	};
}
