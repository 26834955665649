import buildConsultState from './buildConsultState';
import buildSpecialtyType from './buildSpecialtyType';
import buildReviewStatus from './buildReviewStatus';
import buildRequestDate from './buildRequestDate';

const consultFilters = {
	buildConsultState,
	buildSpecialtyType,
	buildReviewStatus,
	buildRequestDate
};

export default consultFilters;
