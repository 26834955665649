import * as React from 'react';
import { Route } from 'react-router-dom';

import AppPage from 'components/Page/AppPage';
import ForceReload from 'components/Routing/ForceReload';
import { RouteRendererProps } from 'routing/types';

function UnauthenticatedRoute({
	canSafelyReload,
	exact,
	pageOptions,
	path,
	template: Template
}: RouteRendererProps): JSX.Element {
	function renderRoute(props: any): JSX.Element {
		return (
			<ForceReload canSafelyReload={canSafelyReload}>
				<AppPage pageOptions={pageOptions}>
					<Template {...props} />
				</AppPage>
			</ForceReload>
		);
	}

	return <Route exact={exact} path={path} render={renderRoute} />;
}

export default UnauthenticatedRoute;
