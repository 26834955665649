import { WebViewerInstance } from '@pdftron/webviewer';
import { initializeVideoViewer, unmountWebViewerVideo } from '@pdftron/webviewer-video';
import type { WebViewerVideoInstance } from '@pdftron/webviewer-video/types/src';

import { PdfViewerAsset, PdfViewerVideo, VideoControlsOptions } from './types';

const dataIsVideo = (data: PdfViewerAsset): data is PdfViewerVideo => data.isVideo;

// .custom-container is managed by PDFTRON, not ourselves.
const getVideoControlsContainer = (webViewer: WebViewerInstance): null | HTMLElement =>
	webViewer.UI.iframeWindow.document.querySelector('.custom-container');

function addVideoListeners(element: HTMLVideoElement, handlers: VideoControlsOptions): void {
	element.addEventListener('play', handlers.onPlay);
	element.addEventListener('ended', handlers.onEnded);
	element.addEventListener('error', handlers.onError);
	element.addEventListener('pause', handlers.onPause);
	element.addEventListener('seeked', handlers.onSeeked);
	element.addEventListener('timeupdate', handlers.onTimeUpdate);
}

function removeVideoListeners(element: HTMLVideoElement, handlers: VideoControlsOptions): void {
	element.removeEventListener('play', handlers.onPlay);
	element.removeEventListener('ended', handlers.onEnded);
	element.removeEventListener('error', handlers.onError);
	element.removeEventListener('pause', handlers.onPause);
	element.removeEventListener('seeked', handlers.onSeeked);
	element.removeEventListener('timeupdate', handlers.onTimeUpdate);
}

function setupVideoViewer(webViewer: WebViewerInstance): Promise<WebViewerVideoInstance> {
	const config = {
		license: process.env.REACT_APP_PDFTRON_VIDEO_LICENSE_KEY
	};
	return initializeVideoViewer(webViewer, config);
}

async function loadVideo({
	data,
	eventHandlers,
	onLoad,
	videoViewer,
	webViewer
}: {
	data: PdfViewerVideo;
	eventHandlers: VideoControlsOptions;
	onLoad: () => void;
	videoViewer: WebViewerVideoInstance;
	webViewer: WebViewerInstance;
}): Promise<() => void> {
	webViewer.Core.documentViewer.addEventListener('videoElementLoaded', setupVideo);

	function setupVideo(): void {
		const videoElement = videoViewer.getVideo().getElement();

		addVideoListeners(videoElement, eventHandlers);
		onLoad();
	}

	function unloadVideo(): void {
		const videoElement = videoViewer.getVideo().getElement();

		removeVideoListeners(videoElement, eventHandlers);
		webViewer.Core.documentViewer.removeEventListener('videoElementLoaded', setupVideo);
		unmountWebViewerVideo(webViewer, getVideoControlsContainer(webViewer));
	}

	try {
		await videoViewer.loadVideo(data.source, { fileName: data.name });
		return Promise.resolve(unloadVideo);
	} catch (e) {
		webViewer.Core.documentViewer.removeEventListener('videoElementLoaded', setupVideo);
		return Promise.reject(e);
	}
}

export { dataIsVideo, setupVideoViewer, loadVideo };
