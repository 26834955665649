import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent } from '../DataFormTypes';

const FIELD_NAME = 'isActive';
const LABEL = 'Deactivated';

const Deactivated: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL,
	isInverted: true
});

export default Deactivated;
