import { DataTableColumn } from 'components/DataTable/DataTable';

const buildIsActive = (): DataTableColumn<User> => ({
	header: 'Active',
	render: ({ is_active: isActive }: User): string | null => {
		if (isActive) {
			return 'Yes';
		} else {
			return 'No';
		}
	}
});

export { buildIsActive };
