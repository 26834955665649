import { manageConversation as manageConversationRequest } from 'api/common';
import {
	ConversationComposite,
	ConversationsQueryMutators
} from 'hooks/queries/conversation/types';
import * as React from 'react';
import { RedactedReason } from 'thriftgen/api_types';
import { NewConversationMessageCamel } from 'thriftgen/thriftCamelTypes';

type PostNewMessageMutator = (
	args: Omit<NewConversationMessageCamel, 'sharedMessageId'>
) => Promise<void>;
type RedactMessageMutator = (args: { messageId: string; reason: RedactedReason }) => Promise<void>;
type RestoreMessageMutator = (args: { messageId: string }) => Promise<void>;
type ShareMessageMutator = (args: {
	targetConversationId: string;
	sharedMessageId: string;
}) => Promise<void>;
type UnshareMessageMutator = (args: {
	targetConversationId: string;
	sharedMessageId: string;
}) => Promise<void>;
type RevalidateMutator = () => Promise<void>;

interface ConversationContextMutators {
	postNewMessage: PostNewMessageMutator;
	redactMessage: RedactMessageMutator;
	restoreMessage: RestoreMessageMutator;
	shareMessage: ShareMessageMutator;
	unshareMessage: UnshareMessageMutator;
	revalidate: RevalidateMutator;
}

interface ConversationContextData {
	conversation: ConversationComposite;
}

type ConversationContextValue = ConversationContextData & ConversationContextMutators;

const ConversationContext = React.createContext<ConversationContextValue | undefined>(undefined);

function buildConversationMutators(
	conversationId: ConversationComposite['conversationId'],
	updateConversation: ConversationsQueryMutators['updateConversation'],
	revalidate: ConversationsQueryMutators['revalidate']
): ConversationContextMutators {
	const postNewMessage: PostNewMessageMutator = message =>
		manageConversationRequest({
			conversationId,
			message
		}).then(updateConversation);

	const redactMessage: RedactMessageMutator = args =>
		manageConversationRequest({
			conversationId,
			messageToRedact: {
				conversationMessageId: args.messageId,
				redacted: true,
				redactedReason: args.reason
			}
		}).then(updateConversation);

	const restoreMessage: RestoreMessageMutator = args =>
		manageConversationRequest({
			conversationId,
			messageToRedact: {
				conversationMessageId: args.messageId,
				redacted: false,
				redactedReason: undefined
			}
		}).then(updateConversation);

	const shareMessage: ShareMessageMutator = args => {
		if (args.targetConversationId === conversationId) {
			return Promise.reject(new Error('Cannot share to same conversation'));
		}

		// Revalidates the original query as a different conversation
		// was mutated.
		return manageConversationRequest({
			conversationId: args.targetConversationId,
			message: {
				sharedMessageId: args.sharedMessageId
			}
		}).then(revalidate);
	};

	const unshareMessage: UnshareMessageMutator = async args => {
		if (args.targetConversationId === conversationId) {
			return Promise.reject(new Error('Cannot unshare from same conversation'));
		}

		// Revalidates the original query as a different conversation
		// was mutated.
		return manageConversationRequest({
			conversationId: args.targetConversationId,
			messageToRemoveId: args.sharedMessageId
		}).then(revalidate);
	};

	return {
		postNewMessage,
		redactMessage,
		restoreMessage,
		shareMessage,
		unshareMessage,
		revalidate
	};
}

function useConversationContext(): ConversationContextValue {
	const context = React.useContext(ConversationContext);

	if (context === undefined) {
		throw new Error('No conversation has been set');
	}

	return context;
}

export {
	buildConversationMutators,
	useConversationContext,
	ConversationContext,
	ConversationContextData,
	ConversationContextMutators,
	ConversationContextValue
};
