import { usePaginationContext } from 'contexts/PaginationContext';
import { Button, Dropdown, DropdownItemProps, DropdownProps, Text } from 'display';
import { css } from 'emotion';
import * as React from 'react';

const styles = {
	container: css`
		display: flex;
		flex-direction: row;
		align-items: center;

		& > *:first-child {
			margin-right: 1rem;
		}
	`
};

const pageSizeOptions = [10, 20, 50, 100];

function getPageSizeOptions(pageSizes: number[]): DropdownProps['options'] {
	return pageSizes.map(
		(option: number): DropdownItemProps => ({
			value: option,
			key: option,
			text: option
		})
	);
}

function PageSizeControl(): JSX.Element {
	const { parameters, setPageSize } = usePaginationContext();

	function onChange(_: React.SyntheticEvent<HTMLElement>, { value }: DropdownProps): void {
		setPageSize(value as number);
	}

	return (
		<div className={styles.container}>
			<Text size={Text.Size.T4}>Rows per page</Text>
			<Button.Text size={Button.Size.SMALL}>
				<Dropdown
					onChange={onChange}
					options={getPageSizeOptions(pageSizeOptions)}
					value={parameters.pageSize}
				/>
			</Button.Text>
		</div>
	);
}

export default PageSizeControl;
