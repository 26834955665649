import { RouteComponentProps } from 'react-router-dom';

function getFullPath(location: RouteComponentProps['location']): string | null {
	if (location === undefined) {
		return null;
	}

	const { pathname, hash, search } = location;
	let path = pathname;

	if (hash) {
		path += hash;
	}

	if (search) {
		path += search;
	}

	return path;
}

export { getFullPath };
