import LinkButton from 'components/Buttons/LinkButtons/LinkButton';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { Button, ButtonType, Label } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { getDoctorName } from 'lib/userHelpers';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { colors, fontSize } from 'styles';
import { isClinicalReviewNew } from 'lib/clinicalReviewHelpers';

const styles = {
	mobile: css`
		text-transform: uppercase;
		color: ${colors.primary.base};
		font-size: ${fontSize[0]};
		margin-bottom: 1em;
	`,
	newLabel: css`
		margin-left: 1em !important;
	`
};

const header = 'Specialist Name';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ consultId, specialist, clinicalReview }: ConsultComposite) => {
		const isNew = !!clinicalReview && isClinicalReviewNew(clinicalReview);

		return (
			<div>
				<LinkButton
					buttonType={ButtonType.TEXT_PRIMARY}
					text={getDoctorName(specialist, true)}
					size={Button.Size.TINY}
					link={RouteUrls.buildUrl(RouteUrls.Key.CONSULT_CLINICAL_REVIEW, {
						consultId: consultId
					})}
				/>
				{isNew && <Label className={styles.newLabel}>New</Label>}
			</div>
		);
	}
});
