import { Button, ButtonType, CardModal, Message } from 'display';
import Strings from 'lib/strings';
import * as React from 'react';
import ModalPortal from './ModalPortal';

interface RemoveUserFromPartnerModalProps {
	modalPortalId: string;
	open: boolean;
	error?: Error;
	loading?: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

export default function RemoveUserFromPartnerModal({
	modalPortalId,
	open,
	error,
	loading,
	onConfirm,
	onCancel
}: RemoveUserFromPartnerModalProps): JSX.Element {
	const ButtonVariationPrimary = Button[ButtonType.PRIMARY];
	const ButtonVariationTextPrimary = Button[ButtonType.TEXT_PRIMARY];

	return (
		<ModalPortal id={modalPortalId}>
			<CardModal open={open} data-testid="RemoveUserFromPartnerModal">
				<CardModal.Title>{Strings.Modals.REMOVE_USER_FROM_PARTNER_TITLE}</CardModal.Title>
				<CardModal.Description>
					{Strings.Modals.REMOVE_USER_FROM_PARTNER_TEXT}
				</CardModal.Description>
				{!!error && <Message negative>{error.message}</Message>}
				<Button.Group>
					<ButtonVariationTextPrimary onClick={onCancel} size={Button.Size.SMALL}>
						{Strings.Buttons.CANCEL}
					</ButtonVariationTextPrimary>
					<ButtonVariationPrimary
						onClick={onConfirm}
						size={Button.Size.SMALL}
						loading={loading}>
						{Strings.Buttons.REMOVE_USER_FROM_PARTNER}
					</ButtonVariationPrimary>
				</Button.Group>
			</CardModal>
		</ModalPortal>
	);
}
