import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';

class SitkaDatadog {
	private static initialized: boolean;

	constructor() {
		SitkaDatadog.initialized = false;
	}

	static isInitialized(): boolean {
		return this.initialized;
	}

	static initialize(): void {
		//init Logs
		datadogLogs.init({
			clientToken: 'pube106a98c72e99549da7dda7f1cbf6fca',
			site: 'datadoghq.com',
			service: 'web',
			env: process.env.REACT_APP_SITKA_ENV,
			forwardErrorsToLogs: true,
			forwardConsoleLogs: 'all',
			forwardReports: 'all',
			sampleRate: 100
		});

		// Init RUM
		datadogRum.init({
			applicationId: '23c6f2a3-a863-4f7e-8cd5-30f78df89018',
			clientToken: 'pub0804af1a9e0f36b3b3391884f89bf2b0',
			site: 'datadoghq.com',
			service: 'web',
			env: process.env.REACT_APP_SITKA_ENV,
			version: '0.0.1',
			sampleRate: 100,
			premiumSampleRate: 100,
			trackInteractions: true,
			defaultPrivacyLevel: 'mask',
			enableExperimentalFeatures: ['clickmap']
		});

		datadogRum.startSessionReplayRecording();
		this.initialized = true;
	}

	static safelyRun<Result>(callback: () => Result): Result | void {
		if (this.isInitialized()) {
			try {
				return callback();
			} catch (e) {
				datadogLogs.logger.error('Datadog initialization error', e);
			}
		}
	}

	static sendEvent(name: string, msg: Record<string, unknown>): void {
		this.safelyRun(() => {
			datadogLogs.logger.info(name, msg);
		});
	}
	static sendError(msg: Record<string, unknown>): void {
		this.safelyRun(() => {
			datadogLogs.logger.error('error', msg);
		});
	}
	static setUserId(id: string): void {
		this.safelyRun(() => {
			datadogLogs.addLoggerGlobalContext('user_id', id);
			datadogRum.setUser({ id: id });
		});
	}

	static setAuthSessionId(id: string): void {
		this.safelyRun(() => {
			datadogLogs.addLoggerGlobalContext('auth_session_id', id);
			datadogRum.addRumGlobalContext('auth_session_id', id);
		});
	}
}

export default SitkaDatadog;
