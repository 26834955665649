import RemoveUserFromPartnerModal from 'components/Modals/RemoveUserFromPartnerModal';
import { useUserContext } from 'contexts';
import { SemanticICONS } from 'display';
import { useLazyAsyncCall } from 'hooks';
import React, { useState } from 'react';
import { Partner } from 'thriftgen/api_types';

interface RemoveUserFromPartnerActionProps {
	data: Partner;
	modalPortalId: string;
	children: React.ReactNode;
}

function RemoveUserFromPartnerAction({
	data,
	modalPortalId,
	children
}: RemoveUserFromPartnerActionProps): JSX.Element | null {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const { removeUserFromPartner } = useUserContext();

	const {
		dispatch: sendRemoveUserFromPartner,
		loading,
		error
	} = useLazyAsyncCall(removeUserFromPartner);

	const { partner_id: partnerId } = data;

	const openModal = () => {
		setModalOpen(true);
	};
	const closeModal = () => setModalOpen(false);

	async function onConfirm() {
		await sendRemoveUserFromPartner(partnerId).promise();
		return closeModal();
	}

	async function onCancel() {
		closeModal();
	}

	return (
		<React.Fragment>
			<RemoveUserFromPartnerModal
				modalPortalId={modalPortalId}
				open={modalOpen}
				onConfirm={onConfirm}
				onCancel={onCancel}
				loading={loading}
				error={error}
			/>

			<div onClick={openModal}>{children}</div>
		</React.Fragment>
	);
}

RemoveUserFromPartnerAction.ICON = 'trash' as SemanticICONS;
RemoveUserFromPartnerAction.TITLE = 'Remove User From Partner';

export default RemoveUserFromPartnerAction;
