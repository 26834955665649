import {
	IntSetMutationCamel,
	StringMutationCamel,
	BoolMutationCamel
} from 'thriftgen/thriftCamelTypes';

function deriveIntSetMutationFromInitialValue(
	initial: number[],
	updated: number[]
): IntSetMutationCamel {
	const toAdd = updated.filter(value => !initial.includes(value));
	const toRemove = initial.filter(value => !updated.includes(value));
	return {
		toAdd,
		toRemove
	};
}

function transformStringToStringMutation(value?: string): StringMutationCamel | undefined {
	if (!value) {
		return undefined;
	}

	return {
		value
	};
}

function transformBooleanToBoolMutation(value?: boolean): BoolMutationCamel | undefined {
	if (value === undefined) {
		return undefined;
	}

	return {
		value
	};
}

export {
	deriveIntSetMutationFromInitialValue,
	transformBooleanToBoolMutation,
	transformStringToStringMutation
};
