import LastName from './LastName';
import { buildFieldVariation } from '../helpers';
import Strings from 'lib/strings';

const FIELD_NAME = 'lastName';

export default buildFieldVariation({
	Field: LastName,
	fieldName: FIELD_NAME,
	label: Strings.Labels.PATIENT_LAST_NAME
});
