import reducerRegistry from 'store/registry';
import { User } from 'thriftgen/api_types';
import { UsersActions, UsersActionType, UsersState } from './users.types';

const reducerName = 'users';

function usersById(users: User[] = []): UsersState['byId'] {
	const byId = {};
	users.forEach(u => {
		byId[u.user_id] = u;
	});
	return byId;
}

const initialUsersState: UsersState = {
	byId: {}
};

function usersReducer(state = initialUsersState, action: UsersActions): UsersState {
	switch (action.type) {
		case UsersActionType.SET_USER:
			return {
				...state,
				byId: {
					...state.byId,
					...usersById([action.user])
				}
			};

		case UsersActionType.SET_USERS:
			return {
				byId: usersById(action.users)
			};

		default:
			return state;
	}
}

reducerRegistry.register(reducerName, usersReducer);
export { usersReducer };
