import { DataFormFieldComponentProps } from 'components/Forms/DataFormTypes';
import { Specialty } from 'thriftgen/api_types';

const FIELD_NAME = 'specialty';

type SpecialtyFieldProps = DataFormFieldComponentProps<Specialty>;
interface DisplaySpecialtyFieldProps extends Partial<SpecialtyFieldProps> {
	data: SpecialtyFieldProps['data'];
}

export { DisplaySpecialtyFieldProps, FIELD_NAME, SpecialtyFieldProps };
