import { DataFormFieldComponent } from '../DataFormTypes';
import buildImageUpload from './base/buildImageUpload';
import { ConsultDataState } from 'thriftgen/api_types';
import { SpecialistDataCamel } from 'thriftgen/thriftCamelTypes';

const FIELD_NAME = 'profilePicUploadKey';
const LABEL = 'SMA Photo';

type BuildSMAPhotoProps = Pick<SpecialistDataCamel, 'profilePicUrl' | 'profilePicState'>;

function getEmptyMessage(picState?: SpecialistDataCamel['profilePicState']): string {
	switch (picState) {
		case ConsultDataState.PROCESSING:
			return 'A photo has been added but it is still being processed. It will be available shortly.';
		case ConsultDataState.FAILED:
			return 'A photo has been added but it failed to process. Please try again.';
		default:
			return 'No photo has been added yet.';
	}
}

function buildSMAPhoto(props?: BuildSMAPhotoProps): DataFormFieldComponent {
	if (!props) {
		return buildImageUpload({
			currentUrl: undefined,
			label: LABEL,
			fieldName: FIELD_NAME,
			emptyMessage: getEmptyMessage()
		});
	}

	const { profilePicUrl, profilePicState } = props;

	return buildImageUpload({
		currentUrl: profilePicState === ConsultDataState.READY ? profilePicUrl : undefined,
		emptyMessage: getEmptyMessage(profilePicState),
		fieldName: FIELD_NAME,
		label: LABEL
	});
}

export default buildSMAPhoto;
