import * as React from 'react';
import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import { Form, Heading, HeadingSize, HeadingType } from 'display';
interface BuildSectionHeadingFieldProps {
	fieldName: string;
	label?: string;
	headingType?: HeadingType;
	headingSize?: HeadingSize;
	subHeader?: string | JSX.Element;
	headingStyles?: string;
}

function buildSectionHeadingField({
	fieldName,
	label,
	headingType = Heading.Type.H3,
	headingSize = Heading.Size.T4,
	subHeader,
	headingStyles
}: BuildSectionHeadingFieldProps): DataFormFieldComponent {
	function HeadingField() {
		return (
			<Form.Field>
				<Heading as={headingType} size={headingSize} className={headingStyles}>
					{label}
				</Heading>
				{subHeader}
			</Form.Field>
		);
	}

	HeadingField.FIELD_NAME = fieldName;
	HeadingField.displayName = `FieldGroup-${fieldName}`;
	HeadingField.validate = () => null;

	return HeadingField;
}

export default buildSectionHeadingField;
