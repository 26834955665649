import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getAdminClient } from 'lib/ThriftClient';
import { UpdateConsultSummaryRequest } from 'thriftgen/api_types';
import {
	UpdateConsultSummaryRequestCamel,
	UpdateConsultSummaryResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function updateConsultSummary(
	params: UpdateConsultSummaryRequestCamel
): Promise<UpdateConsultSummaryResponseCamel> {
	const client = getAdminClient();

	const response: UpdateConsultSummaryResponse = await client.update_consult_summary(
		objectCamelToSnake(params) as UpdateConsultSummaryRequest
	);

	return objectSnakeToCamel(response);
}

export default updateConsultSummary;
