import * as React from 'react';
import { connect } from 'react-redux';
import { sendCreatePartnerWithToast } from 'store/admin';
import { ThunkActionDispatch } from 'store/types';
import { CreatePartnerResponse } from 'thriftgen/api_types';
import { PartnerMutationsCamel, CreatePartnerRequestCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from '../DataFormTypes';
import {
	buildPartnerLogo,
	EnablePatientCommunications,
	PartnerName,
	PartnerPatientMessage,
	PartnerSettings,
	SpecialtiesField
} from '../Fields';
import { PartnerForm } from './PartnerForm';

interface ConsumerProps {
	onDone(): void;
}

interface DispatchProps {
	sendCreatePartnerWithToast(args: CreatePartnerRequestCamel): Promise<CreatePartnerResponse>;
}

type NewPartnerFormProps = ConsumerProps & DispatchProps;

const FIELDS = [
	buildPartnerLogo(),
	PartnerName,
	SpecialtiesField,
	EnablePatientCommunications,
	PartnerPatientMessage,
	PartnerSettings
].filter(Boolean) as DataFormFieldComponent[];

const REQUIRED_FIELDS = [PartnerName.FIELD_NAME];

function NewPartnerForm(props: NewPartnerFormProps): JSX.Element {
	function onSubmit(formData: PartnerMutationsCamel): Promise<void> {
		return props.sendCreatePartnerWithToast({ mutations: formData }).then(props.onDone);
	}

	return (
		<PartnerForm
			onCancel={props.onDone}
			onSubmit={onSubmit}
			fields={FIELDS}
			requiredFields={REQUIRED_FIELDS}
		/>
	);
}

const mapDispatchToProps = (dispatch: ThunkActionDispatch): DispatchProps => ({
	sendCreatePartnerWithToast: (args: CreatePartnerRequestCamel) =>
		dispatch(sendCreatePartnerWithToast(args))
});

export default connect(null, mapDispatchToProps)(NewPartnerForm);
