import { DataTableColumn } from 'components/DataTable/DataTable';
import { buildHalfWidthMobileColumn } from 'components/DataTable/helpers';
import { ConsultComposite } from 'hooks';
import { getAbbreviatedDate } from 'lib/date';
import * as React from 'react';

const header = 'Response Date';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ responseDate }: ConsultComposite) => <>{getAbbreviatedDate(responseDate)}</>,
	renderMobile: ({ responseDate }: ConsultComposite) =>
		buildHalfWidthMobileColumn(header, getAbbreviatedDate(responseDate)),

	sortKeys: ['response_date']
});
