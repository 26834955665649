import * as React from 'react';
import PropTypes from 'prop-types';
import { getReadableBirthDate, getDaysInMonth } from 'lib/date';

import { Form, Icon } from 'display';
import BirthDay from './BirthDay';
import BirthMonth from './BirthMonth';
import BirthYear from './BirthYear';

const noop = () => {};
const FIELD_NAME = 'dateOfBirth';

function adjustValueForDaysInMonth(value) {
	const daysInMonth = getDaysInMonth(
		getDateField(value, BirthYear.FIELD_NAME),
		getDateField(value, BirthMonth.FIELD_NAME)
	);

	if (getDateField(value, BirthDay.FIELD_NAME) > daysInMonth) {
		value[BirthDay.FIELD_NAME] = daysInMonth;
	}

	return value;
}

function canEdit(editableFields) {
	return editableFields.includes(FIELD_NAME);
}

function getDateField(date, field) {
	if (!date) {
		return 1;
	}

	return date[field];
}

function getValue(data) {
	return data[FIELD_NAME];
}

class DateOfBirth extends React.Component {
	static FIELD_NAME = FIELD_NAME;
	static validate(data, required) {
		const dob = getValue(data);
		let fields = [];

		if (!getDateField(dob, BirthMonth.FIELD_NAME)) {
			fields.push(BirthMonth.FIELD_NAME);
		}

		if (!getDateField(dob, BirthDay.FIELD_NAME)) {
			fields.push(BirthDay.FIELD_NAME);
		}

		if (!getDateField(dob, BirthYear.FIELD_NAME)) {
			fields.push(BirthYear.FIELD_NAME);
		}

		if (required && !dob) {
			return {
				fields: [BirthMonth.FIELD_NAME, BirthDay.FIELD_NAME, BirthYear.FIELD_NAME],
				messages: ['Please enter a valid date of birth.']
			};
		}

		if (fields.length > 0) {
			return {
				fields,
				messages: ['Please enter a valid date of birth.']
			};
		}

		return null;
	}

	constructor(props) {
		super(props);

		this.onFormChange = this.onFormChange.bind(this);
	}

	onFormChange(_, { name, value }) {
		const previous = getValue(this.props.data);

		this.props.onChange(null, {
			name: FIELD_NAME,
			value: adjustValueForDaysInMonth({
				...previous,
				[name]: value
			})
		});
	}

	render() {
		const { data, editableFields, errors } = this.props;
		const editable = canEdit(editableFields);
		const value = getValue(data);
		const date = value || {};

		if (editable) {
			return (
				<Form.Group widths="equal">
					<BirthMonth onChange={this.onFormChange} data={date} errors={errors} />
					<BirthDay onChange={this.onFormChange} data={date} errors={errors} />
					<BirthYear onChange={this.onFormChange} data={date} errors={errors} />
				</Form.Group>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<Icon name="calendar outline" />
					<span>
						{getReadableBirthDate(
							getDateField(value, BirthYear.FIELD_NAME),
							getDateField(value, BirthMonth.FIELD_NAME),
							getDateField(value, BirthDay.FIELD_NAME)
						)}
					</span>
				</Form.Field>
			);
		}

		return null;
	}
}

DateOfBirth.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: [],
		messages: []
	},
	onChange: noop
};

DateOfBirth.propTypes = {
	data: PropTypes.shape({
		[FIELD_NAME]: PropTypes.shape({
			day: PropTypes.number,
			month: PropTypes.number,
			year: PropTypes.number
		})
	}),
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.object,
	onChange: PropTypes.func
};

export default DateOfBirth;
