import { NoButton, YesButton } from 'components/Buttons';
import { UnSharingErrorMessage } from 'components/Messages';
import { useCaseContext, useConversationContext } from 'contexts';
import { Checkbox, DialogModal } from 'display';
import { ConversationMessageComposite } from 'hooks/queries';
import React, { useState } from 'react';

interface UnshareConsultMessageModalProps {
	sharedMessageId: ConversationMessageComposite['messageId'];
}

function UnshareConsultMessageModal({
	sharedMessageId
}: UnshareConsultMessageModalProps): JSX.Element {
	const [isOpen, setIsOpen] = useState<boolean>(false);
	const [apiError, setApiError] = useState<Error | null>(null);
	const openModal = () => setIsOpen(true);
	const closeModal = () => setIsOpen(false);
	const { unshareMessage } = useConversationContext();
	const { theCase } = useCaseContext();

	const trigger = (
		<Checkbox
			toggle={true}
			onChange={openModal}
			checked={true}
			title="Stop sharing with patient"
		/>
	);

	function sendUnshareMessage(): Promise<void> {
		setApiError(null);

		return unshareMessage({
			targetConversationId: theCase.patientConversationId,
			sharedMessageId
		})
			.then(closeModal)
			.catch(setApiError);
	}

	function close(): void {
		setApiError(null);
		closeModal();
	}

	return (
		<React.Fragment>
			<DialogModal open={isOpen} trigger={trigger} data-testid="unshareConsultMessageModal">
				{apiError && (
					<DialogModal.Description>
						<UnSharingErrorMessage message={apiError.message} />
					</DialogModal.Description>
				)}
				<DialogModal.Description>
					Do you want to unshare this message with your patient?
				</DialogModal.Description>
				<DialogModal.Actions>
					<NoButton onClick={close} />
					<YesButton onClickAsync={sendUnshareMessage} />
				</DialogModal.Actions>
			</DialogModal>
		</React.Fragment>
	);
}

export default UnshareConsultMessageModal;
