import * as React from 'react';

import { Button } from 'display';
import { SEMANTIC_COLOR_STRINGS } from 'styles';

function StopIconButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Icon
			backgroundColor={SEMANTIC_COLOR_STRINGS.RED}
			icon="square"
			iconInverted={true}
			onClick={onClick}
			title="Stop"
		/>
	);
}

export default StopIconButton;
