import {
	DataFormConditionalField,
	DataFormConditionalFieldAction
} from 'components/Forms/DataFormTypes';
import {
	SitkaMedicalAssociate,
	SMABoardCertification,
	SMAFellowship,
	SMAFunFacts,
	SMAMedSchool,
	SMAResidency,
	SMASpecialty
} from 'components/Forms/Fields/index';

const SMA_BIOS_CONDITIONAL_FIELDS: DataFormConditionalField[] = [
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: 'profilePicUploadKey',
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	},
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: SMASpecialty.FIELD_NAME,
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	},
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: SMAMedSchool.FIELD_NAME,
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	},
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: SMAResidency.FIELD_NAME,
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	},
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: SMAFellowship.FIELD_NAME,
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	},
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: SMABoardCertification.FIELD_NAME,
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	},
	{
		fieldName: SitkaMedicalAssociate.FIELD_NAME,
		conditionalFieldName: SMAFunFacts.FIELD_NAME,
		action: DataFormConditionalFieldAction.SHOW,
		values: [true]
	}
];

export default SMA_BIOS_CONDITIONAL_FIELDS;
