import * as React from 'react';

function ScreenSelectionCanceled(): JSX.Element {
	return (
		<React.Fragment>
			Allow Chrome to share your screen.
			<br />
			<br />
			Open{' '}
			<strong>
				System preferences &gt; Security &amp; Privacy &gt; Screen Recording
			</strong>{' '}
			and select <strong>Google Chrome</strong> to grant access.
		</React.Fragment>
	);
}

export default ScreenSelectionCanceled;
