import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { User } from 'thriftgen/api_types';
import LinkToEditPageButton from './LinkToEditPageButton';

interface LinkToUserEditButtonProps {
	userId: User['user_id'];
}

function LinkToUserEditButton({ userId }: LinkToUserEditButtonProps): JSX.Element {
	return <LinkToEditPageButton link={RouteUrls.buildUrl(RouteUrls.Key.USER_EDIT, { userId })} />;
}

export default LinkToUserEditButton;
