import { ThunkAction } from 'store/types';

import { setConsults } from 'store/consults/consults.actions';
import { setUser } from 'store/users';
import { ReportEventResponse } from 'thriftgen/api_types';

function handleReportEventResponse(response: ReportEventResponse): ThunkAction<void> {
	return dispatch => {
		if (response.user) {
			dispatch(setUser(response.user));
		}
		if (response.consult) {
			dispatch(setConsults([response.consult]));
		}
	};
}

export { handleReportEventResponse };
