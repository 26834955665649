import Cookies from 'js-cookie';
import jwtDecode from 'jwt-decode';
import { SitkaLogger } from 'lib/sitkaLogger';
import {
	localStorage as LocalStorage,
	StorageEntries,
	StorageKeys,
	StorageValue
} from 'lib/Storage';
import { selectAuthUser, selectLoggedIn } from 'store/auth';
import store from 'store/index';
import { AppState } from 'store/types';
import { sendGetUserWithToast } from 'store/users';
import { cache } from 'swr';
import { User } from 'thriftgen/api_types';

const AUTHTOKEN_COOKIE = 'AUTHTOKEN';

interface AuthToken {
	USER_ID: string;
	EMAIL: string;
	AUTH_SESSION_ID: string;
}

function decodeAuthToken(): AuthToken | undefined {
	const encodedToken = Cookies.get(AUTHTOKEN_COOKIE);

	if (!encodedToken) {
		return undefined;
	}

	return jwtDecode<AuthToken>(encodedToken);
}

function getAuthUserFromCache(): User | null {
	return LocalStorage.get(StorageKeys.USER);
}

function getUserIdFromAuthToken(): AuthToken['USER_ID'] | undefined {
	const token = decodeAuthToken();

	if (!token) {
		return undefined;
	}

	return token.USER_ID;
}

function getAuthSessionIdFromAuthToken(): AuthToken['AUTH_SESSION_ID'] | undefined {
	const token = decodeAuthToken();

	if (!token) {
		return undefined;
	}

	return token.AUTH_SESSION_ID;
}

function getPersistedLocalStorageItems(localStoragePersistRegex: RegExp): StorageEntries {
	const localStoragePersistedItems = {};
	for (let i = 0; i < LocalStorage.length(); i++) {
		const key = LocalStorage.key(i);
		if (key && localStoragePersistRegex.test(key)) {
			try {
				localStoragePersistedItems[key] = LocalStorage.get(key);
			} catch (e) {
				SitkaLogger.logMessage(
					new Error(
						`getPersistedLocalStorageItems - not able to retrieve value for ${key} - ${e.message}`
					)
				);
			}
		}
	}

	return localStoragePersistedItems;
}

function restorePersistedLocalStorageItems(localStoragePersistedItems: StorageValue) {
	Object.entries(localStoragePersistedItems).forEach(([key, value]) => {
		// values stored in secure storage should not get stringified, because they are not able to be retrieved

		LocalStorage.set(key, value);
	});
}

function clearUser(): void {
	Cookies.remove(AUTHTOKEN_COOKIE);

	const localStoragePersistRegex = /draftSMAResponse_(.*)|consult_storage_conversation_(.*)/;
	const localStoragePersistedItems = getPersistedLocalStorageItems(localStoragePersistRegex);

	LocalStorage.clear();
	cache.clear();

	restorePersistedLocalStorageItems(localStoragePersistedItems);
}

function getAuthUser(state: AppState): User | null {
	try {
		return selectAuthUser(state);
	} catch {
		return null;
	}
}

function persistUserToLocalStorage(): void {
	let currentValue: User | null = getAuthUser(store.getState());

	store.subscribe((): void => {
		const state = store.getState();
		const loggedIn = selectLoggedIn(state);

		if (loggedIn) {
			const previousValue = currentValue;
			currentValue = getAuthUser(state);
			if (previousValue !== currentValue) {
				LocalStorage.set(StorageKeys.USER, currentValue);
			}
		}
	});
}

function trackAuthenticationStatus(): void {
	let currentValue = selectLoggedIn(store.getState());

	store.subscribe((): void => {
		const previousValue = currentValue;
		currentValue = selectLoggedIn(store.getState());

		if (previousValue && !currentValue) {
			clearUser();
		}
	});
}

function subscribeToAuthStoreChanges(): void {
	persistUserToLocalStorage();
	trackAuthenticationStatus();
}

function startAuthenticationService(): void {
	subscribeToAuthStoreChanges();

	try {
		const actorId = getUserIdFromAuthToken();

		if (actorId) {
			store.dispatch(sendGetUserWithToast({ userId: actorId }));
		}

		const authSessionId = getAuthSessionIdFromAuthToken();

		if (authSessionId) {
			LocalStorage.set(StorageKeys.AUTH_SESSION_ID, authSessionId);
		}
	} catch (err) {
		SitkaLogger.logMessage({
			error: 'The authentication service encountered an issue fetching the user.',
			params: {
				err
			}
		});
	}
}

export default startAuthenticationService;
export { getUserIdFromAuthToken, getAuthUserFromCache };
