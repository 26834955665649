import * as React from 'react';

function ScreenSelectionCanceled(): JSX.Element {
	return (
		<React.Fragment>
			It seems like you canceled screen capture by not picking a screen. Please try again.
		</React.Fragment>
	);
}

export default ScreenSelectionCanceled;
