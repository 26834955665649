import { isIOS, isMobile, isChrome } from 'lib/userAgent';

// We are temporarily constraining this to just Chrome
// but in theory this extends to other browsers too
// given that we are using the native browser API.
const DOES_BROWSER_SUPPORT_SCREEN_RECORDING = !isMobile() && isChrome();
const SHOULD_USE_FALLBACK_RECORDER = isIOS();

function requiresSetupForScreenRecording(): boolean {
	if (DOES_BROWSER_SUPPORT_SCREEN_RECORDING && 'getDisplayMedia' in navigator.mediaDevices) {
		return false;
	}

	return true;
}

export {
	DOES_BROWSER_SUPPORT_SCREEN_RECORDING,
	requiresSetupForScreenRecording,
	SHOULD_USE_FALLBACK_RECORDER
};
