import { DataTableColumn } from 'components/DataTable/DataTable';
import { User } from 'thriftgen/api_types';
import Strings from 'lib/strings';

export default (): DataTableColumn<User> => ({
	header: Strings.Labels.DAILY_CONSULTS,

	render: ({ specialist_data }: User) => {
		const { consults_assigned_today, daily_consults_limit } = specialist_data;
		const numerator = consults_assigned_today;
		const denominator = daily_consults_limit ? daily_consults_limit : Strings.Labels.UNLIMITED;
		return `${numerator} / ${denominator}`;
	}
});
