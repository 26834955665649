import * as React from 'react';

function BrowserPermissionDenied(): JSX.Element {
	return (
		<React.Fragment>
			It seems you have blocked access to your device&apos;s camera or microphone, or none
			were found at all. Please revise your browser&apos;s settings and try again.
		</React.Fragment>
	);
}

export default BrowserPermissionDenied;
