import * as React from 'react';
import { css, cx } from 'emotion';

interface TruncatedFilenameProps {
	filename: string;
}

const styles = {
	container: css`
		display: block;
		overflow: hidden;
		text-decoration: inherit;
		white-space: nowrap;
	`,
	extension: css`
		max-width: 30%;
		text-decoration: inherit;
	`,
	name: css`
		max-width: 70%;
		text-overflow: ellipsis;
		text-decoration: inherit;
	`,
	part: css`
		display: inline-block;
		overflow: hidden;
		text-decoration: inherit;
		vertical-align: bottom;
		white-space: nowrap;
	`
};

function TruncatedFilename({ filename }: TruncatedFilenameProps): JSX.Element {
	if (typeof filename === 'string' && filename.indexOf('.') > -1) {
		const splitString = filename.split('.');
		const extension = splitString.pop();
		const name = splitString.join('.');

		return (
			<span className={styles.container}>
				<span className={cx(styles.part, styles.name)}>{name}</span>
				<span className={cx(styles.part, styles.extension)}>.{extension}</span>
			</span>
		);
	}

	return (
		<span className={styles.container}>
			<span className={cx(styles.part, styles.name)}>{filename}</span>
		</span>
	);
}

export default TruncatedFilename;
