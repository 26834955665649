import * as React from 'react';
import { AssetListItemMetaProps } from './types';
import { Paragraph } from 'display';

function AssetListItemMeta({ content, label }: AssetListItemMetaProps): JSX.Element {
	return (
		<div key={label}>
			<Paragraph>
				{label} <strong>{content}</strong>
			</Paragraph>
		</div>
	);
}

export default AssetListItemMeta;
