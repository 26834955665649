import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const FIELD_NAME = 'isBillable';
const LABEL = 'Billable';

const BillableElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

function Billable(props: DataFormFieldComponentProps): JSX.Element {
	return <BillableElement key={FIELD_NAME} {...props} />;
}

Billable.FIELD_NAME = FIELD_NAME;
Billable.validate = () => null;

export default Billable;
