import { ShareWithPatientButton } from 'components/Buttons';
import {
	PatientContactInformationForm,
	PatientContactInformationFormProps
} from 'components/Forms/PatientContactInformationForm';
import { SharingErrorMessage } from 'components/Messages';
import { ButtonProps, CardModal, CheckboxProps } from 'display';
import { useLazyAsyncCall } from 'hooks';
import noop from 'lib/noop';
import { hasContactInformation } from 'lib/userHelpers';
import React, { useState } from 'react';
import { User, UserInfo } from 'thriftgen/api_types';

type ModalTrigger = (
	props: Pick<CheckboxProps, 'onClick'> | Pick<ButtonProps, 'onClick'>
) => JSX.Element;
interface PatientContactInformationModalBaseProps
	extends Pick<PatientContactInformationFormProps, 'SubmitControl'> {
	descriptionPostscript?: string;
	linkToPatientEditor: JSX.Element | null;
	onCancel?: () => void;
	onDone: PatientContactInformationFormProps['onDone'];
	patient: User;
	title: string;
}
interface PatientContactInformationModalExternallyOpenedProps
	extends PatientContactInformationModalBaseProps {
	open: boolean;
	onCancel: () => void;
	Trigger?: never;
}
interface PatientContactInformationModalInternallyOpenedProps
	extends PatientContactInformationModalBaseProps {
	open?: never;
	Trigger: ModalTrigger | null;
}
type PatientContactInformationModalProps =
	| PatientContactInformationModalExternallyOpenedProps
	| PatientContactInformationModalInternallyOpenedProps;

const EDITABLE_DESCRIPTION =
	"Please add your patient's birthday and email address below so we can notify them on your behalf.";

const DISPLAY_DESCRIPTION =
	"Please confirm your patient's birthday and email address below so we can notify them on your behalf.";

function getDescription(isFormEditable: boolean): string {
	if (isFormEditable) {
		return EDITABLE_DESCRIPTION;
	}

	return DISPLAY_DESCRIPTION;
}

function PatientContactInformationModal({
	descriptionPostscript = '',
	linkToPatientEditor,
	onCancel = noop,
	onDone,
	open,
	patient,
	SubmitControl = ShareWithPatientButton,
	title,
	Trigger
}: PatientContactInformationModalProps): JSX.Element {
	const [isOpen, setIsOpen] = useState(false);
	const {
		dispatch: dispatchOnDone,
		error,
		reset: resetOnDone
	} = useLazyAsyncCall<Pick<UserInfo, 'email' | 'date_of_birth'> | null>(onDone);
	const isFormEditable = !hasContactInformation(patient);
	const modalOwnsOpenState = !!Trigger && open === undefined;

	React.useEffect(() => {
		if (!modalOwnsOpenState) {
			setIsOpen(!!open);
		}

		resetOnDone();
	}, [modalOwnsOpenState, open]);

	const closeModal = () => setIsOpen(false);
	const openModal = () => setIsOpen(true);
	function cancel(): void {
		onCancel();

		if (modalOwnsOpenState) {
			closeModal();
		}
	}

	function submit(userUpdates: Pick<UserInfo, 'email' | 'date_of_birth'> | null): Promise<void> {
		return dispatchOnDone(userUpdates)
			.promise()
			.then(() => {
				if (modalOwnsOpenState) {
					closeModal();
				}
			});
	}

	return (
		<CardModal
			open={isOpen}
			trigger={Trigger ? <Trigger onClick={openModal} /> : null}
			data-testid="patientContactInformationModal">
			<CardModal.Title>{title}</CardModal.Title>
			<CardModal.Description>
				{getDescription(isFormEditable) + ` ` + descriptionPostscript}
			</CardModal.Description>
			{error && <SharingErrorMessage message={error.message} />}
			<PatientContactInformationForm
				isEditable={isFormEditable}
				patient={patient}
				onCancel={cancel}
				onDone={submit}
				SubmitControl={SubmitControl}>
				{!isFormEditable && linkToPatientEditor}
			</PatientContactInformationForm>
		</CardModal>
	);
}

export { PatientContactInformationModal, PatientContactInformationModalProps };
