import { getAuthUserFromCache, getUserIdFromAuthToken } from 'services/authentication';
import { AuthState } from './auth.types';

const getEmptyAuthState = (): AuthState => ({
	loggedIn: false,
	user: null,
	userId: undefined
});

function getInitialAuthState(): AuthState {
	try {
		const user = getAuthUserFromCache();
		const userId = getUserIdFromAuthToken();

		return {
			loggedIn: !!userId && !!user,
			userId,
			user
		};
	} catch (err) {
		return getEmptyAuthState();
	}
}

export { getInitialAuthState };
