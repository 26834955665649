import { Button, ButtonType } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { CaseCamel } from 'thriftgen/thriftCamelTypes';
import LinkButton, { LinkButtonProps } from './LinkButton';
import Strings from 'lib/strings';

interface LinkToRequestConsultButtonProps {
	buttonType?: ButtonType;
	caseId?: CaseCamel['caseId'];
}

function LinkToRequestConsultButton({
	buttonType = ButtonType.PRIMARY,
	caseId
}: LinkToRequestConsultButtonProps): JSX.Element {
	const url =
		caseId !== undefined
			? RouteUrls.buildUrl(RouteUrls.Key.CASE_REQUEST_CONSULT, { caseId })
			: RouteUrls.buildUrl(RouteUrls.Key.CONSULT_REQUEST);

	const buttonProps: LinkButtonProps = {
		buttonType: buttonType,
		link: url,
		text: Strings.Buttons.NEW_CONSULT,
		icon: 'plus',
		size: Button.Size.TINY,
		classNames: 'requestConsultButton'
	};

	return <LinkButton {...buttonProps} />;
}

export default LinkToRequestConsultButton;
