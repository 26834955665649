import moment from 'moment';
import Strings from './strings';

const ONE_MINUTE_MS = 60000;
const ONE_SECOND_MS = 1000;

moment.updateLocale('en', {
	calendar: {
		lastDay: 'LT - [Yesterday]',
		sameDay: 'LT - [Today]',
		nextDay: 'LT - [Tomorrow]',
		lastWeek: 'LT - ddd',
		nextWeek: ' LT - dddd',
		sameElse: 'll'
	},
	meridiem: hours => {
		if (hours > 11) {
			return 'pm';
		} else {
			return 'am';
		}
	}
});

interface DateSelect {
	key: number;
	text: number;
	value: number;
}

function getDateYears(): DateSelect[] {
	const currentYear = new Date().getFullYear();
	return Array.from(Array(115), (_, index): DateSelect => {
		const year = currentYear - index;
		return {
			key: year,
			text: year,
			value: year
		};
	});
}

function getDateMonths(): DateSelect[] {
	return Array.from(Array(12), (_, index): DateSelect => {
		const month = index + 1;
		return {
			key: month,
			text: month,
			value: month
		};
	});
}

function getDaysInMonth(year: number, month: number): number {
	return moment(`${year}-${month}`, 'YYYY-MM').daysInMonth();
}

function getReadableBirthDate(year: number, month: number, day: number): string {
	return moment(`${year}-${month}-${day}`, 'YYYY-MM-DD').format('LL');
}

function getFullDateTime(
	timestamp: number | null,
	emptyTimestampPlaceholder = Strings.PLACEHOLDERS.NO_VALUE
): string {
	return timestamp ? moment.unix(timestamp).format('LLL') : emptyTimestampPlaceholder;
}

function getAbbreviatedDate(
	timestamp: number | null,
	emptyTimestampPlaceholder = Strings.PLACEHOLDERS.NO_VALUE
): string {
	return timestamp ? moment.unix(timestamp).format('L') : emptyTimestampPlaceholder;
}

function timeSinceTimestamp(
	timestamp?: number,
	emptyTimestampPlaceholder = Strings.PLACEHOLDERS.NO_VALUE
): string {
	return timestamp ? moment.unix(timestamp).fromNow() : emptyTimestampPlaceholder;
}

function yearsSinceDate(year: number, month: number, day: number): number {
	return moment().diff(moment(`${year}-${month}-${day}`, 'YYYY-MM-DD'), 'years');
}

function getLocaleWeekOfDate(timestamp: number): string {
	return `Week of ${moment(timestamp).format('LL')}`;
}

function millisecondsToMinutesAndSeconds(milliseconds: number): string {
	const minutes = Math.floor(milliseconds / ONE_MINUTE_MS);
	const seconds = (milliseconds % ONE_MINUTE_MS) / ONE_SECOND_MS;
	const secondsString = seconds.toFixed(0);

	return `${minutes}:${seconds < 10 ? '0' : ''}${secondsString}`;
}

function convertTimestampFromStartToEndOfDay(timestamp: number): number {
	return moment.unix(timestamp).add(1, 'day').subtract(1, 'second').unix();
}

function convertTimestampFromEndToStartOfDay(timestamp: number): number {
	return moment.unix(timestamp).subtract(1, 'day').add(1, 'second').unix();
}

function setTimestampToEndOfDay(timestamp: number): number {
	return moment.unix(timestamp).hour(23).minute(59).second(59).unix();
}

export {
	getAbbreviatedDate,
	getDateMonths,
	getDateYears,
	getDaysInMonth,
	getFullDateTime,
	getLocaleWeekOfDate,
	getReadableBirthDate,
	millisecondsToMinutesAndSeconds,
	timeSinceTimestamp,
	yearsSinceDate,
	convertTimestampFromStartToEndOfDay,
	convertTimestampFromEndToStartOfDay,
	setTimestampToEndOfDay
};
