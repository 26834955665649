import { getTranslatedUserSpecialties } from 'lib/userHelpers';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { User } from 'thriftgen/api_types';

export default (): DataTableColumn<User> => ({
	header: 'Specialties',
	render: (user: User) => {
		if (user.specialties && user.specialties.length > 0) {
			return getTranslatedUserSpecialties(user);
		}

		return 'None';
	}
});
