import { LinkToPartnerDetailButton } from 'components/Buttons';
import { DataTableColumn } from 'components/DataTable/DataTable';
import * as React from 'react';
import { Partner } from 'thriftgen/api_types';

const buildLinkToDetail = (): DataTableColumn<Partner> => ({
	render: ({ partner_id: partnerId }: Partner) => (
		<LinkToPartnerDetailButton
			partnerId={partnerId}
			data-testid={`linkToDetailColumn-${partnerId}`}
		/>
	)
});

export { buildLinkToDetail };
