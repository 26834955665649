import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getAdminClient } from 'lib/ThriftClient';
import { GetConsultSummariesRequest } from 'thriftgen/api_types';
import {
	GetConsultSummariesRequestCamel,
	GetConsultSummariesResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function getConsultSummaries(
	params: GetConsultSummariesRequestCamel
): Promise<GetConsultSummariesResponseCamel> {
	const client = getAdminClient();

	const response: GetConsultSummariesResponse = await client.get_consult_summaries(
		objectCamelToSnake(params) as GetConsultSummariesRequest
	);

	return objectSnakeToCamel(response);
}

export default getConsultSummaries;
