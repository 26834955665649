const BASE_FONT_SIZE = 16;

const FONT_SIZE_SCALE = [12, 14, 16, 18, 24, 32, 48];

export const fontSize = FONT_SIZE_SCALE.map(n => `${n / BASE_FONT_SIZE}rem`);

export enum FontWeight {
	BOLD = 'bold',
	REGULAR = 'regular'
}

export const fontWeight = {
	regular: 400,
	bold: 600
};
