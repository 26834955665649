type ApiResponse = any;

interface ApiMethod extends BaseApiMethod {
	onSuccess?: (args: OnSuccess) => string;
}

type BaseApiMethod = (...args: unknown[]) => ApiResponse;

interface OnSuccess<T = unknown> {
	request: T;
	response: ApiResponse;
}

enum PartnerAssociation {
	ADMIN,
	MEMBER
}

export { ApiMethod, ApiResponse, OnSuccess, PartnerAssociation };
