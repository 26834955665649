import { createSelector } from 'reselect';
import { AppState, SelectorConstraints } from 'store/types';
import { User } from 'thriftgen/api_types';
import { UsersState } from './users.types';

// Individual stores are created lazily for code-splitting
// so we're explicit about the type here since the compiler
// cannot infer when a store is loaded dynamically.
const selectUsers = (state: AppState): UsersState => state.users as UsersState;

const selectUsersById = createSelector(selectUsers, users => users.byId);

const selectUsersByIdAsArray = createSelector(selectUsersById, byId => Object.values(byId));

type SelectUserByIdConstraints = SelectorConstraints<{
	id: User['user_id'];
}>;

type SelectUsersByIdListConstraints = SelectorConstraints<{
	idList: Array<User['user_id']>;
}>;

const selectUserById = (state: AppState, { id }: SelectUserByIdConstraints): User => {
	return selectUsersById(state)[id];
};

function selectUsersByIdList(
	store: AppState,
	{ idList = [] }: SelectUsersByIdListConstraints
): User[] {
	const allLoadedUsers = selectUsersById(store);

	return idList
		.map(userId => {
			return allLoadedUsers[userId];
		})
		.filter(user => user !== undefined);
}

export { selectUserById, selectUsersById, selectUsersByIdAsArray, selectUsersByIdList };
