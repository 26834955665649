import { getConsults, manageConsult as manageConsultRequest } from 'api/common';
import { ManageConsultRequestAction } from 'api/common/manageConsult';
import { ConsultsContextMutators } from 'contexts';
import { infrequentRefresh } from 'hooks/queries/configs';
import { responseInterface } from 'swr';
import { GetConsultsResponse, PaginationMarkers } from 'thriftgen/api_types';
import { GetConsultsRequestCamel } from 'thriftgen/thriftCamelTypes';
import useQuery from '../useQuery';

type ConsultsQueryResult = GetConsultsResponse;

type UseConsultsQuery = {
	data: ConsultsQueryResult | undefined;
	error: responseInterface<GetConsultsResponse, Error>['error'];
	pageMarkers: PaginationMarkers | undefined;
} & ConsultsContextMutators;

function useConsultsQuery(args: GetConsultsRequestCamel): UseConsultsQuery {
	const key = ['consults', args];
	const { data, error, mutate } = useQuery<ConsultsQueryResult>(
		key,
		(_: string, params: GetConsultsRequestCamel) => getConsults(params),
		infrequentRefresh
	);

	// Because manageConsult only mutates and returns one consult, that does not match the data
	// that this query returns because this query returns an array of consults.  Instead of immediately
	// hydrating the query data, a full api refresh has to be made to update the query's data
	async function manageConsult(manageConsultArgs: ManageConsultRequestAction): Promise<void> {
		await manageConsultRequest(manageConsultArgs);
		await mutate();
	}

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		data: data,
		pageMarkers: data ? data.page_markers : undefined,
		error,
		manageConsult,
		revalidate
	};
}

export { UseConsultsQuery, useConsultsQuery };
