import { LinkToLogoutButton } from 'components/Buttons';
import { PoweredByLogo } from 'components/Logos';
import { Button } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import { colors } from 'styles';

const styles = {
	container: css`
		align-items: center;
		background-color: ${colors.primaryAlt[50]};
		display: flex;
		justify-content: space-between;
		padding: 0.5em;
	`
};

function WhitelabelFooter(): JSX.Element {
	return (
		<footer>
			<div className={styles.container}>
				<Button.InlineGroup justifyContent="left">
					<LinkToLogoutButton size={Button.Size.TINY} responsive={false} />
				</Button.InlineGroup>
				<PoweredByLogo />
			</div>
		</footer>
	);
}

export default WhitelabelFooter;
