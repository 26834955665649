import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

function SubmitRequestButton({
	disabled = false,
	onClickAsync
}: Pick<ButtonProps, 'disabled' | 'onClickAsync'>): JSX.Element {
	return (
		<Button.Primary
			disabled={disabled}
			onClickAsync={onClickAsync}
			className="submitRequestButton"
			size={Button.Size.TINY}>
			{Strings.Buttons.SUBMIT_REQUEST}
		</Button.Primary>
	);
}

export default SubmitRequestButton;
