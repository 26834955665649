import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

function SaveButton({ onClickAsync }: Pick<ButtonProps, 'onClickAsync'>): JSX.Element {
	return <Button.Primary onClickAsync={onClickAsync}>{Strings.Buttons.SAVE}</Button.Primary>;
}

export default SaveButton;
