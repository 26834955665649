import { UserLoadError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';
import { Loader } from 'display';
import * as React from 'react';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { SendGetUserAction } from 'store/actionTypes';
import { sendGetUser } from 'store/users';
import { GetUserResponse, User } from 'thriftgen/api_types';

interface RequestUserProps {
	children: JSX.Element;
	userId: User['user_id'];
	sendGetUser: (args: SendGetUserAction) => Promise<GetUserResponse>;
}

function RequestPartner({
	children,
	userId,
	request,
	...props
}: RequestUserProps & WithApiCallProps): JSX.Element {
	React.useEffect(() => {
		props.sendGetUser({ userId });
	}, [userId]);

	const { status, message } = request.data;

	switch (status) {
		case FAILURE:
			return <UserLoadError message={message} />;

		case SUCCESS:
			return children;

		case INACTIVE:
		case IN_PROGRESS:
		default:
			return <Loader active={true} />;
	}
}

export default withApiCall({ sendGetUser }, RequestPartner);
