enum SitkaEvent {
	RECORDING = 'sitka_recording',
	UPLOADING = 'sitka_uploading'
}

function dispatchEvent(eventType: SitkaEvent): void {
	const event = new CustomEvent(eventType);
	window.dispatchEvent(event);
}

export { SitkaEvent, dispatchEvent };
