import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import LinkToEditPageButton from './LinkToEditPageButton';

function LinkToMyAccountEditButton(): JSX.Element {
	return (
		<LinkToEditPageButton
			link={RouteUrls.buildUrl(RouteUrls.Key.MY_ACCOUNT_EDIT)}
			responsive={true}
		/>
	);
}

export default LinkToMyAccountEditButton;
