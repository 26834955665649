import * as React from 'react';
import PropTypes from 'prop-types';
import { css, cx } from 'emotion';
import noop from 'lib/noop';
import { mediaQueries } from 'styles/';

const styles = {
	thumbnail: css`
		border-radius: 4px;
		margin-bottom: 0.5em;
		transition: all 150ms ease-in;

		// Makes every thumbnail have the same size and
		// aspect ratio
		&,
		& * {
			height: 75px;
			object-fit: cover;
			overflow: hidden;
			width: 75px;
		}

		${mediaQueries.desktop} {
			margin-bottom: 1em;
		}
	`,
	thumbnailList: css`
		display: flex;
		flex-direction: row;
		flex-wrap: wrap;

		& > :not(:last-child) {
			margin-right: 0.5em;

			${mediaQueries.desktop} {
				margin-right: 1em;
			}
		}

		margin-right: -0.5em;
		margin-bottom: -0.5em;

		${mediaQueries.desktop} {
			margin-right: -1em;
			margin-bottom: -1em;
		}
	`,
	selectable: css`
		cursor: pointer;
	`,
	selected: css`
		background-color: #000000;
		position: relative;
		& * {
			opacity: 0.2;
		}
	`
};

function getClassName({ className, selectable, selected }) {
	const classes = [styles.thumbnail];

	if (className) {
		classes.push(className);
	}

	if (selectable) {
		classes.push(styles.selectable);
	}

	if (selected) {
		classes.push(styles.selected);
	}

	return classes;
}

function ThumbnailList({ children }) {
	return <div className={styles.thumbnailList}>{children}</div>;
}

ThumbnailList.propTypes = {
	children: PropTypes.node.isRequired
};

function Thumbnail({ children, className, onClick, role, selected, selectable }) {
	const classNames = getClassName({ className, selected, selectable });
	return (
		<div className={cx(...classNames)} onClick={onClick} role={role}>
			{children}
		</div>
	);
}

Thumbnail.List = ThumbnailList;

Thumbnail.defaultProps = {
	className: undefined,
	onClick: noop,
	role: undefined,
	selectable: false,
	selected: false
};

Thumbnail.propTypes = {
	children: PropTypes.node.isRequired,
	className: PropTypes.string,
	onClick: PropTypes.func,
	role: PropTypes.string,
	selectable: PropTypes.bool,
	selected: PropTypes.bool
};

export default Thumbnail;
