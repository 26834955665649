import { DataTableColumn } from 'components/DataTable/DataTable';
import { getAbbreviatedDate } from 'lib/date';
import Strings from 'lib/strings';
import { TimeframeRestriction } from 'thriftgen/api_types';

const buildEndDate = (): DataTableColumn<TimeframeRestriction> => ({
	header: Strings.Labels.END_DATE,
	render: ({ end_date: endDate }: TimeframeRestriction): string => getAbbreviatedDate(endDate)
});

export { buildEndDate };
