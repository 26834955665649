import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import buildTextFieldComponent from '../base/buildTextField';

const FIELD_NAME = 'patientExternalId';
const FIELD_LABEL = 'Patient ID';
const ERROR_MESSAGE = 'Please enter a patient id.';

function buildPatientIdValidator(userPatientIdFieldName: string) {
	return function (data: DataFormFieldComponentProps, required: boolean) {
		const userPatientId: string = data[userPatientIdFieldName];
		if (required && !userPatientId) {
			return {
				fields: [userPatientIdFieldName],
				messages: [ERROR_MESSAGE]
			};
		}

		return null;
	};
}

const UserPatientId: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	icon: 'id card',
	label: FIELD_LABEL
});

UserPatientId.validate = buildPatientIdValidator(UserPatientId.FIELD_NAME);

export default UserPatientId;
