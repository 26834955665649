import {
	Allergy,
	Cardiology,
	Dermatology,
	Endocrinology,
	Gastroenterology,
	Generic,
	HematologyOncology,
	InfectiousDiseases,
	Nephrology,
	Neurology,
	ObstetricsGynecology,
	Ophthalmology,
	Orthopedics,
	Otolaryngology,
	PainMedicine,
	Podiatry,
	Psychiatry,
	Pulmonology,
	Rheumatology,
	SpecialtyIllustrationProps,
	Urology,
	WoundVascular
} from 'components/Svgs';
import * as React from 'react';
import { colors } from 'styles';
import { Specialty } from 'thriftgen/api_types';

interface SpecialtyImages {
	selected: JSX.Element;
	unselected: JSX.Element;
}

type Images = {
	[key in Specialty]?: React.ComponentType<SpecialtyIllustrationProps>;
};

const SPECIALTY_IMAGES: Images = {
	[Specialty.ALLERGY]: Allergy,
	[Specialty.CARDIOLOGY]: Cardiology,
	[Specialty.DERMATOLOGY]: Dermatology,
	[Specialty.ENDOCRINOLOGY]: Endocrinology,
	[Specialty.GASTROENTEROLOGY]: Gastroenterology,
	[Specialty.HEMATOLOGY_ONCOLOGY]: HematologyOncology,
	[Specialty.INFECTIOUS_DISEASES]: InfectiousDiseases,
	[Specialty.NEPHROLOGY]: Nephrology,
	[Specialty.NEUROLOGY]: Neurology,
	[Specialty.OBSTETRICS_GYNECOLOGY]: ObstetricsGynecology,
	[Specialty.OPHTHALMOLOGY]: Ophthalmology,
	[Specialty.ORTHOPEDICS]: Orthopedics,
	[Specialty.OTOLARYNGOLOGY]: Otolaryngology,
	[Specialty.PAIN_MEDICINE]: PainMedicine,
	[Specialty.PODIATRY]: Podiatry,
	[Specialty.PSYCHIATRY]: Psychiatry,
	[Specialty.PULMONOLOGY]: Pulmonology,
	[Specialty.RHEUMATOLOGY]: Rheumatology,
	[Specialty.UROLOGY]: Urology,
	[Specialty.WOUND_VASCULAR]: WoundVascular
};

export function getSpecialtyImage(specialty: Specialty): SpecialtyImages {
	const Specialty = SPECIALTY_IMAGES[specialty] || Generic;

	return {
		selected: (
			<Specialty
				background={colors.primary['300']}
				fillLight={colors.primary['25']}
				fillDark={colors.primary['300']}
				outline={colors.primary['800']}
			/>
		),
		unselected: (
			<Specialty
				background={colors.primary['50']}
				fillLight={colors.primary['25']}
				fillDark={colors.primary['300']}
				outline={colors.primary['500']}
			/>
		)
	};
}
