import * as React from 'react';

import { Button, ButtonProps } from 'display';

function SubmitButton({
	disabled = false,
	onClickAsync
}: Pick<ButtonProps, 'disabled' | 'onClickAsync'>): JSX.Element {
	return (
		<Button.Primary disabled={disabled} onClickAsync={onClickAsync} className="submitButton">
			Submit Written + Clinical
		</Button.Primary>
	);
}

export default SubmitButton;
