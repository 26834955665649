import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { Icon } from './semantic';
import { colors, fontWeight, modularScale } from 'styles/';

const styles = {
	preview: css`
		background-color: ${colors.grey};
		color: ${colors.white};
		text-align: center;

		& .icon {
			margin: 0.5em 0 !important;
		}

		& p {
			font-size: ${modularScale(1)};
			font-weight: ${fontWeight.bold};
			text-overflow: ellipsis;
			white-space: nowrap;
			margin: 0 0.25em;
		}
	`
};

function getLabel(name) {
	const hasExtension = name.indexOf('.') > -1;
	const parts = name.split('.');
	const extension = parts[parts.length - 1];

	return hasExtension ? extension : name;
}

function FilePreview({ name }) {
	return (
		<div className={styles.preview}>
			<Icon name="file outline" size="large" inverted />
			<p>{getLabel(name)}</p>
		</div>
	);
}

FilePreview.propTypes = {
	name: PropTypes.string.isRequired
};

export default FilePreview;
