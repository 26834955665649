import { ConsultDataCamel, SpecialistResponseCamel } from 'thriftgen/thriftCamelTypes';
import { compareDescending } from './sort';
import {
	SUPPORTED_FILE_EXTENSIONS,
	ConsultData,
	Consult,
	ConsultDataType,
	ConsultDataState
} from 'thriftgen/api_types';
import { objectSnakeToCamel } from './objectConverter';

const ALLOWED_DOCUMENT_UPLOAD_EXTENSIONS = SUPPORTED_FILE_EXTENSIONS.map(
	extension => `.${extension}`
).join(',');

const SUPPORTED_FILE_EXTENSIONS_STRING = SUPPORTED_FILE_EXTENSIONS.join(', ');

function categorizeConsultDataByNew(consultDataList: ConsultData[]): {
	oldData: ConsultData[];
	newData: ConsultData[];
} {
	const newData: ConsultData[] = [];
	const oldData: ConsultData[] = [];

	consultDataList.forEach(datum => {
		if (isConsultDataNew(datum)) {
			newData.push(datum);
		} else {
			oldData.push(datum);
		}
	});

	return {
		oldData,
		newData
	};
}

function getConsultDataAuthors(consultDataList: ConsultData[]): Array<ConsultData['author_id']> {
	return consultDataList.map(({ author_id }: ConsultData): ConsultData['author_id'] => author_id);
}

function orderConsultDataByMostRecent(a: ConsultData, b: ConsultData): number {
	return compareDescending(a.created_at, b.created_at);
}

function getConsultDataMessages(consult: Consult): ConsultData[] {
	return consult.consult_data
		.filter((consultData: ConsultData): boolean => consultData.is_message)
		.filter(isNotSpecialistResponse)
		.sort(orderConsultDataByMostRecent);
}

function getConsultClinicalData(consult: Consult): SpecialistResponseCamel | undefined {
	const specialistResponseConsultData = consult.consult_data
		.filter(isSpecialistResponse)
		.sort(orderConsultDataByMostRecent)[0];

	if (specialistResponseConsultData) {
		return objectSnakeToCamel(
			specialistResponseConsultData.specialist_response
		) as SpecialistResponseCamel;
	} else {
		return;
	}
}

function getConsultDataAssets(consultData: ConsultData[]): ConsultData[] {
	return consultData.filter(isNotMessage).filter(isNotSpecialistResponse);
}

function getImagesAndVideos(consultData: ConsultData[]): ConsultData[] {
	return consultData.filter(isImageOrVideo).filter(isNotMessage).filter(isNotSpecialistResponse);
}

function getOtherFiles(consultData: ConsultData[]): ConsultData[] {
	return consultData
		.filter(isNotImageOrVideo)
		.filter(isNotMessage)
		.filter(isNotSpecialistResponse);
}

function getReviewQuery(consultData: ConsultData): string | undefined {
	const { consult_data_id: consultDataId } = consultData;

	if (isViewable(consultData)) {
		return `?id=${consultDataId}`;
	}

	return undefined;
}

function getConsultDataThumbnail(consultData: ConsultData): string | undefined {
	switch (consultData.data_type) {
		case ConsultDataType.IMAGE:
		case ConsultDataType.VIDEO:
			return consultData.thumbnail_url;
	}
	return undefined;
}

function isConsultDataReady(consultData: ConsultData): boolean {
	const { presigned_url: presignedUrl, state } = consultData;
	return presignedUrl !== null && state === ConsultDataState.READY;
}

function isConsultDataNew(consultData: ConsultData): boolean {
	return consultData.is_new;
}

function isConsultDataProcessing({ state }: ConsultData): boolean {
	return state === ConsultDataState.PROCESSING;
}

function isConsultDataShared({ shared_with_patient }: ConsultData): boolean {
	return shared_with_patient;
}

function isImage({ data_type: dataType }: ConsultData): boolean {
	return dataType === ConsultDataType.IMAGE;
}

function isDocument({ data_type: dataType }: ConsultData): boolean {
	return dataType === ConsultDataType.DOCUMENT;
}

function isVideo({ data_type: dataType }: ConsultData): boolean {
	return dataType === ConsultDataType.VIDEO;
}

function isImageOrVideo(consultData: ConsultData): boolean {
	return isImage(consultData) || isVideo(consultData);
}

function isNotImageOrVideo(consultData: ConsultData): boolean {
	return !isImageOrVideo(consultData);
}

function isNotSpecialistResponse({ data_type: dataType }: ConsultData): boolean {
	return dataType !== ConsultDataType.SPECIALIST_RESPONSE;
}

function isSpecialistResponse({ data_type: dataType }: ConsultData): boolean {
	return dataType === ConsultDataType.SPECIALIST_RESPONSE;
}

function isNotMessage({ is_message: isMessage }: ConsultData): boolean {
	return !isMessage;
}

function isNewTextMessage({ dataType, isMessage, isNew }: ConsultDataCamel): boolean {
	return isMessage && dataType === ConsultDataType.TEXT && isNew;
}

function isViewable(consultData: ConsultData): boolean {
	return isDocument(consultData) || isImageOrVideo(consultData);
}

export {
	ALLOWED_DOCUMENT_UPLOAD_EXTENSIONS,
	SUPPORTED_FILE_EXTENSIONS_STRING,
	categorizeConsultDataByNew,
	getConsultDataAuthors,
	getConsultDataAssets,
	getConsultDataMessages,
	getConsultClinicalData,
	getConsultDataThumbnail,
	getImagesAndVideos,
	getOtherFiles,
	getReviewQuery,
	isConsultDataNew,
	isConsultDataProcessing,
	isConsultDataReady,
	isConsultDataShared,
	isNewTextMessage,
	isVideo,
	isViewable
};
