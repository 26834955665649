import * as React from 'react';
import PropTypes from 'prop-types';
import { Container, Heading } from 'display';
import { css } from 'emotion';
import { SitkaLogger } from 'lib/sitkaLogger';

const style = {
	container: css`
		padding: 3em 0;
	`
};

class AirbrakeErrorBoundary extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasError: false };
	}

	componentDidCatch(error, info) {
		// Display fallback UI
		this.setState({ hasError: true });
		// Send error to Airbrake
		SitkaLogger.logMessage({
			error,
			params: { info }
		});
	}

	renderErrorText() {
		return (
			<p>
				{' '}
				Well, this is embarrassing. There was an error that we didn&apos;t handle properly.
				A notification has been sent to the Sitka engineering team, so we&apos;ll get it
				fixed ASAP. In the meantime, try{' '}
				<a href={window.location.href}>reloading the page</a> and see if it works the next
				time around.
			</p>
		);
	}

	render() {
		if (this.state.hasError) {
			return (
				<Container className={style.container}>
					<Heading as="h1" size="t2">
						Something went wrong!
					</Heading>
					{this.renderErrorText()}
					<p>
						If you need help, please let us know at{' '}
						<a href="mailto:support@trustsitka.com">support@trustsitka.com</a>.
					</p>
					<p>Our sincere apologies,</p>
					<p>--The Sitka Engineering Team.</p>
				</Container>
			);
		}
		return this.props.children;
	}
}

AirbrakeErrorBoundary.propTypes = {
	children: PropTypes.node
};

export default AirbrakeErrorBoundary;
