import * as React from 'react';
import { css, cx } from 'emotion';
import { isIOS } from 'lib/userAgent';

import { colors, hexToRgba, zIndexLayer } from 'styles';

export enum ModalWidth {
	NORMAL = 'normal',
	WIDE = 'wide',
	FULL = 'full'
}
interface ModalProps {
	children: React.ReactNode;
	classNameBase?: string;
	classNameActive?: string;
	'data-testid'?: string;
	open: boolean;
	trigger?: React.ReactNode;
	stylingType?: ModalType;
	width?: ModalWidth;
}

export enum ModalType {
	MINIMAL = 'minimal'
}

export type ModalVariationProps = Omit<ModalProps, 'classNameBase' | 'classNameActive'>;

const styles = {
	overlay: {
		base: css`
			left: 0;
			opacity: 0;
			pointer-events: none;
			position: fixed;
			transition-duration: 0.25s;
			z-index: ${zIndexLayer.MAX};
		`,
		active: css`
			opacity: 1;
			pointer-events: auto;
			label: modal-active;
		`
	},
	dimmer: {
		base: css`
			background-color: ${hexToRgba(colors.neutral.base, 0.96)};
			height: 100%;
			top: 0;
			transition-property: opacity;
			width: 100%;
			will-change: opacity;
		`
	},
	modal: {
		base: css`
			background-color: ${colors.neutral['25']};
			margin: 0 auto;
			overflow: hidden;
			padding: 1rem 1rem 3rem;
			right: 0;
			transition-property: transform, opacity, -webkit-transform;
			transform: scale(0.5);
			will-change: transform, opacity;
		`,
		active: css`
			overflow: auto;
			max-height: 90vh;
			-webkit-overflow-scrolling: touch;
		`,
		minimal: css`
			background-color: ${colors.white};
			margin: 0 auto;
			overflow: hidden;
			right: 0;
			transition-property: transform, opacity, -webkit-transform;
			transform: scale(0.5);
			will-change: transform, opacity;

			.content {
				height: 80%;
			}

			.header {
				border-bottom: 1px solid ${colors.neutral['300']};

				.ui.button.icon {
					position: absolute;
					left: 0.5rem;
					width: 2rem;
					height: 2rem;
					top: 0.5rem;
				}

				.ui.button.small {
					width: 5rem;
					position: absolute;
					right: 0.5rem;
					top: 0.5rem;
				}

				h2 {
					text-align: center;
					margin-top: 1rem;
				}
			}
		`,
		fieldsetDisabler: css`
			border: 0;
			margin: 0;
			padding: 0;
		`
	}
};

const IS_IOS = isIOS();
const setAriaHidden = (shown: boolean): React.AriaAttributes['aria-hidden'] =>
	shown ? 'false' : 'true';

function setBodyScroll(freeze: boolean): void {
	const body = document.querySelector('body');

	if (body) {
		// Locks the underlying page scrolling
		body.style.overflow = freeze ? 'hidden' : '';

		if (IS_IOS) {
			body.style.maxHeight = freeze ? '100vh' : '';
			body.style.position = freeze ? 'fixed' : '';
			body.style.width = freeze ? '100%' : '';
		}
	}
}

const unsetBodyScroll = (): void => setBodyScroll(false);

function Dimmer({ active = false }: any): JSX.Element {
	const classNames = [styles.overlay.base, styles.dimmer.base];

	if (active) {
		classNames.push(styles.overlay.active);
	}

	return <div aria-hidden={setAriaHidden(active)} className={cx(...classNames)} />;
}

function BaseModal({
	children,
	classNameActive,
	classNameBase,
	open = false,
	trigger = null,
	...props
}: ModalProps): JSX.Element {
	const classNames =
		props.stylingType === ModalType.MINIMAL
			? [styles.overlay.base, styles.modal.minimal, classNameBase]
			: [styles.overlay.base, styles.modal.base, classNameBase];

	if (open) {
		classNames.push(styles.overlay.active, styles.modal.active, classNameActive);
	}

	React.useLayoutEffect((): (() => void) => {
		setBodyScroll(open);
		return unsetBodyScroll;
	}, [open]);

	return (
		<React.Fragment>
			<Dimmer active={open} />
			<div
				className={cx(...classNames)}
				aria-hidden={setAriaHidden(open)}
				data-testid={props['data-testid']}>
				<fieldset className={styles.modal.fieldsetDisabler} disabled={!open}>
					{children}
				</fieldset>
			</div>
			{trigger}
		</React.Fragment>
	);
}

export default BaseModal;
