import { LinkToEditPatientDetailsButton } from 'components/Buttons';
import { useCaseContext, useConsultContext, useConversationContext } from 'contexts';
import { Checkbox, CheckboxProps } from 'display';
import { ConversationMessageComposite } from 'hooks';
import { getUserById } from 'lib/userHelpers';
import * as React from 'react';
import { User, UserInfo } from 'thriftgen/api_types';
import { PatientContactInformationModal } from '../PatientContactInformationModal';
import Strings from 'lib/strings';

interface ShareConsultMessageModalProps {
	messageId: ConversationMessageComposite['messageId'];
}

const MODAL_TITLE = 'Share this message';

function ModalTrigger({ onClick }: Pick<CheckboxProps, 'onClick'>): JSX.Element {
	return (
		<Checkbox
			toggle={true}
			checked={false}
			onChange={onClick}
			title={Strings.Buttons.SHARE_WITH_PATIENT}
		/>
	);
}

function ShareConsultMessageModal({ messageId }: ShareConsultMessageModalProps): JSX.Element {
	const { shareMessage } = useConversationContext();
	const { consult, updatePatient, users } = useConsultContext();
	const { theCase } = useCaseContext();
	const { consult_id: consultId } = consult;
	const patient = getUserById(users, consult.patient_id) as User;

	async function sendShareMessage(
		userUpdates: Pick<UserInfo, 'email' | 'date_of_birth'> | null
	): Promise<void> {
		if (userUpdates) {
			await updatePatient(userUpdates);
		}

		return shareMessage({
			targetConversationId: theCase.patientConversationId,
			sharedMessageId: messageId
		});
	}

	return (
		<PatientContactInformationModal
			linkToPatientEditor={<LinkToEditPatientDetailsButton consultId={consultId} />}
			onDone={sendShareMessage}
			patient={patient}
			title={MODAL_TITLE}
			Trigger={ModalTrigger}
		/>
	);
}

export default ShareConsultMessageModal;
