import { DataTableColumn } from 'components/DataTable/DataTable';
import { ConsultComposite } from 'hooks';
import Strings from 'lib/strings';
import * as React from 'react';

const header = 'Score';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ clinicalReview }) => {
		let displayValue = '';
		if (clinicalReview) {
			const { score, questions } = clinicalReview;
			displayValue = `${score}/${questions.length}`;
		} else {
			displayValue = Strings.PLACEHOLDERS.NO_VALUE;
		}
		return <div>{displayValue}</div>;
	}
});
