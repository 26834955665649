import * as React from 'react';
import { css } from 'emotion';

import BaseModal, { ModalVariationProps } from './BaseModal';

const styles = {
	active: css`
		transform: translate3d(0, 0, 0);
	`,
	base: css`
		opacity: 1;
		height: 100vh;
		max-height: 100vh !important;
		top: 0;
		transform: translate3d(110%, 0, 0);
		transition-duration: 0.5s;
		width: 100%;
	`
};

function FullScreenModal(props: ModalVariationProps): JSX.Element {
	return <BaseModal classNameActive={styles.active} classNameBase={styles.base} {...props} />;
}

export default FullScreenModal;
