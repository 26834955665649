import * as React from 'react';

import { SUPPORT_EMAIL } from 'lib/supportContacts';
import { Message } from 'display';

function VideoNoAudioError(): JSX.Element {
	return (
		<Message negative={true}>
			The video could not be saved because it is has no audio. Please check your device
			settings and microphone source. If you continue to experience this issue, email{' '}
			<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a>.
		</Message>
	);
}

export { VideoNoAudioError };
