import * as React from 'react';
import { css, cx } from 'emotion';
import { buildInputRegex } from 'lib/regex';

import { mediaQueries, shadows } from 'styles';
import { Form, Search, SearchProps } from 'display';
import SelectItem from './SelectItem';
import { SelectGroupItem, SelectGroupItemGroup } from './types';

const styles = {
	container: css`
		padding: 1em 0.25em 0;
	`,
	label: css`
		padding: 0.5em;

		${mediaQueries.desktop} {
			padding: 0.5em 1em;
		}
	`,
	message: css`
		margin: 2em 0 0;
	`,
	search: css`
		box-shadow: ${shadows.l1};
	`,
	scrollContainer: css`
		max-height: 45vh;
		overflow-y: scroll;
		position: relative;
	`,
	wrapper: css`
		display: flex;
		flex-direction: column;
	`
};

interface SelectGroupProps {
	'data-testid'?: string;
	groups: SelectGroupItemGroup[];
	noResultsFallback: SelectGroupItem;
	noResultsMessage: React.ReactNode;
	onSelected: (selectedValue: number) => void;
	search?: boolean;
	selected: number;
}

interface SelectGroupState {
	query: string;
}

type ComponentProps = SelectGroupProps;

function getTotalItemsLength(groups: SelectGroupItemGroup[]): number {
	return groups.map(group => group.items.length).reduce((a, b) => a + b);
}

class SelectGroup extends React.Component<ComponentProps, SelectGroupState> {
	public static defaultProps = {
		noResultsFallback: null,
		noResultsMessage: null,
		search: false,
		selected: -1
	};

	constructor(props: ComponentProps) {
		super(props);

		this.state = {
			query: ''
		};

		this.filterItems = this.filterItems.bind(this);
		this.renderGroup = this.renderGroup.bind(this);
		this.renderItem = this.renderItem.bind(this);
		this.renderSearch = this.renderSearch.bind(this);
	}

	public render(): JSX.Element {
		const { search } = this.props;

		return (
			<div className={styles.wrapper} data-testid={this.props['data-testid']}>
				{search && this.renderSearch()}
				{this.renderGroups()}
			</div>
		);
	}

	private filterItems(_: React.MouseEvent<HTMLElement>, { value }: SearchProps): void {
		if (typeof value === 'string') {
			this.setState({
				query: value
			});
		}
	}

	private getContainerClass(itemCount: number): string | undefined {
		const { search } = this.props;
		let className = styles.container;

		if (search && itemCount > 5) {
			className = cx(styles.container, styles.scrollContainer);
		}

		return className;
	}

	private getNormalizedGroups(): SelectGroupItemGroup[] {
		const { groups } = this.props;
		return groups.map(group => ({ ...group, items: this.transformItems(group.items) }));
	}

	private highlightMatchedText(text: string, regex: RegExp): string {
		const { query } = this.state;

		if (query.length === 0) {
			return text;
		}

		return text.replace(regex, (match: string) => {
			return `<b>${match}</b>`;
		});
	}

	private transformItems(items: SelectGroupItem[]): SelectGroupItem[] {
		const { search } = this.props;

		if (!search) {
			return items;
		}

		const { query } = this.state;
		const regex = buildInputRegex(query, 'i');

		const isItemMatched = (item: SelectGroupItem): boolean => regex.test(item.text);

		const transformLabel = (item: SelectGroupItem): SelectGroupItem => ({
			...item,
			label: this.highlightMatchedText(item.text, regex)
		});

		return items.filter(isItemMatched).map(transformLabel);
	}

	private renderGroup(group: SelectGroupItemGroup, index: number): JSX.Element {
		const label = group.label ? group.label(group.items) : null;

		return (
			<Form.Field key={`group_${index}`}>
				{label && <label className={styles.label}>{label}</label>}
				{group.items.map(this.renderItem)}
			</Form.Field>
		);
	}

	private renderGroups(): JSX.Element {
		const normalizedGroups = this.getNormalizedGroups();
		const totalItems = getTotalItemsLength(normalizedGroups);

		return (
			<div className={this.getContainerClass(totalItems)}>
				{totalItems > 0 ? normalizedGroups.map(this.renderGroup) : this.renderNoResults()}
			</div>
		);
	}

	private renderItem(item: SelectGroupItem): JSX.Element {
		const { onSelected, selected } = this.props;

		function onItemClicked(_event: React.ChangeEvent) {
			onSelected(item.value);
		}

		return (
			<SelectItem
				disabled={item.disabled}
				images={item.images}
				key={item.value}
				label={item.label}
				text={item.text}
				onClick={onItemClicked}
				selected={selected === item.value}
			/>
		);
	}

	private renderNoResults(): JSX.Element {
		const { noResultsFallback, noResultsMessage } = this.props;

		return (
			<React.Fragment>
				{noResultsFallback !== null && this.renderItem(noResultsFallback)}
				{noResultsMessage !== null && this.renderNoResultsMessage()}
			</React.Fragment>
		);
	}

	private renderNoResultsMessage(): JSX.Element {
		const { noResultsMessage } = this.props;
		return <div className={styles.message}>{noResultsMessage}</div>;
	}

	private renderSearch(): JSX.Element {
		const { query } = this.state;
		return (
			<Search
				className={styles.search}
				fluid={true}
				onSearchChange={this.filterItems}
				placeholder="Filter list"
				showNoResults={false}
				value={query}
			/>
		);
	}
}

export default SelectGroup;
