import buildActionsPCP from './buildActionsPCP';
import buildActionsSMA from './buildActionsSMA';
import buildActionNeeded from './buildActionNeeded';
import buildHasNewMessage from './buildHasNewMessage';
import buildLastUpdate from './buildLastUpdate';
import buildNewTag from './buildNewTag';
import buildPatientName from './buildPatientName';
import buildRequestDate from './buildRequestDate';
import buildRequestor from './buildRequestor';
import buildResponseDate from './buildResponseDate';
import buildReviewer from './buildReviewer';
import buildReviewStatus from './buildReviewStatus';
import buildScore from './buildScore';
import buildSpecialistName from './buildSpecialistName';
import buildSpecialtyType from './buildSpecialtyType';
import buildStatus from './buildStatus';
import buildTimeToRespond from './buildTimeToRespond';

const consultColumns = {
	buildActionsPCP,
	buildActionsSMA,
	buildActionNeeded,
	buildHasNewMessage,
	buildLastUpdate,
	buildNewTag,
	buildPatientName,
	buildRequestDate,
	buildRequestor,
	buildResponseDate,
	buildReviewer,
	buildReviewStatus,
	buildScore,
	buildSpecialistName,
	buildSpecialtyType,
	buildStatus,
	buildTimeToRespond
};

export default consultColumns;
