import { ConversationComposite, ConversationMessageComposite } from 'hooks';
import { ConversationMessageCamel, ConsultDataCamel } from 'thriftgen/thriftCamelTypes';

function getSharedToMessageByConversationId(
	message: ConversationMessageComposite,
	conversationId: ConversationComposite['conversationId']
): ConversationMessageCamel | undefined {
	return message.sharedTo.find(({ conversationId: id }) => id === conversationId);
}

function getSharedFromMessageByConsultDataId(
	messages: ConversationMessageComposite[],
	consultDataId: ConsultDataCamel['consultDataId']
): undefined | ConversationMessageComposite {
	return messages.find(({ sharedFrom }) => {
		if (!sharedFrom) {
			return false;
		}

		if (!sharedFrom.content) {
			return false;
		}

		return sharedFrom.content.consultDataId === consultDataId;
	});
}

export { getSharedFromMessageByConsultDataId, getSharedToMessageByConversationId };
