interface PendingPromise {
	resolve: () => void;
	reject: () => void;
}

type ScriptOptions = Partial<Omit<HTMLScriptElement, 'src'>>;

const loadedScripts: string[] = [];
const pendingScripts: {
	[key: string]: PendingPromise[];
} = {};

function addScript(
	src: string,
	{ async = false, crossOrigin = 'anonymous', ...scriptOptions }: ScriptOptions
): Promise<void> {
	return new Promise((resolve, reject) => {
		const head = document.head;
		const options: Partial<HTMLScriptElement> = {
			async,
			crossOrigin,
			...scriptOptions
		};
		if (!head) {
			reject(new Error('Head not found'));
			return;
		}
		const elem = document.createElement('script');
		elem.src = src;

		Object.entries(options).forEach(([opt, value]) => {
			elem[opt] = value;
		});

		function onLoad() {
			removeListeners();
			resolve();
		}
		function onError() {
			removeListeners();
			reject();
		}
		function removeListeners() {
			elem.removeEventListener('load', onLoad);
			elem.removeEventListener('error', onError);
		}
		elem.addEventListener('load', onLoad);
		elem.addEventListener('error', onError);
		head.appendChild(elem);
	});
}

function isScriptLoaded(src: string): boolean {
	return loadedScripts.includes(src);
}

function onAddScriptSuccess(src: string) {
	loadedScripts.push(src);
	pendingScripts[src].forEach(p => p.resolve());
	delete pendingScripts[src];
}

function onAddScriptFailure(src: string) {
	pendingScripts[src].forEach(p => p.reject());
	delete pendingScripts[src];
}

function loadScript(src: string, scriptOptions: ScriptOptions = {}): Promise<void> {
	return new Promise((resolve, reject) => {
		if (isScriptLoaded(src)) {
			resolve();
			return;
		}

		if (pendingScripts[src]) {
			pendingScripts[src].push({ resolve, reject });
			return;
		}

		pendingScripts[src] = [{ resolve, reject }];

		addScript(src, scriptOptions).then(
			() => onAddScriptSuccess(src),
			() => onAddScriptFailure(src)
		);
	});
}

export default loadScript;
