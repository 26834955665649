import { objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetConversationsRequest } from 'thriftgen/api_types';
import {
	GetConversationsRequestCamel,
	GetConversationsResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function getConversations({
	parentId,
	parentType
}: GetConversationsRequestCamel): Promise<GetConversationsResponseCamel> {
	const client = getCommonClient();
	const request = new GetConversationsRequest({
		parent_id: parentId,
		parent_type: parentType
	});

	const response = await client.get_conversations(request);
	return objectSnakeToCamel(response);
}

export default getConversations;
