import LinkButton from 'components/Buttons/LinkButtons/LinkButton';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { Button, ButtonType } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { getConsultLink } from 'lib/consultHelpers';
import * as React from 'react';
import Strings from 'lib/strings';

const styles = {
	viewButton: css`
		.ui.button {
			text-transform: uppercase;
		}
	`
};

export default (isSitkaMedicalAssociate: boolean): DataTableColumn<ConsultComposite> => ({
	render: () => <React.Fragment />,
	renderMobile: (consultComposite: ConsultComposite) => {
		return (
			<div className={styles.viewButton}>
				<LinkButton
					buttonType={ButtonType.TEXT_PRIMARY}
					text={Strings.Buttons.VIEW}
					size={Button.Size.TINY}
					link={getConsultLink(consultComposite, isSitkaMedicalAssociate)}
				/>
			</div>
		);
	}
});
