import FormErrors from 'components/Forms/FormErrors';
import { MessageSendError } from 'components/Messages';
import { useConversationContext } from 'contexts';
import { useDictationContext } from 'contexts/DictationContext';
import { getMarkdownFromState, RichTextEditorContentValues } from 'display';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';
import debounce from 'lib/debounce';
import { secureStorage as SecureStorage } from 'lib/Storage';
import React, { useCallback, useState } from 'react';
import { DataFormErrors } from './DataFormTypes';
import Message from './Fields/Message';

interface FormData {
	richTextEditorContentValues: RichTextEditorContentValues;
}

interface NewMessageFormProps {
	placeholder: string;
}

const INITIAL_STATE: FormData = {
	richTextEditorContentValues: { markdown: '', editorState: EditorState.createEmpty() }
};
const INITIAL_ERROR_STATE = {
	fields: [],
	messages: []
};

function NewMessageForm({ placeholder }: NewMessageFormProps): JSX.Element {
	const [data, setData] = useState<FormData>(INITIAL_STATE);
	const [formErrors, setFormErrors] = useState<DataFormErrors>(INITIAL_ERROR_STATE);
	const [apiError, setApiError] = useState<Error | null>(null);
	const { conversation, postNewMessage } = useConversationContext();
	const storageKey = `consult_storage_conversation_${conversation.conversationId}`;

	const { stopListening } = useDictationContext();

	const getStateFromStorage = useCallback(() => {
		if (storageKey) {
			const storageState = SecureStorage.get(storageKey);
			if (storageState) {
				let editorState: EditorState | undefined;
				let markdown: string;
				try {
					const rawContentFromStore = convertFromRaw(JSON.parse(storageState));
					editorState = EditorState.createWithContent(rawContentFromStore);
					markdown = getMarkdownFromState(editorState);
				} catch {
					markdown = storageState;
				}
				setData({
					richTextEditorContentValues: {
						markdown,
						editorState
					}
				});
			}
		}
	}, [storageKey]);

	React.useEffect(() => {
		getStateFromStorage();
	}, [getStateFromStorage]);

	async function sendMessage(): Promise<void> {
		stopListening();
		setFormErrors(INITIAL_ERROR_STATE);
		setApiError(null);

		const errors = Message.validate(data.richTextEditorContentValues.markdown, true);

		if (errors !== null) {
			setFormErrors(errors);
			return Promise.resolve();
		}

		try {
			await postNewMessage({ text: data.richTextEditorContentValues.markdown });
			SecureStorage.remove(storageKey);
			setData(INITIAL_STATE);
		} catch (caughtApiError) {
			return setApiError(caughtApiError);
		}
	}

	const debouncedStoreMessageText = React.useRef(
		debounce((content: RichTextEditorContentValues) => {
			let contentToStore: string;
			if (content.editorState) {
				contentToStore = JSON.stringify(
					convertToRaw(content.editorState.getCurrentContent())
				);
			} else {
				contentToStore = content.markdown;
			}
			SecureStorage.set(storageKey, contentToStore);
		}, 500)
	);

	function onChange(content: RichTextEditorContentValues): void {
		setData({
			richTextEditorContentValues: {
				markdown: content.markdown,
				editorState: content.editorState
			}
		});
		debouncedStoreMessageText.current(content || '');
	}

	return (
		<React.Fragment>
			{formErrors.fields.length > 0 && (
				<FormErrors fields={formErrors.fields} messages={formErrors.messages} />
			)}
			{apiError && <MessageSendError message={apiError.message} />}
			<Message
				errors={formErrors}
				placeholder={placeholder}
				onChange={onChange}
				data={data}
				onSubmit={sendMessage}
			/>
		</React.Fragment>
	);
}

export default NewMessageForm;
