import * as React from 'react';
import { UserContext, UserContextData, UserContextMutators } from 'contexts';

interface ConsumerProps {
	data: UserContextData;
	mutators: UserContextMutators;
}

type UserContextProviderProps = ConsumerProps & {
	children: React.ReactNode;
};

function UserContextProvider({ data, mutators, children }: UserContextProviderProps): JSX.Element {
	return <UserContext.Provider value={{ ...data, ...mutators }}>{children}</UserContext.Provider>;
}

export default UserContextProvider;
