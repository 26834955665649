import * as React from 'react';
import MaskedInput from 'react-text-mask';

import { buildLabel } from '../helpers';
import { DataFormFieldComponentProps } from '../../DataFormTypes';
import { conformValue, FIELD_NAME, FIELD_LABEL } from './helpers';
import { Form, Icon } from 'display';

const PLACEHOLDER_MBI = '1EG4-TE5-MK73';
const ERROR_MESSAGE = 'Please enter a valid medicare beneficiary id.';
const mbiMask = [
	/\d/,
	/[a-zA-Z]/,
	/[a-zA-Z]|\d/,
	/\d/,
	'-',
	/[a-zA-Z]/,
	/[a-zA-Z]|\d/,
	/\d/,
	'-',
	/[a-zA-Z]/,
	/[a-zA-Z]/,
	/\d/,
	/\d/
];

function buildPatientMedicareBeneficiaryIdValidator(
	userPatientMedicareBeneficiaryIdFieldName: string
) {
	return function (data: DataFormFieldComponentProps) {
		const userPatientMedicareBeneficiaryId: string =
			data[userPatientMedicareBeneficiaryIdFieldName];

		if (userPatientMedicareBeneficiaryId) {
			const normalizedMBI = conformValue(userPatientMedicareBeneficiaryId);
			if (normalizedMBI.length !== 11) {
				return {
					fields: [userPatientMedicareBeneficiaryIdFieldName],
					messages: [ERROR_MESSAGE]
				};
			}
		}

		return null;
	};
}

function UserPatientMedicareBeneficiaryId({
	data,
	editableFields,
	errors,
	onChange,
	onBlur
}: DataFormFieldComponentProps): JSX.Element | null {
	const value = data[FIELD_NAME];

	function onChangeHandler({ currentTarget }: React.BaseSyntheticEvent) {
		onChange(currentTarget, {
			name: FIELD_NAME,
			value: conformValue(currentTarget.value)
		});
	}

	if (editableFields.includes(FIELD_NAME)) {
		return (
			<div className={errors.fields.includes(FIELD_NAME) ? 'error field' : 'field'}>
				<label htmlFor={FIELD_NAME}>{buildLabel(FIELD_LABEL, true)}</label>
				<Form.Input id={FIELD_NAME} name={FIELD_NAME} width={8}>
					<MaskedInput
						mask={mbiMask}
						guide={true}
						id={FIELD_NAME}
						name={FIELD_NAME}
						placeholder={PLACEHOLDER_MBI}
						type="text"
						onChange={onChangeHandler}
						value={value || ''}
						onBlur={onBlur}
					/>
				</Form.Input>
			</div>
		);
	}

	if (value) {
		return (
			<Form.Field>
				{'id card' && <Icon name={'id card'} />}
				<span>{value}</span>
			</Form.Field>
		);
	}

	return null;
}

UserPatientMedicareBeneficiaryId.FIELD_NAME = FIELD_NAME;
UserPatientMedicareBeneficiaryId.displayName = `TextField-${FIELD_NAME}`;
UserPatientMedicareBeneficiaryId.validate = buildPatientMedicareBeneficiaryIdValidator(
	UserPatientMedicareBeneficiaryId.FIELD_NAME
);

export default UserPatientMedicareBeneficiaryId;
