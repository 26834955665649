import thunkMiddleware from 'redux-thunk';
import { applyMiddleware, createStore, combineReducers } from 'redux';

import reducerRegistry from './registry';
import initialState from './initialstate';

// This type cannot be imported given that reducers are loaded dynamically
const LOGOUT_ACTION = 'auth/logout';

const combine = reducers => {
	const reducerNames = Object.keys(reducers);
	Object.keys(initialState()).forEach(item => {
		if (reducerNames.indexOf(item) === -1) {
			/* eslint-disable no-param-reassign */
			reducers[item] = (state = null) => state;
		}
	});
	return combineReducers(reducers);
};

const rootReducer = reducer => {
	return function (state, action) {
		// Clears the store upon logout
		if (action.type === LOGOUT_ACTION) {
			state = undefined;
		}

		return reducer(state, action);
	};
};

const initialReducer = combine(reducerRegistry.getReducers());
const store = createStore(
	(state, action) => rootReducer(initialReducer)(state, action),
	initialState(),
	applyMiddleware(thunkMiddleware)
);

reducerRegistry.setChangeListener(reducers => {
	store.replaceReducer((state, action) => rootReducer(combine(reducers))(state, action));
});

export default store;
