import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import throttle from 'lib/throttle';

import { getUserIdFromAuthToken } from 'services/authentication';
import { FAILURE, IN_PROGRESS, INACTIVE, SUCCESS } from 'store/actionStatus';
import { sendGetUser } from 'store/users';
import AuthUserRequestContext from 'contexts/AuthUserRequestContext';

import { Loader } from 'display/';
import { UserLoadError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';

class AuthUserRequestContextProvider extends React.Component {
	constructor(props) {
		super(props);

		this.sendGetAuthUser = this.sendGetAuthUser.bind(this);
		this.sendGetUser = throttle(this.sendGetUser.bind(this), 300);

		this.state = {
			blocking: false
		};
	}

	getContextValue() {
		return {
			sendGetAuthUser: this.sendGetAuthUser
		};
	}

	sendGetAuthUser(shouldBlock) {
		const { request } = this.props;
		const { status } = request.data;

		if (status === undefined || status === INACTIVE) {
			this.sendGetUser();
		}

		this.setState(state => {
			if (shouldBlock && !state.blocking) {
				return { blocking: shouldBlock };
			}

			return null;
		});
	}

	sendGetUser() {
		this.props.sendGetUser({ userId: getUserIdFromAuthToken() });
	}

	renderContent() {
		const { children, request } = this.props;
		const { blocking } = this.state;
		const { message, status } = request.data;

		if (!blocking) {
			return children;
		}
		switch (status) {
			case INACTIVE:
			case IN_PROGRESS:
				return <Loader active />;

			case FAILURE:
				return <UserLoadError message={message} />;

			case SUCCESS:
			default:
				return children;
		}
	}

	render() {
		return (
			<AuthUserRequestContext.Provider value={this.getContextValue()}>
				{this.renderContent()}
			</AuthUserRequestContext.Provider>
		);
	}
}

AuthUserRequestContextProvider.defaultProps = {
	actorId: null
};

AuthUserRequestContextProvider.propTypes = {
	actorId: PropTypes.string,
	children: PropTypes.node.isRequired,
	request: withApiCall.requestPropTypes.isRequired,
	sendGetUser: PropTypes.func.isRequired
};

export default withApiCall({ sendGetUser }, connect()(AuthUserRequestContextProvider));
