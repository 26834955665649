import { OnSuccess } from 'api/types';
import { isObjectEmpty } from 'lib/isObjectEmpty';
import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import {
	ConsultDataFile,
	ConsultDataUpdate,
	ConsultDataUpdates,
	ConsultMessage,
	ConsultUpdates,
	ManageConsultRequest,
	ManageConsultResponse,
	UserInfo
} from 'thriftgen/api_types';
import { ConsultUpdatesCamel, ExternalDocumentCamel } from 'thriftgen/thriftCamelTypes';

interface ManageConsultRequestAction {
	consultId?: ManageConsultRequest['consult_id'];
	consultMessage?: {
		mediaKeys?: ConsultMessage['media_keys'];
		text?: ConsultMessage['text'];
		clinicalQuestionText?: ConsultMessage['text'];
		recordingId?: ConsultMessage['recording_id'];
	};
	consultDataUpdates?: Array<{
		consultDataId: ConsultDataUpdates['consult_data_id'];
		update: {
			redactedReason?: ConsultDataUpdate['redacted_reason'];
		};
	}>;
	createNewConsult?: ManageConsultRequest['create_new_consult'];
	dataFilenames?: string[];
	dataUploadKeys?: string[];
	patientUpdates?: {
		dateOfBirth?: UserInfo['date_of_birth'];
		email?: UserInfo['email'];
		firstName?: UserInfo['first_name'];
		lastName?: UserInfo['last_name'];
		phoneNumber?: UserInfo['phone_number'];
	};
	updates?: ConsultUpdatesCamel;
	externalDocuments?: ExternalDocumentCamel[];
}

interface ManageConsultUpdateConsultDataAction {
	consultId: ManageConsultRequestAction['consultId'];
	consultDataId: ConsultDataUpdates['consult_data_id'];
	redactedReason?: ConsultDataUpdate['redacted_reason'];
}

function buildConsultDataUpdatesList(
	consultDataUpdates?: ManageConsultRequestAction['consultDataUpdates']
): ConsultDataUpdates[] | undefined {
	if (consultDataUpdates === undefined) {
		return undefined;
	}

	return consultDataUpdates.map(({ consultDataId, update }) => {
		return new ConsultDataUpdates({
			consult_data_id: consultDataId,
			update: new ConsultDataUpdate(objectCamelToSnake(update))
		}) as ConsultDataUpdates;
	});
}

function buildDataFiles(
	filenames: string[] | undefined,
	uploadKeys: string[] | undefined
): ConsultDataFile[] | undefined {
	if (filenames === undefined || uploadKeys === undefined) {
		return undefined;
	}
	if (filenames.length !== uploadKeys.length) {
		throw new Error(
			'Failed to upload attached files. Please try selecting and uploading them once again.'
		);
	}

	return filenames.map((value, index) => {
		return new ConsultDataFile({
			filename: value,
			upload_key: uploadKeys[index]
		});
	});
}

function buildMessages(
	consultMessage: ManageConsultRequestAction['consultMessage']
): ConsultMessage | undefined {
	if (consultMessage === undefined) {
		return undefined;
	}

	return new ConsultMessage(objectCamelToSnake(consultMessage));
}

function buildPatientUpdates(
	patientUpdates?: ManageConsultRequestAction['patientUpdates']
): UserInfo | undefined {
	if (patientUpdates === undefined || isObjectEmpty(patientUpdates)) {
		return undefined;
	}

	return new UserInfo(objectCamelToSnake(patientUpdates)) as UserInfo;
}

function buildUpdates(updates: ManageConsultRequestAction['updates']): ConsultUpdates | undefined {
	if (updates === undefined) {
		return undefined;
	}

	const newUpdates: ManageConsultRequestAction['updates'] = {
		...updates
	};

	return new ConsultUpdates(objectCamelToSnake(newUpdates)) as ConsultUpdates;
}

function buildExternalDocuments(
	externalDocuments?: ExternalDocumentCamel[]
): ExternalDocument[] | undefined {
	if (externalDocuments === undefined) {
		return undefined;
	}

	return objectCamelToSnake(externalDocuments) as ExternalDocument[];
}

function manageConsult({
	consultId,
	consultMessage,
	consultDataUpdates,
	createNewConsult,
	dataFilenames,
	dataUploadKeys,
	patientUpdates,
	updates,
	externalDocuments
}: ManageConsultRequestAction): Promise<ManageConsultResponse> {
	const client = getCommonClient();
	const dataFilesOrUndef = buildDataFiles(dataFilenames, dataUploadKeys);
	const messageOrUndef = buildMessages(consultMessage);
	const updatesOrUndef = buildUpdates(updates);
	const consultDataUpdatesOrUndef = buildConsultDataUpdatesList(consultDataUpdates);
	const externalDocumentsOrUndef = buildExternalDocuments(externalDocuments);

	const request = new ManageConsultRequest({
		consult_id: consultId,
		consult_message: messageOrUndef,
		consult_data_updates: consultDataUpdatesOrUndef,
		data_files: dataFilesOrUndef,
		create_new_consult: createNewConsult,
		patient_updates: buildPatientUpdates(patientUpdates),
		updates: updatesOrUndef,
		external_documents: externalDocumentsOrUndef
	});

	return client.manage_consult(request);
}

manageConsult.onSuccess = ({ request }: OnSuccess<ManageConsultRequestAction>) =>
	request.createNewConsult ? 'Consultation request sent' : 'Consultation updated';

export {
	buildConsultDataUpdatesList,
	buildPatientUpdates,
	ManageConsultRequestAction,
	ManageConsultUpdateConsultDataAction
};

export default manageConsult;
