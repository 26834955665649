import * as React from 'react';

import { css, cx } from 'emotion';
import { mediaQueries } from 'styles';

const styles = {
	container: css`
		display: flex;
		flex-direction: column;
		margin-bottom: 2em;
		padding: 1.5em;

		${mediaQueries.desktop} {
			flex-direction: row;
		}
	`
};

interface BannerProps {
	children: JSX.Element[];
	className: string;
}

function Banner({ children, className }: BannerProps): JSX.Element {
	return <div className={cx(styles.container, className)}>{children}</div>;
}

export default Banner;
