import { USER_FILTERS } from 'api/customFilters';
import userColumns from 'components/DataTable/UserTable/columns';
import { Filter } from 'hooks/pagination';
import { Feature, isFeatureEnabled } from 'lib/feature';
import { isArray } from 'lodash';
import React, { useMemo, useState } from 'react';
import { Grid } from 'semantic-ui-react';
import { ConsultCamel } from 'thriftgen/thriftCamelTypes';
import ConsultSelectDetails from './ConsultSelectDetails';
import OriginalUserSelectionLabel from './OriginalUserSelectionLabel';
import { UserSelectProps } from './types';
import UserSelect from './UserSelect';
import UserSelectFilter from './UserSelectFilter';

const COLUMNS = [
	userColumns.buildPreviousConsultCount(),
	userColumns.buildDailyConsults(),
	userColumns.buildWeeklyConsults(),
	userColumns.buildSpecialties()
];

type ConsultResponderSelectProps = Pick<UserSelectProps, 'name' | 'onChange'> & {
	consultId: ConsultCamel['consultId'];
	value: string;
	specialty: ConsultCamel['specialty'];
	patientTreatmentState: ConsultCamel['patientTreatmentState'];
	patientIdForConsultCounts: string;
};

const USER_FILTER_ATTRIBUTE = 'user_filters';

function ConsultResponderSelect({
	consultId,
	specialty,
	value,
	...props
}: ConsultResponderSelectProps): JSX.Element {
	const configurableFilters = useMemo(() => {
		const filterValues = [
			{
				label: 'Specialty',
				value: 'user_specialties.specialty'
			}
		];

		if (isFeatureEnabled(Feature.CWA_1378_SMA_LICENSES)) {
			filterValues.push({
				label: 'State License',
				value: USER_FILTERS.SMA_LICENSED_FOR_CONSULT
			});
		}

		filterValues.push({
			label: 'Daily Consult Availability',
			value: USER_FILTERS.SMA_DAILY_CONSULTS_LIMIT_AVAILABLE
		});

		if (isFeatureEnabled(Feature.CWA_1355_SMA_SCHEDULE)) {
			filterValues.push({
				label: 'Schedule Availability',
				value: USER_FILTERS.SMA_SCHEDULE_AVAILABLE_FOR_CONSULT
			});
		}

		return [
			{
				label: 'User Filters:',
				attribute: USER_FILTER_ATTRIBUTE,
				values: filterValues
			}
		];
	}, []);

	const [enabledFilters, setEnabledFilters] = useState<Filter['value']>(
		configurableFilters[0].values.map(value => value.value)
	);

	const selectedFilters: Filter[] = useMemo(() => {
		return [
			{
				attribute: USER_FILTER_ATTRIBUTE,
				value: enabledFilters
			}
		];
	}, [enabledFilters]);

	const requestFilters = useMemo(() => {
		const filters: Filter[] = [
			{
				attribute: 'is_sitka_medical_associate',
				value: true
			},
			{
				attribute: 'is_active',
				value: true
			}
		];

		const additionalFilters: Filter[] = [];

		if (isFeatureEnabled(Feature.CWA_1378_SMA_LICENSES)) {
			additionalFilters.push({
				attribute: USER_FILTERS.SMA_LICENSED_FOR_CONSULT,
				value: consultId
			});
		}

		additionalFilters.push({
			attribute: USER_FILTERS.SMA_DAILY_CONSULTS_LIMIT_AVAILABLE,
			value: consultId
		});

		if (isFeatureEnabled(Feature.CWA_1355_SMA_SCHEDULE)) {
			additionalFilters.push({
				attribute: USER_FILTERS.SMA_SCHEDULE_AVAILABLE_FOR_CONSULT,
				value: consultId
			});
		}

		if (specialty) {
			additionalFilters.push({
				attribute: 'user_specialties.specialty',
				value: specialty
			});
		}

		return [
			...filters,
			...additionalFilters.filter(additionalFilter => {
				if (isArray(enabledFilters)) {
					return enabledFilters.includes(additionalFilter.attribute);
				} else {
					return enabledFilters === additionalFilter.attribute;
				}
			})
		];
	}, [consultId, enabledFilters, specialty]);

	function handleUserSelectFilterChange(filters: Filter[]) {
		const newEnabledFilters = filters.find(
			filter => filter.attribute === USER_FILTER_ATTRIBUTE
		);

		const newEnabledFiltersValue = [];

		if (newEnabledFilters) {
			if (isArray(newEnabledFilters?.value)) {
				newEnabledFiltersValue.push(...newEnabledFilters.value);
			} else {
				newEnabledFiltersValue.push(newEnabledFilters.value);
			}
		}

		setEnabledFilters(newEnabledFiltersValue);
	}

	return (
		<React.Fragment>
			<OriginalUserSelectionLabel value={value} />
			<Grid columns="equal">
				<Grid.Row>
					<Grid.Column>
						<ConsultSelectDetails
							specialty={specialty}
							patientTreatmentState={props.patientTreatmentState}
						/>
					</Grid.Column>
					<Grid.Column>
						<UserSelectFilter
							filters={configurableFilters}
							onChange={handleUserSelectFilterChange}
							selectedFilters={selectedFilters}
						/>
					</Grid.Column>
				</Grid.Row>
			</Grid>
			<UserSelect
				columns={COLUMNS}
				requestFilters={requestFilters}
				value={value}
				{...props}
			/>
		</React.Fragment>
	);
}

export default ConsultResponderSelect;
