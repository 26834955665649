import { updateUser as updateUserRequest } from 'api/common';
import { updatePartner as sendUpdatePartner } from 'api/admin';
import { UserComposite } from 'hooks/queries/user/types';
import * as React from 'react';
import { User } from 'thriftgen/api_types';
import { UserInfoCamel } from 'thriftgen/thriftCamelTypes';

type UpdateUserMutator = (args: Partial<UserInfoCamel>) => Promise<void>;
type RemoveUserFromPartnerMutator = (partnerId: string) => Promise<void>;

interface UserContextMutators {
	updateUser: UpdateUserMutator;
	removeUserFromPartner: RemoveUserFromPartnerMutator;
	revalidate: () => Promise<void>;
}

interface UserContextData {
	user: UserComposite;
}

type UserContextValue = UserContextMutators & UserContextData;

const UserContext = React.createContext<UserContextValue | undefined>(undefined);

function useUserContext(): UserContextValue {
	const context = React.useContext(UserContext);

	if (context === undefined) {
		throw new Error('No user has been set');
	}

	return context;
}

function buildUserMutators(
	userId: User['user_id'],
	revalidate: () => Promise<void>
): UserContextMutators {
	const updateUser: UpdateUserMutator = args =>
		updateUserRequest({
			userId,
			updates: args
		}).then(revalidate);

	const removeUserFromPartner: RemoveUserFromPartnerMutator = partnerId =>
		sendUpdatePartner({
			partnerId,
			mutations: {
				adminIds: {
					toRemove: [userId]
				},
				memberIds: {
					toRemove: [userId]
				}
			}
		}).then(revalidate);

	return {
		updateUser,
		removeUserFromPartner,
		revalidate
	};
}

export {
	buildUserMutators,
	useUserContext,
	UserContext,
	UserContextData,
	UserContextMutators,
	UserContextValue
};
