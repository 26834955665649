import { FilterField, FilterPopoverDropdown } from 'display';
import { Filter } from 'hooks/pagination';
import * as React from 'react';

interface UserSelectFilterProps {
	filters: FilterField[];
	selectedFilters: Filter[];
	onChange: (filters: Filter[]) => void;
}

function UserSelectFilter({
	filters,
	selectedFilters,
	onChange
}: UserSelectFilterProps): JSX.Element {
	return (
		<FilterPopoverDropdown
			filters={filters}
			selectedFilters={selectedFilters}
			triggerIconName="settings"
			triggerText="Configure User Filters"
			displayPopoverTitle={false}
			onChange={onChange}
		/>
	);
}

export default UserSelectFilter;
