import * as React from 'react';
import { css } from 'emotion';

import AssetListItemThumbnail from './AssetListItemThumbnail';
import AssetListItemTitleArea from './AssetListItemTitleArea';
import AssetListItemAction from './AssetListItemAction';
import {
	AssetListItemThumbnailProps,
	AssetListItemTitleAreaProps,
	AssetListItemMetaProps
} from './types';

const styles = {
	container: css`
		display: flex;
		padding: 0.5em;
	`,
	image: css`
		margin-right: 0.5em;
	`
};

interface AssetListItemProps {
	id?: string;
	action?: React.ReactNode;
	meta?: AssetListItemMetaProps[];
	thumbnail: AssetListItemThumbnailProps['thumbnail'];
	title: AssetListItemTitleAreaProps['title'];
	subtitle: AssetListItemTitleAreaProps['subtitle'];
}

function AssetListItem({
	id,
	action,
	meta,
	thumbnail,
	title,
	subtitle
}: AssetListItemProps): JSX.Element {
	return (
		<div className={styles.container}>
			<AssetListItemThumbnail thumbnail={thumbnail} />
			<AssetListItemTitleArea title={title} subtitle={subtitle} meta={meta} id={id} />
			{action && <AssetListItemAction action={action} />}
		</div>
	);
}

export default AssetListItem;
