import buildFieldGroup from './base/buildFieldGroup';
import SitkaMedicalAssociate from './SitkaMedicalAssociate';
import SitkaOps from './SitkaOps';
import { DataFormFieldComponent } from '../DataFormTypes';

const SitkaRoles: DataFormFieldComponent = buildFieldGroup({
	fieldName: 'sitkaRoles',
	label: 'Sitka roles',
	fields: [SitkaOps, SitkaMedicalAssociate],
	groupProps: { inline: true }
});

export default SitkaRoles;
