import { ThunkAction } from 'store/types';
import { GetUserResponse, GetUsersResponse, User } from 'thriftgen/api_types';
import { setPartners } from '../admin/admin.actions';
import { selectAuthUserId, setLogin } from '../auth';
import { SetUser, SetUsers, UsersActionType } from './users.types';

export function setUser(user: User): SetUser {
	return {
		type: UsersActionType.SET_USER,
		user
	};
}

export function setUsers(users: User[]): SetUsers {
	return {
		type: UsersActionType.SET_USERS,
		users
	};
}

export function handleSendGetUsersResponse({ users }: GetUsersResponse): ThunkAction<void> {
	return (dispatch, getState) => {
		const actorId = selectAuthUserId(getState());

		dispatch(setUsers(users));

		const updatedActor = users.find(user => user.user_id === actorId);
		if (updatedActor) {
			dispatch(setLogin(updatedActor));
		}
	};
}

export function handleSingleUserResponse({ partners, user }: GetUserResponse): ThunkAction<void> {
	return (dispatch, getState) => {
		const actorId = selectAuthUserId(getState());

		if (partners) {
			dispatch(setPartners(partners));
		}
		dispatch(setUser(user));

		if (user && actorId === user.user_id) {
			dispatch(setLogin(user));
		}
	};
}
