import { DataTableColumn } from 'components/DataTable/DataTable';
import { buildHalfWidthMobileColumn } from 'components/DataTable/helpers';
import { ConsultComposite } from 'hooks';
import { timeSinceTimestamp } from 'lib/date';
import * as React from 'react';

const header = 'Last Update';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ updatedAt }: ConsultComposite) => <div>{timeSinceTimestamp(updatedAt)}</div>,
	renderMobile: ({ updatedAt }: ConsultComposite) =>
		buildHalfWidthMobileColumn(header, timeSinceTimestamp(updatedAt)),
	sortKeys: ['updated_at']
});
