import store from 'store/index';
import { selectAuthUserId, selectLoggedIn } from 'store/auth';
import { Notifier } from '@airbrake/browser';

function getEnvironment(): string {
	switch (window.location.hostname) {
		case 'consult.trustsitka.com':
			return 'p2p';
		case 'consult-stage.trustsitka.com':
			return 'p2p-stage';
		case 'consult-dev.trustsitka.com':
			return 'p2p-dev';
		case 'stage.trustsitka.com':
			return 'stage';
		case 'trustsitka.com':
		case 'www.trustsitka.com':
			return 'production';
		default:
			return 'local';
	}
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function airbrakeFilter(notice: any): PendingAny {
	const environment = getEnvironment();
	const state = store.getState();
	const userId = selectLoggedIn(state) ? selectAuthUserId(state) : undefined;

	if (environment === 'local') {
		return null;
	}

	// The severity option below should be passed by the `notify` call in
	// AirbrakeErrorBoundary.jsx but it isn't working as expected
	// TODO: Move this severity option there once
	// https://github.com/airbrake/airbrake-js/issues/516 is resolved.
	return {
		...notice,
		context: {
			...notice.context,
			buildVersion: window.Sitka.buildVersion,
			userId,
			environment,
			severity: notice.context.windowError ? 'critical' : notice.context.severity
		}
	};
}

const airbrakeInstance = new Notifier({
	projectId: +(process.env.REACT_APP_AIRBRAKE_PROJECT_ID || ''),
	projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_API_KEY || ''
});

airbrakeInstance.addFilter(airbrakeFilter);

export default airbrakeInstance;
