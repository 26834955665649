import FormErrors from 'components/Forms/FormErrors';
import * as React from 'react';
import { UserInfoCamel } from 'thriftgen/thriftCamelTypes';
import DataForm from './DataForm';
import { DataFormDataProps, DataFormErrors, DataFormFieldComponent } from './DataFormTypes';
import { getFieldsAndSubfieldNames } from './Fields/helpers';
import PatientSearch from './Fields/PatientSearch';

interface PatientErrors {
	errors: DataFormErrors | null;
}

type SearchPatientFormProps = Omit<DataFormDataProps<Partial<UserInfoCamel>>, 'onBlur'> &
	PatientErrors;

const PATIENT_FIELDS: DataFormFieldComponent[] = [PatientSearch];

const REQUIRED_FIELDS: string[] = getFieldsAndSubfieldNames(PATIENT_FIELDS);

function SearchPatientForm({ errors, data, onChange }: SearchPatientFormProps): JSX.Element {
	return (
		<React.Fragment>
			<DataForm
				data={data}
				errors={errors}
				editableFields={getFieldsAndSubfieldNames(PATIENT_FIELDS)}
				fields={PATIENT_FIELDS}
				onChange={onChange}
			/>
			{errors && errors.fields.length > 0 && (
				<FormErrors fields={errors.fields} messages={errors.messages} />
			)}
		</React.Fragment>
	);
}

SearchPatientForm.validate = (data: SearchPatientFormProps['data']): DataFormErrors | null => {
	return DataForm.validate<SearchPatientFormProps['data']>(PATIENT_FIELDS, REQUIRED_FIELDS, data);
};

export { SearchPatientForm };
