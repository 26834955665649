import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { selectIsAppStale } from 'store/meta';
import { MetaState } from 'store/meta/meta.types';
import { RequestsState } from 'store/requests/requests.types';
import { selectActiveRequests } from 'store/requests';
import { AppState } from 'store/types';

import { hasPathChanged } from './hasPathChanged';

interface ConsumerProps {
	canSafelyReload?: boolean;
	children: React.ReactNode;
}

interface StoreProps {
	shouldReload: boolean;
}

type ForceReloadProps = ConsumerProps & RouteComponentProps & StoreProps;

class ForceReload extends React.Component<ForceReloadProps> {
	public shouldComponentUpdate(nextProps: ForceReloadProps): boolean {
		// Disables re-rendering of this component in case
		// the app suddenly needs to reload. Since the render
		// will only get called once then we ensure that a reload
		// happens only during the route mounting stage (thus, the next transition).
		return hasPathChanged(this.props.location, nextProps.location);
	}

	public render(): React.ReactNode {
		const { children, shouldReload } = this.props;

		if (shouldReload) {
			window.location.reload();
			return null;
		}

		return children;
	}
}

const mapStateToProps = (
	store: AppState<{ meta: MetaState; requests: RequestsState }>,
	ownProps: ConsumerProps
): StoreProps => ({
	shouldReload:
		selectIsAppStale(store) &&
		Boolean(ownProps.canSafelyReload) &&
		selectActiveRequests(store).length === 0
});

export default withRouter(connect(mapStateToProps)(ForceReload));
