import * as React from 'react';
import { css, cx } from 'emotion';
import { Link as RouterLink, LinkProps as RouteLinkProps } from 'react-router-dom';
import { LocationDescriptorObject } from 'history';
import isObject from 'lib/isObject';

enum LinkStyle {
	UNDERLINE = 'underline'
}

const styles = {
	[LinkStyle.UNDERLINE]: css`
		text-decoration: underline;
	`
};

type LinkProps = RouteLinkProps<unknown> & {
	children?: any;
	style?: LinkStyle;
	className?: string;
};

const isLocationDescriptor = (to: LinkProps['to']): to is LocationDescriptorObject => isObject(to);

function constructRoute(to: LinkProps['to']): LocationDescriptorObject<unknown> {
	if (typeof to === 'string') {
		const [nohash, hash] = to.split('#');
		const [pathname, search] = nohash.split('?');
		return {
			pathname,
			search,
			hash
		};
	}

	if (isLocationDescriptor(to)) {
		return to;
	}

	throw new Error('Unknown route type' + typeof to);
}

function Link({ children, replace, style, to, className }: LinkProps): JSX.Element {
	const routerTo = constructRoute(to);
	const routerLinkClassName = style ? styles[style] : undefined;

	return (
		<RouterLink className={cx(routerLinkClassName, className)} replace={replace} to={routerTo}>
			{children}
		</RouterLink>
	);
}

Link.Style = LinkStyle;
export default Link;
export { LinkProps };
