import * as React from 'react';

import { css, cx } from 'emotion';
import { colors, fontSize, fontWeight, mediaQueries, shadows } from 'styles';

import { CardLabelImages } from './types';

const styles = {
	cardLabel: {
		base: css`
			align-items: center;
			border-radius: 4px;
			display: flex;
			flex-direction: row;
			font-size: ${fontSize[3]};
			line-height: 2.25rem;
			padding: 0.5em;

			${mediaQueries.desktop} {
				padding: 0.5em 2em;
			}
		`,
		image: css`
			& svg {
				margin-right: 1em;
				height: 40px;
				width: 40px;

				${mediaQueries.desktop} {
					height: 56px;
					width: 56px;
				}
			}
		`,
		hover: css`
			&:hover {
				@media (hover: hover) {
					box-shadow: ${shadows.l1};
				}
			}
		`,
		disabled: css`
			opacity: 0.5;
		`,
		selected: css`
			background-color: ${colors.primary['50']};
			color: ${colors.primary['700']};
			font-weight: ${fontWeight.bold};
		`,
		unselected: css`
			font-weight: ${fontWeight.regular};
		`
	},
	input: css`
		float: left;
		opacity: 0 !important;
		position: absolute;
	`
};

interface CardLabelProps {
	disabled: boolean;
	htmlFor: string;
	images: CardLabelImages;
	label: string;
	selected?: boolean;
	text: string;
}

function CardLabel({ disabled, htmlFor, images, label, selected }: CardLabelProps): JSX.Element {
	let image = null;
	let labelStyles = styles.cardLabel.base;

	if (disabled) {
		image = images.unselected;
		labelStyles = cx(labelStyles, styles.cardLabel.disabled);
	} else if (selected) {
		image = images.selected;
		labelStyles = cx(labelStyles, styles.cardLabel.selected);
	} else {
		image = images.unselected;
		labelStyles = cx(labelStyles, styles.cardLabel.unselected, styles.cardLabel.hover);
	}

	function onClick(e: React.MouseEvent<HTMLElement>): void {
		// Avoids activating an input that is disabled if the label is clicked
		e.preventDefault();
		return;
	}

	return (
		<label className={labelStyles} htmlFor={htmlFor} onClick={disabled ? onClick : undefined}>
			{image && (
				<span className={styles.cardLabel.image} title={label}>
					{image}
				</span>
			)}
			<span dangerouslySetInnerHTML={{ __html: label }} />
		</label>
	);
}

CardLabel.defaultProps = {
	disabled: false,
	selected: false
};

interface SelectItemProps {
	disabled: boolean;
	images: CardLabelImages;
	label: string;
	onClick: React.ChangeEventHandler;
	selected?: boolean;
	text: string;
}

function SelectItem({
	disabled,
	images,
	label,
	onClick,
	selected,
	text
}: SelectItemProps): JSX.Element {
	const name = text.replace(/\s+/g, '');

	return (
		<div>
			<input
				disabled={disabled}
				checked={selected}
				className={styles.input}
				id={name}
				name={name}
				onChange={disabled ? undefined : onClick}
				type="radio"
			/>
			<CardLabel
				disabled={disabled}
				htmlFor={name}
				images={images}
				label={label}
				selected={selected}
				text={text}
			/>
		</div>
	);
}

SelectItem.defaultProps = {
	selected: false
};

export default SelectItem;
