import { DataTableColumn } from 'components/DataTable/DataTable';
import { getAbbreviatedDate } from 'lib/date';
import Strings from 'lib/strings';
import { TimeframeRestriction } from 'thriftgen/api_types';

const buildStartDate = (): DataTableColumn<TimeframeRestriction> => ({
	header: Strings.Labels.START_DATE,
	render: ({ start_date: startDate }: TimeframeRestriction): string =>
		getAbbreviatedDate(startDate)
});

export { buildStartDate };
