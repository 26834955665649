import { DisablePatientCommunicationsModal } from 'components/Modals/DisablePatientCommunicationsModal';
import { EnablePatientCommunicationsModal } from 'components/Modals/EnablePatientCommunicationsModal';
import { Form } from 'display';
import noop from 'lib/noop';
import React, { useState } from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const LEGEND = 'Patient Communications';
const FIELD_NAME = 'enablePatientCommunications';
const LABEL = 'Allow creation of vMessages and sharing of vConsults with patients';

interface Operations {
	cancel: () => void;
	confirm: () => void;
}

function EnablePatientCommunications(props: DataFormFieldComponentProps): JSX.Element {
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [operations, setOperations] = useState<Operations>({
		cancel: noop,
		confirm: noop
	});
	const closeModal = () => setIsModalOpen(false);
	const onModalCancel = () => {
		operations.cancel();
		closeModal();
	};
	const onModalConfirm = () => {
		operations.confirm();
		closeModal();
	};

	function onChangeWrapper(_: boolean): Promise<void> {
		return new Promise((resolve, reject): void => {
			setOperations({
				cancel: reject,
				confirm: resolve
			});

			setIsModalOpen(true);
		});
	}

	const Checkbox: DataFormFieldComponent = buildCheckboxField({
		fieldName: FIELD_NAME,
		label: LABEL,
		onChangeWrapper
	});
	const ChickenTestModal = props.data[FIELD_NAME]
		? DisablePatientCommunicationsModal
		: EnablePatientCommunicationsModal;

	return (
		<Form.Group grouped>
			<label htmlFor={FIELD_NAME}>{LEGEND}</label>
			<Checkbox {...props} />
			<ChickenTestModal
				onCancel={onModalCancel}
				onConfirm={onModalConfirm}
				open={isModalOpen}
			/>
		</Form.Group>
	);
}

EnablePatientCommunications.FIELD_NAME = FIELD_NAME;
EnablePatientCommunications.validate = () => null;

export default EnablePatientCommunications;
