import * as React from 'react';

import { Message, Paragraph } from 'display';

function VideoProcessingFailedMessage(): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Failed to process</Message.Header>
			<Paragraph>
				This video has failed to process correctly. Please try recording it again.
			</Paragraph>
		</Message>
	);
}

export default VideoProcessingFailedMessage;
