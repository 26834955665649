import { SubmitButton, CancelButton } from 'components/Buttons';
import * as React from 'react';
import { User } from 'thriftgen/api_types';
import { TimeframeRestrictionCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from './DataFormTypes';
import DataFormWrapper from './DataFormWrapper';
import { DisplaySpecialistName, EndDate, StartDate } from './Fields';
import { getFieldsAndSubfieldNames } from './Fields/helpers';
import { ButtonType } from 'display';

const FIELDS: DataFormFieldComponent[] = [DisplaySpecialistName, StartDate, EndDate];
const EDITABLE_FIELDS: string[] = getFieldsAndSubfieldNames(FIELDS);
const REQUIRED_FIELDS: string[] = getFieldsAndSubfieldNames(FIELDS);

type TimeframeRestrictionFormData = TimeframeRestrictionCamel & { specialist: User };

interface TimeframeRestrictionFormProps {
	initialData: Partial<TimeframeRestrictionFormData>;
	onSubmit: (formData: TimeframeRestrictionFormData) => Promise<void>;
}

function TimeframeRestrictionForm({
	initialData,
	onSubmit
}: TimeframeRestrictionFormProps): JSX.Element {
	async function handleSubmit(data: TimeframeRestrictionFormData): Promise<void> {
		await onSubmit(data);
	}

	function onCancel() {
		history.back();
	}

	function renderCancelButton(): JSX.Element {
		return <CancelButton buttonType={ButtonType.SECONDARY} onClick={onCancel} />;
	}

	return (
		<DataFormWrapper
			data={initialData}
			editableFields={EDITABLE_FIELDS}
			fields={FIELDS}
			requiredFields={REQUIRED_FIELDS}
			SubmitControl={SubmitButton}
			CancelControl={renderCancelButton}
			onSubmit={handleSubmit}
			submitAllFields={true}
		/>
	);
}

export default TimeframeRestrictionForm;
export { TimeframeRestrictionFormData };
