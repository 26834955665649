import * as React from 'react';

import { css } from 'emotion';
import { Paragraph, Heading } from 'display';

import * as iosAddSvg from 'assets/images/ios-add.svg';
import * as iosAddToHomeScreenSvg from 'assets/images/ios-add-to-home-screen.svg';
import * as iosShareSvg from 'assets/images/ios-share.svg';

const styles = {
	body: css`
		align-items: center;
		display: flex;
		flex-direction: row;
	`,
	image: css`
		margin-left: 1em;
	`
};

const ICONS = {
	'ios-add': iosAddSvg,
	'ios-add-to-home-screen': iosAddToHomeScreenSvg,
	'ios-share': iosShareSvg
};

function Step({
	children,
	icon,
	stepNumber
}: {
	children: React.ReactNode | React.ReactNodeArray;
	icon: string;
	stepNumber: number;
}): JSX.Element {
	return (
		<React.Fragment>
			<Heading as={Heading.Type.H2} size={Heading.Size.T4}>
				Step {stepNumber}
			</Heading>
			<div className={styles.body}>
				<div>
					<Paragraph>{children}</Paragraph>
				</div>
				<div>
					<img className={styles.image} src={ICONS[icon]} />
				</div>
			</div>
		</React.Fragment>
	);
}

function StepList({ children }: { children: React.ReactNodeArray }): JSX.Element {
	return <React.Fragment>{children}</React.Fragment>;
}

StepList.Step = Step;

export default StepList;
