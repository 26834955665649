import {
	CaseCamel,
	GetCaseResponseCamel,
	PartnerCamel,
	UserCamel,
	GetCasesResponseCamel
} from 'thriftgen/thriftCamelTypes';
import { CaseComposite } from 'contexts';

function transformCaseToComposite({
	partner,
	theCase,
	users
}: GetCaseResponseCamel): CaseComposite {
	const patient = users.find(({ userId }: UserCamel): boolean => userId === theCase.patientId);

	if (!patient) {
		throw new Error(`Missing patient for case ${theCase.caseId}`);
	}

	return {
		...theCase,
		partner,
		patient
	};
}

function transformCasesToComposite({
	cases,
	partners,
	users
}: GetCasesResponseCamel): CaseComposite[] {
	return cases.map((theCase: CaseCamel): CaseComposite => {
		const partner = partners.find(
			({ partnerId }: PartnerCamel): boolean => partnerId === theCase.partnerId
		);

		if (!partner) {
			throw new Error(`Missing partner for case ${theCase.caseId}`);
		}

		return transformCaseToComposite({
			partner,
			theCase,
			users
		});
	});
}

export { transformCaseToComposite, transformCasesToComposite };
