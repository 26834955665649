//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./api_types');
//HELPER FUNCTIONS AND STRUCTURES

var AthenaService_athena_get_or_create_case_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.AthenaGetOrCreateCaseRequest(args.request);
    }
  }
};
AthenaService_athena_get_or_create_case_args.prototype = {};
AthenaService_athena_get_or_create_case_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.AthenaGetOrCreateCaseRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaService_athena_get_or_create_case_args.prototype.write = function(output) {
  output.writeStructBegin('AthenaService_athena_get_or_create_case_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaService_athena_get_or_create_case_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.AthenaGetOrCreateCaseResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AthenaService_athena_get_or_create_case_result.prototype = {};
AthenaService_athena_get_or_create_case_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.AthenaGetOrCreateCaseResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaService_athena_get_or_create_case_result.prototype.write = function(output) {
  output.writeStructBegin('AthenaService_athena_get_or_create_case_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaService_athena_get_patient_document_list_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.AthenaGetPatientDocumentListRequest(args.request);
    }
  }
};
AthenaService_athena_get_patient_document_list_args.prototype = {};
AthenaService_athena_get_patient_document_list_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.AthenaGetPatientDocumentListRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaService_athena_get_patient_document_list_args.prototype.write = function(output) {
  output.writeStructBegin('AthenaService_athena_get_patient_document_list_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaService_athena_get_patient_document_list_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.AthenaGetPatientDocumentListResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AthenaService_athena_get_patient_document_list_result.prototype = {};
AthenaService_athena_get_patient_document_list_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.AthenaGetPatientDocumentListResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaService_athena_get_patient_document_list_result.prototype.write = function(output) {
  output.writeStructBegin('AthenaService_athena_get_patient_document_list_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaServiceClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
AthenaServiceClient.prototype = {};
AthenaServiceClient.prototype.seqid = function() { return this._seqid; };
AthenaServiceClient.prototype.new_seqid = function() { return this._seqid += 1; };

AthenaServiceClient.prototype.athena_get_or_create_case = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_athena_get_or_create_case(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_athena_get_or_create_case(request);
  }
};

AthenaServiceClient.prototype.send_athena_get_or_create_case = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AthenaService_athena_get_or_create_case_args(params);
  try {
    output.writeMessageBegin('athena_get_or_create_case', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AthenaServiceClient.prototype.recv_athena_get_or_create_case = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AthenaService_athena_get_or_create_case_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('athena_get_or_create_case failed: unknown result');
};

AthenaServiceClient.prototype.athena_get_patient_document_list = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_athena_get_patient_document_list(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_athena_get_patient_document_list(request);
  }
};

AthenaServiceClient.prototype.send_athena_get_patient_document_list = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AthenaService_athena_get_patient_document_list_args(params);
  try {
    output.writeMessageBegin('athena_get_patient_document_list', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AthenaServiceClient.prototype.recv_athena_get_patient_document_list = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AthenaService_athena_get_patient_document_list_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('athena_get_patient_document_list failed: unknown result');
};
var AthenaServiceProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
AthenaServiceProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
AthenaServiceProcessor.prototype.process_athena_get_or_create_case = function(seqid, input, output) {
  var args = new AthenaService_athena_get_or_create_case_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.athena_get_or_create_case.length === 1) {
    Q.fcall(this._handler.athena_get_or_create_case.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AthenaService_athena_get_or_create_case_result({success: result});
      output.writeMessageBegin("athena_get_or_create_case", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AthenaService_athena_get_or_create_case_result(err);
        output.writeMessageBegin("athena_get_or_create_case", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("athena_get_or_create_case", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.athena_get_or_create_case(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AthenaService_athena_get_or_create_case_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("athena_get_or_create_case", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("athena_get_or_create_case", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AthenaServiceProcessor.prototype.process_athena_get_patient_document_list = function(seqid, input, output) {
  var args = new AthenaService_athena_get_patient_document_list_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.athena_get_patient_document_list.length === 1) {
    Q.fcall(this._handler.athena_get_patient_document_list.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AthenaService_athena_get_patient_document_list_result({success: result});
      output.writeMessageBegin("athena_get_patient_document_list", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AthenaService_athena_get_patient_document_list_result(err);
        output.writeMessageBegin("athena_get_patient_document_list", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("athena_get_patient_document_list", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.athena_get_patient_document_list(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AthenaService_athena_get_patient_document_list_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("athena_get_patient_document_list", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("athena_get_patient_document_list", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
