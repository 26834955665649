import RedactForm, { RedactFormData } from 'components/Forms/RedactForm';
import { CardModal, SemanticICONS } from 'display';
import { DocumentDataAsset } from 'lib/documentAssets';
import React, { useState } from 'react';
import { useConsultContext } from 'contexts';
import { useLazyAsyncCall } from 'hooks';
import ModalPortal from 'components/Modals/ModalPortal';

interface DocumentRedactActionProps {
	data: DocumentDataAsset;
	modalPortalId: string;
	children: React.ReactNode;
}

function DocumentRedactAction({
	data,
	modalPortalId,
	children
}: DocumentRedactActionProps): JSX.Element | null {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const openModal = () => {
		setModalOpen(true);
	};
	const closeModal = () => setModalOpen(false);

	const { consult, redactConsultData } = useConsultContext();
	const { dispatch: sendRedactConsultData, error } = useLazyAsyncCall(redactConsultData);

	async function onSubmit(formData: RedactFormData): Promise<void> {
		await sendRedactConsultData({
			consultId: consult.consult_id,
			consultDataId: data.id,
			redactedReason: formData.redactedReason
		}).promise();
		return closeModal();
	}

	return (
		<React.Fragment>
			<ModalPortal id={modalPortalId}>
				<CardModal open={modalOpen} data-testid={`messageRedactionModal-${data.id}`}>
					<CardModal.Title>Select reason for redaction</CardModal.Title>
					{modalOpen && (
						<RedactForm error={error} onDone={closeModal} onSubmit={onSubmit} />
					)}
				</CardModal>
			</ModalPortal>
			<div onClick={openModal}>{children}</div>
		</React.Fragment>
	);
}

DocumentRedactAction.ICON = 'trash' as SemanticICONS;
DocumentRedactAction.TITLE = 'Redact';

export default DocumentRedactAction;
