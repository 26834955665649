import * as React from 'react';
import { User } from 'thriftgen/api_types';
import DataTable, { DataTableColumn, DataTableProps } from '../DataTable';
import userColumns from './columns';
import userFilters from './filters';
import Strings from 'lib/strings';

interface UserTableProps {
	columns: Array<DataTableColumn<User>>;
	setRowProps?: DataTableProps<User>['setRowProps'];
	users: User[];
}

function UserTable({ users, columns, setRowProps }: UserTableProps): JSX.Element {
	return (
		<DataTable<User>
			columns={columns}
			data={users}
			emptyMessage={Strings.Sentences.NO_USERS_FOUND}
			setRowProps={setRowProps}
		/>
	);
}

UserTable.Columns = userColumns;
UserTable.Filters = userFilters;

export default UserTable;
