import { getPartners } from 'api/common';
import { infrequentRefresh } from 'hooks/queries/configs';
import { responseInterface } from 'swr';
import { GetPartnersResponse, Partner } from 'thriftgen/api_types';
import { GetPartnersRequestCamel } from 'thriftgen/thriftCamelTypes';
import useQuery from '../useQuery';

interface PartnersQuery {
	partners: Partner[] | undefined;
	error: responseInterface<GetPartnersResponse, Error>['error'];
	revalidate: () => Promise<void>;
}

function usePartnersQuery(args?: GetPartnersRequestCamel): PartnersQuery {
	const key = ['partners', args];
	const { data, error, mutate } = useQuery<GetPartnersResponse>(
		key,
		(_: string, params: GetPartnersRequestCamel) => getPartners(params),
		infrequentRefresh
	);

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		partners: data ? data.partners : undefined,
		error,
		revalidate
	};
}

export { PartnersQuery, usePartnersQuery };
