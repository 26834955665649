import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'display';
import Strings from 'lib/strings';

function BackButton({ disabled, onClick }) {
	return (
		<Button.Minimal
			className="backButton"
			disabled={disabled}
			onClick={onClick}
			size={Button.Size.TINY}>
			{Strings.Buttons.BACK}
		</Button.Minimal>
	);
}

BackButton.defaultProps = {
	disabled: false
};

BackButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired
};

export default BackButton;
