import * as React from 'react';
import { MediaDeviceAccessError } from 'lib/mediaDevices';

import Launcher from './Launcher';
import {
	LinkToMacOsCameraPreferences,
	LinkToMacOsScreenPreferences,
	TryAgainButton,
	HelpButton
} from 'components/Buttons';
import { MediaDeviceError } from 'components/Messages';
import { ButtonType } from 'display';

interface RecorderSetupErrorProps {
	error: MediaDeviceAccessError | null;
	onRetry: () => void;
}

function getActionsForError(
	error: RecorderSetupErrorProps['error'],
	onRetry: RecorderSetupErrorProps['onRetry']
): JSX.Element[] {
	switch (error) {
		case MediaDeviceAccessError.WEBCAM_SYSTEM_NOT_ALLOWED:
			return [
				<HelpButton buttonType={ButtonType.MINIMAL} key="help" />,
				<LinkToMacOsCameraPreferences key="camera-preferences" />
			];

		case MediaDeviceAccessError.SCREEN_SYSTEM_NOT_ALLOWED:
			return [
				<HelpButton buttonType={ButtonType.MINIMAL} key="help" />,
				<LinkToMacOsScreenPreferences key="screen-preferences" />
			];
	}

	return [
		<HelpButton buttonType={ButtonType.MINIMAL} key="help" />,
		<TryAgainButton key="try-again" onClick={onRetry} />
	];
}

function RecorderSetupError({ error, onRetry }: RecorderSetupErrorProps): JSX.Element {
	const actions = getActionsForError(error, onRetry);

	return <Launcher actions={actions} content={<MediaDeviceError error={error} />} title={null} />;
}

export default RecorderSetupError;
