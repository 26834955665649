import * as React from 'react';

import { DisplaySpecialtiesField } from './DisplaySpecialtiesField';
import { EditSpecialtiesField } from './EditSpecialtiesField';
import { FIELD_NAME, getValue } from './helpers';
import { SpecialtiesFieldProps } from './types';

function SpecialtiesField({
	editableFields,
	data,
	...rest
}: SpecialtiesFieldProps): JSX.Element | null {
	const value = getValue(data);

	if (editableFields.includes(FIELD_NAME)) {
		return <EditSpecialtiesField data={data} {...rest} />;
	}

	if (value && value.length > 0) {
		return <DisplaySpecialtiesField data={data} {...rest} />;
	}

	return null;
}

SpecialtiesField.FIELD_NAME = FIELD_NAME;
SpecialtiesField.validate = () => null;

export default SpecialtiesField;
