import Partner from 'components/admin/Partner/Partner';
import PartnerSelect from 'components/PartnerSelect/PartnerSelect';
import { Form, Icon } from 'display';
import * as React from 'react';
import { Partner as PartnerThrift } from 'thriftgen/api_types';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import SitkaMedicalAssociate from './SitkaMedicalAssociate';
import SitkaOps from './SitkaOps';

const FIELD_NAME = 'partnerId';
const LABEL = 'Partner';

type PartnerFieldProps = DataFormFieldComponentProps;

function getValue(data: PartnerFieldProps['data']): PartnerFieldProps['data']['partnerId'] {
	return data[FIELD_NAME];
}

function hasError(errors: PartnerFieldProps['errors']): boolean {
	return errors.fields.includes(FIELD_NAME);
}

function canEdit(editableFields: PartnerFieldProps['editableFields']): boolean {
	return editableFields.includes(FIELD_NAME);
}

function validate(
	data: PartnerFieldProps['data'],
	required: boolean
): PartnerFieldProps['errors'] | null {
	const value = getValue(data);
	const isSMASelected = Boolean(data[SitkaMedicalAssociate.FIELD_NAME]);
	const isSitkaOperationsSelected = Boolean(data[SitkaOps.FIELD_NAME]);
	const canBypassPartner = isSMASelected || isSitkaOperationsSelected;

	if (required && !canBypassPartner && !value) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please select a partner.']
		};
	}

	return null;
}

function buildPartner(partners: PartnerThrift[]): DataFormFieldComponent {
	function PartnerField({
		data,
		errors,
		editableFields,
		onChange
	}: PartnerFieldProps): JSX.Element | null {
		const value = getValue(data);

		if (canEdit(editableFields)) {
			return (
				<Form.Field error={hasError(errors)}>
					<label htmlFor={FIELD_NAME}>{LABEL}</label>
					<PartnerSelect
						id={FIELD_NAME}
						name={FIELD_NAME}
						onChange={onChange}
						partnerId={value}
						partners={partners}
					/>
				</Form.Field>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<Icon name="hospital" />
					{` `}
					<Partner partnerId={value} />
				</Form.Field>
			);
		}

		return null;
	}

	PartnerField.FIELD_NAME = FIELD_NAME;
	PartnerField.validate = validate;

	return PartnerField;
}

export default buildPartner;
export { FIELD_NAME, PartnerFieldProps };
