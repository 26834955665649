import React, { useMemo } from 'react';
import { css } from 'emotion';

import { PageDisplayOptions } from 'routing/types';

import Link from 'components/Link';
import LoginButton from 'components/Login/LoginButton';
import MobileMenu from 'components/MobileMenu/MobileMenu';
import { WhitelabelLogo } from 'components/Logos';
import { SitkaLogo } from 'components/Svgs';

import { Menu, Segment } from 'display';
import { colors, modularScale } from 'styles';

import { NavbarItem } from './helpers';

import { useSessionIntegrationSettings } from 'hooks';

const styles = {
	login: css`
		padding-right: 0 !important;
	`,
	logo: css`
		padding-left: 0 !important;
		& img,
		& svg {
			height: 1.5em;
			width: auto;
		}
	`,
	navbar: css`
		margin: 0 0 !important;
		background-color: ${colors.white} !important;

		& a {
			font-size: ${modularScale(1)} !important;
		}

		@media (min-width: 0px) and (max-width: 800px) {
			& .rightMenu {
				display: none !important;
			}
		}
	`
};

interface NavbarProps {
	activeItem?: NavbarItem['name'];
	menuItems?: NavbarItem[];
	pageOptions?: PageDisplayOptions;
}

function Navbar({ activeItem = undefined, menuItems = [], pageOptions }: NavbarProps): JSX.Element {
	const sessionIntegrationSettings = useSessionIntegrationSettings();

	const displayMenuItems = useMemo(() => {
		let items = menuItems;
		if (sessionIntegrationSettings) {
			const { isInIframe, integrationType } = sessionIntegrationSettings;
			if (isInIframe) {
				items = items.filter(({ hideInIframe }) => hideInIframe !== true);
			}
			if (integrationType) {
				items = items.filter(({ hideForIntegrationTypes }) =>
					hideForIntegrationTypes
						? !hideForIntegrationTypes.includes(integrationType)
						: true
				);
			}
		}
		return items;
	}, [menuItems, sessionIntegrationSettings]);

	function renderMenuItem(link: NavbarItem): JSX.Element {
		const { name, url, text } = link;
		return (
			<Menu.Item key={url} active={activeItem === name}>
				<Link to={url}>{text}</Link>
			</Menu.Item>
		);
	}

	function canDisplayLogout() {
		return !sessionIntegrationSettings?.isInIframe;
	}

	function renderMenuItems(): JSX.Element {
		return (
			<React.Fragment>
				<Menu.Menu pointing="true" position="right" className="rightMenu">
					{displayMenuItems.map(renderMenuItem)}
					{canDisplayLogout() && (
						<Menu.Item className={styles.login} position="right">
							<div>
								<LoginButton />
							</div>
						</Menu.Item>
					)}
				</Menu.Menu>
				<MobileMenu
					items={displayMenuItems}
					activeItem={activeItem}
					displayLogout={canDisplayLogout()}
				/>
			</React.Fragment>
		);
	}

	return (
		<Segment data-testid="navbar" basic={true} className={styles.navbar}>
			<Menu size="tiny" secondary={true}>
				<Menu.Item className={styles.logo}>
					<Link to="/">
						{pageOptions && pageOptions.whitelabelLogo ? (
							<WhitelabelLogo />
						) : (
							<SitkaLogo />
						)}
					</Link>
				</Menu.Item>
				{menuItems !== null && renderMenuItems()}
			</Menu>
		</Segment>
	);
}

export default Navbar;
