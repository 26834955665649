import * as React from 'react';

function SourceNotReadable(): JSX.Element {
	return (
		<React.Fragment>
			Make sure that access to your webcam and microphone are not disabled in the system
			settings and that no other application is currently using them.
		</React.Fragment>
	);
}

export default SourceNotReadable;
