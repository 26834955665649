import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

function NoButton({ onClick }: ButtonProps): JSX.Element {
	return (
		<Button.Secondary fluid={true} onClick={onClick}>
			{Strings.Buttons.NO}
		</Button.Secondary>
	);
}

export default NoButton;
