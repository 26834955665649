import { SpecialtiesFieldProps, SpecialtiesValue } from './types';

const FIELD_NAME = 'specialties';

function getValue(data: SpecialtiesFieldProps['data']): SpecialtiesValue {
	return data[FIELD_NAME] || [];
}

function hasError({ fields }: SpecialtiesFieldProps['errors']): boolean {
	return fields.includes(FIELD_NAME);
}

export { FIELD_NAME, getValue, hasError };
