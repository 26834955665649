import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import RequestContext from './RequestContext';
import isObject from 'lib/isObject';
import { registerRequest } from 'store/requests';

function withApiCall(actionToRequestMap, Component) {
	class Wrapper extends React.Component {
		constructor(props) {
			super(props);
			this.prepareRequest();
		}

		prepareRequest() {
			const { request, id } = this.handleActionToRequestMap();

			this.requestId = id;
			this.request = request;
		}

		bindRequest(name, action) {
			const id = Symbol(name);

			// Register the request
			this.props.dispatch(registerRequest({ id, toast: false }));

			/* Bind the request action
			 ** This object is what gets injected onto the passed component.
			 ** Note that it attaches it to namespace that was provided so that is invoked like `this.props.<name>()`
			 */
			const boundAction = {
				[name]: (...actionArgs) => this.props.dispatch(action(id, ...actionArgs))
			};

			return {
				id,
				request: boundAction
			};
		}

		handleActionToRequestMap() {
			if (!isObject(actionToRequestMap)) {
				throw new Error(
					`withApiCall: actionToRequestMap in withApiCall(${
						Component.name
					}) must be an object. Instead received ${typeof actionToRequestMap}.`
				);
			}

			const keys = Object.keys(actionToRequestMap);

			if (keys.length > 1) {
				throw new Error(
					`withApiCall: actionToRequestMap in withApiCall(${Component.name}) can only take one action at a time.`
				);
			}

			const propName = keys[0];
			return this.bindRequest(propName, actionToRequestMap[propName]);
		}

		render() {
			// eslint-disable-next-line
			const { dispatch, ...rest } = this.props;

			return (
				<RequestContext requestId={this.requestId} debounced={true}>
					{requestData => <Component {...this.request} {...rest} request={requestData} />}
				</RequestContext>
			);
		}
	}

	Wrapper.propTypes = {
		dispatch: PropTypes.func.isRequired
	};

	return connect()(Wrapper);
}

withApiCall.requestPropTypes = PropTypes.shape({
	status: PropTypes.string,
	message: PropTypes.string,
	inProgress: PropTypes.bool
});

export default withApiCall;
