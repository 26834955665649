import Strings from 'lib/strings';
import { DataFormFieldComponent } from '../DataFormTypes';
import buildDatePickerField from './base/buildDatePickerField';

const FIELD_NAME = 'expiresAt';
const LABEL = Strings.Labels.EXPIRATION_DATE;

const StartDate: DataFormFieldComponent = buildDatePickerField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default StartDate;
