import { CancelButton } from 'components/Buttons';
import { Button, ButtonProps, ButtonType, Message } from 'display';
import * as React from 'react';
import { RedactedReason } from 'thriftgen/api_types';
import DataFormWrapper from './DataFormWrapper';
import RedactedReasonField from './Fields/RedactedReasonField';
import { getFieldsAndSubfieldNames } from './Fields/helpers';

interface RedactFormData {
	redactedReason: RedactedReason;
}

interface RedactFormProps {
	error?: Error;
	onSubmit: (formData: RedactFormData) => void;
	onDone: () => void;
}

const FIELDS = [RedactedReasonField];
const NAMES = getFieldsAndSubfieldNames(FIELDS);

function CancelControl(onDone: RedactFormProps['onDone']) {
	const cancelControlComponent = (): JSX.Element => (
		<CancelButton buttonType={ButtonType.MINIMAL} onClick={onDone} />
	);
	return cancelControlComponent;
}

function SubmitControl(props: ButtonProps) {
	return <Button.Primary {...props}>Redact</Button.Primary>;
}

function RedactForm({ error, onSubmit, onDone }: RedactFormProps): JSX.Element {
	return (
		<React.Fragment>
			<DataFormWrapper
				data={{
					redactedReason: undefined
				}}
				fields={FIELDS}
				editableFields={NAMES}
				onSubmit={onSubmit}
				requiredFields={NAMES}
				CancelControl={CancelControl(onDone)}
				SubmitControl={SubmitControl}
			/>
			{error && <Message negative>{error.message}</Message>}
		</React.Fragment>
	);
}

export default RedactForm;
export { RedactFormData };
