import { setPartners } from 'store/admin/admin.actions';
import { ThunkAction } from 'store/types';
import { setUsers } from 'store/users';
import { Consult, GetConsultResponse, GetConsultsResponse } from 'thriftgen/api_types';
import { ConsultActionType, SetConsultsAction } from './consults.types';

function setConsults(consults: Consult[]): SetConsultsAction {
	return {
		type: ConsultActionType.SET_CONSULTS,
		consults
	};
}

function handleIncomingConsult(response: GetConsultResponse): ThunkAction<void> {
	return dispatch => {
		dispatch(setUsers(response.users));
		dispatch(setPartners([response.partner]));
		dispatch(setConsults([response.consult]));
	};
}

function handleIncomingConsults(response: GetConsultsResponse): ThunkAction<void> {
	return dispatch => {
		dispatch(setUsers(response.users));
		dispatch(setPartners(response.partners));
		dispatch(setConsults(response.consults));
	};
}

export { handleIncomingConsult, handleIncomingConsults, setConsults };
