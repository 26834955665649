import * as React from 'react';
import { css, cx } from 'emotion';

enum Alignments {
	CENTER = 'center',
	LEFT = 'left'
}

const styles = {
	alignments: {
		[Alignments.CENTER]: css`
			text-align: center;
		`,
		[Alignments.LEFT]: css`
			padding-left: 2em;
			text-align: left;
		`
	},
	divider: css`
		display: block;
		margin: 2em 0;
		overflow: hidden;
		text-align: center;
		white-space: nowrap;

		> span {
			position: relative;
			display: inline-block;
		}

		> span:before,
		> span:after {
			content: '';
			position: absolute;
			top: 50%;
			width: 9999px;
			height: 1px;
			background: #d5d5d5;
		}

		> span:before {
			right: 100%;
			margin-right: 1em;
		}

		> span:after {
			left: 100%;
			margin-left: 1em;
		}
	`
};

function DividerWithText({
	alignment = Alignments.CENTER,
	text
}: {
	alignment?: Alignments;
	text: string;
}): JSX.Element {
	const dividerStyles = cx(styles.divider, styles.alignments[alignment]);

	return (
		<div className={dividerStyles}>
			<span>{text}</span>
		</div>
	);
}

DividerWithText.Alignments = Alignments;

export default DividerWithText;
