import * as React from 'react';
import PropTypes from 'prop-types';
import { css } from 'emotion';

import { Button, Icon } from 'display';
import { SEMANTIC_COLOR_STRINGS } from 'styles/';
import Strings from 'lib/strings';

const styles = {
	container: css`
		& *:first-child {
			margin-right: 0.5em !important;
		}
	`
};

function getButtonContent(expanded) {
	let label;
	let icon;
	if (expanded) {
		label = Strings.Buttons.COLLAPSE;
		icon = 'chevron up';
	} else {
		label = Strings.Buttons.EXPAND;
		icon = 'chevron down';
	}

	return { icon, label };
}

function ExpandCollapseButton({ expanded, customLabel, onClick }) {
	const { label, icon } = getButtonContent(expanded);

	// Handle the click on the container so that the Icon can be clicked too
	// while allow for the label and icon to keep their style. Otherwise,
	// the icon gets underlined if it is a child of the button.
	return (
		<div className={styles.container} onClick={onClick} role="button">
			<Button.TextPrimary size={Button.Size.TINY}>
				{customLabel ? customLabel : label}
			</Button.TextPrimary>
			<Icon name={icon} color={SEMANTIC_COLOR_STRINGS.PRIMARY} />
		</div>
	);
}

ExpandCollapseButton.propTypes = {
	expanded: PropTypes.bool.isRequired,
	customLabel: PropTypes.string,
	onClick: PropTypes.func.isRequired
};

export default ExpandCollapseButton;
