import { Button, ButtonType, CardModal, Message } from 'display';
import Strings from 'lib/strings';
import * as React from 'react';

interface RemoveSpecialistModalProps {
	open: boolean;
	error?: Error;
	loading?: boolean;
	onConfirm: () => void;
	onCancel: () => void;
}

export default function RemoveSpecialistModal({
	open,
	error,
	loading,
	onConfirm,
	onCancel
}: RemoveSpecialistModalProps): JSX.Element {
	const ButtonVariationPrimary = Button[ButtonType.PRIMARY];
	const ButtonVariationTextPrimary = Button[ButtonType.TEXT_PRIMARY];

	return (
		<CardModal open={open} data-testid="RemoveSpecialistModal">
			<CardModal.Title>{Strings.Modals.REMOVE_SPECIALIST_TITLE}</CardModal.Title>
			<CardModal.Description>{Strings.Modals.REMOVE_SPECIALIST_TEXT}</CardModal.Description>
			{!!error && <Message negative>{error.message}</Message>}
			<Button.Group>
				<ButtonVariationTextPrimary onClick={onCancel} size={Button.Size.SMALL}>
					{Strings.Buttons.CANCEL}
				</ButtonVariationTextPrimary>
				<ButtonVariationPrimary
					onClick={onConfirm}
					size={Button.Size.SMALL}
					loading={loading}>
					{Strings.Buttons.REMOVE_SPECIALIST}
				</ButtonVariationPrimary>
			</Button.Group>
		</CardModal>
	);
}
