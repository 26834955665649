import { objectCamelToSnake } from 'lib/objectConverter';
import { getAuthClient } from 'lib/ThriftClient';
import { LoginResponse, OidcLoginRequest } from 'thriftgen/api_types';
import { OidcLoginRequestCamel } from 'thriftgen/thriftCamelTypes';

function oidcLogin(camelRequest: OidcLoginRequestCamel): Promise<LoginResponse> {
	const client = getAuthClient();
	const request: OidcLoginRequest = objectCamelToSnake(camelRequest);

	return client.oidc_login(request);
}

export default oidcLogin;
