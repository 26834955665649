import * as React from 'react';
import { css } from 'emotion';

import { Button, ButtonProps, Icon } from 'display';
import Strings from 'lib/strings';

const styles = {
	button: css`
		cursor: pointer;
	`,
	container: css`
		align-items: center;
		display: flex;
		padding: 1em;
		& > *:first-child {
			margin-right: 0.25em;
		}
	`,
	icon: css`
		margin-right: 0 !important;
		padding-left: 0.5em;
	`
};

function ResendMessageButton({ onClick }: Pick<ButtonProps, 'onClick'>): JSX.Element {
	return (
		<div className={styles.container}>
			<Button.Outline size={Button.Size.TINY} onClick={onClick}>
				{Strings.Buttons.RESEND_MESSAGE}
				<Icon className={styles.icon} name="repeat" />
			</Button.Outline>
		</div>
	);
}

export { ResendMessageButton };
