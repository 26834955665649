import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetUsersRequest, GetUsersResponse, PaginationParameters } from 'thriftgen/api_types';
import { GetUsersRequestCamel } from 'thriftgen/thriftCamelTypes';

function getUsers(params: GetUsersRequestCamel): Promise<GetUsersResponse> {
	const { pageParams, patientIdForConsultCounts } = params;

	const client = getCommonClient();
	const request = new GetUsersRequest({
		page_params: new PaginationParameters(objectCamelToSnake(pageParams)),
		patient_id_for_consult_counts: patientIdForConsultCounts
	});

	return client.get_users(request);
}

export default getUsers;
