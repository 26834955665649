import * as React from 'react';

import { Message, Paragraph } from 'display';

function SubmissionError({ message }: { message: string }): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>There was an error submitting your request.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default SubmissionError;
