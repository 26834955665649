import { ConsultDataState } from 'thriftgen/api_types';
import { PartnerCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from '../DataFormTypes';
import buildImageUpload from './base/buildImageUpload';

type BuildPartnerLogoProps = Pick<PartnerCamel, 'logoState' | 'logoUrl'>;

const LABEL = 'Logo';
const FIELD_NAME = 'logoUploadKey';

function getEmptyMessage(logoState?: PartnerCamel['logoState']): string {
	switch (logoState) {
		case ConsultDataState.PROCESSING:
			return 'A logo has been added but it is still being processed. It will be available shortly.';
		case ConsultDataState.FAILED:
			return 'A logo has been added but it failed to process. Please try again.';
		default:
			return 'No logo has been added yet.';
	}
}

function buildPartnerLogo(props?: BuildPartnerLogoProps): DataFormFieldComponent {
	if (!props) {
		return buildImageUpload({
			currentUrl: undefined,
			label: LABEL,
			fieldName: FIELD_NAME,
			emptyMessage: getEmptyMessage()
		});
	}

	const { logoState, logoUrl } = props;

	return buildImageUpload({
		currentUrl: logoState === ConsultDataState.READY ? logoUrl : undefined,
		label: LABEL,
		fieldName: FIELD_NAME,
		emptyMessage: getEmptyMessage(logoState)
	});
}

export default buildPartnerLogo;
export { BuildPartnerLogoProps };
