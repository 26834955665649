import { USER_FILTERS } from 'api/customFilters';
import { PartnerAssociation } from 'api/types';
import { Filter } from 'hooks/pagination';
import * as React from 'react';
import { Partner } from 'thriftgen/api_types';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import StaffList from './StaffList';

interface BuildStaff {
	fieldName: string;
	filter:
		| typeof USER_FILTERS.ASSIGNABLE_AS_PARTNER_MEMBER
		| typeof USER_FILTERS.ASSIGNABLE_AS_PARTNER_ADMIN;
	label: string;
	partnerId: Partner['partner_id'];
}

function buildStaffList({
	fieldName,
	label,
	filter,
	partnerId
}: BuildStaff): DataFormFieldComponent {
	const filters: Filter[] = [
		{
			attribute: filter,
			value: partnerId
		}
	];

	function PartnerStaffList(props: DataFormFieldComponentProps): JSX.Element {
		return <StaffList filters={filters} fieldName={fieldName} label={label} {...props} />;
	}

	PartnerStaffList.FIELD_NAME = fieldName;
	PartnerStaffList.validate = () => null;

	return PartnerStaffList;
}

function buildStaff(
	role: PartnerAssociation,
	partnerId: BuildStaff['partnerId']
): DataFormFieldComponent {
	if (role === PartnerAssociation.ADMIN) {
		return buildStaffList({
			label: 'Add admins',
			filter: USER_FILTERS.ASSIGNABLE_AS_PARTNER_ADMIN,
			fieldName: 'adminIds',
			partnerId
		});
	}

	return buildStaffList({
		label: 'Add members',
		filter: USER_FILTERS.ASSIGNABLE_AS_PARTNER_MEMBER,
		fieldName: 'memberIds',
		partnerId
	});
}

export default buildStaff;
