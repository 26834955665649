import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import buildTextFieldComponent from 'components/Forms/Fields/base/buildTextField';

const FIELD_NAME = 'specialty';
const FIELD_LABEL = 'SMA Specialty';

const SMASpecialty: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL
});

export default SMASpecialty;
