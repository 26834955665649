import { DataTableColumn } from 'components/DataTable/DataTable';
import { Icon } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import * as React from 'react';
import { mediaQueries } from 'styles';

const styles = {
	newMessageIcon: css`
		position: absolute;
		top: 14px;
		left: -9px;

		${mediaQueries.desktop} {
			position: static;
		}
	`
};

export default (): DataTableColumn<ConsultComposite> => ({
	render: ({ hasNewMessages }: ConsultComposite) => (
		<div className={styles.newMessageIcon}>
			{hasNewMessages && <Icon name="circle" color="orange" aria-label="New Message"></Icon>}
		</div>
	)
});
