import { ToggleListButton } from 'components/Buttons';
import { DocumentAssetSelection } from 'components/Lists/AssetList';
import { DocumentDataAsset, isDocumentDataViewable } from 'lib/documentAssets';
import React, { useState } from 'react';
import { isDesktopViewportWidth } from 'styles';
import DocumentNavigationButtons from './DocumentNavigationButtons';
import DocumentSidebarHeader from './DocumentSidebarHeader';
import DocumentViewerContainer from './DocumentViewerContainer';

interface DocumentViewerNavigationProps {
	children: React.ReactNode;
	currentDocument: DocumentDataAsset | undefined;
	disabled: boolean;
	documents: DocumentDataAsset[];
	onDocumentChange: (document: DocumentDataAsset) => void;
}

function findContiguousReadyAsset(
	assets: DocumentDataAsset[],
	asset: DocumentDataAsset | undefined
): DocumentDataAsset | undefined {
	if (asset === undefined || assets.length < 1) {
		return undefined;
	}

	const sliceStart = assets.findIndex(({ id }) => asset.id === id) + 1;
	const slice = assets.slice(sliceStart);
	return slice.find(item => item.isReady && isDocumentDataViewable(item));
}

function DocumentViewerNavigation({
	children,
	currentDocument,
	disabled,
	documents,
	onDocumentChange
}: DocumentViewerNavigationProps): JSX.Element {
	const [open, setOpen] = useState<boolean>(isDesktopViewportWidth());
	const toggleNavigation = () => setOpen(open => !open);
	const hideNavigation = () => setOpen(false);
	const triggerTitle = open ? 'Hide List' : 'View Documents';

	const nextDocument = findContiguousReadyAsset(documents, currentDocument);
	const previousDocument = findContiguousReadyAsset(documents.slice().reverse(), currentDocument);

	const isNextDisabled = disabled || nextDocument === undefined;
	const isPreviousDisabled = disabled || previousDocument === undefined;

	function setNextDocument(): void {
		if (nextDocument) {
			onDocumentChange(nextDocument);
		}
	}

	function setPreviousDocument(): void {
		if (previousDocument) {
			onDocumentChange(previousDocument);
		}
	}

	function setSelectedDocument(document: DocumentDataAsset): void {
		onDocumentChange(document);
		if (!isDesktopViewportWidth()) {
			hideNavigation();
		}
	}

	function renderSidebar(): JSX.Element {
		return (
			<React.Fragment>
				<DocumentSidebarHeader />
				<DocumentAssetSelection
					documents={documents}
					onSelect={setSelectedDocument}
					selectedDocument={currentDocument}
					disabled={disabled}
				/>
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			<DocumentViewerContainer
				open={open}
				trigger={<ToggleListButton onClick={toggleNavigation} title={triggerTitle} />}
				sidebar={renderSidebar()}
				viewer={children}
			/>
			<DocumentNavigationButtons
				disableNext={isNextDisabled}
				disablePrevious={isPreviousDisabled}
				onNextClick={setNextDocument}
				onPreviousClick={setPreviousDocument}
			/>
		</React.Fragment>
	);
}

export default DocumentViewerNavigation;
export { DocumentViewerNavigationProps };
