import { Form, FormGroupProps } from 'display';
import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import { getFieldsAndSubfieldNames } from '../helpers';

interface BuildFieldGroupProps {
	fieldName: string;
	fields: DataFormFieldComponent[];
	groupProps: FormGroupProps;
	label: string;
}

function buildFieldGroup({
	fieldName,
	fields,
	label,
	groupProps
}: BuildFieldGroupProps): DataFormFieldComponent {
	function FieldGroup(props: DataFormFieldComponentProps) {
		return (
			<Form.Group {...groupProps}>
				<label>{label}</label>
				{fields.map(Field => (
					<Field key={Field.FIELD_NAME} {...props} />
				))}
			</Form.Group>
		);
	}

	FieldGroup.FIELD_NAME = fieldName;
	FieldGroup.SUB_FIELD_NAMES = getFieldsAndSubfieldNames(fields);
	FieldGroup.displayName = `FieldGroup-${fieldName}`;
	FieldGroup.validate = () => null;

	return FieldGroup;
}

export default buildFieldGroup;
