import * as React from 'react';
import { UserCamel } from 'thriftgen/thriftCamelTypes';

import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = UserCamel['patientExternalId'] | null;
type DisplayPatientIdComponent = DataFormFieldComponent<string, DataType>;
type DisplayPatientIdComponentProps = DataFormFieldComponentProps<DataType>;

const DisplayPatientId: DisplayPatientIdComponent = ({
	data
}: DisplayPatientIdComponentProps): JSX.Element => {
	return <ReadOnlyField label="Patient ID">{data[DisplayPatientId.FIELD_NAME]}</ReadOnlyField>;
};

DisplayPatientId.FIELD_NAME = 'patientExternalId';
DisplayPatientId.validate = alwaysValid;

export default DisplayPatientId;
