import { getCommonClient } from 'lib/ThriftClient';
import { GetConsultRequest, GetConsultResponse } from 'thriftgen/api_types';
import { GetConsultRequestCamel } from 'thriftgen/thriftCamelTypes';

function getConsult({ consultId }: GetConsultRequestCamel): Promise<GetConsultResponse> {
	const client = getCommonClient();
	const request = new GetConsultRequest({
		consult_id: consultId
	});

	return client.get_consult(request);
}

export default getConsult;
