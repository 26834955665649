import * as React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';
import { css } from 'emotion';
import { modularScale, colors } from 'styles';
import { fromEvent } from 'file-selector';

const styles = {
	container: css`
		margin-top: 2em;
	`,
	label: css`
		border: 2px dashed ${colors.primary.base};
		border-radius: 4px;
		display: flex;
		font-size: ${modularScale(1)};
		height: 150px;
		max-width: 250px;
		text-align: center;
		transition: all 250ms ease-in;
		width: 100%;

		& div {
			align-items: center;
			display: flex;
			height: 100%;
			justify-content: center;
			margin: auto;
			width: 100%;
		}
	`
};

const FileDropzone = ({ onFileDrop, label, inputProps }) => (
	<div className={styles.container}>
		<Dropzone onDrop={onFileDrop} getDataTransferItems={evt => fromEvent(evt)}>
			{({ getInputProps, getRootProps, isDragActive }) => (
				<div
					className={styles.label}
					style={{ backgroundColor: isDragActive ? colors.primaryLighter : undefined }}>
					<div {...getRootProps()}>
						<input {...getInputProps(inputProps)} />
						<p>{label}</p>
					</div>
				</div>
			)}
		</Dropzone>
	</div>
);

FileDropzone.getDefaultProps = {
	inputProps: {},
	label: 'Or drag files here'
};

FileDropzone.propTypes = {
	inputProps: PropTypes.object,
	label: PropTypes.string,
	onFileDrop: PropTypes.func.isRequired
};

export default FileDropzone;
