import { dispatchEvent, SitkaEvent } from 'lib/events';
import { SitkaLogger } from 'lib/sitkaLogger';

const BEFORE_UNLOAD_EVENT = 'beforeunload';

export default class BaseUploader {
	static EVENTS = {
		BEFORE_UNLOAD: 'before_unload',
		COMPLETE: 'complete',
		ERROR: 'error',
		PROGRESS: 'progress',
		START: 'start'
	};

	constructor() {
		this.beforeUnloadEventSet = false;
		this.uploadCount = 0;
		this.uploads = [];

		this.alertOngoingUpload = this.alertOngoingUpload.bind(this);
		this.listeners = {
			[BaseUploader.EVENTS.BEFORE_UNLOAD]: [],
			[BaseUploader.EVENTS.COMPLETE]: [],
			[BaseUploader.EVENTS.ERROR]: [],
			[BaseUploader.EVENTS.PROGRESS]: [],
			[BaseUploader.EVENTS.START]: []
		};
	}

	addBeforeUnloadListener(callback) {
		this.addEventListener(BaseUploader.EVENTS.BEFORE_UNLOAD, callback);
	}

	addEventListener(event, callback) {
		this.listeners[event].push(callback);
	}

	addUploadCompleteListener(callback) {
		this.addEventListener(BaseUploader.EVENTS.COMPLETE, callback);
	}

	addUploadErrorListener(callback) {
		this.addEventListener(BaseUploader.EVENTS.ERROR, callback);
	}

	addUploadStartListener(callback) {
		this.addEventListener(BaseUploader.EVENTS.START, callback);
	}

	addUploadProgressListener(callback) {
		this.addEventListener(BaseUploader.EVENTS.PROGRESS, callback);
	}

	alertOngoingUpload(event) {
		// This is the standard set up for a beforeunload event handling
		event.preventDefault();
		event.returnValue = '';

		this.emit(BaseUploader.EVENTS.BEFORE_UNLOAD);
	}

	emit(event, data) {
		dispatchEvent(SitkaEvent.UPLOADING);
		this.listeners[event].forEach(callback => callback(data));
	}

	emitError(error) {
		this.emit(BaseUploader.EVENTS.ERROR, error);

		const errorNotification = {
			error: error.message || error
		};

		if (error.code) {
			errorNotification.params = {
				code: error.code
			};
		}

		this.removeBeforeUnloadListener();

		SitkaLogger.logMessage(errorNotification);
	}

	emitProgress() {
		if (!this.uploads.length) {
			return null;
		}

		this.emit(BaseUploader.EVENTS.PROGRESS, this.getProgress());
	}

	getProgress() {
		return this.uploads.reduce((a, b) => ({
			total: a.total + b.total,
			uploaded: a.uploaded + b.uploaded
		}));
	}

	getUploads() {
		return this.uploads.map(({ upload }) => upload);
	}

	registerBeforeUnloadListener() {
		if (!this.beforeUnloadEventSet) {
			window.addEventListener(BEFORE_UNLOAD_EVENT, this.alertOngoingUpload);
			this.beforeUnloadEventSet = true;
		}
	}

	removeBeforeUnloadListener() {
		if (this.beforeUnloadEventSet) {
			window.removeEventListener(BEFORE_UNLOAD_EVENT, this.alertOngoingUpload);
			this.beforeUnloadEventSet = false;
		}
	}

	removeUploadEventListeners(events) {
		events.forEach(event => {
			switch (event) {
				case BaseUploader.EVENTS.PROGRESS:
				case BaseUploader.EVENTS.START:
				case BaseUploader.EVENTS.ERROR:
				case BaseUploader.EVENTS.COMPLETE:
				case BaseUploader.EVENTS.END:
					this.listeners[event] = [];
					break;
				case BaseUploader.EVENTS.BEFORE_UNLOAD:
					this.listeners[event] = [];
					this.removeBeforeUnloadListener();
					break;
			}
		});
	}

	reset() {
		this.removeUploadEventListeners(Object.values(BaseUploader.EVENTS));
		this.uploadCount = 0;
		this.uploads = [];
	}
}
