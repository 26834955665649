import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ObjectType } from 'thriftgen/api_types';

import { sendReportEvent } from 'store/all';
import { selectAuthUser } from 'store/auth';

function withEventReporter(ReportingComponent) {
	class Wrapper extends React.Component {
		constructor(props) {
			super(props);

			this.reportEvent = this.reportEvent.bind(this);
		}

		reportEvent({ eventType, action }) {
			const { actorId } = this.props;

			if (actorId !== null) {
				this.props.sendReportEvent({
					event: { eventType, action },
					objectId: actorId,
					objectType: ObjectType.USER
				});
			}
		}

		render() {
			const {
				actorId: actorIdIgnored,
				dispatch: dispatchIgnored,
				sendReportEvent: sendReportEventIgnored,
				...rest
			} = this.props;

			const props = {
				...rest,
				reportEvent: this.reportEvent
			};

			return <ReportingComponent {...props} />;
		}
	}

	Wrapper.defaultProps = {
		actorId: null
	};

	Wrapper.propTypes = {
		actorId: PropTypes.string,
		dispatch: PropTypes.func,
		sendReportEvent: PropTypes.func.isRequired
	};

	const mapDispatchToProps = dispatch => ({
		sendReportEvent: (...args) => dispatch(sendReportEvent(...args))
	});

	const mapStoreToProps = store => ({
		actorId: selectAuthUser(store).user_id
	});

	return connect(mapStoreToProps, mapDispatchToProps)(Wrapper);
}

export default withEventReporter;
