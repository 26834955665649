// Adopted from https://github.com/evictor/get-blob-duration
function getVideoBlobDurationInSeconds(blob: Blob): Promise<number> {
	const temporaryVideoElement = document.createElement('video');

	const durationPromise = new Promise<number>(
		(resolve: (value: number) => void, reject: (reason: Error | ErrorEvent) => void): void => {
			temporaryVideoElement.addEventListener('error', (error: ErrorEvent): void => {
				reject(error);
			});

			temporaryVideoElement.addEventListener('loadedmetadata', (): void => {
				// Chrome bug: https://bugs.chromium.org/p/chromium/issues/detail?id=642012
				// This issue is marked as WILL NOT FIX
				if (temporaryVideoElement.duration === Infinity) {
					temporaryVideoElement.currentTime = Number.MAX_SAFE_INTEGER;

					temporaryVideoElement.ontimeupdate = (): void => {
						temporaryVideoElement.ontimeupdate = null;

						resolve(temporaryVideoElement.duration);

						temporaryVideoElement.currentTime = 0;
					};
				} else {
					resolve(temporaryVideoElement.duration);
				}
			});
		}
	);

	temporaryVideoElement.src = URL.createObjectURL(blob);

	return durationPromise;
}

export { getVideoBlobDurationInSeconds };
