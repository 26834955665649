import { AuthenticatedRoute } from 'components/Routing/renderers';
import {
	isAnyAdmin,
	isNotPatient,
	isPartnerStaff,
	isPatient,
	isSitkaMedicalAssociate,
	isSitkaOperations,
	or
} from 'routing/restrictions';
import RouteRegistry from 'routing/RouteRegistry';
import RouteUrls from 'routing/RouteUrls';
import { ConfigurablePageParts } from 'routing/types';
import { isFeatureEnabled, Feature } from 'lib/feature';

const authenticatedRoutes = new RouteRegistry({
	renderer: AuthenticatedRoute,
	routes: {
		[RouteUrls.Key.ADD_TO_HOMESCREEN]: {
			canSafelyReload: true,
			pageOptions: {
				excludeParts: [ConfigurablePageParts.FOOTER, ConfigurablePageParts.NAVBAR]
			},
			template: () => import('pages/AddToHomescreenPage'),
			enableTelemetry: true,
			path: '/add-to-homescreen'
		},
		[RouteUrls.Key.CASE]: {
			template: () => import('routing/routers/CaseRouter'),
			enableTelemetry: true,
			path: '/cases/:caseId/*',
			routes: {
				[RouteUrls.Key.CASE_DATA_VIEWER]: {
					enableTelemetry: true,
					path: '/cases/:caseId/viewer',
					template: () => import('pages/FileViewerPage/CaseFileViewerPage'),
					pageOptions: {
						excludeParts: [
							ConfigurablePageParts.FOOTER,
							ConfigurablePageParts.MENU,
							ConfigurablePageParts.NAVBAR,
							ConfigurablePageParts.PAGE_STYLES
						]
					},
					restrictedTo: isNotPatient
				},
				[RouteUrls.Key.CASE_ADD_DATA]: {
					enableTelemetry: true,
					path: '/cases/:caseId/add-case-data',
					restrictedTo: or(isPartnerStaff, isSitkaOperations),
					template: () => import('pages/Editors/AddCaseDataPage')
				},
				[RouteUrls.Key.CASE_DETAIL]: {
					enableTelemetry: true,
					path: '/cases/:caseId/detail',
					restrictedTo: or(isPartnerStaff, isSitkaOperations),
					template: () => import('pages/CaseDetailPage')
				},
				[RouteUrls.Key.CASE_EDIT_PATIENT]: {
					template: () => import('pages/Editors/EditCasePatientPage'),
					enableTelemetry: true,
					path: '/cases/:caseId/edit-patient',
					restrictedTo: or(isPartnerStaff, isSitkaOperations)
				},
				[RouteUrls.Key.CASE_REQUEST_CONSULT]: {
					template: () => import('pages/CaseRequestConsultPage'),
					enableTelemetry: true,
					path: '/cases/:caseId/request-consult',
					restrictedTo: or(isPartnerStaff, isSitkaOperations)
				},
				[RouteUrls.Key.CASE_PATIENT_CONVERSATION]: {
					enableTelemetry: true,
					path: '/cases/:caseId/patient-conversation',
					restrictedTo: or(isSitkaOperations, isPatient),
					template: () => import('pages/CasePatientConversationPage'),
					pageOptions: {
						excludeParts: [ConfigurablePageParts.MENU],
						whitelabelLogo: true,
						whitelabelFooter: true
					}
				}
			}
		},
		[RouteUrls.Key.CONSULT]: {
			template: () => import('routing/routers/ConsultRouter'),
			enableTelemetry: true,
			path: '/consults/:consultId/*',
			routes: {
				[RouteUrls.Key.CONSULT_ADD_DATA]: {
					enableTelemetry: true,
					path: '/consults/:consultId/add-consult-data',
					restrictedTo: or(isPartnerStaff, isSitkaMedicalAssociate, isSitkaOperations),
					template: () => import('pages/Editors/AddConsultDataPage')
				},
				[RouteUrls.Key.CONSULT_CLINICAL_REVIEW]: {
					enableTelemetry: true,
					path: '/consults/:consultId/clinical-review',
					restrictedTo: isSitkaOperations,
					template: () => import('pages/ConsultFeedback/ConsultClinicalReviewPage')
				},
				[RouteUrls.Key.CONSULT_DETAIL]: {
					enableTelemetry: true,
					path: '/consults/:consultId/detail',
					restrictedTo: isNotPatient,
					template: () => import('pages/ConsultDetailPage')
				},
				[RouteUrls.Key.CONSULT_EDIT_PATIENT]: {
					template: () => import('pages/Editors/EditConsultPatientPage'),
					enableTelemetry: true,
					path: '/consults/:consultId/edit-patient',
					restrictedTo: or(isPartnerStaff, isSitkaOperations)
				},
				[RouteUrls.Key.CONSULT_EDIT_REQUESTOR]: {
					template: () => import('pages/Editors/EditRequestorPage'),
					enableTelemetry: true,
					path: '/consults/:consultId/edit-requestor',
					restrictedTo: isAnyAdmin
				},
				[RouteUrls.Key.CONSULT_EDIT_SPECIALIST]: {
					template: () => import('pages/Editors/EditSpecialistPage'),
					enableTelemetry: true,
					path: '/consults/:consultId/edit-specialist',
					restrictedTo: isAnyAdmin
				},
				[RouteUrls.Key.CONSULT_EDIT_SPECIALTY]: {
					template: () => import('pages/Editors/EditSpecialtyPage'),
					enableTelemetry: true,
					path: '/consults/:consultId/edit-specialty',
					restrictedTo: isAnyAdmin
				},
				[RouteUrls.Key.CONSULT_DATA_VIEWER]: {
					enableTelemetry: true,
					path: '/consults/:consultId/viewer',
					template: () => import('pages/FileViewerPage/ConsultFileViewerPage'),
					pageOptions: {
						excludeParts: [
							ConfigurablePageParts.FOOTER,
							ConfigurablePageParts.MENU,
							ConfigurablePageParts.NAVBAR,
							ConfigurablePageParts.PAGE_STYLES
						]
					},
					restrictedTo: isNotPatient
				},
				[RouteUrls.Key.CONSULT_SHARED_MESSAGES]: {
					canSafelyReload: true,
					pageOptions: {
						excludeParts: [ConfigurablePageParts.NAVBAR, ConfigurablePageParts.FOOTER]
					},
					enableTelemetry: true,
					path: '/consults/:consultId/shared-messages',
					restrictedTo: or(isPatient, isSitkaOperations),
					template: () => import('pages/SharedMessages')
				},
				[RouteUrls.Key.CONSULT_REQUEST_DRAFT]: {
					canSafelyReload: true,
					template: () => import('pages/RequestConsultationPage'),
					enableTelemetry: true,
					path: '/consults/:consultId/edit-draft',
					restrictedTo: or(isPartnerStaff, isSitkaOperations)
				},
				[RouteUrls.Key.CONSULT_SMA_RESPONSE]: {
					canSafelyReload: true,
					template: () => import('pages/ConsultFeedback/ConsultSMAResponsePage'),
					enableTelemetry: true,
					path: '/consults/:consultId/sma-response',
					restrictedTo: or(isSitkaMedicalAssociate, isSitkaOperations)
				}
			}
		},
		[RouteUrls.Key.CONSULT_SUMMARY_DASHBOARD]: {
			path: '/consult-summary-dashboard',
			template: () => import('pages/ConsultSummaryDashboardPage'),
			enableTelemetry: true,
			restrictedTo: isSitkaOperations
		},
		[RouteUrls.Key.CASE_CREATE]: {
			canSafelyReload: true,
			template: () => import('pages/NewCasePage'),
			enableTelemetry: true,
			path: '/cases/new',
			restrictedTo: or(isPartnerStaff, isSitkaOperations)
		},
		[RouteUrls.Key.HELP]: {
			enableTelemetry: true,
			path: '/help',
			template: () => import('pages/HelpPage')
		},
		[RouteUrls.Key.HOME]: {
			enableTelemetry: true,
			path: '/',
			template: () => import('pages/Home')
		},
		[RouteUrls.Key.LOGOUT]: {
			pageOptions: {
				excludeParts: [ConfigurablePageParts.MENU]
			},
			template: () => import('pages/LogoutPage'),
			enableTelemetry: true,
			path: '/logout'
		},
		[RouteUrls.Key.MY_ACCOUNT]: {
			canSafelyReload: true,
			template: () => import('pages/MyAccountPage'),
			enableTelemetry: true,
			path: '/my-account',
			restrictedTo: isNotPatient
		},
		[RouteUrls.Key.MY_ACCOUNT_EDIT]: {
			canSafelyReload: true,
			template: () => import('pages/Editors/EditMyAccountPage'),
			enableTelemetry: true,
			path: '/my-account/edit',
			restrictedTo: isNotPatient
		},
		[RouteUrls.Key.MY_CASES]: {
			canSafelyReload: true,
			template: () => import('pages/MyCasesPage'),
			enableTelemetry: true,
			path: '/my-cases',
			restrictedTo: or(isPartnerStaff, isSitkaOperations)
		},
		[RouteUrls.Key.DASHBOARD_CLINICAL]: {
			canSafelyReload: true,
			template: () => import('pages/DashboardClinicalPage'),
			enableTelemetry: true,
			path: '/dashboard-clinical',
			restrictedTo: isSitkaOperations,
			isDisabled: () => !isFeatureEnabled(Feature.CWA_597_CLINICAL_REVIEW)
		},
		[RouteUrls.Key.DASHBOARD_PCP]: {
			canSafelyReload: true,
			template: () => import('pages/DashboardPCPPage'),
			enableTelemetry: true,
			path: '/dashboard-pcp',
			restrictedTo: isPartnerStaff
		},
		[RouteUrls.Key.DASHBOARD_SMA]: {
			canSafelyReload: true,
			template: () => import('pages/DashboardSMAPage'),
			enableTelemetry: true,
			path: '/dashboard-sma',
			restrictedTo: isSitkaMedicalAssociate
		},

		[RouteUrls.Key.VIDEO_VISIT_NEW]: {
			canSafelyReload: true,
			template: () => import('pages/VideoCall/NewVideoVisitPage'),
			enableTelemetry: true,
			path: '/new-video-visit/:consultId',
			restrictedTo: isSitkaOperations
		},
		[RouteUrls.Key.VIDEO_CONFERENCE_NEW]: {
			canSafelyReload: true,
			template: () => import('pages/VideoCall/NewVideoConferencePage'),
			enableTelemetry: true,
			path: '/video-conference',
			restrictedTo: isSitkaOperations
		},
		[RouteUrls.Key.VIDEO_VISIT]: {
			path: '/video-visit/:videoVisitId',
			pageOptions: {
				excludeParts: [ConfigurablePageParts.MENU, ConfigurablePageParts.PAGE_STYLES]
			},
			template: () => import('pages/VideoCall/VideoVisitPage'),
			enableTelemetry: true
		},
		[RouteUrls.Key.VIDEO_VISIT_MINIMAL]: {
			path: '/video-visit-min/:videoVisitId',
			pageOptions: {
				excludeParts: [
					ConfigurablePageParts.FOOTER,
					ConfigurablePageParts.NAVBAR,
					ConfigurablePageParts.PAGE_STYLES
				]
			},
			template: () => import('pages/VideoCall/MinimalVideoVisitPage'),
			enableTelemetry: true
		},
		[RouteUrls.Key.VIDEO_CONFERENCE]: {
			path: '/video-conference/:videoVisitId',
			pageOptions: {
				excludeParts: [ConfigurablePageParts.MENU, ConfigurablePageParts.PAGE_STYLES]
			},
			template: () => import('pages/VideoCall/VideoConferencePage'),
			enableTelemetry: true
		},
		[RouteUrls.Key.NO_PARTNERS_LANDING_PAGE]: {
			canSafelyReload: true,
			template: () => import('pages/NoPartnersPage'),
			enableTelemetry: true,
			path: '/no-partners'
		},
		[RouteUrls.Key.PARTNER]: {
			template: () => import('routing/routers/PartnerRouter'),
			enableTelemetry: true,
			path: '/partners/:partnerId/*',
			restrictedTo: isSitkaOperations,
			routes: {
				[RouteUrls.Key.PARTNER_DETAIL]: {
					canSafelyReload: true,
					template: () => import('pages/PartnerDetailPage'),
					enableTelemetry: true,
					path: '/partners/:partnerId/detail',
					restrictedTo: isSitkaOperations
				},
				[RouteUrls.Key.PARTNER_EDIT]: {
					canSafelyReload: true,
					template: () => import('pages/Editors/EditPartnerPage'),
					enableTelemetry: true,
					path: '/partners/:partnerId/edit',
					restrictedTo: isSitkaOperations
				}
			}
		},
		[RouteUrls.Key.PARTNER_NEW]: {
			template: () => import('pages/Editors/NewPartnerPage'),
			enableTelemetry: true,
			path: '/partners/new',
			restrictedTo: isSitkaOperations
		},
		[RouteUrls.Key.PARTNERS]: {
			template: () => import('pages/PartnersPage'),
			enableTelemetry: true,
			path: '/partners',
			restrictedTo: isSitkaOperations
		},
		[RouteUrls.Key.PATIENT_DASHBOARD]: {
			canSafelyReload: true,
			enableTelemetry: true,
			path: '/patient-dashboard',
			restrictedTo: or(isPatient, isSitkaOperations),
			template: () => import('pages/PatientDashboardPage')
		},
		[RouteUrls.Key.CONSULT_REQUEST]: {
			canSafelyReload: true,
			template: () => import('pages/RequestConsultationPage'),
			enableTelemetry: true,
			path: '/request-consultation',
			restrictedTo: or(isPartnerStaff, isSitkaOperations)
		},
		[RouteUrls.Key.USER]: {
			canSafelyReload: true,
			enableTelemetry: true,
			path: '/users/:userId/*',
			template: () => import('routing/routers/UserRouter'),
			routes: {
				[RouteUrls.Key.USER_DETAIL]: {
					template: () => import('pages/UserDetailPage'),
					enableTelemetry: true,
					path: '/users/:userId/detail',
					restrictedTo: isAnyAdmin
				},
				[RouteUrls.Key.USER_EDIT]: {
					canSafelyReload: true,
					template: () => import('pages/Editors/EditUserPage'),
					enableTelemetry: true,
					path: '/users/:userId/edit',
					restrictedTo: isAnyAdmin
				},
				[RouteUrls.Key.USER_LICENSE_NEW]: {
					template: () => import('pages/Editors/UserLicenseEditorPage'),
					enableTelemetry: true,
					path: '/users/:userId/user-license/new',
					restrictedTo: isSitkaOperations
				},
				[RouteUrls.Key.USER_LICENSE_EDIT]: {
					canSafelyReload: true,
					template: () => import('pages/Editors/UserLicenseEditorPage'),
					enableTelemetry: true,
					path: '/users/:userId/user-license/:userLicenseId/edit',
					restrictedTo: isSitkaOperations
				},
				[RouteUrls.Key.USER_TIMEFRAME_RESTRICTION_NEW]: {
					template: () => import('pages/Editors/UserTimeframeRestrictionPage'),
					enableTelemetry: true,
					path: '/users/:userId/restrictionTimeframe/new',
					restrictedTo: isSitkaOperations
				},
				[RouteUrls.Key.USER_TIMEFRAME_RESTRICTION_EDIT]: {
					template: () => import('pages/Editors/UserTimeframeRestrictionPage'),
					enableTelemetry: true,
					path: '/users/:userId/restrictionTimeframe/:startDate/:endDate',
					restrictedTo: isSitkaOperations
				}
			}
		},
		[RouteUrls.Key.USER_NEW]: {
			template: () => import('pages/Editors/NewUserPage'),
			enableTelemetry: true,
			path: '/users/new',
			restrictedTo: isSitkaOperations
		},
		[RouteUrls.Key.USERS]: {
			canSafelyReload: true,
			template: () => import('pages/UsersPage'),
			enableTelemetry: true,
			path: '/users',
			restrictedTo: isAnyAdmin
		},
		[RouteUrls.Key.SSO_REDIRECT_HANDLER]: {
			canSafelyReload: true,
			template: () => import('pages/SSORedirectHandlerPage'),
			enableTelemetry: true,
			path: '/sso-redirect-handler'
		},
		[RouteUrls.Key.SSO_ATHENA_CONSULT_REQUEST]: {
			canSafelyReload: true,
			template: () => import('pages/SSOAthenaConsultRequestPage'),
			enableTelemetry: true,
			path: '/sso-athena-consult-request'
		}
	}
});

export default authenticatedRoutes;
