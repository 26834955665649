import LinkButton from 'components/Buttons/LinkButtons/LinkButton';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { Button, ButtonType } from 'display';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { getConsultLink } from 'lib/consultHelpers';
import { getPatientName } from 'lib/userHelpers';
import * as React from 'react';
import { fontSize } from 'styles';

const styles = {
	mobile: css`
		font-size: ${fontSize[3]};
	`
};

const header = 'Patient Name';

export default (isSitkaMedicalAssociate?: boolean): DataTableColumn<ConsultComposite> => ({
	header,
	render: (consultComposite: ConsultComposite) => (
		<div>
			<LinkButton
				buttonType={ButtonType.TEXT_PRIMARY}
				text={getPatientName(consultComposite.patient)}
				size={Button.Size.TINY}
				link={getConsultLink(consultComposite, isSitkaMedicalAssociate)}
			/>
		</div>
	),
	renderMobile: ({ patient }: ConsultComposite) => (
		<div className={styles.mobile}>{getPatientName(patient)}</div>
	)
});
