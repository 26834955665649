import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import Alert, { AlertProps } from '@mui/material/Alert';

interface SnackbarAlertProps {
	isOpen: boolean;
	onClose: (_event: React.SyntheticEvent, reason?: string) => void;
	message: string;
	severity?: AlertProps['severity'];
}

function SnackbarAlert({ message, isOpen, onClose, severity = 'success' }: SnackbarAlertProps) {
	return (
		<Snackbar
			open={isOpen}
			autoHideDuration={4000}
			onClose={onClose}
			anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
			<Alert
				onClose={onClose}
				severity={severity}
				variant="standard"
				sx={{ maxWidth: '75%' }}>
				{message}
			</Alert>
		</Snackbar>
	);
}

export default SnackbarAlert;
