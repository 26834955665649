import { handleSendGetUsersResponse, handleSingleUserResponse } from './users.actions';
import { apiCallerWithToast, apiCaller } from 'store/requests';
import { createUser } from 'api/admin';
import { getEditableFields, getUser, getUsers, updatePassword, updateUser } from 'api/common';

const sendCreateUserWithToast = apiCallerWithToast({
	action: handleSingleUserResponse,
	apiCall: createUser
});

const sendGetEditableFields = apiCaller({
	apiCall: getEditableFields
});

const sendGetEditableFieldsWithToast = apiCallerWithToast({
	apiCall: getEditableFields
});

const sendGetUser = apiCaller({
	apiCall: getUser,
	action: handleSingleUserResponse
});

const sendGetUserWithToast = apiCallerWithToast({
	apiCall: getUser,
	action: handleSingleUserResponse
});
const sendGetUsers = apiCaller({
	apiCall: getUsers,
	action: handleSendGetUsersResponse
});

const sendUpdatePassword = apiCallerWithToast({
	apiCall: updatePassword,
	action: handleSingleUserResponse
});

const sendUpdateUserWithToast = apiCallerWithToast({
	apiCall: updateUser,
	action: handleSingleUserResponse
});

export {
	sendCreateUserWithToast,
	sendGetEditableFields,
	sendGetEditableFieldsWithToast,
	sendGetUser,
	sendGetUserWithToast,
	sendGetUsers,
	sendUpdatePassword,
	sendUpdateUserWithToast
};
