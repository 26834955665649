import { getAdminClient } from 'lib/ThriftClient';
import { CreatePartnerResponse } from 'thriftgen/api_types';
import { CreatePartnerRequestCamel } from 'thriftgen/thriftCamelTypes';
import { transformMutations } from './partnerHelpers';

function createPartner({ mutations }: CreatePartnerRequestCamel): CreatePartnerResponse {
	const client = getAdminClient();
	const request = {
		mutations: transformMutations(mutations)
	};

	return client.create_partner(request);
}

export default createPartner;
