import * as React from 'react';
import { Form, InputProps } from 'display';

const FIELD_NAME = 'city';
const LABEL = 'City';

interface CityFieldProps {
	data: {
		[FIELD_NAME]: string;
		[key: string]: unknown;
	};
	editableFields: string[];
	errors: {
		fields: string[];
	};
	onChange: (event: React.SyntheticEvent<HTMLInputElement>, data: InputProps) => void;
}

function getValue(data: CityFieldProps['data']): string {
	return data[FIELD_NAME];
}

function hasError(errors: CityFieldProps['errors']): boolean {
	return errors.fields.includes(FIELD_NAME);
}

function canEdit(editableFields: CityFieldProps['editableFields']): boolean {
	return editableFields.includes(FIELD_NAME);
}

function City({
	editableFields,
	errors = { fields: [] },
	data,
	onChange
}: CityFieldProps): JSX.Element | null {
	const value = getValue(data);

	if (canEdit(editableFields)) {
		return (
			<Form.Input
				error={hasError(errors)}
				id={FIELD_NAME}
				label={LABEL}
				name={FIELD_NAME}
				onChange={onChange}
				placeholder="Monterey"
				value={value || ''}
			/>
		);
	}

	if (value) {
		return (
			<Form.Field>
				<label>{LABEL}</label>
				<span>{value}</span>
			</Form.Field>
		);
	}

	return null;
}

City.FIELD_NAME = FIELD_NAME;

export default City;
