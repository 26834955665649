import reducerRegistry from 'store/registry';
import { MetaState, META_ACTION_TYPES, MetaActionTypes } from './meta.types';

const reducerName = 'meta';

const initialState: MetaState = {
	isAppStale: false
};

function metaReducer(state = initialState, action: MetaActionTypes): MetaState {
	switch (action.type) {
		case META_ACTION_TYPES.SET_APP_STALE:
			return {
				...state,
				isAppStale: action.stale
			};
		default:
			return state;
	}
}

reducerRegistry.register(reducerName, metaReducer);
