import * as React from 'react';
import { Redirect } from 'react-router-dom';
import { RouteComponentProps, RedirectProps } from 'react-router';
import { connect } from 'react-redux';
import { useOktaAuth } from '@okta/okta-react';

import { getLoginRedirect, getPatientExpirationRedirect } from 'routing/redirects';
import { selectHasAuthUser, selectLoggedIn } from 'store/auth';
import { AppState } from 'store/types';

import AuthUserRequestContextProvider from 'components/ContextProviders/AuthUserRequestContextProvider';
import { Loader } from 'display';

interface StoreProps {
	hasActor: boolean;
	loggedIn: boolean;
}

interface ConsumerProps {
	render: () => JSX.Element;
}

type AuthWrapperProps = StoreProps &
	ConsumerProps &
	Pick<RouteComponentProps, 'history' | 'location'>;

function AuthWrapper({
	hasActor,
	history,
	location,
	loggedIn,
	render
}: AuthWrapperProps): JSX.Element {
	const { authState } = useOktaAuth();
	const exchangingOktaTokenForSitka = authState?.isAuthenticated && !loggedIn;
	const loadingLocalCachedInfoFromSitka = loggedIn && !hasActor;

	if (authState?.isPending || exchangingOktaTokenForSitka || loadingLocalCachedInfoFromSitka) {
		return <Loader active={true} data-testid="AuthWrapper-loader" />;
	}

	// This is not a scalable way to check if a user is a patient. The problem I was running into is that
	// the user is no longer logged in when this code runs, so I can not check to see what roles the user
	// has. Because patients can only really view one page right now, this works, but if the patient experience
	// grows larger, it might be necessary to refactor this code to do a proper check.
	function isPatientUser(): boolean {
		const patientPages = ['patient-conversation'];
		for (const patientPage of patientPages) {
			if (location.pathname.endsWith(patientPage)) {
				return true;
			}
		}
		return false;
	}

	if (!loggedIn) {
		let expirationRedirect: RedirectProps['to'];

		if (isPatientUser()) {
			expirationRedirect = getPatientExpirationRedirect();
		} else {
			expirationRedirect = getLoginRedirect(location, history);
		}

		return <Redirect to={expirationRedirect} />;
	}

	return <AuthUserRequestContextProvider>{render()}</AuthUserRequestContextProvider>;
}

const mapStateToProps = (store: AppState): StoreProps => ({
	loggedIn: selectLoggedIn(store),
	hasActor: selectHasAuthUser(store)
});

export default connect(mapStateToProps)(AuthWrapper);
