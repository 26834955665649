import * as React from 'react';
import PropTypes from 'prop-types';

const noop = () => {};

function withEventEmitter(EmittingComponent) {
	class Wrapper extends React.Component {
		constructor(props) {
			super(props);

			this.emitEvent = this.emitEvent.bind(this);
		}

		emitEvent({ eventType, data }) {
			this.props.eventListener({
				eventType,
				action: data
			});
		}

		render() {
			const { eventListener: eventListenerIgnored, ...rest } = this.props;
			const props = {
				...rest,
				emitEvent: this.emitEvent
			};

			return <EmittingComponent {...props} />;
		}
	}

	Wrapper.defaultProps = {
		eventListener: noop
	};

	Wrapper.propTypes = {
		eventListener: PropTypes.func
	};

	return Wrapper;
}

export default withEventEmitter;
