import * as React from 'react';

import { PaginationProvider } from 'contexts/PaginationContext';
import { usePagination } from 'hooks';
import { Filter, Parameters } from 'hooks/pagination';

interface PaginationContextProviderProps {
	children: React.ReactNode;
	defaultPageSize?: Parameters['pageSize'];
	storageKey?: string;
	initialFilters?: Filter[];
}

const DEFAULT_PAGE_SIZE = 10;

function PaginationContextProvider({
	children,
	defaultPageSize = DEFAULT_PAGE_SIZE,
	storageKey,
	initialFilters
}: PaginationContextProviderProps): JSX.Element {
	const pagination = usePagination(defaultPageSize, storageKey, initialFilters);

	return <PaginationProvider value={pagination}>{children}</PaginationProvider>;
}

export default PaginationContextProvider;
export { PaginationContextProviderProps };
