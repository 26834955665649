import { DataTableColumn } from 'components/DataTable/DataTable';
import * as React from 'react';
import { Checkbox, CheckboxProps, Radio } from 'display';
import { User } from 'thriftgen/api_types';

type BuildSelectUser = Pick<CheckboxProps, 'name' | 'onChange'> & {
	isChecked: (user: User) => boolean;
	multiple?: boolean;
};

function buildSelectUser({
	onChange,
	name,
	multiple,
	isChecked
}: BuildSelectUser): DataTableColumn<User> {
	return {
		render: (user: User) => {
			const Input = multiple ? Checkbox : Radio;
			return (
				<Input
					checked={isChecked(user)}
					data-testid={`selectUserColumn-${user.user_id}`}
					name={name}
					onChange={onChange}
					value={user.user_id}
					id={`${name}-${user.user_id}`}
				/>
			);
		}
	};
}

export default buildSelectUser;

export { BuildSelectUser };
