import { UserCamel, PartnerCamel, ConsultCamel } from 'thriftgen/thriftCamelTypes';
import { ConsultComposite } from './types';

function buildConsultComposite(
	consult: ConsultCamel,
	users: UserCamel[],
	partners: PartnerCamel[]
): ConsultComposite {
	const patient = users.find(({ userId }) => userId === consult.patientId);
	const requestor = users.find(({ userId }) => userId === consult.requestorId);
	const specialist = users.find(({ userId }) => userId === consult.specialistId);
	const reviewer = users.find(({ userId }) => userId === consult.clinicalReview?.reviewerId);
	const partner = partners.find(({ partnerId }) => partnerId === consult.partnerId);

	if (!patient) {
		throw new Error(`Missing patient for consult ${consult.consultId}`);
	}

	if (consult.requestorId && !requestor) {
		throw new Error(`Missing requestor for consult ${consult.consultId}`);
	}

	if (consult.specialistId && !specialist) {
		throw new Error(`Missing specialist for consult ${consult.consultId}`);
	}

	if (consult.clinicalReview?.reviewerId && !reviewer) {
		throw new Error(`Missing reviewer for consult ${consult.consultId}`);
	}

	if (!partner) {
		throw new Error(`Missing partner for consult ${consult.consultId}`);
	}

	return {
		...consult,
		specialist,
		patient,
		requestor,
		partner,
		reviewer
	};
}

function transformToConsultsComposite({
	consults,
	partners,
	users
}: {
	consults: ConsultCamel[];
	partners: PartnerCamel[];
	users: UserCamel[];
}): ConsultComposite[] {
	return consults.map(consult => buildConsultComposite(consult, users, partners));
}

export { buildConsultComposite, transformToConsultsComposite };
