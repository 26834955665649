function isString(arg: unknown): arg is string {
	return typeof arg === 'string';
}

function massageOperand(op: string | number): string | number {
	return isString(op) ? op.toUpperCase() : op;
}

function compareAscending(a: string, b: string): number;
function compareAscending(a: number, b: number): number;
function compareAscending<T extends number & string>(a: T, b: T): number {
	const opA = massageOperand(a);
	const opB = massageOperand(b);

	if (opA < opB) {
		return -1;
	}
	if (opA > opB) {
		return 1;
	}

	return 0;
}

function compareDescending(a: string, b: string): number;
function compareDescending(a: number, b: number): number;
function compareDescending<T extends number & string>(a: T, b: T): number {
	return compareAscending(b, a);
}

export { compareAscending, compareDescending };
