import { ConsultData } from 'thriftgen/api_types';

enum DocumentAssetType {
	DATA = 'data'
}

type DocumentDataAsset = ConsultData & { assetType: DocumentAssetType.DATA } & SharedAttributes;

interface SharedAttributes {
	description: string;
	downloadUrl?: string;
	id: string;
	isNew: boolean;
	isProcessing: boolean;
	isReady: boolean;
	reviewQuery: string | undefined;
	reviewUrl?: string;
	thumbnailImage?: string;
}

export { DocumentAssetType, DocumentDataAsset };
