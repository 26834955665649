import { isIE } from 'lib/userAgent';
import { css } from 'emotion';
import ms from 'modularscale-js';

const borderRadius = {
	l1: '4px',
	l2: '8px',
	l3: '18px',
	circle: '50%'
};

const shadows = {
	l1: `0px 4px 8px rgba(45, 45, 45, 0.08),
		 0px 2px 4px rgba(45, 45, 45, 0.08),
		 0px 0px 4px rgba(45, 45, 45, 0.16)`,
	l2: `0px 8px 16px rgba(45, 45, 45, 0.08),
		 0px 4px 8px rgba(45, 45, 45, 0.08),
		 0px 0px 8px rgba(45, 45, 45, 0.16)`,
	l3: `0px 16px 24px rgba(45, 45, 45, 0.08),
		 0px 4px 8px rgba(45, 45, 45, 0.08),
		 0px 4px 16px rgba(45, 45, 45, 0.16)`,
	l4: `0px 32px 40px rgba(45, 45, 45, 0.08),
		 0px 8px 16px rgba(45, 45, 45, 0.08),
		 0px 4px 24px rgba(45, 45, 45, 0.16)`,
	l5: `0px 64px 96px rgba(45, 45, 45, 0.08),
		 0px 16px 24px rgba(45, 45, 45, 0.08),
		 0px 8px 32px rgba(45, 45, 45, 0.16)`
};

/*
 ** Our type scale, see at https://www.modularscale.com/?14,16&px&1.5
 */
const modularScaleConfig = {
	base: [14, 16],
	ratio: 1.5,
	ceiling: 10
};

const makeModularScale = () => {
	let values = {};
	return n => {
		if (n in values) {
			return values[n];
		}

		values[n] = `${ms(n, modularScaleConfig)}px`;
		return values[n];
	};
};

const modularScale = makeModularScale();

/* Style for the React app wrapper. This resets any padding/marging
and ensures the footer is sticky. We use block for IE11 given
that IE11 doesn't handle well how we're using flex + min-height here. */

const appStyle = css`
	height: 100%;
	padding: 0 0 !important;
	margin: 0 0 !important;
	display: ${isIE() ? 'block' : 'flex'};
	flex-direction: column;
`;

export * from './color';
export * from './mediaQueries';
export * from './type';
export * from './zIndex';
export { appStyle, borderRadius, modularScale, shadows };
