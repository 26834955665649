import { objectCamelToSnake } from 'lib/objectConverter';
import { getAdminClient } from 'lib/ThriftClient';
import { CreateUserRequest, CreateUserResponse, DateOfBirth, UserInfo } from 'thriftgen/api_types';
import { CreateUserRequestCamel } from 'thriftgen/thriftCamelTypes';

function createUser(
	params: Omit<CreateUserRequestCamel, 'password' | 'enableLogin'>
): Promise<CreateUserResponse> {
	const client = getAdminClient();
	const { user, emailOption, partnerId, isAdmin, memberRoles } = params;
	const { isBillable, isSupport, isReportable } = memberRoles;

	const request = new CreateUserRequest({
		user: new UserInfo({
			...objectCamelToSnake(user),
			date_of_birth: user.dateOfBirth ? new DateOfBirth(user.dateOfBirth) : null,
			roles: null
		}),
		email_option: emailOption,
		partner_id: partnerId,
		enable_login: true,
		is_admin: isAdmin,
		member_roles: {
			is_billable: isBillable,
			is_support: isSupport,
			is_reportable: isReportable
		}
	});

	return client.create_user(request);
}

export default createUser;
