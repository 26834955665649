import * as React from 'react';

import { cx, css } from 'emotion';
import { FontWeight, fontSize, fontWeight } from 'styles';

enum FontSize {
	/**
	 * Big
	 */
	T1 = 't1',
	/**
	 * Large
	 */
	T2 = 't2',
	/**
	 * Medium
	 */
	T3 = 't3',
	/**
	 * Small
	 */
	T4 = 't4',
	/**
	 * Micro
	 */
	T5 = 't5'
}

const styles = {
	weights: {
		[FontWeight.BOLD]: css`
			font-weight: ${fontWeight.bold};
		`,
		[FontWeight.REGULAR]: css`
			font-weight: ${fontWeight.regular};
		`
	},
	sizes: {
		[FontSize.T1]: css`
			font-size: ${fontSize[4]};
		`,
		[FontSize.T2]: css`
			font-size: ${fontSize[3]};
		`,
		[FontSize.T3]: css`
			font-size: ${fontSize[2]};
		`,
		[FontSize.T4]: css`
			font-size: ${fontSize[1]};
		`,
		[FontSize.T5]: css`
			font-size: ${fontSize[0]};
			margin-block-end: 0;
		`
	}
};

export interface TextProps {
	children: React.ReactNode;
	color?: string;
	size?: FontSize;
	weight?: FontWeight;
	className?: string;
	as?: React.ElementType;
}

function Text({
	as: Comp = 'span',
	children,
	color,
	size = FontSize.T3,
	weight = FontWeight.REGULAR,
	className
}: TextProps): JSX.Element {
	return (
		<Comp
			className={cx(styles.sizes[size], styles.weights[weight], className)}
			style={{ color }}>
			{children}
		</Comp>
	);
}

Text.Size = FontSize;
Text.Weight = FontWeight;

export default Text;
