import * as React from 'react';
import { css, cx } from 'emotion';
import { withRouter, RouteComponentProps } from 'react-router-dom';

import { PageDisplayOptions, ConfigurablePageParts } from 'routing/types';

import Navbar from 'components/Navbar/Navbar';
import { NavbarItem } from 'components/Navbar/helpers';
import Footer from 'components/Footer/Footer';
import WhitelabelFooter from 'components/Footer/WhitelabelFooter';

import { isPartIncluded } from './pageHelpers';

const styles = {
	pageStyle: css`
		padding-bottom: 1em;
	`,
	page: css`
		height: inherit;
	`,
	flexLayout: css`
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		flex-shrink: 0;
	`
};

function getActiveItem(pathname: string): string | undefined {
	return pathname.length ? pathname.split('/')[1] : undefined;
}

function renderFooterArea(pageOptions: PageDisplayOptions = {}): JSX.Element {
	const { whitelabelFooter } = pageOptions;
	return whitelabelFooter ? <WhitelabelFooter /> : <Footer />;
}

type PageProps = {
	children: React.ReactNode;
	pageOptions?: PageDisplayOptions;
	menuItems?: NavbarItem[];
} & RouteComponentProps;

function Page({ children, location, menuItems = [], pageOptions = {} }: PageProps): JSX.Element {
	const renderFooter = isPartIncluded(pageOptions, ConfigurablePageParts.FOOTER);
	const renderNavBar = isPartIncluded(pageOptions, ConfigurablePageParts.NAVBAR);
	const renderMenu = isPartIncluded(pageOptions, ConfigurablePageParts.MENU);

	const pageStyles = isPartIncluded(pageOptions, ConfigurablePageParts.PAGE_STYLES)
		? styles.pageStyle
		: undefined;

	return (
		<div className={cx(styles.page, styles.flexLayout)}>
			{renderNavBar && (
				<Navbar
					menuItems={renderMenu ? menuItems : []}
					activeItem={getActiveItem(location.pathname)}
					pageOptions={pageOptions}
				/>
			)}
			<main className={cx(pageStyles, styles.flexLayout)}>{children}</main>
			{renderFooter && renderFooterArea(pageOptions)}
		</div>
	);
}

export default withRouter(Page);
