import { resendConversationMessageNotification } from 'api/common';
import {
	LinkToEditPatientDetailsButton,
	ResendMessageButton,
	ReshareWithPatientButton
} from 'components/Buttons';
import { useCaseContext } from 'contexts';
import { ConversationMessageComposite } from 'hooks';
import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import * as React from 'react';
import { UserInfo } from 'thriftgen/api_types';
import { PatientContactInformationModal } from './PatientContactInformationModal';

const MODAL_TITLE = 'Reshare this message';

function ResendMessageModal({ message }: { message: ConversationMessageComposite }): JSX.Element {
	const { theCase } = useCaseContext();
	const { caseId, patient, patientConversationId } = theCase;
	const { messageId } = message;

	if (patientConversationId !== message.conversationId) {
		throw new Error('The case conversation does not match the conversation for this message');
	}

	async function onDone(
		userUpdates: Pick<UserInfo, 'email' | 'date_of_birth'> | null
	): Promise<void> {
		await resendConversationMessageNotification({
			conversationMessageId: messageId,
			patientUpdates: userUpdates ? objectSnakeToCamel(userUpdates) : undefined
		});
	}

	return (
		<PatientContactInformationModal
			linkToPatientEditor={<LinkToEditPatientDetailsButton caseId={caseId} />}
			onDone={onDone}
			patient={objectCamelToSnake(patient)}
			SubmitControl={ReshareWithPatientButton}
			title={MODAL_TITLE}
			Trigger={ResendMessageButton}
		/>
	);
}

export { ResendMessageModal };
