import * as React from 'react';

import { IllustrationProps } from './illustrationTypes';

function SendConsult({ fill, stroke }: IllustrationProps): JSX.Element {
	return (
		<svg height="100%" viewBox="0 0 128 96" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M89.6 19.2H38.4L35.2 22.4V73.6L38.4 76.8H89.6L92.8 73.6V22.4L89.6 19.2Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M37.7372 17.6H90.2627L94.4 21.7372V74.2627L90.2627 78.4H37.7372L33.6 74.2627V21.7372L37.7372 17.6ZM39.0627 20.8L36.8 23.0627V72.9372L39.0627 75.2H88.9372L91.2 72.9372V23.0627L88.9372 20.8H39.0627ZM51.2 35.2H62.4V38.4H54.4V52.8H57.6V57.2L63.4666 52.8H73.6V49.6H76.8V56H64.5333L58.1333 60.8H54.4V56H51.2V35.2ZM68.8 40V35.2H72V40H76.8V43.2H72V48H68.8V43.2H64V40H68.8Z"
				fill={stroke}
			/>
		</svg>
	);
}

export default SendConsult;
