import { getCommonClient } from 'lib/ThriftClient';
import { GetUserRequest, GetUserResponse } from 'thriftgen/api_types';
import { GetUserRequestCamel } from 'thriftgen/thriftCamelTypes';

async function getUser(params: GetUserRequestCamel): Promise<GetUserResponse> {
	const client = getCommonClient();

	const { userId } = params;
	const request = new GetUserRequest({ user_id: userId });

	const response = (await client.get_user(request)) as GetUserResponse;

	response.user.licenses = response.user.licenses?.map(license => ({
		...license,
		expires_at: +license.expires_at
	}));

	if (response.user.specialist_data) {
		response.user.specialist_data.timeframe_restrictions =
			response.user.specialist_data.timeframe_restrictions.map(timeframe_restriction => ({
				...timeframe_restriction,
				start_date: +timeframe_restriction.start_date,
				end_date: +timeframe_restriction.end_date
			}));
	}

	return response;
}

export default getUser;
