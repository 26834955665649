import * as React from 'react';

import { Message, Paragraph } from 'display';
import { SUPPORT_EMAIL } from 'lib/supportContacts';

function ErrorMessage({ message }: { message: string }): JSX.Element {
	return (
		<React.Fragment>
			<br />
			{message}
		</React.Fragment>
	);
}

function RecordingPreviewError({ message }: { message?: string }): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Failed to load video preview</Message.Header>
			<Paragraph>
				This video failed to process and cannot be saved. Please try recording again and if
				you continue to experience this issue contact{' '}
				<a href={SUPPORT_EMAIL}>{SUPPORT_EMAIL}</a> if you need help.
				{message ? <ErrorMessage message={message} /> : null}
			</Paragraph>
		</Message>
	);
}

export { RecordingPreviewError };
