import { ButtonType } from 'display';
import Strings from 'lib/strings';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Consult } from 'thriftgen/api_types';
import LinkButton from '../LinkButton';

interface LinkToConsultDetailButtonProps {
	consultId: Consult['consult_id'];
}

function LinkToConsultDetailSMAResponseButton({
	consultId
}: LinkToConsultDetailButtonProps): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			text={Strings.Buttons.GO_TO_CONSULT_PAGE}
			link={RouteUrls.buildUrl(RouteUrls.Key.CONSULT_DETAIL, { consultId })}
		/>
	);
}

export default LinkToConsultDetailSMAResponseButton;
