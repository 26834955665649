import RouteUrls from 'routing/RouteUrls';
import { IntegrationType } from 'thriftgen/api_types';
import Strings from 'lib/strings';

interface NavbarItem {
	name: string;
	text: string;
	url: string;
	hideInIframe?: boolean;
	hideForIntegrationTypes: IntegrationType[];
}

function getNavItems(): NavbarItem[] {
	const NAV_ITEMS = {
		[RouteUrls.Key.DASHBOARD_PCP]: {
			name: 'dashboard-pcp',
			text: Strings.Navbar.MY_SITKA,
			url: RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_PCP)
		},
		[RouteUrls.Key.DASHBOARD_SMA]: {
			name: 'dashboard-sma',
			text: Strings.Navbar.MY_CONSULTS,
			url: RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_SMA)
		},
		[RouteUrls.Key.MY_CASES]: {
			name: 'my-cases',
			text: Strings.Navbar.MY_PATIENTS,
			url: RouteUrls.buildUrl(RouteUrls.Key.MY_CASES),
			hideForIntegrationTypes: [IntegrationType.ATHENA]
		},
		[RouteUrls.Key.DASHBOARD_CLINICAL]: {
			name: 'dashboard-clinical',
			text: Strings.Navbar.CLINICAL_REVIEW,
			url: RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_CLINICAL)
		},
		[RouteUrls.Key.CONSULT_SUMMARY_DASHBOARD]: {
			name: 'consult-summary-dashboard',
			text: Strings.Navbar.CONSULT_SUMMARY_DASHBOARD,
			url: RouteUrls.buildUrl(RouteUrls.Key.CONSULT_SUMMARY_DASHBOARD)
		},
		[RouteUrls.Key.PARTNERS]: {
			name: 'partners',
			text: Strings.Navbar.PARTNERS,
			url: RouteUrls.buildUrl(RouteUrls.Key.PARTNERS)
		},
		[RouteUrls.Key.USERS]: {
			name: 'users',
			text: Strings.Navbar.USERS,
			url: RouteUrls.buildUrl(RouteUrls.Key.USERS)
		},
		[RouteUrls.Key.MY_ACCOUNT]: {
			name: 'my-account',
			text: Strings.Navbar.MY_ACCOUNT,
			url: RouteUrls.buildUrl(RouteUrls.Key.MY_ACCOUNT),
			hideForIntegrationTypes: [IntegrationType.ATHENA]
		}
	};

	const keys = Object.keys(NAV_ITEMS);

	return keys.filter(RouteUrls.canAccessUrl).map(key => NAV_ITEMS[key]);
}

export default getNavItems;
export { NavbarItem };
