import { getCommonClient } from 'lib/ThriftClient';
import { GetHistoryRequest, GetHistoryResponse } from 'thriftgen/api_types';
import { GetHistoryRequestCamel } from 'thriftgen/thriftCamelTypes';

function getHistory(params: GetHistoryRequestCamel): Promise<GetHistoryResponse> {
	const client = getCommonClient();

	const { objectId, objectType } = params;
	const request = new GetHistoryRequest({
		object_id: objectId,
		object_type: objectType
	});

	return client.get_history(request);
}

export default getHistory;
