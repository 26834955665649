export function isBrowserRecordingSupported(): boolean {
	return typeof window.MediaRecorder === 'function';
}

interface PointerEvents {
	down: 'pointerdown' | 'touchstart' | 'mousedown';
	move: 'pointermove' | 'touchmove' | 'mousemove';
	up: 'pointerup' | 'touchend' | 'mouseup';
}

interface PointerEventsHandlers {
	down: 'onPointerDown' | 'onTouchStart' | 'onMouseDown';
	move: 'onPointerMove' | 'onTouchMove' | 'onMouseMove';
	up: 'onPointerUp' | 'onTouchEnd' | 'onMouseUp';
}

export function getPointerEvents(): PointerEvents {
	if ('onpointerdown' in window) {
		return {
			down: 'pointerdown',
			move: 'pointermove',
			up: 'pointerup'
		};
	}

	if ('ontouchstart' in window) {
		return {
			down: 'touchstart',
			move: 'touchmove',
			up: 'touchend'
		};
	}

	return {
		down: 'mousedown',
		move: 'mousemove',
		up: 'mouseup'
	};
}

export function getPointerEventsHandlers(): PointerEventsHandlers {
	if ('onpointerdown' in window) {
		return {
			down: 'onPointerDown',
			move: 'onPointerMove',
			up: 'onPointerUp'
		};
	}

	if ('ontouchstart' in window) {
		return {
			down: 'onTouchStart',
			move: 'onTouchMove',
			up: 'onTouchEnd'
		};
	}

	return {
		down: 'onMouseDown',
		move: 'onMouseMove',
		up: 'onMouseUp'
	};
}
