import * as React from 'react';
import useOnlineStatus from 'hooks/useOnlineStatus';
import { useSnackbarContext } from 'contexts/SnackbarContext';
import Strings from 'lib/strings';

function OnlineStatus(): JSX.Element {
	const onlineStatus = useOnlineStatus();
	const { openSnackbar } = useSnackbarContext();

	React.useEffect(() => {
		if (!onlineStatus) {
			openSnackbar({
				message: Strings.Sentences.OFFLINE_WARNING,
				severity: 'error'
			});
		}
	}, [onlineStatus, openSnackbar]);
	return <React.Fragment />;
}

export default OnlineStatus;
