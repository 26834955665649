import { CheckboxProps, Form } from 'display';
import { translateThriftEnum } from 'lib/i18n';
import * as React from 'react';
import { Sex, SEX } from 'thriftgen/api_types';

const FIELD_NAME = 'sex';
const LABEL = 'Sex (optional)';

interface SexFieldProps {
	data: {
		[FIELD_NAME]: Sex | null;
	};
	editableFields: string[];
	errors: {
		fields: string[];
		messages: string[];
	};
	onChange: (event: React.SyntheticEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

function getValue(data: SexFieldProps['data']): Sex | null {
	return data[FIELD_NAME];
}

function canEdit(editableFields: SexFieldProps['editableFields']): boolean {
	return editableFields.includes(FIELD_NAME);
}

function hasError(errors: SexFieldProps['errors']): boolean {
	return errors.fields.includes(FIELD_NAME);
}

function SexField({ data, editableFields, errors, onChange }: SexFieldProps): JSX.Element | null {
	const value = getValue(data);
	const error = hasError(errors);

	function onValueChange(event: React.FormEvent<HTMLInputElement>, eventData: CheckboxProps) {
		onChange(event, {
			name: FIELD_NAME,
			value: eventData.checked ? eventData.value : value ?? undefined
		});
	}

	if (canEdit(editableFields)) {
		return (
			<Form.Group inline={true}>
				<label>{LABEL}</label>
				<Form.Radio
					id={`${FIELD_NAME}_Female`}
					label="Female"
					name={FIELD_NAME}
					value={Sex.FEMALE}
					checked={value === Sex.FEMALE}
					onChange={onValueChange}
					error={error}
				/>
				<Form.Radio
					id={`${FIELD_NAME}_Male`}
					label="Male"
					name={FIELD_NAME}
					value={Sex.MALE}
					checked={value === Sex.MALE}
					onChange={onValueChange}
					error={error}
				/>
			</Form.Group>
		);
	}

	if (value) {
		return (
			<Form.Field inline={true}>
				<label>{LABEL}</label>
				<span>{translateThriftEnum(value, SEX)}</span>
			</Form.Field>
		);
	}

	return null;
}

SexField.FIELD_NAME = FIELD_NAME;
SexField.validate = () => null;

export default SexField;
