import FirstName from './FirstName';
import { buildFieldVariation } from '../helpers';
import Strings from 'lib/strings';

const FIELD_NAME = 'firstName';

export default buildFieldVariation({
	Field: FirstName,
	fieldName: FIELD_NAME,
	label: Strings.Labels.PATIENT_FIRST_NAME
});
