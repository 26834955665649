import { usePaginationContext } from 'contexts/PaginationContext';
import { Button } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import Strings from 'lib/strings';

const styles = {
	container: css`
		display: flex;
		width: 100%;
		justify-content: space-between;
	`,
	pagingButton: css`
		padding-left: 1em;
		display: inline-block;
	`
};
interface PaginationCursorControlsProps {
	alwaysShow?: boolean;
}

function PaginationCursorControls({
	alwaysShow = false
}: PaginationCursorControlsProps): JSX.Element | null {
	const { hasNextPage, hasPrevPage, setNextPage, setPrevPage } = usePaginationContext();
	const noNextPage = !hasNextPage();
	const noPrevPage = !hasPrevPage();
	const hasNoPages = noNextPage && noPrevPage;

	if (!alwaysShow && hasNoPages) {
		return null;
	}

	return (
		<React.Fragment>
			<div className={styles.pagingButton}>
				<Button.Icon
					disabled={noPrevPage}
					icon="chevron left"
					onClick={setPrevPage}
					title={Strings.Buttons.PREVIOUS_PAGE}
				/>
			</div>
			<div className={styles.pagingButton}>
				<Button.Icon
					disabled={noNextPage}
					icon="chevron right"
					onClick={setNextPage}
					title={Strings.Buttons.NEXT_PAGE}
				/>
			</div>
		</React.Fragment>
	);
}

export default PaginationCursorControls;
