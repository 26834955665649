// Nothing is currently happening.
export const INACTIVE = 'inactive';

// An action is currently running.
export const IN_PROGRESS = 'in_progress';

// The previous action succeeded.
export const SUCCESS = 'success';

// The previous action failed.
export const FAILURE = 'failure';
