import { createSelector } from 'reselect';
import { AppState, SelectorConstraints } from 'store/types';
import { Consult } from 'thriftgen/api_types';
import { ConsultsState } from './consults.types';

const selectConsults = (state: AppState): ConsultsState => state.consults as ConsultsState;

const selectConsultsAsArray = createSelector(selectConsults, (consults: ConsultsState) => {
	return Object.keys(consults).map((consultId: string): Consult => consults[consultId]);
});

type SelectConsultByIdConstraints = SelectorConstraints<{ id: string }>;

const selectConsultById = (state: AppState, constraints: SelectConsultByIdConstraints): Consult => {
	return selectConsults(state)[constraints.id];
};

export { selectConsultById, selectConsultsAsArray };
