import { useFileViewerModalContext } from 'contexts';
import { CardModal, ModalWidth } from 'display';
import noop from 'lib/noop';
import Viewer from 'pages/FileViewerPage/Viewer';
import * as React from 'react';

function FileViewerModal(): JSX.Element {
	const { documents, defaultDocument, reportEvent, setDefaultDocumentById, modalTitle } =
		useFileViewerModalContext();

	function isOpen() {
		return !!defaultDocument;
	}

	function closeModal() {
		setDefaultDocumentById();
	}

	return (
		<CardModal open={isOpen()} width={ModalWidth.FULL} data-testid="fileViewerModal">
			<CardModal.Title onClose={closeModal}>{modalTitle}</CardModal.Title>
			<Viewer
				defaultDocument={defaultDocument}
				documents={documents}
				reportEvent={reportEvent}
				onDocumentChange={noop}
			/>
		</CardModal>
	);
}

export default FileViewerModal;
