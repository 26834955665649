import * as React from 'react';

import LinkButton, { LinkButtonProps } from '../LinkButton';
import { Button, ButtonType } from 'display';
import Strings from 'lib/strings';

function LinkToDetailPageButton({
	text,
	buttonType = ButtonType.SECONDARY,
	...props
}: LinkButtonProps): JSX.Element {
	return (
		<LinkButton
			buttonType={buttonType}
			text={text ?? Strings.Buttons.VIEW}
			size={Button.Size.TINY}
			{...props}
		/>
	);
}

export default LinkToDetailPageButton;
