import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import buildTextFieldComponent from 'components/Forms/Fields/base/buildTextField';

const FIELD_NAME = 'funFacts';
const FIELD_LABEL = 'SMA Fun Facts';

const SMAFunFacts: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL
});

export default SMAFunFacts;
