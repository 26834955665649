import * as React from 'react';

import { Paragraph, Anchor } from 'display';

function NoConsultsWithSharedMessagesMessage(): JSX.Element {
	return (
		<React.Fragment>
			<Paragraph>
				If you&apos;ve seen messages here before, please contact Sitka support via the email
				below to help you find your providers contact information.
			</Paragraph>
			<Anchor href={`mailto:help@trustsitka.com`}>help@trustsitka.com</Anchor>
		</React.Fragment>
	);
}

export default NoConsultsWithSharedMessagesMessage;
