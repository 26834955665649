import { DataTableColumn } from 'components/DataTable/DataTable';
import { ConsultComposite } from 'hooks';
import * as React from 'react';

const header = 'Status';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ clinicalReview }) => <div>{clinicalReview ? 'Reviewed' : 'Needs Review'}</div>
});
