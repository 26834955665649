import SitkaDatadog from 'lib/SitkaDatadog';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectAuthUser, selectHasAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { localStorage as LocalStorage, StorageKeys } from 'lib/Storage';
import { User } from 'thriftgen/api_types';

interface StoreProps {
	actor: User | null;
}

function Datadog({ actor }: StoreProps): null {
	React.useEffect(() => {
		if (!SitkaDatadog.isInitialized()) {
			SitkaDatadog.initialize();
		}
	}, []);

	React.useEffect(() => {
		if (actor) {
			SitkaDatadog.setUserId(actor.user_id);

			const authSessionId = LocalStorage.get(StorageKeys.AUTH_SESSION_ID);
			if (authSessionId) {
				SitkaDatadog.setAuthSessionId(authSessionId);
			}
		}
	}, [actor]);

	return null;
}

const mapStoreToProps = (state: AppState): StoreProps => ({
	actor: selectHasAuthUser(state) ? selectAuthUser(state) : null
});

export default connect(mapStoreToProps)(Datadog);
