import * as React from 'react';
import { DataFormFieldComponentProps } from 'components/Forms/DataFormTypes';
import { InputOnChangeData, Form } from 'display';
import { StringMutationCamel } from 'thriftgen/thriftCamelTypes';

const FIELD_NAME = 'name';
const LABEL = 'Name';

function getValue(data: DataFormFieldComponentProps<StringMutationCamel>['data']): string {
	return data[FIELD_NAME].value;
}

function validate(
	data: DataFormFieldComponentProps<StringMutationCamel>['data'],
	required: boolean
): null | {
	fields: string[];
	messages: string[];
} {
	const value = getValue(data);

	if (required && !value) {
		return {
			fields: [FIELD_NAME],
			messages: ['Partner name cannot be empty']
		};
	}

	return null;
}

function PartnerName({
	data = {
		[FIELD_NAME]: {
			value: ''
		}
	},
	editableFields,
	errors,
	onChange
}: DataFormFieldComponentProps<StringMutationCamel>): JSX.Element | null {
	function canEdit(): boolean {
		return editableFields.includes(FIELD_NAME);
	}

	function hasError(): boolean {
		const { fields } = errors;
		return fields.includes(FIELD_NAME);
	}

	const value = getValue(data);
	const onChangeHandler = (
		_: React.SyntheticEvent<HTMLInputElement>,
		event: InputOnChangeData
	): void => {
		onChange(_, {
			name: FIELD_NAME,
			value: {
				value: event.value
			}
		});
	};

	if (canEdit()) {
		return (
			<Form.Input
				error={hasError()}
				fluid={true}
				id={FIELD_NAME}
				label={LABEL}
				name={FIELD_NAME}
				onChange={onChangeHandler}
				placeholder="Sitka"
				value={value}
			/>
		);
	}

	if (value) {
		return (
			<Form.Field>
				<label>{LABEL}</label>
				<span>{value}</span>
			</Form.Field>
		);
	}

	return null;
}

PartnerName.FIELD_NAME = FIELD_NAME;
PartnerName.validate = validate;

export default PartnerName;
