import * as React from 'react';

import { Filter } from 'hooks/pagination';
import { usePaginationContext } from 'contexts/PaginationContext';

import { FilterMenu, FilterMenuProps } from 'components/Filters';

function ParameterFilterFormControls({
	filters,
	loading
}: Pick<FilterMenuProps, 'filters' | 'loading'>): JSX.Element {
	const { getFilter, setFilter, removeFilter } = usePaginationContext();

	const fields = filters.map(field => {
		const filter = getFilter(field.attribute);
		const value = filter ? filter.value : undefined;

		return {
			...field,
			value
		};
	});

	function changeFilter(attribute: Filter['attribute'], value: Filter['value']): void {
		setFilter({ attribute, value });
	}

	return (
		<FilterMenu
			filters={fields}
			onChange={changeFilter}
			onRemove={removeFilter}
			loading={loading}
		/>
	);
}

export default ParameterFilterFormControls;
