import * as React from 'react';

import { css, cx } from 'emotion';

import { borderRadius, mediaQueries } from 'styles';
import { ShowHideButton } from 'components/Buttons';
import { Paragraph } from 'display';

const BORDER_STYLE = '1px solid #A9A9A9';
const TEXT_COLOR = '#5F5F5F';

const styles = {
	drawer: css`
		border-bottom-left-radius: ${borderRadius.l1};
		border-bottom-right-radius: ${borderRadius.l1};

		border-bottom: ${BORDER_STYLE};
		border-left: ${BORDER_STYLE};
		border-right: ${BORDER_STYLE};

		color: ${TEXT_COLOR};
		justify-content: space-between;
		margin-bottom: 1.5em;
		padding: 0.5em 1em;

		* {
			margin-bottom: 0 !important;
		}

		${mediaQueries.desktop} {
			justify-content: unset;
		}
	`,
	flex: css`
		align-content: center;
		display: flex;

		& > *:first-child + * {
			margin-left: 1.5em !important;
		}
	`,
	hide: css`
		display: none;
	`,
	show: css`
		display: block;
		margin-bottom: 2em;
	`
};

interface DrawerProps {
	children: React.ReactNode;
	label: string;
	defaultOpen: boolean;
	emptyLabel?: string;
	header: React.ReactNode;
}

interface DrawerState {
	open: boolean;
}

const defaultProps = {
	defaultOpen: false
};

type DefaultProps = Readonly<typeof defaultProps>;
type ComponentProps = DrawerProps & DefaultProps;

function DrawerBox({ children }: { children: React.ReactNode }): JSX.Element {
	return <div className={cx(styles.flex, styles.drawer)}>{children}</div>;
}

class Drawer extends React.Component<ComponentProps, DrawerState> {
	public static defaultProps = defaultProps;

	constructor(props: ComponentProps) {
		super(props);

		this.state = {
			open: props.defaultOpen
		};

		this.toggleDrawer = this.toggleDrawer.bind(this);
	}

	public render(): JSX.Element {
		const { open } = this.state;

		if (this.isEmpty()) {
			return this.renderEmpty();
		}

		if (open) {
			return this.renderOpen();
		}

		return this.renderClosed();
	}

	private isEmpty(): boolean {
		const { children, emptyLabel } = this.props;

		return !children && emptyLabel !== undefined;
	}

	private toggleDrawer(): void {
		this.setState(state => ({
			open: !state.open
		}));
	}

	private renderActionButton(): JSX.Element {
		const { open } = this.state;

		return (
			<div>
				<ShowHideButton onClick={this.toggleDrawer} open={open} />
			</div>
		);
	}

	private renderContents({ show }: { show: boolean }): JSX.Element {
		// Hide instead of unmount so that children state
		// is preserved.
		const className = show ? styles.show : styles.hide;
		const { children, header } = this.props;

		return (
			<div className={className}>
				<div className={styles.flex}>
					{header}
					{this.renderActionButton()}
				</div>
				{children}
			</div>
		);
	}

	private renderClosed(): JSX.Element {
		return (
			<React.Fragment>
				{this.renderContents({ show: false })}
				{this.renderDrawerLabel()}
			</React.Fragment>
		);
	}

	private renderDrawerLabel(): JSX.Element {
		const { label } = this.props;

		return (
			<DrawerBox>
				<Paragraph size={Paragraph.Size.T4}>{label}</Paragraph>
				{this.renderActionButton()}
			</DrawerBox>
		);
	}

	private renderEmpty(): JSX.Element {
		const { emptyLabel } = this.props;
		return (
			<DrawerBox>
				<Paragraph size={Paragraph.Size.T4}>{emptyLabel}</Paragraph>
			</DrawerBox>
		);
	}

	private renderOpen(): JSX.Element {
		return <React.Fragment>{this.renderContents({ show: true })}</React.Fragment>;
	}
}

export default Drawer;
