import * as React from 'react';

import { Button, CardModal } from 'display';
import { DismissButton, EnableButton } from 'components/Buttons';

interface EnablePatientCommunicationsModalProps {
	onCancel: () => void;
	onConfirm: () => void;
	open: boolean;
}

function EnablePatientCommunicationsModal({
	onCancel,
	onConfirm,
	open
}: EnablePatientCommunicationsModalProps): JSX.Element {
	return (
		<CardModal open={open} data-testid="enablePatientCommunicationsModal">
			<CardModal.Title>Enable vMessages and sharing of vConsults?</CardModal.Title>
			<CardModal.Description>
				This option allows practitioners to send vMessages to patients as well as share
				vConsults with patients.
			</CardModal.Description>
			<Button.Group>
				<DismissButton onClick={onCancel} />
				<EnableButton onClick={onConfirm} />
			</Button.Group>
		</CardModal>
	);
}

export { EnablePatientCommunicationsModal, EnablePatientCommunicationsModalProps };
