import { zIndexLayer } from 'styles/zIndex';
import loadScript from './loadScript';
import { SitkaLogger } from 'lib/sitkaLogger';

function loadZendesk(): Promise<void> {
	const SNIPPET_SRC = `https://static.zdassets.com/ekr/snippet.js?key=${process.env.REACT_APP_ZENDESK_ID}`;

	window.zESettings = window.zESettings || {
		webWidget: {
			zIndex: zIndexLayer.FIXED
		}
	};

	return loadScript(SNIPPET_SRC, {
		id: 'ze-snippet',
		async: true
	});
}

function zendeskExists(
	namespace: ZendeskGlobalNamespace | undefined | null
): namespace is ZendeskGlobalNamespace {
	return 'zE' in window && Boolean(window.zE);
}

function safelyRun<Result>(callback: (zendesk: ZendeskGlobalNamespace) => Result): Result | void {
	// Protects against exceptions in case Zendesk was not loaded or if it was
	// blocked in the browser.
	if (zendeskExists(window.zE)) {
		try {
			return callback(window.zE);
		} catch (e) {
			SitkaLogger.logMessage(e);
		}
	}
}

function identifyVisitor({ name, email }: { name: string; email: string }): void {
	safelyRun((zendesk: ZendeskGlobalNamespace): void => {
		zendesk('webWidget', 'identify', {
			name,
			email
		});
	});
}

export { loadZendesk, identifyVisitor };
