import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { ResendConversationMessageNotificationRequest } from 'thriftgen/api_types';
import {
	ResendConversationMessageNotificationRequestCamel,
	ResendConversationMessageNotificationResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function resendConversationMessageNotification(
	params: ResendConversationMessageNotificationRequestCamel
): Promise<ResendConversationMessageNotificationResponseCamel> {
	const client = getCommonClient();
	const request = new ResendConversationMessageNotificationRequest(objectCamelToSnake(params));

	return client.resend_conversation_message_notification(request).then(objectSnakeToCamel);
}

export { resendConversationMessageNotification };
