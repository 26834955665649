import { responseInterface } from 'swr';
import { GetConsultRequestCamel, UserInfoCamel } from 'thriftgen/thriftCamelTypes';

import {
	getConsult,
	manageConsult as manageConsultRequest,
	reportEvent as reportEventRequest,
	ReportEventParams,
	updateUser
} from 'api/common';
import {
	ManageConsultRequestAction,
	ManageConsultUpdateConsultDataAction
} from 'api/common/manageConsult';

import { ConsultContextMutators } from 'contexts';
import useQuery from '../useQuery';
import { GetConsultResponse } from 'thriftgen/api_types';

type ConsultQueryResult = GetConsultResponse;

type UseConsultQuery = {
	data: ConsultQueryResult | undefined;
	error: responseInterface<GetConsultResponse, Error>['error'];
} & ConsultContextMutators;

function useConsultQuery({ consultId }: GetConsultRequestCamel): UseConsultQuery {
	const key = `consults/${consultId}`;
	const { data, error, mutate } = useQuery<ConsultQueryResult>(key, () =>
		getConsult({ consultId })
	);

	async function manageConsult(args: ManageConsultRequestAction): Promise<void> {
		await mutate(manageConsultRequest(args), false);
	}

	async function reportEvent(args: ReportEventParams): Promise<void> {
		await reportEventRequest(args).then(({ consult }) =>
			mutate(
				currentData =>
					Promise.resolve({
						...currentData,
						consult
					}),
				false
			)
		);
	}

	async function revalidate(): Promise<void> {
		await mutate();
	}

	async function updatePatient(updates: UserInfoCamel): Promise<void> {
		await mutate(
			(currentData: ConsultQueryResult) =>
				updateUser({
					userId: currentData.consult.patient_id,
					updates
				}).then(({ user: updatedUser }) => ({
					...currentData,
					users: currentData.users.map(user =>
						updatedUser.user_id === user.user_id ? updatedUser : user
					)
				})),
			false
		);
	}

	// This function is needed because we are combining redux and context state management
	// strategies in the consult request form.  There is an opportunity to refactor
	// the consult request form at a later time to solely use the consult context, and if that happens,
	// this function can be removed
	async function updateConsultWithoutRevalidation(args: ConsultQueryResult): Promise<void> {
		await mutate(args, false);
	}

	async function redactConsultData(args: ManageConsultUpdateConsultDataAction): Promise<void> {
		await mutate(
			manageConsultRequest({
				consultId: args.consultId,
				consultDataUpdates: [
					{
						consultDataId: args.consultDataId,
						update: {
							redactedReason: args.redactedReason
						}
					}
				]
			}),
			false
		);
	}

	async function restoreConsultData(args: ManageConsultUpdateConsultDataAction): Promise<void> {
		await mutate(
			manageConsultRequest({
				consultId: args.consultId,
				consultDataUpdates: [
					{
						consultDataId: args.consultDataId,
						update: {
							redactedReason: undefined
						}
					}
				]
			}),
			false
		);
	}

	return {
		data,
		error,
		manageConsult,
		reportEvent,
		revalidate,
		updatePatient,
		updateConsultWithoutRevalidation,
		redactConsultData,
		restoreConsultData
	};
}

export { UseConsultQuery, useConsultQuery };
