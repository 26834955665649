import { Search, SearchProps } from 'display';
import debounce from 'lib/debounce';
import React, { useCallback, useState } from 'react';
import { SearchFilterField } from '../types';

const DEBOUNCE_DELAY = 300;

function SearchFilter({
	onChange,
	placeholder = 'Search',
	value = ''
}: Omit<SearchFilterField, 'type'>): JSX.Element {
	const [realtimeValue, setRealtimeValue] = useState<string>(value);

	const debouncedOnChange = useCallback(debounce(onChange, DEBOUNCE_DELAY), []);

	function onSearchChange(_: React.MouseEvent<HTMLElement>, data: SearchProps): void {
		if (typeof data.value === 'string') {
			setRealtimeValue(data.value);
			debouncedOnChange(data.value);
		}
	}

	return (
		<Search
			onSearchChange={onSearchChange}
			placeholder={placeholder}
			showNoResults={false}
			value={realtimeValue}
		/>
	);
}

export default SearchFilter;
