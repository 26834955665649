import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { ManageCaseRequest } from 'thriftgen/api_types';
import { ManageCaseRequestCamel, ManageCaseResponseCamel } from 'thriftgen/thriftCamelTypes';

async function manageCase(args: ManageCaseRequestCamel): Promise<ManageCaseResponseCamel> {
	const client = getCommonClient();
	const request = new ManageCaseRequest(objectCamelToSnake(args));

	const response = await client.manage_case(request);
	return objectSnakeToCamel(response);
}

export default manageCase;
