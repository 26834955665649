import * as React from 'react';
import noop from 'lib/noop';

// Sourced from https://overreacted.io/making-setinterval-declarative-with-react-hooks/

function useInterval(callback: () => void, delay: number | null): void {
	const savedCallback = React.useRef<() => void>();

	// Remember the latest callback.
	React.useEffect((): void => {
		savedCallback.current = callback;
	}, [callback]);

	// Set up the interval.
	React.useEffect(() => {
		function tick() {
			if (savedCallback.current) {
				savedCallback.current();
			}
		}

		if (delay !== null) {
			const id = setInterval(tick, delay);
			return () => clearInterval(id);
		}

		return noop;
	}, [delay]);
}

export { useInterval };
