import { apiCaller, apiCallerWithToast } from '../requests';
import {
	logout,
	oidcLogin,
	redeemPatientPasswordlessAuthenticationToken,
	redeemSharedPatientLinkToken
} from 'api/auth';
import { handleAuthResponse, handleLogoutResponse } from './auth.actions';
import sendTokenVerificationCode from 'api/auth/tokens/sendTokenVerificationCode';

export const sendLogoutWithToast = apiCallerWithToast({
	apiCall: logout,
	action: handleLogoutResponse
});

export const sendOidcLogin = apiCaller({
	apiCall: oidcLogin,
	action: handleAuthResponse
});

export const sendRedeemPatientPasswordlessAuthentication = apiCaller({
	apiCall: redeemPatientPasswordlessAuthenticationToken,
	action: handleAuthResponse
});

export const sendRedeemSharedPatientLinkToken = apiCaller({
	apiCall: redeemSharedPatientLinkToken,
	action: handleAuthResponse
});

export const sendSendTokenVerificationCode = apiCallerWithToast({
	apiCall: sendTokenVerificationCode
});
