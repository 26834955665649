import * as React from 'react';
import { UserCamel } from 'thriftgen/thriftCamelTypes';

import { getPatientName } from 'lib/userHelpers';

import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = UserCamel['firstName'] | UserCamel['lastName'] | null;
type DisplayPatientNameComponent = DataFormFieldComponent<string, DataType>;
type DisplayPatientNameComponentProps = DataFormFieldComponentProps<DataType>;

const DisplayPatientName: DisplayPatientNameComponent = ({
	data
}: DisplayPatientNameComponentProps): JSX.Element => {
	const content =
		data.firstName && data.lastName ? getPatientName(data as unknown as UserCamel) : null;

	return <ReadOnlyField label="Name">{content}</ReadOnlyField>;
};

DisplayPatientName.FIELD_NAME = 'fullName';
DisplayPatientName.SUB_FIELD_NAMES = ['firstName', 'lastName'];
DisplayPatientName.validate = alwaysValid;

export default DisplayPatientName;
