import * as React from 'react';
import PropTypes from 'prop-types';

import { Message, Paragraph } from 'display/';

function ConsultLoadError({ message }) {
	return (
		<Message negative>
			<Message.Header>Unable to load consult.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

ConsultLoadError.propTypes = {
	message: PropTypes.string.isRequired
};

export default ConsultLoadError;
