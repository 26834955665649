import { User } from 'thriftgen/api_types';

enum UsersActionType {
	SET_USER = 'users/setUser',
	SET_USERS = 'users/setUsers'
}

interface BaseUsersAction {
	type: UsersActionType;
}

interface SetUser extends BaseUsersAction {
	type: UsersActionType.SET_USER;
	user: User;
}

interface SetUsers extends BaseUsersAction {
	type: UsersActionType.SET_USERS;
	users: User[];
}

interface UsersState {
	byId: {
		[key: string]: User;
	};
}

type UsersActions = SetUser | SetUsers;

export { BaseUsersAction, SetUser, SetUsers, UsersActions, UsersActionType, UsersState };
