import { Form } from 'display';
import * as React from 'react';
import { DataFormFieldComponentProps } from '../DataFormTypes';
import { getFieldsAndSubfieldNames } from '../Fields/helpers';
import Billable from './Billable';
import Reportable from './Reportable';
import Support from './Support';

const LEGEND = ' Billing';
const FIELDS = [Reportable, Support, Billable];

function PartnerUserBilling(props: DataFormFieldComponentProps): JSX.Element {
	return (
		<Form.Group grouped>
			<label>{LEGEND}</label>
			{FIELDS.map(Field => {
				return <Field key={Field.name} {...props} />;
			})}
		</Form.Group>
	);
}

PartnerUserBilling.FIELD_NAME = LEGEND;
PartnerUserBilling.SUB_FIELD_NAMES = getFieldsAndSubfieldNames(FIELDS);
PartnerUserBilling.validate = () => null;

export default PartnerUserBilling;
