import * as React from 'react';

import PhoneNumberField from './PhoneNumber';
import { Paragraph } from 'display';

import { buildFieldVariation } from '../helpers';

const LABEL = 'Your phone number';

function renderField(field) {
	return (
		<React.Fragment>
			{field}
			<Paragraph size={Paragraph.Size.T4}>
				We only use your number to send you notifications about important consults.
			</Paragraph>
		</React.Fragment>
	);
}

export default buildFieldVariation({
	render: renderField,
	Field: PhoneNumberField,
	label: LABEL
});
