import {
	ClearFilters,
	Filter,
	ParameterActions,
	ParameterActionType,
	Parameters,
	RemoveFilter,
	RemoveFilterValue,
	SetFilter,
	SetPageCursor,
	SetPageSize,
	SetSorts,
	SetAllFilters
} from './types';
import { Sort } from 'thriftgen/api_types';

function getFilter(state: Parameters, attribute: string): Filter | undefined {
	return state.filters.find(filter => filter.attribute === attribute);
}

function clearFilters(): ClearFilters {
	return {
		type: ParameterActionType.CLEAR_FILTERS
	};
}

function removeFilter(attribute: Filter['attribute']): RemoveFilter {
	return {
		type: ParameterActionType.REMOVE_FILTER,
		attribute
	};
}

function removeFilterValue(
	attribute: Filter['attribute'],
	value: Filter['value']
): RemoveFilterValue {
	return {
		type: ParameterActionType.REMOVE_FILTER_VALUE,
		attribute,
		value
	};
}

function setFilter(filter: Filter): SetFilter {
	return {
		type: ParameterActionType.SET_FILTER,
		filter
	};
}

function setAllFilters(filters: Filter[]): SetAllFilters {
	return {
		type: ParameterActionType.SET_ALL_FILTERS,
		filters
	};
}

function setPageCursor(pageCursor: Parameters['pageCursor']): SetPageCursor {
	return {
		type: ParameterActionType.SET_PAGE_CURSOR,
		pageCursor
	};
}

function setPageSize(pageSize: Parameters['pageSize']): SetPageSize {
	return {
		type: ParameterActionType.SET_PAGE_SIZE,
		pageSize
	};
}

function setSorts(sorts: Sort[]): SetSorts {
	return {
		type: ParameterActionType.SET_SORTS,
		sorts
	};
}

const parameterActionCreators = {
	setFilter,
	setAllFilters,
	clearFilters,
	removeFilter,
	removeFilterValue,
	setPageCursor,
	setPageSize,
	setSorts
};

function setUpdatedFilters(state: Parameters, action: SetFilter): Parameters['filters'] {
	const isOtherFilter = (filter: Filter): boolean => filter.attribute !== action.filter.attribute;
	return [...state.filters.filter(isOtherFilter), action.filter];
}

function removeValueFromFilters(
	state: Parameters,
	action: RemoveFilterValue
): Parameters['filters'] {
	const isOtherFilter = (filter: Filter): boolean => filter.attribute !== action.attribute;
	const newFilters = [...state.filters.filter(isOtherFilter)];

	const currentFilter = state.filters.find(filter => filter.attribute === action.attribute);
	if (currentFilter && Array.isArray(currentFilter.value)) {
		currentFilter.value = currentFilter.value.filter(
			filterValue => filterValue !== action.value
		);
		if (currentFilter.value.length) {
			newFilters.push(currentFilter);
		}
	}

	return newFilters;
}

function parametersReducer(state: Parameters, action: ParameterActions): Parameters {
	switch (action.type) {
		case ParameterActionType.SET_FILTER:
			return {
				...state,
				filters: setUpdatedFilters(state, action),
				pageCursor: null
			};
		case ParameterActionType.SET_ALL_FILTERS:
			return {
				...state,
				filters: action.filters,
				pageCursor: null
			};
		case ParameterActionType.CLEAR_FILTERS:
			return {
				...state,
				filters: [],
				pageCursor: null
			};
		case ParameterActionType.SET_PAGE_CURSOR:
			return {
				...state,
				pageCursor: action.pageCursor
			};
		case ParameterActionType.SET_PAGE_SIZE:
			return {
				...state,
				pageSize: action.pageSize,
				pageCursor: null
			};
		case ParameterActionType.REMOVE_FILTER:
			return {
				...state,
				filters: state.filters.filter(({ attribute }) => attribute !== action.attribute),
				pageCursor: null
			};
		case ParameterActionType.REMOVE_FILTER_VALUE:
			return {
				...state,
				filters: removeValueFromFilters(state, action),
				pageCursor: null
			};
		case ParameterActionType.SET_SORTS:
			return {
				...state,
				sorts: action.sorts,
				pageCursor: null
			};
		default:
			throw new Error('Unknown action type');
	}
}

export { parametersReducer, parameterActionCreators, getFilter };
