function clearTimeoutIfExists(id) {
	if (id !== null) {
		clearTimeout(id);
	}
}

function debounce(fn, delay = 100) {
	let timeout = null;
	function debouncedFunction(...args) {
		clearTimeoutIfExists(timeout);
		timeout = setTimeout(() => fn.apply(this, args), delay);
	}

	debouncedFunction.cancel = function () {
		clearTimeoutIfExists(timeout);
	};

	return debouncedFunction;
}

export default debounce;
