import createActivityDetector from 'activity-detector';
import { SitkaEvent } from 'lib/events';
import { localStorage as LocalStorage } from 'lib/Storage';
import React, { useState } from 'react';
import { useInterval } from '../useInterval';

const LAST_ACTIVITY_DETECTED_STORAGE_KEY = 'LAST_ACTIVITY_DETECTED';

interface UseUserActivityOptions {
	delay: number | null;
	onActive: () => void;
	onIdle: () => void;
}

interface Detector {
	stop: () => void;
	on: (event: string, callback: () => void) => void;
	init: () => void;
}

interface UseUserActivityResult {
	start: () => void;
	stop: () => void;
}

const ACTIVITY_EVENTS = [
	// These are default events from the `activity-detector` package.
	'click',
	'mousemove',
	'keydown',
	'DOMMouseScroll',
	'mousewheel',
	'mousedown',
	'touchstart',
	'touchmove',
	'focus',
	// This event is a custom event dispatched by ourselves in
	// MultiStreamRecording each time a new recording chunk
	// is emitted.
	SitkaEvent.RECORDING,
	SitkaEvent.UPLOADING
];

function createDetector(delay: number | null): Detector {
	return createActivityDetector({
		autoInit: false,
		timeToIdle: delay,
		activityEvents: ACTIVITY_EVENTS,
		inactivityEvents: []
	});
}

function deriveDetectorDelay(
	delay: UseUserActivityOptions['delay']
): UseUserActivityOptions['delay'] {
	if (delay === null) {
		return delay;
	}

	// Prevent having the timers run out of sync in case passed delay is below one second.
	// Three-fourths provides sufficient time to run one tick.
	if (delay <= 1000) {
		return Math.floor(delay * 0.75);
	}

	return delay - 1000;
}

function useUserActivity({
	delay,
	onActive,
	onIdle
}: UseUserActivityOptions): UseUserActivityResult {
	const [monitoring, setMonitoring] = useState<boolean>(false);
	const [userActive, setUserActive] = useState<boolean>(true);

	// Detector's interval is slightly shorter than the interval
	// which we setup to call activity/idleness callbacks
	// so that the latter is not called before the former.
	const detectorInterval = deriveDetectorDelay(delay);
	const [detector, setDetector] = useState<Detector>(createDetector(detectorInterval));

	const [lastDetectionTimestamp, setLastDetectionTimestamp] = useState<number>();

	React.useEffect(() => {
		detector.on('active', () => setUserActive(true));
		detector.on('idle', () => setUserActive(false));

		if (monitoring) {
			detector.init();
		}

		return () => {
			if (monitoring) {
				detector.stop();
			}
		};
	}, [detector, monitoring]);

	React.useEffect(() => {
		setDetector(createDetector(detectorInterval));
	}, [detectorInterval]);

	function wasActivityDetectedInADifferentTab(): boolean {
		return !!(
			lastDetectionTimestamp &&
			lastDetectionTimestamp < LocalStorage.get(LAST_ACTIVITY_DETECTED_STORAGE_KEY)
		);
	}

	useInterval(
		() => {
			const now = Date.now();
			if (userActive) {
				LocalStorage.set(LAST_ACTIVITY_DETECTED_STORAGE_KEY, now);
				onActive();
			} else {
				if (wasActivityDetectedInADifferentTab()) {
					onActive();
				} else {
					onIdle();
				}
			}
			setLastDetectionTimestamp(now);
		},
		monitoring ? delay : null
	);

	return {
		start: () => setMonitoring(true),
		stop: () => setMonitoring(false)
	};
}

export { useUserActivity, UseUserActivityOptions, UseUserActivityResult };
