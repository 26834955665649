import { PaginationContextValue, usePaginationContext } from 'contexts/PaginationContext';
import { css } from 'emotion';
import * as React from 'react';
import { Message, Table, TableHeaderCellProps, TableRowProps } from 'display';
import { mediaQueries } from 'styles';
import { SortDirection } from 'thriftgen/api_types';

const DEFAULT_EMPTY_MESSAGE = 'No information to show at this time.';

type EnabledRowProps = Exclude<TableRowProps, 'children'>;

const sortDirectionMap: {
	[key: number]: 'ascending' | 'descending';
} = {
	[SortDirection.ASCENDING]: 'ascending',
	[SortDirection.DESCENDING]: 'descending'
};

const styles = {
	headerNoSort: css`
		&:hover {
			cursor: default !important;
		}
	`,
	nonCondensedTableOnly: css`
		${mediaQueries.tablet} {
			display: none !important;
		}
		${mediaQueries.mobile} {
			display: none !important;
		}
	`,
	condensedTableOnly: css`
		display: none;
		position: relative;
		${mediaQueries.mobile} {
			display: block !important;
		}
		${mediaQueries.tablet} {
			display: block !important;
		}
	`
};
interface DataTableColumn<DataObject> {
	header?: string;
	render: (data: DataObject) => React.ReactNode;
	renderMobile?: (data: DataObject) => React.ReactNode;
	sortKeys?: string[];
}

interface DataTableProps<DataObject> {
	columns: Array<DataTableColumn<DataObject>>;
	data: DataObject[];
	emptyMessage?: string;
	setRowProps?: (data: DataObject) => EnabledRowProps;
	'data-testid'?: string;
	className?: string;
}

function defaultSetRowProps(): TableRowProps {
	return {};
}

function renderHeader<DataObject>(
	column: DataTableColumn<DataObject>,
	index: number,
	paginationContext: PaginationContextValue | null
): JSX.Element {
	const headerCellProps: TableHeaderCellProps = {};
	if (paginationContext) {
		if (paginationContext.parameters.sorts?.length) {
			const sortKeys = paginationContext.parameters.sorts.map(sort => sort.attribute);
			if (column.sortKeys?.join(',') === sortKeys.join(',')) {
				headerCellProps.sorted =
					sortDirectionMap[paginationContext.parameters.sorts[0].direction];
			}
		}
		if (column.sortKeys) {
			headerCellProps.onClick = () => {
				const newSortDirection =
					headerCellProps.sorted === 'ascending'
						? SortDirection.DESCENDING
						: SortDirection.ASCENDING;

				const newSortKeys = (column.sortKeys || []).map(sortKey => ({
					attribute: sortKey,
					direction: newSortDirection
				}));

				paginationContext.setSorts(newSortKeys);
			};
		} else {
			headerCellProps.className = styles.headerNoSort;
		}
	}

	return (
		<Table.HeaderCell key={`header-${index}`} {...headerCellProps}>
			{column.header}
		</Table.HeaderCell>
	);
}

function DataTable<DataObject>({
	columns,
	data,
	emptyMessage = DEFAULT_EMPTY_MESSAGE,
	setRowProps = defaultSetRowProps,
	className,
	...rest
}: DataTableProps<DataObject>): JSX.Element {
	const isEmpty = data.length === 0;
	const dataTestId = rest['data-testid'] || 'dataTable';

	let paginationContext: PaginationContextValue | null;
	try {
		const paginationContextValue = usePaginationContext();
		paginationContext = paginationContextValue;
	} catch (e) {
		// Pagination context is only used inside of this component for sorting
		// If none is provided to this component, then only sorting functionality
		// will not work, but everything else will function normally.
		paginationContext = null;
	}

	const sortable = !!(columns.filter(column => column.sortKeys).length && paginationContext);

	function renderRow(item: DataObject, rowIndex: number): JSX.Element {
		function renderCell(column: DataTableColumn<DataObject>, cellIndex: number): JSX.Element {
			return (
				<Table.Cell
					className={hasMobileStyles ? styles.nonCondensedTableOnly : undefined}
					key={`cell-${cellIndex}`}>
					{column.render(item)}
				</Table.Cell>
			);
		}

		function renderCellMobile(
			column: DataTableColumn<DataObject>,
			cellIndex: number
		): JSX.Element {
			return (
				<React.Fragment key={`cell-${cellIndex}`}>
					{column.renderMobile ? (
						column.renderMobile(item)
					) : (
						<div>{column.render(item)}</div>
					)}
				</React.Fragment>
			);
		}
		const row = columns.map(renderCell);
		const rowProps = setRowProps(item);

		const mobileRow = hasMobileStyles ? (
			<Table.Cell key={`cell-mobile-display`} className={styles.condensedTableOnly}>
				{columns.map(renderCellMobile)}
			</Table.Cell>
		) : (
			<></>
		);

		return (
			<Table.Row
				key={`row-${rowIndex}`}
				{...rowProps}
				data-testid={`dataTableRow-${rowIndex}`}>
				{row}
				{mobileRow}
			</Table.Row>
		);
	}

	const hasMobileStyles = !!columns.filter(column => column.renderMobile).length;

	return (
		<React.Fragment>
			<Table
				basic="very"
				data-testid={dataTestId}
				size="small"
				sortable={sortable}
				unstackable={hasMobileStyles}
				className={className}>
				<Table.Header
					className={hasMobileStyles ? styles.nonCondensedTableOnly : undefined}>
					<Table.Row>
						{columns.map((column, index) =>
							renderHeader(column, index, paginationContext)
						)}
					</Table.Row>
				</Table.Header>
				<Table.Body>{data.map((row, index) => renderRow(row, index))}</Table.Body>
			</Table>
			{isEmpty && <Message info={true}>{emptyMessage}</Message>}
		</React.Fragment>
	);
}

export default DataTable;
export { DataTableColumn, DataTableProps };
