import { getUser } from 'api/common';
import useQuery from 'swr';
import { GetUserResponse } from 'thriftgen/api_types';
import { GetUserRequestCamel } from 'thriftgen/thriftCamelTypes';
import { UserQuery } from './types';

function useUserQuery({ userId }: GetUserRequestCamel): UserQuery {
	const key = `user/${userId}`;
	const { data, error, mutate } = useQuery<GetUserResponse>(key, () => getUser({ userId }));

	async function revalidate(): Promise<void> {
		await mutate();
	}

	const user = data
		? {
				...data.user,
				partners: data.partners
		  }
		: undefined;

	return {
		user,
		error,
		revalidate
	};
}

export default useUserQuery;
