import * as React from 'react';
import { CaseCamel } from 'thriftgen/thriftCamelTypes';

import RouteUrls from 'routing/RouteUrls';

import LinkToDetailPageButton from './LinkToDetailPageButton';

interface LinkToCaseDetailButtonProps {
	caseId: CaseCamel['caseId'];
}

function LinkToCaseDetailButton({ caseId }: LinkToCaseDetailButtonProps): JSX.Element {
	return (
		<LinkToDetailPageButton link={RouteUrls.buildUrl(RouteUrls.Key.CASE_DETAIL, { caseId })} />
	);
}

export default LinkToCaseDetailButton;
