import { CONSULT_FILTERS } from 'api/customFilters';
import { FilterField } from 'display';

export default (): FilterField => {
	const stateFilter: FilterField = {
		label: 'Status',
		attribute: CONSULT_FILTERS.CONSULTS_BY_CLINICAL_REVIEW,
		values: [
			{ label: 'Needs Review', value: false },
			{ label: 'Reviewed', value: true }
		]
	};

	return stateFilter;
};
