import * as React from 'react';

interface PatientPasswordlessAuthContextData {
	tokenId: string;
	redirect: string;
}

const PatientPasswordlessAuthContext = React.createContext<
	PatientPasswordlessAuthContextData | undefined
>(undefined);

function usePatientPasswordlessAuthContext(): PatientPasswordlessAuthContextData {
	const context = React.useContext(PatientPasswordlessAuthContext);

	if (context === undefined) {
		throw new Error('No patient passwordless auth data has been set');
	}

	return context;
}

const PatientPasswordlessAuthProvider = PatientPasswordlessAuthContext.Provider;

export { PatientPasswordlessAuthProvider, usePatientPasswordlessAuthContext };
