import reducerRegistry from 'store/registry';
import { AuthState, AuthActionTypes } from './auth.types';

const reducerName = 'auth';

const LOGIN = 'auth/login';
const LOGOUT = 'auth/logout';
const REMAINING_ATTEMPTS = 'auth/remainingAttempts';

const initialAuthState: AuthState = {
	loggedIn: false,
	user: null,
	userId: undefined,
	remainingAttempts: 0
};

function authReducer(state = initialAuthState, action: AuthActionTypes): AuthState {
	switch (action.type) {
		case LOGIN:
			return Object.assign({}, state, {
				loggedIn: true,
				user: action.user,
				userId: action.user.user_id,
				remainingAttempts: 0
			});

		case LOGOUT:
			return Object.assign({}, initialAuthState);

		case REMAINING_ATTEMPTS:
			return {
				...state,
				remainingAttempts: action.remainingAttempts
			};

		default:
			return state;
	}
}

reducerRegistry.register(reducerName, authReducer);

export { authReducer, initialAuthState, REMAINING_ATTEMPTS, LOGIN, LOGOUT };
