import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import buildFieldGroup from 'components/Forms/Fields/base/buildFieldGroup';
import buildCheckboxField from 'components/Forms/Fields/base/buildCheckboxField';
import { CONSULT_MISSING_INFO_REASON, ConsultMissingInfoReason } from 'thriftgen/api_types';
import { translateThriftEnum } from 'lib/i18n';

const FIELD_NAME = 'missingInfoReasons';
const FIELD_LABEL = 'Select missing information:';

interface MissingInfoReasonOption {
	key: ConsultMissingInfoReason;
	value: ConsultMissingInfoReason;
	text: string;
}

const OPTIONS: MissingInfoReasonOption[] = Object.values(ConsultMissingInfoReason).map(
	(value: number) => ({
		key: value,
		value,
		text: translateThriftEnum(value, CONSULT_MISSING_INFO_REASON)
	})
);

const reasonsFields = OPTIONS.map(option => {
	return buildCheckboxField({
		fieldName: option.key.toString(),
		label: option.text
	});
});

const CreateMissingInfoReasons: DataFormFieldComponent = buildFieldGroup({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	fields: reasonsFields,
	groupProps: { grouped: true }
});

export default CreateMissingInfoReasons;
