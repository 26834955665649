import * as React from 'react';

function WebcamSystemNotAllowed(): JSX.Element {
	return (
		<React.Fragment>
			Allow Chrome to use your camera.
			<br />
			<br />
			Open <strong>System preferences &gt; Security &amp; Privacy &gt; Camera</strong> and
			select <strong>Google Chrome</strong> to grant access.
		</React.Fragment>
	);
}

export default WebcamSystemNotAllowed;
