import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'display';
import Strings from 'lib/strings';

function AttachDocumentsButton({ disabled, onClick }) {
	return (
		<Button.Primary disabled={disabled} onClick={onClick}>
			{Strings.Buttons.ATTACH_DOCUMENTS}
		</Button.Primary>
	);
}

AttachDocumentsButton.defaultProps = {
	disabled: false
};

AttachDocumentsButton.propTypes = {
	disabled: PropTypes.bool,
	onClick: PropTypes.func.isRequired
};

export default AttachDocumentsButton;
