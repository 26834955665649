import { Button, ButtonType } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Partner } from 'thriftgen/api_types';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

function LinkToPartnerEditButton({ partnerId }: { partnerId: Partner['partner_id'] }): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			link={RouteUrls.buildUrl(RouteUrls.Key.PARTNER_EDIT, { partnerId })}
			text={Strings.Buttons.EDIT}
			size={Button.Size.TINY}
			classNames="partnerEditButton"
		/>
	);
}

export default LinkToPartnerEditButton;
