import * as React from 'react';

import { Button, ButtonProps, ButtonType } from 'display';
import Strings from 'lib/strings';

interface DismissButtonProps {
	buttonType?: ButtonType;
	disabled?: ButtonProps['disabled'];
	onClick: ButtonProps['onClick'];
}

function DismissButton({
	buttonType = ButtonType.MINIMAL,
	disabled = false,
	onClick
}: DismissButtonProps): JSX.Element {
	const ButtonVariation = Button[buttonType];

	return (
		<ButtonVariation disabled={disabled} onClick={onClick}>
			{Strings.Buttons.DISMISS}
		</ButtonVariation>
	);
}

export { DismissButton };
