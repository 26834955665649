import { objectSnakeToCamel } from 'lib/objectConverter';
import { getAthenaClient } from 'lib/ThriftClient';
import { AthenaGetOrCreateCaseRequest } from 'thriftgen/api_types';
import {
	AthenaGetOrCreateCaseRequestCamel,
	AthenaGetOrCreateCaseResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function athenaGetOrCreateCase({
	athenaPatientId,
	athenaPracticeId
}: AthenaGetOrCreateCaseRequestCamel): Promise<AthenaGetOrCreateCaseResponseCamel> {
	const client = getAthenaClient();
	const request = new AthenaGetOrCreateCaseRequest({
		athena_patient_id: athenaPatientId,
		athena_practice_id: athenaPracticeId
	});

	const response = await client.athena_get_or_create_case(request);
	return objectSnakeToCamel(response);
}

export default athenaGetOrCreateCase;
