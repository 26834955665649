// TODO: For when we add pagination, include the following filter import and add it to the sourcefile //import { PARTNER_FILTERS } from 'api/customFilters';
import { FilterType } from './types';
import Strings from 'lib/strings';

const PARTNER_SEARCH_FILTER = {
	attribute: 'name', // TODO: Replace this hardcoded value when we add pagination //PARTNER_FILTERS.PARTNER_NAME,
	placeholder: Strings.Labels.SEARCH_PARTNER_PLACEHOLDER,
	type: FilterType.SEARCH
};

export { PARTNER_SEARCH_FILTER };
