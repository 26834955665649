import {
	isUserAdmin,
	isUserMember,
	isUserPatient,
	isUserSitkaOperations,
	isUserSitkaMedicalAssociate
} from 'lib/userHelpers';
import * as React from 'react';
import { Redirect, RedirectProps, Route, RouteComponentProps } from 'react-router-dom';
import { User } from 'thriftgen/api_types';
import RouteUrls from './RouteUrls';

function buildPathFromLocation(location: RouteComponentProps['location']): string {
	let path = location.pathname;

	if (location.hash && location.hash.length) {
		path += location.hash;
	}

	if (location.search && location.search.length) {
		path += location.search;
	}

	return path;
}

function getLoginRedirect(
	location: RouteComponentProps['location'],
	history: RouteComponentProps['history']
): RedirectProps['to'] {
	const pathname = RouteUrls.buildUrl(RouteUrls.Key.LOGIN);

	if (history.action === 'REPLACE') {
		return {
			pathname
		};
	}

	return {
		pathname,
		state: {
			referrer: buildPathFromLocation(location)
		}
	};
}

function getPatientExpirationRedirect(): RedirectProps['to'] {
	return RouteUrls.buildUrl(RouteUrls.Key.PATIENT_SESSION_EXPIRATION);
}

function getHomePageRedirect(actor: User): string {
	if (isUserMember(actor)) {
		return RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_PCP);
	}

	if (isUserAdmin(actor) || isUserSitkaOperations(actor)) {
		return RouteUrls.buildUrl(RouteUrls.Key.MY_CASES);
	}

	if (isUserSitkaMedicalAssociate(actor)) {
		return RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_SMA);
	}

	if (isUserPatient(actor)) {
		return RouteUrls.buildUrl(RouteUrls.Key.PATIENT_DASHBOARD);
	}

	return RouteUrls.buildUrl(RouteUrls.Key.NO_PARTNERS_LANDING_PAGE);
}

function redirectCase({ match }: RouteComponentProps<{ caseId: string }>): JSX.Element {
	const { caseId } = match.params;
	const url = RouteUrls.buildUrl(RouteUrls.Key.CASE_DETAIL, { caseId });

	return <Redirect from={`/cases/${caseId}`} to={url} />;
}

function redirectConsult({ match }: RouteComponentProps<{ consultId: string }>): JSX.Element {
	const { consultId } = match.params;
	const url = RouteUrls.buildUrl(RouteUrls.Key.CONSULT_DETAIL, { consultId });

	return <Redirect from={`/consults/${consultId}`} to={url} />;
}

function redirectPartner({ match }: RouteComponentProps<{ partnerId: string }>): JSX.Element {
	const { partnerId } = match.params;
	const url = RouteUrls.buildUrl(RouteUrls.Key.PARTNER_DETAIL, { partnerId });

	return <Redirect from={`/partners/${partnerId}`} to={url} />;
}

function redirectUser({ match }: RouteComponentProps<{ userId: string }>): JSX.Element {
	const { userId } = match.params;
	const url = RouteUrls.buildUrl(RouteUrls.Key.USER_DETAIL, { userId });

	return <Redirect from={`/users/${userId}`} to={url} />;
}

function renderCaseRedirect(): JSX.Element {
	return <Route path="/cases/:caseId" exact={true} render={redirectCase} />;
}

function renderConsultRedirect(): JSX.Element {
	return <Route path="/consults/:consultId" exact={true} render={redirectConsult} />;
}

function renderPartnerRedirect(): JSX.Element {
	return <Route path="/partners/:partnerId" exact={true} render={redirectPartner} />;
}

function renderUserRedirect(): JSX.Element {
	return <Route path="/users/:userId" exact={true} render={redirectUser} />;
}

export {
	getHomePageRedirect,
	getLoginRedirect,
	getPatientExpirationRedirect,
	renderCaseRedirect,
	renderConsultRedirect,
	renderPartnerRedirect,
	renderUserRedirect
};
