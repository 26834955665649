import * as React from 'react';
import { Button, ButtonProps } from 'display';

interface TryAgainButtonProps {
	onClick: ButtonProps['onClick'];
}

function TryAgainButton({ onClick }: TryAgainButtonProps): JSX.Element {
	return <Button.Primary onClick={onClick}>Try again</Button.Primary>;
}

export default TryAgainButton;
