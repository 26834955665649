import * as React from 'react';
import PropTypes from 'prop-types';
import { Loader } from 'display';

export default function Loading({ pastDelay }) {
	return pastDelay ? <Loader active /> : null;
}

Loading.propTypes = {
	pastDelay: PropTypes.bool.isRequired
};
