import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import { Button, ButtonType } from 'display';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

function LinkToCreatePartnerButton(): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			link={RouteUrls.buildUrl(RouteUrls.Key.PARTNER_NEW)}
			text={Strings.Buttons.CREATE_PARTNER}
			size={Button.Size.TINY}
			icon="plus"
			classNames="createPartnerButton"
		/>
	);
}

export default LinkToCreatePartnerButton;
