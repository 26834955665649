import RouteUrls, { RouteKey } from 'routing/RouteUrls';
import {
	Consult,
	ConsultData,
	ConsultState,
	CONSULT_CLOSED_STATES,
	CONSULT_OPEN_STATES,
	FAST_TRACK_SPECIALTIES,
	Specialty,
	User
} from 'thriftgen/api_types';
import { ConsultComposite } from '../hooks';
import { ConsultFileReviewType } from './consultFileReviewTypeHelpers';

function buildPdfReviewUrl(
	consultId: Consult['consult_id'],
	consultDataId: ConsultData['consult_data_id']
): string {
	const query = `?type=${ConsultFileReviewType.PDF}&id=${consultDataId}`;
	return RouteUrls.buildUrl(RouteUrls.Key.CONSULT_DATA_VIEWER, { consultId }, query);
}

function canAddConsultMessages(consult: Consult): boolean {
	return CONSULT_OPEN_STATES.includes(consult.state);
}

function consultHasResponse(consult: Consult): boolean {
	return consult.state === ConsultState.RESPONDED;
}

function canCloseOwnConsult(consult: Consult, actor: User): boolean {
	return (
		canAddConsultMessages(consult) &&
		consultHasResponse(consult) &&
		consult.requestor_id === actor.user_id
	);
}

function isClosedConsult({ state }: Consult): boolean {
	return CONSULT_CLOSED_STATES.includes(state);
}

function isDraftConsult({ state }: Consult | ConsultComposite): boolean {
	return state === ConsultState.DRAFT;
}

function isFastTrackConsult({ specialty }: Consult): boolean {
	return isFastTrackConsultSpecialty(specialty);
}

function isFastTrackConsultSpecialty(specialty: Specialty | undefined): boolean {
	return Boolean(specialty && FAST_TRACK_SPECIALTIES.includes(specialty));
}

function getConsultDataById(
	consultData: ConsultData[],
	id?: ConsultData['consult_data_id']
): ConsultData | null {
	const consultDatum = consultData.find(({ consult_data_id }): boolean => consult_data_id === id);

	if (!consultDatum) {
		return null;
	}

	return consultDatum;
}

function getConsultLink(
	consultComposite: ConsultComposite,
	isSitkaMedicalAssociate?: boolean
): string {
	let routeKey: RouteKey;

	if (consultComposite.state === ConsultState.DRAFT) {
		routeKey = RouteUrls.Key.CONSULT_REQUEST_DRAFT;
	} else if (isSitkaMedicalAssociate && !consultComposite.hasResponse) {
		routeKey = RouteUrls.Key.CONSULT_SMA_RESPONSE;
	} else {
		routeKey = RouteUrls.Key.CONSULT_DETAIL;
	}

	return RouteUrls.buildUrl(routeKey, {
		consultId: consultComposite.consultId
	});
}

export {
	canAddConsultMessages,
	canCloseOwnConsult,
	isClosedConsult,
	isDraftConsult,
	isFastTrackConsult,
	isFastTrackConsultSpecialty,
	buildPdfReviewUrl,
	getConsultDataById,
	getConsultLink
};
