import * as React from 'react';

import { BooleanFilterField } from '../types';

import SelectFilter from './SelectFilter';

/**
 * Semantic does not support boolean select options. We'll coerce the value in both directions, until we resolve by upgrading Semantic.
 * See: https://github.com/Semantic-Org/Semantic-UI-React/issues/2564
 */

const TRUE = {
	text: 'True',
	value: 'true',
	key: 'true'
};

const FALSE = {
	text: 'False',
	value: 'false',
	key: 'false'
};

const OPTIONS = [TRUE, FALSE];

function BooleanFilter({
	value,
	onChange,
	...props
}: Omit<BooleanFilterField, 'type'>): JSX.Element {
	function onSelectChange(changeValue: string): void {
		if (changeValue === TRUE.value) {
			onChange(true);
		} else if (changeValue === FALSE.value) {
			onChange(false);
		}
	}

	return (
		<SelectFilter
			onChange={onSelectChange}
			options={OPTIONS}
			value={value !== undefined ? String(value) : value}
			{...props}
		/>
	);
}

export default BooleanFilter;
