import * as React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import rehypeRaw from 'rehype-raw';

interface MarkdownViewerProps {
	content?: string;
}

export default function MarkdownViewer({ content }: MarkdownViewerProps): JSX.Element | null {
	if (content) {
		return (
			<ReactMarkdown
				remarkPlugins={[remarkGfm]}
				rehypePlugins={[rehypeRaw]}
				linkTarget="_blank"
				disallowedElements={['script', 'object', 'embed', 'link', 'code', 'pre']}
				unwrapDisallowed={true}>
				{content}
			</ReactMarkdown>
		);
	} else {
		return null;
	}
}
