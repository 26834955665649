function throttle(func, limit = 100) {
	let isThrottled = false;

	function throttledFunction() {
		if (!isThrottled) {
			func.apply(this, arguments);
			isThrottled = true;

			setTimeout(() => (isThrottled = false), limit);
		}
	}

	return throttledFunction;
}

export default throttle;
