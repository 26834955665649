import * as React from 'react';

import { Paragraph, Text } from 'display';

const COPY = `Sitka vConsults are not intended for emergent or urgent situations. If your patient requires immediate care, do not wait for a Sitka response.`;

function UrgentEmergentDisclaimer(): JSX.Element {
	return <Paragraph size={Text.Size.T4}>{COPY}</Paragraph>;
}

export { UrgentEmergentDisclaimer };
