import * as React from 'react';
import { css } from 'emotion';

import { Button } from 'display';
import { mediaQueries, zIndexLayer } from 'styles';
import Strings from 'lib/strings';

const styles = {
	container: css`
		display: none;

		${mediaQueries.desktop} {
			display: flex;
			flex-direction: column;

			position: fixed;
			bottom: 0;
			right: 0;
			padding: 0.5rem;
			z-index: ${zIndexLayer.FIXED};

			& *:last-child {
				margin-top: 0.5em;
			}
		}
	`
};

interface DocumentNavigationButtonsProps {
	disableNext: boolean;
	disablePrevious: boolean;
	onNextClick: () => void;
	onPreviousClick: () => void;
}

function DocumentNavigationButtons({
	disableNext,
	disablePrevious,
	onNextClick,
	onPreviousClick
}: DocumentNavigationButtonsProps): JSX.Element {
	return (
		<div className={styles.container}>
			<Button.Primary
				size={Button.Size.MINI}
				onClick={onPreviousClick}
				disabled={disablePrevious}>
				{Strings.Buttons.PREVIOUS_DOCUMENT}
			</Button.Primary>
			<Button.Primary size={Button.Size.MINI} onClick={onNextClick} disabled={disableNext}>
				{Strings.Buttons.NEXT_DOCUMENT}
			</Button.Primary>
		</div>
	);
}

export default DocumentNavigationButtons;
