import UserFromConsultContext from 'components/admin/User/UserFromConsultContext';
import { ConsultResponderSelect } from 'components/UserSelect';
import { UserSelectProps } from 'components/UserSelect/types';
import { Form } from 'display';
import noop from 'lib/noop';
import * as React from 'react';
import { ConsultCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponentProps } from '../DataFormTypes';

interface SpecialistProps {
	data: ConsultCamel;
	editableFields: DataFormFieldComponentProps['editableFields'];
	onChange: UserSelectProps['onChange'];
}

const FIELD_NAME = 'specialistId';

function canEdit(editableFields: SpecialistProps['editableFields']): boolean {
	return editableFields.includes(FIELD_NAME);
}

function getValue(data: SpecialistProps['data']): string | null {
	return data[FIELD_NAME];
}

function validate() {
	return null;
}

function Specialist({ data, editableFields = [], onChange = noop }: SpecialistProps) {
	const value = getValue(data);
	const { consultId, specialty, patientTreatmentState, patientId } = data;

	if (canEdit(editableFields)) {
		return (
			<ConsultResponderSelect
				consultId={consultId}
				specialty={specialty}
				patientTreatmentState={patientTreatmentState}
				patientIdForConsultCounts={patientId}
				key={FIELD_NAME}
				name={FIELD_NAME}
				onChange={onChange}
				value={value || ''}
			/>
		);
	}

	let user = <>Unassigned</>;

	if (value) {
		user = <UserFromConsultContext userId={value} />;
	}

	return <Form.Field>{user}</Form.Field>;
}

Specialist.FIELD_NAME = FIELD_NAME;
Specialist.validate = validate;

export default Specialist;
