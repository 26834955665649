import buildSectionHeadingField from './base/buildSectionHeadingField';

const FIELD_NAME = 'sectionInfoWrittenResponse';
const SUB_HEADER = 'Your written response will be sent to the requesting provider.';
const SectionInfoWrittenResponse = buildSectionHeadingField({
	fieldName: FIELD_NAME,
	subHeader: SUB_HEADER
});

export default SectionInfoWrittenResponse;
