import { useUserContext } from 'contexts';
import { SemanticICONS } from 'display';
import { useLazyAsyncCall } from 'hooks';
import React, { useState } from 'react';
import DeleteUserLicenseModal from 'components/Modals/DeleteUserLicenseModal';
import { objectSnakeToCamel } from 'lib/objectConverter';
import { UserLicenseCamel } from 'thriftgen/thriftCamelTypes';
import { SitkaLogger } from 'lib/sitkaLogger';

interface ViewPartnerDetailsActionProps {
	data: UserLicense;
	modalPortalId: string;
	children: React.ReactNode;
}

function ViewPartnerDetailsAction({
	data,
	modalPortalId,
	children
}: ViewPartnerDetailsActionProps): JSX.Element {
	const { user_license_id: userLicenseId } = data;

	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const openModal = () => {
		setModalOpen(true);
	};
	const closeModal = () => setModalOpen(false);

	const { user, updateUser } = useUserContext();
	const { dispatch: sendUpdateUser, loading, error } = useLazyAsyncCall(updateUser);

	async function onConfirm(): Promise<void> {
		const newLicenses = user.licenses
			.filter(userLicense => userLicense.user_license_id !== userLicenseId)
			.map(userLicense => objectSnakeToCamel(userLicense) as UserLicenseCamel);

		try {
			await sendUpdateUser({ licenses: newLicenses }).promise();
			closeModal();
		} catch (e) {
			SitkaLogger.logMessage(e);
		}
	}

	function onCancel(): void {
		closeModal();
	}

	return (
		<React.Fragment>
			<DeleteUserLicenseModal
				modalPortalId={modalPortalId}
				open={modalOpen}
				onConfirm={onConfirm}
				onCancel={onCancel}
				loading={loading}
				error={error}
				user={user}
				userLicense={data}
			/>
			<div onClick={openModal}>{children}</div>
		</React.Fragment>
	);
}

ViewPartnerDetailsAction.ICON = 'trash' as SemanticICONS;
ViewPartnerDetailsAction.TITLE = 'Delete';

export default ViewPartnerDetailsAction;
