import * as React from 'react';

import { ButtonProps } from 'semantic-ui-react';

interface AsyncButtonState {
	inProgress: boolean;
}

interface ConsumerProps {
	as: React.ComponentType<ButtonProps>;
	onClickAsync: () => Promise<unknown>;
}

export type AsyncButtonProps = ConsumerProps & ButtonProps;

class AsyncButton extends React.Component<AsyncButtonProps, AsyncButtonState> {
	private asyncAction: Promise<unknown> | null;

	constructor(props: AsyncButtonProps) {
		super(props);
		this.state = {
			inProgress: false
		};

		this.asyncAction = null;

		this.onClick = this.onClick.bind(this);
		this.resolveSafely = this.resolveSafely.bind(this);
		this.setInProgress = this.setInProgress.bind(this);
		this.unsetInProgress = this.unsetInProgress.bind(this);
	}

	public componentWillUnmount(): void {
		this.asyncAction = null;
	}

	public render(): JSX.Element {
		const {
			as: Component,
			loading: _loadingIgnored,
			disabled,
			onClick: _onClickIgnored,
			onClickAsync: _onClickAsyncIgnored,
			...rest
		} = this.props;
		const { inProgress } = this.state;
		return (
			<Component
				disabled={disabled || inProgress}
				loading={inProgress}
				onClick={this.onClick}
				{...rest}
			/>
		);
	}

	private onClick(): void {
		const resolveRequest = this.resolveSafely(this.unsetInProgress);
		this.setInProgress();

		this.asyncAction = this.props.onClickAsync().then(resolveRequest, resolveRequest);
	}

	private resolveSafely(
		callback: (args: unknown | Error) => void
	): (args: unknown | Error) => void {
		return (...args) => {
			if (this.asyncAction instanceof Promise) {
				callback(...args);
			}
		};
	}

	private setInProgress(): void {
		this.setState({ inProgress: true });
	}

	private unsetInProgress(): void {
		this.setState({ inProgress: false });
	}
}

export default AsyncButton;
