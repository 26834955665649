import { Button, Heading, Icon, Segment } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import { mediaQueries } from 'styles';

const DEFAULT_TITLE = 'Get ready';
const styles = {
	callToAction: css`
		margin-top: 2em;

		${mediaQueries.desktop} {
			margin-top: 4em;
		}
	`,
	container: css`
		padding: 0.5em 1em 1em !important;

		${mediaQueries.desktop} {
			padding: 4em 25% 1em !important;
		}
	`,
	header: css`
		.icon {
			display: none;
			${mediaQueries.desktop} {
				display: inline-block;
			}
		}

		margin-bottom: 0.5em;

		${mediaQueries.desktop} {
			margin-bottom: 1em;
		}
	`
};

interface LauncherProps {
	actions: JSX.Element[];
	content: React.ReactNode;
	title?: React.ReactNode;
}

function Launcher({ actions, content, title = DEFAULT_TITLE }: LauncherProps): JSX.Element {
	const actionsAlignment = React.Children.count(actions) === 1 ? 'center' : 'space-between';

	return (
		<Segment className={styles.container} textAlign="center" inverted={true} noMargins={true}>
			<div className={styles.header}>
				{typeof title === 'string' && (
					<React.Fragment>
						<Icon name="camera" size="big" />
						<Heading as={Heading.Type.H3} size={Heading.Size.T3}>
							{title}
						</Heading>
					</React.Fragment>
				)}
			</div>
			{content}
			<div className={styles.callToAction}>
				<Button.Group justifyContent={actionsAlignment}>{actions}</Button.Group>
			</div>
		</Segment>
	);
}

export default Launcher;
