import React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const FIELD_NAME = 'needsAdditionalInfo';
const LABEL = 'Check this box if you asked for additional information in your response.';

const SpecialistResponseMoreInfoCheckboxElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

function SpecialistResponseMoreInfoCheckbox(props: DataFormFieldComponentProps): JSX.Element {
	return <SpecialistResponseMoreInfoCheckboxElement key={FIELD_NAME} {...props} />;
}

SpecialistResponseMoreInfoCheckbox.FIELD_NAME = FIELD_NAME;
SpecialistResponseMoreInfoCheckbox.validate = () => null;

export default SpecialistResponseMoreInfoCheckbox;
