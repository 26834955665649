import buildSectionHeadingField from './base/buildSectionHeadingField';

const FIELD_NAME = 'sectionHeadingDocumentConsultResponse';
const LABEL = 'Document Consult Response';
const SUB_HEADER =
	'Complete the sections below to respond to the consult request. These can be completed in any order.';

const SectionHeadingDocumentConsultResponse = buildSectionHeadingField({
	fieldName: FIELD_NAME,
	label: LABEL,
	subHeader: SUB_HEADER
});

export default SectionHeadingDocumentConsultResponse;
