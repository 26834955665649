import * as React from 'react';
import { css } from 'emotion';

import { Container, Grid } from 'display';

import { PrimaryPaneComponent, SecondaryPaneComponent } from './types';
import { PrimaryPane } from './PrimaryPane';
import { SecondaryPane } from './SecondaryPane';

const styles = {
	grid: css`
		padding-bottom: 0 !important;
	`
};

interface ContentPanesProps {
	panes: {
		primary: JSX.Element;
		secondary: JSX.Element;
	};
}

class ContentPanes extends React.PureComponent<ContentPanesProps> {
	public static Primary: PrimaryPaneComponent = PrimaryPane;
	public static Secondary: SecondaryPaneComponent = SecondaryPane;

	public render(): JSX.Element {
		const { primary, secondary } = this.props.panes;

		return (
			<Container>
				<Grid className={styles.grid} columns="16" stackable={true}>
					{primary}
					{secondary}
				</Grid>
			</Container>
		);
	}
}

export { ContentPanes };
