import * as React from 'react';

import { Message, Paragraph } from 'display';

interface PartnerLoadErrorProps {
	message: string;
}

function PartnerLoadError({ message }: PartnerLoadErrorProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Failed to load partner information.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default PartnerLoadError;
