import * as React from 'react';
import PropTypes from 'prop-types';

import CredentialField from './Credential';

const noop = () => {};

function buildNameValidator(firstFieldName, lastFieldName, credentialsFieldName) {
	return function (data, required) {
		const firstName = data[firstFieldName];
		const lastName = data[lastFieldName];
		let fields = [];

		if (required && (!firstName || !lastName)) {
			return {
				fields: [firstFieldName, lastFieldName],
				messages: ['Please enter a first and last name.']
			};
		}

		if (firstName && !lastName) {
			fields.push(lastFieldName);
		}

		if (lastName && !firstName) {
			fields.push(firstFieldName);
		}

		if (fields.length > 0) {
			return {
				fields,
				messages: ['Please enter a first and last name or neither.']
			};
		}

		if (credentialsFieldName) {
			return CredentialField.validate(data, required);
		}

		return null;
	};
}

class FullName extends React.Component {
	includeEditableChildFields() {
		const { editableFields, fieldName, FirstName, LastName } = this.props;

		if (editableFields.includes(fieldName)) {
			return [...editableFields, FirstName.FIELD_NAME, LastName.FIELD_NAME];
		}

		return editableFields;
	}

	shouldRender() {
		const { data, editableFields, fieldName, FirstName, LastName } = this.props;

		return (
			data[fieldName] ||
			data[FirstName.FIELD_NAME] ||
			data[LastName.FIELD_NAME] ||
			editableFields.includes(fieldName) ||
			editableFields.includes(FirstName.FIELD_NAME) ||
			editableFields.includes(LastName.FIELD_NAME)
		);
	}

	render() {
		const { Credential, data, errors, FirstName, LastName, onChange, Title } = this.props;
		const allEditableFields = this.includeEditableChildFields();

		if (this.shouldRender()) {
			return (
				<React.Fragment>
					{Title !== null && (
						<Title
							data={data}
							editableFields={allEditableFields}
							errors={errors}
							onChange={onChange}
						/>
					)}
					<FirstName
						data={data}
						editableFields={allEditableFields}
						errors={errors}
						onChange={onChange}
					/>
					<LastName
						data={data}
						editableFields={allEditableFields}
						errors={errors}
						onChange={onChange}
					/>
					{Credential !== null && (
						<Credential
							data={data}
							editableFields={allEditableFields}
							errors={errors}
							onChange={onChange}
						/>
					)}
				</React.Fragment>
			);
		}

		return null;
	}
}

FullName.defaultProps = {
	Credential: null,
	data: {},
	editableFields: [],
	errors: {
		fields: [],
		messages: []
	},
	onChange: noop,
	Title: null
};

FullName.propTypes = {
	Credential: PropTypes.func,
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.object,
	fieldName: PropTypes.string.isRequired,
	FirstName: PropTypes.func.isRequired,
	LastName: PropTypes.func.isRequired,
	onChange: PropTypes.func,
	Title: PropTypes.func
};

export { buildNameValidator, FullName };
