import * as React from 'react';

import {
	getDefaultRecordingStreamData,
	getRecordingStreamData,
	getScreenStream,
	getWebcamStream,
	MediaDeviceSource,
	MediaDeviceAccessError,
	RecordingStreamData,
	clearMediaStreams,
	MediaRetrievalException
} from 'lib/mediaDevices';
import MultiStreamRecorder from 'lib/MultiStreamRecorder';

import { RecordingProps, RecordingOption } from './types';

import Recorder from './Recorder';
import RecorderSettings from './RecorderSettings';
import RecorderSetupError from './RecorderSetupError';
import ScreenRecorderSetup from './ScreenRecorderSetup';

import { requiresSetupForScreenRecording } from './helpers';
import { Loader } from 'display';

const TEN_SECONDS = 10000;

const MIME_TYPES = ['video/webm', 'video/mp4'];

enum Status {
	SETTINGS,
	SETUP,
	LOADING,
	ERROR,
	READY
}

interface OwnState {
	error: MediaDeviceAccessError | null;
	recorder: MultiStreamRecorder | null;
	recordingOption: RecordingOption;
	status: Status;
}

type RecorderLoaderState = OwnState & RecordingStreamData;

function getInitialState(): RecorderLoaderState {
	return {
		error: null,
		recorder: null,
		recordingOption: RecordingOption.CAMERA_ONLY,
		status: Status.LOADING,
		...getDefaultRecordingStreamData()
	};
}

function getStreamsForRecorder(streams: RecordingStreamData['streams']): MediaStream[] {
	return Object.keys(streams)
		.map(key => streams[key])
		.filter(Boolean);
}

class RecorderLoader extends React.PureComponent<RecordingProps, RecorderLoaderState> {
	constructor(props: RecordingProps) {
		super(props);

		this.state = getInitialState();

		this.applySettings = this.applySettings.bind(this);
		this.changeRecordingOption = this.changeRecordingOption.bind(this);
		this.changeSources = this.changeSources.bind(this);
		this.createStreams = this.createStreams.bind(this);
		this.onSetupRetry = this.onSetupRetry.bind(this);
		this.setRecordingStreamData = this.setRecordingStreamData.bind(this);
		this.setRecordingStreamError = this.setRecordingStreamError.bind(this);
		this.setReadyScreen = this.setReadyScreen.bind(this);
		this.setSettingsScreen = this.setSettingsScreen.bind(this);
		this.setSetupScreen = this.setSetupScreen.bind(this);
		this.setupMediaDevices = this.setupMediaDevices.bind(this);
	}

	public componentDidMount(): void {
		this.setupMediaDevices();
	}

	public componentWillUnmount(): void {
		this.clearStreams();
	}

	public render(): JSX.Element {
		const { recorder, status, streams, recordingOption } = this.state;

		switch (status) {
			case Status.READY:
				if (recorder) {
					return (
						<Recorder
							{...this.props}
							onOpenSettings={this.setSettingsScreen}
							recorder={recorder as unknown as MultiStreamRecorder}
							streams={streams}
							recordingOption={recordingOption}
						/>
					);
				}

				return this.renderError();
			case Status.ERROR:
				return this.renderError();
			case Status.LOADING:
				return <Loader active={true} data-testid="RecorderLoader-loading" />;
			case Status.SETTINGS:
				return this.renderSettings();
			case Status.SETUP:
				return (
					<ScreenRecorderSetup
						onSetupCancel={this.setSettingsScreen}
						onSetupComplete={this.applySettings}
					/>
				);
		}
	}

	private applySettings(): void {
		if (this.shouldShowSetupScreen) {
			this.setSetupScreen();
			return;
		}

		this.createStreams().then(this.setReadyScreen).catch(this.setRecordingStreamError);
	}

	private changeSources({ name, value }: MediaDeviceSource): void {
		const { sources } = this.state;

		if (sources[name] === value) {
			return;
		}

		this.setState(state => ({
			...state,
			sources: {
				...state.sources,
				[name]: value
			}
		}));
	}

	private changeRecordingOption(recordingOption: RecordingOption): void {
		if (this.state.recordingOption === recordingOption) {
			return;
		}

		this.setState(state => ({
			...state,
			recordingOption
		}));
	}

	private clearStreams(): void {
		const { screen, webcam } = this.state.streams;
		const { recorder } = this.state;
		const streamsToClear = [];

		if (recorder) {
			streamsToClear.push(recorder.getStream());
		}

		if (screen !== null) {
			streamsToClear.push(screen);
		}

		if (webcam !== null) {
			streamsToClear.push(webcam);
		}

		clearMediaStreams(streamsToClear);
	}

	private async createStreams(): Promise<void> {
		const { sources, recordingOption } = this.state;

		this.clearStreams();

		const streams: RecorderLoaderState['streams'] = {
			screen: null,
			webcam: await getWebcamStream(sources)
		};

		if (recordingOption === RecordingOption.SCREENSHARE) {
			streams.screen = await getScreenStream();
		}

		const recorder = new MultiStreamRecorder({
			streams: getStreamsForRecorder(streams),
			mimeType: this.getMimeType(),
			timeslice: TEN_SECONDS,
			onDataAvailable: this.props.onRecordingData
		});

		this.setRecordingStreamData({ recorder, streams });
	}

	private getMimeType(): string {
		for (const mimeType of MIME_TYPES) {
			if (this.isMimeTypeSupported(mimeType)) {
				return mimeType;
			}
		}
		return MIME_TYPES[0];
	}

	private isMimeTypeSupported(mimeType: string): boolean {
		if (!window.MediaRecorder) {
			return false;
		} else if (!window.MediaRecorder.isTypeSupported) {
			return mimeType.startsWith('audio/mp4') || mimeType.startsWith('video/mp4');
		} else {
			return window.MediaRecorder.isTypeSupported(mimeType);
		}
	}

	private onSetupRetry(): void {
		this.setupMediaDevices();
	}

	private setRecordingStreamData({
		mediaDevices,
		recorder,
		sources,
		streams
	}: Partial<RecorderLoaderState>): void {
		this.setState(state => {
			const newState = {
				...state
			};

			if (mediaDevices) {
				newState.mediaDevices = {
					...state.mediaDevices,
					...mediaDevices
				};
			}

			if (sources) {
				newState.sources = {
					...state.sources,
					...sources
				};
			}

			if (streams) {
				newState.streams = {
					...state.streams,
					...streams
				};
			}

			if (recorder) {
				newState.recorder = recorder;
			}

			return newState;
		});
	}

	private setRecordingStreamError(error: MediaRetrievalException): void {
		this.setState(state => ({
			...state,
			error: error.type,
			status: Status.ERROR
		}));
	}

	private setSettingsScreen(): void {
		this.setState(state => ({
			...state,
			status: Status.SETTINGS
		}));
	}

	private setSetupScreen(): void {
		this.setState(state => ({
			...state,
			status: Status.SETUP
		}));
	}

	private setReadyScreen(): void {
		this.setState(state => ({
			...state,
			status: Status.READY
		}));
	}

	private setupMediaDevices(): void {
		const { canRecordScreen } = this.props;

		getRecordingStreamData()
			.then(this.setRecordingStreamData)
			.then((): void => {
				if (canRecordScreen) {
					this.setSettingsScreen();
				} else {
					this.applySettings();
				}
			})
			.catch(this.setRecordingStreamError);
	}

	private get shouldShowSetupScreen(): boolean {
		const { canRecordScreen } = this.props;
		const { recordingOption } = this.state;

		return (
			canRecordScreen &&
			recordingOption === RecordingOption.SCREENSHARE &&
			requiresSetupForScreenRecording()
		);
	}

	private renderError(): JSX.Element {
		const { error } = this.state;

		return <RecorderSetupError error={error} onRetry={this.onSetupRetry} />;
	}

	private renderSettings(): JSX.Element {
		const { canRecordScreen } = this.props;
		const { mediaDevices, recordingOption, sources } = this.state;
		return (
			<RecorderSettings
				mediaDevices={mediaDevices}
				onRecordingOptionChange={this.changeRecordingOption}
				onSourceChange={this.changeSources}
				onSettingsConfirm={this.applySettings}
				recordingOption={recordingOption}
				canRecordScreen={canRecordScreen}
				sources={sources}
			/>
		);
	}
}

export default RecorderLoader;
