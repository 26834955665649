import { objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetClinicalReviewRequest, GetClinicalReviewResponse } from 'thriftgen/api_types';
import {
	GetClinicalReviewRequestCamel,
	GetClinicalReviewResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function getClinicalReview({
	consultId
}: GetClinicalReviewRequestCamel): Promise<GetClinicalReviewResponseCamel> {
	const client = getCommonClient();

	const request = new GetClinicalReviewRequest({
		consult_id: consultId
	});

	const response: GetClinicalReviewResponse = await client.get_clinical_review(request);
	return objectSnakeToCamel(response);
}

export default getClinicalReview;
