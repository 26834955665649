import * as React from 'react';

import { Anchor, Button } from 'display';
import Strings from 'lib/strings';

function LinkToMacOsCameraPreferences(): JSX.Element {
	return (
		<Anchor
			href="x-apple.systempreferences:com.apple.preference.security?Privacy_Camera"
			rel=""
			target="">
			<Button.Primary>{Strings.Buttons.OPEN_SYSTEM_PREFERENCES}</Button.Primary>
		</Anchor>
	);
}

export default LinkToMacOsCameraPreferences;
