import { Image, Loader, Message } from 'display';
import { css } from 'emotion';
import { StreamUploader } from 'lib/Uploaders';
import React, { useState } from 'react';
import { colors } from 'styles';
import FileInput from './FileInputs/FileInput';
import Text from './Text';

interface ImageUploadState {
	error: Error | null;
	file: File | null;
	uploader: StreamUploader;
	uploading: boolean;
}

interface ImageUploadProps {
	currentUrl?: string;
	id: string;
	onImageUpload: (uploadKey: string) => void;
	emptyMessage?: ImageUploadPreviewProps['emptyMessage'];
}

interface ImageUploadPreviewProps {
	src?: string;
	emptyMessage?: string;
}

const styles = {
	button: css`
		cursor: pointer;
		color: ${colors.primary.base};
		margin-top: 1em;
	`,
	input: css`
		margin-top: 0.5em;
	`
};

const DEFAULT_EMPTY_MESSAGE = 'No image has been added yet.';

function getInitialUploadState(): ImageUploadState {
	return {
		error: null,
		file: null,
		uploader: new StreamUploader(),
		uploading: false
	};
}

function ImageUploadPreview({
	emptyMessage = DEFAULT_EMPTY_MESSAGE,
	src
}: ImageUploadPreviewProps): JSX.Element {
	if (src) {
		return <Image size="small" src={src} alt="Image preview" />;
	}

	return <Text>{emptyMessage}</Text>;
}

function ImageUpload({
	currentUrl,
	id,
	onImageUpload,
	emptyMessage
}: ImageUploadProps): JSX.Element {
	const [state, setState] = useState<ImageUploadState>(getInitialUploadState());
	const imgSrc = state.file ? URL.createObjectURL(state.file) : currentUrl;
	function onError(error: Error): void {
		setState(currentState => ({
			...currentState,
			uploading: false,
			uploader: new StreamUploader(),
			error
		}));
	}

	function onUploadComplete(keys: string[]): void {
		onImageUpload(keys[0]);
		setState(currentState => ({
			...currentState,
			error: null,
			uploading: false,
			uploader: new StreamUploader()
		}));
	}

	function onFileChange(files: File[]): void {
		if (files.length) {
			const file = files[0];
			setState(currentState => ({
				...currentState,
				error: null,
				file,
				uploading: true
			}));

			state.uploader.upload(file);
			state.uploader.end();
		}
	}

	React.useEffect((): void => {
		state.uploader.addUploadCompleteListener(onUploadComplete);
		state.uploader.addUploadErrorListener(onError);
	}, [state.uploader]);

	if (state.uploading) {
		return <Loader active={true} inline={true} />;
	}

	return (
		<React.Fragment>
			<ImageUploadPreview src={imgSrc} emptyMessage={emptyMessage} />
			<div className={styles.input}>
				<FileInput
					accept=".jpeg,.jpg,.png,.tif,.tiff"
					className={styles.button}
					button={false}
					label={imgSrc ? 'Update' : 'Add'}
					id={id}
					multiple={false}
					onFileChange={onFileChange}
				/>
			</div>
			{state.error && (
				<Message negative={true}>
					<Message.Header>Upload failure</Message.Header>
					<Message.Content>{state.error.message}</Message.Content>
				</Message>
			)}
		</React.Fragment>
	);
}

export default ImageUpload;
export { ImageUploadPreview };
