import { ConsultData } from 'thriftgen/api_types';
import {
	getConsultDataAssets,
	getConsultDataThumbnail,
	getReviewQuery,
	isConsultDataProcessing,
	isConsultDataReady,
	isViewable
} from '../consultDataHelpers';
import { compareDescending } from '../sort';
import { DocumentAssetType, DocumentDataAsset } from './types';

function orderDocumentAssetsByMostRecent(a: DocumentDataAsset, b: DocumentDataAsset): number {
	return compareDescending(a.created_at, b.created_at);
}

function getDataAssets(consultData: ConsultData[]): DocumentDataAsset[] {
	const data = consultData || [];
	return getConsultDataAssets(data)
		.map((consultDatum): DocumentDataAsset => {
			const reviewQuery: string | undefined = getReviewQuery(consultDatum);
			const isNew: boolean = Boolean(reviewQuery) && consultDatum.is_new;

			return {
				...consultDatum,
				assetType: DocumentAssetType.DATA,
				description: consultDatum.data_name,
				downloadUrl: consultDatum.presigned_url,
				id: consultDatum.consult_data_id,
				isNew,
				isProcessing: isConsultDataProcessing(consultDatum),
				isReady: isConsultDataReady(consultDatum),
				reviewQuery,
				thumbnailImage: getConsultDataThumbnail(consultDatum)
			};
		})
		.sort(orderDocumentAssetsByMostRecent);
}

const isDocumentDataViewable = isViewable;
const isTiffImage = (documentData: DocumentDataAsset): boolean =>
	documentData.data_name.includes('.tif');

export default getDataAssets;
export { isDocumentDataViewable, isTiffImage };
