import buildTextFieldComponent from 'components/Forms/Fields/base/buildTextField';
import Strings from 'lib/strings';

const LABEL = Strings.Labels.CORRECTIVE_ACTION_TEXT;
const FIELD_NAME = 'correctiveActionText';
const PLACEHOLDER = Strings.Labels.CORRECTIVE_ACTION_TEXT_PLACEHOLDER;

function validate(value: string | undefined, required: boolean): string[] | null {
	if (required && !value) {
		return [Strings.Errors.CORRECTIVE_ACTION_TEXT];
	}

	return null;
}

const CorrectiveActionText = buildTextFieldComponent({
	fieldName: FIELD_NAME,
	label: LABEL,
	placeholder: PLACEHOLDER,
	validate,
	includeLabelForReadonlyField: true
});

export default CorrectiveActionText;
