import airbrake from 'lib/airbrake';
import SitkaDatadog from 'lib/SitkaDatadog';

type Message = string | Record<string, unknown> | Error;

enum LogEventName {
	UPLOAD = 'upload',
	USER_SESSION = 'user session',
	VIDEO = 'video',
	WEB_VIEWER = 'web viewer',
	LOG = 'log',
	SSO = 'sso',
	DICTATION = 'dictation',
	TRANSCRIPTION_LOG = 'transcription log',
	SMA = 'sma',
	SMA_BIO = 'sma bio',
	MISC = 'misc logging'
}

class SitkaLogger {
	static logMessage = (msg: Message, name?: LogEventName): void => {
		const tag = name ? name : LogEventName.LOG;
		// only send to airbrake in the case of an Error object
		if (msg instanceof Error) {
			window.pendo?.track('error', msg);
			SitkaDatadog.sendError({ log: msg });
			airbrake.notify(msg);
		} else if (msg instanceof String || typeof msg === 'string') {
			window.pendo?.track(tag, msg);
			SitkaDatadog.sendEvent(tag, { log: msg });
		} else {
			// handle a JSON object
			if (msg?.error) {
				airbrake.notify(msg);
			}
			window.pendo?.track(tag, msg);
			SitkaDatadog.sendEvent(tag, msg);
		}
	};
}

export { SitkaLogger, LogEventName };
