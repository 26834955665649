import { OktaSecurity } from 'components/Okta';
import Pendo from 'components/Pendo';
import NotFoundRoute from 'components/Routing/NotFoundRoute';
import ScrollToTop from 'components/Routing/ScrollToTop';
import Zendesk from 'components/Zendesk';
import * as React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import {
	renderCaseRedirect,
	renderConsultRedirect,
	renderPartnerRedirect,
	renderUserRedirect
} from 'routing/redirects';
import { authenticatedRoutes, unauthenticatedRoutes } from 'routing/routes';
import Datadog from 'components/Datadog';
import UnleashProvider from 'components/Unleash/UnleashProvider';
import { SnackbarContextProvider } from 'components/ContextProviders';
import OnlineStatus from 'components/OnlineStatus';

export default function Router(): JSX.Element {
	return (
		<BrowserRouter>
			<ScrollToTop>
				<OktaSecurity>
					<UnleashProvider />
					<Pendo />
					<Datadog />
					<Zendesk />
					<SnackbarContextProvider>
						<OnlineStatus />
						<Switch>
							{/* Routes */}
							{authenticatedRoutes.render()}
							{unauthenticatedRoutes.render()}

							{/* Redirects */}
							{renderCaseRedirect()}
							{renderConsultRedirect()}
							{renderPartnerRedirect()}
							{renderUserRedirect()}

							{/* The 404 route below must be the last route */}
							{NotFoundRoute()}
						</Switch>
					</SnackbarContextProvider>
				</OktaSecurity>
			</ScrollToTop>
		</BrowserRouter>
	);
}
