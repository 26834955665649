import * as React from 'react';
import PropTypes from 'prop-types';

import { Message, Paragraph } from 'display/';

function EditableFieldsError({ message }) {
	return (
		<Message negative>
			<Message.Header>Unable to load editable fields.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

EditableFieldsError.propTypes = {
	message: PropTypes.string.isRequired
};

export default EditableFieldsError;
