import PhoneNumberField from './PhoneNumber';
import { buildFieldVariation } from '../helpers';

const LABEL = 'Patient phone number';

export default buildFieldVariation({
	Field: PhoneNumberField,
	label: LABEL,
	optionalLabelName: true
});
