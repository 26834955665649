import { css } from 'emotion';
import React from 'react';
import { colors } from 'styles';
import buildSectionHeadingField from './base/buildSectionHeadingField';

const FIELD_NAME = 'sectionHeadingClinicalDataCapture';
const LABEL = 'Clinical Data';
const SUB_HEADER = (
	<>
		Clinical data is for <strong>internal purposes only</strong> and will not be shared with the
		requesting provider.
	</>
);
const styles = {
	headingStyles: css`
		color: ${colors.primary.base};
		font-size: 15px;
	`
};
const SectionHeadingClinicalDataCapture = buildSectionHeadingField({
	fieldName: FIELD_NAME,
	label: LABEL,
	subHeader: SUB_HEADER,
	headingStyles: styles.headingStyles
});

export default SectionHeadingClinicalDataCapture;
