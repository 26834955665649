import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'display';

function CancelIconButton({ onClick }) {
	return <Button.Icon icon="cancel" onClick={onClick} />;
}

CancelIconButton.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default CancelIconButton;
