import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'display';

function UnlockAccountButton({ loading, onClick }) {
	return (
		<Button.Primary loading={loading} onClick={onClick}>
			Unlock account
		</Button.Primary>
	);
}

UnlockAccountButton.defaultProps = {
	loading: false
};

UnlockAccountButton.propTypes = {
	loading: PropTypes.bool,
	onClick: PropTypes.func.isRequired
};

export default UnlockAccountButton;
