import { useConsultContext } from 'contexts';
import { Loader } from 'display';
import { getUserFullNameAndTitle } from 'lib/userHelpers';
import React, { useState } from 'react';
import { User } from 'thriftgen/api_types';
import UserNameAndAvatar from '../../User/UserNameAndAvatar';

interface UserProps {
	userId?: User['user_id'];
}

function UserFromConsultContext({ userId }: UserProps): JSX.Element {
	const [isLoading, setIsLoading] = useState(true);
	const [user, setUser] = useState<User>();
	const { users } = useConsultContext();

	React.useEffect(() => {
		if (!user && userId) {
			const userFromContext = users.find(({ user_id }) => user_id === userId);
			if (userFromContext) {
				setUser(userFromContext);
			}
			setIsLoading(false);
		}
	}, [user, userId, users]);

	if (!userId) {
		return <span>Unassigned</span>;
	}

	if (isLoading) {
		return <Loader active={true} />;
	}

	if (!user) {
		return <span>User not found</span>;
	}

	return <UserNameAndAvatar user={user} displayNameFunc={getUserFullNameAndTitle} />;
}

export default UserFromConsultContext;
