const AGENTS = {
	IS_IOS: agent => Boolean(agent.match(/(iphone|ipod|ipad)/)),
	IS_IPAD: agent => Boolean(agent.match(/ipad/i)),
	IS_IPHONE: agent => Boolean(agent.match(/iphone/i)),
	IS_IPOD: agent => Boolean(agent.match(/ipod/i)),
	IS_BLACKBERRY: agent => Boolean(agent.match(/blackberry/i)),
	IS_IEMOBILE: agent => Boolean(agent.match(/(iemobile)/)),
	IS_ANDROID: agent => Boolean(agent.match(/(android)/)),
	IS_BADA: agent => Boolean(agent.match(/bada/i)),
	IS_IE: agent => Boolean(agent.match(/trident/) || agent.match(/msie/))
};

export const getUserAgent = () => window.navigator.userAgent.toLowerCase();

export const hasTouch = () => 'ontouchstart' in window;

export function isChrome() {
	return window.chrome !== undefined && navigator.userAgent.indexOf('Chrome') > -1;
}

export function isMobile() {
	const agent = getUserAgent();
	return (
		hasTouch() &&
		(AGENTS.IS_IOS(agent) ||
			AGENTS.IS_IPAD(agent) ||
			AGENTS.IS_IEMOBILE(agent) ||
			AGENTS.IS_IPHONE(agent) ||
			AGENTS.IS_IPAD(agent) ||
			AGENTS.IS_IPOD(agent) ||
			AGENTS.IS_BLACKBERRY(agent) ||
			AGENTS.IS_BADA(agent) ||
			AGENTS.IS_ANDROID(agent))
	);
}

export function isIE() {
	const agent = getUserAgent();
	return AGENTS.IS_IE(agent);
}

export function isIOS() {
	const agent = getUserAgent();
	return AGENTS.IS_IOS(agent);
}
