import { DropdownProps } from 'display';
import { Range } from 'thriftgen/api_types';

enum FilterType {
	BOOLEAN,
	SEARCH,
	SELECT
}

type FilterValueType = string | number | boolean | Range;

type FilterValue = FilterValueType | Array<FilterValueType> | undefined;

type NonNullableFilterValue = NonNullable<FilterValue>;

interface BaseFilterField {
	attribute: string;
	label?: string;
	type: FilterType;
	value?: FilterValue;
	placeholder?: string;
	loading?: boolean;
	onChange: (value: FilterValue) => void;
}

interface SearchFilterField extends BaseFilterField {
	type: FilterType.SEARCH;
	value?: string | undefined;
	onChange: (value: string | undefined) => void;
}

interface SelectFilterField extends BaseFilterField {
	type: FilterType.SELECT;
	options: DropdownProps['options'];
	multiple?: DropdownProps['multiple'];
	onChange: (value: DropdownProps['value']) => void;
}

interface BooleanFilterField extends BaseFilterField {
	type: FilterType.BOOLEAN;
	value?: boolean | undefined;
	onChange: (value: boolean | undefined) => void;
}

type FilterField = SearchFilterField | SelectFilterField | BooleanFilterField;

type OnChange = (attribute: string, value: NonNullableFilterValue) => void;
type OnRemove = (attribute: string) => void;

export {
	BooleanFilterField,
	FilterField,
	FilterType,
	FilterValue,
	FilterValueType,
	NonNullableFilterValue,
	OnChange,
	OnRemove,
	SearchFilterField,
	SelectFilterField
};
