import * as React from 'react';

import { Button, ButtonProps } from 'display';

interface StaySignedInButtonProps {
	disabled?: ButtonProps['disabled'];
	onClickAsync: ButtonProps['onClickAsync'];
}

function StaySignedInButton({
	disabled = false,
	onClickAsync
}: StaySignedInButtonProps): JSX.Element {
	return (
		<Button.Primary disabled={disabled} onClickAsync={onClickAsync}>
			Stay signed in
		</Button.Primary>
	);
}

export default StaySignedInButton;
