import { Anchor, SemanticICONS } from 'display';
import { DocumentDataAsset } from 'lib/documentAssets';
import * as React from 'react';
import { css } from 'emotion';

const styles = {
	anchor: css`
		 {
			color: inherit;
			&:hover {
				color: inherit;
			}
		}
	`
};

interface DocumentDownloadActionProps {
	data: DocumentDataAsset;
	children: React.ReactNode;
}

function DocumentDownloadAction({
	data,
	children
}: DocumentDownloadActionProps): JSX.Element | null {
	if (data.downloadUrl) {
		return (
			<Anchor href={data.downloadUrl} className={styles.anchor}>
				{children}
			</Anchor>
		);
	} else {
		return <> {children}</>;
	}
}

DocumentDownloadAction.ICON = 'download' as SemanticICONS;
DocumentDownloadAction.TITLE = 'Download';

export default DocumentDownloadAction;
