import { useConsultContext } from 'contexts/ConsultContext';
import { MarkdownViewer, Table, Timestamp } from 'display';
import { getUserById } from 'lib/userHelpers';
import * as React from 'react';
import { ConversationMessage } from 'thriftgen/api_types';
import { translatePatientTreatmentState } from '../../Forms/Fields/PatientTreatmentState/helpers';
import { translateSpecialty } from '../../Forms/Fields/Specialty/helpers';

function ConsultReportTable(): JSX.Element {
	const { consult, partner, users } = useConsultContext();
	const patient = getUserById(users, consult.patient_id);
	const requestor = getUserById(users, consult.requestor_id);
	const responder = getUserById(users, consult.specialist_id);
	const [firstRequestMsg, firstResponseMsg, additionalMsgs] = separateMessages();

	function isBabylonPartner() {
		return partner.name.includes('Babylon');
	}

	function formatName(first = '', last = '') {
		return `${first} ${last}`;
	}

	const containerStyle: React.CSSProperties = {
			WebkitPrintColorAdjust: 'exact',
			fontFamily:
				"'Libre Franklin', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,Helvetica, Arial, sans-serif"
		},
		headerContainer: React.CSSProperties = {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			color: '#317BE0',
			marginBottom: '3rem'
		},
		headerSubContainer: React.CSSProperties = {
			display: 'flex',
			flexDirection: 'column'
		},
		alignItemsEnd: React.CSSProperties = {
			alignItems: 'flex-end'
		},
		alignItemsStart: React.CSSProperties = {
			alignItems: 'flex-start'
		},
		footerStyle: React.CSSProperties = {
			textAlign: 'center',
			width: '100%',
			fontSize: '0.8rem',
			marginTop: '2rem',
			marginBottom: '2rem'
		};
	const styles = {
		reportH1: {
			fontSize: '1.25rem',
			marginTop: '0',
			marginBottom: '0',
			fontWeight: '700'
		},
		reportH2: {
			marginTop: '0',
			marginBottom: '0',
			fontWeight: '700'
		},
		tableHeader: {
			fontSize: '1.25rem'
		},
		title: {
			fontWeight: '700',
			color: '#317BE0',
			backgroundColor: 'rgba(0, 0, 0, 0.03)'
		},
		normalCell: {
			fontWeight: '400',
			backgroundColor: 'white'
		},
		border: {
			border: 'solid black 1px'
		},
		avoidBreak: {
			breakInside: 'avoid'
		}
	};

	function separateMessages(): [string | undefined, string | undefined, ConversationMessage[]] {
		const TEXT_MESSAGE_DATA_TYPE = 6;
		const textMessages = consult.conversations[0].messages.filter(
			msg => msg.content.data_type === TEXT_MESSAGE_DATA_TYPE
		);
		let firstRequestMessage,
			firstResponseMessage,
			index = 0;
		textMessages.reverse();

		if (textMessages[0].sender_id === consult.requestor_id) {
			firstRequestMessage = textMessages.shift()?.content.meta_data;
		}
		while (index < textMessages.length) {
			if (textMessages[index].sender_id === consult.specialist_id) {
				firstResponseMessage = textMessages[index].content.meta_data;
				textMessages.splice(index, 1);
				break;
			}
			index++;
		}

		return [firstRequestMessage, firstResponseMessage, textMessages];
	}

	function constructReportHeader() {
		return (
			<header style={headerContainer}>
				<div style={{ ...headerSubContainer, ...alignItemsStart }}>
					<h1 style={styles.reportH1}>712 H St NE PMB 12</h1>
					<h1 style={styles.reportH1}>Washington, DC 20002</h1>
				</div>
				<div style={{ ...headerSubContainer, ...alignItemsEnd }}>
					<h1 style={styles.reportH1}>Sitka Medical Associates</h1>
					<h2 style={styles.reportH2}>powered by Sitka</h2>
				</div>
			</header>
		);
	}

	function constructReportFooter() {
		return (
			<footer style={footerStyle}>
				**For complete consult information, please access the full consult request via the
				link included in this summary**
			</footer>
		);
	}

	function constructPatientDataTable() {
		return (
			<Table celled padded definition>
				<Table.Header>
					<Table.Row style={styles.avoidBreak}>
						<Table.HeaderCell
							colSpan={2}
							textAlign="center"
							style={{ ...styles.title, ...styles.tableHeader, ...styles.border }}>
							Sitka vConsult Summary
						</Table.HeaderCell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell
							colSpan={2}
							textAlign="center"
							style={{ ...styles.title, ...styles.border }}>
							Patient Data
						</Table.Cell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell style={styles.border}>Patient Name:</Table.Cell>
						<Table.Cell style={styles.border}>
							{formatName(patient?.first_name, patient?.last_name)}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell style={styles.border}>Patient ID:</Table.Cell>
						<Table.Cell style={styles.border}>
							{patient?.patient_external_id}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell style={styles.border}>Link to Consult:</Table.Cell>
						<Table.Cell style={styles.border}>{window.location.href}</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		);
	}

	function constructSpecialtyDataTable() {
		return (
			<Table celled padded definition>
				<Table.Body>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell
							colSpan={2}
							textAlign="center"
							style={{ ...styles.title, ...styles.border }}>
							Specialty Data
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							Partner Name:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							{partner?.name}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							{isBabylonPartner() ? 'Patient' : 'Practice'} Location:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							{isBabylonPartner()
								? translatePatientTreatmentState(consult?.patient_treatment_state)
								: requestor?.city}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							Requested Specialty Type:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							{translateSpecialty(consult?.specialty)}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							Requesting Provider:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							{formatName(requestor?.first_name, requestor?.last_name)}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							Date & Time of Request:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							<Timestamp timestamp={consult.request_date} />
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							Responding Specialist:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							{formatName(responder?.first_name, responder?.last_name)}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell width={4} style={styles.border}>
							Initial Response Date/Time:
						</Table.Cell>
						<Table.Cell width={12} style={styles.border}>
							<Timestamp timestamp={consult.response_date} />
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell
							colSpan={2}
							textAlign="center"
							style={{ ...styles.title, ...styles.border }}>
							Written Request (If Supplied)
						</Table.Cell>
					</Table.Row>
					<Table.Row style={firstRequestMsg ? {} : styles.avoidBreak}>
						<Table.Cell colSpan={2} style={{ ...styles.normalCell, ...styles.border }}>
							{firstRequestMsg ? (
								<MarkdownViewer content={firstRequestMsg} />
							) : (
								'Not included if request recorded in video'
							)}
						</Table.Cell>
					</Table.Row>
					<Table.Row style={styles.avoidBreak}>
						<Table.Cell
							colSpan={2}
							textAlign="center"
							style={{ ...styles.title, ...styles.border }}>
							Specialist Plan
						</Table.Cell>
					</Table.Row>
					<Table.Row>
						<Table.Cell colSpan={2} style={{ ...styles.normalCell, ...styles.border }}>
							<MarkdownViewer content={firstResponseMsg} />
						</Table.Cell>
					</Table.Row>
				</Table.Body>
			</Table>
		);
	}

	function constructAdditionalResponsesDataTable() {
		return (
			<Table celled padded striped>
				<Table.Header>
					<Table.Row style={styles.avoidBreak}>
						<Table.HeaderCell
							colSpan={2}
							textAlign="center"
							style={{ ...styles.title, ...styles.border }}>
							Additional Specialist/Requestor Responses:
						</Table.HeaderCell>
					</Table.Row>
				</Table.Header>
				<Table.Body>
					{additionalMsgs?.map(msg => {
						const sender = getUserById(users, msg.sender_id);
						return (
							<Table.Row key={`row-msg-${msg.message_id}`}>
								<Table.Cell
									key={`user-time-msg-${msg.message_id}`}
									width={4}
									style={styles.border}>
									{formatName(sender?.first_name, sender?.last_name)} - Date/Time:{' '}
									<Timestamp timestamp={msg.created_at} />
								</Table.Cell>
								<Table.Cell
									key={`content-msg-${msg.message_id}`}
									width={8}
									style={styles.border}>
									<MarkdownViewer content={msg.content.meta_data} />
								</Table.Cell>
							</Table.Row>
						);
					})}
				</Table.Body>
			</Table>
		);
	}

	return (
		<div style={containerStyle}>
			{constructReportHeader()}
			{constructPatientDataTable()}
			{constructSpecialtyDataTable()}
			{additionalMsgs.length > 0 && constructAdditionalResponsesDataTable()}
			{constructReportFooter()}
		</div>
	);
}

export default ConsultReportTable;
