import * as React from 'react';

import { Button, ButtonProps } from 'display';

interface CloseIconButtonProps {
	onClick: ButtonProps['onClick'];
	inverted?: boolean;
}

function CloseIconButton({ onClick, inverted = true }: CloseIconButtonProps): JSX.Element {
	return (
		<Button.Icon
			icon="close"
			size={Button.IconSize.LARGE}
			inverted={inverted}
			onClick={onClick}
		/>
	);
}

export default CloseIconButton;
