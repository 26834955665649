import { ActionMenu, Button, SemanticICONS } from 'display';
import React, { useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';

type ActionComponent = React.ComponentType<{
	data: unknown;
	modalPortalId?: string;
	children: React.ReactNode;
}> & {
	ICON: SemanticICONS;
	TITLE: string;
};

interface AssetListItemActionDropdownProps {
	actionComponents: ActionComponent[];
	data: unknown;
}

function AssetListItemActionDropdown({
	actionComponents,
	data
}: AssetListItemActionDropdownProps): JSX.Element {
	const modalPortalId = useMemo(() => {
		return uuidv4();
	}, []);

	function renderActionComponentMinimized(
		ActionComponent: ActionComponent,
		modalPortalId: string
	) {
		return (
			<ActionComponent data={data} modalPortalId={modalPortalId}>
				<Button.Icon
					icon={ActionComponent.ICON}
					title={ActionComponent.TITLE}
					data-testid="assetListItemActionIcon"
				/>
			</ActionComponent>
		);
	}

	function renderActionComponentDropdownItem(
		ActionComponent: ActionComponent,
		modalPortalId: string
	) {
		return (
			<div className="item" key={ActionComponent.TITLE}>
				<ActionComponent data={data} modalPortalId={modalPortalId}>
					<ActionMenu.Item
						text={ActionComponent.TITLE}
						onClick={() => {
							return;
						}}></ActionMenu.Item>
				</ActionComponent>
			</div>
		);
	}

	if (actionComponents.length === 0) {
		return <></>;
	}

	return (
		<React.Fragment>
			<div id={modalPortalId} />
			{actionComponents.length === 1 ? (
				renderActionComponentMinimized(actionComponents[0], modalPortalId)
			) : (
				<ActionMenu data-testid="assetListItemActionDropdown">
					{actionComponents.map(ActionComponent =>
						renderActionComponentDropdownItem(ActionComponent, modalPortalId)
					)}
				</ActionMenu>
			)}
		</React.Fragment>
	);
}

export default AssetListItemActionDropdown;
export { ActionComponent };
