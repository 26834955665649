import { objectSnakeToCamel } from 'lib/objectConverter';
import { getAthenaClient } from 'lib/ThriftClient';
import { AthenaGetPatientDocumentListRequest } from 'thriftgen/api_types';
import {
	AthenaGetPatientDocumentListRequestCamel,
	AthenaGetPatientDocumentListResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function athenaGetPatientDocumentList({
	caseId,
	athenaDepartmentId
}: AthenaGetPatientDocumentListRequestCamel): Promise<AthenaGetPatientDocumentListResponseCamel> {
	const client = getAthenaClient();
	const request = new AthenaGetPatientDocumentListRequest({
		case_id: caseId,
		athena_department_id: athenaDepartmentId
	});

	const response: AthenaGetPatientDocumentListResponse =
		await client.athena_get_patient_document_list(request);

	response.documents = response.documents.map(document => ({
		...document,
		document_id: +document.document_id
	}));

	return objectSnakeToCamel(response);
}

export default athenaGetPatientDocumentList;
