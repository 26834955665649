import * as React from 'react';
import { css, cx } from 'emotion';
import { borderRadius, colors, HueVariations } from 'styles';
import { Paragraph } from '.';

type SupportedHues = Exclude<HueVariations, HueVariations.NEUTRAL | HueVariations.PRIMARY>;

interface TagProps {
	children: string;
	hue: SupportedHues;
}

type ConsumerProps = Pick<TagProps, 'children'>;

const styles = {
	color: (hue: SupportedHues) => css`
		background-color: ${colors[hue]['300']};
		color: ${colors[hue]['900']};
	`,
	tag: css`
		border-radius: ${borderRadius.l1};
		display: inline-block;
		padding: 0.125rem 0.5rem 0;
	`
};

function Tag({ children, hue }: TagProps): JSX.Element {
	return (
		<div className={cx(styles.tag, styles.color(hue))}>
			<Paragraph size={Paragraph.Size.T5} weight={Paragraph.Weight.BOLD}>
				{children}
			</Paragraph>
		</div>
	);
}

function makeTag(hue: SupportedHues): React.ComponentType<ConsumerProps> {
	// eslint-disable-next-line react/display-name
	return ({ children }: ConsumerProps): JSX.Element => <Tag hue={hue}>{children}</Tag>;
}

const ExportedTag = {
	PrimaryAlt: makeTag(HueVariations.PRIMARY_ALT),
	New: makeTag(HueVariations.NEW),
	Problem: makeTag(HueVariations.PROBLEM),
	Info: makeTag(HueVariations.INFO)
};

export default ExportedTag;
