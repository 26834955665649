import {
	SpecialistResponseRecommendation,
	SPECIALIST_RESPONSE_RECOMMENDATION
} from 'thriftgen/api_types.js';
import buildSelectField, { buildSelectFieldOptions } from './base/buildSelectField';

const FIELD_NAME = 'managementRecommendation';
const LABEL = 'Management Recommendation';
const PLACEHOLDER = 'Select management recommendation';
const VALIDATION_MESSAGE = 'Please enter a management recommendation.';

const options = buildSelectFieldOptions(
	SpecialistResponseRecommendation,
	SPECIALIST_RESPONSE_RECOMMENDATION
);

const ManagementRecommendation = buildSelectField({
	fieldName: FIELD_NAME,
	label: LABEL,
	placeholder: PLACEHOLDER,
	options,
	validationMessage: VALIDATION_MESSAGE
});

export default ManagementRecommendation;
