import { DataFormFieldComponent } from '../DataFormTypes';
import buildTextFieldComponent from './base/buildTextField';

const FIELD_NAME = 'dailyConsultsLimit';
const FIELD_LABEL = 'Daily Consult Response Limit (leave empty for unlimited)';

function validate(value: string | undefined, required: boolean): string[] | null {
	if ((required && !value) || (value && isNaN(+value))) {
		return ['Please provide a valid number.'];
	}

	return null;
}

const DailyConsultsLimit: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	validate,
	width: '4'
});

export default DailyConsultsLimit;
