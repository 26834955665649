// Fallback to setTimeout if browser doesn't support
// requestAnimationFrame or requestIdleCallback

function raf(callback: FrameRequestCallback): number {
	if ('requestAnimationFrame' in window) {
		return window.requestAnimationFrame(callback);
	} else {
		return (window as Window).setTimeout(callback, 0);
	}
}

function ric(callback: RequestIdleCallbackHandler): number {
	if ('requestIdleCallback' in window) {
		return window.requestIdleCallback(callback);
	} else {
		return (window as Window).setTimeout(callback, 0);
	}
}

const browserScheduling = {
	requestAnimationFrame: raf,
	requestIdleCallback: ric
};

export default browserScheduling;
