import { getUsers } from 'api/common';
import { transformToThriftFilter } from 'api/helpers';
import { UserTable } from 'components/DataTable';
import userColumns from 'components/DataTable/UserTable/columns';
import { USER_SEARCH_FILTER } from 'components/Filters';
import { UsersLoadError } from 'components/Messages';
import { Pagination } from 'components/Pagination';
import { usePaginationContext } from 'contexts/PaginationContext';
import { Loader, TableRowProps } from 'display';
import { UserComposite } from 'hooks';
import noop from 'lib/noop';
import React, { useState } from 'react';
import { User } from 'thriftgen/api_types';
import { UserSelectProps } from './types';

function isMultiple(
	value: UserSelectProps['value'],
	multiple: UserSelectProps['multiple']
): value is string[] {
	return Boolean(multiple) && Array.isArray(value);
}

function UserSelectControl({
	columns,
	filterControls = [],
	multiple,
	name,
	onChange = noop,
	requestFilters,
	value,
	patientIdForConsultCounts
}: UserSelectProps): JSX.Element {
	const [users, setUsers] = useState<UserComposite[]>([]);
	const [loading, setLoading] = useState<boolean>(false);
	const [error, setError] = useState<string | null>(null);

	const { parameters, setNeighborPageCursors } = usePaginationContext();

	React.useEffect((): void => {
		let filters = [...parameters.filters];

		if (requestFilters) {
			filters = [...requestFilters, ...filters];
		}

		setLoading(true);
		setError(null);

		getUsers({
			pageParams: {
				filters: filters.map(transformToThriftFilter),
				pageCursor: parameters.pageCursor ?? undefined,
				pageSize: parameters.pageSize
			},
			patientIdForConsultCounts
		}).then(
			response => {
				const users: UserComposite[] = response.users.map(user => {
					const userComposite: UserComposite = {
						...user,
						partners: [],
						numConsultsForPatient: +(
							response.sma_consult_count_for_patient?.[user.user_id] ?? 0
						)
					};
					return userComposite;
				});
				setUsers(users);
				setNeighborPageCursors({
					nextPageCursor: response.page_markers.next_page_cursor,
					prevPageCursor: response.page_markers.prev_page_cursor
				});

				setLoading(false);
			},
			reason => {
				setError(reason.message);
				setLoading(false);
			}
		);
	}, [parameters, patientIdForConsultCounts, requestFilters]);

	function onSelect(_: React.FormEvent<HTMLInputElement>, eventData: { value: string }): void {
		if (!isMultiple(value, multiple)) {
			return onChange(_, eventData);
		}

		let selection: string[];

		if (value.includes(eventData.value)) {
			selection = value.filter(id => id !== eventData.value);
		} else {
			selection = [...value, eventData.value];
		}

		onChange(_, { name, value: selection });
	}

	const isUserSelected = (user: User): boolean => Boolean(value) && value.includes(user.user_id);
	const getUserRowProps = (user: User): TableRowProps => ({ active: isUserSelected(user) });

	const uiFilters = [USER_SEARCH_FILTER, ...filterControls];

	const tableColumns = [
		userColumns.buildSelectUser({
			isChecked: isUserSelected,
			multiple,
			onChange: onSelect,
			name
		}),
		userColumns.buildName(),
		...columns
	];

	function SelectionControl(): JSX.Element {
		if (error) {
			return <UsersLoadError message={error} />;
		}

		if (loading) {
			return <Loader active={true} data-testid="userSelect-loader" />;
		}

		return <UserTable columns={tableColumns} users={users} setRowProps={getUserRowProps} />;
	}

	return (
		<React.Fragment>
			<Pagination.FilterForm filters={uiFilters} loading={loading} />
			<SelectionControl />
			<Pagination.Cursors />
		</React.Fragment>
	);
}

const UserSelect = (props: UserSelectProps): JSX.Element => (
	<Pagination defaultPageSize={10}>
		<UserSelectControl {...props} />
	</Pagination>
);

export default UserSelect;
