import reducerRegistry from 'store/registry';
import * as ACTION_STATUS from 'store/actionStatus';
import {
	Request,
	RequestsState,
	RequestActionTypes,
	RegisterRequestAction,
	UpdateRequestAction
} from './requests.types';

const REDUCER_NAME = 'requests';

export const CLEAR_REQUEST = 'requests/clearRequest';
export const REGISTER_REQUEST = 'requests/registerRequest';
export const UPDATE_REQUEST = 'requests/updateRequest';

export function registerRequest(action: RegisterRequestAction): Request {
	return {
		id: action.id,
		inProgress: false,
		message: null,
		status: ACTION_STATUS.INACTIVE,
		toast: action.toast
	};
}

export function updateRequest(state: Request, action: UpdateRequestAction): Request {
	return {
		...state,
		inProgress: action.status === ACTION_STATUS.IN_PROGRESS,
		message: action.message,
		status: action.status
	};
}

const initialRequestsState: RequestsState = [];

export function requestsReducer(
	state = initialRequestsState,
	action: RequestActionTypes
): RequestsState {
	switch (action.type) {
		case REGISTER_REQUEST:
			return [...state, registerRequest(action)];
		case CLEAR_REQUEST:
			return state.filter(request => request.id !== action.id);
		case UPDATE_REQUEST:
			return state.map(request =>
				request.id === action.id ? updateRequest(request, action) : request
			);
		default:
			return state;
	}
}

reducerRegistry.register(REDUCER_NAME, requestsReducer);
