import { FilterField, Segment } from 'display';
import * as React from 'react';
import PaginationFilterPopoverDropdown from './components/PaginationFilterPopoverDropdown';
import PaginationFilterPopoverSelectedItems from './components/PaginationFilterPopoverSelectedItems';
import { css } from 'emotion';

const styles = {
	filterPopoverChips: css`
		padding: 0.5em !important;
	`
};

interface PaginationFilterPopoverProps {
	filters: FilterField[];
}

function PaginationFilterPopover({ filters }: PaginationFilterPopoverProps): JSX.Element {
	return (
		<React.Fragment>
			<Segment.Group horizontal basic={true}>
				<Segment
					basic={true}
					floated="left"
					data-testid="filterPopoverChips"
					className={styles.filterPopoverChips}>
					<PaginationFilterPopoverSelectedItems filters={filters} />
				</Segment>
				<Segment basic={true} floated="right" data-testid="filterPopover">
					<PaginationFilterPopoverDropdown filters={filters} />
				</Segment>
			</Segment.Group>
		</React.Fragment>
	);
}

export default PaginationFilterPopover;
export { PaginationFilterPopoverProps };
