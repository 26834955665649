import FormErrors from 'components/Forms/FormErrors';
import * as React from 'react';
import { UserInfoCamel } from 'thriftgen/thriftCamelTypes';
import DataForm from './DataForm';
import { DataFormDataProps, DataFormErrors, DataFormFieldComponent } from './DataFormTypes';
import {
	CombinedDateOfBirth,
	UserPatientFirstName,
	UserPatientFullName,
	UserPatientId,
	UserPatientLastName
} from './Fields';
import { getFieldsAndSubfieldNames } from './Fields/helpers';

interface PatientFormProps {
	errors: DataFormErrors | null;
	isDisabled?: boolean;
}

type CreatePatientFormProps = Omit<DataFormDataProps<Partial<UserInfoCamel>>, 'onBlur'> &
	PatientFormProps;

const PATIENT_FIELDS: DataFormFieldComponent[] = [
	UserPatientFirstName,
	UserPatientLastName,
	UserPatientId,
	CombinedDateOfBirth
];

const REQUIRED_FIELDS: string[] = getFieldsAndSubfieldNames([
	UserPatientFullName,
	UserPatientId,
	CombinedDateOfBirth
]);

function CreatePatientForm({
	errors,
	data,
	onChange,
	isDisabled
}: CreatePatientFormProps): JSX.Element {
	return (
		<React.Fragment>
			<DataForm
				data={data}
				errors={errors}
				editableFields={isDisabled ? [] : getFieldsAndSubfieldNames(PATIENT_FIELDS)}
				fields={PATIENT_FIELDS}
				onChange={onChange}
			/>
			{errors && errors.fields.length > 0 && (
				<FormErrors fields={errors.fields} messages={errors.messages} />
			)}
		</React.Fragment>
	);
}

CreatePatientForm.validate = (data: CreatePatientFormProps['data']): DataFormErrors | null => {
	return DataForm.validate<CreatePatientFormProps['data']>(PATIENT_FIELDS, REQUIRED_FIELDS, data);
};

export { CreatePatientForm, CreatePatientFormProps };
