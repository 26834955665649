import reportWebVitals from './reportWebVitals';
import * as React from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { Provider, ProviderProps } from 'react-redux';

import { appStyle } from 'styles';
import Router from 'routing/Router';
import store from 'store/index';

import ToastNotifications from 'components/ToastNotifications/ToastNotifications';
import AirbrakeErrorBoundary from 'components/AirbrakeErrorBoundary/AirbrakeErrorBoundary';

import 'semantic-ui-less/semantic.less';
import startAuthenticationService from 'services/authentication';
import 'services/versioning';

startAuthenticationService();

interface RootProps {
	reduxStore: ProviderProps['store'];
}

const Root = (props: RootProps): JSX.Element => (
	<AirbrakeErrorBoundary>
		<Provider store={props.reduxStore}>
			<div className={appStyle}>
				<ToastNotifications />
				<Router />
			</div>
		</Provider>
	</AirbrakeErrorBoundary>
);

Root.propTypes = {
	reduxStore: PropTypes.object.isRequired
};

export default Root;

ReactDOM.render(<Root reduxStore={store} />, document.getElementById('app-root') as HTMLElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
