import * as React from 'react';

import { Grid } from 'display';

import { SecondaryPaneComponent, SecondaryPaneProps } from './types';

const SecondaryPane: SecondaryPaneComponent = ({ children }: SecondaryPaneProps): JSX.Element => {
	return (
		<Grid.Column computer="8" mobile="16">
			{children}
		</Grid.Column>
	);
};

export { SecondaryPane };
