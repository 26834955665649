import * as React from 'react';

import { getValue, translatePatientTreatmentState } from './helpers';
import { DisplayPatientTreatmentStateFieldProps, FIELD_NAME } from './types';
import ReadOnlyField from '../base/ReadOnlyField';

function DisplayPatientTreatmentStateReadOnly({
	data
}: DisplayPatientTreatmentStateFieldProps): JSX.Element {
	const value = getValue(data);
	const content = translatePatientTreatmentState(value) || 'Not provided';

	return <ReadOnlyField label="State">{content}</ReadOnlyField>;
}

DisplayPatientTreatmentStateReadOnly.FIELD_NAME = FIELD_NAME;
DisplayPatientTreatmentStateReadOnly.validate = () => null;

export { DisplayPatientTreatmentStateReadOnly };
