import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { GetEditableFieldsRequest } from 'thriftgen/api_types';
import { GetEditableFieldsRequestCamel } from 'thriftgen/thriftCamelTypes';

function getEditableFields(
	args: Partial<GetEditableFieldsRequestCamel>
): Promise<GetEditableFieldsResponse> {
	const client = getCommonClient();
	const request = new GetEditableFieldsRequest(objectCamelToSnake(args));
	return client.get_editable_fields(request);
}

export default getEditableFields;
