import moment from 'moment';
import { DateOfBirth } from 'thriftgen/api_types';

export function conformValue(rawValue: string): { year: number; month: number; day: number } {
	return {
		year: parseInt(rawValue.substr(6), 10),
		month: parseInt(rawValue.substr(0, 2), 10),
		day: parseInt(rawValue.substr(3, 2), 10)
	};
}

export function isValidDate(date: string): boolean {
	return moment(date, 'MM/DD/YYYY', true).isValid();
}

export function normalizeBirthDate(date: DateOfBirth | undefined): string {
	if (date) {
		return `${date.month.toString().padStart(2, '0')}/${date.day.toString().padStart(2, '0')}/${
			date.year
		}`;
	}

	return '';
}
