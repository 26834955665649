import { CustomFilterId, CUSTOM_FILTERS, ObjectType } from 'thriftgen/api_types';

type MappedCustomFilter = { [key in keyof typeof CustomFilterId]: string };

function reduceFiltersToObject(objectType: ObjectType): MappedCustomFilter {
	if (!(objectType in CUSTOM_FILTERS)) {
		throw new Error('The requested object has no custom filters defined');
	}

	return Object.entries(CustomFilterId)
		.filter(([, value]) => value in CUSTOM_FILTERS[objectType])
		.reduce((list, [key, value]) => {
			return {
				...list,
				[key]: CUSTOM_FILTERS[objectType][value]
			};
		}, {} as MappedCustomFilter);
}

const CASE_FILTERS: Pick<
	MappedCustomFilter,
	| 'PATIENT_NAME'
	| 'ACTOR_OR_CONTRIBUTOR'
	| 'USER_PROFILE'
	| 'PATIENT_LAST_NAME_CASE_INSENSITIVE'
	| 'PATIENT_EXTERNAL_ID_CASE_INSENSITIVE'
> = reduceFiltersToObject(ObjectType.CASE);

const CONSULT_FILTERS: Pick<
	MappedCustomFilter,
	| 'PATIENT_NAME'
	| 'ACTOR_OR_CONTRIBUTOR'
	| 'USER_PROFILE'
	| 'PATIENT_LAST_NAME_CASE_INSENSITIVE'
	| 'PATIENT_EXTERNAL_ID_CASE_INSENSITIVE'
	| 'PREVIOUS_CONSULTS_AT_CASE'
	| 'CONSULTS_BY_CLINICAL_REVIEW'
> = reduceFiltersToObject(ObjectType.CONSULT);

const USER_FILTERS: Pick<
	MappedCustomFilter,
	| 'USER_NAME'
	| 'MEMBER_AT_PARTNER'
	| 'ADMIN_AT_PARTNER'
	| 'ASSIGNABLE_AS_PARTNER_ADMIN'
	| 'ASSIGNABLE_AS_PARTNER_MEMBER'
	| 'REQUESTOR_FOR_CONSULT'
	| 'NOT_PATIENT'
	| 'SMA_LICENSED_FOR_CONSULT'
	| 'SMA_DAILY_CONSULTS_LIMIT_AVAILABLE'
	| 'SMA_SCHEDULE_AVAILABLE_FOR_CONSULT'
> = reduceFiltersToObject(ObjectType.USER);

const CONSULT_SUMMARY_FILTERS: Pick<MappedCustomFilter, 'CONSULT_REQUESTOR_CITY'> =
	reduceFiltersToObject(ObjectType.CONSULT_SUMMARY);

export {
	reduceFiltersToObject,
	CASE_FILTERS,
	CONSULT_FILTERS,
	USER_FILTERS,
	CONSULT_SUMMARY_FILTERS
};
