import { RedeemTokenResponse } from 'thriftgen/api_types';
import redeemToken, { Payload, Token } from '../redeemToken';

export default function redeemPatientPasswordlessAuthenticationToken({
	token,
	payload
}: {
	token: Token;
	payload?: Payload;
}): Promise<RedeemTokenResponse> {
	return redeemToken(token, payload);
}
