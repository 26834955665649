import * as React from 'react';

import { SpecialtyIllustrationProps } from '../illustrationTypes';

function Allergy({
	background,
	fillLight,
	fillDark,
	outline
}: SpecialtyIllustrationProps): JSX.Element {
	return (
		<svg viewBox="0 0 88 88" fill="none" xmlns="https://www.w3.org/2000/svg">
			<circle cx="44" cy="44" r="40" fill={background} />
			<path
				d="M33.9771 75.3614C45.0098 73.8623 50.6857 62.6742 46.7543 43.897L43.4761 33.1851C39.261 23.2313 32.562 17 24.1486 17C13.0208 17 4 30.6327 4 47.4494C4 64.2662 15.3029 77.8989 33.9771 75.3614Z"
				fill={fillLight}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M42.5553 33.575C38.4305 23.8344 31.2689 18.2223 23.2021 19.0425C16.939 19.6793 12.4417 22.6528 9.4733 27.4681C6.47579 32.3305 5 39.1383 5 47.4494C5 55.6421 7.75276 62.9844 12.6951 67.9584C17.6177 72.9126 24.7921 75.6003 33.8425 74.3705C39.0025 73.6694 42.8881 70.7303 45.0595 65.7114C47.2539 60.6392 47.7178 53.3784 45.7755 44.1019C45.6623 43.5613 46.0088 43.0314 46.5494 42.9182C47.0899 42.805 47.6199 43.1515 47.7331 43.6921C49.7222 53.1927 49.3139 60.9146 46.895 66.5055C44.4531 72.1498 39.9845 75.5543 34.1118 76.3523C24.4879 77.66 16.6738 74.8001 11.2764 69.3681C5.89867 63.9559 3 56.0735 3 47.4494C3 38.9438 4.50345 31.7187 7.7708 26.4186C11.0672 21.0712 16.1204 17.7522 22.9998 17.0527C32.2917 16.108 40.0916 22.6281 44.397 32.7951C44.6123 33.3037 44.3746 33.8905 43.8661 34.1059C43.3575 34.3212 42.7706 34.0835 42.5553 33.575Z"
				fill={outline}
			/>
			<path
				d="M32.2857 33.2382C32.2857 38.1561 28.2745 42.1774 23.3809 42.6667C18.1428 43.1905 12.8031 39.4927 13.4285 33.2382C13.9523 28.0001 18.4629 24.3334 23.3809 24.3334C28.2989 24.3334 32.2857 28.3202 32.2857 33.2382Z"
				fill={fillDark}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M65.6563 7.99998C66.2085 7.99998 66.6563 8.4477 66.6562 8.99998L66.6562 13.6568L71.3131 13.6568C71.8654 13.6568 72.3131 14.1046 72.3131 14.6568C72.3131 15.2091 71.8654 15.6568 71.3131 15.6568L66.0705 15.6568L61.6493 20.078C61.2588 20.4685 60.6256 20.4685 60.2351 20.078C59.8446 19.6875 59.8446 19.0543 60.2351 18.6638L64.6562 14.2426L64.6563 8.99998C64.6563 8.4477 65.104 7.99998 65.6563 7.99998Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M47.6563 31C48.2085 31 48.6563 31.4477 48.6562 32L48.6562 36.6569L53.3131 36.6569C53.8654 36.6569 54.3131 37.1046 54.3131 37.6569C54.3131 38.2091 53.8654 38.6569 53.3131 38.6569L48.0705 38.6569L43.6493 43.078C43.2588 43.4685 42.6256 43.4685 42.2351 43.078C41.8446 42.6875 41.8446 42.0543 42.2351 41.6638L46.6562 37.2426L46.6563 32C46.6563 31.4477 47.104 31 47.6563 31Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M62.9841 23.8229C63.4252 23.4906 64.0522 23.5787 64.3846 24.0198L67.1872 27.7389L70.9063 24.9363C71.3474 24.6039 71.9744 24.6921 72.3067 25.1331C72.6391 25.5742 72.551 26.2012 72.1099 26.5336L67.923 29.6887L67.0528 35.8803C66.9759 36.4272 66.4703 36.8082 65.9233 36.7314C65.3764 36.6545 64.9954 36.1489 65.0722 35.6019L65.9424 29.4103L62.7873 25.2234C62.4549 24.7823 62.5431 24.1553 62.9841 23.8229Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M72.5583 64.1675C73.0589 64.4009 73.2754 64.9959 73.042 65.4964L71.074 69.717L75.2945 71.6851C75.795 71.9185 76.0116 72.5134 75.7782 73.014C75.5448 73.5145 74.9498 73.7311 74.4493 73.4977L69.6978 71.282L63.8224 73.4205C63.3035 73.6094 62.7296 73.3418 62.5407 72.8228C62.3518 72.3039 62.6194 71.73 63.1384 71.5411L69.0138 69.4027L71.2294 64.6512C71.4628 64.1507 72.0578 63.9341 72.5583 64.1675Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M72.6664 31.8755C72.9342 31.3925 73.5428 31.218 74.0258 31.4857L78.0988 33.7434L80.3565 29.6704C80.6243 29.1874 81.2329 29.0129 81.7159 29.2806C82.199 29.5484 82.3735 30.157 82.1058 30.64L79.5641 35.2254L81.2875 41.2356C81.4397 41.7665 81.1327 42.3203 80.6019 42.4725C80.071 42.6247 79.5172 42.3178 79.365 41.7869L77.6415 35.7766L73.0562 33.235C72.5732 32.9672 72.3987 32.3586 72.6664 31.8755Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M63.9915 41.5317C64.4439 41.8485 64.5539 42.472 64.2371 42.9244L61.5661 46.7391L65.3807 49.4101C65.8331 49.7269 65.9431 50.3505 65.6263 50.8029C65.3095 51.2553 64.686 51.3652 64.2336 51.0485L59.9391 48.0414L53.7816 49.1271C53.2377 49.223 52.719 48.8599 52.6231 48.316C52.5272 47.7721 52.8904 47.2534 53.4343 47.1575L59.5918 46.0718L62.5988 41.7773C62.9156 41.3249 63.5391 41.2149 63.9915 41.5317Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M72.7675 45.101C73.301 44.958 73.8493 45.2746 73.9923 45.8081L75.1976 50.3062L79.6957 49.101C80.2292 48.958 80.7776 49.2746 80.9205 49.8081C81.0634 50.3415 80.7469 50.8899 80.2134 51.0328L75.1494 52.3897L72.0232 57.8045C71.747 58.2828 71.1354 58.4467 70.6571 58.1705C70.1788 57.8944 70.015 57.2828 70.2911 56.8045L73.4173 51.3897L72.0604 46.3257C71.9175 45.7922 72.2341 45.2439 72.7675 45.101Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M60.8333 67.2793C60.3809 67.5961 59.7574 67.4862 59.4406 67.0338L56.7695 63.2191L52.9549 65.8902C52.5025 66.2069 51.8789 66.097 51.5621 65.6446C51.2454 65.1922 51.3553 64.5686 51.8077 64.2519L56.1022 61.2448L57.188 55.0873C57.2839 54.5434 57.8025 54.1803 58.3464 54.2762C58.8903 54.3721 59.2535 54.8907 59.1576 55.4346L58.0719 61.5921L61.0789 65.8866C61.3957 66.339 61.2857 66.9626 60.8333 67.2793Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M46.2929 14.2929C46.6834 13.9024 47.3166 13.9024 47.7071 14.2929L51 17.5858L54.2929 14.2929C54.6834 13.9024 55.3166 13.9024 55.7071 14.2929C56.0976 14.6834 56.0976 15.3166 55.7071 15.7071L52 19.4142V25.6667C52 26.219 51.5523 26.6667 51 26.6667C50.4477 26.6667 50 26.219 50 25.6667V19.4142L46.2929 15.7071C45.9024 15.3166 45.9024 14.6834 46.2929 14.2929Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M28.139 5.49713C28.4068 5.01409 29.0154 4.83956 29.4985 5.10732L33.5714 7.365L35.8291 3.29203C36.0969 2.80899 36.7055 2.63446 37.1886 2.90222C37.6716 3.16997 37.8461 3.77861 37.5784 4.26165L35.0367 8.84696L36.7601 14.8572C36.9123 15.3881 36.6054 15.9419 36.0745 16.0941C35.5436 16.2463 34.9898 15.9394 34.8376 15.4085L33.1142 9.39824L28.5289 6.85655C28.0458 6.5888 27.8713 5.98016 28.139 5.49713Z"
				fill={outline}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M12 59C12 58.4477 12.4477 58 13 58H42C42.5523 58 43 58.4477 43 59C43 59.5523 42.5523 60 42 60H13C12.4477 60 12 59.5523 12 59Z"
				fill={fillDark}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M15.5 63.5C15.5 62.9477 15.9477 62.5 16.5 62.5H38.5C39.0523 62.5 39.5 62.9477 39.5 63.5C39.5 64.0523 39.0523 64.5 38.5 64.5H16.5C15.9477 64.5 15.5 64.0523 15.5 63.5Z"
				fill={fillDark}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M18.5 67.5C18.5 66.9477 18.9477 66.5 19.5 66.5H35.5C36.0523 66.5 36.5 66.9477 36.5 67.5C36.5 68.0523 36.0523 68.5 35.5 68.5H19.5C18.9477 68.5 18.5 68.0523 18.5 67.5Z"
				fill={fillDark}
			/>
		</svg>
	);
}

export default Allergy;
