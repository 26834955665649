import FirstName from './FirstName';
import { buildFieldVariation } from '../helpers';

const FIELD_NAME = 'firstName';
const LABEL = 'Your first name';

export default buildFieldVariation({
	Field: FirstName,
	fieldName: FIELD_NAME,
	label: LABEL
});
