import {
	deriveIntSetMutationFromInitialValue,
	transformBooleanToBoolMutation,
	transformStringToStringMutation
} from 'api/mutationHelpers';
import { CancelButton } from 'components/Buttons';
import { ButtonType } from 'display';
import * as React from 'react';
import { Partner, PartnerMutations, Specialty } from 'thriftgen/api_types';
import {
	PartnerCamel,
	PartnerMutationsCamel,
	StringMutationCamel
} from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from '../DataFormTypes';
import DataFormWrapper from '../DataFormWrapper';
import { getFieldsAndSubfieldNames } from '../Fields/helpers';

import DEFAULT_SPECIALTIES from './defaultSpecialties';

interface PartnerFormProps {
	consumerEditableFields?: string[];
	fields: DataFormFieldComponent[];
	onCancel: () => void;
	onSubmit: (formData: Partial<PartnerMutationsCamel>) => Promise<void>;
	partner?: PartnerCamel;
	requiredFields?: string[];
}

/*
These type definitions highlight issues and limitations in DataForm:
1. DataForm does case conversion on the input data.
2. DataForm does not have an abstraction for state vs. changes.
*/
interface PartnerFormInitialState {
	name: StringMutationCamel;
	admin_ids: PartnerMutations['admin_ids'];
	logoUploadKey: string;
	logoUrl: Partner['logo_url'];
	member_ids: PartnerMutations['member_ids'];
	shared_patient_page_intro: string;
	specialties: Specialty[];
	create_cases_sitka: Partner['create_cases_sitka'];
	create_consults_sitka: Partner['create_consults_sitka'];
	enable_patient_communications: Partner['enable_patient_communications'];
	enable_screen_sharing: Partner['enable_screen_sharing'];
}

interface PartnerFormState {
	name: StringMutationCamel;
	adminIds: PartnerMutationsCamel['adminIds'];
	logoUploadKey: string;
	logoUrl: Partner['logo_url'];
	memberIds: PartnerMutationsCamel['memberIds'];
	sharedPatientPageIntro: string;
	specialties: Specialty[];
	createCasesSitka: PartnerCamel['createCasesSitka'];
	createConsultsSitka: PartnerCamel['createConsultsSitka'];
	enablePatientCommunications: PartnerCamel['enablePatientCommunications'];
	enableScreenSharing: PartnerCamel['enableScreenSharing'];
}

function getInitialFormState(partner?: PartnerCamel): PartnerFormInitialState {
	return {
		name: {
			value: partner ? partner.name : ''
		},
		admin_ids: {
			to_add: [],
			to_remove: []
		},
		logoUploadKey: '',
		logoUrl: partner ? partner.logoUrl : '',
		member_ids: {
			to_add: [],
			to_remove: []
		},
		specialties: partner ? partner.specialties : DEFAULT_SPECIALTIES,
		shared_patient_page_intro: partner ? partner.sharedPatientPageIntro : '',
		create_consults_sitka: partner ? partner.createConsultsSitka : true,
		create_cases_sitka: partner ? partner.createCasesSitka : false,
		enable_patient_communications: partner ? partner.enablePatientCommunications : false,
		enable_screen_sharing: partner ? partner.enableScreenSharing : true
	};
}

function PartnerForm({
	consumerEditableFields,
	fields,
	onCancel,
	onSubmit,
	partner,
	requiredFields
}: PartnerFormProps): JSX.Element {
	const isNewPartner = !partner;
	const initialFormState = getInitialFormState(partner);

	function renderCancelButton(): JSX.Element {
		return <CancelButton buttonType={ButtonType.SECONDARY} onClick={onCancel} />;
	}

	function getSpecialtiesMutation(changes: Partial<PartnerFormState>) {
		const initialList: Specialty[] = isNewPartner ? [] : initialFormState.specialties;
		const updatedList: Specialty[] = changes.specialties
			? changes.specialties
			: initialFormState.specialties;

		if (!isNewPartner && !changes.specialties) {
			return undefined;
		}

		return deriveIntSetMutationFromInitialValue(initialList, updatedList);
	}

	function onSubmitHandler(changes: Partial<PartnerFormState>): Promise<void> {
		if (changes.enablePatientCommunications === false) {
			changes.createCasesSitka = false;
			changes.sharedPatientPageIntro = undefined;
		}

		const specialtiesMutation = getSpecialtiesMutation(changes);

		const mutations: Partial<PartnerMutationsCamel> = {
			...changes,
			logoUploadKey: transformStringToStringMutation(changes.logoUploadKey),
			sharedPatientPageIntro: transformStringToStringMutation(changes.sharedPatientPageIntro),
			createConsultsSitka: transformBooleanToBoolMutation(changes.createConsultsSitka),
			createCasesSitka: transformBooleanToBoolMutation(changes.createCasesSitka),
			enablePatientCommunications: transformBooleanToBoolMutation(
				changes.enablePatientCommunications
			),
			enableScreenSharing: transformBooleanToBoolMutation(changes.enableScreenSharing),
			specialties: specialtiesMutation
		};
		return onSubmit(mutations);
	}

	return (
		<DataFormWrapper
			data={initialFormState}
			editableFields={consumerEditableFields || getFieldsAndSubfieldNames(fields)}
			fields={fields}
			onSubmit={onSubmitHandler}
			requiredFields={requiredFields}
			CancelControl={renderCancelButton}
		/>
	);
}

export { PartnerForm };
