import * as React from 'react';
import { css } from 'emotion';

import { SitkaLogo } from 'components/Svgs';
import { Text } from 'display';

const styles = {
	container: css`
		align-items: center;
		display: flex;
		flex-direction: column;
		& svg {
			height: 1em;
			width: auto;
		}
	`,
	text: css`
		line-height: 1em;
	`
};

function PoweredByLogo(): JSX.Element {
	return (
		<div className={styles.container}>
			<Text size={Text.Size.T5} className={styles.text}>
				powered by
			</Text>
			<SitkaLogo />
		</div>
	);
}

export default PoweredByLogo;
