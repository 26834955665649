import { apiCaller, apiCallerWithToast } from 'store/requests';
import { getHistory, reportEvent } from 'api/common';
import { checkToken } from 'api/auth';

import { handleReportEventResponse } from './all.actions';

const sendCheckToken = apiCaller({
	apiCall: checkToken
});

const sendGetHistory = apiCaller({
	apiCall: getHistory
});

const sendReportEvent = apiCallerWithToast({
	action: handleReportEventResponse,
	apiCall: reportEvent
});

export { sendCheckToken, sendGetHistory, sendReportEvent };
