import { transformToThriftFilter } from 'api/helpers';
import { ConsultsLoadError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';
import { usePaginationContext } from 'contexts/PaginationContext';
import { Loader } from 'display';
import { Parameters } from 'hooks/pagination';
import * as React from 'react';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { sendGetConsults } from 'store/consults';
import { GetConsultsResponse, Sort } from 'thriftgen/api_types';
import { FilterCamel, GetConsultsRequestCamel } from 'thriftgen/thriftCamelTypes';

type RequestConsultsProps = WithApiCallProps & {
	children: JSX.Element;
	defaultFilters: Parameters['filters'];
	defaultSorts: Parameters['sorts'];
	sendGetConsults: (args: GetConsultsRequestCamel) => Promise<GetConsultsResponse>;
};

function RequestConsults({
	children,
	defaultFilters = [],
	request,
	defaultSorts = [],
	...props
}: RequestConsultsProps): JSX.Element {
	const { status, message } = request.data;
	const { parameters, setNeighborPageCursors } = usePaginationContext();

	React.useEffect((): void => {
		const { pageSize, pageCursor, filters, sorts } = parameters;

		const requestFilters: FilterCamel[] = [...defaultFilters, ...filters].map(
			transformToThriftFilter
		);

		const requestSorts: Sort[] = [...defaultSorts, ...sorts];

		props
			.sendGetConsults({
				pageParams: {
					pageCursor: pageCursor || undefined,
					pageSize,
					filters: requestFilters,
					sorts: requestSorts
				}
			})
			.then(({ page_markers }) =>
				setNeighborPageCursors({
					prevPageCursor: page_markers.prev_page_cursor,
					nextPageCursor: page_markers.next_page_cursor
				})
			);
	}, [parameters]);

	switch (status) {
		case FAILURE:
			return <ConsultsLoadError message={message} />;

		case SUCCESS:
			return children;

		case INACTIVE:
		case IN_PROGRESS:
		default:
			return <Loader active={true} />;
	}
}

export default withApiCall({ sendGetConsults }, RequestConsults);
