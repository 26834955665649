import * as React from 'react';
import { isMobile } from 'lib/userAgent';

import { css } from 'emotion';
import { fontWeight } from 'styles';
import Heading from '../Heading';
import Paragraph from '../Paragraph';

import * as flyingMailSvg from 'assets/images/flying-mail-orange.svg';
import * as flyingVideoSvg from 'assets/images/flying-video-orange.svg';
import * as idCardSvg from 'assets/images/id-card-orange.svg';
import { Banner } from 'display';

const styles = {
	banner: css`
		background-color: #fceee5;
	`,
	heading: css`
		font-weight: ${fontWeight.regular};
	`,
	image: css`
		margin-bottom: 1em;
		margin-right: 1.5em;
	`,
	imageSize: {
		desktop: css`
			height: 76px;
			width: 96px;
		`,
		mobile: css`
			height: 64px;
			width: 80px;
		`
	}
};

const ICONS = {
	'flying-mail': flyingMailSvg,
	'flying-video': flyingVideoSvg,
	'id-card': idCardSvg
};

const PLATFORM = isMobile() ? 'mobile' : 'desktop';

interface NotificationBannerProps {
	body: string;
	icon: string;
	title: string;
}

function getPlatformIcon(iconName: string): string {
	return ICONS[iconName];
}

function NotificationBanner({ body, icon, title }: NotificationBannerProps): JSX.Element {
	return (
		<Banner className={styles.banner}>
			<div className={styles.image}>
				<img
					className={styles.imageSize[PLATFORM]}
					src={getPlatformIcon(icon)}
					alt={icon}
				/>
			</div>
			<div>
				<Heading className={styles.heading} as={Heading.Type.H2} size={Heading.Size.T4}>
					{title}
				</Heading>
				<Paragraph weight={Paragraph.Weight.BOLD}>{body}</Paragraph>
			</div>
		</Banner>
	);
}

export default NotificationBanner;
