import * as React from 'react';

import CreateConsultsSitka from '../Fields/CreateConsultsSitka';
import CreateCasesSitka from '../Fields/CreateCasesSitka';
import EnableScreenSharing from '../Fields/EnableScreenSharing';
import { DataFormFieldComponentProps } from '../DataFormTypes';
import { getFieldsAndSubfieldNames } from '../Fields/helpers';
import { Form } from 'display';

const LEGEND = ' Settings';
const FIELDS = [CreateConsultsSitka, CreateCasesSitka, EnableScreenSharing];

function PartnerSettings(props: DataFormFieldComponentProps): JSX.Element {
	return (
		<Form.Group grouped>
			<label>{LEGEND}</label>
			{FIELDS.map(Field => {
				return <Field key={Field.name} {...props} />;
			})}
		</Form.Group>
	);
}

PartnerSettings.FIELD_NAME = LEGEND;
PartnerSettings.SUB_FIELD_NAMES = getFieldsAndSubfieldNames(FIELDS);
PartnerSettings.validate = () => null;

export default PartnerSettings;
