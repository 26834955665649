import React, { useState } from 'react';
import {
	CheckboxProps,
	DropdownProps,
	DropdownItemProps,
	Form,
	Icon,
	Loader,
	Message,
	Paragraph,
	Segment
} from 'display';
import { css } from 'emotion';
import { camelCase } from 'lodash';

import { MediaDevicesList, MediaDeviceSource, MediaDeviceSources } from 'lib/mediaDevices';
import { SEMANTIC_COLOR_STRINGS } from 'styles';
import { RecordingOption } from './types';
import RecordButton from 'components/Buttons/LinkButtons/RecordButton';
import Strings from 'lib/strings';

interface RecorderSettingsProps {
	mediaDevices: MediaDevicesList;
	onRecordingOptionChange: (option: RecordingOption) => void;
	onSettingsConfirm: () => void;
	onSourceChange: (mediaDeviceSource: MediaDeviceSource) => void;
	recordingOption: RecordingOption;
	canRecordScreen: boolean;
	sources: MediaDeviceSources;
}

interface RecordingOptionValue {
	key: string;
	text: string;
	value: string;
}

const styles = {
	override: css`
		& .equal.width.fields .field {
			width: 50% !important;
		}
	`,
	actionWrapper: css`
		display: flex;
		flex-direction: column;
	`,
	recordButton: css`
		width: fit-content;
		align-self: flex-end;
	`
};

function getEmptySourceWarning(mediaKind: string[]): string {
	return `No sources for ${mediaKind.join(
		' and '
	)} were found. Please ensure that your webcam is connected properly and try again.`;
}

const RECORDING_OPTIONS: RecordingOptionValue[] = [
	{
		key: RecordingOption.CAMERA_ONLY,
		text: 'Camera',
		value: RecordingOption.CAMERA_ONLY
	},
	{
		key: RecordingOption.SCREENSHARE,
		text: 'Screen share',
		value: RecordingOption.SCREENSHARE
	}
];

function hasNoMediaDevices(devices: MediaDeviceInfo[]): boolean {
	return devices.length === 0;
}

function normalizeMediaDevices(sources: MediaDeviceInfo[]): DropdownItemProps[] {
	return sources.map(source => ({
		key: source.label,
		text: source.label,
		value: source.deviceId
	}));
}

const SOURCE_PLACEHOLDER = 'Select source';

function SelectScreenOptions({
	onChange,
	value
}: {
	onChange: CheckboxProps['onChange'];
	value: RecordingOption;
}): JSX.Element {
	const [recordingOptions, setRecordingOptions] = useState<RecordingOptionValue[]>();

	React.useEffect(() => {
		setRecordingOptions(RECORDING_OPTIONS);
	}, []);

	function renderOption(opts: RecordingOptionValue): JSX.Element {
		return (
			<Form.Radio
				label={opts.text}
				name={opts.key}
				value={opts.value}
				checked={opts.value === value}
				onChange={onChange}
				key={opts.key}
				id={opts.key}
				className={`${camelCase(opts.value)}`}
			/>
		);
	}

	return (
		<Form.Field>
			<label>{Strings.Labels.RECORD}</label>
			<Form.Group grouped={true}>
				{recordingOptions ? recordingOptions.map(renderOption) : <Loader active />}
			</Form.Group>
		</Form.Field>
	);
}

class RecorderSettings extends React.PureComponent<RecorderSettingsProps> {
	constructor(props: RecorderSettingsProps) {
		super(props);

		this.handleRecordingOptionChange = this.handleRecordingOptionChange.bind(this);
		this.handleSourceChange = this.handleSourceChange.bind(this);
	}

	public render(): JSX.Element {
		const { onSettingsConfirm } = this.props;
		return (
			<Segment basic={true} inverted={true} noMargins={true} className={styles.actionWrapper}>
				{this.renderHeader()}
				{this.renderForm()}
				{this.renderEmptySourceWarningIfAny()}
				<div className={styles.recordButton}>
					<RecordButton onClick={onSettingsConfirm} />
				</div>
			</Segment>
		);
	}

	private handleRecordingOptionChange(
		_: React.SyntheticEvent<HTMLElement>,
		{ value }: CheckboxProps
	): void {
		if (value) {
			this.props.onRecordingOptionChange(value as RecordingOption);
		}
	}

	private handleSourceChange(
		_: React.SyntheticEvent<HTMLElement>,
		{ name, value }: DropdownProps
	): void {
		if (typeof value === 'string') {
			this.props.onSourceChange({ name, value });
		}
	}

	private renderEmptySourceWarningIfAny(): React.ReactNode {
		const { mediaDevices } = this.props;
		const emptySources = Object.keys(mediaDevices).filter(key =>
			hasNoMediaDevices(mediaDevices[key])
		);

		if (emptySources.length > 0) {
			return <Message warning={true}>{getEmptySourceWarning(emptySources)}</Message>;
		}

		return null;
	}

	private renderForm(): JSX.Element {
		const { mediaDevices, recordingOption, canRecordScreen, sources } = this.props;
		return (
			<Form as="div" className={styles.override} inverted={true}>
				{canRecordScreen && (
					<SelectScreenOptions
						onChange={this.handleRecordingOptionChange}
						value={recordingOption}
					/>
				)}
				<Form.Group widths="equal">
					<Form.Select
						label={Strings.Labels.CAMERA_SOURCE}
						name="video"
						onChange={this.handleSourceChange}
						options={normalizeMediaDevices(mediaDevices.video)}
						value={sources.video ? sources.video : undefined}
						placeholder={SOURCE_PLACEHOLDER}
						disabled={hasNoMediaDevices(mediaDevices.video)}
						selectOnBlur={false}
					/>
					<Form.Select
						label={Strings.Labels.MICROPHONE_SOURCE}
						name="audio"
						onChange={this.handleSourceChange}
						options={normalizeMediaDevices(mediaDevices.audio)}
						value={sources.audio ? sources.audio : undefined}
						placeholder={SOURCE_PLACEHOLDER}
						disabled={hasNoMediaDevices(mediaDevices.audio)}
						selectOnBlur={false}
					/>
				</Form.Group>
			</Form>
		);
	}

	private renderHeader(): JSX.Element {
		return (
			<Paragraph weight={Paragraph.Weight.BOLD} size={Paragraph.Size.T2}>
				<Icon name="settings" color={SEMANTIC_COLOR_STRINGS.PRIMARY} />
				{Strings.Headers.DEVICE_SETTINGS}
			</Paragraph>
		);
	}
}

export default RecorderSettings;
