import * as React from 'react';

import { Message, Paragraph } from 'display';

interface SharingErrorMessageProps {
	message: string;
}

function SharingErrorMessage({ message }: SharingErrorMessageProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Unable to share message</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default SharingErrorMessage;
