import * as React from 'react';

import {
	ConversationContext,
	ConversationContextData,
	ConversationContextMutators
} from 'contexts';

interface ContextProps {
	children: React.ReactNode;
	data: ConversationContextData;
	mutators: ConversationContextMutators;
}

function ConversationContextProvider({ children, data, mutators }: ContextProps): JSX.Element {
	return (
		<ConversationContext.Provider
			value={{
				...data,
				...mutators
			}}>
			{children}
		</ConversationContext.Provider>
	);
}

export default ConversationContextProvider;
