import { Anchor, Paragraph } from 'display';
import { getUserFullNameAndTitleAndCredentials } from 'lib/userHelpers';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectConsultById } from 'store/consults';
import { AppState } from 'store/types';
import { selectUserById } from 'store/users';
import { Consult, User } from 'thriftgen/api_types';

interface ConsumerProps {
	consultId: Consult['consult_id'];
}

interface StoreProps {
	consultRequestor: User;
}

type NoSharedMessagesMessageProps = ConsumerProps & StoreProps;

function NoSharedMessagesMessage({ consultRequestor }: NoSharedMessagesMessageProps): JSX.Element {
	return (
		<React.Fragment>
			<Paragraph>
				If you&apos;ve seen messages here before, please contact your provider,{' '}
				{getUserFullNameAndTitleAndCredentials(consultRequestor)} for help.
			</Paragraph>
			<Anchor href={`mailto:${consultRequestor.email}`}>{consultRequestor.email}</Anchor>
		</React.Fragment>
	);
}

const mapStoreToProps = (store: AppState, ownProps: ConsumerProps): StoreProps => {
	const { consultId } = ownProps;
	const consult = selectConsultById(store, { id: consultId });

	return {
		consultRequestor: selectUserById(store, { id: consult.requestor_id })
	};
};

export default connect(mapStoreToProps)(NoSharedMessagesMessage);
