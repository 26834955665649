import * as React from 'react';

import { Button } from 'display';
import Link from 'components/Link';
import Strings from 'lib/strings';

interface LinkToEditButtonProps {
	link: string;
}

function LinkToEditButton({ link }: LinkToEditButtonProps): JSX.Element {
	return (
		<Link to={link}>
			<Button.TextPrimary className="editButton" size={Button.Size.TINY}>
				{Strings.Buttons.EDIT}
			</Button.TextPrimary>
		</Link>
	);
}

export default LinkToEditButton;
