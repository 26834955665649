import { SitkaLogger } from 'lib/sitkaLogger';

enum PendoGuide {
	CONSULT_SURVEY = 'CONSULT_SURVEY'
}

type PendoGuideKeys = keyof typeof PendoGuide;
type PendoGuideIds = {
	[key in PendoGuideKeys]: string;
};

interface PendoProfile {
	visitor: {
		id: string;
		isSitkaMedicalAssociate: boolean;
		isSitkaOperations: boolean;
	};
	account: {
		id?: string | null;
		[key: string]: unknown;
	};
}

const PENDO_KEY = '8982c112-1082-4c8b-70ea-a58cf2615d7b';
const GUIDES_IDS: PendoGuideIds = {
	[PendoGuide.CONSULT_SURVEY]: 'Vzs7GneI0en1QIxy0thga5x0Ymo'
};

function loadPendo(): void {
	try {
		// Snippet from Pendo, not our own.

		/* eslint-disable */
		(function (apiKey) {
			(function (p, e, n, d, o) {
				// @ts-ignore
				var v, w, x, y, z;
				o = p[d] = p[d] || {};
				// @ts-ignore
				o._q = o._q || [];
				v = ['initialize', 'identify', 'updateOptions', 'pageLoad', 'track'];
				for (w = 0, x = v.length; w < x; ++w)
					(function (m) {
						// @ts-ignore
						o[m] =
							// @ts-ignore
							o[m] ||
							function () {
								// @ts-ignore
								o._q[m === v[0] ? 'unshift' : 'push'](
									[m].concat([].slice.call(arguments, 0))
								);
							};
					})(v[w]);
				y = e.createElement(n);
				// @ts-ignore
				y.async = !0;
				// @ts-ignore
				y.src =
					'https://content.sts001.trustsitka.com/agent/static/' + apiKey + '/pendo.js';
				z = e.getElementsByTagName(n)[0];
				// @ts-ignore
				z.parentNode.insertBefore(y, z);
			})(window, document, 'script', 'pendo');
		})(PENDO_KEY);
		/* eslint-enable */
	} catch (e) {
		SitkaLogger.logMessage(e);
	}
}

function pendoExists(
	namespace: PendoGlobalNamespace | null | undefined
): namespace is PendoGlobalNamespace {
	return 'pendo' in window && Boolean(namespace);
}

function safelyRun<Result>(callback: (pendo: PendoGlobalNamespace) => Result): Result | void {
	// Protects against exceptions in case Pendo was not loaded or if it was
	// blocked in the browser (e.g extensions)
	if (pendoExists(window.pendo)) {
		try {
			return callback(window.pendo);
		} catch (e) {
			SitkaLogger.logMessage(e);
		}
	}
}

function initializePendo(config: PendoProfile, onReady: () => void): void {
	safelyRun((pendo: PendoGlobalNamespace): void => {
		pendo.initialize({
			...config,
			// Do not collect the innerText from any element to avoid leaking PHI or PII.
			excludeAllText: true,
			events: {
				ready: onReady
			}
		});
	});
}

function disablePendo(): void {
	safelyRun((pendo: PendoGlobalNamespace): void => {
		pendo.stopSendingEvents();
		pendo.setGuidesDisabled(true);
		pendo.loadGuides();
	});
}

function isPendoEnabled(): boolean {
	return (
		safelyRun((pendo: PendoGlobalNamespace): boolean => {
			return pendo.isSendingEvents();
		}) || false
	);
}

function reenablePendo(): void {
	safelyRun((pendo: PendoGlobalNamespace): void => {
		pendo.startSendingEvents();
		pendo.setGuidesDisabled(false);
		pendo.loadGuides();
	});
}

function updateProfile(info: PendoProfile): void {
	safelyRun((pendo: PendoGlobalNamespace): void => {
		pendo.identify(info);
		pendo.loadGuides();
	});
}

function showGuideIfAvailable(guideKey: PendoGuide): void {
	safelyRun((pendo: PendoGlobalNamespace) => {
		if (pendo.areGuidesDisabled()) {
			return;
		}

		const guide = pendo.guides.find(({ id }) => id === GUIDES_IDS[guideKey]);

		if (guide) {
			guide.launch();
		}
	});
}

export {
	disablePendo,
	initializePendo,
	isPendoEnabled,
	loadPendo,
	PendoGuide,
	PendoProfile,
	reenablePendo,
	showGuideIfAvailable,
	updateProfile
};
