import { DataFormErrors } from 'components/Forms/DataFormTypes';
import { SpecialtyNotListedMessage } from 'components/Messages';
import { SelectGroup, SelectGroupItem, SelectGroupItemGroup } from 'display';
import { css } from 'emotion';
import * as React from 'react';
import { Specialty } from 'thriftgen/api_types';
import {
	transformSpecialtiesToSelectGroupItems,
	transformSpecialtyToSelectGroupItem
} from './helpers';
import { PrettySpecialtiesProps, SelectedSpecialty } from './types';
import Strings from 'lib/strings';

const styles = {
	header: css`
		font-size: 1.5rem;
	`
};

const SPECIALTY_TITLE = Strings.Labels.SELECT_A_SPECIALTY;
const FIELD_NAME = 'specialty';
const UNLISTED_FALLBACK: SelectGroupItem = transformSpecialtyToSelectGroupItem(Specialty.UNLISTED);

function getValue(data: PrettySpecialtiesProps['data']): SelectedSpecialty {
	return data[FIELD_NAME];
}

function validate(data: PrettySpecialtiesProps['data'], required: boolean): DataFormErrors | null {
	const errors: DataFormErrors = {
		fields: [],
		messages: []
	};
	if (required && data[FIELD_NAME] === undefined) {
		errors.fields.push(FIELD_NAME);
		errors.messages.push(Strings.Errors.PLEASE_SELECT_A_SPECIALTY);
		return errors;
	}
	return null;
}

function PrettySpecialties({
	editableFields,
	data,
	onChange,
	specialties
}: PrettySpecialtiesProps): JSX.Element | null {
	const specialtyGroup: SelectGroupItemGroup = {
		items: transformSpecialtiesToSelectGroupItems(specialties)
	};

	function onSelected(selected: number): void {
		onChange(null as never, { name: FIELD_NAME, value: selected });
	}

	if (editableFields.includes(FIELD_NAME)) {
		return (
			<React.Fragment>
				<h2 className={styles.header}>{SPECIALTY_TITLE}</h2>
				<SelectGroup
					data-testid="prettySpecialties"
					groups={[specialtyGroup]}
					noResultsMessage={<SpecialtyNotListedMessage />}
					noResultsFallback={UNLISTED_FALLBACK}
					onSelected={onSelected}
					search={true}
					selected={getValue(data)}
				/>
			</React.Fragment>
		);
	}

	return null;
}

PrettySpecialties.FIELD_NAME = FIELD_NAME;
PrettySpecialties.validate = validate;

export default PrettySpecialties;
