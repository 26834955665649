import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import { Icon } from 'display';
import Link from 'components/Link';
import Strings from 'lib/strings';

function LinkToDashboardPCPButton() {
	return (
		<Link to={RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_PCP)}>
			<Icon name="left arrow" />
			{` ${Strings.Buttons.MY_SITKA}`}
		</Link>
	);
}

export default LinkToDashboardPCPButton;
