import * as React from 'react';

import { Button } from 'display';
import Strings from 'lib/strings';

function PauseRecordingButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Minimal className="pauseRecordingButton" onClick={onClick}>
			{Strings.Buttons.PAUSE}
		</Button.Minimal>
	);
}

export default PauseRecordingButton;
