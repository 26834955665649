import * as React from 'react';

import { Divider, Paragraph } from 'display';
import { getFieldsAndSubfieldNames, getErrors } from './helpers';
import Email from './Email/Email';
import PhoneNumber from './PhoneNumber/PhoneNumber';
import { DataFormFieldComponentProps, DataFormErrors } from '../DataFormTypes';

type UserContactProps = DataFormFieldComponentProps<string> & {
	label?: string;
};

interface UserContactData {
	email: string;
	phoneNumber: string;
}

const FIELD_NAME = 'userContact';
const FIELDS = [Email, PhoneNumber];
const SUB_FIELD_NAMES = getFieldsAndSubfieldNames(FIELDS);

function hasNoContactInformation(data: UserContactData): boolean {
	return FIELDS.every(field => !data[field.FIELD_NAME]);
}

function UserContact({ label, ...props }: UserContactProps): JSX.Element {
	const { editableFields } = props;

	if (editableFields.includes(FIELD_NAME)) {
		return (
			<React.Fragment>
				<PhoneNumber {...props} alwaysShow={true} />
				<Divider.Text alignment={Divider.Text.Alignments.LEFT} text="And / Or" />
				<Email {...props} alwaysShow={true} />
			</React.Fragment>
		);
	}

	return (
		<React.Fragment>
			{label && (
				<Paragraph size={Paragraph.Size.T4} weight={Paragraph.Weight.BOLD}>
					{label}
				</Paragraph>
			)}
			<PhoneNumber {...props} alwaysShow={true} />
			<Email {...props} alwaysShow={true} />
		</React.Fragment>
	);
}

UserContact.FIELD_NAME = FIELD_NAME;
UserContact.SUB_FIELD_NAMES = SUB_FIELD_NAMES;
UserContact.validate = (data: UserContactData, required: boolean): DataFormErrors => {
	const errors = getErrors(FIELDS, [], data) as DataFormErrors;

	if (required && hasNoContactInformation(data)) {
		errors.fields = SUB_FIELD_NAMES;
		errors.messages = [
			...errors.messages,
			'Please enter a valid email address or phone number.'
		];
	}

	return errors;
};

export default UserContact;
