import * as React from 'react';

import { Message, Paragraph } from 'display';

interface SendLinkErrorMessageProps {
	message: string;
}

function SendLinkErrorMessage({ message }: SendLinkErrorMessageProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Unable to send link</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default SendLinkErrorMessage;
