import { SubmitVideoButton, SubmitWrittenClinicalButton } from 'components/Buttons';
import { useDictationContext } from 'contexts/DictationContext';
import { objectCamelToSnake } from 'lib/objectConverter';
import { capitalizeFirstLetter } from 'lib/stringConverter';
import Strings from 'lib/strings';
import { noop } from 'lodash';
import React, { useCallback, useState } from 'react';
import { Prompt, withRouter } from 'react-router';
import {
	ConsultDataType,
	NewConversationMessage,
	SpecialistResponse,
	SpecialistResponseAssessment,
	SpecialistResponseRecommendation
} from 'thriftgen/api_types.js';
import {
	DataFormConditionalField,
	DataFormConditionalFieldAction,
	DataFormFieldComponent
} from './DataFormTypes';
import {
	ManagementRecommendation,
	RecordVideo,
	ReferralSpecialty,
	SectionHeadingClinicalDataCapture,
	SectionHeadingDocumentConsultResponse,
	SectionHeadingPCPManagement,
	SectionInfoWrittenResponse,
	SpecialistResponseAssessmentGroup,
	SpecialistResponseMoreInfoCheckbox,
	SpecialistResponseTypeRadio,
	TextResponse
} from './Fields';
import SMAResponseFormSubsection, {
	OnUnsavedChangesChangeProps,
	SMAResponseFormData,
	SMAResponseFormSubsectionProps
} from './SMAResponseFormSubsection';

interface UnsavedChanges {
	[key: string]: boolean;
}

function SMAResponseForm(): JSX.Element {
	const { stopListening } = useDictationContext();
	const [unsavedChanges, setUnsavedChanges] = useState<UnsavedChanges>({});

	const TEXT_FIELDS: DataFormFieldComponent[] = [
		SectionInfoWrittenResponse,
		TextResponse,
		SpecialistResponseMoreInfoCheckbox,
		SectionHeadingClinicalDataCapture,
		SpecialistResponseTypeRadio,
		SpecialistResponseAssessmentGroup,
		SectionHeadingPCPManagement,
		ManagementRecommendation,
		ReferralSpecialty
	];

	const VIDEO_FIELDS: DataFormFieldComponent[] = [RecordVideo];

	const DEFAULT_TEXT_DATA: Partial<SMAResponseFormData> = {
		specialistResponseAssessment: [{ order: 1 }]
	};

	const DEFAULT_VIDEO_DATA: Partial<SMAResponseFormData> = {
		recordVideo: ['']
	};

	const CONDITIONAL_TEXT_FIELDS: DataFormConditionalField[] = [
		{
			fieldName: ManagementRecommendation.FIELD_NAME,
			conditionalFieldName: ReferralSpecialty.FIELD_NAME,
			action: DataFormConditionalFieldAction.SHOW,
			values: [
				SpecialistResponseRecommendation.MANAGE_AND_REFER,
				SpecialistResponseRecommendation.REFER
			]
		}
	];

	const subsectionPropsArray: Omit<SMAResponseFormSubsectionProps, 'onUnsavedChangesChange'>[] = [
		{
			fields: TEXT_FIELDS,
			conditionalFields: CONDITIONAL_TEXT_FIELDS,
			formatSubmitData: formatTextSubmitData,
			dataType: ConsultDataType.TEXT,
			accordionTitle: 'Written Response + Clinical Data',
			hasDraftData: hasDraftTextData,
			defaultFormData: DEFAULT_TEXT_DATA,
			submitControl: SubmitWrittenClinicalButton,
			shouldSaveDraft: true
		},
		{
			fields: VIDEO_FIELDS,
			formatSubmitData: formatVideoSubmitData,
			dataType: ConsultDataType.VIDEO,
			accordionTitle: 'Record Video',
			hasDraftData: hasDraftVideoData,
			defaultFormData: DEFAULT_VIDEO_DATA,
			submitControl: SubmitVideoButton,
			// We made the decision to not save draft data for videos, as the draft videos
			// are not easily able to be retrieved from S3 and displayed to users.  Since
			// the video form only has one field, there is no real reason why someone would
			// leave and come back to finish after recording a video.
			shouldSaveDraft: false
		}
	];

	function hasDraftTextData(draftResponse: SMAResponseFormData): boolean {
		return (
			!!draftResponse?.specialistResponseType ||
			!!draftResponse?.specialistResponseAssessment ||
			!!draftResponse?.managementRecommendation ||
			!!draftResponse?.referralSpecialty ||
			!!draftResponse?.textResponse
		);
	}

	function hasDraftVideoData(draftResponse: SMAResponseFormData): boolean {
		return !!draftResponse?.recordVideo;
	}

	function formatSpecialistResponseCondition(condition: string) {
		return condition
			.trim()
			.split(' ')
			.filter(word => word.length > 0)
			.map(capitalizeFirstLetter)
			.join(' ');
	}

	function formatTextSubmitData(
		smaResponseFormData: Partial<SMAResponseFormData>
	): Partial<NewConversationMessage>[] {
		stopListening();
		const {
			textResponse,
			needsAdditionalInfo,
			specialistResponseAssessment,
			specialistResponseType,
			managementRecommendation,
			referralSpecialty
		} = smaResponseFormData;

		const specialistResponseAssessmentSnake = objectCamelToSnake(
			specialistResponseAssessment
		) as SMAResponseFormData['specialistResponseAssessment'];

		const assessments: SpecialistResponseAssessment[] | undefined =
			specialistResponseAssessmentSnake?.map(assessment => {
				assessment.specialist_response_condition = formatSpecialistResponseCondition(
					assessment.specialist_response_condition as string
				);
				return new SpecialistResponseAssessment({ ...assessment });
			});

		const specialistResponse: SpecialistResponse = new SpecialistResponse({
			needs_additional_info: needsAdditionalInfo,
			response_type: specialistResponseType,
			assessments,
			recommendation: managementRecommendation,
			specialty: referralSpecialty
		});
		return [
			{
				text: textResponse
			},
			{
				specialist_response: specialistResponse
			}
		];
	}

	function formatVideoSubmitData(
		smaResponseFormData: Partial<SMAResponseFormData>
	): Partial<NewConversationMessage>[] {
		const { recordVideo } = smaResponseFormData;
		return [
			{
				media_keys: recordVideo
			}
		];
	}

	function onUnsavedChangesChange({
		dataType,
		hasUnsavedChanges
	}: OnUnsavedChangesChangeProps): void {
		if (unsavedChanges[dataType] !== hasUnsavedChanges) {
			setUnsavedChanges({ ...unsavedChanges, [dataType]: hasUnsavedChanges });
		}
	}

	const shouldBlockNavigation = useCallback((): boolean => {
		if (
			unsavedChanges[`${ConsultDataType.VIDEO}`] ||
			unsavedChanges[`${ConsultDataType.TEXT}`]
		) {
			return true;
		}
		return false;
	}, [unsavedChanges]);

	const onBeforeUnloadHandler = useCallback(
		(e: BeforeUnloadEvent) => {
			if (shouldBlockNavigation()) {
				const message = Strings.Sentences.SMA_RESPONSE_UNSAVED_CHANGES_WARNING;
				e.returnValue = message;
				e.preventDefault();
				return message;
			} else {
				return;
			}
		},
		[shouldBlockNavigation]
	);

	React.useEffect(() => {
		window.addEventListener('beforeunload', onBeforeUnloadHandler);
		return () => {
			window.removeEventListener('beforeunload', onBeforeUnloadHandler);
		};
	}, [onBeforeUnloadHandler]);

	return (
		<React.Fragment>
			<Prompt
				when={shouldBlockNavigation()}
				message={Strings.Sentences.SMA_RESPONSE_UNSAVED_CHANGES_WARNING}
			/>
			<SectionHeadingDocumentConsultResponse
				data={{}}
				editableFields={[]}
				errors={{ fields: [], messages: [] }}
				onChange={noop}></SectionHeadingDocumentConsultResponse>
			<br />
			{subsectionPropsArray.map(subsectionProps => {
				return (
					<SMAResponseFormSubsection
						key={subsectionProps.dataType}
						onUnsavedChangesChange={onUnsavedChangesChange}
						{...subsectionProps}
					/>
				);
			})}
		</React.Fragment>
	);
}

export default withRouter(SMAResponseForm);
