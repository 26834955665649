import * as React from 'react';
import RecorderFallback from './RecorderFallback';
import Launcher from './Launcher';
import { Paragraph } from 'display';
import { RecordingOption } from './types';

interface RecorderFallbackLauncherProps {
	onRecordingFallbackVideo: (video: Blob, recordingOption: RecordingOption) => void;
}

const FALLBACK_TEXT: JSX.Element = (
	<Paragraph size={Paragraph.Size.T4}>
		Click <strong>&quot;Launch my camera&quot;</strong> to open your camera and microphone in
		order for you to record your message.
	</Paragraph>
);

function RecorderFallbackLauncher({
	onRecordingFallbackVideo
}: RecorderFallbackLauncherProps): JSX.Element {
	const actions = [<RecorderFallback key="launch" onVideoSelect={onRecordingFallbackVideo} />];

	return <Launcher actions={actions} content={FALLBACK_TEXT} />;
}

export default RecorderFallbackLauncher;
