import * as React from 'react';
import { Divider as SemanticDivider, DividerProps } from 'semantic-ui-react';
import DividerWithText from 'display/SemanticUI/shims/DividerWithText';

type ShimProps = DividerProps;

function Divider(props: ShimProps): JSX.Element {
	return <SemanticDivider {...props} />;
}

Divider.Text = DividerWithText;

export default Divider;
