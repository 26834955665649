import { SemanticCOLORS } from 'display';

const white = '#ffffff';
const greyDark = '#2D2D2D';
const grey = '#4A4A4A';
const greyLight = '#767676';

const sitkaPrimaryLight = '#5FC095';
const sitkaPrimaryLighter = '#C9EADC';
const sitkaPrimaryLightest = '#F4FBF8';

const sitkaOrangeDark = '#9C4B1B';
const sitkaOrangeLight = '#E99160';
const sitkaOrangeLighter = '#F6D9CB';
const sitkaOrangeLightest = '#FEF8F4';

const sitkaLightOrangeBackground = '#FCEEE5';

interface Colors {
	grey: string;
	greyDark: string;
	greyLight: string;
	white: string;

	// Hue scales
	primary: HueScale;
	primaryAlt: HueScale;
	problem: HueScale;
	warn: HueScale;
	new: HueScale;
	info: HueScale;
	neutral: HueScale;

	// Deprecated, remove when references are updated
	primaryLight: string;
	primaryLighter: string;
	primaryLightest: string;
	primaryAltDark: string;
	primaryAltLight: string;
	primaryAltLighter: string;
	primaryAltLightest: string;
	sitkaLightOrangeBackground: string;
}

export enum HueVariations {
	PRIMARY = 'primary',
	PRIMARY_ALT = 'primaryAlt',
	PROBLEM = 'problem',
	WARN = 'warn',
	NEW = 'new',
	INFO = 'info',
	NEUTRAL = 'neutral'
}

interface Hues {
	25: string;
	50: string;
	100: string;
	200: string;
	300: string;
	400: string;
	500: string;
	600: string;
	700: string;
	800: string;
	900: string;
}

interface HueScale extends Hues {
	base: string;
}

const blueHues: Hues = {
	25: '#F9FCFF',
	50: '#DCE6FD',
	100: '#DCE6FD',
	200: '#C5D9F9',
	300: '#A1C3F4',
	400: '#73A8ED',
	500: '#317BE0',
	600: '#1756D5',
	700: '#0636C5',
	800: '#00138E',
	900: '#000968'
};

const redHues: Hues = {
	25: '#FDF6F6',
	50: '#FAE4E4',
	100: '#F3BABA',
	200: '#EB8D8D',
	300: '#E35F5F',
	400: '#DD3C3C',
	500: '#D71A1A',
	600: '#D31717',
	700: '#CD1313',
	800: '#C70F0F',
	900: '#BE0808'
};

const orangeHues: Hues = {
	25: '#fcf1e9',
	50: '#FCEEE5',
	100: '#F8D4BF',
	200: '#F3B794',
	300: '#EE9A69',
	400: '#EA8448',
	500: '#E66E28',
	600: '#E36624',
	700: '#DF5B1E',
	800: '#DB5118',
	900: '#D53F0F'
};

const greyHues: Hues = {
	25: '#FBFBFB',
	50: '#E9EAEA',
	100: '#D8D9DA',
	200: '#C7C8C9',
	300: '#B6B7B9',
	400: '#A4A6A8',
	500: '#939598',
	600: '#7B7D80',
	700: '#646567',
	800: '#4C4D4F',
	900: '#353637'
};

const neutralHues: Hues = {
	25: '#F7F7F7',
	50: '#D5D5D5',
	100: '#B3B3B3',
	200: '#929292',
	300: '#707070',
	400: '#4F4F4F',
	500: '#2D2D2D',
	600: '#262626',
	700: '#1F1F1F',
	800: '#171717',
	900: '#101010'
};

const BASE_HUE = 500;

function makeHueScale(hues: Hues): HueScale {
	return {
		...hues,
		base: hues[BASE_HUE.toString()]
	};
}

export const colors: Colors = {
	grey,
	greyDark,
	greyLight,
	white,

	// Hue scales
	[HueVariations.PRIMARY]: makeHueScale(blueHues),
	[HueVariations.PRIMARY_ALT]: makeHueScale(orangeHues),
	[HueVariations.PROBLEM]: makeHueScale(redHues),
	[HueVariations.WARN]: makeHueScale(orangeHues),
	[HueVariations.NEW]: makeHueScale(blueHues),
	[HueVariations.INFO]: makeHueScale(greyHues),
	[HueVariations.NEUTRAL]: makeHueScale(neutralHues),

	// Deprecated, remove when references are updated
	primaryLight: sitkaPrimaryLight,
	primaryLighter: sitkaPrimaryLighter,
	primaryLightest: sitkaPrimaryLightest,
	primaryAltDark: sitkaOrangeDark,
	primaryAltLight: sitkaOrangeLight,
	primaryAltLighter: sitkaOrangeLighter,
	primaryAltLightest: sitkaOrangeLightest,
	sitkaLightOrangeBackground
};

// Semantic components have a `color` prop which takes literal
// color names. These are mapped to our brand colors
// in src/semantic/src/themes/sitka/globals/site.variables.
//

interface SemanticColorStrings {
	PRIMARY: SemanticCOLORS;
	PRIMARY_ALT: SemanticCOLORS;
	SECONDARY: SemanticCOLORS;
	RED: SemanticCOLORS;
	WHITE: 'white';
	GREEN: SemanticCOLORS;
}

export const SEMANTIC_COLOR_STRINGS: SemanticColorStrings = {
	PRIMARY: 'blue',
	PRIMARY_ALT: 'orange',
	RED: 'red',
	SECONDARY: 'grey',
	WHITE: 'white',
	GREEN: 'green'
};

export function hexToRgba(hex: string, opacity: number): string {
	const sanitizedHex = hex.replace('#', '');

	if (sanitizedHex.length !== 6) {
		throw new Error(`Hex ${hex} must contain 6 characters.`);
	}

	if (opacity > 1) {
		throw new Error(`Opacity must be a value between 0 and 1`);
	}

	const rgb = [];

	rgb.push(parseInt(sanitizedHex.substring(0, 2), 16)); // R
	rgb.push(parseInt(sanitizedHex.substring(2, 4), 16)); // G
	rgb.push(parseInt(sanitizedHex.substring(4, 6), 16)); // B

	return `rgba(${rgb.join(',')},${opacity})`;
}
