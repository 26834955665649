import { Button, ButtonType } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import LinkButton, { LinkButtonProps } from './LinkButton';
import Strings from 'lib/strings';

function LinkToCreateCaseButton(): JSX.Element {
	const buttonProps: LinkButtonProps = {
		buttonType: ButtonType.PRIMARY,
		link: RouteUrls.buildUrl(RouteUrls.Key.CASE_CREATE),
		text: Strings.Buttons.NEW_PATIENT_MESSAGE,
		icon: 'plus',
		size: Button.Size.TINY,
		classNames: 'createCaseButton'
	};

	return <LinkButton {...buttonProps} />;
}

export default LinkToCreateCaseButton;
