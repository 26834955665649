import { GetCasesRequestCamel, GetCasesResponseCamel } from 'thriftgen/thriftCamelTypes';

import { getCases } from 'api/common';
import { objectSnakeToCamel } from 'lib/objectConverter';
import useQuery from '../useQuery';

import { transformCasesToComposite } from './helpers';
import { CasesQuery } from './types';

function useCasesQuery(args: GetCasesRequestCamel): CasesQuery {
	const key = ['cases', args];
	const { data, error, mutate, isValidating } = useQuery<GetCasesResponseCamel>(
		key,
		(_: string, params: GetCasesRequestCamel) => getCases(params)
	);

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		cases: data ? transformCasesToComposite(data) : undefined,
		error,
		revalidate,
		pageMarkers: data ? objectSnakeToCamel(data.pageMarkers) : undefined,
		isValidating
	};
}

export default useCasesQuery;
