import * as React from 'react';
import { UserCamel } from 'thriftgen/thriftCamelTypes';

import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';
import { FIELD_NAME, FIELD_LABEL } from './helpers';

type DataType = UserCamel['medicareBeneficiaryIdentifier'] | null;
type DisplayPatientMedicareBeneficiaryIdComponent = DataFormFieldComponent<string, DataType>;
type DisplayPatientMedicareBeneficiaryIdComponentProps = DataFormFieldComponentProps<DataType>;

function MBIFormatter(MBI: string | null): string {
	return MBI ? MBI.replace(/(\d{4})(\d{3})(\d{4})/, '$1-$2-$3') : '';
}

const DisplayPatientMedicareBeneficiaryId: DisplayPatientMedicareBeneficiaryIdComponent = ({
	data
}: DisplayPatientMedicareBeneficiaryIdComponentProps): JSX.Element => {
	return (
		<ReadOnlyField label={FIELD_LABEL}>
			{MBIFormatter(data[DisplayPatientMedicareBeneficiaryId.FIELD_NAME])}
		</ReadOnlyField>
	);
};

DisplayPatientMedicareBeneficiaryId.FIELD_NAME = FIELD_NAME;
DisplayPatientMedicareBeneficiaryId.validate = alwaysValid;

export default DisplayPatientMedicareBeneficiaryId;
