import { DataFormFieldComponent } from '../../DataFormTypes';
import buildTextFieldComponent from '../base/buildTextField';

const FIELD_NAME = 'externalUserId';
const FIELD_LABEL = 'External user ID';

const ExternalUserId: DataFormFieldComponent<typeof FIELD_NAME> = buildTextFieldComponent<
	typeof FIELD_NAME
>({
	fieldName: FIELD_NAME,
	icon: 'id card',
	label: FIELD_LABEL
});

export default ExternalUserId;
