import * as React from 'react';
import PropTypes from 'prop-types';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

function PhoneNumber({ number, country, format }) {
	let href;
	let value;
	let parsed = parsePhoneNumberFromString(number, country);

	if (parsed === undefined) {
		href = `tel:${number}`;
		value = number;
	} else {
		href = parsed.getURI();
		value = parsed.format(format.toUpperCase());
	}

	return <a href={href}>{value}</a>;
}

PhoneNumber.defaultProps = {
	country: 'US',
	format: 'national'
};

PhoneNumber.propTypes = {
	country: PropTypes.string,
	format: PropTypes.oneOf(['national', 'international']),
	number: PropTypes.string.isRequired
};

export default PhoneNumber;
