import * as React from 'react';
import { Button, ButtonProps } from 'display';
import { SEMANTIC_COLOR_STRINGS } from 'styles';
import Strings from 'lib/strings';

interface RecorderMinimizeMaximizeButtonProps {
	minimized: boolean;
	onClick: ButtonProps['onClick'];
}

function RecorderMinimizeMaximizeButton({
	onClick,
	minimized
}: RecorderMinimizeMaximizeButtonProps): JSX.Element {
	if (minimized) {
		return (
			<Button.Icon
				backgroundColor={SEMANTIC_COLOR_STRINGS.WHITE}
				color={SEMANTIC_COLOR_STRINGS.PRIMARY}
				data-testid="recorderMinimizeMaximizeButton"
				icon="expand"
				onClick={onClick}
				title="Maximize"
			/>
		);
	}

	return (
		<Button.Secondary data-testid="recorderMinimizeMaximizeButton" onClick={onClick}>
			{Strings.Buttons.MINIMIZE}
		</Button.Secondary>
	);
}

export default RecorderMinimizeMaximizeButton;
