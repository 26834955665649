import * as React from 'react';
import { css, cx } from 'emotion';
import { getFullDateTime } from 'lib/date';
import { DocumentDataAsset } from 'lib/documentAssets';

import documentIcon from 'assets/images/document.svg';
import processingIcon from 'assets/images/processing.svg';

import { AssetListItemTitleAreaProps, AssetListItemThumbnailData } from './types';

import AssetListItem from './AssetListItem';
import { TruncatedFilename } from 'display';

interface DocumentAssetItemProps {
	action?: React.ReactNode;
	asset: DocumentAssetMinimal;
	subtitle?: AssetListItemTitleAreaProps['subtitle'];
	onClick?: (id: string) => void;
}

const styles = {
	processingText: css`
		font-style: italic;
	`,
	redacted: css`
		text-decoration: line-through;
	`
};

type DocumentAssetMinimal = Pick<
	DocumentDataAsset,
	| 'id'
	| 'description'
	| 'reviewUrl'
	| 'isProcessing'
	| 'thumbnailImage'
	| 'isNew'
	| 'created_at'
	| 'isReady'
	| 'redacted_reason'
	| 'redacted_at'
>;
function DocumentAssetItem({
	action,
	asset,
	subtitle,
	onClick
}: DocumentAssetItemProps): JSX.Element {
	const { redacted_reason: redactedReason } = asset;

	function getAssetTitle(): AssetListItemTitleAreaProps['title'] {
		return {
			content: <TruncatedFilename filename={asset.description || ''} />,
			onClick: redactedReason ? undefined : onClick,
			style: cx({
				[styles.redacted]: !!redactedReason
			})
		};
	}

	function getDocumentAssetThumbnail(): AssetListItemThumbnailData {
		return {
			icon: asset.isProcessing ? processingIcon : documentIcon,
			image: asset.thumbnailImage,
			isNew: asset.isNew,
			isProcessing: asset.isProcessing
		};
	}

	function getAssetSubtitle(): AssetListItemTitleAreaProps['subtitle'] {
		const { isProcessing, isReady, created_at: createdAt, redacted_at: redacetdAt } = asset;
		let text;
		if (isProcessing) {
			text = 'Processing';
		} else if (isReady) {
			text = `Uploaded ${getFullDateTime(createdAt ?? Math.floor(Date.now() / 1000))}`;
		} else if (redactedReason) {
			text = `Redacted ${getFullDateTime(redacetdAt ?? Math.floor(Date.now() / 1000))}`;
		} else {
			text = 'Failed to process';
		}

		return {
			text,
			style: cx({
				[styles.processingText]: isProcessing,
				[styles.redacted]: !!redactedReason
			})
		};
	}

	return (
		<AssetListItem
			id={asset.id}
			action={action}
			thumbnail={getDocumentAssetThumbnail()}
			title={getAssetTitle()}
			subtitle={subtitle || getAssetSubtitle()}
		/>
	);
}

export default DocumentAssetItem;
export { DocumentAssetItemProps, DocumentAssetMinimal };
