import * as React from 'react';

import { css, cx } from 'emotion';
import { Label } from 'display';

interface TileLabelProps {
	children: React.ReactNode;
	className: string;
}

const styles = {
	label: css`
		border-radius: 2em !important;
		margin-bottom: -1.25em !important;
		margin-right: 2em !important;
	`
};

function TileLabel({ children, className }: TileLabelProps): JSX.Element {
	return (
		<Label attached="bottom right" className={cx(styles.label, className)}>
			{children}
		</Label>
	);
}

export default TileLabel;
