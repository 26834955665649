import * as React from 'react';

import { RecordingStatus, RecordingMouseEvents } from 'components/Recorder/types';

import { PauseIconButton, PlayIconButton, StopIconButton } from 'components/Buttons';

type RecordingControlsMinimizedProps = Omit<
	RecordingMouseEvents,
	'onMinimize' | 'onOpenSettings'
> & {
	status: RecordingStatus;
};

function RecordingControlsMinimized({
	status,
	onPause,
	onResume,
	onStart,
	onStop
}: RecordingControlsMinimizedProps): JSX.Element {
	switch (status) {
		case RecordingStatus.RECORDING:
			return (
				<React.Fragment>
					<StopIconButton onClick={onStop} />
					<PauseIconButton onClick={onPause} />
				</React.Fragment>
			);
		case RecordingStatus.PAUSED:
			return (
				<React.Fragment>
					<StopIconButton onClick={onStop} />
					<PlayIconButton onClick={onResume} />
				</React.Fragment>
			);
		case RecordingStatus.IDLE:
			return <PlayIconButton onClick={onStart} />;
	}
}

export default RecordingControlsMinimized;
