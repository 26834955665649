import * as React from 'react';

import { getValue, translatePatientTreatmentState } from './helpers';
import { DisplayPatientTreatmentStateFieldProps, FIELD_NAME } from './types';
import { Form, Icon } from 'display';

function DisplayPatientTreatmentStateField({
	data
}: DisplayPatientTreatmentStateFieldProps): JSX.Element {
	const value = getValue(data);

	return (
		<Form.Field data-testid="DisplayPatientTreatmentStateField">
			<Icon name="point" />
			{` `}
			{translatePatientTreatmentState(value) || 'Not provided'}
		</Form.Field>
	);
}

DisplayPatientTreatmentStateField.FIELD_NAME = FIELD_NAME;
DisplayPatientTreatmentStateField.validate = () => null;

export { DisplayPatientTreatmentStateField };
