import Loadable from 'react-loadable';
import Loading from 'components/Routing/Loading';

export default function RouteLoader(options) {
	return Loadable({
		loading: Loading,
		delay: 300,
		timeout: 5000,
		...options
	});
}
