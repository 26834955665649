import { createSelector } from 'reselect';
import { AppState } from 'store/types';
import { User } from 'thriftgen/api_types';
import { AuthState } from './auth.types';

// Individual stores are created lazily for code-splitting
// so we're explicit about the type here since the compiler
// cannot infer when a store is loaded dynamically.
const selectAuth = (state: AppState): AuthState => state.auth as AuthState;
const selectAuthUser = (state: AppState): User => {
	const auth = selectAuth(state);

	if (auth.user === null) {
		throw new Error('Actor is not authenticated.');
	}

	return auth.user;
};

const selectAuthUserId = createSelector(selectAuth, auth => auth.userId as string);

const selectHasAuth = createSelector(selectAuthUser, user => {
	return user.has_auth;
});

const selectHasAuthUser = (state: AppState): boolean => {
	try {
		selectAuthUser(state);
		return true;
	} catch {
		return false;
	}
};

const selectLoggedIn = createSelector(selectAuth, auth => auth.loggedIn);

const selectRemainingAttempts = createSelector(selectAuth, auth => auth.remainingAttempts);

export {
	selectAuthUser,
	selectAuthUserId,
	selectHasAuth,
	selectHasAuthUser,
	selectLoggedIn,
	selectRemainingAttempts
};
