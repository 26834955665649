import UserFromConsultContext from 'components/admin/User/UserFromConsultContext';
import { ConsultInitiatorSelect } from 'components/UserSelect';
import { UserSelectProps } from 'components/UserSelect/types';
import { Form } from 'display';
import * as React from 'react';
import { DataFormFieldComponentProps } from '../DataFormTypes';

interface RequestorProps {
	data: DataFormFieldComponentProps['data'];
	editableFields: DataFormFieldComponentProps['editableFields'];
	onChange: UserSelectProps['onChange'];
}

const FIELD_NAME = 'requestorId';

function canEdit(editableFields: RequestorProps['editableFields']): boolean {
	return editableFields.includes(FIELD_NAME);
}

function getValue(data: RequestorProps['data']): string {
	return data[FIELD_NAME];
}

function validate(): null {
	return null;
}

function Requestor({ data, editableFields, onChange }: RequestorProps): JSX.Element {
	const value = getValue(data);

	if (canEdit(editableFields)) {
		return (
			<ConsultInitiatorSelect
				consultId={data.consultId}
				key={FIELD_NAME}
				name={FIELD_NAME}
				onChange={onChange}
				value={value}
			/>
		);
	}

	let user = <span>Unassigned</span>;

	if (value) {
		user = <UserFromConsultContext userId={value} />;
	}

	return <Form.Field>{user}</Form.Field>;
}

Requestor.FIELD_NAME = FIELD_NAME;
Requestor.validate = validate;

export default Requestor;
