import { translateThriftEnum } from 'lib/i18n';
import { State, STATE } from 'thriftgen/api_types';
import { DisplayPatientTreatmentStateFieldProps, FIELD_NAME } from './types';

function getValue(data: DisplayPatientTreatmentStateFieldProps['data']): State {
	return data[FIELD_NAME];
}

function translatePatientTreatmentState(patient_treatment_state: State | null): string {
	if (!patient_treatment_state) {
		return 'Unspecified';
	}

	return translateThriftEnum(patient_treatment_state, STATE);
}

export { getValue, translatePatientTreatmentState };
