import * as React from 'react';
import { css, cx } from 'emotion';
import { camelCase } from 'lodash';

import { Text, Button } from 'display';
import Link from 'components/Link';
import { AssetListItemTitleAreaProps } from './types';
import AssetListItemMeta from './AssetListItemMeta';
import { colors } from 'styles';

const styles = {
	link: css`
		text-decoration-line: underline;
		color: ${colors.primary.base};
	`,
	titleContainer: css`
		display: flex;
		flex: auto;
		flex-direction: column;
		overflow: hidden;
	`
};

type Subtitle = AssetListItemTitleAreaProps['subtitle'];
type Title = AssetListItemTitleAreaProps['title'];

function renderTitleContent(content: JSX.Element, style?: string): JSX.Element {
	return (
		<Text size={Text.Size.T4} className={style}>
			{content}
		</Text>
	);
}

function renderTitle(title: Title, id?: string): JSX.Element {
	const { content, url, onClick } = title;
	if (onClick && id) {
		return (
			<Button.Text
				onClick={() => {
					onClick(id);
				}}
				className={styles.link}>
				{renderTitleContent(
					content,
					cx(styles.link, title.style) + ` ${camelCase(content.props.children)}`
				)}
			</Button.Text>
		);
	}

	if (url) {
		return (
			<Link to={url}>
				{renderTitleContent(
					content,
					cx(styles.link, title.style) + ` ${camelCase(content.props.children)}`
				)}
			</Link>
		);
	}

	return renderTitleContent(content, title.style);
}

function renderSubtitle(subtitle: Subtitle): JSX.Element {
	return (
		<Text size={Text.Size.T5} className={subtitle.style}>
			{subtitle.text}
		</Text>
	);
}

function AssetListItemTitleArea({
	id,
	meta,
	title,
	subtitle
}: AssetListItemTitleAreaProps): JSX.Element {
	return (
		<div className={cx(styles.titleContainer)}>
			{renderTitle(title, id)}
			{renderSubtitle(subtitle)}
			{meta && meta.map(AssetListItemMeta)}
		</div>
	);
}

export default AssetListItemTitleArea;
