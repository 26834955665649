import * as React from 'react';

import { FilterField, FilterValue, OnChange, OnRemove } from './types';
import { isEmptyQuery } from './helpers';

import Filter from './Filter';
import { Form, Segment } from 'display';

interface FilterMenuProps {
	filters: Array<Omit<FilterField, 'onChange'>>;
	onRemove: OnRemove;
	onChange: OnChange;
	loading?: boolean;
}

function FilterMenu({ filters, onRemove, onChange, loading }: FilterMenuProps): JSX.Element {
	function renderFilter({ onChange: _onChange, ...fieldRest }: FilterField): React.ReactNode {
		function changeFilter(changeValue: FilterValue): void {
			if (isEmptyQuery(fieldRest.type, changeValue)) {
				onRemove(fieldRest.attribute);
			} else if (changeValue !== undefined) {
				onChange(fieldRest.attribute, changeValue);
			}
		}

		return (
			<Filter
				key={fieldRest.attribute}
				onChange={changeFilter}
				loading={loading}
				{...fieldRest}
			/>
		);
	}

	return (
		<Segment basic={true}>
			<Form type="">{filters.map(renderFilter)}</Form>
		</Segment>
	);
}

export default FilterMenu;
export { FilterMenuProps };
