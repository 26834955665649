import DeleteUserTimeframeRestrictionModal from 'components/Modals/DeleteUserTimeframeRestrictionModal';
import { useUserContext } from 'contexts';
import { SemanticICONS } from 'display';
import { useLazyAsyncCall } from 'hooks';
import { objectSnakeToCamel } from 'lib/objectConverter';
import { SitkaLogger } from 'lib/sitkaLogger';
import React, { useState } from 'react';
import { TimeframeRestrictionCamel } from 'thriftgen/thriftCamelTypes';

interface UserTimeframeRestrictionDeleteActionProps {
	data: TimeframeRestriction;
	modalPortalId: string;
	children: React.ReactNode;
}

function UserTimeframeRestrictionDeleteAction({
	data,
	modalPortalId,
	children
}: UserTimeframeRestrictionDeleteActionProps): JSX.Element {
	const [modalOpen, setModalOpen] = useState<boolean>(false);
	const openModal = () => {
		setModalOpen(true);
	};
	const closeModal = () => setModalOpen(false);

	const { user, updateUser } = useUserContext();
	const { dispatch: sendUpdateUser, loading, error } = useLazyAsyncCall(updateUser);

	async function onConfirm(): Promise<void> {
		const newTimeframeRestrictions = user.specialist_data.timeframe_restrictions
			.filter(
				({ start_date, end_date }) =>
					data.start_date !== start_date && data.end_date !== end_date
			)
			.map(
				timeframeRestriction =>
					objectSnakeToCamel(timeframeRestriction) as TimeframeRestrictionCamel
			);

		try {
			await sendUpdateUser({
				specialistData: { timeframeRestrictions: newTimeframeRestrictions }
			}).promise();
			closeModal();
		} catch (e) {
			SitkaLogger.logMessage(e);
		}
	}

	function onCancel(): void {
		closeModal();
	}

	return (
		<React.Fragment>
			<DeleteUserTimeframeRestrictionModal
				modalPortalId={modalPortalId}
				open={modalOpen}
				onConfirm={onConfirm}
				onCancel={onCancel}
				loading={loading}
				error={error}
				user={user}
				timeframeRestriction={data}
			/>
			<div onClick={openModal}>{children}</div>
		</React.Fragment>
	);
}

UserTimeframeRestrictionDeleteAction.ICON = 'trash' as SemanticICONS;
UserTimeframeRestrictionDeleteAction.TITLE = 'Delete';

export default UserTimeframeRestrictionDeleteAction;
