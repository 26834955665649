import * as React from 'react';
import { Paragraph, Divider } from 'display';
import { InstallChromeButton } from 'components/Buttons';

function InstallBrowser(): JSX.Element {
	return (
		<React.Fragment>
			<Paragraph>
				Screen recording is not supported in your current browser. Download or upgrade to a
				supported browser.
			</Paragraph>
			<InstallChromeButton />
			<Divider section={true} />
			<Paragraph>
				When you&apos;ve installed a supported browser, we&apos;ll need permission to record
				your screen during filming.
			</Paragraph>
		</React.Fragment>
	);
}

export default InstallBrowser;
