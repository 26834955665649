import * as React from 'react';

import { AppState } from 'store/types';

enum ConfigurablePageParts {
	FOOTER = 'footer',
	MENU = 'menu',
	NAVBAR = 'navbar',
	PAGE_STYLES = 'page_styles'
}

type RouteRestriction = (store: AppState) => boolean;

interface PageDisplayOptions {
	whitelabelLogo?: boolean;
	whitelabelFooter?: boolean;
	excludeParts?: ConfigurablePageParts[];
}

interface RouteProperties {
	canSafelyReload?: boolean;
	enableTelemetry: boolean;
	exact?: boolean;
	pageOptions?: PageDisplayOptions;
	path: string;
	restrictedTo?: RouteRestriction;
	isDisabled?: () => boolean;
}

interface RouteDefinition extends RouteProperties {
	routes?: RouteDefinitionMap;
	template: () => Promise<any>;
}

interface RouteComponentProperties extends Omit<RouteProperties, 'restrictedTo'> {
	shouldRestrict: () => boolean;
}

interface RouteComponent extends RouteComponentProperties {
	routes: RouteComponent[];
	template: React.ComponentType<any>;
}

interface RouteRendererProps extends Partial<RouteComponentProperties> {
	routes?: React.ReactNode[];
	template: React.ComponentType<any>;
}

type RouteRenderer = React.ComponentType<RouteRendererProps>;

type RouteDefinitionMap = {
	[key in RouteKey]?: RouteDefinition;
};

interface Registry {
	renderer: RouteRenderer;
	routes: RouteComponent[];
}

interface RegistryConfig {
	renderer: RouteRenderer;
	routes: RouteDefinitionMap;
}

enum RouteKey {
	ADD_TO_HOMESCREEN = 'add_to_homescreen',
	CASE = 'case',
	CASE_ADD_DATA = 'case_add_data',
	CASE_CREATE = 'case_create',
	CASE_DATA_VIEWER = 'case_data_viewer',
	CASE_DETAIL = 'case_detail',
	CASE_EDIT_PATIENT = 'case_edit_patient',
	CASE_REQUEST_CONSULT = 'case_request_consult',
	CASE_PATIENT_CONVERSATION = 'case_patient_conversation',
	CONSULT = 'consult',
	CONSULT_ADD_DATA = 'consult_add_data',
	CONSULT_CLINICAL_REVIEW = 'consult_clinical_review',
	CONSULT_DATA_VIEWER = 'consult_data_viewer',
	CONSULT_DETAIL = 'consult_detail',
	CONSULT_EDIT_PATIENT = 'consult_edit_patient',
	CONSULT_EDIT_REQUESTOR = 'consult_edit_requestor',
	CONSULT_EDIT_SPECIALIST = 'consult_edit_specialist',
	CONSULT_EDIT_SPECIALTY = 'consult_edit_specialty',
	CONSULT_REQUEST = 'consult_request',
	CONSULT_REQUEST_DRAFT = 'consult_request_draft',
	CONSULT_SHARED_MESSAGES = 'consult_shared_messages',
	CONSULT_SMA_RESPONSE = 'consult_sma_response',
	CONSULT_SUMMARY_DASHBOARD = 'consult_summary_dashboard',
	DASHBOARD_PCP = 'dashboard_pcp',
	DASHBOARD_SMA = 'dashboard_sma',
	DASHBOARD_CLINICAL = 'dashboard_clinical',
	HELP = 'help',
	HOME = 'home',
	INBOUND = 'inbound',
	LOGIN = 'login',
	LOGOUT = 'logout',
	MY_ACCOUNT = 'my_account',
	MY_ACCOUNT_EDIT = 'my_account_edit',
	MY_CASES = 'my_cases',
	NO_PARTNERS_LANDING_PAGE = 'no_partners_landing_page',
	OKTA_CALLBACK = 'okta_callback',
	OKTA_LOGIN = 'okta_login',
	PARTNER = 'partner',
	PARTNER_DETAIL = 'partner_detail',
	PARTNER_EDIT = 'partner_edit',
	PARTNER_NEW = 'partners_new',
	PARTNERS = 'partners',
	PATIENT_DASHBOARD = 'patient_dashboard',
	PATIENT_SESSION_EXPIRATION = 'patient_session_expiration',
	SSO_REDIRECT_HANDLER = 'sso_redirect_handler',
	SSO_ATHENA_CONSULT_REQUEST = 'sso_athena_consult_request',
	USER = 'user',
	USER_DETAIL = 'user_detail',
	USER_EDIT = 'user_edit',
	USER_NEW = 'user_new',
	USER_LICENSE_NEW = 'user_license_new',
	USER_LICENSE_EDIT = 'user_license_edit',
	USER_TIMEFRAME_RESTRICTION_NEW = 'user_timeframe_restriction_new',
	USER_TIMEFRAME_RESTRICTION_EDIT = 'user_timeframe_restriction_edit',
	USERS = 'users',
	VIDEO_CONFERENCE = 'video_conference',
	VIDEO_CONFERENCE_NEW = 'video_conference_new',
	VIDEO_VISIT = 'video_visit',
	VIDEO_VISIT_MINIMAL = 'video_visit_minimal',
	VIDEO_VISIT_NEW = 'video_visit_new'
}

export {
	ConfigurablePageParts,
	PageDisplayOptions,
	Registry,
	RegistryConfig,
	RouteComponent,
	RouteDefinition,
	RouteDefinitionMap,
	RouteKey,
	RouteRendererProps,
	RouteRestriction
};
