import * as React from 'react';
import { css } from 'emotion';

const styles = {
	mobile: {
		labelledHalfWidthContainer: css`
			display: inline-block;
			width: 50%;
			margin-bottom: 1em;

			> div:first-child {
				font-weight: bold;
			}
		`
	}
};

export function buildHalfWidthMobileColumn(
	label: string,
	value: string | JSX.Element
): JSX.Element {
	return (
		<div className={styles.mobile.labelledHalfWidthContainer}>
			<div>{label}</div>
			<div>{value}</div>
		</div>
	);
}
