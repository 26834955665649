import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { ManageConversationRequest } from 'thriftgen/api_types';
import {
	ManageConversationRequestCamel,
	ManageConversationResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function manageConversation(
	args: ManageConversationRequestCamel
): Promise<ManageConversationResponseCamel> {
	const client = getCommonClient();
	const request = new ManageConversationRequest(objectCamelToSnake(args));

	const response = await client.manage_conversation(request);
	return objectSnakeToCamel(response);
}

export default manageConversation;
