import * as React from 'react';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const FIELD_NAME = 'isReportable';
const LABEL = 'Reportable';

const ReportableElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

function Reportable(props: DataFormFieldComponentProps): JSX.Element {
	return <ReportableElement key={FIELD_NAME} {...props} />;
}

Reportable.FIELD_NAME = FIELD_NAME;
Reportable.validate = () => null;

export default Reportable;
