import * as React from 'react';
import { connect } from 'react-redux';
import { PRIVACY_PAGE, TERMS_AND_CONDITIONS } from 'lib/marketingLinks';

import { AppState } from 'store/types';
import { selectLoggedIn } from 'store/auth';

import { css, cx } from 'emotion';
import Link from 'components/Link';
import { colors } from 'styles';
import { Anchor, Container } from 'display';

import { HipaaCompliant, SitkaLogo } from 'components/Svgs';

const styles = {
	footer: {
		all: css`
			background-color: ${colors.primary['25']};
			color: ${colors.primary['700']};
			width: 100%;
		`,
		condensed: css`
			padding: 1em 0;

			& img,
			& svg {
				height: 1em;
				width: auto;
			}

			& a {
				margin-top: -2px;
				height: 1em;
			}
		`,
		full: css`
			padding: 2em 0;

			& img,
			& svg {
				width: 70px;
				height: auto;
				margin-top: 2.5em;
			}
		`
	},
	logos: css`
		align-items: center;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	`,
	nav: css`
		& a {
			display: block;
			color: ${colors.primary['700']} !important;
			margin-bottom: 1em;
		}

		& a:last-child {
			margin-bottom: 0;
		}

		& a:hover {
			opacity: 0.8;
		}
	`
};

interface StoreProps {
	showCondensedFooter: boolean;
}

type FooterProps = StoreProps;

function renderNav() {
	return (
		<nav className={styles.nav}>
			<Anchor href={TERMS_AND_CONDITIONS}>Our Terms and Conditions</Anchor>
			<Anchor href={PRIVACY_PAGE}>Our Privacy Policy</Anchor>
		</nav>
	);
}

function Footer({ showCondensedFooter }: FooterProps): JSX.Element {
	const footerStyle = cx(
		styles.footer.all,
		styles.footer[showCondensedFooter ? 'condensed' : 'full']
	);

	return (
		<footer>
			<div data-testid="footer" className={footerStyle}>
				<Container>
					{!showCondensedFooter && renderNav()}
					<div className={styles.logos}>
						<Link to="/">
							<SitkaLogo />
						</Link>
						<HipaaCompliant stroke={colors.primary['700']} fill={colors.primary.base} />
					</div>
				</Container>
			</div>
		</footer>
	);
}

const mapStoreToProps = (store: AppState): StoreProps => {
	return {
		showCondensedFooter: selectLoggedIn(store)
	};
};

export default connect(mapStoreToProps)(Footer);
