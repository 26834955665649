import {
	DataFormFieldComponent,
	DataFormFieldComponentProps
} from 'components/Forms/DataFormTypes';
import { DatePicker, Form } from 'display';
import { getAbbreviatedDate } from 'lib/date';
import * as React from 'react';

type DatePickerFieldProps = DataFormFieldComponentProps<number>;

interface BuildDatePickerFieldProps {
	fieldName: string;
	label: string | JSX.Element;
	validate?: (
		value: DatePickerFieldProps['data']['fieldName'],
		required: boolean
	) => null | string[];
}

function defaultValidate(
	value: DatePickerFieldProps['data']['fieldName'],
	required: boolean
): string[] | null {
	if (required && !value) {
		return ['Please select a date.'];
	}
	return null;
}

function buildDatePickerField({
	fieldName,
	label,
	validate = defaultValidate
}: BuildDatePickerFieldProps): DataFormFieldComponent<string, number> {
	function validateData(
		data: { [key: string]: number },
		required: boolean
	): ReturnType<DataFormFieldComponent<string, number>['validate']> {
		const messages = validate(data[fieldName], required);
		return messages ? { fields: [fieldName], messages } : null;
	}

	function DatePickerField({
		data,
		editableFields,
		errors,
		onChange
	}: DataFormFieldComponentProps<number>): JSX.Element | null {
		const value = data[fieldName];

		const hasError = errors.fields.includes(fieldName);
		const canEdit = editableFields.includes(fieldName);

		function handleChange(timestamp: number) {
			onChange({ target: { value: `${timestamp}` } } as React.ChangeEvent<HTMLInputElement>, {
				name: fieldName,
				value: timestamp
			});
		}

		if (canEdit) {
			return (
				<Form.Field error={hasError}>
					<label htmlFor={fieldName}>{label}</label>
					<DatePicker onChange={handleChange} defaultValue={value} />
				</Form.Field>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<label>{label}</label>
					<div>{getAbbreviatedDate(value)}</div>
				</Form.Field>
			);
		}

		return null;
	}

	DatePickerField.FIELD_NAME = fieldName;
	DatePickerField.validate = validateData;
	DatePickerField.displayName = `DatePickerField-${fieldName}`;

	return DatePickerField;
}

export default buildDatePickerField;
