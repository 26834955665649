import * as React from 'react';
import { createPortal } from 'react-dom';

// You can use this component to render a modal within and have
// React render it in a different DOM node. This DOM node will
// be an element in the document with the provided "id". You may
// want to use this in situations where rendering the modal in its
// immediate DOM structure is not ideal.

function ModalPortal({
	children,
	id
}: {
	children: React.ReactNode;
	id: string;
}): React.ReactPortal {
	const element = React.useRef(document.createElement('div'));

	React.useEffect((): (() => void) => {
		const portalRoot = document.getElementById(id);
		const { current } = element;

		if (portalRoot && current) {
			portalRoot.appendChild(current);
		}

		return (): void => {
			if (portalRoot && current) {
				portalRoot.removeChild(current);
			}
		};
	}, [id]);

	return createPortal(children, element.current);
}

export default ModalPortal;
