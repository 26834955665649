import { InputProps } from 'display';
import * as React from 'react';
import { UserInfo } from 'thriftgen/api_types';
import Credential from './Credential';
import FirstName from './FirstName';
import { buildNameValidator, FullName } from './FullName';
import LastName from './LastName';
import Title from './Title';

const FIELD_NAME = 'userFullName';

interface UserFullNameFieldProps {
	data: {
		firstName: UserInfo['first_name'];
		lastName: UserInfo['last_name'];
		title: UserInfo['title'];
		[key: string]: unknown;
	};
	editableFields: string[];
	errors: {
		fields: string[];
	};
	onChange: InputProps['onChange'];
}

function UserFullName(props: UserFullNameFieldProps): JSX.Element {
	return (
		<FullName
			{...props}
			fieldName={FIELD_NAME}
			FirstName={FirstName}
			LastName={LastName}
			Title={Title}
			Credential={Credential}
		/>
	);
}

UserFullName.FIELD_NAME = FIELD_NAME;
UserFullName.SUB_FIELD_NAMES = [
	FirstName.FIELD_NAME,
	LastName.FIELD_NAME,
	Title.FIELD_NAME,
	Credential.FIELD_NAME
];
UserFullName.validate = buildNameValidator(
	FirstName.FIELD_NAME,
	LastName.FIELD_NAME,
	Credential.FIELD_NAME
);

export default UserFullName;
