import { css, cx } from 'emotion';
import * as React from 'react';
import { Segment as SemanticSegment, SegmentProps } from 'semantic-ui-react';
import SegmentGroup from './SegmentGroup';

const styles = {
	noMargins: css`
		margin: 0 !important;
	`,
	basic: css`
		border: 0 !important;
	`
};

interface OwnProps {
	noMargins?: boolean;
}

type ShimProps = SegmentProps & OwnProps;

function Segment({ className, basic, noMargins, ...rest }: ShimProps): JSX.Element {
	const classNames = [];

	if (noMargins) {
		classNames.push(styles.noMargins);
	}

	if (rest) {
		classNames.push(styles.basic);
	}

	return <SemanticSegment className={cx(...classNames, className)} basic={basic} {...rest} />;
}

Segment.Group = SegmentGroup;

export default Segment;
