import { getCommonClient } from 'lib/ThriftClient';
import { objectSnakeToCamel } from 'lib/objectConverter';
import { GetUploadParamsResponseCamel } from 'thriftgen/thriftCamelTypes';
import { SitkaLogger } from 'lib/sitkaLogger';

async function getUploadParams(): Promise<GetUploadParamsResponseCamel> {
	const client = getCommonClient();
	let response = {};
	try {
		response = await client.get_upload_params();
	} catch (err) {
		SitkaLogger.logMessage(err);
	}
	return objectSnakeToCamel(response);
}

export default getUploadParams;
