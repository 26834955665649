import { translateThriftEnum } from 'lib/i18n';
import * as React from 'react';
import { SEX } from 'thriftgen/api_types';
import { UserCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = UserCamel['sex'] | null;
type DisplaySexComponent = DataFormFieldComponent<string, DataType>;
type DisplaySexComponentProps = DataFormFieldComponentProps<DataType>;

const DisplaySex: DisplaySexComponent = ({ data }: DisplaySexComponentProps): JSX.Element => {
	const sex: UserCamel['sex'] | null = data[DisplaySex.FIELD_NAME];

	return <ReadOnlyField label="Sex">{sex ? translateThriftEnum(sex, SEX) : null}</ReadOnlyField>;
};

DisplaySex.FIELD_NAME = 'sex';
DisplaySex.validate = alwaysValid;

export default DisplaySex;
