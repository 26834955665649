import * as React from 'react';
import { AdapterDayjs, dayjs, MUIDatePicker, LocalizationProvider } from 'display';

interface DatePickerProps {
	className?: string;
	label?: string;
	defaultValue?: number;
	maxDate?: string;
	minDate?: string;
	disableFuture?: boolean;
	disablePast?: boolean;
	onChange: (timestamp: number) => void;
}

function DatePicker({
	className,
	label,
	defaultValue,
	maxDate,
	minDate,
	disableFuture,
	disablePast,
	onChange
}: DatePickerProps): JSX.Element {
	function handleDateChange(value: dayjs.Dayjs) {
		onChange(value?.unix());
	}

	return (
		<LocalizationProvider dateAdapter={AdapterDayjs}>
			<MUIDatePicker
				className={className}
				label={label}
				defaultValue={defaultValue ? dayjs(defaultValue, 'X') : undefined}
				maxDate={maxDate ? dayjs(maxDate) : ''}
				minDate={minDate ? dayjs(minDate) : ''}
				disableFuture={disableFuture}
				disablePast={disablePast}
				onChange={(date: dayjs.Dayjs) => {
					handleDateChange(date);
				}}
				slotProps={{
					popper: {
						sx: { zIndex: 9999 },
						disablePortal: true
					}
				}}></MUIDatePicker>
		</LocalizationProvider>
	);
}

export default DatePicker;
