class MissingSenderError extends Error {
	public message = 'Missing message sender.';
	public name = 'MissingSenderError';
}
class MissingContentError extends Error {
	public message = 'Missing message content.';
	public name = 'MissingContentError';
}
class UnsupportedContentError extends Error {
	public message = 'Unsupported message content type.';
	public name = 'UnsupportedContentError';
}

type InvalidMessageError = MissingSenderError | MissingContentError | UnsupportedContentError;

export { MissingContentError, MissingSenderError, UnsupportedContentError, InvalidMessageError };
