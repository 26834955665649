import { Button, ButtonType, CardModal, Message } from 'display';
import { css } from 'emotion';
import { getAbbreviatedDate } from 'lib/date';
import Strings from 'lib/strings';
import { getUserFullNameAndTitleAndCredentials } from 'lib/userHelpers';
import * as React from 'react';
import { TimeframeRestriction } from 'thriftgen/api_types';
import ModalPortal from './ModalPortal';

const styles = {
	label: css`
		font-weight: bold;
	`,
	descriptionHeader: css`
		margin-bottom: 1em;
	`
};

interface DeleteUserTimeframeRestrictionModalProps {
	modalPortalId: string;
	open: boolean;
	error?: Error;
	loading?: boolean;
	onConfirm: () => Promise<void>;
	onCancel: () => void;
	user: User;
	timeframeRestriction: TimeframeRestriction;
}

export default function DeleteUserTimeframeRestrictionModal({
	modalPortalId,
	open,
	error,
	loading,
	onConfirm,
	onCancel,
	user,
	timeframeRestriction
}: DeleteUserTimeframeRestrictionModalProps): JSX.Element {
	const ButtonVariationPrimary = Button[ButtonType.PRIMARY];
	const ButtonVariationTextPrimary = Button[ButtonType.TEXT_PRIMARY];

	const { start_date: startDate, end_date: endDate } = timeframeRestriction;

	return (
		<ModalPortal id={modalPortalId}>
			<CardModal open={open} data-testid="DeleteUserTimeframeRestrictionModal">
				<CardModal.Title>
					{Strings.Modals.DELETE_USER_TIMEFRAME_RESTRICTION_TITLE}
				</CardModal.Title>
				<CardModal.Description>
					<div className={styles.descriptionHeader}>
						{Strings.Modals.DELETE_USER_TIMEFRAME_RESTRICTION_TEXT}
					</div>
					<div>
						<span className={styles.label}>{Strings.Labels.USER}</span>:{' '}
						{getUserFullNameAndTitleAndCredentials(user)}
					</div>
					<div>
						<span className={styles.label}>{Strings.Labels.START_DATE}</span>:{' '}
						{getAbbreviatedDate(startDate)}
					</div>
					<div>
						<span className={styles.label}>{Strings.Labels.END_DATE}</span>:{' '}
						{getAbbreviatedDate(endDate)}
					</div>
				</CardModal.Description>
				{!!error && <Message negative>{error.message}</Message>}
				<Button.Group>
					<ButtonVariationTextPrimary onClick={onCancel} size={Button.Size.SMALL}>
						{Strings.Buttons.CANCEL}
					</ButtonVariationTextPrimary>
					<ButtonVariationPrimary
						onClickAsync={onConfirm}
						size={Button.Size.SMALL}
						loading={loading}>
						{Strings.Buttons.DELETE_USER_TIMEFRAME_RESTRICTION}
					</ButtonVariationPrimary>
				</Button.Group>
			</CardModal>
		</ModalPortal>
	);
}
