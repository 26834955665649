import * as React from 'react';
import PropTypes from 'prop-types';

import { Form } from 'display';
import Password from 'components/Forms/Fields/Password/Password';

const noop = () => {};
const FIELD_NAME = 'verifyPassword';
const LABEL = 'Repeat your new password';

function buildVerifyPasswordValidator(primaryFieldName, verifyFieldName) {
	return function (data) {
		const password = data[primaryFieldName];
		const verifyPassword = data[verifyFieldName];

		if (password !== verifyPassword) {
			return {
				fields: [verifyFieldName],
				messages: ['Passwords must match.']
			};
		}

		return null;
	};
}

class VerifyPassword extends React.Component {
	hasError() {
		const { errors } = this.props;
		const { fields } = errors;

		return fields.includes(FIELD_NAME);
	}

	getValue() {
		const { data } = this.props;

		return data[FIELD_NAME];
	}

	render() {
		const { className, onChange } = this.props;

		return (
			<Form.Input
				className={className}
				data-testid={FIELD_NAME}
				error={this.hasError()}
				fluid
				id={FIELD_NAME}
				label={LABEL}
				name={FIELD_NAME}
				onChange={onChange}
				type="password"
				value={this.getValue() || ''}
			/>
		);
	}
}

VerifyPassword.FIELD_NAME = FIELD_NAME;
VerifyPassword.validate = buildVerifyPasswordValidator(Password.FIELD_NAME, FIELD_NAME);

VerifyPassword.defaultProps = {
	className: null,
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	onChange: noop
};

VerifyPassword.propTypes = {
	className: PropTypes.string,
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	onChange: PropTypes.func
};

export default VerifyPassword;
