import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import {
	DateOfBirth,
	UpdateUserRequest,
	UpdateUserResponse,
	UserInfo,
	UserLicense,
	TimeframeRestriction
} from 'thriftgen/api_types';
import { UpdateUserRequestCamel } from 'thriftgen/thriftCamelTypes';

async function updateUser({
	userId,
	updates
}: UpdateUserRequestCamel): Promise<UpdateUserResponse> {
	const client = getCommonClient();

	const req = new UpdateUserRequest({
		user_id: userId,
		updates: new UserInfo({
			...objectCamelToSnake(updates),
			date_of_birth: updates.dateOfBirth && new DateOfBirth(updates.dateOfBirth),
			licenses: updates.licenses?.map(
				license => new UserLicense({ ...objectCamelToSnake(license) })
			)
		})
	});

	if (updates.specialistData?.timeframeRestrictions) {
		const timeframeRestrictions: TimeframeRestriction[] =
			updates.specialistData.timeframeRestrictions.map(
				timeframeRestriction =>
					new TimeframeRestriction(objectCamelToSnake(timeframeRestriction))
			);
		req.updates.specialist_data.timeframe_restrictions = timeframeRestrictions;
	}

	return client.update_user(req);
}

export default updateUser;
