import * as React from 'react';
import { UserCamel } from 'thriftgen/thriftCamelTypes';

import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = UserCamel['email'] | null;
type DisplayPatientEmailComponent = DataFormFieldComponent<string, DataType>;
type DisplayPatientEmailComponentProps = DataFormFieldComponentProps<DataType>;

const DisplayPatientEmail: DisplayPatientEmailComponent = ({
	data
}: DisplayPatientEmailComponentProps): JSX.Element => {
	const email = data[DisplayPatientEmail.FIELD_NAME];

	return (
		<ReadOnlyField label="Email">
			{email ? <a href={`mailto:${email}`}>{email}</a> : null}
		</ReadOnlyField>
	);
};

DisplayPatientEmail.FIELD_NAME = 'email';
DisplayPatientEmail.validate = alwaysValid;

export default DisplayPatientEmail;
