import { RouteComponentProps } from 'react-router-dom';
import { getFullPath } from './getFullPath';

function hasPathChanged(
	previousLocation: RouteComponentProps['location'],
	nextLocation: RouteComponentProps['location']
): boolean {
	const nextPath = getFullPath(previousLocation);
	const previousPath = getFullPath(nextLocation);

	return nextPath !== previousPath;
}

export { hasPathChanged };
