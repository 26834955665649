import * as React from 'react';

import { Button } from 'display';

function StopRecordingButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Destructive className="stopRecordingButton" onClick={onClick}>
			Stop
		</Button.Destructive>
	);
}

export default StopRecordingButton;
