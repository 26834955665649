import { isOnLine } from 'lib/isOnLine';
import { sitkaOktaAuth } from 'lib/okta';
import { ErrorCode, InvalidRequest } from 'thriftgen/api_types';

type Dispatcher = (args: { type: string }) => void;

const OFFLINE_ERROR = 'Your network connection is offline. Please try again later.';

function getErrorMessage(error: Error): string {
	if (!isOnLine()) {
		return OFFLINE_ERROR;
	}

	return error.message;
}

// Dispatcher is the redux store dispatch function. It is passed in rather than
// imported directly from store/index to avoid coupling it to every store, which
// would defeat the purpose of lazy loading stores. This will be removed
// once we replace the remaining request actions in Redux in favor of query hooks.
function handleInvalidRequestErrorCodes(error: Error, dispatcher: Dispatcher): void {
	function dispatchLogout(): void {
		dispatcher({ type: 'auth/logout' });
	}

	if (error instanceof InvalidRequest) {
		switch (error.error_code) {
			case ErrorCode.AUTH_SESSION_REVOKED:
			case ErrorCode.AUTH_SESSION_EXPIRED:
			case ErrorCode.USER_INACTIVE:
				sitkaOktaAuth.isAuthenticated().then(isAuthenticated => {
					if (isAuthenticated) {
						sitkaOktaAuth.signOut().then(dispatchLogout).catch(dispatchLogout);
					} else {
						dispatchLogout();
					}
				});
		}
	}
}

export { getErrorMessage, handleInvalidRequestErrorCodes, OFFLINE_ERROR };
