import * as React from 'react';

import { Paragraph } from 'display';
import { InstallChromeButton } from 'components/Buttons';
import Launcher from './Launcher';

function renderContent(): JSX.Element {
	return (
		<React.Fragment>
			<Paragraph size={Paragraph.Size.T4}>Please use Chrome</Paragraph>
			<InstallChromeButton key="chrome" />
		</React.Fragment>
	);
}

function UnsupportedBrowserLauncher(): JSX.Element {
	return (
		<Launcher
			actions={[]}
			content={renderContent()}
			title="Your browser doesn't support video recording"
		/>
	);
}

export default UnsupportedBrowserLauncher;
