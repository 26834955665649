import * as React from 'react';

import { Button } from 'display';
import Strings from 'lib/strings';

function RecordButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Primary onClick={onClick} size={Button.Size.TINY}>
			{Strings.Buttons.RECORD}
		</Button.Primary>
	);
}

export default RecordButton;
