import * as React from 'react';
import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const FIELD_NAME = 'legalRepresentativeConsent';
const FIELD_LABEL: JSX.Element = (
	<label>
		By checking this box and accessing this portal, you agree that you are the patient or are
		acting as the patient’s legal representative and if acting as the patient’s legal
		representative, you represent and warrant that you are authorized to act as the patient’s
		legal representative and that the patient has consented to your use of this portal.
	</label>
);

function validate(value: string | undefined, required: boolean): string[] | null {
	if (required && !value) {
		return [
			'Please verify that you are the patient or have the patient’s consent to use this portal.'
		];
	}

	return null;
}

const LegalRepresentativeVMessages: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	validate
});

export default LegalRepresentativeVMessages;
