import * as React from 'react';

import { Button } from 'display';

interface ShowHideButtonProps {
	onClick: React.MouseEventHandler;
	open: boolean;
}

function ShowHideButton({ onClick, open }: ShowHideButtonProps): JSX.Element {
	const text = open ? 'Hide' : 'Show';

	return (
		<Button.TextPrimary onClick={onClick} size={Button.Size.MINI}>
			{text}
		</Button.TextPrimary>
	);
}

export default ShowHideButton;
