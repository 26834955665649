import { CONSULT_FILTERS } from 'api/customFilters';
import { FilterType } from './types';
import Strings from 'lib/strings';

const PATIENT_NAME_SEARCH_FILTER = {
	attribute: CONSULT_FILTERS.PATIENT_NAME,
	placeholder: Strings.Labels.SEARCH_PATIENT_NAME_PLACEHOLDER,
	type: FilterType.SEARCH
};

export { PATIENT_NAME_SEARCH_FILTER };
