import { css } from 'emotion';
import * as React from 'react';
import PageSizeControl from './PageSizeControl';
import PaginationCursorControls from './PaginationCursorControls';

const styles = {
	container: css`
		display: flex;
		justify-content: space-between;
	`
};

function AllPageControls(): JSX.Element {
	return (
		<div className={styles.container}>
			<div>
				<PageSizeControl />
			</div>
			<div>
				<PaginationCursorControls />
			</div>
		</div>
	);
}

export default AllPageControls;
