import * as React from 'react';

import { Filter } from 'hooks/pagination';
import { usePaginationContext } from 'contexts/PaginationContext';

import { FilterMenuWithActions, FilterMenuWithActionsProps } from 'components/Filters';

function ParameterFilterFormControlsWithActions({
	filters,
	actions
}: Pick<FilterMenuWithActionsProps, 'filters' | 'actions'>): JSX.Element {
	const { getFilter, setFilter, removeFilter } = usePaginationContext();

	const fields = filters.map(field => {
		const filter = getFilter(field.attribute);
		const value = filter ? filter.value : undefined;

		return {
			...field,
			value
		};
	});

	function changeFilter(attribute: Filter['attribute'], value: Filter['value']): void {
		setFilter({ attribute, value });
	}

	return (
		<FilterMenuWithActions
			actions={actions}
			filters={fields}
			onChange={changeFilter}
			onRemove={removeFilter}
		/>
	);
}

export default ParameterFilterFormControlsWithActions;
