import * as React from 'react';
import PropTypes from 'prop-types';

import { Message } from 'display';

function FormErrors({ fields, messages }) {
	return (
		<Message negative hidden={fields.length === 0}>
			<Message.Header>Form errors</Message.Header>
			<ul data-testid="form-errors">
				{messages.length > 0
					? messages.map((error, index) => <li key={index}>{error}</li>)
					: null}
			</ul>
		</Message>
	);
}

FormErrors.propTypes = {
	fields: PropTypes.arrayOf(PropTypes.string).isRequired,
	messages: PropTypes.arrayOf(PropTypes.string).isRequired
};

export default FormErrors;
