import { PaginationContextProvider } from 'components/ContextProviders';
import { PaginationContextProviderProps } from 'components/ContextProviders/PaginationContextProvider';
import * as React from 'react';
import AllPageControls from './Controls/AllPageControls';
import PageSizeControl from './Controls/PageSizeControl';
import PaginationCursorControls from './Controls/PaginationCursorControls';
import ParameterFilterFormControls from './Controls/ParameterFilterFormControls';
import ParameterFilterFormControlsWithActions from './Controls/ParameterFilterFormControlsWithActions';
import ParameterFilterPopoverControls from './Controls/ParameterFilterPopoverControls';
import { PaginationStorageKeys } from './storageKeys';

function Pagination(props: PaginationContextProviderProps): JSX.Element {
	return <PaginationContextProvider {...props} />;
}

Pagination.FilterForm = ParameterFilterFormControls;
Pagination.FilterFormWithActions = ParameterFilterFormControlsWithActions;
Pagination.FilterPopover = ParameterFilterPopoverControls;
Pagination.Cursors = PaginationCursorControls;
Pagination.PageSize = PageSizeControl;

Pagination.AllPageControls = AllPageControls;

Pagination.StorageKeys = PaginationStorageKeys;

export default Pagination;
