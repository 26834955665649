import * as React from 'react';

import { Button, ButtonProps } from 'display';

function ToggleListButton({ onClick, title }: Pick<ButtonProps, 'onClick' | 'title'>): JSX.Element {
	return (
		<Button.TextPrimary onClick={onClick} size={Button.Size.SMALL} title={title}>
			{title}
		</Button.TextPrimary>
	);
}

export default ToggleListButton;
