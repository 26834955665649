import * as React from 'react';
import { ButtonType } from 'display';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

function LinkToHomeButton(): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			link="/"
			text={Strings.Buttons.RETURN_HOME}
			classNames="homeButton"
		/>
	);
}

export default LinkToHomeButton;
