import * as React from 'react';

import { Message, Paragraph } from 'display';

interface ConversationsLoadErrorProps {
	message: string;
}

function ConversationsLoadError({ message }: ConversationsLoadErrorProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Unable to load conversations.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default ConversationsLoadError;
