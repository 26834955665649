import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { SendVideoVisitLinkRequest } from 'thriftgen/api_types';
import {
	SendVideoVisitLinkRequestCamel,
	SendVideoVisitLinkResponseCamel
} from 'thriftgen/thriftCamelTypes';

async function sendVideoVisitLink(
	args: SendVideoVisitLinkRequestCamel
): Promise<SendVideoVisitLinkResponseCamel> {
	const client = getCommonClient();

	const req = new SendVideoVisitLinkRequest(objectCamelToSnake(args));

	const response = await client.send_video_visit_link(req);

	return objectSnakeToCamel(response);
}

export default sendVideoVisitLink;
