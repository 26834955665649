import * as React from 'react';

import { Button } from 'display';
import { SettingsIconButton, RecorderMinimizeMaximizeButton } from 'components/Buttons';

import RecordingControls from './RecordingControls';
import RecordingControlsMinimized from './RecordingControlsMinimized';

import { RecordingStatus, RecordingMouseEvents } from './types';

type RecorderActionBarProps = RecordingMouseEvents & {
	minimized: boolean;
	status: RecordingStatus;
};

class RecorderActionBar extends React.PureComponent<RecorderActionBarProps> {
	public render(): JSX.Element {
		if (this.isIdle) {
			return this.renderIdleRecorderControls();
		}

		return this.renderActiveRecordingControls();
	}

	private get isIdle() {
		return this.props.status === RecordingStatus.IDLE;
	}

	private renderIdleRecorderControls(): JSX.Element {
		const { minimized, onMinimize, onOpenSettings } = this.props;

		return (
			<Button.Group justifyContent="space-between">
				{onMinimize && this.renderMinimizeControl()}
				<Button.InlineGroup>
					{this.renderRecordingControls()}
					{!minimized && <SettingsIconButton onClick={onOpenSettings} />}
				</Button.InlineGroup>
			</Button.Group>
		);
	}

	private renderActiveRecordingControls(): JSX.Element {
		const { onMinimize } = this.props;

		return (
			<Button.Group justifyContent="space-between">
				{this.renderRecordingControls()}
				{onMinimize && this.renderMinimizeControl()}
			</Button.Group>
		);
	}

	private renderMinimizeControl(): JSX.Element | null {
		const { minimized, onMinimize } = this.props;

		return <RecorderMinimizeMaximizeButton minimized={minimized} onClick={onMinimize} />;
	}

	private renderRecordingControls(): JSX.Element {
		const { minimized, onPause, onResume, onStart, onStop, status } = this.props;

		if (minimized) {
			return (
				<RecordingControlsMinimized
					onPause={onPause}
					onResume={onResume}
					onStart={onStart}
					onStop={onStop}
					status={status}
				/>
			);
		}

		return (
			<RecordingControls
				onPause={onPause}
				onResume={onResume}
				onStart={onStart}
				onStop={onStop}
				status={status}
			/>
		);
	}
}

export default RecorderActionBar;
export { RecorderActionBarProps };
