import * as React from 'react';

import { IllustrationProps } from './illustrationTypes';

function HipaaCompliant({ fill, stroke }: IllustrationProps): JSX.Element {
	return (
		<svg viewBox="0 0 84 25" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M25.2 0H22.05V12.6H25.2V7.875H30.975V12.6H34.125V0H30.975V4.725H25.2V0ZM44.625 0H36.225V3.15H38.85V9.45H36.225V12.6H44.625V9.45H42V3.15H44.625V0ZM77.6999 16.8H83.9999V18.9H81.8999V24.15H79.7999V18.9H77.6999V16.8ZM26.685 16.8H23.1901L22.05 17.9401V23.0099L23.1901 24.15H26.685L27.825 23.0099V21.525H25.725V22.05H24.15V18.9H25.725V19.425H27.825V17.9401L26.685 16.8ZM52.4999 16.8H54.5999V22.05H57.2249V24.15H52.4999V16.8ZM58.275 24.15H60.375V16.8H58.275V24.15ZM30.015 16.8H34.0348L35.1749 17.9401V23.0099L34.0348 24.15H30.015L28.8749 23.0099V17.9401L30.015 16.8ZM30.9749 18.9V22.05H33.0749V18.9H30.9749ZM38.4489 16.8H36.225V24.15H38.325V21.2479L39.2511 23.1H41.0739L42 21.2479V24.15H44.1V16.8H41.8761L40.1625 20.2271L38.4489 16.8ZM69.3001 16.8H71.3797L74.5501 20.7629V16.8H76.6501V24.15H74.5704L71.4001 20.1871V24.15H69.3001V16.8ZM55.2524 0H46.725V12.6H49.875V9.975H55.2524L57.75 7.47739V2.49761L55.2524 0ZM53.9476 6.825H49.875V3.15H53.9476L54.6 3.80239V6.17261L53.9476 6.825ZM45.1501 16.8H50.31L51.4501 17.9401V21.4349L50.31 22.575H47.2501V24.15H45.1501V16.8ZM47.2501 20.475H49.3501V18.9H47.2501V20.475ZM68.3633 0H61.8368L59.325 8.16317V12.6H62.475V9.975H67.725V12.6H70.875V8.16317L68.3633 0ZM67.1675 6.825H63.0325L64.1633 3.15H66.0368L67.1675 6.825ZM62.733 16.8H66.942L68.25 21.3779V24.15H66.15V22.575H63.525V24.15H61.425V21.3779L62.733 16.8ZM63.867 20.475H65.808L65.358 18.9H64.317L63.867 20.475ZM81.4883 0H74.9618L72.45 8.16317V12.6H75.6V9.975H80.85V12.6H84V8.16317L81.4883 0ZM80.2925 6.825H76.1575L77.2883 3.15H79.1618L80.2925 6.825Z"
				fill={fill}
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M8.925 3.15C7.7652 3.15 6.825 4.0902 6.825 5.25V8.4H11.025V5.25C11.025 4.0902 10.0848 3.15 8.925 3.15ZM14.175 8.4V5.25C14.175 2.3505 11.8245 0 8.925 0C6.02551 0 3.675 2.3505 3.675 5.25V8.4H0V24.15H17.85V8.4H14.175ZM3.15 11.55V21H14.7V11.55H3.15ZM7.35 18.9V13.65H10.5V18.9H7.35Z"
				fill={stroke}
			/>
		</svg>
	);
}

export default HipaaCompliant;
