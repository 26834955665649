import { Token } from '@okta/okta-auth-js';
import { useOktaAuth } from '@okta/okta-react';
import { SessionExpiryModal } from 'components/Modals';
import { useUserActivity, useWindowEventLog } from 'hooks';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import React, { useCallback, useState } from 'react';
import { connect } from 'react-redux';
import { selectAuthUser, sendLogoutWithToast, setLogout } from 'store/auth';
import { AppState } from 'store/types';
import { LogoutReason } from 'thriftgen/api_types.js';
import { LogoutRequestCamel } from 'thriftgen/thriftCamelTypes';
import { deriveActivityIntervalFromToken, getTokenExpiryLocaleTime } from './helpers';
import { useSessionExpiry } from './useSessionExpiry';

interface DispatchProps {
	logoutLocally: () => void;
	sendLogoutRequest: (requestCamel: LogoutRequestCamel) => Promise<void>;
}

interface StoreProps {
	actorExercisesOkta: boolean;
}

interface OktaSessionTimeoutState {
	activityInterval: number | null;
	error: null | Error;
}

type OktaSessionTimeoutProps = StoreProps & DispatchProps;

const getInitialState = (): OktaSessionTimeoutState => ({
	error: null,
	activityInterval: null
});

function OktaSessionTimeout({
	actorExercisesOkta,
	logoutLocally,
	sendLogoutRequest
}: OktaSessionTimeoutProps): JSX.Element {
	const { oktaAuth } = useOktaAuth();
	const [state, setState] = useState<OktaSessionTimeoutState>(getInitialState());
	const errorMessage = state.error ? state.error.message : null;

	const logout = useCallback(
		(reason: LogoutReason): Promise<void> => {
			return sendLogoutRequest({ reason }).catch(logoutLocally);
		},
		[sendLogoutRequest, logoutLocally]
	);

	const logoutOnExpired = useCallback(() => {
		SitkaLogger.logMessage('Logout on session expired', LogEventName.USER_SESSION);
		return logout(LogoutReason.SESSION_EXPIRED);
	}, [logout]);

	const logoutOnUserInput = useCallback(() => {
		SitkaLogger.logMessage('Logout on user initiated', LogEventName.USER_SESSION);
		return logout(LogoutReason.USER_INITIATED);
	}, [logout]);

	const logEvent = useWindowEventLog('oktaSessionTimeout');

	const { clearCountdown, countdownStarted, countdownTimeLeft } = useSessionExpiry({
		actorExercisesOkta,
		onBeforeExpired,
		onExpired: logoutOnExpired,
		onStart,
		onRenewed
	});

	const { start: startMonitoring, stop: stopMonitoring } = useUserActivity({
		delay: state.activityInterval,
		onActive: onUserActivity,
		onIdle: onUserIdleness
	});

	function onUserActivity(): void {
		oktaAuth.tokenManager
			.get('accessToken')
			.then((token: Token): void | Promise<void> => {
				if (token && !oktaAuth.tokenManager.hasExpired(token)) {
					SitkaLogger.logMessage(
						'renewing token upon user activity',
						LogEventName.USER_SESSION
					);
					logEvent('renewing token upon user activity');
					return renewToken();
				}
			})
			.catch(reason => {
				const errorMsg = 'failed to renew token';
				logEvent(errorMsg);
				SitkaLogger.logMessage(reason);
			});
	}

	function onUserIdleness(): void {
		logEvent('skipping token renewal, user is idle');
	}

	function onRenewed(token: Token): void {
		SitkaLogger.logMessage(
			`renewed token, expires at ${getTokenExpiryLocaleTime(token)}`,
			LogEventName.USER_SESSION
		);
		logEvent(`renewed token, expires at ${getTokenExpiryLocaleTime(token)}`);
		setActivityInterval(token);
	}

	function onStart(token: Token): void {
		logEvent(`found access token that expires at ${getTokenExpiryLocaleTime(token)}`);
		setActivityInterval(token);
		logEvent('starting user activity monitoring');
		startMonitoring();
	}

	function onBeforeExpired(): void {
		logEvent('access token expiring in 5 minutes and 30 seconds');
		logEvent('stopping user activity monitoring');
		stopMonitoring();
		logEvent('starting session expiry modal countdown');
		SitkaLogger.logMessage(
			'starting session expiry modal countdown',
			LogEventName.USER_SESSION
		);
	}

	function setActivityInterval(token: Token): void {
		const activityInterval = deriveActivityIntervalFromToken(token);
		const reportTime = new Date();
		reportTime.setMilliseconds(reportTime.getMilliseconds() + activityInterval);
		logEvent(`reporting on user activity at ${reportTime.toLocaleTimeString()}`);
		setState(current => ({
			...current,
			activityInterval
		}));
	}

	async function renewToken(): Promise<void> {
		await oktaAuth.tokenManager.renew('accessToken');
	}

	function onLogout(): Promise<void> {
		setState(current => ({ ...current, error: null }));
		clearCountdown();
		return logoutOnUserInput();
	}

	function onContinue(): Promise<void> {
		SitkaLogger.logMessage(
			'user decided to stay signed in, renewing token',
			LogEventName.USER_SESSION
		);
		logEvent('user decided to stay signed in, renewing token');
		setState(current => ({ ...current, error: null }));
		return renewToken()
			.then(() => {
				logEvent('resuming user activity monitoring');
				startMonitoring();
				logEvent('closing session expiry modal');
				clearCountdown();
			})
			.catch((reason: Error) => {
				const errorMsg = 'failed to renew token';
				logEvent(errorMsg);
				SitkaLogger.logMessage(reason);
				setState(current => ({ ...current, error: reason }));
			});
	}

	return (
		<SessionExpiryModal
			open={countdownStarted}
			onLogout={onLogout}
			onContinue={onContinue}
			millisecondsLeft={countdownTimeLeft}
			errorMessage={errorMessage}
		/>
	);
}

const mapStateToProps = (store: AppState): StoreProps => {
	try {
		const user = selectAuthUser(store);
		return {
			actorExercisesOkta: user.exercises_okta
		};
	} catch {
		return {
			actorExercisesOkta: false
		};
	}
};

export default connect(mapStateToProps, {
	sendLogoutRequest: sendLogoutWithToast,
	logoutLocally: setLogout
})(OktaSessionTimeout);
