import {
	buildSMAPhoto,
	SMABoardCertification,
	SMAFellowship,
	SMAFunFacts,
	SMAMedSchool,
	SMAResidency,
	SMASpecialty
} from 'components/Forms/Fields';
import { rebuildUserObject, UserData } from 'components/Forms/UserForms/helpers';
import { useUserContext } from 'contexts/UserContext';
import React, { useMemo, useState } from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { DataFormFieldComponent } from '../DataFormTypes';
import {
	Deactivated,
	Email,
	ExternalUserId,
	Location,
	NpiNumber,
	PhoneNumber,
	SitkaRoles,
	SpecialtiesField,
	TextingPermission,
	UserFullName
} from '../Fields';
import UserEditor from './UserEditor';

interface ConsumerProps {
	onDone: () => void;
}

interface StoreProps {
	isSitkaOperations: boolean;
}

type EditUserFormProps = StoreProps & ConsumerProps;

function EditUserForm({ isSitkaOperations, onDone }: EditUserFormProps): JSX.Element {
	const [error, setError] = useState<Error>();
	const { user, updateUser } = useUserContext();

	const fields = useMemo(
		() =>
			[
				UserFullName,
				PhoneNumber,
				TextingPermission,
				isSitkaOperations ? Email : undefined,
				Location,
				isSitkaOperations ? ExternalUserId : undefined,
				SpecialtiesField,
				Deactivated,
				// TODO: Remove this `isSitkaOperations` checks when DataFormWrapper
				// can be configured so that the display-only fallback is optional.
				isSitkaOperations ? SitkaRoles : undefined,
				NpiNumber,
				buildSMAPhoto({
					profilePicUrl: user.specialist_data?.profile_pic_url,
					profilePicState: user.specialist_data?.profile_pic_state
				}),
				SMASpecialty,
				SMAMedSchool,
				SMAResidency,
				SMAFellowship,
				SMABoardCertification,
				SMAFunFacts
			].filter(Boolean) as DataFormFieldComponent[],
		// we do need to have an empty dependency array here because adding the dependencies it wants
		// reintroduces a bug with the image upload component where the preview image does not update
		// after a new photo is uploaded
		/* eslint-disable-next-line */
		[]
	);

	async function onSubmit(data: UserData): Promise<void> {
		setError(undefined);
		try {
			await updateUser(rebuildUserObject(data));
			return onDone();
		} catch (error) {
			return setError(error);
		}
	}

	return (
		<UserEditor
			onSubmit={onSubmit}
			onCancel={onDone}
			user={user}
			fields={fields}
			error={error}
		/>
	);
}

const mapStoreToProps = (store: AppState): StoreProps => {
	const actor = selectAuthUser(store);
	return {
		isSitkaOperations: actor.is_sitka_operations
	};
};

export default connect(mapStoreToProps)(EditUserForm);
