import { i18n } from 'thriftgen/api_types';

const DEFAULT_LANG = i18n.ENGLISH;

// Eventually we'd pick this up from a global/env variable
const CURRENT_LANG = DEFAULT_LANG;

export function translateThriftEnum(enumKey, translationMap) {
	return translationMap[CURRENT_LANG][enumKey] || null;
}
