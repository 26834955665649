import { GetCasesRequestCamel, GetCasesResponseCamel } from 'thriftgen/thriftCamelTypes';
import { getCommonClient } from 'lib/ThriftClient';
import { objectSnakeToCamel, objectCamelToSnake } from 'lib/objectConverter';
import { GetCasesRequest } from 'thriftgen/api_types';

async function getCases(params: GetCasesRequestCamel): Promise<GetCasesResponseCamel> {
	const client = getCommonClient();
	const request = new GetCasesRequest(objectCamelToSnake(params));

	const response = await client.get_cases(request);
	return objectSnakeToCamel(response);
}

export default getCases;
