import * as React from 'react';
import PropTypes from 'prop-types';

import { Message, Paragraph } from 'display/';

function ConsultsLoadError({ message }) {
	return (
		<Message negative>
			<Message.Header>Unable to load consults.</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

ConsultsLoadError.propTypes = {
	message: PropTypes.string.isRequired
};

export default ConsultsLoadError;
