import { Anchor, Button, ButtonProps, ButtonType } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import Strings from 'lib/strings';

type AllowedButtonProps = Pick<ButtonProps, 'size' | 'responsive'>;

type HelpButtonProps = AllowedButtonProps & {
	buttonType?: ButtonType;
};

function HelpButton({ buttonType = ButtonType.PRIMARY, ...props }: HelpButtonProps): JSX.Element {
	const ButtonVariation = Button[buttonType];

	return (
		<Anchor href={RouteUrls.buildUrl(RouteUrls.Key.HELP)}>
			<ButtonVariation {...props}>{Strings.Buttons.HELP}</ButtonVariation>
		</Anchor>
	);
}

export default HelpButton;
