import { Consult } from 'thriftgen/api_types';

enum ConsultActionType {
	SET_CONSULTS = 'consults/setConsults'
}

interface SetConsultsAction {
	type: ConsultActionType.SET_CONSULTS;
	consults: Consult[];
}

type ConsultActions = SetConsultsAction;
type ConsultsState = ConsultsById;
interface ConsultsById {
	[consultId: string]: Consult;
}

export { ConsultActionType, SetConsultsAction, ConsultActions, ConsultsState, ConsultsById };
