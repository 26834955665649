/* eslint-disable react/prop-types */
import * as React from 'react';

import { css, cx, keyframes } from 'emotion';

import { zIndex } from 'styles/zIndex';
import { AudioIssueType } from 'lib/AudioMonitor/types';
import { Icon, Message } from 'display';

const fadeIn = keyframes`
	from { opacity: 0; }
	to { opacity: 1; }
`;

const styles = {
	base: css`
		position: absolute !important;
		z-index: ${zIndex.L10};
		width: 80% !important;
		margin: 0 10% !important;
		opacity: 0.85;
		text-align: center;
		animation: ${fadeIn} 0.5s ease;
	`,
	alert: css`
		top: 45%;
	`,
	warn: css`
		bottom: 1rem;
	`
};

interface AudioEventMessageProps {
	audioIssue: AudioIssueType;
	onDismiss: React.ReactEventHandler;
}

// redundant and unused events preserved in case messaging becomes more
// granular for event types in the future
export const AUDIO_ISSUE_CONTENT: { [key in AudioIssueType]: string | null } = {
	[AudioIssueType.CLEAN_AUDIO]: null,
	[AudioIssueType.NO_AUDIO_CHANNEL]:
		'No audio detected. Please check your device settings and microphone source.',
	[AudioIssueType.AUDIO_DROPPED_OUT]:
		'No audio detected. Please check your device settings and microphone source.',
	[AudioIssueType.AUDIO_TOO_HIGH]:
		'Audio input level is too high. Please find a quieter place or Pause the recording to adjust your audio via your device settings',
	[AudioIssueType.AUDIO_TOO_LOW]:
		'Audio input level is too low. Please try speaking louder or Pause the recording to adjust your audio via your device settings.'
};

function AudioEventMessage(props: AudioEventMessageProps): JSX.Element {
	const isDismissible: boolean = deriveIfDismissible(props.audioIssue);

	function deriveIfDismissible(audioIssue: AudioIssueType): boolean {
		switch (audioIssue) {
			case AudioIssueType.CLEAN_AUDIO:
			case AudioIssueType.NO_AUDIO_CHANNEL:
			case AudioIssueType.AUDIO_DROPPED_OUT:
				return false;
			case AudioIssueType.AUDIO_TOO_HIGH:
			case AudioIssueType.AUDIO_TOO_LOW:
				return true;
		}
	}

	function dismissableMessage(): JSX.Element {
		return (
			<Message
				className={cx(styles.base, styles.warn)}
				info={true}
				onDismiss={props.onDismiss}>
				<Message.Content>{AUDIO_ISSUE_CONTENT[props.audioIssue]}</Message.Content>
			</Message>
		);
	}

	function nonDismissibleMessage(): JSX.Element {
		return (
			<Message className={cx(styles.base, styles.alert)} icon={true} negative={true}>
				<Icon.Group size="big">
					<Icon loading={true} size="big" name="circle notched" />
					<Icon name="microphone slash" />
				</Icon.Group>
				<Message.Content>{AUDIO_ISSUE_CONTENT[props.audioIssue]}</Message.Content>
			</Message>
		);
	}

	return isDismissible ? dismissableMessage() : nonDismissibleMessage();
}

export default AudioEventMessage;
