import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getAdminClient } from 'lib/ThriftClient';
import { MergeCasesRequest } from 'thriftgen/api_types';
import { MergeCasesRequestCamel, MergeCasesResponseCamel } from 'thriftgen/thriftCamelTypes';

async function mergeCases(
	mergeCaseRequest: MergeCasesRequestCamel
): Promise<MergeCasesResponseCamel> {
	const client = getAdminClient();
	const request: MergeCasesRequest = {
		source_case_id: mergeCaseRequest.sourceCaseId,
		target_case_id: mergeCaseRequest.targetCaseId
	};
	const response = await client.merge_cases(objectCamelToSnake(request) as MergeCasesRequest);
	return objectSnakeToCamel(response);
}

export default mergeCases;
