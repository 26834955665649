import { SaveButton } from 'components/Buttons';
import DataForm from 'components/Forms/DataForm';
import FormErrors from 'components/Forms/FormErrors';
import { UnchangedFormMessage } from 'components/Messages';
import { Button, Divider } from 'display';
import { determineDisplayFields } from './helpers';
import { objectSnakeToCamel } from 'lib/objectConverter';
import { isEqual } from 'lodash';
import PropTypes from 'prop-types';
import * as React from 'react';

function getInitialState(data) {
	return {
		data: data ? objectSnakeToCamel(data) : {},
		errors: {
			fields: [],
			messages: []
		},
		submittedUnchanged: false
	};
}

class DataFormWrapper extends React.Component {
	constructor(props) {
		super(props);

		this.isValid = this.isValid.bind(this);
		this.onFormChange = this.onFormChange.bind(this);
		this.onSubmit = this.onSubmit.bind(this);

		this.state = getInitialState(props.data);
	}

	getErrors() {
		const { errors } = this.state;
		const { externalErrors } = this.props;

		return {
			fields: [...errors.fields, ...externalErrors.fields],
			messages: [...errors.messages, ...externalErrors.messages]
		};
	}

	getChanges() {
		const { data: initialData, editableFields } = this.props;
		const initialDataCamel = objectSnakeToCamel(initialData);
		const { data: formContent } = this.state;
		let changes = {};

		editableFields.forEach(field => {
			const initialValue = initialDataCamel[field];
			const formValue = formContent[field];

			// Note, an array whose values are the same
			// but whose order did change is considered not to
			// be equal.
			if (!isEqual(initialValue, formValue)) {
				changes[field] = formValue;
			}
		});

		return changes;
	}

	isValid() {
		const { conditionalFields, fields, requiredFields } = this.props;
		const { data } = this.state;
		const displayFields = determineDisplayFields(conditionalFields, data, fields);
		const errors = DataForm.validate(displayFields, requiredFields, data);

		if (errors) {
			this.setState({ errors });

			return false;
		}

		return true;
	}

	onFormChange(_, { name, value }) {
		const { onChange } = this.props;
		const newData = {
			...this.state.data,
			[name]: value
		};
		this.setState(state => ({
			...state,
			data: newData
		}));
		!!onChange && onChange(newData);
	}

	onSubmit() {
		const changes = this.props.submitAllFields ? this.state.data : this.getChanges();
		const submittedUnchanged = Object.keys(changes).length === 0;
		this.setState({ submittedUnchanged });

		if (submittedUnchanged || !this.isValid()) {
			return Promise.resolve();
		}

		return this.props.onSubmit(changes).then(() => {
			if (this.props.resetOnSubmit) {
				this.setState(getInitialState(this.props.data));
			}
		});
	}

	renderActions() {
		const { CancelControl, SubmitControl, editableFields, justifyContent } = this.props;

		return (
			<Button.Group justifyContent={justifyContent}>
				{CancelControl !== null && <CancelControl />}
				{!!editableFields.length && <SubmitControl onClickAsync={this.onSubmit} />}
			</Button.Group>
		);
	}

	render() {
		const { editableFields, fields, conditionalFields, displayActionsDivider } = this.props;
		const { data, submittedUnchanged } = this.state;

		const errors = this.getErrors();

		return (
			<React.Fragment>
				<DataForm
					data={data}
					editableFields={editableFields}
					errors={errors}
					fields={fields}
					conditionalFields={conditionalFields}
					onChange={this.onFormChange}
				/>
				{errors.fields.length > 0 && (
					<FormErrors fields={errors.fields} messages={errors.messages} />
				)}
				{submittedUnchanged && <UnchangedFormMessage />}
				{displayActionsDivider && <Divider section />}
				{this.renderActions()}
			</React.Fragment>
		);
	}
}

DataFormWrapper.defaultProps = {
	data: {},
	editableFields: [],
	requiredFields: [],
	conditionalFields: [],
	externalErrors: {
		fields: [],
		messages: []
	},
	resetOnSubmit: false,
	submitAllFields: false,
	CancelControl: null,
	SubmitControl: SaveButton,
	onChange: null,
	justifyContent: null,
	displayActionsDivider: true
};

DataFormWrapper.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	fields: PropTypes.arrayOf(PropTypes.func).isRequired,
	externalErrors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string),
		messages: PropTypes.arrayOf(PropTypes.string)
	}),
	requiredFields: PropTypes.arrayOf(PropTypes.string),
	conditionalFields: PropTypes.arrayOf(
		PropTypes.shape({
			fieldName: PropTypes.string,
			conditionalFieldName: PropTypes.string,
			action: PropTypes.string,
			values: PropTypes.arrayOf(
				PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.bool])
			)
		})
	),
	resetOnSubmit: PropTypes.bool,
	submitAllFields: PropTypes.bool,
	onSubmit: PropTypes.func.isRequired,
	CancelControl: PropTypes.func,
	SubmitControl: PropTypes.func,
	onChange: PropTypes.func,
	justifyContent: PropTypes.string,
	displayActionsDivider: PropTypes.bool
};

export default DataFormWrapper;
