import {
	ActionComponent,
	ActionMenuDropdown,
	UserTimeframeRestrictionEditAction,
	UserTimeframeRestrictionDeleteAction
} from 'components/ActionMenuDropdown';
import { DataTableColumn } from 'components/DataTable/DataTable';
import { css } from 'emotion';
import React from 'react';
import { mediaQueries } from 'styles';

const styles = {
	actionMenuDropdownWrapper: css`
		float: right;
		${mediaQueries.mobile} {
			float: none;
		}
	`
};

const buildActions = (): DataTableColumn<TimeframeRestriction> => ({
	render: (timeframeRestriction: TimeframeRestriction): JSX.Element => {
		const actionComponents: ActionComponent[] = [
			UserTimeframeRestrictionEditAction,
			UserTimeframeRestrictionDeleteAction
		];

		return (
			<div className={styles.actionMenuDropdownWrapper}>
				<ActionMenuDropdown
					actionComponents={actionComponents}
					data={timeframeRestriction}
				/>
			</div>
		);
	}
});

export { buildActions };
