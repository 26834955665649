import { css } from 'emotion';
import noop from 'lib/noop';
import * as React from 'react';
import { mediaQueries } from 'styles';
import { CancelIconButton } from 'components/Buttons';
import filesize from 'filesize';

type FileListPreviewProps = {
	files: File[];
	onFileRemove: (fileIndexesToRemove: number[]) => void;
};

const styles = {
	container: css`
		padding: 1em 0.5em;
		background-color: white;
		${mediaQueries.desktop} {
			padding: 1.5em 1em;
		}
	`,
	fileItemWrapper: css`
		display: flex;
		justify-content: space-between;
		padding: 0.25rem;
	`
};

function FileListPreview({ files, onFileRemove = noop }: FileListPreviewProps): JSX.Element {
	function renderFileItem(file: File, index: number) {
		const formattedFileSize = filesize(file.size, {
			base: 2,
			standard: 'jedec'
		});

		return (
			<React.Fragment key={`fragment-${index}-attached-file`}>
				{index !== 0 && <hr key={`hr-${index}-attached-file`} />}
				<div className={styles.fileItemWrapper} key={`container-${index}-${file.name}`}>
					<div
						key={`name-size-${index}-${file.name}`}>{`${file.name} (${formattedFileSize})`}</div>
					<CancelIconButton
						onClick={() => onFileRemove([index])}
						key={`delete-button-${index}-${file.name}`}
					/>
				</div>
			</React.Fragment>
		);
	}

	function render() {
		return <div className={styles.container}>{files.map(renderFileItem)}</div>;
	}

	return render();
}

export default FileListPreview;
