import {
	GetCaseRequestCamel,
	GetCaseResponseCamel,
	ManageCaseRequestCamel,
	UserInfoCamel
} from 'thriftgen/thriftCamelTypes';
import {
	getCase,
	manageCase as manageCaseRequest,
	reportEvent as reportEventRequest,
	ReportEventParams,
	updateUser
} from 'api/common';
import { objectSnakeToCamel } from 'lib/objectConverter';

import useQuery from '../useQuery';

import { transformCaseToComposite } from './helpers';
import { CaseQuery } from './types';

function useCaseQuery({ caseId }: GetCaseRequestCamel): CaseQuery {
	const key = `case/${caseId}`;
	const { data, error, mutate } = useQuery<GetCaseResponseCamel>(key, () => getCase({ caseId }));

	async function manageCase(args: ManageCaseRequestCamel): Promise<void> {
		await mutate(manageCaseRequest(args), false);
	}

	async function reportEvent(args: ReportEventParams): Promise<void> {
		await reportEventRequest(args).then(({ the_case: theCase }) =>
			mutate(
				currentData =>
					Promise.resolve({
						...currentData,
						theCase: objectSnakeToCamel(theCase)
					}),
				false
			)
		);
	}

	async function revalidate(): Promise<void> {
		await mutate();
	}

	async function updatePatient(updates: UserInfoCamel): Promise<void> {
		await mutate(
			(currentData: GetCaseResponseCamel) =>
				updateUser({
					userId: currentData.theCase.patientId,
					updates
				}).then(({ user: updatedUser }) => ({
					...currentData,
					users: currentData.users.map(user =>
						updatedUser.user_id === user.userId ? objectSnakeToCamel(updatedUser) : user
					)
				})),
			false
		);
	}

	return {
		theCase: data ? transformCaseToComposite(data) : undefined,
		error,
		manageCase,
		reportEvent,
		revalidate,
		updatePatient
	};
}

export default useCaseQuery;
