import * as React from 'react';
import PropTypes from 'prop-types';

import AuthUserRequestContext from 'contexts/AuthUserRequestContext';

class RequestAuthUser extends React.Component {
	static contextType = AuthUserRequestContext;

	componentDidMount() {
		const { blocking } = this.props;

		this.context.sendGetAuthUser(blocking);
	}

	render() {
		const { children } = this.props;

		return children;
	}
}

RequestAuthUser.defaultProps = {
	blocking: false
};

RequestAuthUser.propTypes = {
	blocking: PropTypes.bool,
	children: PropTypes.node.isRequired
};

export default RequestAuthUser;
