import * as React from 'react';

import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';

const FIELD_NAME = 'enableScreenSharing';
const LABEL = 'Enable Screen Sharing';

const EnableScreenSharingElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

function EnableScreenSharing(props: DataFormFieldComponentProps): JSX.Element {
	return (
		<>
			<EnableScreenSharingElement key={FIELD_NAME} {...props} />
		</>
	);
}

EnableScreenSharing.FIELD_NAME = FIELD_NAME;
EnableScreenSharing.validate = () => null;

export default EnableScreenSharing;
