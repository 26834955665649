import * as React from 'react';

import { Button, ButtonProps, Icon } from 'display';
import Strings from 'lib/strings';

function SendButton({
	onClickAsync,
	size
}: Pick<ButtonProps, 'onClickAsync' | 'size'>): JSX.Element {
	return (
		<Button.Primary className="sendButton" size={size} onClickAsync={onClickAsync}>
			<Icon name="send" />
			{Strings.Buttons.SEND}
		</Button.Primary>
	);
}

export default SendButton;
