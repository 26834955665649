import * as React from 'react';
import { css, cx } from 'emotion';
import { mediaQueries } from 'styles';
import { Properties } from 'csstype';

const DEFAULT_JUSTIFY_CONTENT = 'initial';

const styles = {
	group: (justifyContent: Properties['justifyContent']) => css`
		display: flex;

		${mediaQueries.desktop} {
			justify-content: ${justifyContent};
		}

		& > *:not(:last-child) {
			${mediaQueries.desktop} {
				margin-right: 1.5em !important;
			}
		}
	`,
	groupInline: css`
		& > *:not(:last-child) {
			margin-right: 0.5em;

			${mediaQueries.desktop} {
				margin-right: 1.5em !important;
			}
		}
	`,
	groupResponsive: css`
		flex-direction: column-reverse;

		& > *:not(:last-child) {
			margin-top: 1em !important;

			${mediaQueries.desktop} {
				margin-top: 0 !important;
			}
		}

		${mediaQueries.desktop} {
			flex-direction: initial;
		}
	`
};

interface ButtonGroupProps {
	children: React.ReactNode;
	justifyContent?: Properties['justifyContent'];
}

export function ButtonGroup({
	children,
	justifyContent = DEFAULT_JUSTIFY_CONTENT
}: ButtonGroupProps): JSX.Element {
	const className = [styles.group(justifyContent), styles.groupResponsive];

	return <div className={cx(className)}>{children}</div>;
}

export function ButtonInlineGroup({
	children,
	justifyContent = DEFAULT_JUSTIFY_CONTENT
}: ButtonGroupProps): JSX.Element {
	return <div className={cx(styles.group(justifyContent), styles.groupInline)}>{children}</div>;
}
