import * as React from 'react';
import { css } from 'emotion';

import { colors, mediaQueries, zIndexLayer } from 'styles';

interface DocumentViewerContainerProps {
	open: boolean;
	sidebar: React.ReactNode;
	trigger: React.ReactNode;
	viewer: React.ReactNode;
}

const styles = {
	container: css`
		width: 100%;
		display: flex;
		flex: 1;
		padding-top: 1rem;
		height: calc(90vh - 10rem);
	`,
	sidebar: (open: boolean) => css`
		padding: 0 1rem 1rem 0;

		display: ${open ? 'flex' : 'none'};
		flex: 1;
		flex-direction: column;

		background-color: ${colors.neutral['25']};

		overflow-y: auto;

		& > *:last-child {
			margin-bottom: 6rem;
		}

		z-index: ${zIndexLayer.GLOBAL};

		${mediaQueries.desktop} {
			border-right: 1px solid ${colors.neutral['100']};
			max-width: 25%;
			z-index: unset;
		}
	`,
	viewer: (open: boolean) => css`
		position: relative;
		display: flex;
		flex: 1;
		width: ${open ? '75%' : '100%'};

		${mediaQueries.mobile} {
			display: ${open ? 'none' : 'flex'};
		}
	`
};

function DocumentViewerContainer({
	open,
	sidebar,
	trigger,
	viewer
}: DocumentViewerContainerProps): JSX.Element {
	return (
		<React.Fragment>
			{trigger}
			<div className={styles.container}>
				<div className={styles.sidebar(open)} data-testid="DocumentViewerContainer-sidebar">
					{sidebar}
				</div>
				<div className={styles.viewer(open)}>{viewer}</div>
			</div>
		</React.Fragment>
	);
}

export default DocumentViewerContainer;
