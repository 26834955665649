import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import { Button, ButtonType } from 'display';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

function LinkToCreateUserButton(): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			link={RouteUrls.buildUrl(RouteUrls.Key.USER_NEW)}
			text={Strings.Buttons.CREATE_USER}
			size={Button.Size.TINY}
			icon="plus"
			classNames="createUserButton"
		/>
	);
}

export default LinkToCreateUserButton;
