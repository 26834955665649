import * as React from 'react';
import { css, cx } from 'emotion';

import Heading, { HeadingSize, HeadingProps } from '../Heading';
import Paragraph from '../Paragraph';
import BaseModal, { ModalVariationProps, ModalWidth } from './BaseModal';
import { borderRadius, mediaQueries } from 'styles';
import { CloseIconButton } from 'components/Buttons';
import { ButtonProps } from 'display';

interface SubcomponentProps {
	children?: React.ReactNode;
}

interface TitleProps {
	size?: HeadingProps['size'];
	onClose?: ButtonProps['onClick'];
}

const styles = {
	active: css`
		transform: translate3d(0, 0, 0);
	`,
	base: css`
		border-bottom-right-radius: ${borderRadius.l2};
		border-bottom-left-radius: ${borderRadius.l2};
		padding: 3rem 2.5rem 2.5rem;
		top: 0;
		transform: translate3d(0, -110%, 0);
		transition-duration: 0.5s;
		width: 100%;

		${mediaQueries.mobile} {
			padding: 3rem 1rem 2.5rem;
		}
	`,
	width: {
		[ModalWidth.NORMAL]: css`
			max-width: 480px;
		`,
		[ModalWidth.WIDE]: css`
			max-width: 800px;
		`,
		[ModalWidth.FULL]: css`
			max-width: 90%;
		`
	},
	description: css`
		margin-bottom: 1.5rem;
	`,
	title: css`
		margin-bottom: 1rem;
		display: flex;
		justify-content: space-between;
	`,
	titleHeader: css`
		margin-bottom: 0;
	`
};

/**
 * Modal for card-based content.
 */
function CardModal(props: ModalVariationProps): JSX.Element {
	const defaultWidth = ModalWidth.NORMAL;
	const modalWidth =
		props.width && Object.keys(styles.width).includes(props.width) ? props.width : defaultWidth;
	const widthStyles = styles.width[modalWidth];
	return (
		<BaseModal
			classNameActive={styles.active}
			classNameBase={cx(styles.base, widthStyles)}
			{...props}
		/>
	);
}

function Title({
	children,
	size = Heading.Size.T3,
	onClose
}: SubcomponentProps & TitleProps): JSX.Element {
	return (
		<div className={styles.title}>
			<Heading size={size} className={styles.titleHeader}>
				{children}
			</Heading>
			{onClose && <CloseIconButton onClick={onClose} inverted={false} />}
		</div>
	);
}

Title.Size = HeadingSize;

function Description({ children }: SubcomponentProps): JSX.Element {
	return (
		<div className={styles.description}>
			<Paragraph>{children}</Paragraph>
		</div>
	);
}

CardModal.Title = Title;
CardModal.Description = Description;

export default CardModal;
