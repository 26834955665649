import * as React from 'react';

function DeviceNotFound(): JSX.Element {
	return (
		<React.Fragment>
			No webcam and microphone were found. Make sure they are connected properly and that no
			other application is currently using them.
		</React.Fragment>
	);
}

export default DeviceNotFound;
