import User from 'components/admin/User/User';
import { Text } from 'display';
import { getUserFullNameAndTitle } from 'lib/userHelpers';
import React, { useRef } from 'react';

interface OriginalUserSelectionLabelProps {
	value: string | undefined;
}

function OriginalUserSelectionLabel({ value }: OriginalUserSelectionLabelProps): JSX.Element {
	const originalSelectionId = useRef(value);
	let selection: React.ReactNode = 'None';

	if (originalSelectionId.current) {
		selection = (
			<User userId={originalSelectionId.current} textFunc={getUserFullNameAndTitle} />
		);
	}

	return (
		<Text>
			Current (to be replaced): <strong>{selection}</strong>
		</Text>
	);
}

export default OriginalUserSelectionLabel;
