import { getCommonClient } from 'lib/ThriftClient';
import {
	UpdateClinicalReviewRequestCamel,
	UpdateClinicalReviewResponseCamel
} from 'thriftgen/thriftCamelTypes';
import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import {
	UpdateClinicalReviewRequest,
	UpdateClinicalReviewResponse,
	ClinicalReviewQuestion
} from 'thriftgen/api_types';

async function updateClinicalReview(
	params: UpdateClinicalReviewRequestCamel
): Promise<UpdateClinicalReviewResponseCamel> {
	const client = getCommonClient();
	const request = new UpdateClinicalReviewRequest(objectCamelToSnake(params));
	request.review.questions = request.review.questions.map(question => {
		return new ClinicalReviewQuestion(question);
	});
	const response: UpdateClinicalReviewResponse = await client.update_clinical_review(request);
	return objectSnakeToCamel(response);
}

export default updateClinicalReview;
