import * as React from 'react';

import { Message, Paragraph } from 'display';

interface UnsharingErrorMessageProps {
	message: string;
}

function UnsharingErrorMessage({ message }: UnsharingErrorMessageProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Unable to unshare message</Message.Header>
			<Paragraph>{message}</Paragraph>
		</Message>
	);
}

export default UnsharingErrorMessage;
