import { FilterField } from 'display';
import { Range } from 'thriftgen/api_types';

export default (): FilterField => {
	const requestDateFilter: FilterField = {
		label: 'Request Date',
		attribute: 'request_date',
		values: [
			{
				label: 'From',
				value: new Range({ range_start: undefined })
			},
			{
				label: 'To',
				value: new Range({ range_end: undefined })
			}
		]
	};

	return requestDateFilter;
};
