import * as React from 'react';

import { Message, Paragraph } from 'display';

interface PdfViewerLoadErrorProps {
	documentName: string;
}

function PdfViewerLoadError({ documentName }: PdfViewerLoadErrorProps): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Failed to load document</Message.Header>
			<Paragraph>
				There was en error trying to load &quot;{documentName}&quot;. Please try again or
				contact support at{' '}
				<a href="mailto:support@trustsitka.com">support@trustsitka.com</a> if you need help.
			</Paragraph>
		</Message>
	);
}

export default PdfViewerLoadError;
