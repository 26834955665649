import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { UpdatePasswordRequest, UpdatePasswordResponse } from 'thriftgen/api_types';
import { UpdatePasswordRequestCamel } from 'thriftgen/thriftCamelTypes';

function updatePassword(requestCamel: UpdatePasswordRequestCamel): Promise<UpdatePasswordResponse> {
	const client = getCommonClient();
	const request = new UpdatePasswordRequest(objectCamelToSnake(requestCamel));

	return client.update_password(request);
}

export default updatePassword;
