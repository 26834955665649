import { combineReducers } from 'redux';
import reducerRegistry from 'store/registry';

const reducerName = 'admin';
export const ACTION_TYPES = {
	SET_PARTNERS: `${reducerName}/setPartners`
};

export function partnersById(state = {}, action) {
	const byId = {};
	const partnerList = action.partners || [];
	partnerList.forEach(p => {
		byId[p.partner_id] = p;
	});
	return Object.assign({}, state, byId);
}

export function partnersReducer(
	state = {
		byId: {}
	},
	action
) {
	switch (action.type) {
		case ACTION_TYPES.SET_PARTNERS:
			return Object.assign({}, state, {
				byId: partnersById(state.byId, action)
			});
		default:
			return state;
	}
}

// TODO: Remove users once all of its parts are completely
// decoupled from store/admin
const adminReducer = combineReducers({
	partners: partnersReducer
});

reducerRegistry.register(reducerName, adminReducer);
