export enum META_ACTION_TYPES {
	SET_APP_STALE = 'meta/setAppStale'
}

export interface SetAppStale {
	type: META_ACTION_TYPES.SET_APP_STALE;
	stale: boolean;
}

export interface MetaState {
	isAppStale: boolean;
}

export type MetaActionTypes = SetAppStale;
