import withEventEmitter from 'components/withEvent/withEventEmitter';
import UploadContext from 'contexts/UploadContext';
import { isBrowserRecordingSupported } from 'lib/featureDetection';
import noop from 'lib/noop';
import { isMobile } from 'lib/userAgent';
import * as React from 'react';
import { EventType, VideoRecordingFatalError } from 'thriftgen/api_types';
import { SHOULD_USE_FALLBACK_RECORDER } from './helpers';
import RecorderFallbackLauncher from './RecorderFallbackLauncher';
import RecorderLoader from './RecorderLoader';
import { RecordingPreview } from './RecordingPreview';
import { RecorderStateMachineProps, RecordingOption } from './types';
import UnsupportedBrowserLauncher from './UnsupportedBrowserLauncher';

enum State {
	PREVIEWING,
	RECORD_WITH_BROWSER,
	RECORD_WITH_FALLBACK,
	RECORD_UNSUPPORTED
}

type RecorderState =
	| State.RECORD_WITH_BROWSER
	| State.RECORD_WITH_FALLBACK
	| State.RECORD_UNSUPPORTED;

interface RecorderStateMachineState {
	current: State;
	audioError: Error | null;
}

const IS_BROWSER_RECORDING_SUPPORTED: boolean = isBrowserRecordingSupported();

function getRecorderState(): RecorderState {
	if (IS_BROWSER_RECORDING_SUPPORTED) {
		return State.RECORD_WITH_BROWSER;
	}

	if (SHOULD_USE_FALLBACK_RECORDER) {
		return State.RECORD_WITH_FALLBACK;
	}

	return State.RECORD_UNSUPPORTED;
}

function getInitialState(props: RecorderStateMachineProps): State {
	const { video, hideVideoPreview } = props;

	if (video === null && !hideVideoPreview) {
		return getRecorderState();
	}

	return State.PREVIEWING;
}

class RecorderStateMachine extends React.Component<
	RecorderStateMachineProps,
	RecorderStateMachineState
> {
	public static defaultProps = {
		minimized: false,
		onRecordingStart: noop,
		canRecordScreen: false,
		recordingId: 'missing'
	};

	public static contextType = UploadContext;
	public context!: React.ContextType<typeof UploadContext>;

	constructor(props: RecorderStateMachineProps) {
		super(props);

		this.state = {
			current: getInitialState(props),
			audioError: null
		};

		this.onRecorderLaunch = this.onRecorderLaunch.bind(this);

		this.onRecordingData = this.onRecordingData.bind(this);
		this.onRecordingEnd = this.onRecordingEnd.bind(this);
		this.onRecordingFallbackVideo = this.onRecordingFallbackVideo.bind(this);
		this.onRecordingReject = this.onRecordingReject.bind(this);
		this.onRecordingStart = this.onRecordingStart.bind(this);
		this.onVideoError = this.onVideoError.bind(this);
		this.onUploadRetry = this.onUploadRetry.bind(this);
	}

	public render(): JSX.Element {
		const {
			minimized,
			onRecorderMinimize,
			recordingPreviewAction,
			canRecordScreen,
			video,
			hideVideoPreview
		} = this.props;

		const { current, audioError } = this.state;

		switch (current) {
			case State.PREVIEWING:
				return (
					<RecordingPreview
						hideVideoPreview={hideVideoPreview}
						onRecordingReject={this.onRecordingReject}
						onVideoError={this.onVideoError}
						video={video}
						audioError={audioError}
						recordingPreviewAction={recordingPreviewAction}
					/>
				);

			case State.RECORD_WITH_FALLBACK:
				return (
					<RecorderFallbackLauncher
						onRecordingFallbackVideo={this.onRecordingFallbackVideo}
					/>
				);

			case State.RECORD_WITH_BROWSER:
				return (
					<RecorderLoader
						onRecorderMinimize={onRecorderMinimize}
						onRecordingData={this.onRecordingData}
						onRecordingEnd={this.onRecordingEnd}
						onRecordingStart={this.onRecordingStart}
						canRecordScreen={canRecordScreen && !isMobile()}
						minimized={minimized}
					/>
				);

			case State.RECORD_UNSUPPORTED:
				return <UnsupportedBrowserLauncher />;
		}
	}

	private addUploaderListeners(): void {
		this.context.uploader.addUploadStartListener(
			this.emitEvent.bind(this, EventType.VIDEO_UPLOAD_START)
		);

		this.context.uploader.addUploadErrorListener(
			this.emitEvent.bind(this, EventType.VIDEO_UPLOAD_FAILED)
		);

		this.context.uploader.addUploadCompleteListener(
			this.emitEvent.bind(this, EventType.VIDEO_UPLOAD_END)
		);
	}

	private emitEvent(eventType: EventType, action: Record<string, unknown> = {}) {
		this.props.emitEvent({
			eventType,
			data: {
				...action,
				recordingId: this.props.recordingId
			}
		});
	}
	private goToState(stateEnum: State): void {
		this.setState(state => ({
			...state,
			current: stateEnum
		}));
	}

	private onRecordingData(slice: Blob): void {
		this.context.uploader.upload(slice);
	}

	private onVideoError(videoError: VideoRecordingFatalError, error?: Error | ErrorEvent): void {
		this.emitEvent(EventType.VIDEO_RECORDING_FATAL_ERROR, {
			errorType: videoError,
			errorMessage: error ? error.message : null
		});
	}

	private onRecordingEnd(video: Blob | null, audioError?: Error): void {
		this.context.uploader.end();
		this.emitEvent(EventType.VIDEO_RECORDING_END);
		if (audioError) {
			this.emitEvent(EventType.VIDEO_RECORDING_FATAL_ERROR, {
				errorType: VideoRecordingFatalError.NO_AUDIO_DETECTED,
				errorMessage: audioError.message
			});
		}
		this.setState(state => ({
			...state,
			audioError: audioError ? audioError : null
		}));
		if (video) {
			this.props.onRecordingComplete(video, this.props.recordingId);
		}
		this.goToState(State.PREVIEWING);
	}

	private onRecordingFallbackVideo(video: Blob, recordingOption: RecordingOption): void {
		this.onRecordingStart(recordingOption);
		this.onRecordingData(video);
		this.onRecordingEnd(video);
	}

	private onRecorderLaunch(): void {
		this.goToState(State.RECORD_WITH_BROWSER);
	}

	private onRecordingReject(_event: React.SyntheticEvent): void {
		const nextState = getRecorderState();

		this.emitEvent(EventType.VIDEO_RECORDING_REJECTED);

		this.props.onRecordingReject();
		this.goToState(nextState);
	}

	private onRecordingStart(recordingOption: RecordingOption): void {
		this.addUploaderListeners();
		this.emitEvent(EventType.VIDEO_RECORDING_START, {
			recordingOption,
			canRecordScreen: this.props.canRecordScreen
		});

		this.props.onRecordingStart();
	}

	private onUploadRetry(): void {
		this.emitEvent(EventType.VIDEO_UPLOAD_RETRY);
	}
}

export default withEventEmitter(RecorderStateMachine);
