const VIEWER_ELEMENTS_TO_DISABLE = [
	// Disable page thumbnail control (to prevent page deletion)
	'thumbnailControl',
	// Disable Annotations buttons
	'ribbons',
	// Disable side comments section
	'notesPanel',
	'notesPanelResizeBar',
	// Disable the popup that appears on right clicking a document
	'contextMenuPopup',
	// Disable toggle button for notes section
	'toggleNotesButton',
	// Disable text/tools header
	'toolsHeader'
];

const VIEWER_FEATURES_TO_DISABLE = [
	// Disable thumbnail reorder (to prevent page reordering)
	'ThumbnailReordering',
	// Disable thumbnail merging
	'ThumbnailMerging'
];

export { VIEWER_ELEMENTS_TO_DISABLE, VIEWER_FEATURES_TO_DISABLE };
