import * as React from 'react';

import { buildNameValidator, FullName } from './FullName';
import FirstName from './FirstName';
import LastName from './LastName';
import Title from './Title';

const FIELD_NAME = 'userActorFullName';

function UserActorFullName(props) {
	return (
		<FullName
			{...props}
			fieldName={FIELD_NAME}
			FirstName={FirstName}
			LastName={LastName}
			Title={Title}
		/>
	);
}

UserActorFullName.FIELD_NAME = FIELD_NAME;
UserActorFullName.SUB_FIELD_NAMES = [FirstName.FIELD_NAME, LastName.FIELD_NAME, Title.FIELD_NAME];
UserActorFullName.validate = buildNameValidator(FirstName.FIELD_NAME, LastName.FIELD_NAME);

export default UserActorFullName;
