import * as React from 'react';
import { css } from 'emotion';

import { FaceVideoLoadError } from 'components/Messages';
import { Video } from 'display';
import { colors, hexToRgba } from 'styles';

import { RecordingStatus } from './types';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

interface FaceVideoProps {
	stream: MediaStream | null;
	status: RecordingStatus;
}

interface FaceVideoState {
	error: boolean;
}

const styles = {
	overlay: css`
		background-color: ${hexToRgba(colors.neutral.base, 0.75)};
		bottom: 0;
		display: block;
		left: 0;
		opacity: 0;
		position: absolute;
		right: 0;
		top: 0;
		will-change: opacity;
	`
};

class FaceVideo extends React.PureComponent<FaceVideoProps, FaceVideoState> {
	private video: HTMLVideoElement | null;
	constructor(props: FaceVideoProps) {
		super(props);

		this.setVideoPlayback = this.setVideoPlayback.bind(this);
		this.setVideoRef = this.setVideoRef.bind(this);

		this.video = null;

		this.state = {
			error: false
		};

		this.setError = this.setError.bind(this);
	}

	public componentDidMount(): void {
		this.setVideoPlayback();
	}

	public componentDidUpdate(prevProps: FaceVideoProps): void {
		if (prevProps.stream !== this.props.stream) {
			this.setVideoPlayback();
		}
	}

	public render(): JSX.Element {
		const { error } = this.state;
		const { status } = this.props;
		const isPaused = status === RecordingStatus.PAUSED;

		if (error) {
			return <FaceVideoLoadError />;
		}

		return (
			<React.Fragment>
				<div className={styles.overlay} style={{ opacity: isPaused ? 1 : undefined }} />
				<Video controls={false} setRef={this.setVideoRef} muted={true} />
			</React.Fragment>
		);
	}

	private setError(): void {
		this.setState(() => ({
			error: true
		}));
		SitkaLogger.logMessage('Failed to load FaceVideo video playback.', LogEventName.VIDEO);
	}

	private setVideoPlayback(): void {
		const { stream } = this.props;

		if (this.video && stream) {
			this.video.srcObject = stream;
			this.video.onloadedmetadata = () => {
				if (this.video) {
					this.video.play();
				} else {
					this.setError();
				}
			};

			this.video.onerror = this.setError;
		} else {
			this.setError();
		}
	}

	private setVideoRef(node: HTMLVideoElement): void {
		this.video = node;
	}
}

export default FaceVideo;
