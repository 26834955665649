import { CanonicalMessage, ConversationMessageComposite } from 'hooks';

function getCanonicalMessage(message: ConversationMessageComposite): CanonicalMessage {
	if (message.sharedFrom) {
		return message.sharedFrom;
	}

	return message;
}

function getMessageContent(message: ConversationMessageComposite): CanonicalMessage['content'] {
	const canonicalMessage = getCanonicalMessage(message);
	return canonicalMessage.content;
}

function isTextMessage(message: ConversationMessageComposite): boolean {
	const { messageType } = getCanonicalMessage(message);
	return messageType === 'text';
}

function isVideoMessage(message: ConversationMessageComposite): boolean {
	const { messageType } = getCanonicalMessage(message);
	return messageType === 'video';
}

export { getCanonicalMessage, getMessageContent, isTextMessage, isVideoMessage };
