import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent } from '../DataFormTypes';
import { getRoleReadableForm, Role } from 'lib/userHelpers';

const FIELD_NAME = 'isSitkaOperations';
const LABEL = getRoleReadableForm(Role.SITKA_OPERATIONS);
const SitkaOpsField: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default SitkaOpsField;
