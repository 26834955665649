export function getUniqueElements(array, key) {
	const elementDict = {};

	array.forEach(element => {
		if (key) {
			elementDict[element[key]] = element;
		} else {
			elementDict[element] = element;
		}
	});

	return Object.values(elementDict);
}
