import Link from 'components/Link';
import { getPatientName } from 'lib/userHelpers';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { User } from 'thriftgen/api_types';
import { CaseCamel } from 'thriftgen/thriftCamelTypes';

interface LinkToPatientCaseButtonProps {
	caseId: CaseCamel['caseId'];
	patient: User;
}

function LinkToPatientCaseButton({ caseId, patient }: LinkToPatientCaseButtonProps): JSX.Element {
	return (
		<Link to={RouteUrls.buildUrl(RouteUrls.Key.CASE_DETAIL, { caseId })}>
			<span data-testid="linkToPatientCaseButton">{getPatientName(patient)}</span>
		</Link>
	);
}

export default LinkToPatientCaseButton;
