import * as React from 'react';
import { css } from 'emotion';

import { borderRadius, mediaQueries } from 'styles';
import BaseModal, { ModalVariationProps } from './BaseModal';
import Button from '../shims/Button';
import Paragraph from '../Paragraph';

interface SubcomponentProps {
	children: React.ReactNode;
}

const styles = {
	actions: css`
		margin-top: 1.5rem;
	`,
	base: css`
		border-radius: ${borderRadius.l2};
		max-width: 400px;
		padding: 2rem 2.5rem;
		transform: scale(0.5);
		width: 100%;
		bottom: 50%;

		${mediaQueries.mobile} {
			bottom: 2rem;
			margin: 0 1rem;
			padding: 1.5rem 1rem 1rem;
			width: auto;
		}
	`,
	active: css`
		transform: scale(1);
	`
};
/**
 * Modal for confirmation dialogs
 */
function DialogModal(props: ModalVariationProps): JSX.Element {
	return <BaseModal classNameActive={styles.active} classNameBase={styles.base} {...props} />;
}

function Description({ children }: SubcomponentProps): JSX.Element {
	return <Paragraph size={Paragraph.Size.T2}>{children}</Paragraph>;
}

function Actions({ children }: SubcomponentProps): JSX.Element {
	return (
		<div className={styles.actions}>
			<Button.InlineGroup justifyContent="space-between">{children}</Button.InlineGroup>
		</div>
	);
}

DialogModal.Actions = Actions;
DialogModal.Description = Description;

export default DialogModal;
