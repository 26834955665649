import * as React from 'react';

import { css, cx } from 'emotion';
import { fontSize, mediaQueries } from 'styles';

const styles = {
	sizes: {
		t1: css`
			font-size: ${fontSize[6]};

			${mediaQueries.mobile} {
				font-size: ${fontSize[5]};
			}
		`,
		t2: css`
			font-size: ${fontSize[5]};

			${mediaQueries.mobile} {
				font-size: ${fontSize[4]};
			}
		`,
		t3: css`
			font-size: ${fontSize[4]};

			${mediaQueries.mobile} {
				font-size: ${fontSize[3]};
			}
		`,
		t4: css`
			font-size: ${fontSize[3]};
			line-height: 1.44;

			${mediaQueries.mobile} {
				font-size: ${fontSize[2]};
			}
		`,
		t5: css`
			font-size: ${fontSize[2]};

			${mediaQueries.mobile} {
				font-size: ${fontSize[1]};
			}
		`
	}
};

enum HeadingSize {
	T1 = 't1',
	T2 = 't2',
	T3 = 't3',
	T4 = 't4',
	T5 = 't5'
}

enum HeadingType {
	H1 = 'h1',
	H2 = 'h2',
	H3 = 'h3',
	H4 = 'h4',
	H5 = 'h5',
	H6 = 'h6'
}

interface HeadingProps {
	as: HeadingType;
	children: React.ReactNode;
	className?: string;
	size: HeadingSize;
}

function Heading({ as: Element, children, className, size }: HeadingProps): JSX.Element {
	return <Element className={cx(styles.sizes[size], className)}>{children}</Element>;
}

Heading.Type = HeadingType;
Heading.Size = HeadingSize;

Heading.defaultProps = {
	as: HeadingType.H1,
	className: undefined,
	size: HeadingSize.T1
};
export default Heading;
export { HeadingProps, HeadingSize, HeadingType };
