import * as React from 'react';
import PropTypes from 'prop-types';

import RouteUrls from 'routing/RouteUrls';

import { Icon } from 'display';
import Link from 'components/Link';
import Strings from 'lib/strings';

function ReturnToConsultButton({ consultId }) {
	const url = RouteUrls.buildUrl(RouteUrls.Key.CONSULT_DETAIL, { consultId });

	return (
		<Link to={url}>
			<Icon name="left arrow" />
			{` ${Strings.Buttons.RETURN_TO_CONSULT}`}
		</Link>
	);
}

ReturnToConsultButton.propTypes = {
	consultId: PropTypes.string.isRequired
};

export default ReturnToConsultButton;
