import * as React from 'react';

import { IllustrationProps } from './illustrationTypes';
import { colors } from 'styles';

function SitkaLogo({
	fill = colors.primary.base
}: {
	fill?: IllustrationProps['fill'];
}): JSX.Element {
	return (
		<svg data-testid="sitkaLogo" viewBox="0 0 67 23">
			<mask id="a" fill="#fff">
				<path
					d="m.00007528.15926222h14.15707492v16.45184888h-14.15707492z"
					fill="#fff"
					fillRule="evenodd"
				/>
			</mask>
			<mask id="b" fill="#fff">
				<path
					d="m.02301086.25018889h4.61145693v22.19517891h-4.61145693z"
					fill="#fff"
					fillRule="evenodd"
				/>
			</mask>
			<mask id="c" fill="#fff">
				<path
					d="m.05814195.25018889h15.53772655v22.19515331h-15.53772655z"
					fill="#fff"
					fillRule="evenodd"
				/>
			</mask>
			<g fill={fill} fillRule="evenodd">
				<path
					d="m7.22528502 2.97088444c-.74427715 0-1.36107865.1449-1.8504045.43367778-.48957677.28954445-.7342397.68335556-.7342397 1.18168889 0 .27957778.11241948.5336.33801124.76283333.22458801.22948889.66071536.42396667 1.3068764.58317778l3.37785394.83745556c1.5465206.37924444 2.6822584.98261111 3.4069625 1.80984444.7242023.828 1.0868053 1.79987778 1.0868053 2.91639998 0 1.5755-.6461611 2.8221-1.9387341 3.7390334-1.2920712.9174444-2.98614232 1.3761666-5.08120973 1.3761666-1.68403371 0-3.14272659-.3036-4.37632959-.9123333-1.233603-.6082222-2.10510487-1.4009556-2.61400375-2.3782-.09836704-.1392778-.14679775-.2693556-.14679775-.3887 0-.1392778.06825468-.2394556.20551686-.2992556l1.99720224-1.0467555c.15633334-.0797334.29359551-.1198556.41128465-.1198556.1174382 0 .22508988.0500889.32295505.1495.43060674.6184445.95481274 1.1121778 1.57161423 1.4806889.6168015.3692778 1.43410113.5535333 2.45240075.5535333.92018352 0 1.66922847-.1392777 2.2468839-.4188555.57740449-.2790667.86648314-.6879556.86648314-1.2264111 0-.3590556-.14679775-.6580556-.44039325-.8972556-.29384645-.2394555-.79320974-.4487555-1.49808989-.6281555l-3.02528839-.65805559c-2.97660674-.65831111-4.46465918-2.16353333-4.46465918-4.51694444 0-.99692223.26925469-1.88446667.80776405-2.66212223.53850936-.77765555 1.30712734-1.38587777 2.30560299-1.82466666.9987266-.43853334 2.17386143-.65805556 3.52465169-.65805556 1.42933333 0 2.69731461.26935556 3.80394378.80755556 1.1058764.53845555 1.903854 1.2167 2.3936817 2.03396666.1174382.15997778.1761573.29925556.1761573.41885556 0 .11985556-.0690075.21952222-.2057678.29925556l-2.1143895 1.04675555c-.05897.04037778-.1372622.06005556-.2351274.06005556-.1761573 0-.3232059-.07973334-.4406441-.23945556-.80324724-1.07691111-1.94852065-1.61536667-3.43657308-1.61536667"
					mask="url(#a)"
					transform="translate(0 6.138649)"
				/>
				<path
					d="m4.57574869 21.7272567c0 .2596444-.0587191.4441555-.1761573.5535333-.11743821.1098889-.32295506.1645778-.6168015.1645778h-3.08400749c-.23512734 0-.40174907-.0495778-.49911236-.1495-.09836704-.0996667-.14704869-.2591334-.14704869-.4786556v-14.65713331c0-.37847778.16611985-.56861111.49936329-.56861111h3.58311985c.29384645 0 .4406442.16968889.4406442.50881111zm.0587191-17.79791114c0 .49884444-.26423595.74775555-.7929588.74775555h-3.17208614c-.23512734 0-.40149813-.05443333-.4993633-.16432222-.0981161-.10937778-.14704869-.28417778-.14704869-.52337778v-3.14077778c0-.39892222.16637079-.59851111.49961423-.59851111h3.64183895c.13701124 0 .24968165.05008889.3377603.14975556.08832959.10017778.13224345.2392.13224345.41885555z"
					mask="url(#b)"
					transform="translate(16.310861 .005316)"
				/>
				<path d="m30.4859034 9.76764c-.2155543 0-.3229551.11985556-.3229551.3590556v7.2087111c0 .6785.1563333 1.1620111.4697528 1.4507889.3131686.2895444.8122809.4339333 1.4980899.4339333h1.5859176c.1370113 0 .2446629.0447222.323206.1346778.0780412.0894444.1174382.2144111.1174382.3738777l-.0293595 1.8244112c0 .3991777-.2158053.6386333-.646412.7181111-.8220674.1196-1.9874158.1794-3.4950412.1794-1.3706143 0-2.4378465-.3289-3.2014457-.9869556-.7638502-.6583111-1.1452734-1.6156222-1.1452734-2.8716778v-8.3755777c0-.2992556-.1277266-.4487556-.3821761-.4487556h-1.7916853c-.3721386 0-.5578315-.16917778-.5578315-.5083v-2.21362222c0-.299.1761573-.44875556.5284719-.44875556h2.0265618c.1565843 0 .2544494-.10937778.2938464-.3289l.4112847-4.54658889c.0388951-.33912222.1856929-.50881111.4406442-.50881111h3.2014457c.1367603 0 .2496816.05034445.3377603.1495.0880786.10017778.1322434.23945556.1322434.41885556v4.48704444c0 .19958889.0976142.29925556.2938465.29925556h3.0840074c.3520637 0 .528472.15972222.528472.4784v2.21362222c0 .33912222-.1959813.5083-.587442.5083z" />
				<path
					d="m15.5958685 22.0264867c0 .1196-.0441648.2195222-.1322434.2992555-.0880787.0797334-.2105356.1196-.3671199.1196h-3.8476067c-.3332434 0-.5977303-.2095555-.7929588-.6281555l-3.02553933-6.1622111c-.07854307-.1794-.16662172-.2688445-.26448689-.2688445-.05846816 0-.13701123.0498333-.23462547.1495l-2.08553183 2.0937667c-.1761573.1397889-.26448689.3887-.26448689.7480111v3.3498222c0 .2596445-.0587191.4441556-.1761573.5535333-.11718727.1098889-.31341948.1645778-.58744195.1645778h-3.11336704c-.23487641 0-.40149813-.0495778-.4993633-.1495-.0981161-.0996666-.14679775-.2591333-.14679775-.4786555v-20.99848892c0-.37873334.15658427-.56861111.47000374-.56861111h3.61298128c.29334457 0 .44014232.17965555.44014232.53871111v12.26411112c0 .1597222.03914607.2392.11768914.2392.0780412 0 .15633333-.0495778.2348764-.1497556l5.52210487-6.10215552c.2938464-.299.5578315-.4485.7929588-.4485h3.1424757c.3327415 0 .4993633.10988889.4993633.32915555 0 .13953334-.0687566.27932223-.2055169.4186l-4.1120936 4.24758887c-.1171873.1397889-.1761573.2691-.1761573.3889556 0 .1397889.039146.2892889.1174382.4487555l5.0227415 9.3323778c.0388952.0600556.0587191.1497556.0587191.2693556"
					mask="url(#c)"
					transform="translate(36.385768 .005316)"
				/>
				<path d="m62.3822697 15.0022867c-.0587191-.0794778-.1761573-.1196-.3523146-.1196l-.8225693.0600555c-1.1357379.0595445-2.1397341.3340111-3.0107341.8223778-.871251.4888778-1.3068764 1.1617556-1.3068764 2.0191444 0 .5985112.2057678 1.0720556.6165505 1.4208889.4112847.3490889.9791536.5233778 1.7036068.5233778.9006105 0 1.6642097-.2693555 2.2910487-.8075555.646161-.5182667.969367-1.0470112.969367-1.5854667v-1.9442667c0-.1794-.0296105-.3089666-.0880786-.3889555m-5.1108203 7.7474222c-1.4684794 0-2.6335767-.4040333-3.4950412-1.2113333-.8619663-.8078112-1.2923221-1.8594223-1.2923221-3.1556 0-1.6754223.7535618-3.0214334 2.2614382-4.0382889 1.5076255-1.0173667 3.5833708-1.5555667 6.2267341-1.6156223l1.0576967-.0894444c.1171872 0 .2200711-.0347556.3081498-.1047778.0883296-.0697666.1322434-.1745444.1322434-.3143333v-.6279c0-.7779111-.2158052-1.3761667-.646161-1.79476668-.4308577-.41885555-1.0476592-.62815555-1.8504045-.62815555-1.3708652 0-2.3891648.59825555-3.054648 1.79476663-.078543.1993334-.2057678.299-.3819251.299-.098367 0-.1861947-.0097111-.2642359-.0299l-2.9372098-.6877c-.1761573-.0401222-.2642359-.1497555-.2642359-.3294111 0-.1193444.0388951-.26909998.1171873-.44849998.4700037-1.15638889 1.3071273-2.02374444 2.5113707-2.60232222 1.2042435-.57832222 2.6973146-.86761111 4.4794645-.86761111 2.3299438 0 4.0137266.43393333 5.0518501 1.30128889 1.0373708.86735555 1.5565581 2.09887778 1.5565581 3.69405552v10.4992445c0 .2394555-.0396479.4091444-.1174382.5088111-.0785431.0999222-.2255918.1495-.4406442.1495h-3.0252884c-.1959813 0-.3377603-.0697667-.4258389-.2095556-.0880787-.1392777-.1518165-.3485777-.1909626-.6281555l-.0293595-.6580556c-.019824-.1794-.0981161-.2693555-.2348764-.2693555-.0785431 0-.2253409.0802444-.4406442.2394555-1.2923221 1.2167-2.829809 1.8246667-4.611457 1.8246667" />
			</g>
		</svg>
	);
}

export default SitkaLogo;
