import { ButtonType } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Consult } from 'thriftgen/api_types';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

interface LinkToSharedConsultButtonProps {
	consultId: Consult['consult_id'];
}

function LinkToSharedConsultButton({ consultId }: LinkToSharedConsultButtonProps): JSX.Element {
	const link = RouteUrls.buildUrl(RouteUrls.Key.CONSULT_SHARED_MESSAGES, { consultId });
	return (
		<LinkButton
			buttonType={ButtonType.SECONDARY}
			link={link}
			text={Strings.Buttons.VIEW}
			classNames="sharedConsultButton"></LinkButton>
	);
}

export default LinkToSharedConsultButton;
