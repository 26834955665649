import * as React from 'react';
import { Anchor, Button } from 'display';
import Strings from 'lib/strings';

const CHROME_INSTALL_URL = 'https://www.google.com/chrome/';

function InstallChromeButton(): JSX.Element {
	return (
		<Anchor href={CHROME_INSTALL_URL}>
			<Button.TextPrimary>{Strings.Buttons.GOOGLE_CHROME}</Button.TextPrimary>
		</Anchor>
	);
}

export default InstallChromeButton;
