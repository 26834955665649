//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = module.exports = {};
ttypes.i18n = {
  'ENGLISH' : 1
};
ttypes.ErrorCode = {
  'AUTH_SESSION_EXPIRED' : 1,
  'AUTH_SESSION_REVOKED' : 2,
  'USER_INACTIVE' : 3
};
ttypes.Specialty = {
  'ORTHOPEDICS' : 1,
  'ENDOCRINOLOGY' : 2,
  'RHEUMATOLOGY' : 3,
  'CARDIOLOGY' : 4,
  'ALLERGY' : 5,
  'PHARMACY' : 6,
  'DENTISTRY' : 7,
  'DERMATOLOGY' : 8,
  'EMERGENCY_MEDICINE' : 9,
  'FUNCTIONAL_MEDICINE' : 10,
  'GASTROENTEROLOGY' : 11,
  'GERIATRIC_MEDICINE' : 12,
  'HEMATOLOGY_ONCOLOGY' : 13,
  'INFECTIOUS_DISEASES' : 14,
  'INTEGRATIVE_HOLISTIC_MEDICINE' : 15,
  'NEPHROLOGY' : 16,
  'NEUROLOGY' : 17,
  'NEUROSURGERY' : 18,
  'NUTRITION' : 19,
  'OBESITY_MEDICINE' : 20,
  'OBSTETRICS_GYNECOLOGY' : 21,
  'OCCUPATIONAL_MEDICINE' : 22,
  'OPHTHALMOLOGY' : 23,
  'OTOLARYNGOLOGY' : 24,
  'PAIN_MEDICINE' : 25,
  'PALLIATIVE_CARE' : 26,
  'PATHOLOGY' : 27,
  'PLASTIC_SURGERY' : 28,
  'PHYSICAL_MEDICINE_AND_REHABILITATION' : 29,
  'PSYCHIATRY' : 30,
  'PSYCHOLOGY' : 31,
  'PULMONOLOGY' : 32,
  'SLEEP_MEDICINE' : 33,
  'SURGERY' : 34,
  'TRANSGENDER_AND_GENDER_NON_CONFORMING_HEALTH' : 35,
  'UROLOGY' : 36,
  'WOMENS_HEALTH' : 37,
  'UNLISTED' : 38,
  'DIABETES_EDUCATION' : 39,
  'WOUND_VASCULAR' : 40,
  'PODIATRY' : 41,
  'FASTTRACK_PSYCHIATRY' : 42,
  'PEDIATRICS' : 43,
  'FOOT_AND_ANKLE_CARE' : 44
};
ttypes.Sex = {
  'MALE' : 1,
  'FEMALE' : 2
};
ttypes.AuthSessionStatus = {
  'ACTIVE' : 1,
  'EXPIRED' : 2,
  'REVOKED' : 3
};
ttypes.ClinicalReviewYesNoAnswer = {
  'YES' : 1,
  'NO' : 2,
  'NA' : 3
};
ttypes.ClinicalReviewAnswerType = {
  'YES_NO' : 1,
  'YES_NO_NA' : 2,
  'SCALE' : 3
};
ttypes.ConsultType = {
  'DEFAULT' : 1,
  'TEST' : 2
};
ttypes.ConsultDataType = {
  'VIDEO' : 1,
  'IMAGE' : 2,
  'DOCUMENT' : 3,
  'OTHER' : 4,
  'ZIPFILE' : 5,
  'TEXT' : 6,
  'SPECIALIST_RESPONSE' : 8
};
ttypes.ConsultDataState = {
  'UPLOADING' : 1,
  'PROCESSING' : 2,
  'READY' : 3,
  'FAILED' : 4
};
ttypes.ConsultState = {
  'SUBMITTED' : 1,
  'ACTIVE' : 2,
  'CLOSED_INAPPROPRIATE_REFERRAL' : 3,
  'CLOSED_INCOMPLETE_REFERRAL' : 4,
  'CLOSED_DUPLICATE_REFERRAL' : 5,
  'CLOSED' : 6,
  'PENDING_REQUESTOR_ACTION' : 8,
  'CLOSED_PCP_INITIATED' : 9,
  'RESPONDED' : 10,
  'DRAFT' : 11,
  'DRAFT_DELETED' : 12,
  'AWAITING_SPECIALIST_RESPONSE' : 13,
  'UNDER_REVIEW' : 14
};
ttypes.ConsultStateSimplified = {
  'DRAFT' : 1,
  'NOT_RESPONDED' : 2,
  'RESPONDED' : 3,
  'PENDING_REQUESTOR_ACTION' : 4,
  'CLOSED' : 5
};
ttypes.DicomStudyType = {
  'DICOM' : 1,
  'PDF' : 2
};
ttypes.EventType = {
  'CREATION' : 1,
  'UPDATE' : 2,
  'STATE_TRANSITION' : 3,
  'CLINICAL_DATA_ACCESSED' : 4,
  'NOTIFICATION_SENT' : 5,
  'LOGIN_FAILED' : 6,
  'LOGIN_SUCCESS' : 7,
  'CLINICAL_DATA_PUBLIC_URL_ACCESSED' : 8,
  'TOKEN_CHECKED' : 9,
  'TOKEN_REDEEMED' : 10,
  'TOKEN_REISSUED' : 11,
  'CLINICAL_VIDEO_STARTED' : 12,
  'CLINICAL_VIDEO_COMPLETED' : 13,
  'CLINICAL_VIDEO_FAILED' : 14,
  'VIDEO_RECORDING_START' : 15,
  'VIDEO_RECORDING_END' : 16,
  'VIDEO_UPLOAD_START' : 17,
  'VIDEO_UPLOAD_END' : 18,
  'VIDEO_UPLOAD_FAILED' : 19,
  'VIDEO_UPLOAD_RETRY' : 20,
  'VIDEO_RECORDING_REJECTED' : 21,
  'DATA_VIEWED' : 22,
  'DATA_VIEW_FAILED' : 23,
  'DATA_VIEW_COMPLETED' : 24,
  'TOKEN_REVOKED' : 25,
  'VERIFIED' : 26,
  'CREATED_BY_CONSULT_PATIENT_MIGRATION' : 27,
  'SHARED_PATIENT_DATA' : 28,
  'LOGOUT' : 29,
  'MESSAGE_POSTED' : 30,
  'LINK_SHARED_WITH_PATIENT' : 31,
  'VIDEO_PAUSED' : 32,
  'VIDEO_SEEKED' : 33,
  'VIDEO_HEARTBEAT' : 34,
  'VIDEO_PLAYED' : 35,
  'CONNECTION_CREATED' : 36,
  'CONNECTION_DESTROYED' : 37,
  'STREAM_PUBLISHED' : 38,
  'STREAM_DEPUBLISHED' : 39,
  'CONNECT_ERROR' : 40,
  'START_ERROR' : 41,
  'USER_DEACTIVATED' : 42,
  'USER_REACTIVATED' : 43,
  'CONSULT_REQUESTOR_ASSIGNED' : 44,
  'CONSULT_RESPONDER_ASSIGNED' : 45,
  'CONSULT_TIMING_EXCEPTION_CHANGED' : 46,
  'CONSULT_STATE_CHANGED' : 47,
  'CONSULT_SPECIALTY_CHANGED' : 48,
  'SOURCE_OF_MERGE' : 49,
  'TARGET_OF_MERGE' : 50,
  'CONSULT_TYPE_CHANGED' : 51,
  'CONSULT_PATIENT_UPDATED' : 52,
  'TRANSCODING' : 53,
  'VIDEO_RECORDING_FATAL_ERROR' : 54,
  'DATA_REDACTED' : 55,
  'DATA_UNREDACTED' : 56,
  'VERIFICATION_CODE_SENT' : 57,
  'UPLOAD_ERROR' : 58,
  'PARTNER_MEMBER_ADDED' : 59,
  'PARTNER_MEMBER_REMOVED' : 60,
  'PARTNER_MEMBER_ROLES_CHANGED' : 61,
  'PAGE_VIEW' : 62,
  'DICTATION_STARTED' : 63,
  'DICTATION_STOPPED' : 64
};
ttypes.IntegrationType = {
  'ATHENA' : 1
};
ttypes.ObjectType = {
  'USER' : 1,
  'PARTNER' : 2,
  'CONSULT' : 4,
  'CONSULT_DATA' : 5,
  'DICOM_STUDY' : 6,
  'TOKEN' : 7,
  'USER_LICENSE' : 8,
  'CASE' : 9,
  'CONVERSATION' : 10,
  'CONVERSATION_MESSAGE' : 11,
  'VIDEO_VISIT' : 12,
  'EMAIL' : 13,
  'CONSULT_SUMMARY' : 14
};
ttypes.CustomFilterId = {
  'USER_NAME' : 2,
  'ACTOR_OR_CONTRIBUTOR' : 3,
  'PATIENT_NAME' : 4,
  'STAFF_AT_PARTNER' : 6,
  'REQUESTOR_FOR_CONSULT' : 7,
  'NOT_ADMIN_AT_PARTNER' : 8,
  'NOT_MEMBER_AT_PARTNER' : 9,
  'NOT_PATIENT' : 10,
  'ASSIGNABLE_AS_PARTNER_MEMBER' : 11,
  'ASSIGNABLE_AS_PARTNER_ADMIN' : 12,
  'MEMBER_AT_PARTNER' : 13,
  'ADMIN_AT_PARTNER' : 14,
  'USER_PROFILE' : 15,
  'PATIENT_LAST_NAME_CASE_INSENSITIVE' : 16,
  'PATIENT_EXTERNAL_ID_CASE_INSENSITIVE' : 17,
  'PREVIOUS_CONSULTS_AT_CASE' : 18,
  'CONSULTS_BY_CLINICAL_REVIEW' : 19,
  'SMA_LICENSED_FOR_CONSULT' : 20,
  'SMA_DAILY_CONSULTS_LIMIT_AVAILABLE' : 21,
  'SMA_SCHEDULE_AVAILABLE_FOR_CONSULT' : 22,
  'CONSULT_REQUESTOR_CITY' : 23
};
ttypes.RedactedReason = {
  'UNKNOWN' : 1,
  'CLINICAL_ACCURACY' : 2,
  'HIPAA_RELATED_ISSUE' : 3,
  'OTHER_ERROR' : 4
};
ttypes.ReportDeliveryStrategy = {
  'PARTNER_ID' : 1,
  'PCP_CITY' : 2,
  'PATIENT_TREATMENT_STATE' : 3,
  'STATIC' : 4
};
ttypes.State = {
  'AL' : 2,
  'AK' : 3,
  'AZ' : 4,
  'AR' : 5,
  'CA' : 6,
  'CO' : 7,
  'CT' : 8,
  'DE' : 9,
  'DC' : 10,
  'FL' : 11,
  'GA' : 12,
  'HI' : 13,
  'ID' : 14,
  'IL' : 15,
  'IN' : 16,
  'IA' : 17,
  'KS' : 18,
  'KY' : 19,
  'LA' : 20,
  'ME' : 21,
  'MD' : 22,
  'MA' : 23,
  'MI' : 24,
  'MN' : 25,
  'MS' : 26,
  'MO' : 27,
  'MT' : 28,
  'NE' : 29,
  'NV' : 30,
  'NH' : 31,
  'NJ' : 32,
  'NM' : 33,
  'NY' : 34,
  'NC' : 35,
  'ND' : 36,
  'OH' : 37,
  'OK' : 38,
  'OR' : 39,
  'PA' : 40,
  'RI' : 41,
  'SC' : 42,
  'SD' : 43,
  'TN' : 44,
  'TX' : 45,
  'UT' : 46,
  'VT' : 47,
  'VA' : 48,
  'WA' : 49,
  'WV' : 50,
  'WI' : 51,
  'WY' : 52
};
ttypes.TokenState = {
  'ACTIVE' : 1,
  'REDEEMED' : 2,
  'EXPIRED' : 3,
  'REVOKED' : 4
};
ttypes.TokenType = {
  'EMAIL_VERIFICATION' : 1,
  'NEW_ACCOUNT' : 2,
  'PASSWORD_RESET' : 3,
  'NEW_ADMIN_ACCOUNT' : 4,
  'NEW_ACCOUNT_REVIEW_READY' : 5,
  'NEW_MEMBER_ACCOUNT' : 6,
  'NEW_SPECIALIST_ACCOUNT' : 7,
  'PATIENT_PASSWORDLESS_AUTHENTICATION' : 8,
  'SHARED_PATIENT_LINK' : 9
};
ttypes.ConsultMissingInfoReason = {
  'UNKNOWN_USER' : 1,
  'NO_VIDEO_OR_TEXT_REQUEST' : 2,
  'VIDEO_FAILED_TO_PROCESS' : 3,
  'POOR_AUDIO' : 4,
  'INSUFFICIENT_INFORMATION' : 5,
  'NO_IMAGES' : 6,
  'DOCUMENTS_FAILED_TO_PROCESS' : 7,
  'INAPPROPRIATE_REQUEST' : 8,
  'NO_CLEAR_CLINICAL_QUESTION' : 9,
  'MISSING_DOCUMENTS' : 10,
  'POOR_PHOTO_QUALITY_OR_UNABLE_TO_DECIPHER' : 11
};
ttypes.TranscodeState = {
  'START' : 1,
  'SUCCESS' : 2,
  'ERROR' : 3
};
ttypes.UserCredential = {
  'MD' : 1,
  'NP' : 2,
  'PHD' : 3,
  'OTHER' : 4,
  'DO' : 5,
  'DNP' : 6,
  'RN' : 7,
  'PT' : 8,
  'NON_CLINICAL' : 9,
  'OT' : 10,
  'LCSW' : 11,
  'DC' : 12,
  'PA' : 13
};
ttypes.UserTitle = {
  'NONE' : 1,
  'DR' : 2,
  'MR' : 3,
  'MRS' : 4,
  'MS' : 5
};
ttypes.SortDirection = {
  'ASCENDING' : 1,
  'DESCENDING' : 2
};
ttypes.VideoCallRoutingMode = {
  'RELAYED' : 1,
  'ROUTED' : 2
};
ttypes.VideoRecordingFatalError = {
  'UNKNOWN' : 1,
  'TOO_SHORT' : 2,
  'NO_AUDIO_DETECTED' : 3
};
ttypes.SpecialistResponseType = {
  'DIAGNOSTIC' : 1,
  'MANAGEMENT' : 2
};
ttypes.SpecialistResponseIntervention = {
  'MEDICATION_MANAGEMENT' : 1,
  'LABS' : 2,
  'ADDITIONAL_ASSESSMENT' : 5,
  'IMAGING' : 3,
  'PATIENT_EDUCATION' : 6,
  'PCP_IN_OFFICE_PROCEDURE' : 4,
  'THERAPY' : 9,
  'OTHER' : 10,
  'NO_TREATMENT_NECESSARY' : 11,
  'EMERGENCY_CARE' : 8,
  'REFER_OUT_TO_IN_PERSON_SPECIALIST' : 7,
  'NA' : 12
};
ttypes.SpecialistResponseRecommendation = {
  'MANAGE' : 1,
  'REFER' : 2,
  'MANAGE_AND_REFER' : 3,
  'MANAGE_ATTEMPT_AND_REFER' : 4
};
ttypes.ServiceType = {
  'S3' : 1,
  'TRANSCRIPTION' : 2
};
ttypes.LogoutReason = {
  'USER_INITIATED' : 1,
  'SESSION_EXPIRED' : 2
};
ttypes.CreateUserEmailOption = {
  'NONE' : 1,
  'NEW_USER_PASSWORD_SETUP' : 2
};
ttypes.ConsultSummaryStatus = {
  'UNSENT' : 1,
  'DELIVERED' : 2,
  'FAILED' : 3,
  'IN_TRANSIT' : 4,
  'MANUAL' : 5
};
ttypes.AthenaDocumentType = {
  'CLINICAL' : 1,
  'LAB_RESULT' : 2,
  'PROGRESS_NOTE' : 3
};
var DateOfBirth = module.exports.DateOfBirth = function(args) {
  this.day = null;
  this.month = null;
  this.year = null;
  if (args) {
    if (args.day !== undefined && args.day !== null) {
      this.day = args.day;
    }
    if (args.month !== undefined && args.month !== null) {
      this.month = args.month;
    }
    if (args.year !== undefined && args.year !== null) {
      this.year = args.year;
    }
  }
};
DateOfBirth.prototype = {};
DateOfBirth.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I16) {
        this.day = input.readI16();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I16) {
        this.month = input.readI16();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I16) {
        this.year = input.readI16();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DateOfBirth.prototype.write = function(output) {
  output.writeStructBegin('DateOfBirth');
  if (this.day !== null && this.day !== undefined) {
    output.writeFieldBegin('day', Thrift.Type.I16, 1);
    output.writeI16(this.day);
    output.writeFieldEnd();
  }
  if (this.month !== null && this.month !== undefined) {
    output.writeFieldBegin('month', Thrift.Type.I16, 2);
    output.writeI16(this.month);
    output.writeFieldEnd();
  }
  if (this.year !== null && this.year !== undefined) {
    output.writeFieldBegin('year', Thrift.Type.I16, 3);
    output.writeI16(this.year);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var InvalidRequest = module.exports.InvalidRequest = function(args) {
  Thrift.TException.call(this, "InvalidRequest");
  this.name = "InvalidRequest";
  this.message = null;
  this.error_code = null;
  if (args) {
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
    if (args.error_code !== undefined && args.error_code !== null) {
      this.error_code = args.error_code;
    }
  }
};
Thrift.inherits(InvalidRequest, Thrift.TException);
InvalidRequest.prototype.name = 'InvalidRequest';
InvalidRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.error_code = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

InvalidRequest.prototype.write = function(output) {
  output.writeStructBegin('InvalidRequest');
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 1);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  if (this.error_code !== null && this.error_code !== undefined) {
    output.writeFieldBegin('error_code', Thrift.Type.I32, 2);
    output.writeI32(this.error_code);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetPartnerSpecialtiesRequest = module.exports.GetPartnerSpecialtiesRequest = function(args) {
  this.healthjump_client_id = null;
  this.patient_state = null;
  if (args) {
    if (args.healthjump_client_id !== undefined && args.healthjump_client_id !== null) {
      this.healthjump_client_id = args.healthjump_client_id;
    }
    if (args.patient_state !== undefined && args.patient_state !== null) {
      this.patient_state = args.patient_state;
    }
  }
};
GetPartnerSpecialtiesRequest.prototype = {};
GetPartnerSpecialtiesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.healthjump_client_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.patient_state = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetPartnerSpecialtiesRequest.prototype.write = function(output) {
  output.writeStructBegin('GetPartnerSpecialtiesRequest');
  if (this.healthjump_client_id !== null && this.healthjump_client_id !== undefined) {
    output.writeFieldBegin('healthjump_client_id', Thrift.Type.STRING, 1);
    output.writeString(this.healthjump_client_id);
    output.writeFieldEnd();
  }
  if (this.patient_state !== null && this.patient_state !== undefined) {
    output.writeFieldBegin('patient_state', Thrift.Type.STRING, 2);
    output.writeString(this.patient_state);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetPartnerSpecialtiesResponse = module.exports.GetPartnerSpecialtiesResponse = function(args) {
  this.specialties = null;
  if (args) {
    if (args.specialties !== undefined && args.specialties !== null) {
      this.specialties = Thrift.copyList(args.specialties, [null]);
    }
  }
};
GetPartnerSpecialtiesResponse.prototype = {};
GetPartnerSpecialtiesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.specialties = [];
        var _rtmp31 = input.readListBegin();
        var _size0 = _rtmp31.size || 0;
        for (var _i2 = 0; _i2 < _size0; ++_i2) {
          var elem3 = null;
          elem3 = input.readI32();
          this.specialties.push(elem3);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetPartnerSpecialtiesResponse.prototype.write = function(output) {
  output.writeStructBegin('GetPartnerSpecialtiesResponse');
  if (this.specialties !== null && this.specialties !== undefined) {
    output.writeFieldBegin('specialties', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.I32, this.specialties.length);
    for (var iter4 in this.specialties) {
      if (this.specialties.hasOwnProperty(iter4)) {
        iter4 = this.specialties[iter4];
        output.writeI32(iter4);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PatientInfo = module.exports.PatientInfo = function(args) {
  this.first_name = null;
  this.last_name = null;
  this.date_of_birth = null;
  this.phone_number = null;
  this.email = null;
  this.medical_record_number = null;
  this.medicare_beneficiary_identifier = null;
  this.sex = null;
  if (args) {
    if (args.first_name !== undefined && args.first_name !== null) {
      this.first_name = args.first_name;
    }
    if (args.last_name !== undefined && args.last_name !== null) {
      this.last_name = args.last_name;
    }
    if (args.date_of_birth !== undefined && args.date_of_birth !== null) {
      this.date_of_birth = new ttypes.DateOfBirth(args.date_of_birth);
    }
    if (args.phone_number !== undefined && args.phone_number !== null) {
      this.phone_number = args.phone_number;
    }
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.medical_record_number !== undefined && args.medical_record_number !== null) {
      this.medical_record_number = args.medical_record_number;
    }
    if (args.medicare_beneficiary_identifier !== undefined && args.medicare_beneficiary_identifier !== null) {
      this.medicare_beneficiary_identifier = args.medicare_beneficiary_identifier;
    }
    if (args.sex !== undefined && args.sex !== null) {
      this.sex = args.sex;
    }
  }
};
PatientInfo.prototype = {};
PatientInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.first_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.last_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.date_of_birth = new ttypes.DateOfBirth();
        this.date_of_birth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.phone_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.medical_record_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.medicare_beneficiary_identifier = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.sex = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PatientInfo.prototype.write = function(output) {
  output.writeStructBegin('PatientInfo');
  if (this.first_name !== null && this.first_name !== undefined) {
    output.writeFieldBegin('first_name', Thrift.Type.STRING, 1);
    output.writeString(this.first_name);
    output.writeFieldEnd();
  }
  if (this.last_name !== null && this.last_name !== undefined) {
    output.writeFieldBegin('last_name', Thrift.Type.STRING, 2);
    output.writeString(this.last_name);
    output.writeFieldEnd();
  }
  if (this.date_of_birth !== null && this.date_of_birth !== undefined) {
    output.writeFieldBegin('date_of_birth', Thrift.Type.STRUCT, 3);
    this.date_of_birth.write(output);
    output.writeFieldEnd();
  }
  if (this.phone_number !== null && this.phone_number !== undefined) {
    output.writeFieldBegin('phone_number', Thrift.Type.STRING, 4);
    output.writeString(this.phone_number);
    output.writeFieldEnd();
  }
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 5);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.medical_record_number !== null && this.medical_record_number !== undefined) {
    output.writeFieldBegin('medical_record_number', Thrift.Type.STRING, 6);
    output.writeString(this.medical_record_number);
    output.writeFieldEnd();
  }
  if (this.medicare_beneficiary_identifier !== null && this.medicare_beneficiary_identifier !== undefined) {
    output.writeFieldBegin('medicare_beneficiary_identifier', Thrift.Type.STRING, 7);
    output.writeString(this.medicare_beneficiary_identifier);
    output.writeFieldEnd();
  }
  if (this.sex !== null && this.sex !== undefined) {
    output.writeFieldBegin('sex', Thrift.Type.STRING, 8);
    output.writeString(this.sex);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateConsultRequest = module.exports.CreateConsultRequest = function(args) {
  this.patient_info = null;
  this.healthjump_client_id = null;
  this.specialty = null;
  this.provider_id = null;
  this.patient_treatment_state = null;
  if (args) {
    if (args.patient_info !== undefined && args.patient_info !== null) {
      this.patient_info = new ttypes.PatientInfo(args.patient_info);
    }
    if (args.healthjump_client_id !== undefined && args.healthjump_client_id !== null) {
      this.healthjump_client_id = args.healthjump_client_id;
    }
    if (args.specialty !== undefined && args.specialty !== null) {
      this.specialty = args.specialty;
    }
    if (args.provider_id !== undefined && args.provider_id !== null) {
      this.provider_id = args.provider_id;
    }
    if (args.patient_treatment_state !== undefined && args.patient_treatment_state !== null) {
      this.patient_treatment_state = args.patient_treatment_state;
    }
  }
};
CreateConsultRequest.prototype = {};
CreateConsultRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.patient_info = new ttypes.PatientInfo();
        this.patient_info.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.healthjump_client_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.specialty = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.provider_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.patient_treatment_state = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateConsultRequest.prototype.write = function(output) {
  output.writeStructBegin('CreateConsultRequest');
  if (this.patient_info !== null && this.patient_info !== undefined) {
    output.writeFieldBegin('patient_info', Thrift.Type.STRUCT, 1);
    this.patient_info.write(output);
    output.writeFieldEnd();
  }
  if (this.healthjump_client_id !== null && this.healthjump_client_id !== undefined) {
    output.writeFieldBegin('healthjump_client_id', Thrift.Type.STRING, 2);
    output.writeString(this.healthjump_client_id);
    output.writeFieldEnd();
  }
  if (this.specialty !== null && this.specialty !== undefined) {
    output.writeFieldBegin('specialty', Thrift.Type.I32, 3);
    output.writeI32(this.specialty);
    output.writeFieldEnd();
  }
  if (this.provider_id !== null && this.provider_id !== undefined) {
    output.writeFieldBegin('provider_id', Thrift.Type.STRING, 4);
    output.writeString(this.provider_id);
    output.writeFieldEnd();
  }
  if (this.patient_treatment_state !== null && this.patient_treatment_state !== undefined) {
    output.writeFieldBegin('patient_treatment_state', Thrift.Type.STRING, 5);
    output.writeString(this.patient_treatment_state);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateConsultResponse = module.exports.CreateConsultResponse = function(args) {
  this.consult_url = null;
  if (args) {
    if (args.consult_url !== undefined && args.consult_url !== null) {
      this.consult_url = args.consult_url;
    }
  }
};
CreateConsultResponse.prototype = {};
CreateConsultResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateConsultResponse.prototype.write = function(output) {
  output.writeStructBegin('CreateConsultResponse');
  if (this.consult_url !== null && this.consult_url !== undefined) {
    output.writeFieldBegin('consult_url', Thrift.Type.STRING, 1);
    output.writeString(this.consult_url);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResourceMoved = module.exports.ResourceMoved = function(args) {
  Thrift.TException.call(this, "ResourceMoved");
  this.name = "ResourceMoved";
  this.canonical_object_id = null;
  this.object_type = null;
  this.message = null;
  if (args) {
    if (args.canonical_object_id !== undefined && args.canonical_object_id !== null) {
      this.canonical_object_id = args.canonical_object_id;
    }
    if (args.object_type !== undefined && args.object_type !== null) {
      this.object_type = args.object_type;
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = args.message;
    }
  }
};
Thrift.inherits(ResourceMoved, Thrift.TException);
ResourceMoved.prototype.name = 'ResourceMoved';
ResourceMoved.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.canonical_object_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.object_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResourceMoved.prototype.write = function(output) {
  output.writeStructBegin('ResourceMoved');
  if (this.canonical_object_id !== null && this.canonical_object_id !== undefined) {
    output.writeFieldBegin('canonical_object_id', Thrift.Type.STRING, 1);
    output.writeString(this.canonical_object_id);
    output.writeFieldEnd();
  }
  if (this.object_type !== null && this.object_type !== undefined) {
    output.writeFieldBegin('object_type', Thrift.Type.I32, 2);
    output.writeI32(this.object_type);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRING, 3);
    output.writeString(this.message);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var BoolMutation = module.exports.BoolMutation = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
  }
};
BoolMutation.prototype = {};
BoolMutation.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.BOOL) {
        this.value = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

BoolMutation.prototype.write = function(output) {
  output.writeStructBegin('BoolMutation');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.BOOL, 1);
    output.writeBool(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var IntMutation = module.exports.IntMutation = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
  }
};
IntMutation.prototype = {};
IntMutation.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.value = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntMutation.prototype.write = function(output) {
  output.writeStructBegin('IntMutation');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.I64, 1);
    output.writeI64(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var IntSetMutation = module.exports.IntSetMutation = function(args) {
  this.to_add = null;
  this.to_remove = null;
  if (args) {
    if (args.to_add !== undefined && args.to_add !== null) {
      this.to_add = Thrift.copyList(args.to_add, [null]);
    }
    if (args.to_remove !== undefined && args.to_remove !== null) {
      this.to_remove = Thrift.copyList(args.to_remove, [null]);
    }
  }
};
IntSetMutation.prototype = {};
IntSetMutation.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.to_add = [];
        var _rtmp36 = input.readListBegin();
        var _size5 = _rtmp36.size || 0;
        for (var _i7 = 0; _i7 < _size5; ++_i7) {
          var elem8 = null;
          elem8 = input.readI64();
          this.to_add.push(elem8);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.to_remove = [];
        var _rtmp310 = input.readListBegin();
        var _size9 = _rtmp310.size || 0;
        for (var _i11 = 0; _i11 < _size9; ++_i11) {
          var elem12 = null;
          elem12 = input.readI64();
          this.to_remove.push(elem12);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

IntSetMutation.prototype.write = function(output) {
  output.writeStructBegin('IntSetMutation');
  if (this.to_add !== null && this.to_add !== undefined) {
    output.writeFieldBegin('to_add', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.I64, this.to_add.length);
    for (var iter13 in this.to_add) {
      if (this.to_add.hasOwnProperty(iter13)) {
        iter13 = this.to_add[iter13];
        output.writeI64(iter13);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.to_remove !== null && this.to_remove !== undefined) {
    output.writeFieldBegin('to_remove', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.I64, this.to_remove.length);
    for (var iter14 in this.to_remove) {
      if (this.to_remove.hasOwnProperty(iter14)) {
        iter14 = this.to_remove[iter14];
        output.writeI64(iter14);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var StringMutation = module.exports.StringMutation = function(args) {
  this.value = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
  }
};
StringMutation.prototype = {};
StringMutation.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.value = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

StringMutation.prototype.write = function(output) {
  output.writeStructBegin('StringMutation');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.STRING, 1);
    output.writeString(this.value);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var StringSetMutation = module.exports.StringSetMutation = function(args) {
  this.to_add = null;
  this.to_remove = null;
  if (args) {
    if (args.to_add !== undefined && args.to_add !== null) {
      this.to_add = Thrift.copyList(args.to_add, [null]);
    }
    if (args.to_remove !== undefined && args.to_remove !== null) {
      this.to_remove = Thrift.copyList(args.to_remove, [null]);
    }
  }
};
StringSetMutation.prototype = {};
StringSetMutation.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.to_add = [];
        var _rtmp316 = input.readListBegin();
        var _size15 = _rtmp316.size || 0;
        for (var _i17 = 0; _i17 < _size15; ++_i17) {
          var elem18 = null;
          elem18 = input.readString();
          this.to_add.push(elem18);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.to_remove = [];
        var _rtmp320 = input.readListBegin();
        var _size19 = _rtmp320.size || 0;
        for (var _i21 = 0; _i21 < _size19; ++_i21) {
          var elem22 = null;
          elem22 = input.readString();
          this.to_remove.push(elem22);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

StringSetMutation.prototype.write = function(output) {
  output.writeStructBegin('StringSetMutation');
  if (this.to_add !== null && this.to_add !== undefined) {
    output.writeFieldBegin('to_add', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.to_add.length);
    for (var iter23 in this.to_add) {
      if (this.to_add.hasOwnProperty(iter23)) {
        iter23 = this.to_add[iter23];
        output.writeString(iter23);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.to_remove !== null && this.to_remove !== undefined) {
    output.writeFieldBegin('to_remove', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.to_remove.length);
    for (var iter24 in this.to_remove) {
      if (this.to_remove.hasOwnProperty(iter24)) {
        iter24 = this.to_remove[iter24];
        output.writeString(iter24);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TemporaryAwsCredentials = module.exports.TemporaryAwsCredentials = function(args) {
  this.access_key_id = null;
  this.secret_access_key = null;
  this.session_token = null;
  if (args) {
    if (args.access_key_id !== undefined && args.access_key_id !== null) {
      this.access_key_id = args.access_key_id;
    }
    if (args.secret_access_key !== undefined && args.secret_access_key !== null) {
      this.secret_access_key = args.secret_access_key;
    }
    if (args.session_token !== undefined && args.session_token !== null) {
      this.session_token = args.session_token;
    }
  }
};
TemporaryAwsCredentials.prototype = {};
TemporaryAwsCredentials.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.access_key_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.secret_access_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.session_token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TemporaryAwsCredentials.prototype.write = function(output) {
  output.writeStructBegin('TemporaryAwsCredentials');
  if (this.access_key_id !== null && this.access_key_id !== undefined) {
    output.writeFieldBegin('access_key_id', Thrift.Type.STRING, 1);
    output.writeString(this.access_key_id);
    output.writeFieldEnd();
  }
  if (this.secret_access_key !== null && this.secret_access_key !== undefined) {
    output.writeFieldBegin('secret_access_key', Thrift.Type.STRING, 2);
    output.writeString(this.secret_access_key);
    output.writeFieldEnd();
  }
  if (this.session_token !== null && this.session_token !== undefined) {
    output.writeFieldBegin('session_token', Thrift.Type.STRING, 3);
    output.writeString(this.session_token);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Case = module.exports.Case = function(args) {
  this.case_id = null;
  this.partner_id = null;
  this.patient_id = null;
  this.case_data = null;
  this.studies = null;
  this.conversations = null;
  this.patient_conversation_id = null;
  if (args) {
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.patient_id !== undefined && args.patient_id !== null) {
      this.patient_id = args.patient_id;
    }
    if (args.case_data !== undefined && args.case_data !== null) {
      this.case_data = Thrift.copyList(args.case_data, [null]);
    }
    if (args.studies !== undefined && args.studies !== null) {
      this.studies = Thrift.copyList(args.studies, [null]);
    }
    if (args.conversations !== undefined && args.conversations !== null) {
      this.conversations = Thrift.copyList(args.conversations, [null]);
    }
    if (args.patient_conversation_id !== undefined && args.patient_conversation_id !== null) {
      this.patient_conversation_id = args.patient_conversation_id;
    }
  }
};
Case.prototype = {};
Case.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.patient_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        this.case_data = [];
        var _rtmp326 = input.readListBegin();
        var _size25 = _rtmp326.size || 0;
        for (var _i27 = 0; _i27 < _size25; ++_i27) {
          var elem28 = null;
          elem28 = new ttypes.ConsultData();
          elem28.read(input);
          this.case_data.push(elem28);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        this.studies = [];
        var _rtmp330 = input.readListBegin();
        var _size29 = _rtmp330.size || 0;
        for (var _i31 = 0; _i31 < _size29; ++_i31) {
          var elem32 = null;
          elem32 = new ttypes.DicomStudy();
          elem32.read(input);
          this.studies.push(elem32);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        this.conversations = [];
        var _rtmp334 = input.readListBegin();
        var _size33 = _rtmp334.size || 0;
        for (var _i35 = 0; _i35 < _size33; ++_i35) {
          var elem36 = null;
          elem36 = new ttypes.Conversation();
          elem36.read(input);
          this.conversations.push(elem36);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.patient_conversation_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Case.prototype.write = function(output) {
  output.writeStructBegin('Case');
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 1);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 2);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.patient_id !== null && this.patient_id !== undefined) {
    output.writeFieldBegin('patient_id', Thrift.Type.STRING, 3);
    output.writeString(this.patient_id);
    output.writeFieldEnd();
  }
  if (this.case_data !== null && this.case_data !== undefined) {
    output.writeFieldBegin('case_data', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.case_data.length);
    for (var iter37 in this.case_data) {
      if (this.case_data.hasOwnProperty(iter37)) {
        iter37 = this.case_data[iter37];
        iter37.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.studies !== null && this.studies !== undefined) {
    output.writeFieldBegin('studies', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.studies.length);
    for (var iter38 in this.studies) {
      if (this.studies.hasOwnProperty(iter38)) {
        iter38 = this.studies[iter38];
        iter38.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.conversations !== null && this.conversations !== undefined) {
    output.writeFieldBegin('conversations', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.conversations.length);
    for (var iter39 in this.conversations) {
      if (this.conversations.hasOwnProperty(iter39)) {
        iter39 = this.conversations[iter39];
        iter39.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.patient_conversation_id !== null && this.patient_conversation_id !== undefined) {
    output.writeFieldBegin('patient_conversation_id', Thrift.Type.STRING, 8);
    output.writeString(this.patient_conversation_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CaseMutations = module.exports.CaseMutations = function(args) {
  this.partner_id = null;
  this.patient_id = null;
  if (args) {
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = new ttypes.StringMutation(args.partner_id);
    }
    if (args.patient_id !== undefined && args.patient_id !== null) {
      this.patient_id = new ttypes.StringMutation(args.patient_id);
    }
  }
};
CaseMutations.prototype = {};
CaseMutations.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner_id = new ttypes.StringMutation();
        this.partner_id.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.patient_id = new ttypes.StringMutation();
        this.patient_id.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CaseMutations.prototype.write = function(output) {
  output.writeStructBegin('CaseMutations');
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRUCT, 1);
    this.partner_id.write(output);
    output.writeFieldEnd();
  }
  if (this.patient_id !== null && this.patient_id !== undefined) {
    output.writeFieldBegin('patient_id', Thrift.Type.STRUCT, 2);
    this.patient_id.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ClinicalReview = module.exports.ClinicalReview = function(args) {
  this.consult_id = null;
  this.reviewer_id = null;
  this.corrective_action_needed = null;
  this.corrective_action_text = null;
  this.questions = null;
  this.score = null;
  this.num_previous_reviewed_consults = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.reviewer_id !== undefined && args.reviewer_id !== null) {
      this.reviewer_id = args.reviewer_id;
    }
    if (args.corrective_action_needed !== undefined && args.corrective_action_needed !== null) {
      this.corrective_action_needed = args.corrective_action_needed;
    }
    if (args.corrective_action_text !== undefined && args.corrective_action_text !== null) {
      this.corrective_action_text = args.corrective_action_text;
    }
    if (args.questions !== undefined && args.questions !== null) {
      this.questions = Thrift.copyList(args.questions, [null]);
    }
    if (args.score !== undefined && args.score !== null) {
      this.score = args.score;
    }
    if (args.num_previous_reviewed_consults !== undefined && args.num_previous_reviewed_consults !== null) {
      this.num_previous_reviewed_consults = args.num_previous_reviewed_consults;
    }
  }
};
ClinicalReview.prototype = {};
ClinicalReview.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.reviewer_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.corrective_action_needed = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.corrective_action_text = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        this.questions = [];
        var _rtmp341 = input.readListBegin();
        var _size40 = _rtmp341.size || 0;
        for (var _i42 = 0; _i42 < _size40; ++_i42) {
          var elem43 = null;
          elem43 = new ttypes.ClinicalReviewQuestion();
          elem43.read(input);
          this.questions.push(elem43);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.score = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.num_previous_reviewed_consults = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ClinicalReview.prototype.write = function(output) {
  output.writeStructBegin('ClinicalReview');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.reviewer_id !== null && this.reviewer_id !== undefined) {
    output.writeFieldBegin('reviewer_id', Thrift.Type.STRING, 2);
    output.writeString(this.reviewer_id);
    output.writeFieldEnd();
  }
  if (this.corrective_action_needed !== null && this.corrective_action_needed !== undefined) {
    output.writeFieldBegin('corrective_action_needed', Thrift.Type.BOOL, 4);
    output.writeBool(this.corrective_action_needed);
    output.writeFieldEnd();
  }
  if (this.corrective_action_text !== null && this.corrective_action_text !== undefined) {
    output.writeFieldBegin('corrective_action_text', Thrift.Type.STRING, 5);
    output.writeString(this.corrective_action_text);
    output.writeFieldEnd();
  }
  if (this.questions !== null && this.questions !== undefined) {
    output.writeFieldBegin('questions', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.questions.length);
    for (var iter44 in this.questions) {
      if (this.questions.hasOwnProperty(iter44)) {
        iter44 = this.questions[iter44];
        iter44.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.score !== null && this.score !== undefined) {
    output.writeFieldBegin('score', Thrift.Type.I32, 7);
    output.writeI32(this.score);
    output.writeFieldEnd();
  }
  if (this.num_previous_reviewed_consults !== null && this.num_previous_reviewed_consults !== undefined) {
    output.writeFieldBegin('num_previous_reviewed_consults', Thrift.Type.I32, 8);
    output.writeI32(this.num_previous_reviewed_consults);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ClinicalReviewQuestion = module.exports.ClinicalReviewQuestion = function(args) {
  this.question_id = null;
  this.question_text = null;
  this.answer_type = null;
  this.answer = null;
  this.scale_answer_options = null;
  if (args) {
    if (args.question_id !== undefined && args.question_id !== null) {
      this.question_id = args.question_id;
    }
    if (args.question_text !== undefined && args.question_text !== null) {
      this.question_text = args.question_text;
    }
    if (args.answer_type !== undefined && args.answer_type !== null) {
      this.answer_type = args.answer_type;
    }
    if (args.answer !== undefined && args.answer !== null) {
      this.answer = args.answer;
    }
    if (args.scale_answer_options !== undefined && args.scale_answer_options !== null) {
      this.scale_answer_options = Thrift.copyList(args.scale_answer_options, [null]);
    }
  }
};
ClinicalReviewQuestion.prototype = {};
ClinicalReviewQuestion.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.question_id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.question_text = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.answer_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.answer = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        this.scale_answer_options = [];
        var _rtmp346 = input.readListBegin();
        var _size45 = _rtmp346.size || 0;
        for (var _i47 = 0; _i47 < _size45; ++_i47) {
          var elem48 = null;
          elem48 = new ttypes.ClinicalReviewAnswerOption();
          elem48.read(input);
          this.scale_answer_options.push(elem48);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ClinicalReviewQuestion.prototype.write = function(output) {
  output.writeStructBegin('ClinicalReviewQuestion');
  if (this.question_id !== null && this.question_id !== undefined) {
    output.writeFieldBegin('question_id', Thrift.Type.I64, 1);
    output.writeI64(this.question_id);
    output.writeFieldEnd();
  }
  if (this.question_text !== null && this.question_text !== undefined) {
    output.writeFieldBegin('question_text', Thrift.Type.STRING, 2);
    output.writeString(this.question_text);
    output.writeFieldEnd();
  }
  if (this.answer_type !== null && this.answer_type !== undefined) {
    output.writeFieldBegin('answer_type', Thrift.Type.I32, 3);
    output.writeI32(this.answer_type);
    output.writeFieldEnd();
  }
  if (this.answer !== null && this.answer !== undefined) {
    output.writeFieldBegin('answer', Thrift.Type.I32, 4);
    output.writeI32(this.answer);
    output.writeFieldEnd();
  }
  if (this.scale_answer_options !== null && this.scale_answer_options !== undefined) {
    output.writeFieldBegin('scale_answer_options', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.scale_answer_options.length);
    for (var iter49 in this.scale_answer_options) {
      if (this.scale_answer_options.hasOwnProperty(iter49)) {
        iter49 = this.scale_answer_options[iter49];
        iter49.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ClinicalReviewAnswerOption = module.exports.ClinicalReviewAnswerOption = function(args) {
  this.value = null;
  this.description = null;
  if (args) {
    if (args.value !== undefined && args.value !== null) {
      this.value = args.value;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
  }
};
ClinicalReviewAnswerOption.prototype = {};
ClinicalReviewAnswerOption.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.value = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ClinicalReviewAnswerOption.prototype.write = function(output) {
  output.writeStructBegin('ClinicalReviewAnswerOption');
  if (this.value !== null && this.value !== undefined) {
    output.writeFieldBegin('value', Thrift.Type.I32, 1);
    output.writeI32(this.value);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 2);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Consult = module.exports.Consult = function(args) {
  this.consult_id = null;
  this.requestor_id = null;
  this.patient_id = null;
  this.specialist_id = null;
  this.specialty = null;
  this.state = null;
  this.consult_data = null;
  this.updated_at = null;
  this.consult_type = null;
  this.has_new_messages = null;
  this.studies = null;
  this.created_at = null;
  this.partner_id = null;
  this.case_id = null;
  this.conversations = null;
  this.timing_exception = null;
  this.patient_treatment_state = null;
  this.sla_expires_at = null;
  this.has_response = null;
  this.request_date = null;
  this.response_date = null;
  this.simplified_state = null;
  this.integration_data = null;
  this.clinical_review = null;
  this.missing_info_reasons = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.requestor_id !== undefined && args.requestor_id !== null) {
      this.requestor_id = args.requestor_id;
    }
    if (args.patient_id !== undefined && args.patient_id !== null) {
      this.patient_id = args.patient_id;
    }
    if (args.specialist_id !== undefined && args.specialist_id !== null) {
      this.specialist_id = args.specialist_id;
    }
    if (args.specialty !== undefined && args.specialty !== null) {
      this.specialty = args.specialty;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.consult_data !== undefined && args.consult_data !== null) {
      this.consult_data = Thrift.copyList(args.consult_data, [null]);
    }
    if (args.updated_at !== undefined && args.updated_at !== null) {
      this.updated_at = args.updated_at;
    }
    if (args.consult_type !== undefined && args.consult_type !== null) {
      this.consult_type = args.consult_type;
    }
    if (args.has_new_messages !== undefined && args.has_new_messages !== null) {
      this.has_new_messages = args.has_new_messages;
    }
    if (args.studies !== undefined && args.studies !== null) {
      this.studies = Thrift.copyList(args.studies, [null]);
    }
    if (args.created_at !== undefined && args.created_at !== null) {
      this.created_at = args.created_at;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.conversations !== undefined && args.conversations !== null) {
      this.conversations = Thrift.copyList(args.conversations, [null]);
    }
    if (args.timing_exception !== undefined && args.timing_exception !== null) {
      this.timing_exception = args.timing_exception;
    }
    if (args.patient_treatment_state !== undefined && args.patient_treatment_state !== null) {
      this.patient_treatment_state = args.patient_treatment_state;
    }
    if (args.sla_expires_at !== undefined && args.sla_expires_at !== null) {
      this.sla_expires_at = args.sla_expires_at;
    }
    if (args.has_response !== undefined && args.has_response !== null) {
      this.has_response = args.has_response;
    }
    if (args.request_date !== undefined && args.request_date !== null) {
      this.request_date = args.request_date;
    }
    if (args.response_date !== undefined && args.response_date !== null) {
      this.response_date = args.response_date;
    }
    if (args.simplified_state !== undefined && args.simplified_state !== null) {
      this.simplified_state = args.simplified_state;
    }
    if (args.integration_data !== undefined && args.integration_data !== null) {
      this.integration_data = new ttypes.ConsultIntegrationData(args.integration_data);
    }
    if (args.clinical_review !== undefined && args.clinical_review !== null) {
      this.clinical_review = new ttypes.ClinicalReview(args.clinical_review);
    }
    if (args.missing_info_reasons !== undefined && args.missing_info_reasons !== null) {
      this.missing_info_reasons = Thrift.copyList(args.missing_info_reasons, [null]);
    }
  }
};
Consult.prototype = {};
Consult.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.requestor_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.patient_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.specialist_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I32) {
        this.specialty = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        this.consult_data = [];
        var _rtmp351 = input.readListBegin();
        var _size50 = _rtmp351.size || 0;
        for (var _i52 = 0; _i52 < _size50; ++_i52) {
          var elem53 = null;
          elem53 = new ttypes.ConsultData();
          elem53.read(input);
          this.consult_data.push(elem53);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.I64) {
        this.updated_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.I32) {
        this.consult_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.BOOL) {
        this.has_new_messages = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.LIST) {
        this.studies = [];
        var _rtmp355 = input.readListBegin();
        var _size54 = _rtmp355.size || 0;
        for (var _i56 = 0; _i56 < _size54; ++_i56) {
          var elem57 = null;
          elem57 = new ttypes.DicomStudy();
          elem57.read(input);
          this.studies.push(elem57);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 23:
      if (ftype == Thrift.Type.I64) {
        this.created_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 24:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 25:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 26:
      if (ftype == Thrift.Type.LIST) {
        this.conversations = [];
        var _rtmp359 = input.readListBegin();
        var _size58 = _rtmp359.size || 0;
        for (var _i60 = 0; _i60 < _size58; ++_i60) {
          var elem61 = null;
          elem61 = new ttypes.Conversation();
          elem61.read(input);
          this.conversations.push(elem61);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 27:
      if (ftype == Thrift.Type.BOOL) {
        this.timing_exception = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 28:
      if (ftype == Thrift.Type.I32) {
        this.patient_treatment_state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 29:
      if (ftype == Thrift.Type.I64) {
        this.sla_expires_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 30:
      if (ftype == Thrift.Type.BOOL) {
        this.has_response = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 31:
      if (ftype == Thrift.Type.I64) {
        this.request_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 32:
      if (ftype == Thrift.Type.I64) {
        this.response_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 33:
      if (ftype == Thrift.Type.I32) {
        this.simplified_state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 34:
      if (ftype == Thrift.Type.STRUCT) {
        this.integration_data = new ttypes.ConsultIntegrationData();
        this.integration_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 35:
      if (ftype == Thrift.Type.STRUCT) {
        this.clinical_review = new ttypes.ClinicalReview();
        this.clinical_review.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 36:
      if (ftype == Thrift.Type.LIST) {
        this.missing_info_reasons = [];
        var _rtmp363 = input.readListBegin();
        var _size62 = _rtmp363.size || 0;
        for (var _i64 = 0; _i64 < _size62; ++_i64) {
          var elem65 = null;
          elem65 = input.readI32();
          this.missing_info_reasons.push(elem65);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Consult.prototype.write = function(output) {
  output.writeStructBegin('Consult');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.requestor_id !== null && this.requestor_id !== undefined) {
    output.writeFieldBegin('requestor_id', Thrift.Type.STRING, 2);
    output.writeString(this.requestor_id);
    output.writeFieldEnd();
  }
  if (this.patient_id !== null && this.patient_id !== undefined) {
    output.writeFieldBegin('patient_id', Thrift.Type.STRING, 3);
    output.writeString(this.patient_id);
    output.writeFieldEnd();
  }
  if (this.specialist_id !== null && this.specialist_id !== undefined) {
    output.writeFieldBegin('specialist_id', Thrift.Type.STRING, 4);
    output.writeString(this.specialist_id);
    output.writeFieldEnd();
  }
  if (this.specialty !== null && this.specialty !== undefined) {
    output.writeFieldBegin('specialty', Thrift.Type.I32, 5);
    output.writeI32(this.specialty);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.I32, 6);
    output.writeI32(this.state);
    output.writeFieldEnd();
  }
  if (this.consult_data !== null && this.consult_data !== undefined) {
    output.writeFieldBegin('consult_data', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRUCT, this.consult_data.length);
    for (var iter66 in this.consult_data) {
      if (this.consult_data.hasOwnProperty(iter66)) {
        iter66 = this.consult_data[iter66];
        iter66.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.updated_at !== null && this.updated_at !== undefined) {
    output.writeFieldBegin('updated_at', Thrift.Type.I64, 15);
    output.writeI64(this.updated_at);
    output.writeFieldEnd();
  }
  if (this.consult_type !== null && this.consult_type !== undefined) {
    output.writeFieldBegin('consult_type', Thrift.Type.I32, 20);
    output.writeI32(this.consult_type);
    output.writeFieldEnd();
  }
  if (this.has_new_messages !== null && this.has_new_messages !== undefined) {
    output.writeFieldBegin('has_new_messages', Thrift.Type.BOOL, 21);
    output.writeBool(this.has_new_messages);
    output.writeFieldEnd();
  }
  if (this.studies !== null && this.studies !== undefined) {
    output.writeFieldBegin('studies', Thrift.Type.LIST, 22);
    output.writeListBegin(Thrift.Type.STRUCT, this.studies.length);
    for (var iter67 in this.studies) {
      if (this.studies.hasOwnProperty(iter67)) {
        iter67 = this.studies[iter67];
        iter67.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.created_at !== null && this.created_at !== undefined) {
    output.writeFieldBegin('created_at', Thrift.Type.I64, 23);
    output.writeI64(this.created_at);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 24);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 25);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.conversations !== null && this.conversations !== undefined) {
    output.writeFieldBegin('conversations', Thrift.Type.LIST, 26);
    output.writeListBegin(Thrift.Type.STRUCT, this.conversations.length);
    for (var iter68 in this.conversations) {
      if (this.conversations.hasOwnProperty(iter68)) {
        iter68 = this.conversations[iter68];
        iter68.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.timing_exception !== null && this.timing_exception !== undefined) {
    output.writeFieldBegin('timing_exception', Thrift.Type.BOOL, 27);
    output.writeBool(this.timing_exception);
    output.writeFieldEnd();
  }
  if (this.patient_treatment_state !== null && this.patient_treatment_state !== undefined) {
    output.writeFieldBegin('patient_treatment_state', Thrift.Type.I32, 28);
    output.writeI32(this.patient_treatment_state);
    output.writeFieldEnd();
  }
  if (this.sla_expires_at !== null && this.sla_expires_at !== undefined) {
    output.writeFieldBegin('sla_expires_at', Thrift.Type.I64, 29);
    output.writeI64(this.sla_expires_at);
    output.writeFieldEnd();
  }
  if (this.has_response !== null && this.has_response !== undefined) {
    output.writeFieldBegin('has_response', Thrift.Type.BOOL, 30);
    output.writeBool(this.has_response);
    output.writeFieldEnd();
  }
  if (this.request_date !== null && this.request_date !== undefined) {
    output.writeFieldBegin('request_date', Thrift.Type.I64, 31);
    output.writeI64(this.request_date);
    output.writeFieldEnd();
  }
  if (this.response_date !== null && this.response_date !== undefined) {
    output.writeFieldBegin('response_date', Thrift.Type.I64, 32);
    output.writeI64(this.response_date);
    output.writeFieldEnd();
  }
  if (this.simplified_state !== null && this.simplified_state !== undefined) {
    output.writeFieldBegin('simplified_state', Thrift.Type.I32, 33);
    output.writeI32(this.simplified_state);
    output.writeFieldEnd();
  }
  if (this.integration_data !== null && this.integration_data !== undefined) {
    output.writeFieldBegin('integration_data', Thrift.Type.STRUCT, 34);
    this.integration_data.write(output);
    output.writeFieldEnd();
  }
  if (this.clinical_review !== null && this.clinical_review !== undefined) {
    output.writeFieldBegin('clinical_review', Thrift.Type.STRUCT, 35);
    this.clinical_review.write(output);
    output.writeFieldEnd();
  }
  if (this.missing_info_reasons !== null && this.missing_info_reasons !== undefined) {
    output.writeFieldBegin('missing_info_reasons', Thrift.Type.LIST, 36);
    output.writeListBegin(Thrift.Type.I32, this.missing_info_reasons.length);
    for (var iter69 in this.missing_info_reasons) {
      if (this.missing_info_reasons.hasOwnProperty(iter69)) {
        iter69 = this.missing_info_reasons[iter69];
        output.writeI32(iter69);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultIntegrationData = module.exports.ConsultIntegrationData = function(args) {
  this.type = null;
  this.athena = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.athena !== undefined && args.athena !== null) {
      this.athena = new ttypes.AthenaConsultFields(args.athena);
    }
  }
};
ConsultIntegrationData.prototype = {};
ConsultIntegrationData.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.athena = new ttypes.AthenaConsultFields();
        this.athena.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultIntegrationData.prototype.write = function(output) {
  output.writeStructBegin('ConsultIntegrationData');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.athena !== null && this.athena !== undefined) {
    output.writeFieldBegin('athena', Thrift.Type.STRUCT, 2);
    this.athena.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultData = module.exports.ConsultData = function(args) {
  this.data_name = null;
  this.presigned_url = null;
  this.data_type = null;
  this.state = null;
  this.public_url = null;
  this.thumbnail_url = null;
  this.meta_data = null;
  this.consult_data_id = null;
  this.created_at = null;
  this.author_id = null;
  this.consult_id = null;
  this.is_message = null;
  this.is_new = null;
  this.shared_with_patient = null;
  this.patient_view_count = null;
  this.case_id = null;
  this.video_metadata = null;
  this.redacted_reason = null;
  this.redacted_at = null;
  this.specialist_response = null;
  this.integration_data = null;
  this.clinical_question_text = null;
  if (args) {
    if (args.data_name !== undefined && args.data_name !== null) {
      this.data_name = args.data_name;
    }
    if (args.presigned_url !== undefined && args.presigned_url !== null) {
      this.presigned_url = args.presigned_url;
    }
    if (args.data_type !== undefined && args.data_type !== null) {
      this.data_type = args.data_type;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.public_url !== undefined && args.public_url !== null) {
      this.public_url = args.public_url;
    }
    if (args.thumbnail_url !== undefined && args.thumbnail_url !== null) {
      this.thumbnail_url = args.thumbnail_url;
    }
    if (args.meta_data !== undefined && args.meta_data !== null) {
      this.meta_data = args.meta_data;
    }
    if (args.consult_data_id !== undefined && args.consult_data_id !== null) {
      this.consult_data_id = args.consult_data_id;
    }
    if (args.created_at !== undefined && args.created_at !== null) {
      this.created_at = args.created_at;
    }
    if (args.author_id !== undefined && args.author_id !== null) {
      this.author_id = args.author_id;
    }
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.is_message !== undefined && args.is_message !== null) {
      this.is_message = args.is_message;
    }
    if (args.is_new !== undefined && args.is_new !== null) {
      this.is_new = args.is_new;
    }
    if (args.shared_with_patient !== undefined && args.shared_with_patient !== null) {
      this.shared_with_patient = args.shared_with_patient;
    }
    if (args.patient_view_count !== undefined && args.patient_view_count !== null) {
      this.patient_view_count = args.patient_view_count;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.video_metadata !== undefined && args.video_metadata !== null) {
      this.video_metadata = new ttypes.VideoMetaData(args.video_metadata);
    }
    if (args.redacted_reason !== undefined && args.redacted_reason !== null) {
      this.redacted_reason = args.redacted_reason;
    }
    if (args.redacted_at !== undefined && args.redacted_at !== null) {
      this.redacted_at = args.redacted_at;
    }
    if (args.specialist_response !== undefined && args.specialist_response !== null) {
      this.specialist_response = new ttypes.SpecialistResponse(args.specialist_response);
    }
    if (args.integration_data !== undefined && args.integration_data !== null) {
      this.integration_data = new ttypes.ConsultDataIntegrationData(args.integration_data);
    }
    if (args.clinical_question_text !== undefined && args.clinical_question_text !== null) {
      this.clinical_question_text = args.clinical_question_text;
    }
  }
};
ConsultData.prototype = {};
ConsultData.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.data_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.presigned_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.data_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.public_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.thumbnail_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.meta_data = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.consult_data_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I64) {
        this.created_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.author_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.BOOL) {
        this.is_message = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.BOOL) {
        this.is_new = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.BOOL) {
        this.shared_with_patient = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.I64) {
        this.patient_view_count = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRUCT) {
        this.video_metadata = new ttypes.VideoMetaData();
        this.video_metadata.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.I32) {
        this.redacted_reason = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.I64) {
        this.redacted_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialist_response = new ttypes.SpecialistResponse();
        this.specialist_response.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.STRUCT) {
        this.integration_data = new ttypes.ConsultDataIntegrationData();
        this.integration_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.STRING) {
        this.clinical_question_text = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultData.prototype.write = function(output) {
  output.writeStructBegin('ConsultData');
  if (this.data_name !== null && this.data_name !== undefined) {
    output.writeFieldBegin('data_name', Thrift.Type.STRING, 1);
    output.writeString(this.data_name);
    output.writeFieldEnd();
  }
  if (this.presigned_url !== null && this.presigned_url !== undefined) {
    output.writeFieldBegin('presigned_url', Thrift.Type.STRING, 2);
    output.writeString(this.presigned_url);
    output.writeFieldEnd();
  }
  if (this.data_type !== null && this.data_type !== undefined) {
    output.writeFieldBegin('data_type', Thrift.Type.I32, 3);
    output.writeI32(this.data_type);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.I32, 4);
    output.writeI32(this.state);
    output.writeFieldEnd();
  }
  if (this.public_url !== null && this.public_url !== undefined) {
    output.writeFieldBegin('public_url', Thrift.Type.STRING, 5);
    output.writeString(this.public_url);
    output.writeFieldEnd();
  }
  if (this.thumbnail_url !== null && this.thumbnail_url !== undefined) {
    output.writeFieldBegin('thumbnail_url', Thrift.Type.STRING, 6);
    output.writeString(this.thumbnail_url);
    output.writeFieldEnd();
  }
  if (this.meta_data !== null && this.meta_data !== undefined) {
    output.writeFieldBegin('meta_data', Thrift.Type.STRING, 7);
    output.writeString(this.meta_data);
    output.writeFieldEnd();
  }
  if (this.consult_data_id !== null && this.consult_data_id !== undefined) {
    output.writeFieldBegin('consult_data_id', Thrift.Type.STRING, 8);
    output.writeString(this.consult_data_id);
    output.writeFieldEnd();
  }
  if (this.created_at !== null && this.created_at !== undefined) {
    output.writeFieldBegin('created_at', Thrift.Type.I64, 9);
    output.writeI64(this.created_at);
    output.writeFieldEnd();
  }
  if (this.author_id !== null && this.author_id !== undefined) {
    output.writeFieldBegin('author_id', Thrift.Type.STRING, 10);
    output.writeString(this.author_id);
    output.writeFieldEnd();
  }
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 11);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.is_message !== null && this.is_message !== undefined) {
    output.writeFieldBegin('is_message', Thrift.Type.BOOL, 12);
    output.writeBool(this.is_message);
    output.writeFieldEnd();
  }
  if (this.is_new !== null && this.is_new !== undefined) {
    output.writeFieldBegin('is_new', Thrift.Type.BOOL, 13);
    output.writeBool(this.is_new);
    output.writeFieldEnd();
  }
  if (this.shared_with_patient !== null && this.shared_with_patient !== undefined) {
    output.writeFieldBegin('shared_with_patient', Thrift.Type.BOOL, 14);
    output.writeBool(this.shared_with_patient);
    output.writeFieldEnd();
  }
  if (this.patient_view_count !== null && this.patient_view_count !== undefined) {
    output.writeFieldBegin('patient_view_count', Thrift.Type.I64, 15);
    output.writeI64(this.patient_view_count);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 16);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.video_metadata !== null && this.video_metadata !== undefined) {
    output.writeFieldBegin('video_metadata', Thrift.Type.STRUCT, 17);
    this.video_metadata.write(output);
    output.writeFieldEnd();
  }
  if (this.redacted_reason !== null && this.redacted_reason !== undefined) {
    output.writeFieldBegin('redacted_reason', Thrift.Type.I32, 18);
    output.writeI32(this.redacted_reason);
    output.writeFieldEnd();
  }
  if (this.redacted_at !== null && this.redacted_at !== undefined) {
    output.writeFieldBegin('redacted_at', Thrift.Type.I64, 19);
    output.writeI64(this.redacted_at);
    output.writeFieldEnd();
  }
  if (this.specialist_response !== null && this.specialist_response !== undefined) {
    output.writeFieldBegin('specialist_response', Thrift.Type.STRUCT, 20);
    this.specialist_response.write(output);
    output.writeFieldEnd();
  }
  if (this.integration_data !== null && this.integration_data !== undefined) {
    output.writeFieldBegin('integration_data', Thrift.Type.STRUCT, 21);
    this.integration_data.write(output);
    output.writeFieldEnd();
  }
  if (this.clinical_question_text !== null && this.clinical_question_text !== undefined) {
    output.writeFieldBegin('clinical_question_text', Thrift.Type.STRING, 22);
    output.writeString(this.clinical_question_text);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultDataIntegrationData = module.exports.ConsultDataIntegrationData = function(args) {
  this.type = null;
  this.athena = null;
  if (args) {
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.athena !== undefined && args.athena !== null) {
      this.athena = new ttypes.AthenaConsultDataFields(args.athena);
    }
  }
};
ConsultDataIntegrationData.prototype = {};
ConsultDataIntegrationData.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.athena = new ttypes.AthenaConsultDataFields();
        this.athena.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultDataIntegrationData.prototype.write = function(output) {
  output.writeStructBegin('ConsultDataIntegrationData');
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 1);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.athena !== null && this.athena !== undefined) {
    output.writeFieldBegin('athena', Thrift.Type.STRUCT, 2);
    this.athena.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultDataFile = module.exports.ConsultDataFile = function(args) {
  this.filename = null;
  this.upload_key = null;
  if (args) {
    if (args.filename !== undefined && args.filename !== null) {
      this.filename = args.filename;
    }
    if (args.upload_key !== undefined && args.upload_key !== null) {
      this.upload_key = args.upload_key;
    }
  }
};
ConsultDataFile.prototype = {};
ConsultDataFile.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.filename = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.upload_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultDataFile.prototype.write = function(output) {
  output.writeStructBegin('ConsultDataFile');
  if (this.filename !== null && this.filename !== undefined) {
    output.writeFieldBegin('filename', Thrift.Type.STRING, 1);
    output.writeString(this.filename);
    output.writeFieldEnd();
  }
  if (this.upload_key !== null && this.upload_key !== undefined) {
    output.writeFieldBegin('upload_key', Thrift.Type.STRING, 2);
    output.writeString(this.upload_key);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultDataUpdate = module.exports.ConsultDataUpdate = function(args) {
  this.redacted_reason = null;
  if (args) {
    if (args.redacted_reason !== undefined && args.redacted_reason !== null) {
      this.redacted_reason = args.redacted_reason;
    }
  }
};
ConsultDataUpdate.prototype = {};
ConsultDataUpdate.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.redacted_reason = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultDataUpdate.prototype.write = function(output) {
  output.writeStructBegin('ConsultDataUpdate');
  if (this.redacted_reason !== null && this.redacted_reason !== undefined) {
    output.writeFieldBegin('redacted_reason', Thrift.Type.I32, 1);
    output.writeI32(this.redacted_reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultDataUpdates = module.exports.ConsultDataUpdates = function(args) {
  this.consult_data_id = null;
  this.update = null;
  if (args) {
    if (args.consult_data_id !== undefined && args.consult_data_id !== null) {
      this.consult_data_id = args.consult_data_id;
    }
    if (args.update !== undefined && args.update !== null) {
      this.update = new ttypes.ConsultDataUpdate(args.update);
    }
  }
};
ConsultDataUpdates.prototype = {};
ConsultDataUpdates.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_data_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.update = new ttypes.ConsultDataUpdate();
        this.update.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultDataUpdates.prototype.write = function(output) {
  output.writeStructBegin('ConsultDataUpdates');
  if (this.consult_data_id !== null && this.consult_data_id !== undefined) {
    output.writeFieldBegin('consult_data_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_data_id);
    output.writeFieldEnd();
  }
  if (this.update !== null && this.update !== undefined) {
    output.writeFieldBegin('update', Thrift.Type.STRUCT, 2);
    this.update.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SpecialistResponse = module.exports.SpecialistResponse = function(args) {
  this.response_type = null;
  this.assessments = null;
  this.recommendation = null;
  this.specialty = null;
  this.needs_additional_info = null;
  if (args) {
    if (args.response_type !== undefined && args.response_type !== null) {
      this.response_type = args.response_type;
    }
    if (args.assessments !== undefined && args.assessments !== null) {
      this.assessments = Thrift.copyList(args.assessments, [null]);
    }
    if (args.recommendation !== undefined && args.recommendation !== null) {
      this.recommendation = args.recommendation;
    }
    if (args.specialty !== undefined && args.specialty !== null) {
      this.specialty = args.specialty;
    }
    if (args.needs_additional_info !== undefined && args.needs_additional_info !== null) {
      this.needs_additional_info = args.needs_additional_info;
    }
  }
};
SpecialistResponse.prototype = {};
SpecialistResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.response_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.assessments = [];
        var _rtmp371 = input.readListBegin();
        var _size70 = _rtmp371.size || 0;
        for (var _i72 = 0; _i72 < _size70; ++_i72) {
          var elem73 = null;
          elem73 = new ttypes.SpecialistResponseAssessment();
          elem73.read(input);
          this.assessments.push(elem73);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.recommendation = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I32) {
        this.specialty = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.needs_additional_info = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SpecialistResponse.prototype.write = function(output) {
  output.writeStructBegin('SpecialistResponse');
  if (this.response_type !== null && this.response_type !== undefined) {
    output.writeFieldBegin('response_type', Thrift.Type.I32, 1);
    output.writeI32(this.response_type);
    output.writeFieldEnd();
  }
  if (this.assessments !== null && this.assessments !== undefined) {
    output.writeFieldBegin('assessments', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.assessments.length);
    for (var iter74 in this.assessments) {
      if (this.assessments.hasOwnProperty(iter74)) {
        iter74 = this.assessments[iter74];
        iter74.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.recommendation !== null && this.recommendation !== undefined) {
    output.writeFieldBegin('recommendation', Thrift.Type.I32, 3);
    output.writeI32(this.recommendation);
    output.writeFieldEnd();
  }
  if (this.specialty !== null && this.specialty !== undefined) {
    output.writeFieldBegin('specialty', Thrift.Type.I32, 4);
    output.writeI32(this.specialty);
    output.writeFieldEnd();
  }
  if (this.needs_additional_info !== null && this.needs_additional_info !== undefined) {
    output.writeFieldBegin('needs_additional_info', Thrift.Type.BOOL, 5);
    output.writeBool(this.needs_additional_info);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SpecialistResponseAssessment = module.exports.SpecialistResponseAssessment = function(args) {
  this.specialist_response_condition = null;
  this.interventions = null;
  this.order = null;
  if (args) {
    if (args.specialist_response_condition !== undefined && args.specialist_response_condition !== null) {
      this.specialist_response_condition = args.specialist_response_condition;
    }
    if (args.interventions !== undefined && args.interventions !== null) {
      this.interventions = Thrift.copyList(args.interventions, [null]);
    }
    if (args.order !== undefined && args.order !== null) {
      this.order = args.order;
    }
  }
};
SpecialistResponseAssessment.prototype = {};
SpecialistResponseAssessment.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.specialist_response_condition = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.interventions = [];
        var _rtmp376 = input.readListBegin();
        var _size75 = _rtmp376.size || 0;
        for (var _i77 = 0; _i77 < _size75; ++_i77) {
          var elem78 = null;
          elem78 = input.readI32();
          this.interventions.push(elem78);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.order = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SpecialistResponseAssessment.prototype.write = function(output) {
  output.writeStructBegin('SpecialistResponseAssessment');
  if (this.specialist_response_condition !== null && this.specialist_response_condition !== undefined) {
    output.writeFieldBegin('specialist_response_condition', Thrift.Type.STRING, 1);
    output.writeString(this.specialist_response_condition);
    output.writeFieldEnd();
  }
  if (this.interventions !== null && this.interventions !== undefined) {
    output.writeFieldBegin('interventions', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.I32, this.interventions.length);
    for (var iter79 in this.interventions) {
      if (this.interventions.hasOwnProperty(iter79)) {
        iter79 = this.interventions[iter79];
        output.writeI32(iter79);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.order !== null && this.order !== undefined) {
    output.writeFieldBegin('order', Thrift.Type.I32, 3);
    output.writeI32(this.order);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultMessage = module.exports.ConsultMessage = function(args) {
  this.media_keys = null;
  this.text = null;
  this.recording_id = null;
  this.clinical_question_text = null;
  if (args) {
    if (args.media_keys !== undefined && args.media_keys !== null) {
      this.media_keys = Thrift.copyList(args.media_keys, [null]);
    }
    if (args.text !== undefined && args.text !== null) {
      this.text = args.text;
    }
    if (args.recording_id !== undefined && args.recording_id !== null) {
      this.recording_id = args.recording_id;
    }
    if (args.clinical_question_text !== undefined && args.clinical_question_text !== null) {
      this.clinical_question_text = args.clinical_question_text;
    }
  }
};
ConsultMessage.prototype = {};
ConsultMessage.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.media_keys = [];
        var _rtmp381 = input.readListBegin();
        var _size80 = _rtmp381.size || 0;
        for (var _i82 = 0; _i82 < _size80; ++_i82) {
          var elem83 = null;
          elem83 = input.readString();
          this.media_keys.push(elem83);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.text = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.recording_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.clinical_question_text = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultMessage.prototype.write = function(output) {
  output.writeStructBegin('ConsultMessage');
  if (this.media_keys !== null && this.media_keys !== undefined) {
    output.writeFieldBegin('media_keys', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.media_keys.length);
    for (var iter84 in this.media_keys) {
      if (this.media_keys.hasOwnProperty(iter84)) {
        iter84 = this.media_keys[iter84];
        output.writeString(iter84);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.text !== null && this.text !== undefined) {
    output.writeFieldBegin('text', Thrift.Type.STRING, 2);
    output.writeString(this.text);
    output.writeFieldEnd();
  }
  if (this.recording_id !== null && this.recording_id !== undefined) {
    output.writeFieldBegin('recording_id', Thrift.Type.STRING, 3);
    output.writeString(this.recording_id);
    output.writeFieldEnd();
  }
  if (this.clinical_question_text !== null && this.clinical_question_text !== undefined) {
    output.writeFieldBegin('clinical_question_text', Thrift.Type.STRING, 4);
    output.writeString(this.clinical_question_text);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultUpdates = module.exports.ConsultUpdates = function(args) {
  this.specialist_id = null;
  this.specialty = null;
  this.consult_type = null;
  this.requestor_id = null;
  this.patient_id = null;
  this.partner_id = null;
  this.case_id = null;
  this.state = null;
  this.timing_exception = null;
  this.patient_treatment_state = null;
  this.integration_data = null;
  this.reset_specialist = null;
  this.missing_info_reasons = null;
  if (args) {
    if (args.specialist_id !== undefined && args.specialist_id !== null) {
      this.specialist_id = args.specialist_id;
    }
    if (args.specialty !== undefined && args.specialty !== null) {
      this.specialty = args.specialty;
    }
    if (args.consult_type !== undefined && args.consult_type !== null) {
      this.consult_type = args.consult_type;
    }
    if (args.requestor_id !== undefined && args.requestor_id !== null) {
      this.requestor_id = args.requestor_id;
    }
    if (args.patient_id !== undefined && args.patient_id !== null) {
      this.patient_id = args.patient_id;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.timing_exception !== undefined && args.timing_exception !== null) {
      this.timing_exception = args.timing_exception;
    }
    if (args.patient_treatment_state !== undefined && args.patient_treatment_state !== null) {
      this.patient_treatment_state = args.patient_treatment_state;
    }
    if (args.integration_data !== undefined && args.integration_data !== null) {
      this.integration_data = new ttypes.ConsultIntegrationData(args.integration_data);
    }
    if (args.reset_specialist !== undefined && args.reset_specialist !== null) {
      this.reset_specialist = args.reset_specialist;
    }
    if (args.missing_info_reasons !== undefined && args.missing_info_reasons !== null) {
      this.missing_info_reasons = Thrift.copyList(args.missing_info_reasons, [null]);
    }
  }
};
ConsultUpdates.prototype = {};
ConsultUpdates.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.specialist_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.specialty = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.consult_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.requestor_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.patient_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.I32) {
        this.state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.BOOL) {
        this.timing_exception = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.I32) {
        this.patient_treatment_state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.STRUCT) {
        this.integration_data = new ttypes.ConsultIntegrationData();
        this.integration_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.BOOL) {
        this.reset_specialist = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.LIST) {
        this.missing_info_reasons = [];
        var _rtmp386 = input.readListBegin();
        var _size85 = _rtmp386.size || 0;
        for (var _i87 = 0; _i87 < _size85; ++_i87) {
          var elem88 = null;
          elem88 = input.readI32();
          this.missing_info_reasons.push(elem88);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultUpdates.prototype.write = function(output) {
  output.writeStructBegin('ConsultUpdates');
  if (this.specialist_id !== null && this.specialist_id !== undefined) {
    output.writeFieldBegin('specialist_id', Thrift.Type.STRING, 1);
    output.writeString(this.specialist_id);
    output.writeFieldEnd();
  }
  if (this.specialty !== null && this.specialty !== undefined) {
    output.writeFieldBegin('specialty', Thrift.Type.I32, 7);
    output.writeI32(this.specialty);
    output.writeFieldEnd();
  }
  if (this.consult_type !== null && this.consult_type !== undefined) {
    output.writeFieldBegin('consult_type', Thrift.Type.I32, 9);
    output.writeI32(this.consult_type);
    output.writeFieldEnd();
  }
  if (this.requestor_id !== null && this.requestor_id !== undefined) {
    output.writeFieldBegin('requestor_id', Thrift.Type.STRING, 10);
    output.writeString(this.requestor_id);
    output.writeFieldEnd();
  }
  if (this.patient_id !== null && this.patient_id !== undefined) {
    output.writeFieldBegin('patient_id', Thrift.Type.STRING, 11);
    output.writeString(this.patient_id);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 12);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 13);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.I32, 14);
    output.writeI32(this.state);
    output.writeFieldEnd();
  }
  if (this.timing_exception !== null && this.timing_exception !== undefined) {
    output.writeFieldBegin('timing_exception', Thrift.Type.BOOL, 15);
    output.writeBool(this.timing_exception);
    output.writeFieldEnd();
  }
  if (this.patient_treatment_state !== null && this.patient_treatment_state !== undefined) {
    output.writeFieldBegin('patient_treatment_state', Thrift.Type.I32, 16);
    output.writeI32(this.patient_treatment_state);
    output.writeFieldEnd();
  }
  if (this.integration_data !== null && this.integration_data !== undefined) {
    output.writeFieldBegin('integration_data', Thrift.Type.STRUCT, 17);
    this.integration_data.write(output);
    output.writeFieldEnd();
  }
  if (this.reset_specialist !== null && this.reset_specialist !== undefined) {
    output.writeFieldBegin('reset_specialist', Thrift.Type.BOOL, 18);
    output.writeBool(this.reset_specialist);
    output.writeFieldEnd();
  }
  if (this.missing_info_reasons !== null && this.missing_info_reasons !== undefined) {
    output.writeFieldBegin('missing_info_reasons', Thrift.Type.LIST, 19);
    output.writeListBegin(Thrift.Type.I32, this.missing_info_reasons.length);
    for (var iter89 in this.missing_info_reasons) {
      if (this.missing_info_reasons.hasOwnProperty(iter89)) {
        iter89 = this.missing_info_reasons[iter89];
        output.writeI32(iter89);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Conversation = module.exports.Conversation = function(args) {
  this.conversation_id = null;
  this.case_id = null;
  this.consult_id = null;
  this.messages = null;
  this.participant_ids = null;
  this.can_share_messages = null;
  this.can_redact_messages = null;
  if (args) {
    if (args.conversation_id !== undefined && args.conversation_id !== null) {
      this.conversation_id = args.conversation_id;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.messages !== undefined && args.messages !== null) {
      this.messages = Thrift.copyList(args.messages, [null]);
    }
    if (args.participant_ids !== undefined && args.participant_ids !== null) {
      this.participant_ids = Thrift.copyList(args.participant_ids, [null]);
    }
    if (args.can_share_messages !== undefined && args.can_share_messages !== null) {
      this.can_share_messages = args.can_share_messages;
    }
    if (args.can_redact_messages !== undefined && args.can_redact_messages !== null) {
      this.can_redact_messages = args.can_redact_messages;
    }
  }
};
Conversation.prototype = {};
Conversation.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.conversation_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.messages = [];
        var _rtmp391 = input.readListBegin();
        var _size90 = _rtmp391.size || 0;
        for (var _i92 = 0; _i92 < _size90; ++_i92) {
          var elem93 = null;
          elem93 = new ttypes.ConversationMessage();
          elem93.read(input);
          this.messages.push(elem93);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        this.participant_ids = [];
        var _rtmp395 = input.readListBegin();
        var _size94 = _rtmp395.size || 0;
        for (var _i96 = 0; _i96 < _size94; ++_i96) {
          var elem97 = null;
          elem97 = input.readString();
          this.participant_ids.push(elem97);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.BOOL) {
        this.can_share_messages = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.BOOL) {
        this.can_redact_messages = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Conversation.prototype.write = function(output) {
  output.writeStructBegin('Conversation');
  if (this.conversation_id !== null && this.conversation_id !== undefined) {
    output.writeFieldBegin('conversation_id', Thrift.Type.STRING, 1);
    output.writeString(this.conversation_id);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 2);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 3);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.messages !== null && this.messages !== undefined) {
    output.writeFieldBegin('messages', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.messages.length);
    for (var iter98 in this.messages) {
      if (this.messages.hasOwnProperty(iter98)) {
        iter98 = this.messages[iter98];
        iter98.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.participant_ids !== null && this.participant_ids !== undefined) {
    output.writeFieldBegin('participant_ids', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRING, this.participant_ids.length);
    for (var iter99 in this.participant_ids) {
      if (this.participant_ids.hasOwnProperty(iter99)) {
        iter99 = this.participant_ids[iter99];
        output.writeString(iter99);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.can_share_messages !== null && this.can_share_messages !== undefined) {
    output.writeFieldBegin('can_share_messages', Thrift.Type.BOOL, 6);
    output.writeBool(this.can_share_messages);
    output.writeFieldEnd();
  }
  if (this.can_redact_messages !== null && this.can_redact_messages !== undefined) {
    output.writeFieldBegin('can_redact_messages', Thrift.Type.BOOL, 7);
    output.writeBool(this.can_redact_messages);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConversationMessage = module.exports.ConversationMessage = function(args) {
  this.message_id = null;
  this.conversation_id = null;
  this.content = null;
  this.sender_id = null;
  this.created_at = null;
  this.shared_from = null;
  this.shared_to = null;
  this.state = null;
  this.patient_view_count = null;
  this.patient_has_watched = null;
  this.is_redacted = null;
  this.is_new = null;
  if (args) {
    if (args.message_id !== undefined && args.message_id !== null) {
      this.message_id = args.message_id;
    }
    if (args.conversation_id !== undefined && args.conversation_id !== null) {
      this.conversation_id = args.conversation_id;
    }
    if (args.content !== undefined && args.content !== null) {
      this.content = new ttypes.ConsultData(args.content);
    }
    if (args.sender_id !== undefined && args.sender_id !== null) {
      this.sender_id = args.sender_id;
    }
    if (args.created_at !== undefined && args.created_at !== null) {
      this.created_at = args.created_at;
    }
    if (args.shared_from !== undefined && args.shared_from !== null) {
      this.shared_from = new ttypes.ConversationMessage(args.shared_from);
    }
    if (args.shared_to !== undefined && args.shared_to !== null) {
      this.shared_to = Thrift.copyList(args.shared_to, [null]);
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.patient_view_count !== undefined && args.patient_view_count !== null) {
      this.patient_view_count = args.patient_view_count;
    }
    if (args.patient_has_watched !== undefined && args.patient_has_watched !== null) {
      this.patient_has_watched = args.patient_has_watched;
    }
    if (args.is_redacted !== undefined && args.is_redacted !== null) {
      this.is_redacted = args.is_redacted;
    }
    if (args.is_new !== undefined && args.is_new !== null) {
      this.is_new = args.is_new;
    }
  }
};
ConversationMessage.prototype = {};
ConversationMessage.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.message_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.conversation_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.content = new ttypes.ConsultData();
        this.content.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.sender_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.created_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.shared_from = new ttypes.ConversationMessage();
        this.shared_from.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.LIST) {
        this.shared_to = [];
        var _rtmp3101 = input.readListBegin();
        var _size100 = _rtmp3101.size || 0;
        for (var _i102 = 0; _i102 < _size100; ++_i102) {
          var elem103 = null;
          elem103 = new ttypes.ConversationMessage();
          elem103.read(input);
          this.shared_to.push(elem103);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I64) {
        this.patient_view_count = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.BOOL) {
        this.patient_has_watched = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.BOOL) {
        this.is_redacted = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.BOOL) {
        this.is_new = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConversationMessage.prototype.write = function(output) {
  output.writeStructBegin('ConversationMessage');
  if (this.message_id !== null && this.message_id !== undefined) {
    output.writeFieldBegin('message_id', Thrift.Type.STRING, 1);
    output.writeString(this.message_id);
    output.writeFieldEnd();
  }
  if (this.conversation_id !== null && this.conversation_id !== undefined) {
    output.writeFieldBegin('conversation_id', Thrift.Type.STRING, 2);
    output.writeString(this.conversation_id);
    output.writeFieldEnd();
  }
  if (this.content !== null && this.content !== undefined) {
    output.writeFieldBegin('content', Thrift.Type.STRUCT, 3);
    this.content.write(output);
    output.writeFieldEnd();
  }
  if (this.sender_id !== null && this.sender_id !== undefined) {
    output.writeFieldBegin('sender_id', Thrift.Type.STRING, 4);
    output.writeString(this.sender_id);
    output.writeFieldEnd();
  }
  if (this.created_at !== null && this.created_at !== undefined) {
    output.writeFieldBegin('created_at', Thrift.Type.I64, 5);
    output.writeI64(this.created_at);
    output.writeFieldEnd();
  }
  if (this.shared_from !== null && this.shared_from !== undefined) {
    output.writeFieldBegin('shared_from', Thrift.Type.STRUCT, 6);
    this.shared_from.write(output);
    output.writeFieldEnd();
  }
  if (this.shared_to !== null && this.shared_to !== undefined) {
    output.writeFieldBegin('shared_to', Thrift.Type.LIST, 7);
    output.writeListBegin(Thrift.Type.STRUCT, this.shared_to.length);
    for (var iter104 in this.shared_to) {
      if (this.shared_to.hasOwnProperty(iter104)) {
        iter104 = this.shared_to[iter104];
        iter104.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.I32, 8);
    output.writeI32(this.state);
    output.writeFieldEnd();
  }
  if (this.patient_view_count !== null && this.patient_view_count !== undefined) {
    output.writeFieldBegin('patient_view_count', Thrift.Type.I64, 9);
    output.writeI64(this.patient_view_count);
    output.writeFieldEnd();
  }
  if (this.patient_has_watched !== null && this.patient_has_watched !== undefined) {
    output.writeFieldBegin('patient_has_watched', Thrift.Type.BOOL, 10);
    output.writeBool(this.patient_has_watched);
    output.writeFieldEnd();
  }
  if (this.is_redacted !== null && this.is_redacted !== undefined) {
    output.writeFieldBegin('is_redacted', Thrift.Type.BOOL, 11);
    output.writeBool(this.is_redacted);
    output.writeFieldEnd();
  }
  if (this.is_new !== null && this.is_new !== undefined) {
    output.writeFieldBegin('is_new', Thrift.Type.BOOL, 12);
    output.writeBool(this.is_new);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConversationUpdates = module.exports.ConversationUpdates = function(args) {
  this.participant_ids = null;
  if (args) {
    if (args.participant_ids !== undefined && args.participant_ids !== null) {
      this.participant_ids = new ttypes.StringSetMutation(args.participant_ids);
    }
  }
};
ConversationUpdates.prototype = {};
ConversationUpdates.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.participant_ids = new ttypes.StringSetMutation();
        this.participant_ids.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConversationUpdates.prototype.write = function(output) {
  output.writeStructBegin('ConversationUpdates');
  if (this.participant_ids !== null && this.participant_ids !== undefined) {
    output.writeFieldBegin('participant_ids', Thrift.Type.STRUCT, 1);
    this.participant_ids.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DicomStudy = module.exports.DicomStudy = function(args) {
  this.study_id = null;
  this.study_instance_uid = null;
  this.description = null;
  this.consult_id = null;
  this.created_at = null;
  this.updated_at = null;
  this.study_type = null;
  this.is_new = null;
  this.case_id = null;
  if (args) {
    if (args.study_id !== undefined && args.study_id !== null) {
      this.study_id = args.study_id;
    }
    if (args.study_instance_uid !== undefined && args.study_instance_uid !== null) {
      this.study_instance_uid = args.study_instance_uid;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.created_at !== undefined && args.created_at !== null) {
      this.created_at = args.created_at;
    }
    if (args.updated_at !== undefined && args.updated_at !== null) {
      this.updated_at = args.updated_at;
    }
    if (args.study_type !== undefined && args.study_type !== null) {
      this.study_type = args.study_type;
    }
    if (args.is_new !== undefined && args.is_new !== null) {
      this.is_new = args.is_new;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
  }
};
DicomStudy.prototype = {};
DicomStudy.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.study_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.study_instance_uid = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.created_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.updated_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.study_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.BOOL) {
        this.is_new = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DicomStudy.prototype.write = function(output) {
  output.writeStructBegin('DicomStudy');
  if (this.study_id !== null && this.study_id !== undefined) {
    output.writeFieldBegin('study_id', Thrift.Type.STRING, 1);
    output.writeString(this.study_id);
    output.writeFieldEnd();
  }
  if (this.study_instance_uid !== null && this.study_instance_uid !== undefined) {
    output.writeFieldBegin('study_instance_uid', Thrift.Type.STRING, 2);
    output.writeString(this.study_instance_uid);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 3);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 4);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.created_at !== null && this.created_at !== undefined) {
    output.writeFieldBegin('created_at', Thrift.Type.I64, 5);
    output.writeI64(this.created_at);
    output.writeFieldEnd();
  }
  if (this.updated_at !== null && this.updated_at !== undefined) {
    output.writeFieldBegin('updated_at', Thrift.Type.I64, 6);
    output.writeI64(this.updated_at);
    output.writeFieldEnd();
  }
  if (this.study_type !== null && this.study_type !== undefined) {
    output.writeFieldBegin('study_type', Thrift.Type.I32, 7);
    output.writeI32(this.study_type);
    output.writeFieldEnd();
  }
  if (this.is_new !== null && this.is_new !== undefined) {
    output.writeFieldBegin('is_new', Thrift.Type.BOOL, 8);
    output.writeBool(this.is_new);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 9);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Email = module.exports.Email = function(args) {
  this.rendered_subject = null;
  this.rendered_message = null;
  this.custom_subject = null;
  this.custom_message = null;
  this.email_id = null;
  this.recipient_user_id = null;
  this.sender_user_id = null;
  this.created_at = null;
  this.destination_address = null;
  this.sender_full_name = null;
  if (args) {
    if (args.rendered_subject !== undefined && args.rendered_subject !== null) {
      this.rendered_subject = args.rendered_subject;
    }
    if (args.rendered_message !== undefined && args.rendered_message !== null) {
      this.rendered_message = args.rendered_message;
    }
    if (args.custom_subject !== undefined && args.custom_subject !== null) {
      this.custom_subject = args.custom_subject;
    }
    if (args.custom_message !== undefined && args.custom_message !== null) {
      this.custom_message = args.custom_message;
    }
    if (args.email_id !== undefined && args.email_id !== null) {
      this.email_id = args.email_id;
    }
    if (args.recipient_user_id !== undefined && args.recipient_user_id !== null) {
      this.recipient_user_id = args.recipient_user_id;
    }
    if (args.sender_user_id !== undefined && args.sender_user_id !== null) {
      this.sender_user_id = args.sender_user_id;
    }
    if (args.created_at !== undefined && args.created_at !== null) {
      this.created_at = args.created_at;
    }
    if (args.destination_address !== undefined && args.destination_address !== null) {
      this.destination_address = args.destination_address;
    }
    if (args.sender_full_name !== undefined && args.sender_full_name !== null) {
      this.sender_full_name = args.sender_full_name;
    }
  }
};
Email.prototype = {};
Email.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.rendered_subject = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.rendered_message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.custom_subject = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.custom_message = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.email_id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.recipient_user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.sender_user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.I64) {
        this.created_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.destination_address = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRING) {
        this.sender_full_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Email.prototype.write = function(output) {
  output.writeStructBegin('Email');
  if (this.rendered_subject !== null && this.rendered_subject !== undefined) {
    output.writeFieldBegin('rendered_subject', Thrift.Type.STRING, 1);
    output.writeString(this.rendered_subject);
    output.writeFieldEnd();
  }
  if (this.rendered_message !== null && this.rendered_message !== undefined) {
    output.writeFieldBegin('rendered_message', Thrift.Type.STRING, 2);
    output.writeString(this.rendered_message);
    output.writeFieldEnd();
  }
  if (this.custom_subject !== null && this.custom_subject !== undefined) {
    output.writeFieldBegin('custom_subject', Thrift.Type.STRING, 3);
    output.writeString(this.custom_subject);
    output.writeFieldEnd();
  }
  if (this.custom_message !== null && this.custom_message !== undefined) {
    output.writeFieldBegin('custom_message', Thrift.Type.STRING, 4);
    output.writeString(this.custom_message);
    output.writeFieldEnd();
  }
  if (this.email_id !== null && this.email_id !== undefined) {
    output.writeFieldBegin('email_id', Thrift.Type.I64, 5);
    output.writeI64(this.email_id);
    output.writeFieldEnd();
  }
  if (this.recipient_user_id !== null && this.recipient_user_id !== undefined) {
    output.writeFieldBegin('recipient_user_id', Thrift.Type.STRING, 6);
    output.writeString(this.recipient_user_id);
    output.writeFieldEnd();
  }
  if (this.sender_user_id !== null && this.sender_user_id !== undefined) {
    output.writeFieldBegin('sender_user_id', Thrift.Type.STRING, 7);
    output.writeString(this.sender_user_id);
    output.writeFieldEnd();
  }
  if (this.created_at !== null && this.created_at !== undefined) {
    output.writeFieldBegin('created_at', Thrift.Type.I64, 10);
    output.writeI64(this.created_at);
    output.writeFieldEnd();
  }
  if (this.destination_address !== null && this.destination_address !== undefined) {
    output.writeFieldBegin('destination_address', Thrift.Type.STRING, 11);
    output.writeString(this.destination_address);
    output.writeFieldEnd();
  }
  if (this.sender_full_name !== null && this.sender_full_name !== undefined) {
    output.writeFieldBegin('sender_full_name', Thrift.Type.STRING, 12);
    output.writeString(this.sender_full_name);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Event = module.exports.Event = function(args) {
  this.event_type = null;
  this.actor_id = null;
  this.timestamp = null;
  this.action = null;
  this.actor_full_name = null;
  if (args) {
    if (args.event_type !== undefined && args.event_type !== null) {
      this.event_type = args.event_type;
    }
    if (args.actor_id !== undefined && args.actor_id !== null) {
      this.actor_id = args.actor_id;
    }
    if (args.timestamp !== undefined && args.timestamp !== null) {
      this.timestamp = args.timestamp;
    }
    if (args.action !== undefined && args.action !== null) {
      this.action = args.action;
    }
    if (args.actor_full_name !== undefined && args.actor_full_name !== null) {
      this.actor_full_name = args.actor_full_name;
    }
  }
};
Event.prototype = {};
Event.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.event_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.actor_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.timestamp = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.action = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.actor_full_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Event.prototype.write = function(output) {
  output.writeStructBegin('Event');
  if (this.event_type !== null && this.event_type !== undefined) {
    output.writeFieldBegin('event_type', Thrift.Type.I32, 1);
    output.writeI32(this.event_type);
    output.writeFieldEnd();
  }
  if (this.actor_id !== null && this.actor_id !== undefined) {
    output.writeFieldBegin('actor_id', Thrift.Type.STRING, 2);
    output.writeString(this.actor_id);
    output.writeFieldEnd();
  }
  if (this.timestamp !== null && this.timestamp !== undefined) {
    output.writeFieldBegin('timestamp', Thrift.Type.I64, 3);
    output.writeI64(this.timestamp);
    output.writeFieldEnd();
  }
  if (this.action !== null && this.action !== undefined) {
    output.writeFieldBegin('action', Thrift.Type.STRING, 4);
    output.writeString(this.action);
    output.writeFieldEnd();
  }
  if (this.actor_full_name !== null && this.actor_full_name !== undefined) {
    output.writeFieldBegin('actor_full_name', Thrift.Type.STRING, 5);
    output.writeString(this.actor_full_name);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ExternalDocument = module.exports.ExternalDocument = function(args) {
  this.integration_type = null;
  this.athena_doc = null;
  if (args) {
    if (args.integration_type !== undefined && args.integration_type !== null) {
      this.integration_type = args.integration_type;
    }
    if (args.athena_doc !== undefined && args.athena_doc !== null) {
      this.athena_doc = new ttypes.AthenaDocument(args.athena_doc);
    }
  }
};
ExternalDocument.prototype = {};
ExternalDocument.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.integration_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.athena_doc = new ttypes.AthenaDocument();
        this.athena_doc.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ExternalDocument.prototype.write = function(output) {
  output.writeStructBegin('ExternalDocument');
  if (this.integration_type !== null && this.integration_type !== undefined) {
    output.writeFieldBegin('integration_type', Thrift.Type.I32, 1);
    output.writeI32(this.integration_type);
    output.writeFieldEnd();
  }
  if (this.athena_doc !== null && this.athena_doc !== undefined) {
    output.writeFieldBegin('athena_doc', Thrift.Type.STRUCT, 2);
    this.athena_doc.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Filter = module.exports.Filter = function(args) {
  this.attribute = null;
  this.values_string = null;
  this.values_int = null;
  this.value_bool = null;
  this.range = null;
  if (args) {
    if (args.attribute !== undefined && args.attribute !== null) {
      this.attribute = args.attribute;
    }
    if (args.values_string !== undefined && args.values_string !== null) {
      this.values_string = Thrift.copyList(args.values_string, [null]);
    }
    if (args.values_int !== undefined && args.values_int !== null) {
      this.values_int = Thrift.copyList(args.values_int, [null]);
    }
    if (args.value_bool !== undefined && args.value_bool !== null) {
      this.value_bool = args.value_bool;
    }
    if (args.range !== undefined && args.range !== null) {
      this.range = new ttypes.Range(args.range);
    }
  }
};
Filter.prototype = {};
Filter.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.attribute = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.values_string = [];
        var _rtmp3106 = input.readListBegin();
        var _size105 = _rtmp3106.size || 0;
        for (var _i107 = 0; _i107 < _size105; ++_i107) {
          var elem108 = null;
          elem108 = input.readString();
          this.values_string.push(elem108);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        this.values_int = [];
        var _rtmp3110 = input.readListBegin();
        var _size109 = _rtmp3110.size || 0;
        for (var _i111 = 0; _i111 < _size109; ++_i111) {
          var elem112 = null;
          elem112 = input.readI64();
          this.values_int.push(elem112);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.value_bool = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.range = new ttypes.Range();
        this.range.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Filter.prototype.write = function(output) {
  output.writeStructBegin('Filter');
  if (this.attribute !== null && this.attribute !== undefined) {
    output.writeFieldBegin('attribute', Thrift.Type.STRING, 1);
    output.writeString(this.attribute);
    output.writeFieldEnd();
  }
  if (this.values_string !== null && this.values_string !== undefined) {
    output.writeFieldBegin('values_string', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.values_string.length);
    for (var iter113 in this.values_string) {
      if (this.values_string.hasOwnProperty(iter113)) {
        iter113 = this.values_string[iter113];
        output.writeString(iter113);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.values_int !== null && this.values_int !== undefined) {
    output.writeFieldBegin('values_int', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.I64, this.values_int.length);
    for (var iter114 in this.values_int) {
      if (this.values_int.hasOwnProperty(iter114)) {
        iter114 = this.values_int[iter114];
        output.writeI64(iter114);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.value_bool !== null && this.value_bool !== undefined) {
    output.writeFieldBegin('value_bool', Thrift.Type.BOOL, 4);
    output.writeBool(this.value_bool);
    output.writeFieldEnd();
  }
  if (this.range !== null && this.range !== undefined) {
    output.writeFieldBegin('range', Thrift.Type.STRUCT, 5);
    this.range.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Sort = module.exports.Sort = function(args) {
  this.attribute = null;
  this.direction = null;
  if (args) {
    if (args.attribute !== undefined && args.attribute !== null) {
      this.attribute = args.attribute;
    }
    if (args.direction !== undefined && args.direction !== null) {
      this.direction = args.direction;
    }
  }
};
Sort.prototype = {};
Sort.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.attribute = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.direction = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Sort.prototype.write = function(output) {
  output.writeStructBegin('Sort');
  if (this.attribute !== null && this.attribute !== undefined) {
    output.writeFieldBegin('attribute', Thrift.Type.STRING, 1);
    output.writeString(this.attribute);
    output.writeFieldEnd();
  }
  if (this.direction !== null && this.direction !== undefined) {
    output.writeFieldBegin('direction', Thrift.Type.I32, 2);
    output.writeI32(this.direction);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var NewConversationMessage = module.exports.NewConversationMessage = function(args) {
  this.media_keys = null;
  this.text = null;
  this.shared_message_id = null;
  this.recording_id = null;
  this.specialist_response = null;
  if (args) {
    if (args.media_keys !== undefined && args.media_keys !== null) {
      this.media_keys = Thrift.copyList(args.media_keys, [null]);
    }
    if (args.text !== undefined && args.text !== null) {
      this.text = args.text;
    }
    if (args.shared_message_id !== undefined && args.shared_message_id !== null) {
      this.shared_message_id = args.shared_message_id;
    }
    if (args.recording_id !== undefined && args.recording_id !== null) {
      this.recording_id = args.recording_id;
    }
    if (args.specialist_response !== undefined && args.specialist_response !== null) {
      this.specialist_response = new ttypes.SpecialistResponse(args.specialist_response);
    }
  }
};
NewConversationMessage.prototype = {};
NewConversationMessage.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.media_keys = [];
        var _rtmp3116 = input.readListBegin();
        var _size115 = _rtmp3116.size || 0;
        for (var _i117 = 0; _i117 < _size115; ++_i117) {
          var elem118 = null;
          elem118 = input.readString();
          this.media_keys.push(elem118);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.text = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.shared_message_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.recording_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialist_response = new ttypes.SpecialistResponse();
        this.specialist_response.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

NewConversationMessage.prototype.write = function(output) {
  output.writeStructBegin('NewConversationMessage');
  if (this.media_keys !== null && this.media_keys !== undefined) {
    output.writeFieldBegin('media_keys', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.media_keys.length);
    for (var iter119 in this.media_keys) {
      if (this.media_keys.hasOwnProperty(iter119)) {
        iter119 = this.media_keys[iter119];
        output.writeString(iter119);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.text !== null && this.text !== undefined) {
    output.writeFieldBegin('text', Thrift.Type.STRING, 2);
    output.writeString(this.text);
    output.writeFieldEnd();
  }
  if (this.shared_message_id !== null && this.shared_message_id !== undefined) {
    output.writeFieldBegin('shared_message_id', Thrift.Type.STRING, 3);
    output.writeString(this.shared_message_id);
    output.writeFieldEnd();
  }
  if (this.recording_id !== null && this.recording_id !== undefined) {
    output.writeFieldBegin('recording_id', Thrift.Type.STRING, 4);
    output.writeString(this.recording_id);
    output.writeFieldEnd();
  }
  if (this.specialist_response !== null && this.specialist_response !== undefined) {
    output.writeFieldBegin('specialist_response', Thrift.Type.STRUCT, 5);
    this.specialist_response.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ObjectTypeQuery = module.exports.ObjectTypeQuery = function(args) {
  this.object_type = null;
  this.object_ids = null;
  if (args) {
    if (args.object_type !== undefined && args.object_type !== null) {
      this.object_type = args.object_type;
    }
    if (args.object_ids !== undefined && args.object_ids !== null) {
      this.object_ids = Thrift.copyList(args.object_ids, [null]);
    }
  }
};
ObjectTypeQuery.prototype = {};
ObjectTypeQuery.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.object_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.object_ids = [];
        var _rtmp3121 = input.readListBegin();
        var _size120 = _rtmp3121.size || 0;
        for (var _i122 = 0; _i122 < _size120; ++_i122) {
          var elem123 = null;
          elem123 = input.readString();
          this.object_ids.push(elem123);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ObjectTypeQuery.prototype.write = function(output) {
  output.writeStructBegin('ObjectTypeQuery');
  if (this.object_type !== null && this.object_type !== undefined) {
    output.writeFieldBegin('object_type', Thrift.Type.I32, 1);
    output.writeI32(this.object_type);
    output.writeFieldEnd();
  }
  if (this.object_ids !== null && this.object_ids !== undefined) {
    output.writeFieldBegin('object_ids', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.object_ids.length);
    for (var iter124 in this.object_ids) {
      if (this.object_ids.hasOwnProperty(iter124)) {
        iter124 = this.object_ids[iter124];
        output.writeString(iter124);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PaginationMarkers = module.exports.PaginationMarkers = function(args) {
  this.next_page_cursor = null;
  this.prev_page_cursor = null;
  if (args) {
    if (args.next_page_cursor !== undefined && args.next_page_cursor !== null) {
      this.next_page_cursor = args.next_page_cursor;
    }
    if (args.prev_page_cursor !== undefined && args.prev_page_cursor !== null) {
      this.prev_page_cursor = args.prev_page_cursor;
    }
  }
};
PaginationMarkers.prototype = {};
PaginationMarkers.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.next_page_cursor = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.prev_page_cursor = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PaginationMarkers.prototype.write = function(output) {
  output.writeStructBegin('PaginationMarkers');
  if (this.next_page_cursor !== null && this.next_page_cursor !== undefined) {
    output.writeFieldBegin('next_page_cursor', Thrift.Type.STRING, 1);
    output.writeString(this.next_page_cursor);
    output.writeFieldEnd();
  }
  if (this.prev_page_cursor !== null && this.prev_page_cursor !== undefined) {
    output.writeFieldBegin('prev_page_cursor', Thrift.Type.STRING, 2);
    output.writeString(this.prev_page_cursor);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PaginationParameters = module.exports.PaginationParameters = function(args) {
  this.filters = null;
  this.sorts = null;
  this.page_size = null;
  this.page_cursor = null;
  if (args) {
    if (args.filters !== undefined && args.filters !== null) {
      this.filters = Thrift.copyList(args.filters, [ttypes.Filter]);
    }
    if (args.sorts !== undefined && args.sorts !== null) {
      this.sorts = Thrift.copyList(args.sorts, [ttypes.Sort]);
    }
    if (args.page_size !== undefined && args.page_size !== null) {
      this.page_size = args.page_size;
    }
    if (args.page_cursor !== undefined && args.page_cursor !== null) {
      this.page_cursor = args.page_cursor;
    }
  }
};
PaginationParameters.prototype = {};
PaginationParameters.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.filters = [];
        var _rtmp3126 = input.readListBegin();
        var _size125 = _rtmp3126.size || 0;
        for (var _i127 = 0; _i127 < _size125; ++_i127) {
          var elem128 = null;
          elem128 = new ttypes.Filter();
          elem128.read(input);
          this.filters.push(elem128);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.sorts = [];
        var _rtmp3130 = input.readListBegin();
        var _size129 = _rtmp3130.size || 0;
        for (var _i131 = 0; _i131 < _size129; ++_i131) {
          var elem132 = null;
          elem132 = new ttypes.Sort();
          elem132.read(input);
          this.sorts.push(elem132);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.page_size = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.page_cursor = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PaginationParameters.prototype.write = function(output) {
  output.writeStructBegin('PaginationParameters');
  if (this.filters !== null && this.filters !== undefined) {
    output.writeFieldBegin('filters', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.filters.length);
    for (var iter133 in this.filters) {
      if (this.filters.hasOwnProperty(iter133)) {
        iter133 = this.filters[iter133];
        iter133.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.sorts !== null && this.sorts !== undefined) {
    output.writeFieldBegin('sorts', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.sorts.length);
    for (var iter134 in this.sorts) {
      if (this.sorts.hasOwnProperty(iter134)) {
        iter134 = this.sorts[iter134];
        iter134.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.page_size !== null && this.page_size !== undefined) {
    output.writeFieldBegin('page_size', Thrift.Type.I32, 3);
    output.writeI32(this.page_size);
    output.writeFieldEnd();
  }
  if (this.page_cursor !== null && this.page_cursor !== undefined) {
    output.writeFieldBegin('page_cursor', Thrift.Type.STRING, 4);
    output.writeString(this.page_cursor);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Partner = module.exports.Partner = function(args) {
  this.partner_id = null;
  this.name = null;
  this.logo_url = null;
  this.shared_patient_page_intro = null;
  this.specialties = null;
  this.logo_state = null;
  this.create_cases_sitka = null;
  this.create_consults_sitka = null;
  this.enable_patient_communications = null;
  this.enable_screen_sharing = null;
  if (args) {
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.logo_url !== undefined && args.logo_url !== null) {
      this.logo_url = args.logo_url;
    }
    if (args.shared_patient_page_intro !== undefined && args.shared_patient_page_intro !== null) {
      this.shared_patient_page_intro = args.shared_patient_page_intro;
    }
    if (args.specialties !== undefined && args.specialties !== null) {
      this.specialties = Thrift.copyList(args.specialties, [null]);
    }
    if (args.logo_state !== undefined && args.logo_state !== null) {
      this.logo_state = args.logo_state;
    }
    if (args.create_cases_sitka !== undefined && args.create_cases_sitka !== null) {
      this.create_cases_sitka = args.create_cases_sitka;
    }
    if (args.create_consults_sitka !== undefined && args.create_consults_sitka !== null) {
      this.create_consults_sitka = args.create_consults_sitka;
    }
    if (args.enable_patient_communications !== undefined && args.enable_patient_communications !== null) {
      this.enable_patient_communications = args.enable_patient_communications;
    }
    if (args.enable_screen_sharing !== undefined && args.enable_screen_sharing !== null) {
      this.enable_screen_sharing = args.enable_screen_sharing;
    }
  }
};
Partner.prototype = {};
Partner.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.logo_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.shared_patient_page_intro = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.LIST) {
        this.specialties = [];
        var _rtmp3136 = input.readListBegin();
        var _size135 = _rtmp3136.size || 0;
        for (var _i137 = 0; _i137 < _size135; ++_i137) {
          var elem138 = null;
          elem138 = input.readI32();
          this.specialties.push(elem138);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.I32) {
        this.logo_state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.BOOL) {
        this.create_cases_sitka = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.BOOL) {
        this.create_consults_sitka = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.BOOL) {
        this.enable_patient_communications = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.BOOL) {
        this.enable_screen_sharing = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Partner.prototype.write = function(output) {
  output.writeStructBegin('Partner');
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 1);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 3);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.logo_url !== null && this.logo_url !== undefined) {
    output.writeFieldBegin('logo_url', Thrift.Type.STRING, 6);
    output.writeString(this.logo_url);
    output.writeFieldEnd();
  }
  if (this.shared_patient_page_intro !== null && this.shared_patient_page_intro !== undefined) {
    output.writeFieldBegin('shared_patient_page_intro', Thrift.Type.STRING, 7);
    output.writeString(this.shared_patient_page_intro);
    output.writeFieldEnd();
  }
  if (this.specialties !== null && this.specialties !== undefined) {
    output.writeFieldBegin('specialties', Thrift.Type.LIST, 8);
    output.writeListBegin(Thrift.Type.I32, this.specialties.length);
    for (var iter139 in this.specialties) {
      if (this.specialties.hasOwnProperty(iter139)) {
        iter139 = this.specialties[iter139];
        output.writeI32(iter139);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.logo_state !== null && this.logo_state !== undefined) {
    output.writeFieldBegin('logo_state', Thrift.Type.I32, 9);
    output.writeI32(this.logo_state);
    output.writeFieldEnd();
  }
  if (this.create_cases_sitka !== null && this.create_cases_sitka !== undefined) {
    output.writeFieldBegin('create_cases_sitka', Thrift.Type.BOOL, 10);
    output.writeBool(this.create_cases_sitka);
    output.writeFieldEnd();
  }
  if (this.create_consults_sitka !== null && this.create_consults_sitka !== undefined) {
    output.writeFieldBegin('create_consults_sitka', Thrift.Type.BOOL, 11);
    output.writeBool(this.create_consults_sitka);
    output.writeFieldEnd();
  }
  if (this.enable_patient_communications !== null && this.enable_patient_communications !== undefined) {
    output.writeFieldBegin('enable_patient_communications', Thrift.Type.BOOL, 12);
    output.writeBool(this.enable_patient_communications);
    output.writeFieldEnd();
  }
  if (this.enable_screen_sharing !== null && this.enable_screen_sharing !== undefined) {
    output.writeFieldBegin('enable_screen_sharing', Thrift.Type.BOOL, 13);
    output.writeBool(this.enable_screen_sharing);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PartnerMemberRoles = module.exports.PartnerMemberRoles = function(args) {
  this.is_billable = null;
  this.is_reportable = null;
  this.is_support = null;
  if (args) {
    if (args.is_billable !== undefined && args.is_billable !== null) {
      this.is_billable = args.is_billable;
    }
    if (args.is_reportable !== undefined && args.is_reportable !== null) {
      this.is_reportable = args.is_reportable;
    }
    if (args.is_support !== undefined && args.is_support !== null) {
      this.is_support = args.is_support;
    }
  }
};
PartnerMemberRoles.prototype = {};
PartnerMemberRoles.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.BOOL) {
        this.is_billable = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.is_reportable = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.is_support = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PartnerMemberRoles.prototype.write = function(output) {
  output.writeStructBegin('PartnerMemberRoles');
  if (this.is_billable !== null && this.is_billable !== undefined) {
    output.writeFieldBegin('is_billable', Thrift.Type.BOOL, 1);
    output.writeBool(this.is_billable);
    output.writeFieldEnd();
  }
  if (this.is_reportable !== null && this.is_reportable !== undefined) {
    output.writeFieldBegin('is_reportable', Thrift.Type.BOOL, 2);
    output.writeBool(this.is_reportable);
    output.writeFieldEnd();
  }
  if (this.is_support !== null && this.is_support !== undefined) {
    output.writeFieldBegin('is_support', Thrift.Type.BOOL, 3);
    output.writeBool(this.is_support);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Range = module.exports.Range = function(args) {
  this.range_start = null;
  this.range_end = null;
  if (args) {
    if (args.range_start !== undefined && args.range_start !== null) {
      this.range_start = args.range_start;
    }
    if (args.range_end !== undefined && args.range_end !== null) {
      this.range_end = args.range_end;
    }
  }
};
Range.prototype = {};
Range.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.range_start = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.range_end = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Range.prototype.write = function(output) {
  output.writeStructBegin('Range');
  if (this.range_start !== null && this.range_start !== undefined) {
    output.writeFieldBegin('range_start', Thrift.Type.I64, 1);
    output.writeI64(this.range_start);
    output.writeFieldEnd();
  }
  if (this.range_end !== null && this.range_end !== undefined) {
    output.writeFieldBegin('range_end', Thrift.Type.I64, 2);
    output.writeI64(this.range_end);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RedactedMessage = module.exports.RedactedMessage = function(args) {
  this.conversation_message_id = null;
  this.redacted = null;
  this.redacted_reason = null;
  if (args) {
    if (args.conversation_message_id !== undefined && args.conversation_message_id !== null) {
      this.conversation_message_id = args.conversation_message_id;
    }
    if (args.redacted !== undefined && args.redacted !== null) {
      this.redacted = args.redacted;
    }
    if (args.redacted_reason !== undefined && args.redacted_reason !== null) {
      this.redacted_reason = args.redacted_reason;
    }
  }
};
RedactedMessage.prototype = {};
RedactedMessage.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.conversation_message_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.redacted = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.redacted_reason = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RedactedMessage.prototype.write = function(output) {
  output.writeStructBegin('RedactedMessage');
  if (this.conversation_message_id !== null && this.conversation_message_id !== undefined) {
    output.writeFieldBegin('conversation_message_id', Thrift.Type.STRING, 1);
    output.writeString(this.conversation_message_id);
    output.writeFieldEnd();
  }
  if (this.redacted !== null && this.redacted !== undefined) {
    output.writeFieldBegin('redacted', Thrift.Type.BOOL, 2);
    output.writeBool(this.redacted);
    output.writeFieldEnd();
  }
  if (this.redacted_reason !== null && this.redacted_reason !== undefined) {
    output.writeFieldBegin('redacted_reason', Thrift.Type.I32, 3);
    output.writeI32(this.redacted_reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserInfo = module.exports.UserInfo = function(args) {
  this.email = null;
  this.first_name = null;
  this.last_name = null;
  this.phone_number = null;
  this.state = null;
  this.city = null;
  this.text_message_permission = null;
  this.date_of_birth = null;
  this.sex = null;
  this.is_sitka_operations = null;
  this.terms_and_conditions_consent = null;
  this.mfa_required = null;
  this.specialties = null;
  this.provider_npi = null;
  this.title = null;
  this.credentials = null;
  this.provider_insurance_carrier = null;
  this.patient_external_id = null;
  this.is_sitka_medical_associate = null;
  this.is_active = null;
  this.external_user_id = null;
  this.exercises_okta = null;
  this.medicare_beneficiary_identifier = null;
  this.is_sitka_clinical_reviewer = null;
  this.licenses = null;
  this.specialist_data = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.first_name !== undefined && args.first_name !== null) {
      this.first_name = args.first_name;
    }
    if (args.last_name !== undefined && args.last_name !== null) {
      this.last_name = args.last_name;
    }
    if (args.phone_number !== undefined && args.phone_number !== null) {
      this.phone_number = args.phone_number;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.city !== undefined && args.city !== null) {
      this.city = args.city;
    }
    if (args.text_message_permission !== undefined && args.text_message_permission !== null) {
      this.text_message_permission = args.text_message_permission;
    }
    if (args.date_of_birth !== undefined && args.date_of_birth !== null) {
      this.date_of_birth = new ttypes.DateOfBirth(args.date_of_birth);
    }
    if (args.sex !== undefined && args.sex !== null) {
      this.sex = args.sex;
    }
    if (args.is_sitka_operations !== undefined && args.is_sitka_operations !== null) {
      this.is_sitka_operations = args.is_sitka_operations;
    }
    if (args.terms_and_conditions_consent !== undefined && args.terms_and_conditions_consent !== null) {
      this.terms_and_conditions_consent = args.terms_and_conditions_consent;
    }
    if (args.mfa_required !== undefined && args.mfa_required !== null) {
      this.mfa_required = args.mfa_required;
    }
    if (args.specialties !== undefined && args.specialties !== null) {
      this.specialties = Thrift.copyList(args.specialties, [null]);
    }
    if (args.provider_npi !== undefined && args.provider_npi !== null) {
      this.provider_npi = args.provider_npi;
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.credentials !== undefined && args.credentials !== null) {
      this.credentials = Thrift.copyList(args.credentials, [null]);
    }
    if (args.provider_insurance_carrier !== undefined && args.provider_insurance_carrier !== null) {
      this.provider_insurance_carrier = args.provider_insurance_carrier;
    }
    if (args.patient_external_id !== undefined && args.patient_external_id !== null) {
      this.patient_external_id = args.patient_external_id;
    }
    if (args.is_sitka_medical_associate !== undefined && args.is_sitka_medical_associate !== null) {
      this.is_sitka_medical_associate = args.is_sitka_medical_associate;
    }
    if (args.is_active !== undefined && args.is_active !== null) {
      this.is_active = args.is_active;
    }
    if (args.external_user_id !== undefined && args.external_user_id !== null) {
      this.external_user_id = args.external_user_id;
    }
    if (args.exercises_okta !== undefined && args.exercises_okta !== null) {
      this.exercises_okta = args.exercises_okta;
    }
    if (args.medicare_beneficiary_identifier !== undefined && args.medicare_beneficiary_identifier !== null) {
      this.medicare_beneficiary_identifier = args.medicare_beneficiary_identifier;
    }
    if (args.is_sitka_clinical_reviewer !== undefined && args.is_sitka_clinical_reviewer !== null) {
      this.is_sitka_clinical_reviewer = args.is_sitka_clinical_reviewer;
    }
    if (args.licenses !== undefined && args.licenses !== null) {
      this.licenses = Thrift.copyList(args.licenses, [null]);
    }
    if (args.specialist_data !== undefined && args.specialist_data !== null) {
      this.specialist_data = new ttypes.SpecialistDataUpdates(args.specialist_data);
    }
  }
};
UserInfo.prototype = {};
UserInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.first_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.last_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.phone_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.state = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.city = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.BOOL) {
        this.text_message_permission = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.date_of_birth = new ttypes.DateOfBirth();
        this.date_of_birth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.I32) {
        this.sex = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.BOOL) {
        this.is_sitka_operations = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 14:
      if (ftype == Thrift.Type.BOOL) {
        this.terms_and_conditions_consent = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.BOOL) {
        this.mfa_required = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.LIST) {
        this.specialties = [];
        var _rtmp3141 = input.readListBegin();
        var _size140 = _rtmp3141.size || 0;
        for (var _i142 = 0; _i142 < _size140; ++_i142) {
          var elem143 = null;
          elem143 = input.readI32();
          this.specialties.push(elem143);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 18:
      if (ftype == Thrift.Type.STRING) {
        this.provider_npi = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.I32) {
        this.title = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.LIST) {
        this.credentials = [];
        var _rtmp3145 = input.readListBegin();
        var _size144 = _rtmp3145.size || 0;
        for (var _i146 = 0; _i146 < _size144; ++_i146) {
          var elem147 = null;
          elem147 = input.readI32();
          this.credentials.push(elem147);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.STRING) {
        this.provider_insurance_carrier = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.STRING) {
        this.patient_external_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 23:
      if (ftype == Thrift.Type.BOOL) {
        this.is_sitka_medical_associate = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 24:
      if (ftype == Thrift.Type.BOOL) {
        this.is_active = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 25:
      if (ftype == Thrift.Type.STRING) {
        this.external_user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 26:
      if (ftype == Thrift.Type.BOOL) {
        this.exercises_okta = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 27:
      if (ftype == Thrift.Type.STRING) {
        this.medicare_beneficiary_identifier = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 28:
      if (ftype == Thrift.Type.BOOL) {
        this.is_sitka_clinical_reviewer = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 29:
      if (ftype == Thrift.Type.LIST) {
        this.licenses = [];
        var _rtmp3149 = input.readListBegin();
        var _size148 = _rtmp3149.size || 0;
        for (var _i150 = 0; _i150 < _size148; ++_i150) {
          var elem151 = null;
          elem151 = new ttypes.UserLicense();
          elem151.read(input);
          this.licenses.push(elem151);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 30:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialist_data = new ttypes.SpecialistDataUpdates();
        this.specialist_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserInfo.prototype.write = function(output) {
  output.writeStructBegin('UserInfo');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.first_name !== null && this.first_name !== undefined) {
    output.writeFieldBegin('first_name', Thrift.Type.STRING, 2);
    output.writeString(this.first_name);
    output.writeFieldEnd();
  }
  if (this.last_name !== null && this.last_name !== undefined) {
    output.writeFieldBegin('last_name', Thrift.Type.STRING, 3);
    output.writeString(this.last_name);
    output.writeFieldEnd();
  }
  if (this.phone_number !== null && this.phone_number !== undefined) {
    output.writeFieldBegin('phone_number', Thrift.Type.STRING, 6);
    output.writeString(this.phone_number);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.STRING, 7);
    output.writeString(this.state);
    output.writeFieldEnd();
  }
  if (this.city !== null && this.city !== undefined) {
    output.writeFieldBegin('city', Thrift.Type.STRING, 8);
    output.writeString(this.city);
    output.writeFieldEnd();
  }
  if (this.text_message_permission !== null && this.text_message_permission !== undefined) {
    output.writeFieldBegin('text_message_permission', Thrift.Type.BOOL, 9);
    output.writeBool(this.text_message_permission);
    output.writeFieldEnd();
  }
  if (this.date_of_birth !== null && this.date_of_birth !== undefined) {
    output.writeFieldBegin('date_of_birth', Thrift.Type.STRUCT, 10);
    this.date_of_birth.write(output);
    output.writeFieldEnd();
  }
  if (this.sex !== null && this.sex !== undefined) {
    output.writeFieldBegin('sex', Thrift.Type.I32, 11);
    output.writeI32(this.sex);
    output.writeFieldEnd();
  }
  if (this.is_sitka_operations !== null && this.is_sitka_operations !== undefined) {
    output.writeFieldBegin('is_sitka_operations', Thrift.Type.BOOL, 13);
    output.writeBool(this.is_sitka_operations);
    output.writeFieldEnd();
  }
  if (this.terms_and_conditions_consent !== null && this.terms_and_conditions_consent !== undefined) {
    output.writeFieldBegin('terms_and_conditions_consent', Thrift.Type.BOOL, 14);
    output.writeBool(this.terms_and_conditions_consent);
    output.writeFieldEnd();
  }
  if (this.mfa_required !== null && this.mfa_required !== undefined) {
    output.writeFieldBegin('mfa_required', Thrift.Type.BOOL, 15);
    output.writeBool(this.mfa_required);
    output.writeFieldEnd();
  }
  if (this.specialties !== null && this.specialties !== undefined) {
    output.writeFieldBegin('specialties', Thrift.Type.LIST, 16);
    output.writeListBegin(Thrift.Type.I32, this.specialties.length);
    for (var iter152 in this.specialties) {
      if (this.specialties.hasOwnProperty(iter152)) {
        iter152 = this.specialties[iter152];
        output.writeI32(iter152);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.provider_npi !== null && this.provider_npi !== undefined) {
    output.writeFieldBegin('provider_npi', Thrift.Type.STRING, 18);
    output.writeString(this.provider_npi);
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.I32, 19);
    output.writeI32(this.title);
    output.writeFieldEnd();
  }
  if (this.credentials !== null && this.credentials !== undefined) {
    output.writeFieldBegin('credentials', Thrift.Type.LIST, 20);
    output.writeListBegin(Thrift.Type.I32, this.credentials.length);
    for (var iter153 in this.credentials) {
      if (this.credentials.hasOwnProperty(iter153)) {
        iter153 = this.credentials[iter153];
        output.writeI32(iter153);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.provider_insurance_carrier !== null && this.provider_insurance_carrier !== undefined) {
    output.writeFieldBegin('provider_insurance_carrier', Thrift.Type.STRING, 21);
    output.writeString(this.provider_insurance_carrier);
    output.writeFieldEnd();
  }
  if (this.patient_external_id !== null && this.patient_external_id !== undefined) {
    output.writeFieldBegin('patient_external_id', Thrift.Type.STRING, 22);
    output.writeString(this.patient_external_id);
    output.writeFieldEnd();
  }
  if (this.is_sitka_medical_associate !== null && this.is_sitka_medical_associate !== undefined) {
    output.writeFieldBegin('is_sitka_medical_associate', Thrift.Type.BOOL, 23);
    output.writeBool(this.is_sitka_medical_associate);
    output.writeFieldEnd();
  }
  if (this.is_active !== null && this.is_active !== undefined) {
    output.writeFieldBegin('is_active', Thrift.Type.BOOL, 24);
    output.writeBool(this.is_active);
    output.writeFieldEnd();
  }
  if (this.external_user_id !== null && this.external_user_id !== undefined) {
    output.writeFieldBegin('external_user_id', Thrift.Type.STRING, 25);
    output.writeString(this.external_user_id);
    output.writeFieldEnd();
  }
  if (this.exercises_okta !== null && this.exercises_okta !== undefined) {
    output.writeFieldBegin('exercises_okta', Thrift.Type.BOOL, 26);
    output.writeBool(this.exercises_okta);
    output.writeFieldEnd();
  }
  if (this.medicare_beneficiary_identifier !== null && this.medicare_beneficiary_identifier !== undefined) {
    output.writeFieldBegin('medicare_beneficiary_identifier', Thrift.Type.STRING, 27);
    output.writeString(this.medicare_beneficiary_identifier);
    output.writeFieldEnd();
  }
  if (this.is_sitka_clinical_reviewer !== null && this.is_sitka_clinical_reviewer !== undefined) {
    output.writeFieldBegin('is_sitka_clinical_reviewer', Thrift.Type.BOOL, 28);
    output.writeBool(this.is_sitka_clinical_reviewer);
    output.writeFieldEnd();
  }
  if (this.licenses !== null && this.licenses !== undefined) {
    output.writeFieldBegin('licenses', Thrift.Type.LIST, 29);
    output.writeListBegin(Thrift.Type.STRUCT, this.licenses.length);
    for (var iter154 in this.licenses) {
      if (this.licenses.hasOwnProperty(iter154)) {
        iter154 = this.licenses[iter154];
        iter154.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.specialist_data !== null && this.specialist_data !== undefined) {
    output.writeFieldBegin('specialist_data', Thrift.Type.STRUCT, 30);
    this.specialist_data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var User = module.exports.User = function(args) {
  this.user_id = null;
  this.email = null;
  this.first_name = null;
  this.last_name = null;
  this.phone_number = null;
  this.state = null;
  this.city = null;
  this.date_of_birth = null;
  this.sex = null;
  this.is_sitka_operations = null;
  this.patient_for_partner_ids = null;
  this.admin_for_partner_ids = null;
  this.text_message_permission = null;
  this.is_mfa_enabled = null;
  this.mfa_required = null;
  this.specialties = null;
  this.provider_npi = null;
  this.title = null;
  this.credentials = null;
  this.licenses = null;
  this.provider_insurance_carrier = null;
  this.provider_intro_video_url = null;
  this.has_auth = null;
  this.patient_external_id = null;
  this.is_sitka_medical_associate = null;
  this.is_active = null;
  this.external_user_id = null;
  this.member_for_partner_ids = null;
  this.exercises_okta = null;
  this.medicare_beneficiary_identifier = null;
  this.is_sitka_clinical_reviewer = null;
  this.partner_member_roles = null;
  this.specialist_data = null;
  if (args) {
    if (args.user_id !== undefined && args.user_id !== null) {
      this.user_id = args.user_id;
    }
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.first_name !== undefined && args.first_name !== null) {
      this.first_name = args.first_name;
    }
    if (args.last_name !== undefined && args.last_name !== null) {
      this.last_name = args.last_name;
    }
    if (args.phone_number !== undefined && args.phone_number !== null) {
      this.phone_number = args.phone_number;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.city !== undefined && args.city !== null) {
      this.city = args.city;
    }
    if (args.date_of_birth !== undefined && args.date_of_birth !== null) {
      this.date_of_birth = new ttypes.DateOfBirth(args.date_of_birth);
    }
    if (args.sex !== undefined && args.sex !== null) {
      this.sex = args.sex;
    }
    if (args.is_sitka_operations !== undefined && args.is_sitka_operations !== null) {
      this.is_sitka_operations = args.is_sitka_operations;
    }
    if (args.patient_for_partner_ids !== undefined && args.patient_for_partner_ids !== null) {
      this.patient_for_partner_ids = Thrift.copyList(args.patient_for_partner_ids, [null]);
    }
    if (args.admin_for_partner_ids !== undefined && args.admin_for_partner_ids !== null) {
      this.admin_for_partner_ids = Thrift.copyList(args.admin_for_partner_ids, [null]);
    }
    if (args.text_message_permission !== undefined && args.text_message_permission !== null) {
      this.text_message_permission = args.text_message_permission;
    }
    if (args.is_mfa_enabled !== undefined && args.is_mfa_enabled !== null) {
      this.is_mfa_enabled = args.is_mfa_enabled;
    }
    if (args.mfa_required !== undefined && args.mfa_required !== null) {
      this.mfa_required = args.mfa_required;
    }
    if (args.specialties !== undefined && args.specialties !== null) {
      this.specialties = Thrift.copyList(args.specialties, [null]);
    }
    if (args.provider_npi !== undefined && args.provider_npi !== null) {
      this.provider_npi = args.provider_npi;
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
    if (args.credentials !== undefined && args.credentials !== null) {
      this.credentials = Thrift.copyList(args.credentials, [null]);
    }
    if (args.licenses !== undefined && args.licenses !== null) {
      this.licenses = Thrift.copyList(args.licenses, [null]);
    }
    if (args.provider_insurance_carrier !== undefined && args.provider_insurance_carrier !== null) {
      this.provider_insurance_carrier = args.provider_insurance_carrier;
    }
    if (args.provider_intro_video_url !== undefined && args.provider_intro_video_url !== null) {
      this.provider_intro_video_url = args.provider_intro_video_url;
    }
    if (args.has_auth !== undefined && args.has_auth !== null) {
      this.has_auth = args.has_auth;
    }
    if (args.patient_external_id !== undefined && args.patient_external_id !== null) {
      this.patient_external_id = args.patient_external_id;
    }
    if (args.is_sitka_medical_associate !== undefined && args.is_sitka_medical_associate !== null) {
      this.is_sitka_medical_associate = args.is_sitka_medical_associate;
    }
    if (args.is_active !== undefined && args.is_active !== null) {
      this.is_active = args.is_active;
    }
    if (args.external_user_id !== undefined && args.external_user_id !== null) {
      this.external_user_id = args.external_user_id;
    }
    if (args.member_for_partner_ids !== undefined && args.member_for_partner_ids !== null) {
      this.member_for_partner_ids = Thrift.copyList(args.member_for_partner_ids, [null]);
    }
    if (args.exercises_okta !== undefined && args.exercises_okta !== null) {
      this.exercises_okta = args.exercises_okta;
    }
    if (args.medicare_beneficiary_identifier !== undefined && args.medicare_beneficiary_identifier !== null) {
      this.medicare_beneficiary_identifier = args.medicare_beneficiary_identifier;
    }
    if (args.is_sitka_clinical_reviewer !== undefined && args.is_sitka_clinical_reviewer !== null) {
      this.is_sitka_clinical_reviewer = args.is_sitka_clinical_reviewer;
    }
    if (args.partner_member_roles !== undefined && args.partner_member_roles !== null) {
      this.partner_member_roles = Thrift.copyMap(args.partner_member_roles, [ttypes.PartnerMemberRoles]);
    }
    if (args.specialist_data !== undefined && args.specialist_data !== null) {
      this.specialist_data = new ttypes.SpecialistData(args.specialist_data);
    }
  }
};
User.prototype = {};
User.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.first_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.last_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.phone_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRING) {
        this.state = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.STRING) {
        this.city = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 12:
      if (ftype == Thrift.Type.STRUCT) {
        this.date_of_birth = new ttypes.DateOfBirth();
        this.date_of_birth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 13:
      if (ftype == Thrift.Type.I32) {
        this.sex = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 15:
      if (ftype == Thrift.Type.BOOL) {
        this.is_sitka_operations = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 16:
      if (ftype == Thrift.Type.LIST) {
        this.patient_for_partner_ids = [];
        var _rtmp3156 = input.readListBegin();
        var _size155 = _rtmp3156.size || 0;
        for (var _i157 = 0; _i157 < _size155; ++_i157) {
          var elem158 = null;
          elem158 = input.readString();
          this.patient_for_partner_ids.push(elem158);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 17:
      if (ftype == Thrift.Type.LIST) {
        this.admin_for_partner_ids = [];
        var _rtmp3160 = input.readListBegin();
        var _size159 = _rtmp3160.size || 0;
        for (var _i161 = 0; _i161 < _size159; ++_i161) {
          var elem162 = null;
          elem162 = input.readString();
          this.admin_for_partner_ids.push(elem162);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 19:
      if (ftype == Thrift.Type.BOOL) {
        this.text_message_permission = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 20:
      if (ftype == Thrift.Type.BOOL) {
        this.is_mfa_enabled = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 21:
      if (ftype == Thrift.Type.BOOL) {
        this.mfa_required = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 22:
      if (ftype == Thrift.Type.LIST) {
        this.specialties = [];
        var _rtmp3164 = input.readListBegin();
        var _size163 = _rtmp3164.size || 0;
        for (var _i165 = 0; _i165 < _size163; ++_i165) {
          var elem166 = null;
          elem166 = input.readI32();
          this.specialties.push(elem166);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 26:
      if (ftype == Thrift.Type.STRING) {
        this.provider_npi = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 27:
      if (ftype == Thrift.Type.I32) {
        this.title = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 28:
      if (ftype == Thrift.Type.LIST) {
        this.credentials = [];
        var _rtmp3168 = input.readListBegin();
        var _size167 = _rtmp3168.size || 0;
        for (var _i169 = 0; _i169 < _size167; ++_i169) {
          var elem170 = null;
          elem170 = input.readI32();
          this.credentials.push(elem170);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 29:
      if (ftype == Thrift.Type.LIST) {
        this.licenses = [];
        var _rtmp3172 = input.readListBegin();
        var _size171 = _rtmp3172.size || 0;
        for (var _i173 = 0; _i173 < _size171; ++_i173) {
          var elem174 = null;
          elem174 = new ttypes.UserLicense();
          elem174.read(input);
          this.licenses.push(elem174);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 30:
      if (ftype == Thrift.Type.STRING) {
        this.provider_insurance_carrier = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 31:
      if (ftype == Thrift.Type.STRING) {
        this.provider_intro_video_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 32:
      if (ftype == Thrift.Type.BOOL) {
        this.has_auth = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 33:
      if (ftype == Thrift.Type.STRING) {
        this.patient_external_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 34:
      if (ftype == Thrift.Type.BOOL) {
        this.is_sitka_medical_associate = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 35:
      if (ftype == Thrift.Type.BOOL) {
        this.is_active = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 36:
      if (ftype == Thrift.Type.STRING) {
        this.external_user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 37:
      if (ftype == Thrift.Type.LIST) {
        this.member_for_partner_ids = [];
        var _rtmp3176 = input.readListBegin();
        var _size175 = _rtmp3176.size || 0;
        for (var _i177 = 0; _i177 < _size175; ++_i177) {
          var elem178 = null;
          elem178 = input.readString();
          this.member_for_partner_ids.push(elem178);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 38:
      if (ftype == Thrift.Type.BOOL) {
        this.exercises_okta = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 39:
      if (ftype == Thrift.Type.STRING) {
        this.medicare_beneficiary_identifier = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 40:
      if (ftype == Thrift.Type.BOOL) {
        this.is_sitka_clinical_reviewer = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 41:
      if (ftype == Thrift.Type.MAP) {
        this.partner_member_roles = {};
        var _rtmp3180 = input.readMapBegin();
        var _size179 = _rtmp3180.size || 0;
        for (var _i181 = 0; _i181 < _size179; ++_i181) {
          var key182 = null;
          var val183 = null;
          key182 = input.readString();
          val183 = new ttypes.PartnerMemberRoles();
          val183.read(input);
          this.partner_member_roles[key182] = val183;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 42:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialist_data = new ttypes.SpecialistData();
        this.specialist_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

User.prototype.write = function(output) {
  output.writeStructBegin('User');
  if (this.user_id !== null && this.user_id !== undefined) {
    output.writeFieldBegin('user_id', Thrift.Type.STRING, 1);
    output.writeString(this.user_id);
    output.writeFieldEnd();
  }
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 2);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.first_name !== null && this.first_name !== undefined) {
    output.writeFieldBegin('first_name', Thrift.Type.STRING, 3);
    output.writeString(this.first_name);
    output.writeFieldEnd();
  }
  if (this.last_name !== null && this.last_name !== undefined) {
    output.writeFieldBegin('last_name', Thrift.Type.STRING, 4);
    output.writeString(this.last_name);
    output.writeFieldEnd();
  }
  if (this.phone_number !== null && this.phone_number !== undefined) {
    output.writeFieldBegin('phone_number', Thrift.Type.STRING, 8);
    output.writeString(this.phone_number);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.STRING, 10);
    output.writeString(this.state);
    output.writeFieldEnd();
  }
  if (this.city !== null && this.city !== undefined) {
    output.writeFieldBegin('city', Thrift.Type.STRING, 11);
    output.writeString(this.city);
    output.writeFieldEnd();
  }
  if (this.date_of_birth !== null && this.date_of_birth !== undefined) {
    output.writeFieldBegin('date_of_birth', Thrift.Type.STRUCT, 12);
    this.date_of_birth.write(output);
    output.writeFieldEnd();
  }
  if (this.sex !== null && this.sex !== undefined) {
    output.writeFieldBegin('sex', Thrift.Type.I32, 13);
    output.writeI32(this.sex);
    output.writeFieldEnd();
  }
  if (this.is_sitka_operations !== null && this.is_sitka_operations !== undefined) {
    output.writeFieldBegin('is_sitka_operations', Thrift.Type.BOOL, 15);
    output.writeBool(this.is_sitka_operations);
    output.writeFieldEnd();
  }
  if (this.patient_for_partner_ids !== null && this.patient_for_partner_ids !== undefined) {
    output.writeFieldBegin('patient_for_partner_ids', Thrift.Type.LIST, 16);
    output.writeListBegin(Thrift.Type.STRING, this.patient_for_partner_ids.length);
    for (var iter184 in this.patient_for_partner_ids) {
      if (this.patient_for_partner_ids.hasOwnProperty(iter184)) {
        iter184 = this.patient_for_partner_ids[iter184];
        output.writeString(iter184);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.admin_for_partner_ids !== null && this.admin_for_partner_ids !== undefined) {
    output.writeFieldBegin('admin_for_partner_ids', Thrift.Type.LIST, 17);
    output.writeListBegin(Thrift.Type.STRING, this.admin_for_partner_ids.length);
    for (var iter185 in this.admin_for_partner_ids) {
      if (this.admin_for_partner_ids.hasOwnProperty(iter185)) {
        iter185 = this.admin_for_partner_ids[iter185];
        output.writeString(iter185);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.text_message_permission !== null && this.text_message_permission !== undefined) {
    output.writeFieldBegin('text_message_permission', Thrift.Type.BOOL, 19);
    output.writeBool(this.text_message_permission);
    output.writeFieldEnd();
  }
  if (this.is_mfa_enabled !== null && this.is_mfa_enabled !== undefined) {
    output.writeFieldBegin('is_mfa_enabled', Thrift.Type.BOOL, 20);
    output.writeBool(this.is_mfa_enabled);
    output.writeFieldEnd();
  }
  if (this.mfa_required !== null && this.mfa_required !== undefined) {
    output.writeFieldBegin('mfa_required', Thrift.Type.BOOL, 21);
    output.writeBool(this.mfa_required);
    output.writeFieldEnd();
  }
  if (this.specialties !== null && this.specialties !== undefined) {
    output.writeFieldBegin('specialties', Thrift.Type.LIST, 22);
    output.writeListBegin(Thrift.Type.I32, this.specialties.length);
    for (var iter186 in this.specialties) {
      if (this.specialties.hasOwnProperty(iter186)) {
        iter186 = this.specialties[iter186];
        output.writeI32(iter186);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.provider_npi !== null && this.provider_npi !== undefined) {
    output.writeFieldBegin('provider_npi', Thrift.Type.STRING, 26);
    output.writeString(this.provider_npi);
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.I32, 27);
    output.writeI32(this.title);
    output.writeFieldEnd();
  }
  if (this.credentials !== null && this.credentials !== undefined) {
    output.writeFieldBegin('credentials', Thrift.Type.LIST, 28);
    output.writeListBegin(Thrift.Type.I32, this.credentials.length);
    for (var iter187 in this.credentials) {
      if (this.credentials.hasOwnProperty(iter187)) {
        iter187 = this.credentials[iter187];
        output.writeI32(iter187);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.licenses !== null && this.licenses !== undefined) {
    output.writeFieldBegin('licenses', Thrift.Type.LIST, 29);
    output.writeListBegin(Thrift.Type.STRUCT, this.licenses.length);
    for (var iter188 in this.licenses) {
      if (this.licenses.hasOwnProperty(iter188)) {
        iter188 = this.licenses[iter188];
        iter188.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.provider_insurance_carrier !== null && this.provider_insurance_carrier !== undefined) {
    output.writeFieldBegin('provider_insurance_carrier', Thrift.Type.STRING, 30);
    output.writeString(this.provider_insurance_carrier);
    output.writeFieldEnd();
  }
  if (this.provider_intro_video_url !== null && this.provider_intro_video_url !== undefined) {
    output.writeFieldBegin('provider_intro_video_url', Thrift.Type.STRING, 31);
    output.writeString(this.provider_intro_video_url);
    output.writeFieldEnd();
  }
  if (this.has_auth !== null && this.has_auth !== undefined) {
    output.writeFieldBegin('has_auth', Thrift.Type.BOOL, 32);
    output.writeBool(this.has_auth);
    output.writeFieldEnd();
  }
  if (this.patient_external_id !== null && this.patient_external_id !== undefined) {
    output.writeFieldBegin('patient_external_id', Thrift.Type.STRING, 33);
    output.writeString(this.patient_external_id);
    output.writeFieldEnd();
  }
  if (this.is_sitka_medical_associate !== null && this.is_sitka_medical_associate !== undefined) {
    output.writeFieldBegin('is_sitka_medical_associate', Thrift.Type.BOOL, 34);
    output.writeBool(this.is_sitka_medical_associate);
    output.writeFieldEnd();
  }
  if (this.is_active !== null && this.is_active !== undefined) {
    output.writeFieldBegin('is_active', Thrift.Type.BOOL, 35);
    output.writeBool(this.is_active);
    output.writeFieldEnd();
  }
  if (this.external_user_id !== null && this.external_user_id !== undefined) {
    output.writeFieldBegin('external_user_id', Thrift.Type.STRING, 36);
    output.writeString(this.external_user_id);
    output.writeFieldEnd();
  }
  if (this.member_for_partner_ids !== null && this.member_for_partner_ids !== undefined) {
    output.writeFieldBegin('member_for_partner_ids', Thrift.Type.LIST, 37);
    output.writeListBegin(Thrift.Type.STRING, this.member_for_partner_ids.length);
    for (var iter189 in this.member_for_partner_ids) {
      if (this.member_for_partner_ids.hasOwnProperty(iter189)) {
        iter189 = this.member_for_partner_ids[iter189];
        output.writeString(iter189);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.exercises_okta !== null && this.exercises_okta !== undefined) {
    output.writeFieldBegin('exercises_okta', Thrift.Type.BOOL, 38);
    output.writeBool(this.exercises_okta);
    output.writeFieldEnd();
  }
  if (this.medicare_beneficiary_identifier !== null && this.medicare_beneficiary_identifier !== undefined) {
    output.writeFieldBegin('medicare_beneficiary_identifier', Thrift.Type.STRING, 39);
    output.writeString(this.medicare_beneficiary_identifier);
    output.writeFieldEnd();
  }
  if (this.is_sitka_clinical_reviewer !== null && this.is_sitka_clinical_reviewer !== undefined) {
    output.writeFieldBegin('is_sitka_clinical_reviewer', Thrift.Type.BOOL, 40);
    output.writeBool(this.is_sitka_clinical_reviewer);
    output.writeFieldEnd();
  }
  if (this.partner_member_roles !== null && this.partner_member_roles !== undefined) {
    output.writeFieldBegin('partner_member_roles', Thrift.Type.MAP, 41);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.STRUCT, Thrift.objectLength(this.partner_member_roles));
    for (var kiter190 in this.partner_member_roles) {
      if (this.partner_member_roles.hasOwnProperty(kiter190)) {
        var viter191 = this.partner_member_roles[kiter190];
        output.writeString(kiter190);
        viter191.write(output);
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  if (this.specialist_data !== null && this.specialist_data !== undefined) {
    output.writeFieldBegin('specialist_data', Thrift.Type.STRUCT, 42);
    this.specialist_data.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UserLicense = module.exports.UserLicense = function(args) {
  this.user_license_id = null;
  this.user_id = null;
  this.state = null;
  this.specialty = null;
  this.expires_at = null;
  if (args) {
    if (args.user_license_id !== undefined && args.user_license_id !== null) {
      this.user_license_id = args.user_license_id;
    }
    if (args.user_id !== undefined && args.user_id !== null) {
      this.user_id = args.user_id;
    }
    if (args.state !== undefined && args.state !== null) {
      this.state = args.state;
    }
    if (args.specialty !== undefined && args.specialty !== null) {
      this.specialty = args.specialty;
    }
    if (args.expires_at !== undefined && args.expires_at !== null) {
      this.expires_at = args.expires_at;
    }
  }
};
UserLicense.prototype = {};
UserLicense.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user_license_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I32) {
        this.specialty = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I64) {
        this.expires_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UserLicense.prototype.write = function(output) {
  output.writeStructBegin('UserLicense');
  if (this.user_license_id !== null && this.user_license_id !== undefined) {
    output.writeFieldBegin('user_license_id', Thrift.Type.STRING, 1);
    output.writeString(this.user_license_id);
    output.writeFieldEnd();
  }
  if (this.user_id !== null && this.user_id !== undefined) {
    output.writeFieldBegin('user_id', Thrift.Type.STRING, 2);
    output.writeString(this.user_id);
    output.writeFieldEnd();
  }
  if (this.state !== null && this.state !== undefined) {
    output.writeFieldBegin('state', Thrift.Type.I32, 3);
    output.writeI32(this.state);
    output.writeFieldEnd();
  }
  if (this.specialty !== null && this.specialty !== undefined) {
    output.writeFieldBegin('specialty', Thrift.Type.I32, 6);
    output.writeI32(this.specialty);
    output.writeFieldEnd();
  }
  if (this.expires_at !== null && this.expires_at !== undefined) {
    output.writeFieldBegin('expires_at', Thrift.Type.I64, 7);
    output.writeI64(this.expires_at);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SpecialistData = module.exports.SpecialistData = function(args) {
  this.daily_consults_limit = null;
  this.consults_assigned_today = null;
  this.daily_availability = null;
  this.timeframe_restrictions = null;
  this.profile_pic_url = null;
  this.specialist_bio_info = null;
  this.profile_pic_state = null;
  this.consults_assigned_this_week = null;
  if (args) {
    if (args.daily_consults_limit !== undefined && args.daily_consults_limit !== null) {
      this.daily_consults_limit = args.daily_consults_limit;
    }
    if (args.consults_assigned_today !== undefined && args.consults_assigned_today !== null) {
      this.consults_assigned_today = args.consults_assigned_today;
    }
    if (args.daily_availability !== undefined && args.daily_availability !== null) {
      this.daily_availability = new ttypes.DailyAvailability(args.daily_availability);
    }
    if (args.timeframe_restrictions !== undefined && args.timeframe_restrictions !== null) {
      this.timeframe_restrictions = Thrift.copyList(args.timeframe_restrictions, [null]);
    }
    if (args.profile_pic_url !== undefined && args.profile_pic_url !== null) {
      this.profile_pic_url = args.profile_pic_url;
    }
    if (args.specialist_bio_info !== undefined && args.specialist_bio_info !== null) {
      this.specialist_bio_info = new ttypes.SpecialistBioInfo(args.specialist_bio_info);
    }
    if (args.profile_pic_state !== undefined && args.profile_pic_state !== null) {
      this.profile_pic_state = args.profile_pic_state;
    }
    if (args.consults_assigned_this_week !== undefined && args.consults_assigned_this_week !== null) {
      this.consults_assigned_this_week = args.consults_assigned_this_week;
    }
  }
};
SpecialistData.prototype = {};
SpecialistData.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.daily_consults_limit = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.consults_assigned_today = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.daily_availability = new ttypes.DailyAvailability();
        this.daily_availability.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.timeframe_restrictions = [];
        var _rtmp3193 = input.readListBegin();
        var _size192 = _rtmp3193.size || 0;
        for (var _i194 = 0; _i194 < _size192; ++_i194) {
          var elem195 = null;
          elem195 = new ttypes.TimeframeRestriction();
          elem195.read(input);
          this.timeframe_restrictions.push(elem195);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.profile_pic_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialist_bio_info = new ttypes.SpecialistBioInfo();
        this.specialist_bio_info.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.I32) {
        this.profile_pic_state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.I32) {
        this.consults_assigned_this_week = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SpecialistData.prototype.write = function(output) {
  output.writeStructBegin('SpecialistData');
  if (this.daily_consults_limit !== null && this.daily_consults_limit !== undefined) {
    output.writeFieldBegin('daily_consults_limit', Thrift.Type.I32, 1);
    output.writeI32(this.daily_consults_limit);
    output.writeFieldEnd();
  }
  if (this.consults_assigned_today !== null && this.consults_assigned_today !== undefined) {
    output.writeFieldBegin('consults_assigned_today', Thrift.Type.I32, 2);
    output.writeI32(this.consults_assigned_today);
    output.writeFieldEnd();
  }
  if (this.daily_availability !== null && this.daily_availability !== undefined) {
    output.writeFieldBegin('daily_availability', Thrift.Type.STRUCT, 3);
    this.daily_availability.write(output);
    output.writeFieldEnd();
  }
  if (this.timeframe_restrictions !== null && this.timeframe_restrictions !== undefined) {
    output.writeFieldBegin('timeframe_restrictions', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.timeframe_restrictions.length);
    for (var iter196 in this.timeframe_restrictions) {
      if (this.timeframe_restrictions.hasOwnProperty(iter196)) {
        iter196 = this.timeframe_restrictions[iter196];
        iter196.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.profile_pic_url !== null && this.profile_pic_url !== undefined) {
    output.writeFieldBegin('profile_pic_url', Thrift.Type.STRING, 5);
    output.writeString(this.profile_pic_url);
    output.writeFieldEnd();
  }
  if (this.specialist_bio_info !== null && this.specialist_bio_info !== undefined) {
    output.writeFieldBegin('specialist_bio_info', Thrift.Type.STRUCT, 6);
    this.specialist_bio_info.write(output);
    output.writeFieldEnd();
  }
  if (this.profile_pic_state !== null && this.profile_pic_state !== undefined) {
    output.writeFieldBegin('profile_pic_state', Thrift.Type.I32, 7);
    output.writeI32(this.profile_pic_state);
    output.writeFieldEnd();
  }
  if (this.consults_assigned_this_week !== null && this.consults_assigned_this_week !== undefined) {
    output.writeFieldBegin('consults_assigned_this_week', Thrift.Type.I32, 8);
    output.writeI32(this.consults_assigned_this_week);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SpecialistDataUpdates = module.exports.SpecialistDataUpdates = function(args) {
  this.daily_consults_limit = null;
  this.daily_availability = null;
  this.timeframe_restrictions = null;
  this.profile_pic_upload_key = null;
  this.specialist_bio_info = null;
  if (args) {
    if (args.daily_consults_limit !== undefined && args.daily_consults_limit !== null) {
      this.daily_consults_limit = args.daily_consults_limit;
    }
    if (args.daily_availability !== undefined && args.daily_availability !== null) {
      this.daily_availability = new ttypes.DailyAvailability(args.daily_availability);
    }
    if (args.timeframe_restrictions !== undefined && args.timeframe_restrictions !== null) {
      this.timeframe_restrictions = Thrift.copyList(args.timeframe_restrictions, [null]);
    }
    if (args.profile_pic_upload_key !== undefined && args.profile_pic_upload_key !== null) {
      this.profile_pic_upload_key = args.profile_pic_upload_key;
    }
    if (args.specialist_bio_info !== undefined && args.specialist_bio_info !== null) {
      this.specialist_bio_info = new ttypes.SpecialistBioInfo(args.specialist_bio_info);
    }
  }
};
SpecialistDataUpdates.prototype = {};
SpecialistDataUpdates.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.daily_consults_limit = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.daily_availability = new ttypes.DailyAvailability();
        this.daily_availability.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        this.timeframe_restrictions = [];
        var _rtmp3198 = input.readListBegin();
        var _size197 = _rtmp3198.size || 0;
        for (var _i199 = 0; _i199 < _size197; ++_i199) {
          var elem200 = null;
          elem200 = new ttypes.TimeframeRestriction();
          elem200.read(input);
          this.timeframe_restrictions.push(elem200);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.profile_pic_upload_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialist_bio_info = new ttypes.SpecialistBioInfo();
        this.specialist_bio_info.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SpecialistDataUpdates.prototype.write = function(output) {
  output.writeStructBegin('SpecialistDataUpdates');
  if (this.daily_consults_limit !== null && this.daily_consults_limit !== undefined) {
    output.writeFieldBegin('daily_consults_limit', Thrift.Type.I32, 1);
    output.writeI32(this.daily_consults_limit);
    output.writeFieldEnd();
  }
  if (this.daily_availability !== null && this.daily_availability !== undefined) {
    output.writeFieldBegin('daily_availability', Thrift.Type.STRUCT, 2);
    this.daily_availability.write(output);
    output.writeFieldEnd();
  }
  if (this.timeframe_restrictions !== null && this.timeframe_restrictions !== undefined) {
    output.writeFieldBegin('timeframe_restrictions', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.timeframe_restrictions.length);
    for (var iter201 in this.timeframe_restrictions) {
      if (this.timeframe_restrictions.hasOwnProperty(iter201)) {
        iter201 = this.timeframe_restrictions[iter201];
        iter201.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.profile_pic_upload_key !== null && this.profile_pic_upload_key !== undefined) {
    output.writeFieldBegin('profile_pic_upload_key', Thrift.Type.STRING, 4);
    output.writeString(this.profile_pic_upload_key);
    output.writeFieldEnd();
  }
  if (this.specialist_bio_info !== null && this.specialist_bio_info !== undefined) {
    output.writeFieldBegin('specialist_bio_info', Thrift.Type.STRUCT, 5);
    this.specialist_bio_info.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SpecialistBioInfo = module.exports.SpecialistBioInfo = function(args) {
  this.med_school = null;
  this.residency = null;
  this.board_certification = null;
  this.fun_facts = null;
  this.specialty = null;
  this.fellowship = null;
  if (args) {
    if (args.med_school !== undefined && args.med_school !== null) {
      this.med_school = args.med_school;
    }
    if (args.residency !== undefined && args.residency !== null) {
      this.residency = args.residency;
    }
    if (args.board_certification !== undefined && args.board_certification !== null) {
      this.board_certification = args.board_certification;
    }
    if (args.fun_facts !== undefined && args.fun_facts !== null) {
      this.fun_facts = args.fun_facts;
    }
    if (args.specialty !== undefined && args.specialty !== null) {
      this.specialty = args.specialty;
    }
    if (args.fellowship !== undefined && args.fellowship !== null) {
      this.fellowship = args.fellowship;
    }
  }
};
SpecialistBioInfo.prototype = {};
SpecialistBioInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.med_school = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.residency = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.board_certification = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.fun_facts = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.specialty = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.fellowship = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SpecialistBioInfo.prototype.write = function(output) {
  output.writeStructBegin('SpecialistBioInfo');
  if (this.med_school !== null && this.med_school !== undefined) {
    output.writeFieldBegin('med_school', Thrift.Type.STRING, 1);
    output.writeString(this.med_school);
    output.writeFieldEnd();
  }
  if (this.residency !== null && this.residency !== undefined) {
    output.writeFieldBegin('residency', Thrift.Type.STRING, 2);
    output.writeString(this.residency);
    output.writeFieldEnd();
  }
  if (this.board_certification !== null && this.board_certification !== undefined) {
    output.writeFieldBegin('board_certification', Thrift.Type.STRING, 3);
    output.writeString(this.board_certification);
    output.writeFieldEnd();
  }
  if (this.fun_facts !== null && this.fun_facts !== undefined) {
    output.writeFieldBegin('fun_facts', Thrift.Type.STRING, 4);
    output.writeString(this.fun_facts);
    output.writeFieldEnd();
  }
  if (this.specialty !== null && this.specialty !== undefined) {
    output.writeFieldBegin('specialty', Thrift.Type.STRING, 5);
    output.writeString(this.specialty);
    output.writeFieldEnd();
  }
  if (this.fellowship !== null && this.fellowship !== undefined) {
    output.writeFieldBegin('fellowship', Thrift.Type.STRING, 6);
    output.writeString(this.fellowship);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var DailyAvailability = module.exports.DailyAvailability = function(args) {
  this.monday = null;
  this.tuesday = null;
  this.wednesday = null;
  this.thursday = null;
  this.friday = null;
  if (args) {
    if (args.monday !== undefined && args.monday !== null) {
      this.monday = args.monday;
    }
    if (args.tuesday !== undefined && args.tuesday !== null) {
      this.tuesday = args.tuesday;
    }
    if (args.wednesday !== undefined && args.wednesday !== null) {
      this.wednesday = args.wednesday;
    }
    if (args.thursday !== undefined && args.thursday !== null) {
      this.thursday = args.thursday;
    }
    if (args.friday !== undefined && args.friday !== null) {
      this.friday = args.friday;
    }
  }
};
DailyAvailability.prototype = {};
DailyAvailability.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.BOOL) {
        this.monday = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.tuesday = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.wednesday = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.thursday = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.BOOL) {
        this.friday = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

DailyAvailability.prototype.write = function(output) {
  output.writeStructBegin('DailyAvailability');
  if (this.monday !== null && this.monday !== undefined) {
    output.writeFieldBegin('monday', Thrift.Type.BOOL, 1);
    output.writeBool(this.monday);
    output.writeFieldEnd();
  }
  if (this.tuesday !== null && this.tuesday !== undefined) {
    output.writeFieldBegin('tuesday', Thrift.Type.BOOL, 2);
    output.writeBool(this.tuesday);
    output.writeFieldEnd();
  }
  if (this.wednesday !== null && this.wednesday !== undefined) {
    output.writeFieldBegin('wednesday', Thrift.Type.BOOL, 3);
    output.writeBool(this.wednesday);
    output.writeFieldEnd();
  }
  if (this.thursday !== null && this.thursday !== undefined) {
    output.writeFieldBegin('thursday', Thrift.Type.BOOL, 4);
    output.writeBool(this.thursday);
    output.writeFieldEnd();
  }
  if (this.friday !== null && this.friday !== undefined) {
    output.writeFieldBegin('friday', Thrift.Type.BOOL, 5);
    output.writeBool(this.friday);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Team = module.exports.Team = function(args) {
  this.team_id = null;
  this.name = null;
  this.partner_id = null;
  this.memberships = null;
  if (args) {
    if (args.team_id !== undefined && args.team_id !== null) {
      this.team_id = args.team_id;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.memberships !== undefined && args.memberships !== null) {
      this.memberships = Thrift.copyList(args.memberships, [null]);
    }
  }
};
Team.prototype = {};
Team.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.team_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.memberships = [];
        var _rtmp3203 = input.readListBegin();
        var _size202 = _rtmp3203.size || 0;
        for (var _i204 = 0; _i204 < _size202; ++_i204) {
          var elem205 = null;
          elem205 = new ttypes.TeamMembership();
          elem205.read(input);
          this.memberships.push(elem205);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Team.prototype.write = function(output) {
  output.writeStructBegin('Team');
  if (this.team_id !== null && this.team_id !== undefined) {
    output.writeFieldBegin('team_id', Thrift.Type.STRING, 1);
    output.writeString(this.team_id);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 2);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 3);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.memberships !== null && this.memberships !== undefined) {
    output.writeFieldBegin('memberships', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.memberships.length);
    for (var iter206 in this.memberships) {
      if (this.memberships.hasOwnProperty(iter206)) {
        iter206 = this.memberships[iter206];
        iter206.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TeamMembership = module.exports.TeamMembership = function(args) {
  this.id = null;
  this.team_id = null;
  this.user_id = null;
  this.is_lead = null;
  this.start_date = null;
  this.end_date = null;
  if (args) {
    if (args.id !== undefined && args.id !== null) {
      this.id = args.id;
    }
    if (args.team_id !== undefined && args.team_id !== null) {
      this.team_id = args.team_id;
    }
    if (args.user_id !== undefined && args.user_id !== null) {
      this.user_id = args.user_id;
    }
    if (args.is_lead !== undefined && args.is_lead !== null) {
      this.is_lead = args.is_lead;
    }
    if (args.start_date !== undefined && args.start_date !== null) {
      this.start_date = args.start_date;
    }
    if (args.end_date !== undefined && args.end_date !== null) {
      this.end_date = args.end_date;
    }
  }
};
TeamMembership.prototype = {};
TeamMembership.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.team_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.is_lead = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.start_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.I64) {
        this.end_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TeamMembership.prototype.write = function(output) {
  output.writeStructBegin('TeamMembership');
  if (this.id !== null && this.id !== undefined) {
    output.writeFieldBegin('id', Thrift.Type.I64, 1);
    output.writeI64(this.id);
    output.writeFieldEnd();
  }
  if (this.team_id !== null && this.team_id !== undefined) {
    output.writeFieldBegin('team_id', Thrift.Type.STRING, 2);
    output.writeString(this.team_id);
    output.writeFieldEnd();
  }
  if (this.user_id !== null && this.user_id !== undefined) {
    output.writeFieldBegin('user_id', Thrift.Type.STRING, 3);
    output.writeString(this.user_id);
    output.writeFieldEnd();
  }
  if (this.is_lead !== null && this.is_lead !== undefined) {
    output.writeFieldBegin('is_lead', Thrift.Type.BOOL, 4);
    output.writeBool(this.is_lead);
    output.writeFieldEnd();
  }
  if (this.start_date !== null && this.start_date !== undefined) {
    output.writeFieldBegin('start_date', Thrift.Type.I64, 5);
    output.writeI64(this.start_date);
    output.writeFieldEnd();
  }
  if (this.end_date !== null && this.end_date !== undefined) {
    output.writeFieldBegin('end_date', Thrift.Type.I64, 6);
    output.writeI64(this.end_date);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TeamUpdates = module.exports.TeamUpdates = function(args) {
  this.name = null;
  this.partner_id = null;
  this.memberships_to_update = null;
  this.memberships_to_add = null;
  this.user_ids_to_remove = null;
  if (args) {
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.memberships_to_update !== undefined && args.memberships_to_update !== null) {
      this.memberships_to_update = Thrift.copyList(args.memberships_to_update, [ttypes.TeamMembership]);
    }
    if (args.memberships_to_add !== undefined && args.memberships_to_add !== null) {
      this.memberships_to_add = Thrift.copyList(args.memberships_to_add, [ttypes.TeamMembership]);
    }
    if (args.user_ids_to_remove !== undefined && args.user_ids_to_remove !== null) {
      this.user_ids_to_remove = Thrift.copyList(args.user_ids_to_remove, [null]);
    }
  }
};
TeamUpdates.prototype = {};
TeamUpdates.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        this.memberships_to_update = [];
        var _rtmp3208 = input.readListBegin();
        var _size207 = _rtmp3208.size || 0;
        for (var _i209 = 0; _i209 < _size207; ++_i209) {
          var elem210 = null;
          elem210 = new ttypes.TeamMembership();
          elem210.read(input);
          this.memberships_to_update.push(elem210);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.memberships_to_add = [];
        var _rtmp3212 = input.readListBegin();
        var _size211 = _rtmp3212.size || 0;
        for (var _i213 = 0; _i213 < _size211; ++_i213) {
          var elem214 = null;
          elem214 = new ttypes.TeamMembership();
          elem214.read(input);
          this.memberships_to_add.push(elem214);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        this.user_ids_to_remove = [];
        var _rtmp3216 = input.readListBegin();
        var _size215 = _rtmp3216.size || 0;
        for (var _i217 = 0; _i217 < _size215; ++_i217) {
          var elem218 = null;
          elem218 = input.readString();
          this.user_ids_to_remove.push(elem218);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TeamUpdates.prototype.write = function(output) {
  output.writeStructBegin('TeamUpdates');
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 1);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 2);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.memberships_to_update !== null && this.memberships_to_update !== undefined) {
    output.writeFieldBegin('memberships_to_update', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.memberships_to_update.length);
    for (var iter219 in this.memberships_to_update) {
      if (this.memberships_to_update.hasOwnProperty(iter219)) {
        iter219 = this.memberships_to_update[iter219];
        iter219.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.memberships_to_add !== null && this.memberships_to_add !== undefined) {
    output.writeFieldBegin('memberships_to_add', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.memberships_to_add.length);
    for (var iter220 in this.memberships_to_add) {
      if (this.memberships_to_add.hasOwnProperty(iter220)) {
        iter220 = this.memberships_to_add[iter220];
        iter220.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.user_ids_to_remove !== null && this.user_ids_to_remove !== undefined) {
    output.writeFieldBegin('user_ids_to_remove', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRING, this.user_ids_to_remove.length);
    for (var iter221 in this.user_ids_to_remove) {
      if (this.user_ids_to_remove.hasOwnProperty(iter221)) {
        iter221 = this.user_ids_to_remove[iter221];
        output.writeString(iter221);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TimeframeRestriction = module.exports.TimeframeRestriction = function(args) {
  this.start_date = null;
  this.end_date = null;
  if (args) {
    if (args.start_date !== undefined && args.start_date !== null) {
      this.start_date = args.start_date;
    }
    if (args.end_date !== undefined && args.end_date !== null) {
      this.end_date = args.end_date;
    }
  }
};
TimeframeRestriction.prototype = {};
TimeframeRestriction.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 2:
      if (ftype == Thrift.Type.I64) {
        this.start_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I64) {
        this.end_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TimeframeRestriction.prototype.write = function(output) {
  output.writeStructBegin('TimeframeRestriction');
  if (this.start_date !== null && this.start_date !== undefined) {
    output.writeFieldBegin('start_date', Thrift.Type.I64, 2);
    output.writeI64(this.start_date);
    output.writeFieldEnd();
  }
  if (this.end_date !== null && this.end_date !== undefined) {
    output.writeFieldBegin('end_date', Thrift.Type.I64, 3);
    output.writeI64(this.end_date);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var VideoMetaData = module.exports.VideoMetaData = function(args) {
  this.video_length = null;
  if (args) {
    if (args.video_length !== undefined && args.video_length !== null) {
      this.video_length = args.video_length;
    }
  }
};
VideoMetaData.prototype = {};
VideoMetaData.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.video_length = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

VideoMetaData.prototype.write = function(output) {
  output.writeStructBegin('VideoMetaData');
  if (this.video_length !== null && this.video_length !== undefined) {
    output.writeFieldBegin('video_length', Thrift.Type.I64, 1);
    output.writeI64(this.video_length);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateVideoVisitRequest = module.exports.CreateVideoVisitRequest = function(args) {
  this.routing_mode = null;
  this.consult_id = null;
  if (args) {
    if (args.routing_mode !== undefined && args.routing_mode !== null) {
      this.routing_mode = args.routing_mode;
    }
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
  }
};
CreateVideoVisitRequest.prototype = {};
CreateVideoVisitRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.routing_mode = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateVideoVisitRequest.prototype.write = function(output) {
  output.writeStructBegin('CreateVideoVisitRequest');
  if (this.routing_mode !== null && this.routing_mode !== undefined) {
    output.writeFieldBegin('routing_mode', Thrift.Type.I32, 1);
    output.writeI32(this.routing_mode);
    output.writeFieldEnd();
  }
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 2);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateVideoVisitResponse = module.exports.CreateVideoVisitResponse = function(args) {
  this.video_visit_id = null;
  if (args) {
    if (args.video_visit_id !== undefined && args.video_visit_id !== null) {
      this.video_visit_id = args.video_visit_id;
    }
  }
};
CreateVideoVisitResponse.prototype = {};
CreateVideoVisitResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.video_visit_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateVideoVisitResponse.prototype.write = function(output) {
  output.writeStructBegin('CreateVideoVisitResponse');
  if (this.video_visit_id !== null && this.video_visit_id !== undefined) {
    output.writeFieldBegin('video_visit_id', Thrift.Type.STRING, 1);
    output.writeString(this.video_visit_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Disable2FARequest = module.exports.Disable2FARequest = function(args) {
  this.totp_key = null;
  this.target_user_id = null;
  if (args) {
    if (args.totp_key !== undefined && args.totp_key !== null) {
      this.totp_key = args.totp_key;
    }
    if (args.target_user_id !== undefined && args.target_user_id !== null) {
      this.target_user_id = args.target_user_id;
    }
  }
};
Disable2FARequest.prototype = {};
Disable2FARequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.totp_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.target_user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Disable2FARequest.prototype.write = function(output) {
  output.writeStructBegin('Disable2FARequest');
  if (this.totp_key !== null && this.totp_key !== undefined) {
    output.writeFieldBegin('totp_key', Thrift.Type.STRING, 1);
    output.writeString(this.totp_key);
    output.writeFieldEnd();
  }
  if (this.target_user_id !== null && this.target_user_id !== undefined) {
    output.writeFieldBegin('target_user_id', Thrift.Type.STRING, 2);
    output.writeString(this.target_user_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Disable2FAResponse = module.exports.Disable2FAResponse = function(args) {
  this.user = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
  }
};
Disable2FAResponse.prototype = {};
Disable2FAResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Disable2FAResponse.prototype.write = function(output) {
  output.writeStructBegin('Disable2FAResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var EmailCustomization = module.exports.EmailCustomization = function(args) {
  this.email_fields = null;
  if (args) {
    if (args.email_fields !== undefined && args.email_fields !== null) {
      this.email_fields = new ttypes.EmailTemplateFields(args.email_fields);
    }
  }
};
EmailCustomization.prototype = {};
EmailCustomization.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.email_fields = new ttypes.EmailTemplateFields();
        this.email_fields.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

EmailCustomization.prototype.write = function(output) {
  output.writeStructBegin('EmailCustomization');
  if (this.email_fields !== null && this.email_fields !== undefined) {
    output.writeFieldBegin('email_fields', Thrift.Type.STRUCT, 1);
    this.email_fields.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var EmailTemplateFields = module.exports.EmailTemplateFields = function(args) {
  this.subject = null;
  this.body = null;
  if (args) {
    if (args.subject !== undefined && args.subject !== null) {
      this.subject = args.subject;
    }
    if (args.body !== undefined && args.body !== null) {
      this.body = args.body;
    }
  }
};
EmailTemplateFields.prototype = {};
EmailTemplateFields.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.subject = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.body = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

EmailTemplateFields.prototype.write = function(output) {
  output.writeStructBegin('EmailTemplateFields');
  if (this.subject !== null && this.subject !== undefined) {
    output.writeFieldBegin('subject', Thrift.Type.STRING, 1);
    output.writeString(this.subject);
    output.writeFieldEnd();
  }
  if (this.body !== null && this.body !== undefined) {
    output.writeFieldBegin('body', Thrift.Type.STRING, 2);
    output.writeString(this.body);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetCaseRequest = module.exports.GetCaseRequest = function(args) {
  this.case_id = null;
  if (args) {
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
  }
};
GetCaseRequest.prototype = {};
GetCaseRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCaseRequest.prototype.write = function(output) {
  output.writeStructBegin('GetCaseRequest');
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 1);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetCaseResponse = module.exports.GetCaseResponse = function(args) {
  this.the_case = null;
  this.users = null;
  this.partner = null;
  if (args) {
    if (args.the_case !== undefined && args.the_case !== null) {
      this.the_case = new ttypes.Case(args.the_case);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
GetCaseResponse.prototype = {};
GetCaseResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.the_case = new ttypes.Case();
        this.the_case.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3223 = input.readListBegin();
        var _size222 = _rtmp3223.size || 0;
        for (var _i224 = 0; _i224 < _size222; ++_i224) {
          var elem225 = null;
          elem225 = new ttypes.User();
          elem225.read(input);
          this.users.push(elem225);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCaseResponse.prototype.write = function(output) {
  output.writeStructBegin('GetCaseResponse');
  if (this.the_case !== null && this.the_case !== undefined) {
    output.writeFieldBegin('the_case', Thrift.Type.STRUCT, 1);
    this.the_case.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter226 in this.users) {
      if (this.users.hasOwnProperty(iter226)) {
        iter226 = this.users[iter226];
        iter226.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 3);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetCasesRequest = module.exports.GetCasesRequest = function(args) {
  this.page_params = null;
  if (args) {
    if (args.page_params !== undefined && args.page_params !== null) {
      this.page_params = new ttypes.PaginationParameters(args.page_params);
    }
  }
};
GetCasesRequest.prototype = {};
GetCasesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_params = new ttypes.PaginationParameters();
        this.page_params.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCasesRequest.prototype.write = function(output) {
  output.writeStructBegin('GetCasesRequest');
  if (this.page_params !== null && this.page_params !== undefined) {
    output.writeFieldBegin('page_params', Thrift.Type.STRUCT, 1);
    this.page_params.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetCasesResponse = module.exports.GetCasesResponse = function(args) {
  this.page_markers = null;
  this.cases = null;
  this.partners = null;
  this.users = null;
  if (args) {
    if (args.page_markers !== undefined && args.page_markers !== null) {
      this.page_markers = new ttypes.PaginationMarkers(args.page_markers);
    }
    if (args.cases !== undefined && args.cases !== null) {
      this.cases = Thrift.copyList(args.cases, [ttypes.Case]);
    }
    if (args.partners !== undefined && args.partners !== null) {
      this.partners = Thrift.copyList(args.partners, [ttypes.Partner]);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
  }
};
GetCasesResponse.prototype = {};
GetCasesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_markers = new ttypes.PaginationMarkers();
        this.page_markers.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.cases = [];
        var _rtmp3228 = input.readListBegin();
        var _size227 = _rtmp3228.size || 0;
        for (var _i229 = 0; _i229 < _size227; ++_i229) {
          var elem230 = null;
          elem230 = new ttypes.Case();
          elem230.read(input);
          this.cases.push(elem230);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        this.partners = [];
        var _rtmp3232 = input.readListBegin();
        var _size231 = _rtmp3232.size || 0;
        for (var _i233 = 0; _i233 < _size231; ++_i233) {
          var elem234 = null;
          elem234 = new ttypes.Partner();
          elem234.read(input);
          this.partners.push(elem234);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3236 = input.readListBegin();
        var _size235 = _rtmp3236.size || 0;
        for (var _i237 = 0; _i237 < _size235; ++_i237) {
          var elem238 = null;
          elem238 = new ttypes.User();
          elem238.read(input);
          this.users.push(elem238);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetCasesResponse.prototype.write = function(output) {
  output.writeStructBegin('GetCasesResponse');
  if (this.page_markers !== null && this.page_markers !== undefined) {
    output.writeFieldBegin('page_markers', Thrift.Type.STRUCT, 1);
    this.page_markers.write(output);
    output.writeFieldEnd();
  }
  if (this.cases !== null && this.cases !== undefined) {
    output.writeFieldBegin('cases', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.cases.length);
    for (var iter239 in this.cases) {
      if (this.cases.hasOwnProperty(iter239)) {
        iter239 = this.cases[iter239];
        iter239.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partners !== null && this.partners !== undefined) {
    output.writeFieldBegin('partners', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.partners.length);
    for (var iter240 in this.partners) {
      if (this.partners.hasOwnProperty(iter240)) {
        iter240 = this.partners[iter240];
        iter240.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter241 in this.users) {
      if (this.users.hasOwnProperty(iter241)) {
        iter241 = this.users[iter241];
        iter241.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetClinicalReviewRequest = module.exports.GetClinicalReviewRequest = function(args) {
  this.consult_id = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
  }
};
GetClinicalReviewRequest.prototype = {};
GetClinicalReviewRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetClinicalReviewRequest.prototype.write = function(output) {
  output.writeStructBegin('GetClinicalReviewRequest');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetClinicalReviewResponse = module.exports.GetClinicalReviewResponse = function(args) {
  this.review_data = null;
  this.num_consults_responded_by_sma = null;
  if (args) {
    if (args.review_data !== undefined && args.review_data !== null) {
      this.review_data = new ttypes.ClinicalReview(args.review_data);
    }
    if (args.num_consults_responded_by_sma !== undefined && args.num_consults_responded_by_sma !== null) {
      this.num_consults_responded_by_sma = args.num_consults_responded_by_sma;
    }
  }
};
GetClinicalReviewResponse.prototype = {};
GetClinicalReviewResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.review_data = new ttypes.ClinicalReview();
        this.review_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.num_consults_responded_by_sma = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetClinicalReviewResponse.prototype.write = function(output) {
  output.writeStructBegin('GetClinicalReviewResponse');
  if (this.review_data !== null && this.review_data !== undefined) {
    output.writeFieldBegin('review_data', Thrift.Type.STRUCT, 1);
    this.review_data.write(output);
    output.writeFieldEnd();
  }
  if (this.num_consults_responded_by_sma !== null && this.num_consults_responded_by_sma !== undefined) {
    output.writeFieldBegin('num_consults_responded_by_sma', Thrift.Type.I32, 2);
    output.writeI32(this.num_consults_responded_by_sma);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultDataRequest = module.exports.GetConsultDataRequest = function(args) {
  this.consult_data_id = null;
  if (args) {
    if (args.consult_data_id !== undefined && args.consult_data_id !== null) {
      this.consult_data_id = args.consult_data_id;
    }
  }
};
GetConsultDataRequest.prototype = {};
GetConsultDataRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_data_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultDataRequest.prototype.write = function(output) {
  output.writeStructBegin('GetConsultDataRequest');
  if (this.consult_data_id !== null && this.consult_data_id !== undefined) {
    output.writeFieldBegin('consult_data_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_data_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultDataResponse = module.exports.GetConsultDataResponse = function(args) {
  this.consult_data = null;
  this.users = null;
  if (args) {
    if (args.consult_data !== undefined && args.consult_data !== null) {
      this.consult_data = new ttypes.ConsultData(args.consult_data);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
  }
};
GetConsultDataResponse.prototype = {};
GetConsultDataResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.consult_data = new ttypes.ConsultData();
        this.consult_data.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3243 = input.readListBegin();
        var _size242 = _rtmp3243.size || 0;
        for (var _i244 = 0; _i244 < _size242; ++_i244) {
          var elem245 = null;
          elem245 = new ttypes.User();
          elem245.read(input);
          this.users.push(elem245);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultDataResponse.prototype.write = function(output) {
  output.writeStructBegin('GetConsultDataResponse');
  if (this.consult_data !== null && this.consult_data !== undefined) {
    output.writeFieldBegin('consult_data', Thrift.Type.STRUCT, 1);
    this.consult_data.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter246 in this.users) {
      if (this.users.hasOwnProperty(iter246)) {
        iter246 = this.users[iter246];
        iter246.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultRequest = module.exports.GetConsultRequest = function(args) {
  this.consult_id = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
  }
};
GetConsultRequest.prototype = {};
GetConsultRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultRequest.prototype.write = function(output) {
  output.writeStructBegin('GetConsultRequest');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultResponse = module.exports.GetConsultResponse = function(args) {
  this.consult = null;
  this.users = null;
  this.partner = null;
  if (args) {
    if (args.consult !== undefined && args.consult !== null) {
      this.consult = new ttypes.Consult(args.consult);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
GetConsultResponse.prototype = {};
GetConsultResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.consult = new ttypes.Consult();
        this.consult.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3248 = input.readListBegin();
        var _size247 = _rtmp3248.size || 0;
        for (var _i249 = 0; _i249 < _size247; ++_i249) {
          var elem250 = null;
          elem250 = new ttypes.User();
          elem250.read(input);
          this.users.push(elem250);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultResponse.prototype.write = function(output) {
  output.writeStructBegin('GetConsultResponse');
  if (this.consult !== null && this.consult !== undefined) {
    output.writeFieldBegin('consult', Thrift.Type.STRUCT, 1);
    this.consult.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter251 in this.users) {
      if (this.users.hasOwnProperty(iter251)) {
        iter251 = this.users[iter251];
        iter251.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 3);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultsRequest = module.exports.GetConsultsRequest = function(args) {
  this.page_params = null;
  this.include_clinical_review = null;
  if (args) {
    if (args.page_params !== undefined && args.page_params !== null) {
      this.page_params = new ttypes.PaginationParameters(args.page_params);
    }
    if (args.include_clinical_review !== undefined && args.include_clinical_review !== null) {
      this.include_clinical_review = args.include_clinical_review;
    }
  }
};
GetConsultsRequest.prototype = {};
GetConsultsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_params = new ttypes.PaginationParameters();
        this.page_params.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.include_clinical_review = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetConsultsRequest');
  if (this.page_params !== null && this.page_params !== undefined) {
    output.writeFieldBegin('page_params', Thrift.Type.STRUCT, 2);
    this.page_params.write(output);
    output.writeFieldEnd();
  }
  if (this.include_clinical_review !== null && this.include_clinical_review !== undefined) {
    output.writeFieldBegin('include_clinical_review', Thrift.Type.BOOL, 3);
    output.writeBool(this.include_clinical_review);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultsResponse = module.exports.GetConsultsResponse = function(args) {
  this.consults = null;
  this.users = null;
  this.page_markers = null;
  this.partners = null;
  if (args) {
    if (args.consults !== undefined && args.consults !== null) {
      this.consults = Thrift.copyList(args.consults, [ttypes.Consult]);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.page_markers !== undefined && args.page_markers !== null) {
      this.page_markers = new ttypes.PaginationMarkers(args.page_markers);
    }
    if (args.partners !== undefined && args.partners !== null) {
      this.partners = Thrift.copyList(args.partners, [ttypes.Partner]);
    }
  }
};
GetConsultsResponse.prototype = {};
GetConsultsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.consults = [];
        var _rtmp3253 = input.readListBegin();
        var _size252 = _rtmp3253.size || 0;
        for (var _i254 = 0; _i254 < _size252; ++_i254) {
          var elem255 = null;
          elem255 = new ttypes.Consult();
          elem255.read(input);
          this.consults.push(elem255);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3257 = input.readListBegin();
        var _size256 = _rtmp3257.size || 0;
        for (var _i258 = 0; _i258 < _size256; ++_i258) {
          var elem259 = null;
          elem259 = new ttypes.User();
          elem259.read(input);
          this.users.push(elem259);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_markers = new ttypes.PaginationMarkers();
        this.page_markers.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.partners = [];
        var _rtmp3261 = input.readListBegin();
        var _size260 = _rtmp3261.size || 0;
        for (var _i262 = 0; _i262 < _size260; ++_i262) {
          var elem263 = null;
          elem263 = new ttypes.Partner();
          elem263.read(input);
          this.partners.push(elem263);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetConsultsResponse');
  if (this.consults !== null && this.consults !== undefined) {
    output.writeFieldBegin('consults', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.consults.length);
    for (var iter264 in this.consults) {
      if (this.consults.hasOwnProperty(iter264)) {
        iter264 = this.consults[iter264];
        iter264.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter265 in this.users) {
      if (this.users.hasOwnProperty(iter265)) {
        iter265 = this.users[iter265];
        iter265.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.page_markers !== null && this.page_markers !== undefined) {
    output.writeFieldBegin('page_markers', Thrift.Type.STRUCT, 3);
    this.page_markers.write(output);
    output.writeFieldEnd();
  }
  if (this.partners !== null && this.partners !== undefined) {
    output.writeFieldBegin('partners', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.partners.length);
    for (var iter266 in this.partners) {
      if (this.partners.hasOwnProperty(iter266)) {
        iter266 = this.partners[iter266];
        iter266.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConversationsRequest = module.exports.GetConversationsRequest = function(args) {
  this.parent_type = null;
  this.parent_id = null;
  if (args) {
    if (args.parent_type !== undefined && args.parent_type !== null) {
      this.parent_type = args.parent_type;
    }
    if (args.parent_id !== undefined && args.parent_id !== null) {
      this.parent_id = args.parent_id;
    }
  }
};
GetConversationsRequest.prototype = {};
GetConversationsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.parent_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.parent_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConversationsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetConversationsRequest');
  if (this.parent_type !== null && this.parent_type !== undefined) {
    output.writeFieldBegin('parent_type', Thrift.Type.I32, 1);
    output.writeI32(this.parent_type);
    output.writeFieldEnd();
  }
  if (this.parent_id !== null && this.parent_id !== undefined) {
    output.writeFieldBegin('parent_id', Thrift.Type.STRING, 2);
    output.writeString(this.parent_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConversationsResponse = module.exports.GetConversationsResponse = function(args) {
  this.conversations = null;
  this.users = null;
  if (args) {
    if (args.conversations !== undefined && args.conversations !== null) {
      this.conversations = Thrift.copyList(args.conversations, [ttypes.Conversation]);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
  }
};
GetConversationsResponse.prototype = {};
GetConversationsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.conversations = [];
        var _rtmp3268 = input.readListBegin();
        var _size267 = _rtmp3268.size || 0;
        for (var _i269 = 0; _i269 < _size267; ++_i269) {
          var elem270 = null;
          elem270 = new ttypes.Conversation();
          elem270.read(input);
          this.conversations.push(elem270);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3272 = input.readListBegin();
        var _size271 = _rtmp3272.size || 0;
        for (var _i273 = 0; _i273 < _size271; ++_i273) {
          var elem274 = null;
          elem274 = new ttypes.User();
          elem274.read(input);
          this.users.push(elem274);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConversationsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetConversationsResponse');
  if (this.conversations !== null && this.conversations !== undefined) {
    output.writeFieldBegin('conversations', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.conversations.length);
    for (var iter275 in this.conversations) {
      if (this.conversations.hasOwnProperty(iter275)) {
        iter275 = this.conversations[iter275];
        iter275.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter276 in this.users) {
      if (this.users.hasOwnProperty(iter276)) {
        iter276 = this.users[iter276];
        iter276.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetEditableFieldsResponse = module.exports.GetEditableFieldsResponse = function(args) {
  this.editable_fields = null;
  if (args) {
    if (args.editable_fields !== undefined && args.editable_fields !== null) {
      this.editable_fields = Thrift.copyList(args.editable_fields, [null]);
    }
  }
};
GetEditableFieldsResponse.prototype = {};
GetEditableFieldsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.editable_fields = [];
        var _rtmp3278 = input.readListBegin();
        var _size277 = _rtmp3278.size || 0;
        for (var _i279 = 0; _i279 < _size277; ++_i279) {
          var elem280 = null;
          elem280 = input.readString();
          this.editable_fields.push(elem280);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetEditableFieldsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetEditableFieldsResponse');
  if (this.editable_fields !== null && this.editable_fields !== undefined) {
    output.writeFieldBegin('editable_fields', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.editable_fields.length);
    for (var iter281 in this.editable_fields) {
      if (this.editable_fields.hasOwnProperty(iter281)) {
        iter281 = this.editable_fields[iter281];
        output.writeString(iter281);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetEditableFieldsRequest = module.exports.GetEditableFieldsRequest = function(args) {
  this.user_id = null;
  this.partner_id = null;
  this.consult_id = null;
  this.consult_data_id = null;
  if (args) {
    if (args.user_id !== undefined && args.user_id !== null) {
      this.user_id = args.user_id;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.consult_data_id !== undefined && args.consult_data_id !== null) {
      this.consult_data_id = args.consult_data_id;
    }
  }
};
GetEditableFieldsRequest.prototype = {};
GetEditableFieldsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.consult_data_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetEditableFieldsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetEditableFieldsRequest');
  if (this.user_id !== null && this.user_id !== undefined) {
    output.writeFieldBegin('user_id', Thrift.Type.STRING, 1);
    output.writeString(this.user_id);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 2);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 3);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.consult_data_id !== null && this.consult_data_id !== undefined) {
    output.writeFieldBegin('consult_data_id', Thrift.Type.STRING, 4);
    output.writeString(this.consult_data_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetEmailsRequest = module.exports.GetEmailsRequest = function(args) {
  this.page_params = null;
  if (args) {
    if (args.page_params !== undefined && args.page_params !== null) {
      this.page_params = new ttypes.PaginationParameters(args.page_params);
    }
  }
};
GetEmailsRequest.prototype = {};
GetEmailsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_params = new ttypes.PaginationParameters();
        this.page_params.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetEmailsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetEmailsRequest');
  if (this.page_params !== null && this.page_params !== undefined) {
    output.writeFieldBegin('page_params', Thrift.Type.STRUCT, 2);
    this.page_params.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetEmailsResponse = module.exports.GetEmailsResponse = function(args) {
  this.emails = null;
  this.page_markers = null;
  if (args) {
    if (args.emails !== undefined && args.emails !== null) {
      this.emails = Thrift.copyList(args.emails, [ttypes.Email]);
    }
    if (args.page_markers !== undefined && args.page_markers !== null) {
      this.page_markers = new ttypes.PaginationMarkers(args.page_markers);
    }
  }
};
GetEmailsResponse.prototype = {};
GetEmailsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.emails = [];
        var _rtmp3283 = input.readListBegin();
        var _size282 = _rtmp3283.size || 0;
        for (var _i284 = 0; _i284 < _size282; ++_i284) {
          var elem285 = null;
          elem285 = new ttypes.Email();
          elem285.read(input);
          this.emails.push(elem285);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_markers = new ttypes.PaginationMarkers();
        this.page_markers.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetEmailsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetEmailsResponse');
  if (this.emails !== null && this.emails !== undefined) {
    output.writeFieldBegin('emails', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.emails.length);
    for (var iter286 in this.emails) {
      if (this.emails.hasOwnProperty(iter286)) {
        iter286 = this.emails[iter286];
        iter286.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.page_markers !== null && this.page_markers !== undefined) {
    output.writeFieldBegin('page_markers', Thrift.Type.STRUCT, 2);
    this.page_markers.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetHistoryRequest = module.exports.GetHistoryRequest = function(args) {
  this.object_id = null;
  this.object_type = null;
  if (args) {
    if (args.object_id !== undefined && args.object_id !== null) {
      this.object_id = args.object_id;
    }
    if (args.object_type !== undefined && args.object_type !== null) {
      this.object_type = args.object_type;
    }
  }
};
GetHistoryRequest.prototype = {};
GetHistoryRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.object_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.object_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetHistoryRequest.prototype.write = function(output) {
  output.writeStructBegin('GetHistoryRequest');
  if (this.object_id !== null && this.object_id !== undefined) {
    output.writeFieldBegin('object_id', Thrift.Type.STRING, 1);
    output.writeString(this.object_id);
    output.writeFieldEnd();
  }
  if (this.object_type !== null && this.object_type !== undefined) {
    output.writeFieldBegin('object_type', Thrift.Type.I32, 2);
    output.writeI32(this.object_type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetHistoryResponse = module.exports.GetHistoryResponse = function(args) {
  this.history = null;
  if (args) {
    if (args.history !== undefined && args.history !== null) {
      this.history = Thrift.copyList(args.history, [ttypes.Event]);
    }
  }
};
GetHistoryResponse.prototype = {};
GetHistoryResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.history = [];
        var _rtmp3288 = input.readListBegin();
        var _size287 = _rtmp3288.size || 0;
        for (var _i289 = 0; _i289 < _size287; ++_i289) {
          var elem290 = null;
          elem290 = new ttypes.Event();
          elem290.read(input);
          this.history.push(elem290);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetHistoryResponse.prototype.write = function(output) {
  output.writeStructBegin('GetHistoryResponse');
  if (this.history !== null && this.history !== undefined) {
    output.writeFieldBegin('history', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.history.length);
    for (var iter291 in this.history) {
      if (this.history.hasOwnProperty(iter291)) {
        iter291 = this.history[iter291];
        iter291.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetPartnersRequest = module.exports.GetPartnersRequest = function(args) {
  this.partner_ids = null;
  if (args) {
    if (args.partner_ids !== undefined && args.partner_ids !== null) {
      this.partner_ids = Thrift.copyList(args.partner_ids, [null]);
    }
  }
};
GetPartnersRequest.prototype = {};
GetPartnersRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.partner_ids = [];
        var _rtmp3293 = input.readListBegin();
        var _size292 = _rtmp3293.size || 0;
        for (var _i294 = 0; _i294 < _size292; ++_i294) {
          var elem295 = null;
          elem295 = input.readString();
          this.partner_ids.push(elem295);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetPartnersRequest.prototype.write = function(output) {
  output.writeStructBegin('GetPartnersRequest');
  if (this.partner_ids !== null && this.partner_ids !== undefined) {
    output.writeFieldBegin('partner_ids', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRING, this.partner_ids.length);
    for (var iter296 in this.partner_ids) {
      if (this.partner_ids.hasOwnProperty(iter296)) {
        iter296 = this.partner_ids[iter296];
        output.writeString(iter296);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetPartnersResponse = module.exports.GetPartnersResponse = function(args) {
  this.partners = null;
  if (args) {
    if (args.partners !== undefined && args.partners !== null) {
      this.partners = Thrift.copyList(args.partners, [ttypes.Partner]);
    }
  }
};
GetPartnersResponse.prototype = {};
GetPartnersResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.partners = [];
        var _rtmp3298 = input.readListBegin();
        var _size297 = _rtmp3298.size || 0;
        for (var _i299 = 0; _i299 < _size297; ++_i299) {
          var elem300 = null;
          elem300 = new ttypes.Partner();
          elem300.read(input);
          this.partners.push(elem300);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetPartnersResponse.prototype.write = function(output) {
  output.writeStructBegin('GetPartnersResponse');
  if (this.partners !== null && this.partners !== undefined) {
    output.writeFieldBegin('partners', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.partners.length);
    for (var iter301 in this.partners) {
      if (this.partners.hasOwnProperty(iter301)) {
        iter301 = this.partners[iter301];
        iter301.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetSharedPatientLinkRequest = module.exports.GetSharedPatientLinkRequest = function(args) {
  this.consult_data_id = null;
  if (args) {
    if (args.consult_data_id !== undefined && args.consult_data_id !== null) {
      this.consult_data_id = args.consult_data_id;
    }
  }
};
GetSharedPatientLinkRequest.prototype = {};
GetSharedPatientLinkRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_data_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSharedPatientLinkRequest.prototype.write = function(output) {
  output.writeStructBegin('GetSharedPatientLinkRequest');
  if (this.consult_data_id !== null && this.consult_data_id !== undefined) {
    output.writeFieldBegin('consult_data_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_data_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetSharedPatientLinkResponse = module.exports.GetSharedPatientLinkResponse = function(args) {
  this.consult_id = null;
  this.author_name = null;
  this.shared_link = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.author_name !== undefined && args.author_name !== null) {
      this.author_name = args.author_name;
    }
    if (args.shared_link !== undefined && args.shared_link !== null) {
      this.shared_link = args.shared_link;
    }
  }
};
GetSharedPatientLinkResponse.prototype = {};
GetSharedPatientLinkResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.author_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.shared_link = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetSharedPatientLinkResponse.prototype.write = function(output) {
  output.writeStructBegin('GetSharedPatientLinkResponse');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.author_name !== null && this.author_name !== undefined) {
    output.writeFieldBegin('author_name', Thrift.Type.STRING, 2);
    output.writeString(this.author_name);
    output.writeFieldEnd();
  }
  if (this.shared_link !== null && this.shared_link !== undefined) {
    output.writeFieldBegin('shared_link', Thrift.Type.STRING, 3);
    output.writeString(this.shared_link);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetTeamsRequest = module.exports.GetTeamsRequest = function(args) {
  this.page_params = null;
  if (args) {
    if (args.page_params !== undefined && args.page_params !== null) {
      this.page_params = new ttypes.PaginationParameters(args.page_params);
    }
  }
};
GetTeamsRequest.prototype = {};
GetTeamsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_params = new ttypes.PaginationParameters();
        this.page_params.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetTeamsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetTeamsRequest');
  if (this.page_params !== null && this.page_params !== undefined) {
    output.writeFieldBegin('page_params', Thrift.Type.STRUCT, 1);
    this.page_params.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetTeamsResponse = module.exports.GetTeamsResponse = function(args) {
  this.teams = null;
  this.users = null;
  this.page_markers = null;
  if (args) {
    if (args.teams !== undefined && args.teams !== null) {
      this.teams = Thrift.copyList(args.teams, [ttypes.Team]);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.page_markers !== undefined && args.page_markers !== null) {
      this.page_markers = new ttypes.PaginationMarkers(args.page_markers);
    }
  }
};
GetTeamsResponse.prototype = {};
GetTeamsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.teams = [];
        var _rtmp3303 = input.readListBegin();
        var _size302 = _rtmp3303.size || 0;
        for (var _i304 = 0; _i304 < _size302; ++_i304) {
          var elem305 = null;
          elem305 = new ttypes.Team();
          elem305.read(input);
          this.teams.push(elem305);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3307 = input.readListBegin();
        var _size306 = _rtmp3307.size || 0;
        for (var _i308 = 0; _i308 < _size306; ++_i308) {
          var elem309 = null;
          elem309 = new ttypes.User();
          elem309.read(input);
          this.users.push(elem309);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_markers = new ttypes.PaginationMarkers();
        this.page_markers.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetTeamsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetTeamsResponse');
  if (this.teams !== null && this.teams !== undefined) {
    output.writeFieldBegin('teams', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.teams.length);
    for (var iter310 in this.teams) {
      if (this.teams.hasOwnProperty(iter310)) {
        iter310 = this.teams[iter310];
        iter310.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter311 in this.users) {
      if (this.users.hasOwnProperty(iter311)) {
        iter311 = this.users[iter311];
        iter311.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.page_markers !== null && this.page_markers !== undefined) {
    output.writeFieldBegin('page_markers', Thrift.Type.STRUCT, 3);
    this.page_markers.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUpdatesRequest = module.exports.GetUpdatesRequest = function(args) {
  this.last_update = null;
  this.object_queries = null;
  this.include_related = null;
  if (args) {
    if (args.last_update !== undefined && args.last_update !== null) {
      this.last_update = args.last_update;
    }
    if (args.object_queries !== undefined && args.object_queries !== null) {
      this.object_queries = Thrift.copyList(args.object_queries, [ttypes.ObjectTypeQuery]);
    }
    if (args.include_related !== undefined && args.include_related !== null) {
      this.include_related = args.include_related;
    }
  }
};
GetUpdatesRequest.prototype = {};
GetUpdatesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.last_update = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.object_queries = [];
        var _rtmp3313 = input.readListBegin();
        var _size312 = _rtmp3313.size || 0;
        for (var _i314 = 0; _i314 < _size312; ++_i314) {
          var elem315 = null;
          elem315 = new ttypes.ObjectTypeQuery();
          elem315.read(input);
          this.object_queries.push(elem315);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.include_related = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUpdatesRequest.prototype.write = function(output) {
  output.writeStructBegin('GetUpdatesRequest');
  if (this.last_update !== null && this.last_update !== undefined) {
    output.writeFieldBegin('last_update', Thrift.Type.I64, 1);
    output.writeI64(this.last_update);
    output.writeFieldEnd();
  }
  if (this.object_queries !== null && this.object_queries !== undefined) {
    output.writeFieldBegin('object_queries', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.object_queries.length);
    for (var iter316 in this.object_queries) {
      if (this.object_queries.hasOwnProperty(iter316)) {
        iter316 = this.object_queries[iter316];
        iter316.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.include_related !== null && this.include_related !== undefined) {
    output.writeFieldBegin('include_related', Thrift.Type.BOOL, 3);
    output.writeBool(this.include_related);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUpdatesResponse = module.exports.GetUpdatesResponse = function(args) {
  this.update_time = null;
  this.users = null;
  this.partners = null;
  this.consults = null;
  this.consult_data = null;
  if (args) {
    if (args.update_time !== undefined && args.update_time !== null) {
      this.update_time = args.update_time;
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partners !== undefined && args.partners !== null) {
      this.partners = Thrift.copyList(args.partners, [ttypes.Partner]);
    }
    if (args.consults !== undefined && args.consults !== null) {
      this.consults = Thrift.copyList(args.consults, [ttypes.Consult]);
    }
    if (args.consult_data !== undefined && args.consult_data !== null) {
      this.consult_data = Thrift.copyList(args.consult_data, [ttypes.ConsultData]);
    }
  }
};
GetUpdatesResponse.prototype = {};
GetUpdatesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.update_time = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3318 = input.readListBegin();
        var _size317 = _rtmp3318.size || 0;
        for (var _i319 = 0; _i319 < _size317; ++_i319) {
          var elem320 = null;
          elem320 = new ttypes.User();
          elem320.read(input);
          this.users.push(elem320);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.LIST) {
        this.partners = [];
        var _rtmp3322 = input.readListBegin();
        var _size321 = _rtmp3322.size || 0;
        for (var _i323 = 0; _i323 < _size321; ++_i323) {
          var elem324 = null;
          elem324 = new ttypes.Partner();
          elem324.read(input);
          this.partners.push(elem324);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.consults = [];
        var _rtmp3326 = input.readListBegin();
        var _size325 = _rtmp3326.size || 0;
        for (var _i327 = 0; _i327 < _size325; ++_i327) {
          var elem328 = null;
          elem328 = new ttypes.Consult();
          elem328.read(input);
          this.consults.push(elem328);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        this.consult_data = [];
        var _rtmp3330 = input.readListBegin();
        var _size329 = _rtmp3330.size || 0;
        for (var _i331 = 0; _i331 < _size329; ++_i331) {
          var elem332 = null;
          elem332 = new ttypes.ConsultData();
          elem332.read(input);
          this.consult_data.push(elem332);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUpdatesResponse.prototype.write = function(output) {
  output.writeStructBegin('GetUpdatesResponse');
  if (this.update_time !== null && this.update_time !== undefined) {
    output.writeFieldBegin('update_time', Thrift.Type.I64, 1);
    output.writeI64(this.update_time);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter333 in this.users) {
      if (this.users.hasOwnProperty(iter333)) {
        iter333 = this.users[iter333];
        iter333.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partners !== null && this.partners !== undefined) {
    output.writeFieldBegin('partners', Thrift.Type.LIST, 3);
    output.writeListBegin(Thrift.Type.STRUCT, this.partners.length);
    for (var iter334 in this.partners) {
      if (this.partners.hasOwnProperty(iter334)) {
        iter334 = this.partners[iter334];
        iter334.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.consults !== null && this.consults !== undefined) {
    output.writeFieldBegin('consults', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.consults.length);
    for (var iter335 in this.consults) {
      if (this.consults.hasOwnProperty(iter335)) {
        iter335 = this.consults[iter335];
        iter335.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.consult_data !== null && this.consult_data !== undefined) {
    output.writeFieldBegin('consult_data', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.consult_data.length);
    for (var iter336 in this.consult_data) {
      if (this.consult_data.hasOwnProperty(iter336)) {
        iter336 = this.consult_data[iter336];
        iter336.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUploadBucketRequest = module.exports.GetUploadBucketRequest = function(args) {
};
GetUploadBucketRequest.prototype = {};
GetUploadBucketRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUploadBucketRequest.prototype.write = function(output) {
  output.writeStructBegin('GetUploadBucketRequest');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUploadBucketResponse = module.exports.GetUploadBucketResponse = function(args) {
  this.bucket = null;
  this.endpoint = null;
  this.key_prefix = null;
  this.identity_pool_id = null;
  this.region = null;
  this.api_version = null;
  if (args) {
    if (args.bucket !== undefined && args.bucket !== null) {
      this.bucket = args.bucket;
    }
    if (args.endpoint !== undefined && args.endpoint !== null) {
      this.endpoint = args.endpoint;
    }
    if (args.key_prefix !== undefined && args.key_prefix !== null) {
      this.key_prefix = args.key_prefix;
    }
    if (args.identity_pool_id !== undefined && args.identity_pool_id !== null) {
      this.identity_pool_id = args.identity_pool_id;
    }
    if (args.region !== undefined && args.region !== null) {
      this.region = args.region;
    }
    if (args.api_version !== undefined && args.api_version !== null) {
      this.api_version = args.api_version;
    }
  }
};
GetUploadBucketResponse.prototype = {};
GetUploadBucketResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bucket = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.endpoint = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.key_prefix = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.identity_pool_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.region = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.api_version = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUploadBucketResponse.prototype.write = function(output) {
  output.writeStructBegin('GetUploadBucketResponse');
  if (this.bucket !== null && this.bucket !== undefined) {
    output.writeFieldBegin('bucket', Thrift.Type.STRING, 1);
    output.writeString(this.bucket);
    output.writeFieldEnd();
  }
  if (this.endpoint !== null && this.endpoint !== undefined) {
    output.writeFieldBegin('endpoint', Thrift.Type.STRING, 2);
    output.writeString(this.endpoint);
    output.writeFieldEnd();
  }
  if (this.key_prefix !== null && this.key_prefix !== undefined) {
    output.writeFieldBegin('key_prefix', Thrift.Type.STRING, 3);
    output.writeString(this.key_prefix);
    output.writeFieldEnd();
  }
  if (this.identity_pool_id !== null && this.identity_pool_id !== undefined) {
    output.writeFieldBegin('identity_pool_id', Thrift.Type.STRING, 4);
    output.writeString(this.identity_pool_id);
    output.writeFieldEnd();
  }
  if (this.region !== null && this.region !== undefined) {
    output.writeFieldBegin('region', Thrift.Type.STRING, 5);
    output.writeString(this.region);
    output.writeFieldEnd();
  }
  if (this.api_version !== null && this.api_version !== undefined) {
    output.writeFieldBegin('api_version', Thrift.Type.STRING, 6);
    output.writeString(this.api_version);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetServiceAccessParamsRequest = module.exports.GetServiceAccessParamsRequest = function(args) {
  this.service_type = null;
  if (args) {
    if (args.service_type !== undefined && args.service_type !== null) {
      this.service_type = args.service_type;
    }
  }
};
GetServiceAccessParamsRequest.prototype = {};
GetServiceAccessParamsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.service_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetServiceAccessParamsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetServiceAccessParamsRequest');
  if (this.service_type !== null && this.service_type !== undefined) {
    output.writeFieldBegin('service_type', Thrift.Type.I32, 1);
    output.writeI32(this.service_type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetServiceAccessParamsResponse = module.exports.GetServiceAccessParamsResponse = function(args) {
  this.credentials = null;
  this.bucket = null;
  this.endpoint = null;
  this.key = null;
  this.region = null;
  this.api_version = null;
  if (args) {
    if (args.credentials !== undefined && args.credentials !== null) {
      this.credentials = new ttypes.TemporaryAwsCredentials(args.credentials);
    }
    if (args.bucket !== undefined && args.bucket !== null) {
      this.bucket = args.bucket;
    }
    if (args.endpoint !== undefined && args.endpoint !== null) {
      this.endpoint = args.endpoint;
    }
    if (args.key !== undefined && args.key !== null) {
      this.key = args.key;
    }
    if (args.region !== undefined && args.region !== null) {
      this.region = args.region;
    }
    if (args.api_version !== undefined && args.api_version !== null) {
      this.api_version = args.api_version;
    }
  }
};
GetServiceAccessParamsResponse.prototype = {};
GetServiceAccessParamsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.credentials = new ttypes.TemporaryAwsCredentials();
        this.credentials.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.bucket = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.endpoint = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.region = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.api_version = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetServiceAccessParamsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetServiceAccessParamsResponse');
  if (this.credentials !== null && this.credentials !== undefined) {
    output.writeFieldBegin('credentials', Thrift.Type.STRUCT, 1);
    this.credentials.write(output);
    output.writeFieldEnd();
  }
  if (this.bucket !== null && this.bucket !== undefined) {
    output.writeFieldBegin('bucket', Thrift.Type.STRING, 2);
    output.writeString(this.bucket);
    output.writeFieldEnd();
  }
  if (this.endpoint !== null && this.endpoint !== undefined) {
    output.writeFieldBegin('endpoint', Thrift.Type.STRING, 3);
    output.writeString(this.endpoint);
    output.writeFieldEnd();
  }
  if (this.key !== null && this.key !== undefined) {
    output.writeFieldBegin('key', Thrift.Type.STRING, 4);
    output.writeString(this.key);
    output.writeFieldEnd();
  }
  if (this.region !== null && this.region !== undefined) {
    output.writeFieldBegin('region', Thrift.Type.STRING, 5);
    output.writeString(this.region);
    output.writeFieldEnd();
  }
  if (this.api_version !== null && this.api_version !== undefined) {
    output.writeFieldBegin('api_version', Thrift.Type.STRING, 6);
    output.writeString(this.api_version);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUploadParamsRequest = module.exports.GetUploadParamsRequest = function(args) {
};
GetUploadParamsRequest.prototype = {};
GetUploadParamsRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUploadParamsRequest.prototype.write = function(output) {
  output.writeStructBegin('GetUploadParamsRequest');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUploadParamsResponse = module.exports.GetUploadParamsResponse = function(args) {
  this.bucket = null;
  this.endpoint = null;
  this.key = null;
  this.region = null;
  this.api_version = null;
  this.credentials = null;
  if (args) {
    if (args.bucket !== undefined && args.bucket !== null) {
      this.bucket = args.bucket;
    }
    if (args.endpoint !== undefined && args.endpoint !== null) {
      this.endpoint = args.endpoint;
    }
    if (args.key !== undefined && args.key !== null) {
      this.key = args.key;
    }
    if (args.region !== undefined && args.region !== null) {
      this.region = args.region;
    }
    if (args.api_version !== undefined && args.api_version !== null) {
      this.api_version = args.api_version;
    }
    if (args.credentials !== undefined && args.credentials !== null) {
      this.credentials = new ttypes.TemporaryAwsCredentials(args.credentials);
    }
  }
};
GetUploadParamsResponse.prototype = {};
GetUploadParamsResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.bucket = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.endpoint = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.region = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.api_version = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.credentials = new ttypes.TemporaryAwsCredentials();
        this.credentials.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUploadParamsResponse.prototype.write = function(output) {
  output.writeStructBegin('GetUploadParamsResponse');
  if (this.bucket !== null && this.bucket !== undefined) {
    output.writeFieldBegin('bucket', Thrift.Type.STRING, 1);
    output.writeString(this.bucket);
    output.writeFieldEnd();
  }
  if (this.endpoint !== null && this.endpoint !== undefined) {
    output.writeFieldBegin('endpoint', Thrift.Type.STRING, 2);
    output.writeString(this.endpoint);
    output.writeFieldEnd();
  }
  if (this.key !== null && this.key !== undefined) {
    output.writeFieldBegin('key', Thrift.Type.STRING, 3);
    output.writeString(this.key);
    output.writeFieldEnd();
  }
  if (this.region !== null && this.region !== undefined) {
    output.writeFieldBegin('region', Thrift.Type.STRING, 5);
    output.writeString(this.region);
    output.writeFieldEnd();
  }
  if (this.api_version !== null && this.api_version !== undefined) {
    output.writeFieldBegin('api_version', Thrift.Type.STRING, 6);
    output.writeString(this.api_version);
    output.writeFieldEnd();
  }
  if (this.credentials !== null && this.credentials !== undefined) {
    output.writeFieldBegin('credentials', Thrift.Type.STRUCT, 7);
    this.credentials.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUserRequest = module.exports.GetUserRequest = function(args) {
  this.user_id = null;
  if (args) {
    if (args.user_id !== undefined && args.user_id !== null) {
      this.user_id = args.user_id;
    }
  }
};
GetUserRequest.prototype = {};
GetUserRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUserRequest.prototype.write = function(output) {
  output.writeStructBegin('GetUserRequest');
  if (this.user_id !== null && this.user_id !== undefined) {
    output.writeFieldBegin('user_id', Thrift.Type.STRING, 1);
    output.writeString(this.user_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUserResponse = module.exports.GetUserResponse = function(args) {
  this.user = null;
  this.partners = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.partners !== undefined && args.partners !== null) {
      this.partners = Thrift.copyList(args.partners, [ttypes.Partner]);
    }
  }
};
GetUserResponse.prototype = {};
GetUserResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.partners = [];
        var _rtmp3338 = input.readListBegin();
        var _size337 = _rtmp3338.size || 0;
        for (var _i339 = 0; _i339 < _size337; ++_i339) {
          var elem340 = null;
          elem340 = new ttypes.Partner();
          elem340.read(input);
          this.partners.push(elem340);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUserResponse.prototype.write = function(output) {
  output.writeStructBegin('GetUserResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.partners !== null && this.partners !== undefined) {
    output.writeFieldBegin('partners', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.partners.length);
    for (var iter341 in this.partners) {
      if (this.partners.hasOwnProperty(iter341)) {
        iter341 = this.partners[iter341];
        iter341.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUsersRequest = module.exports.GetUsersRequest = function(args) {
  this.page_params = null;
  this.patient_id_for_consult_counts = null;
  if (args) {
    if (args.page_params !== undefined && args.page_params !== null) {
      this.page_params = new ttypes.PaginationParameters(args.page_params);
    }
    if (args.patient_id_for_consult_counts !== undefined && args.patient_id_for_consult_counts !== null) {
      this.patient_id_for_consult_counts = args.patient_id_for_consult_counts;
    }
  }
};
GetUsersRequest.prototype = {};
GetUsersRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_params = new ttypes.PaginationParameters();
        this.page_params.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.patient_id_for_consult_counts = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUsersRequest.prototype.write = function(output) {
  output.writeStructBegin('GetUsersRequest');
  if (this.page_params !== null && this.page_params !== undefined) {
    output.writeFieldBegin('page_params', Thrift.Type.STRUCT, 3);
    this.page_params.write(output);
    output.writeFieldEnd();
  }
  if (this.patient_id_for_consult_counts !== null && this.patient_id_for_consult_counts !== undefined) {
    output.writeFieldBegin('patient_id_for_consult_counts', Thrift.Type.STRING, 4);
    output.writeString(this.patient_id_for_consult_counts);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetUsersResponse = module.exports.GetUsersResponse = function(args) {
  this.users = null;
  this.partners = null;
  this.page_markers = null;
  this.sma_consult_count_for_patient = null;
  if (args) {
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partners !== undefined && args.partners !== null) {
      this.partners = Thrift.copyList(args.partners, [ttypes.Partner]);
    }
    if (args.page_markers !== undefined && args.page_markers !== null) {
      this.page_markers = new ttypes.PaginationMarkers(args.page_markers);
    }
    if (args.sma_consult_count_for_patient !== undefined && args.sma_consult_count_for_patient !== null) {
      this.sma_consult_count_for_patient = Thrift.copyMap(args.sma_consult_count_for_patient, [null]);
    }
  }
};
GetUsersResponse.prototype = {};
GetUsersResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3343 = input.readListBegin();
        var _size342 = _rtmp3343.size || 0;
        for (var _i344 = 0; _i344 < _size342; ++_i344) {
          var elem345 = null;
          elem345 = new ttypes.User();
          elem345.read(input);
          this.users.push(elem345);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.partners = [];
        var _rtmp3347 = input.readListBegin();
        var _size346 = _rtmp3347.size || 0;
        for (var _i348 = 0; _i348 < _size346; ++_i348) {
          var elem349 = null;
          elem349 = new ttypes.Partner();
          elem349.read(input);
          this.partners.push(elem349);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_markers = new ttypes.PaginationMarkers();
        this.page_markers.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.MAP) {
        this.sma_consult_count_for_patient = {};
        var _rtmp3351 = input.readMapBegin();
        var _size350 = _rtmp3351.size || 0;
        for (var _i352 = 0; _i352 < _size350; ++_i352) {
          var key353 = null;
          var val354 = null;
          key353 = input.readString();
          val354 = input.readI64();
          this.sma_consult_count_for_patient[key353] = val354;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetUsersResponse.prototype.write = function(output) {
  output.writeStructBegin('GetUsersResponse');
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter355 in this.users) {
      if (this.users.hasOwnProperty(iter355)) {
        iter355 = this.users[iter355];
        iter355.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partners !== null && this.partners !== undefined) {
    output.writeFieldBegin('partners', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.partners.length);
    for (var iter356 in this.partners) {
      if (this.partners.hasOwnProperty(iter356)) {
        iter356 = this.partners[iter356];
        iter356.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.page_markers !== null && this.page_markers !== undefined) {
    output.writeFieldBegin('page_markers', Thrift.Type.STRUCT, 3);
    this.page_markers.write(output);
    output.writeFieldEnd();
  }
  if (this.sma_consult_count_for_patient !== null && this.sma_consult_count_for_patient !== undefined) {
    output.writeFieldBegin('sma_consult_count_for_patient', Thrift.Type.MAP, 4);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.I64, Thrift.objectLength(this.sma_consult_count_for_patient));
    for (var kiter357 in this.sma_consult_count_for_patient) {
      if (this.sma_consult_count_for_patient.hasOwnProperty(kiter357)) {
        var viter358 = this.sma_consult_count_for_patient[kiter357];
        output.writeString(kiter357);
        output.writeI64(viter358);
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var JoinVideoVisitRequest = module.exports.JoinVideoVisitRequest = function(args) {
  this.video_visit_id = null;
  if (args) {
    if (args.video_visit_id !== undefined && args.video_visit_id !== null) {
      this.video_visit_id = args.video_visit_id;
    }
  }
};
JoinVideoVisitRequest.prototype = {};
JoinVideoVisitRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.video_visit_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

JoinVideoVisitRequest.prototype.write = function(output) {
  output.writeStructBegin('JoinVideoVisitRequest');
  if (this.video_visit_id !== null && this.video_visit_id !== undefined) {
    output.writeFieldBegin('video_visit_id', Thrift.Type.STRING, 1);
    output.writeString(this.video_visit_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var JoinVideoVisitResponse = module.exports.JoinVideoVisitResponse = function(args) {
  this.api_key = null;
  this.session_id = null;
  this.token = null;
  if (args) {
    if (args.api_key !== undefined && args.api_key !== null) {
      this.api_key = args.api_key;
    }
    if (args.session_id !== undefined && args.session_id !== null) {
      this.session_id = args.session_id;
    }
    if (args.token !== undefined && args.token !== null) {
      this.token = args.token;
    }
  }
};
JoinVideoVisitResponse.prototype = {};
JoinVideoVisitResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.api_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.session_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

JoinVideoVisitResponse.prototype.write = function(output) {
  output.writeStructBegin('JoinVideoVisitResponse');
  if (this.api_key !== null && this.api_key !== undefined) {
    output.writeFieldBegin('api_key', Thrift.Type.STRING, 1);
    output.writeString(this.api_key);
    output.writeFieldEnd();
  }
  if (this.session_id !== null && this.session_id !== undefined) {
    output.writeFieldBegin('session_id', Thrift.Type.STRING, 2);
    output.writeString(this.session_id);
    output.writeFieldEnd();
  }
  if (this.token !== null && this.token !== undefined) {
    output.writeFieldBegin('token', Thrift.Type.STRING, 3);
    output.writeString(this.token);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageCaseRequest = module.exports.ManageCaseRequest = function(args) {
  this.case_id = null;
  this.create_new_case = null;
  this.mutations = null;
  this.data_files = null;
  this.patient_info = null;
  this.idempotency_key = null;
  this.external_documents = null;
  if (args) {
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.create_new_case !== undefined && args.create_new_case !== null) {
      this.create_new_case = args.create_new_case;
    }
    if (args.mutations !== undefined && args.mutations !== null) {
      this.mutations = new ttypes.CaseMutations(args.mutations);
    }
    if (args.data_files !== undefined && args.data_files !== null) {
      this.data_files = Thrift.copyList(args.data_files, [ttypes.ConsultDataFile]);
    }
    if (args.patient_info !== undefined && args.patient_info !== null) {
      this.patient_info = new ttypes.UserInfo(args.patient_info);
    }
    if (args.idempotency_key !== undefined && args.idempotency_key !== null) {
      this.idempotency_key = args.idempotency_key;
    }
    if (args.external_documents !== undefined && args.external_documents !== null) {
      this.external_documents = Thrift.copyList(args.external_documents, [ttypes.ExternalDocument]);
    }
  }
};
ManageCaseRequest.prototype = {};
ManageCaseRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.create_new_case = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.mutations = new ttypes.CaseMutations();
        this.mutations.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.LIST) {
        this.data_files = [];
        var _rtmp3360 = input.readListBegin();
        var _size359 = _rtmp3360.size || 0;
        for (var _i361 = 0; _i361 < _size359; ++_i361) {
          var elem362 = null;
          elem362 = new ttypes.ConsultDataFile();
          elem362.read(input);
          this.data_files.push(elem362);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.patient_info = new ttypes.UserInfo();
        this.patient_info.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.idempotency_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        this.external_documents = [];
        var _rtmp3364 = input.readListBegin();
        var _size363 = _rtmp3364.size || 0;
        for (var _i365 = 0; _i365 < _size363; ++_i365) {
          var elem366 = null;
          elem366 = new ttypes.ExternalDocument();
          elem366.read(input);
          this.external_documents.push(elem366);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageCaseRequest.prototype.write = function(output) {
  output.writeStructBegin('ManageCaseRequest');
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 1);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.create_new_case !== null && this.create_new_case !== undefined) {
    output.writeFieldBegin('create_new_case', Thrift.Type.BOOL, 2);
    output.writeBool(this.create_new_case);
    output.writeFieldEnd();
  }
  if (this.mutations !== null && this.mutations !== undefined) {
    output.writeFieldBegin('mutations', Thrift.Type.STRUCT, 3);
    this.mutations.write(output);
    output.writeFieldEnd();
  }
  if (this.data_files !== null && this.data_files !== undefined) {
    output.writeFieldBegin('data_files', Thrift.Type.LIST, 4);
    output.writeListBegin(Thrift.Type.STRUCT, this.data_files.length);
    for (var iter367 in this.data_files) {
      if (this.data_files.hasOwnProperty(iter367)) {
        iter367 = this.data_files[iter367];
        iter367.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.patient_info !== null && this.patient_info !== undefined) {
    output.writeFieldBegin('patient_info', Thrift.Type.STRUCT, 7);
    this.patient_info.write(output);
    output.writeFieldEnd();
  }
  if (this.idempotency_key !== null && this.idempotency_key !== undefined) {
    output.writeFieldBegin('idempotency_key', Thrift.Type.STRING, 8);
    output.writeString(this.idempotency_key);
    output.writeFieldEnd();
  }
  if (this.external_documents !== null && this.external_documents !== undefined) {
    output.writeFieldBegin('external_documents', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRUCT, this.external_documents.length);
    for (var iter368 in this.external_documents) {
      if (this.external_documents.hasOwnProperty(iter368)) {
        iter368 = this.external_documents[iter368];
        iter368.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageCaseResponse = module.exports.ManageCaseResponse = function(args) {
  this.the_case = null;
  this.users = null;
  this.partner = null;
  if (args) {
    if (args.the_case !== undefined && args.the_case !== null) {
      this.the_case = new ttypes.Case(args.the_case);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
ManageCaseResponse.prototype = {};
ManageCaseResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.the_case = new ttypes.Case();
        this.the_case.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3370 = input.readListBegin();
        var _size369 = _rtmp3370.size || 0;
        for (var _i371 = 0; _i371 < _size369; ++_i371) {
          var elem372 = null;
          elem372 = new ttypes.User();
          elem372.read(input);
          this.users.push(elem372);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageCaseResponse.prototype.write = function(output) {
  output.writeStructBegin('ManageCaseResponse');
  if (this.the_case !== null && this.the_case !== undefined) {
    output.writeFieldBegin('the_case', Thrift.Type.STRUCT, 1);
    this.the_case.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter373 in this.users) {
      if (this.users.hasOwnProperty(iter373)) {
        iter373 = this.users[iter373];
        iter373.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 3);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageConsultRequest = module.exports.ManageConsultRequest = function(args) {
  this.consult_id = null;
  this.create_new_consult = null;
  this.updates = null;
  this.consult_message = null;
  this.data_files = null;
  this.consult_data_updates = null;
  this.patient_updates = null;
  this.idempotency_key = null;
  this.external_documents = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.create_new_consult !== undefined && args.create_new_consult !== null) {
      this.create_new_consult = args.create_new_consult;
    }
    if (args.updates !== undefined && args.updates !== null) {
      this.updates = new ttypes.ConsultUpdates(args.updates);
    }
    if (args.consult_message !== undefined && args.consult_message !== null) {
      this.consult_message = new ttypes.ConsultMessage(args.consult_message);
    }
    if (args.data_files !== undefined && args.data_files !== null) {
      this.data_files = Thrift.copyList(args.data_files, [ttypes.ConsultDataFile]);
    }
    if (args.consult_data_updates !== undefined && args.consult_data_updates !== null) {
      this.consult_data_updates = Thrift.copyList(args.consult_data_updates, [ttypes.ConsultDataUpdates]);
    }
    if (args.patient_updates !== undefined && args.patient_updates !== null) {
      this.patient_updates = new ttypes.UserInfo(args.patient_updates);
    }
    if (args.idempotency_key !== undefined && args.idempotency_key !== null) {
      this.idempotency_key = args.idempotency_key;
    }
    if (args.external_documents !== undefined && args.external_documents !== null) {
      this.external_documents = Thrift.copyList(args.external_documents, [ttypes.ExternalDocument]);
    }
  }
};
ManageConsultRequest.prototype = {};
ManageConsultRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.create_new_consult = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.updates = new ttypes.ConsultUpdates();
        this.updates.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.consult_message = new ttypes.ConsultMessage();
        this.consult_message.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.LIST) {
        this.data_files = [];
        var _rtmp3375 = input.readListBegin();
        var _size374 = _rtmp3375.size || 0;
        for (var _i376 = 0; _i376 < _size374; ++_i376) {
          var elem377 = null;
          elem377 = new ttypes.ConsultDataFile();
          elem377.read(input);
          this.data_files.push(elem377);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.LIST) {
        this.consult_data_updates = [];
        var _rtmp3379 = input.readListBegin();
        var _size378 = _rtmp3379.size || 0;
        for (var _i380 = 0; _i380 < _size378; ++_i380) {
          var elem381 = null;
          elem381 = new ttypes.ConsultDataUpdates();
          elem381.read(input);
          this.consult_data_updates.push(elem381);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.patient_updates = new ttypes.UserInfo();
        this.patient_updates.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.idempotency_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.LIST) {
        this.external_documents = [];
        var _rtmp3383 = input.readListBegin();
        var _size382 = _rtmp3383.size || 0;
        for (var _i384 = 0; _i384 < _size382; ++_i384) {
          var elem385 = null;
          elem385 = new ttypes.ExternalDocument();
          elem385.read(input);
          this.external_documents.push(elem385);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageConsultRequest.prototype.write = function(output) {
  output.writeStructBegin('ManageConsultRequest');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.create_new_consult !== null && this.create_new_consult !== undefined) {
    output.writeFieldBegin('create_new_consult', Thrift.Type.BOOL, 2);
    output.writeBool(this.create_new_consult);
    output.writeFieldEnd();
  }
  if (this.updates !== null && this.updates !== undefined) {
    output.writeFieldBegin('updates', Thrift.Type.STRUCT, 3);
    this.updates.write(output);
    output.writeFieldEnd();
  }
  if (this.consult_message !== null && this.consult_message !== undefined) {
    output.writeFieldBegin('consult_message', Thrift.Type.STRUCT, 4);
    this.consult_message.write(output);
    output.writeFieldEnd();
  }
  if (this.data_files !== null && this.data_files !== undefined) {
    output.writeFieldBegin('data_files', Thrift.Type.LIST, 5);
    output.writeListBegin(Thrift.Type.STRUCT, this.data_files.length);
    for (var iter386 in this.data_files) {
      if (this.data_files.hasOwnProperty(iter386)) {
        iter386 = this.data_files[iter386];
        iter386.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.consult_data_updates !== null && this.consult_data_updates !== undefined) {
    output.writeFieldBegin('consult_data_updates', Thrift.Type.LIST, 6);
    output.writeListBegin(Thrift.Type.STRUCT, this.consult_data_updates.length);
    for (var iter387 in this.consult_data_updates) {
      if (this.consult_data_updates.hasOwnProperty(iter387)) {
        iter387 = this.consult_data_updates[iter387];
        iter387.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.patient_updates !== null && this.patient_updates !== undefined) {
    output.writeFieldBegin('patient_updates', Thrift.Type.STRUCT, 7);
    this.patient_updates.write(output);
    output.writeFieldEnd();
  }
  if (this.idempotency_key !== null && this.idempotency_key !== undefined) {
    output.writeFieldBegin('idempotency_key', Thrift.Type.STRING, 8);
    output.writeString(this.idempotency_key);
    output.writeFieldEnd();
  }
  if (this.external_documents !== null && this.external_documents !== undefined) {
    output.writeFieldBegin('external_documents', Thrift.Type.LIST, 9);
    output.writeListBegin(Thrift.Type.STRUCT, this.external_documents.length);
    for (var iter388 in this.external_documents) {
      if (this.external_documents.hasOwnProperty(iter388)) {
        iter388 = this.external_documents[iter388];
        iter388.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageConsultResponse = module.exports.ManageConsultResponse = function(args) {
  this.consult = null;
  this.users = null;
  this.partner = null;
  if (args) {
    if (args.consult !== undefined && args.consult !== null) {
      this.consult = new ttypes.Consult(args.consult);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
ManageConsultResponse.prototype = {};
ManageConsultResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.consult = new ttypes.Consult();
        this.consult.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3390 = input.readListBegin();
        var _size389 = _rtmp3390.size || 0;
        for (var _i391 = 0; _i391 < _size389; ++_i391) {
          var elem392 = null;
          elem392 = new ttypes.User();
          elem392.read(input);
          this.users.push(elem392);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageConsultResponse.prototype.write = function(output) {
  output.writeStructBegin('ManageConsultResponse');
  if (this.consult !== null && this.consult !== undefined) {
    output.writeFieldBegin('consult', Thrift.Type.STRUCT, 1);
    this.consult.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter393 in this.users) {
      if (this.users.hasOwnProperty(iter393)) {
        iter393 = this.users[iter393];
        iter393.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 3);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageConversationRequest = module.exports.ManageConversationRequest = function(args) {
  this.conversation_id = null;
  this.create_new_conversation = null;
  this.case_id = null;
  this.consult_id = null;
  this.updates = null;
  this.message = null;
  this.message_to_remove_id = null;
  this.message_to_redact = null;
  if (args) {
    if (args.conversation_id !== undefined && args.conversation_id !== null) {
      this.conversation_id = args.conversation_id;
    }
    if (args.create_new_conversation !== undefined && args.create_new_conversation !== null) {
      this.create_new_conversation = args.create_new_conversation;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.updates !== undefined && args.updates !== null) {
      this.updates = new ttypes.ConversationUpdates(args.updates);
    }
    if (args.message !== undefined && args.message !== null) {
      this.message = new ttypes.NewConversationMessage(args.message);
    }
    if (args.message_to_remove_id !== undefined && args.message_to_remove_id !== null) {
      this.message_to_remove_id = args.message_to_remove_id;
    }
    if (args.message_to_redact !== undefined && args.message_to_redact !== null) {
      this.message_to_redact = new ttypes.RedactedMessage(args.message_to_redact);
    }
  }
};
ManageConversationRequest.prototype = {};
ManageConversationRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.conversation_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.create_new_conversation = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.updates = new ttypes.ConversationUpdates();
        this.updates.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.message = new ttypes.NewConversationMessage();
        this.message.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.message_to_remove_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.message_to_redact = new ttypes.RedactedMessage();
        this.message_to_redact.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageConversationRequest.prototype.write = function(output) {
  output.writeStructBegin('ManageConversationRequest');
  if (this.conversation_id !== null && this.conversation_id !== undefined) {
    output.writeFieldBegin('conversation_id', Thrift.Type.STRING, 1);
    output.writeString(this.conversation_id);
    output.writeFieldEnd();
  }
  if (this.create_new_conversation !== null && this.create_new_conversation !== undefined) {
    output.writeFieldBegin('create_new_conversation', Thrift.Type.BOOL, 2);
    output.writeBool(this.create_new_conversation);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 3);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 4);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.updates !== null && this.updates !== undefined) {
    output.writeFieldBegin('updates', Thrift.Type.STRUCT, 5);
    this.updates.write(output);
    output.writeFieldEnd();
  }
  if (this.message !== null && this.message !== undefined) {
    output.writeFieldBegin('message', Thrift.Type.STRUCT, 6);
    this.message.write(output);
    output.writeFieldEnd();
  }
  if (this.message_to_remove_id !== null && this.message_to_remove_id !== undefined) {
    output.writeFieldBegin('message_to_remove_id', Thrift.Type.STRING, 7);
    output.writeString(this.message_to_remove_id);
    output.writeFieldEnd();
  }
  if (this.message_to_redact !== null && this.message_to_redact !== undefined) {
    output.writeFieldBegin('message_to_redact', Thrift.Type.STRUCT, 8);
    this.message_to_redact.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageConversationResponse = module.exports.ManageConversationResponse = function(args) {
  this.conversation = null;
  this.users = null;
  if (args) {
    if (args.conversation !== undefined && args.conversation !== null) {
      this.conversation = new ttypes.Conversation(args.conversation);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
  }
};
ManageConversationResponse.prototype = {};
ManageConversationResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.conversation = new ttypes.Conversation();
        this.conversation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3395 = input.readListBegin();
        var _size394 = _rtmp3395.size || 0;
        for (var _i396 = 0; _i396 < _size394; ++_i396) {
          var elem397 = null;
          elem397 = new ttypes.User();
          elem397.read(input);
          this.users.push(elem397);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageConversationResponse.prototype.write = function(output) {
  output.writeStructBegin('ManageConversationResponse');
  if (this.conversation !== null && this.conversation !== undefined) {
    output.writeFieldBegin('conversation', Thrift.Type.STRUCT, 1);
    this.conversation.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter398 in this.users) {
      if (this.users.hasOwnProperty(iter398)) {
        iter398 = this.users[iter398];
        iter398.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageTeamRequest = module.exports.ManageTeamRequest = function(args) {
  this.team_id = null;
  this.create_new_team = null;
  this.updates = null;
  this.remove_team = null;
  if (args) {
    if (args.team_id !== undefined && args.team_id !== null) {
      this.team_id = args.team_id;
    }
    if (args.create_new_team !== undefined && args.create_new_team !== null) {
      this.create_new_team = args.create_new_team;
    }
    if (args.updates !== undefined && args.updates !== null) {
      this.updates = new ttypes.TeamUpdates(args.updates);
    }
    if (args.remove_team !== undefined && args.remove_team !== null) {
      this.remove_team = args.remove_team;
    }
  }
};
ManageTeamRequest.prototype = {};
ManageTeamRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.team_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.BOOL) {
        this.create_new_team = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.updates = new ttypes.TeamUpdates();
        this.updates.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.remove_team = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageTeamRequest.prototype.write = function(output) {
  output.writeStructBegin('ManageTeamRequest');
  if (this.team_id !== null && this.team_id !== undefined) {
    output.writeFieldBegin('team_id', Thrift.Type.STRING, 1);
    output.writeString(this.team_id);
    output.writeFieldEnd();
  }
  if (this.create_new_team !== null && this.create_new_team !== undefined) {
    output.writeFieldBegin('create_new_team', Thrift.Type.BOOL, 2);
    output.writeBool(this.create_new_team);
    output.writeFieldEnd();
  }
  if (this.updates !== null && this.updates !== undefined) {
    output.writeFieldBegin('updates', Thrift.Type.STRUCT, 3);
    this.updates.write(output);
    output.writeFieldEnd();
  }
  if (this.remove_team !== null && this.remove_team !== undefined) {
    output.writeFieldBegin('remove_team', Thrift.Type.BOOL, 4);
    output.writeBool(this.remove_team);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ManageTeamResponse = module.exports.ManageTeamResponse = function(args) {
  this.team = null;
  this.users = null;
  if (args) {
    if (args.team !== undefined && args.team !== null) {
      this.team = new ttypes.Team(args.team);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
  }
};
ManageTeamResponse.prototype = {};
ManageTeamResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.team = new ttypes.Team();
        this.team.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3400 = input.readListBegin();
        var _size399 = _rtmp3400.size || 0;
        for (var _i401 = 0; _i401 < _size399; ++_i401) {
          var elem402 = null;
          elem402 = new ttypes.User();
          elem402.read(input);
          this.users.push(elem402);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ManageTeamResponse.prototype.write = function(output) {
  output.writeStructBegin('ManageTeamResponse');
  if (this.team !== null && this.team !== undefined) {
    output.writeFieldBegin('team', Thrift.Type.STRUCT, 1);
    this.team.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter403 in this.users) {
      if (this.users.hasOwnProperty(iter403)) {
        iter403 = this.users[iter403];
        iter403.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ReportEventRequest = module.exports.ReportEventRequest = function(args) {
  this.event = null;
  this.object_id = null;
  this.object_type = null;
  if (args) {
    if (args.event !== undefined && args.event !== null) {
      this.event = new ttypes.Event(args.event);
    }
    if (args.object_id !== undefined && args.object_id !== null) {
      this.object_id = args.object_id;
    }
    if (args.object_type !== undefined && args.object_type !== null) {
      this.object_type = args.object_type;
    }
  }
};
ReportEventRequest.prototype = {};
ReportEventRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.event = new ttypes.Event();
        this.event.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.object_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.object_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ReportEventRequest.prototype.write = function(output) {
  output.writeStructBegin('ReportEventRequest');
  if (this.event !== null && this.event !== undefined) {
    output.writeFieldBegin('event', Thrift.Type.STRUCT, 1);
    this.event.write(output);
    output.writeFieldEnd();
  }
  if (this.object_id !== null && this.object_id !== undefined) {
    output.writeFieldBegin('object_id', Thrift.Type.STRING, 2);
    output.writeString(this.object_id);
    output.writeFieldEnd();
  }
  if (this.object_type !== null && this.object_type !== undefined) {
    output.writeFieldBegin('object_type', Thrift.Type.I32, 3);
    output.writeI32(this.object_type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ReportEventResponse = module.exports.ReportEventResponse = function(args) {
  this.user = null;
  this.consult = null;
  this.the_case = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.consult !== undefined && args.consult !== null) {
      this.consult = new ttypes.Consult(args.consult);
    }
    if (args.the_case !== undefined && args.the_case !== null) {
      this.the_case = new ttypes.Case(args.the_case);
    }
  }
};
ReportEventResponse.prototype = {};
ReportEventResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.consult = new ttypes.Consult();
        this.consult.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.the_case = new ttypes.Case();
        this.the_case.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ReportEventResponse.prototype.write = function(output) {
  output.writeStructBegin('ReportEventResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.consult !== null && this.consult !== undefined) {
    output.writeFieldBegin('consult', Thrift.Type.STRUCT, 2);
    this.consult.write(output);
    output.writeFieldEnd();
  }
  if (this.the_case !== null && this.the_case !== undefined) {
    output.writeFieldBegin('the_case', Thrift.Type.STRUCT, 3);
    this.the_case.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResendConversationMessageNotificationRequest = module.exports.ResendConversationMessageNotificationRequest = function(args) {
  this.conversation_message_id = null;
  this.patient_updates = null;
  if (args) {
    if (args.conversation_message_id !== undefined && args.conversation_message_id !== null) {
      this.conversation_message_id = args.conversation_message_id;
    }
    if (args.patient_updates !== undefined && args.patient_updates !== null) {
      this.patient_updates = new ttypes.UserInfo(args.patient_updates);
    }
  }
};
ResendConversationMessageNotificationRequest.prototype = {};
ResendConversationMessageNotificationRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.conversation_message_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.patient_updates = new ttypes.UserInfo();
        this.patient_updates.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResendConversationMessageNotificationRequest.prototype.write = function(output) {
  output.writeStructBegin('ResendConversationMessageNotificationRequest');
  if (this.conversation_message_id !== null && this.conversation_message_id !== undefined) {
    output.writeFieldBegin('conversation_message_id', Thrift.Type.STRING, 1);
    output.writeString(this.conversation_message_id);
    output.writeFieldEnd();
  }
  if (this.patient_updates !== null && this.patient_updates !== undefined) {
    output.writeFieldBegin('patient_updates', Thrift.Type.STRUCT, 2);
    this.patient_updates.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResendConversationMessageNotificationResponse = module.exports.ResendConversationMessageNotificationResponse = function(args) {
  this.conversation = null;
  this.users = null;
  if (args) {
    if (args.conversation !== undefined && args.conversation !== null) {
      this.conversation = new ttypes.Conversation(args.conversation);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
  }
};
ResendConversationMessageNotificationResponse.prototype = {};
ResendConversationMessageNotificationResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.conversation = new ttypes.Conversation();
        this.conversation.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3405 = input.readListBegin();
        var _size404 = _rtmp3405.size || 0;
        for (var _i406 = 0; _i406 < _size404; ++_i406) {
          var elem407 = null;
          elem407 = new ttypes.User();
          elem407.read(input);
          this.users.push(elem407);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResendConversationMessageNotificationResponse.prototype.write = function(output) {
  output.writeStructBegin('ResendConversationMessageNotificationResponse');
  if (this.conversation !== null && this.conversation !== undefined) {
    output.writeFieldBegin('conversation', Thrift.Type.STRUCT, 1);
    this.conversation.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter408 in this.users) {
      if (this.users.hasOwnProperty(iter408)) {
        iter408 = this.users[iter408];
        iter408.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SendVideoVisitLinkRequest = module.exports.SendVideoVisitLinkRequest = function(args) {
  this.link = null;
  this.email = null;
  this.phone_number = null;
  if (args) {
    if (args.link !== undefined && args.link !== null) {
      this.link = args.link;
    }
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.phone_number !== undefined && args.phone_number !== null) {
      this.phone_number = args.phone_number;
    }
  }
};
SendVideoVisitLinkRequest.prototype = {};
SendVideoVisitLinkRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.link = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.phone_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendVideoVisitLinkRequest.prototype.write = function(output) {
  output.writeStructBegin('SendVideoVisitLinkRequest');
  if (this.link !== null && this.link !== undefined) {
    output.writeFieldBegin('link', Thrift.Type.STRING, 1);
    output.writeString(this.link);
    output.writeFieldEnd();
  }
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 2);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.phone_number !== null && this.phone_number !== undefined) {
    output.writeFieldBegin('phone_number', Thrift.Type.STRING, 3);
    output.writeString(this.phone_number);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SendVideoVisitLinkResponse = module.exports.SendVideoVisitLinkResponse = function(args) {
};
SendVideoVisitLinkResponse.prototype = {};
SendVideoVisitLinkResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendVideoVisitLinkResponse.prototype.write = function(output) {
  output.writeStructBegin('SendVideoVisitLinkResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateUserRequest = module.exports.UpdateUserRequest = function(args) {
  this.user_id = null;
  this.updates = null;
  if (args) {
    if (args.user_id !== undefined && args.user_id !== null) {
      this.user_id = args.user_id;
    }
    if (args.updates !== undefined && args.updates !== null) {
      this.updates = new ttypes.UserInfo(args.updates);
    }
  }
};
UpdateUserRequest.prototype = {};
UpdateUserRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.user_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.updates = new ttypes.UserInfo();
        this.updates.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUserRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdateUserRequest');
  if (this.user_id !== null && this.user_id !== undefined) {
    output.writeFieldBegin('user_id', Thrift.Type.STRING, 1);
    output.writeString(this.user_id);
    output.writeFieldEnd();
  }
  if (this.updates !== null && this.updates !== undefined) {
    output.writeFieldBegin('updates', Thrift.Type.STRUCT, 2);
    this.updates.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateUserResponse = module.exports.UpdateUserResponse = function(args) {
  this.user = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
  }
};
UpdateUserResponse.prototype = {};
UpdateUserResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateUserResponse.prototype.write = function(output) {
  output.writeStructBegin('UpdateUserResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateClinicalReviewRequest = module.exports.UpdateClinicalReviewRequest = function(args) {
  this.review = null;
  if (args) {
    if (args.review !== undefined && args.review !== null) {
      this.review = new ttypes.ClinicalReview(args.review);
    }
  }
};
UpdateClinicalReviewRequest.prototype = {};
UpdateClinicalReviewRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.review = new ttypes.ClinicalReview();
        this.review.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateClinicalReviewRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdateClinicalReviewRequest');
  if (this.review !== null && this.review !== undefined) {
    output.writeFieldBegin('review', Thrift.Type.STRUCT, 1);
    this.review.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateClinicalReviewResponse = module.exports.UpdateClinicalReviewResponse = function(args) {
  this.review = null;
  if (args) {
    if (args.review !== undefined && args.review !== null) {
      this.review = new ttypes.ClinicalReview(args.review);
    }
  }
};
UpdateClinicalReviewResponse.prototype = {};
UpdateClinicalReviewResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.review = new ttypes.ClinicalReview();
        this.review.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateClinicalReviewResponse.prototype.write = function(output) {
  output.writeStructBegin('UpdateClinicalReviewResponse');
  if (this.review !== null && this.review !== undefined) {
    output.writeFieldBegin('review', Thrift.Type.STRUCT, 1);
    this.review.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdatePasswordRequest = module.exports.UpdatePasswordRequest = function(args) {
  this.password = null;
  this.old_password = null;
  if (args) {
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
    if (args.old_password !== undefined && args.old_password !== null) {
      this.old_password = args.old_password;
    }
  }
};
UpdatePasswordRequest.prototype = {};
UpdatePasswordRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.old_password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdatePasswordRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdatePasswordRequest');
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 2);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  if (this.old_password !== null && this.old_password !== undefined) {
    output.writeFieldBegin('old_password', Thrift.Type.STRING, 3);
    output.writeString(this.old_password);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdatePasswordResponse = module.exports.UpdatePasswordResponse = function(args) {
  this.user = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
  }
};
UpdatePasswordResponse.prototype = {};
UpdatePasswordResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdatePasswordResponse.prototype.write = function(output) {
  output.writeStructBegin('UpdatePasswordResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var EmailAlreadyRegistered = module.exports.EmailAlreadyRegistered = function(args) {
  Thrift.TException.call(this, "EmailAlreadyRegistered");
  this.name = "EmailAlreadyRegistered";
};
Thrift.inherits(EmailAlreadyRegistered, Thrift.TException);
EmailAlreadyRegistered.prototype.name = 'EmailAlreadyRegistered';
EmailAlreadyRegistered.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

EmailAlreadyRegistered.prototype.write = function(output) {
  output.writeStructBegin('EmailAlreadyRegistered');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CheckTokenRequest = module.exports.CheckTokenRequest = function(args) {
  this.token_id = null;
  if (args) {
    if (args.token_id !== undefined && args.token_id !== null) {
      this.token_id = args.token_id;
    }
  }
};
CheckTokenRequest.prototype = {};
CheckTokenRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CheckTokenRequest.prototype.write = function(output) {
  output.writeStructBegin('CheckTokenRequest');
  if (this.token_id !== null && this.token_id !== undefined) {
    output.writeFieldBegin('token_id', Thrift.Type.STRING, 1);
    output.writeString(this.token_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CheckTokenResponse = module.exports.CheckTokenResponse = function(args) {
  this.token_state = null;
  if (args) {
    if (args.token_state !== undefined && args.token_state !== null) {
      this.token_state = args.token_state;
    }
  }
};
CheckTokenResponse.prototype = {};
CheckTokenResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.token_state = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CheckTokenResponse.prototype.write = function(output) {
  output.writeStructBegin('CheckTokenResponse');
  if (this.token_state !== null && this.token_state !== undefined) {
    output.writeFieldBegin('token_state', Thrift.Type.I32, 1);
    output.writeI32(this.token_state);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateAccountRequest = module.exports.CreateAccountRequest = function(args) {
  this.email = null;
  this.password = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
  }
};
CreateAccountRequest.prototype = {};
CreateAccountRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateAccountRequest.prototype.write = function(output) {
  output.writeStructBegin('CreateAccountRequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 2);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateAccountResponse = module.exports.CreateAccountResponse = function(args) {
  this.token = null;
  if (args) {
    if (args.token !== undefined && args.token !== null) {
      this.token = args.token;
    }
  }
};
CreateAccountResponse.prototype = {};
CreateAccountResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateAccountResponse.prototype.write = function(output) {
  output.writeStructBegin('CreateAccountResponse');
  if (this.token !== null && this.token !== undefined) {
    output.writeFieldBegin('token', Thrift.Type.STRING, 1);
    output.writeString(this.token);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Enable2FARequest = module.exports.Enable2FARequest = function(args) {
  this.email = null;
  this.password = null;
  this.totp_key = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
    if (args.totp_key !== undefined && args.totp_key !== null) {
      this.totp_key = args.totp_key;
    }
  }
};
Enable2FARequest.prototype = {};
Enable2FARequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.totp_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Enable2FARequest.prototype.write = function(output) {
  output.writeStructBegin('Enable2FARequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 2);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  if (this.totp_key !== null && this.totp_key !== undefined) {
    output.writeFieldBegin('totp_key', Thrift.Type.STRING, 3);
    output.writeString(this.totp_key);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Enable2FAResponse = module.exports.Enable2FAResponse = function(args) {
  this.user = null;
  this.backup_codes = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.backup_codes !== undefined && args.backup_codes !== null) {
      this.backup_codes = Thrift.copyList(args.backup_codes, [null]);
    }
  }
};
Enable2FAResponse.prototype = {};
Enable2FAResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.backup_codes = [];
        var _rtmp3410 = input.readListBegin();
        var _size409 = _rtmp3410.size || 0;
        for (var _i411 = 0; _i411 < _size409; ++_i411) {
          var elem412 = null;
          elem412 = input.readString();
          this.backup_codes.push(elem412);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Enable2FAResponse.prototype.write = function(output) {
  output.writeStructBegin('Enable2FAResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.backup_codes !== null && this.backup_codes !== undefined) {
    output.writeFieldBegin('backup_codes', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRING, this.backup_codes.length);
    for (var iter413 in this.backup_codes) {
      if (this.backup_codes.hasOwnProperty(iter413)) {
        iter413 = this.backup_codes[iter413];
        output.writeString(iter413);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Generate2FARequest = module.exports.Generate2FARequest = function(args) {
  this.email = null;
  this.password = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
  }
};
Generate2FARequest.prototype = {};
Generate2FARequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Generate2FARequest.prototype.write = function(output) {
  output.writeStructBegin('Generate2FARequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 2);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var Generate2FAResponse = module.exports.Generate2FAResponse = function(args) {
  this.totp_seed = null;
  if (args) {
    if (args.totp_seed !== undefined && args.totp_seed !== null) {
      this.totp_seed = args.totp_seed;
    }
  }
};
Generate2FAResponse.prototype = {};
Generate2FAResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.totp_seed = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

Generate2FAResponse.prototype.write = function(output) {
  output.writeStructBegin('Generate2FAResponse');
  if (this.totp_seed !== null && this.totp_seed !== undefined) {
    output.writeFieldBegin('totp_seed', Thrift.Type.STRING, 1);
    output.writeString(this.totp_seed);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetPartnerInfoForTokenRequest = module.exports.GetPartnerInfoForTokenRequest = function(args) {
  this.token_id = null;
  this.case_id = null;
  if (args) {
    if (args.token_id !== undefined && args.token_id !== null) {
      this.token_id = args.token_id;
    }
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
  }
};
GetPartnerInfoForTokenRequest.prototype = {};
GetPartnerInfoForTokenRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetPartnerInfoForTokenRequest.prototype.write = function(output) {
  output.writeStructBegin('GetPartnerInfoForTokenRequest');
  if (this.token_id !== null && this.token_id !== undefined) {
    output.writeFieldBegin('token_id', Thrift.Type.STRING, 1);
    output.writeString(this.token_id);
    output.writeFieldEnd();
  }
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 2);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetPartnerInfoForTokenResponse = module.exports.GetPartnerInfoForTokenResponse = function(args) {
  this.partner_name = null;
  this.partner_logo_url = null;
  if (args) {
    if (args.partner_name !== undefined && args.partner_name !== null) {
      this.partner_name = args.partner_name;
    }
    if (args.partner_logo_url !== undefined && args.partner_logo_url !== null) {
      this.partner_logo_url = args.partner_logo_url;
    }
  }
};
GetPartnerInfoForTokenResponse.prototype = {};
GetPartnerInfoForTokenResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.partner_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.partner_logo_url = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetPartnerInfoForTokenResponse.prototype.write = function(output) {
  output.writeStructBegin('GetPartnerInfoForTokenResponse');
  if (this.partner_name !== null && this.partner_name !== undefined) {
    output.writeFieldBegin('partner_name', Thrift.Type.STRING, 1);
    output.writeString(this.partner_name);
    output.writeFieldEnd();
  }
  if (this.partner_logo_url !== null && this.partner_logo_url !== undefined) {
    output.writeFieldBegin('partner_logo_url', Thrift.Type.STRING, 2);
    output.writeString(this.partner_logo_url);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ValidateLoginRequest = module.exports.ValidateLoginRequest = function(args) {
  this.email = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
  }
};
ValidateLoginRequest.prototype = {};
ValidateLoginRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ValidateLoginRequest.prototype.write = function(output) {
  output.writeStructBegin('ValidateLoginRequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ValidateLoginResponse = module.exports.ValidateLoginResponse = function(args) {
  this.should_redirect = null;
  if (args) {
    if (args.should_redirect !== undefined && args.should_redirect !== null) {
      this.should_redirect = args.should_redirect;
    }
  }
};
ValidateLoginResponse.prototype = {};
ValidateLoginResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.BOOL) {
        this.should_redirect = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ValidateLoginResponse.prototype.write = function(output) {
  output.writeStructBegin('ValidateLoginResponse');
  if (this.should_redirect !== null && this.should_redirect !== undefined) {
    output.writeFieldBegin('should_redirect', Thrift.Type.BOOL, 1);
    output.writeBool(this.should_redirect);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var LoginRequest = module.exports.LoginRequest = function(args) {
  this.email = null;
  this.password = null;
  this.client_token = null;
  this.totp_key = null;
  this.backup_code = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
    if (args.client_token !== undefined && args.client_token !== null) {
      this.client_token = args.client_token;
    }
    if (args.totp_key !== undefined && args.totp_key !== null) {
      this.totp_key = args.totp_key;
    }
    if (args.backup_code !== undefined && args.backup_code !== null) {
      this.backup_code = args.backup_code;
    }
  }
};
LoginRequest.prototype = {};
LoginRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.client_token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.totp_key = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.backup_code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LoginRequest.prototype.write = function(output) {
  output.writeStructBegin('LoginRequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 2);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  if (this.client_token !== null && this.client_token !== undefined) {
    output.writeFieldBegin('client_token', Thrift.Type.STRING, 3);
    output.writeString(this.client_token);
    output.writeFieldEnd();
  }
  if (this.totp_key !== null && this.totp_key !== undefined) {
    output.writeFieldBegin('totp_key', Thrift.Type.STRING, 4);
    output.writeString(this.totp_key);
    output.writeFieldEnd();
  }
  if (this.backup_code !== null && this.backup_code !== undefined) {
    output.writeFieldBegin('backup_code', Thrift.Type.STRING, 5);
    output.writeString(this.backup_code);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var OidcLoginRequest = module.exports.OidcLoginRequest = function(args) {
  this.id_token = null;
  this.access_token = null;
  if (args) {
    if (args.id_token !== undefined && args.id_token !== null) {
      this.id_token = args.id_token;
    }
    if (args.access_token !== undefined && args.access_token !== null) {
      this.access_token = args.access_token;
    }
  }
};
OidcLoginRequest.prototype = {};
OidcLoginRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.id_token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.access_token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

OidcLoginRequest.prototype.write = function(output) {
  output.writeStructBegin('OidcLoginRequest');
  if (this.id_token !== null && this.id_token !== undefined) {
    output.writeFieldBegin('id_token', Thrift.Type.STRING, 1);
    output.writeString(this.id_token);
    output.writeFieldEnd();
  }
  if (this.access_token !== null && this.access_token !== undefined) {
    output.writeFieldBegin('access_token', Thrift.Type.STRING, 2);
    output.writeString(this.access_token);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var LoginResponse = module.exports.LoginResponse = function(args) {
  this.token = null;
  this.user = null;
  this.mfa_setup_required = null;
  this.totp_required = null;
  if (args) {
    if (args.token !== undefined && args.token !== null) {
      this.token = args.token;
    }
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.mfa_setup_required !== undefined && args.mfa_setup_required !== null) {
      this.mfa_setup_required = args.mfa_setup_required;
    }
    if (args.totp_required !== undefined && args.totp_required !== null) {
      this.totp_required = args.totp_required;
    }
  }
};
LoginResponse.prototype = {};
LoginResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.BOOL) {
        this.mfa_setup_required = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.totp_required = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LoginResponse.prototype.write = function(output) {
  output.writeStructBegin('LoginResponse');
  if (this.token !== null && this.token !== undefined) {
    output.writeFieldBegin('token', Thrift.Type.STRING, 1);
    output.writeString(this.token);
    output.writeFieldEnd();
  }
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 2);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.mfa_setup_required !== null && this.mfa_setup_required !== undefined) {
    output.writeFieldBegin('mfa_setup_required', Thrift.Type.BOOL, 3);
    output.writeBool(this.mfa_setup_required);
    output.writeFieldEnd();
  }
  if (this.totp_required !== null && this.totp_required !== undefined) {
    output.writeFieldBegin('totp_required', Thrift.Type.BOOL, 4);
    output.writeBool(this.totp_required);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var LogoutRequest = module.exports.LogoutRequest = function(args) {
  this.reason = null;
  if (args) {
    if (args.reason !== undefined && args.reason !== null) {
      this.reason = args.reason;
    }
  }
};
LogoutRequest.prototype = {};
LogoutRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I32) {
        this.reason = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LogoutRequest.prototype.write = function(output) {
  output.writeStructBegin('LogoutRequest');
  if (this.reason !== null && this.reason !== undefined) {
    output.writeFieldBegin('reason', Thrift.Type.I32, 1);
    output.writeI32(this.reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var LogoutResponse = module.exports.LogoutResponse = function(args) {
};
LogoutResponse.prototype = {};
LogoutResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

LogoutResponse.prototype.write = function(output) {
  output.writeStructBegin('LogoutResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PatientVerificationPayload = module.exports.PatientVerificationPayload = function(args) {
  this.verification_code = null;
  this.date_of_birth = null;
  if (args) {
    if (args.verification_code !== undefined && args.verification_code !== null) {
      this.verification_code = args.verification_code;
    }
    if (args.date_of_birth !== undefined && args.date_of_birth !== null) {
      this.date_of_birth = new ttypes.DateOfBirth(args.date_of_birth);
    }
  }
};
PatientVerificationPayload.prototype = {};
PatientVerificationPayload.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.verification_code = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.date_of_birth = new ttypes.DateOfBirth();
        this.date_of_birth.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PatientVerificationPayload.prototype.write = function(output) {
  output.writeStructBegin('PatientVerificationPayload');
  if (this.verification_code !== null && this.verification_code !== undefined) {
    output.writeFieldBegin('verification_code', Thrift.Type.STRING, 1);
    output.writeString(this.verification_code);
    output.writeFieldEnd();
  }
  if (this.date_of_birth !== null && this.date_of_birth !== undefined) {
    output.writeFieldBegin('date_of_birth', Thrift.Type.STRUCT, 2);
    this.date_of_birth.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PasswordResetRequest = module.exports.PasswordResetRequest = function(args) {
  this.email = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
  }
};
PasswordResetRequest.prototype = {};
PasswordResetRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PasswordResetRequest.prototype.write = function(output) {
  output.writeStructBegin('PasswordResetRequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PasswordResetResponse = module.exports.PasswordResetResponse = function(args) {
};
PasswordResetResponse.prototype = {};
PasswordResetResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PasswordResetResponse.prototype.write = function(output) {
  output.writeStructBegin('PasswordResetResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RedeemTokenPayload = module.exports.RedeemTokenPayload = function(args) {
  this.patient_verification = null;
  if (args) {
    if (args.patient_verification !== undefined && args.patient_verification !== null) {
      this.patient_verification = new ttypes.PatientVerificationPayload(args.patient_verification);
    }
  }
};
RedeemTokenPayload.prototype = {};
RedeemTokenPayload.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.patient_verification = new ttypes.PatientVerificationPayload();
        this.patient_verification.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RedeemTokenPayload.prototype.write = function(output) {
  output.writeStructBegin('RedeemTokenPayload');
  if (this.patient_verification !== null && this.patient_verification !== undefined) {
    output.writeFieldBegin('patient_verification', Thrift.Type.STRUCT, 1);
    this.patient_verification.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RedeemTokenRequest = module.exports.RedeemTokenRequest = function(args) {
  this.token_id = null;
  this.payload = null;
  if (args) {
    if (args.token_id !== undefined && args.token_id !== null) {
      this.token_id = args.token_id;
    }
    if (args.payload !== undefined && args.payload !== null) {
      this.payload = new ttypes.RedeemTokenPayload(args.payload);
    }
  }
};
RedeemTokenRequest.prototype = {};
RedeemTokenRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.payload = new ttypes.RedeemTokenPayload();
        this.payload.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RedeemTokenRequest.prototype.write = function(output) {
  output.writeStructBegin('RedeemTokenRequest');
  if (this.token_id !== null && this.token_id !== undefined) {
    output.writeFieldBegin('token_id', Thrift.Type.STRING, 1);
    output.writeString(this.token_id);
    output.writeFieldEnd();
  }
  if (this.payload !== null && this.payload !== undefined) {
    output.writeFieldBegin('payload', Thrift.Type.STRUCT, 2);
    this.payload.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var RedeemTokenResponse = module.exports.RedeemTokenResponse = function(args) {
  this.user = null;
  this.remaining_attempts = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
    if (args.remaining_attempts !== undefined && args.remaining_attempts !== null) {
      this.remaining_attempts = args.remaining_attempts;
    }
  }
};
RedeemTokenResponse.prototype = {};
RedeemTokenResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I16) {
        this.remaining_attempts = input.readI16();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

RedeemTokenResponse.prototype.write = function(output) {
  output.writeStructBegin('RedeemTokenResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.remaining_attempts !== null && this.remaining_attempts !== undefined) {
    output.writeFieldBegin('remaining_attempts', Thrift.Type.I16, 2);
    output.writeI16(this.remaining_attempts);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResendExpiredTokenRequest = module.exports.ResendExpiredTokenRequest = function(args) {
  this.token_id = null;
  if (args) {
    if (args.token_id !== undefined && args.token_id !== null) {
      this.token_id = args.token_id;
    }
  }
};
ResendExpiredTokenRequest.prototype = {};
ResendExpiredTokenRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResendExpiredTokenRequest.prototype.write = function(output) {
  output.writeStructBegin('ResendExpiredTokenRequest');
  if (this.token_id !== null && this.token_id !== undefined) {
    output.writeFieldBegin('token_id', Thrift.Type.STRING, 1);
    output.writeString(this.token_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ResendExpiredTokenResponse = module.exports.ResendExpiredTokenResponse = function(args) {
};
ResendExpiredTokenResponse.prototype = {};
ResendExpiredTokenResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ResendExpiredTokenResponse.prototype.write = function(output) {
  output.writeStructBegin('ResendExpiredTokenResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SendTokenVerificationCodeRequest = module.exports.SendTokenVerificationCodeRequest = function(args) {
  this.token_id = null;
  if (args) {
    if (args.token_id !== undefined && args.token_id !== null) {
      this.token_id = args.token_id;
    }
  }
};
SendTokenVerificationCodeRequest.prototype = {};
SendTokenVerificationCodeRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.token_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendTokenVerificationCodeRequest.prototype.write = function(output) {
  output.writeStructBegin('SendTokenVerificationCodeRequest');
  if (this.token_id !== null && this.token_id !== undefined) {
    output.writeFieldBegin('token_id', Thrift.Type.STRING, 1);
    output.writeString(this.token_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SendTokenVerificationCodeResponse = module.exports.SendTokenVerificationCodeResponse = function(args) {
};
SendTokenVerificationCodeResponse.prototype = {};
SendTokenVerificationCodeResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SendTokenVerificationCodeResponse.prototype.write = function(output) {
  output.writeStructBegin('SendTokenVerificationCodeResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SubmitContactInfoRequest = module.exports.SubmitContactInfoRequest = function(args) {
  this.email = null;
  this.phone_number = null;
  this.name = null;
  this.company = null;
  this.detail = null;
  this.title = null;
  if (args) {
    if (args.email !== undefined && args.email !== null) {
      this.email = args.email;
    }
    if (args.phone_number !== undefined && args.phone_number !== null) {
      this.phone_number = args.phone_number;
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = args.name;
    }
    if (args.company !== undefined && args.company !== null) {
      this.company = args.company;
    }
    if (args.detail !== undefined && args.detail !== null) {
      this.detail = args.detail;
    }
    if (args.title !== undefined && args.title !== null) {
      this.title = args.title;
    }
  }
};
SubmitContactInfoRequest.prototype = {};
SubmitContactInfoRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.email = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.phone_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.company = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRING) {
        this.detail = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.title = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SubmitContactInfoRequest.prototype.write = function(output) {
  output.writeStructBegin('SubmitContactInfoRequest');
  if (this.email !== null && this.email !== undefined) {
    output.writeFieldBegin('email', Thrift.Type.STRING, 1);
    output.writeString(this.email);
    output.writeFieldEnd();
  }
  if (this.phone_number !== null && this.phone_number !== undefined) {
    output.writeFieldBegin('phone_number', Thrift.Type.STRING, 2);
    output.writeString(this.phone_number);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRING, 3);
    output.writeString(this.name);
    output.writeFieldEnd();
  }
  if (this.company !== null && this.company !== undefined) {
    output.writeFieldBegin('company', Thrift.Type.STRING, 4);
    output.writeString(this.company);
    output.writeFieldEnd();
  }
  if (this.detail !== null && this.detail !== undefined) {
    output.writeFieldBegin('detail', Thrift.Type.STRING, 5);
    output.writeString(this.detail);
    output.writeFieldEnd();
  }
  if (this.title !== null && this.title !== undefined) {
    output.writeFieldBegin('title', Thrift.Type.STRING, 6);
    output.writeString(this.title);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var SubmitContactInfoResponse = module.exports.SubmitContactInfoResponse = function(args) {
};
SubmitContactInfoResponse.prototype = {};
SubmitContactInfoResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

SubmitContactInfoResponse.prototype.write = function(output) {
  output.writeStructBegin('SubmitContactInfoResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var NameAlreadyRegistered = module.exports.NameAlreadyRegistered = function(args) {
  Thrift.TException.call(this, "NameAlreadyRegistered");
  this.name = "NameAlreadyRegistered";
};
Thrift.inherits(NameAlreadyRegistered, Thrift.TException);
NameAlreadyRegistered.prototype.name = 'NameAlreadyRegistered';
NameAlreadyRegistered.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

NameAlreadyRegistered.prototype.write = function(output) {
  output.writeStructBegin('NameAlreadyRegistered');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreatePartnerRequest = module.exports.CreatePartnerRequest = function(args) {
  this.mutations = null;
  if (args) {
    if (args.mutations !== undefined && args.mutations !== null) {
      this.mutations = new ttypes.PartnerMutations(args.mutations);
    }
  }
};
CreatePartnerRequest.prototype = {};
CreatePartnerRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.mutations = new ttypes.PartnerMutations();
        this.mutations.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreatePartnerRequest.prototype.write = function(output) {
  output.writeStructBegin('CreatePartnerRequest');
  if (this.mutations !== null && this.mutations !== undefined) {
    output.writeFieldBegin('mutations', Thrift.Type.STRUCT, 2);
    this.mutations.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreatePartnerResponse = module.exports.CreatePartnerResponse = function(args) {
  this.partner = null;
  if (args) {
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
CreatePartnerResponse.prototype = {};
CreatePartnerResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreatePartnerResponse.prototype.write = function(output) {
  output.writeStructBegin('CreatePartnerResponse');
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 1);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateUserRequest = module.exports.CreateUserRequest = function(args) {
  this.user = null;
  this.password = null;
  this.email_option = null;
  this.partner_id = null;
  this.enable_login = null;
  this.is_admin = null;
  this.member_roles = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.UserInfo(args.user);
    }
    if (args.password !== undefined && args.password !== null) {
      this.password = args.password;
    }
    if (args.email_option !== undefined && args.email_option !== null) {
      this.email_option = args.email_option;
    }
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.enable_login !== undefined && args.enable_login !== null) {
      this.enable_login = args.enable_login;
    }
    if (args.is_admin !== undefined && args.is_admin !== null) {
      this.is_admin = args.is_admin;
    }
    if (args.member_roles !== undefined && args.member_roles !== null) {
      this.member_roles = new ttypes.PartnerMemberRoles(args.member_roles);
    }
  }
};
CreateUserRequest.prototype = {};
CreateUserRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.UserInfo();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.password = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.email_option = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.BOOL) {
        this.enable_login = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.BOOL) {
        this.is_admin = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.member_roles = new ttypes.PartnerMemberRoles();
        this.member_roles.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateUserRequest.prototype.write = function(output) {
  output.writeStructBegin('CreateUserRequest');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  if (this.password !== null && this.password !== undefined) {
    output.writeFieldBegin('password', Thrift.Type.STRING, 2);
    output.writeString(this.password);
    output.writeFieldEnd();
  }
  if (this.email_option !== null && this.email_option !== undefined) {
    output.writeFieldBegin('email_option', Thrift.Type.I32, 3);
    output.writeI32(this.email_option);
    output.writeFieldEnd();
  }
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 4);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.enable_login !== null && this.enable_login !== undefined) {
    output.writeFieldBegin('enable_login', Thrift.Type.BOOL, 6);
    output.writeBool(this.enable_login);
    output.writeFieldEnd();
  }
  if (this.is_admin !== null && this.is_admin !== undefined) {
    output.writeFieldBegin('is_admin', Thrift.Type.BOOL, 7);
    output.writeBool(this.is_admin);
    output.writeFieldEnd();
  }
  if (this.member_roles !== null && this.member_roles !== undefined) {
    output.writeFieldBegin('member_roles', Thrift.Type.STRUCT, 8);
    this.member_roles.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var CreateUserResponse = module.exports.CreateUserResponse = function(args) {
  this.user = null;
  if (args) {
    if (args.user !== undefined && args.user !== null) {
      this.user = new ttypes.User(args.user);
    }
  }
};
CreateUserResponse.prototype = {};
CreateUserResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.user = new ttypes.User();
        this.user.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

CreateUserResponse.prototype.write = function(output) {
  output.writeStructBegin('CreateUserResponse');
  if (this.user !== null && this.user !== undefined) {
    output.writeFieldBegin('user', Thrift.Type.STRUCT, 1);
    this.user.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var MergeCasesRequest = module.exports.MergeCasesRequest = function(args) {
  this.source_case_id = null;
  this.target_case_id = null;
  if (args) {
    if (args.source_case_id !== undefined && args.source_case_id !== null) {
      this.source_case_id = args.source_case_id;
    }
    if (args.target_case_id !== undefined && args.target_case_id !== null) {
      this.target_case_id = args.target_case_id;
    }
  }
};
MergeCasesRequest.prototype = {};
MergeCasesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.source_case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.target_case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

MergeCasesRequest.prototype.write = function(output) {
  output.writeStructBegin('MergeCasesRequest');
  if (this.source_case_id !== null && this.source_case_id !== undefined) {
    output.writeFieldBegin('source_case_id', Thrift.Type.STRING, 1);
    output.writeString(this.source_case_id);
    output.writeFieldEnd();
  }
  if (this.target_case_id !== null && this.target_case_id !== undefined) {
    output.writeFieldBegin('target_case_id', Thrift.Type.STRING, 2);
    output.writeString(this.target_case_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var MergeCasesResponse = module.exports.MergeCasesResponse = function(args) {
};
MergeCasesResponse.prototype = {};
MergeCasesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    input.skip(ftype);
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

MergeCasesResponse.prototype.write = function(output) {
  output.writeStructBegin('MergeCasesResponse');
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var PartnerMutations = module.exports.PartnerMutations = function(args) {
  this.member_ids = null;
  this.admin_ids = null;
  this.name = null;
  this.logo_upload_key = null;
  this.shared_patient_page_intro = null;
  this.specialties = null;
  this.create_cases_sitka = null;
  this.create_consults_sitka = null;
  this.enable_patient_communications = null;
  this.enable_screen_sharing = null;
  this.member_roles = null;
  if (args) {
    if (args.member_ids !== undefined && args.member_ids !== null) {
      this.member_ids = new ttypes.StringSetMutation(args.member_ids);
    }
    if (args.admin_ids !== undefined && args.admin_ids !== null) {
      this.admin_ids = new ttypes.StringSetMutation(args.admin_ids);
    }
    if (args.name !== undefined && args.name !== null) {
      this.name = new ttypes.StringMutation(args.name);
    }
    if (args.logo_upload_key !== undefined && args.logo_upload_key !== null) {
      this.logo_upload_key = new ttypes.StringMutation(args.logo_upload_key);
    }
    if (args.shared_patient_page_intro !== undefined && args.shared_patient_page_intro !== null) {
      this.shared_patient_page_intro = new ttypes.StringMutation(args.shared_patient_page_intro);
    }
    if (args.specialties !== undefined && args.specialties !== null) {
      this.specialties = new ttypes.IntSetMutation(args.specialties);
    }
    if (args.create_cases_sitka !== undefined && args.create_cases_sitka !== null) {
      this.create_cases_sitka = new ttypes.BoolMutation(args.create_cases_sitka);
    }
    if (args.create_consults_sitka !== undefined && args.create_consults_sitka !== null) {
      this.create_consults_sitka = new ttypes.BoolMutation(args.create_consults_sitka);
    }
    if (args.enable_patient_communications !== undefined && args.enable_patient_communications !== null) {
      this.enable_patient_communications = new ttypes.BoolMutation(args.enable_patient_communications);
    }
    if (args.enable_screen_sharing !== undefined && args.enable_screen_sharing !== null) {
      this.enable_screen_sharing = new ttypes.BoolMutation(args.enable_screen_sharing);
    }
    if (args.member_roles !== undefined && args.member_roles !== null) {
      this.member_roles = Thrift.copyMap(args.member_roles, [ttypes.PartnerMemberRoles]);
    }
  }
};
PartnerMutations.prototype = {};
PartnerMutations.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.member_ids = new ttypes.StringSetMutation();
        this.member_ids.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.admin_ids = new ttypes.StringSetMutation();
        this.admin_ids.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.name = new ttypes.StringMutation();
        this.name.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.STRUCT) {
        this.logo_upload_key = new ttypes.StringMutation();
        this.logo_upload_key.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.STRUCT) {
        this.shared_patient_page_intro = new ttypes.StringMutation();
        this.shared_patient_page_intro.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRUCT) {
        this.specialties = new ttypes.IntSetMutation();
        this.specialties.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRUCT) {
        this.create_cases_sitka = new ttypes.BoolMutation();
        this.create_cases_sitka.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRUCT) {
        this.create_consults_sitka = new ttypes.BoolMutation();
        this.create_consults_sitka.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 9:
      if (ftype == Thrift.Type.STRUCT) {
        this.enable_patient_communications = new ttypes.BoolMutation();
        this.enable_patient_communications.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 10:
      if (ftype == Thrift.Type.STRUCT) {
        this.enable_screen_sharing = new ttypes.BoolMutation();
        this.enable_screen_sharing.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 11:
      if (ftype == Thrift.Type.MAP) {
        this.member_roles = {};
        var _rtmp3415 = input.readMapBegin();
        var _size414 = _rtmp3415.size || 0;
        for (var _i416 = 0; _i416 < _size414; ++_i416) {
          var key417 = null;
          var val418 = null;
          key417 = input.readString();
          val418 = new ttypes.PartnerMemberRoles();
          val418.read(input);
          this.member_roles[key417] = val418;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

PartnerMutations.prototype.write = function(output) {
  output.writeStructBegin('PartnerMutations');
  if (this.member_ids !== null && this.member_ids !== undefined) {
    output.writeFieldBegin('member_ids', Thrift.Type.STRUCT, 1);
    this.member_ids.write(output);
    output.writeFieldEnd();
  }
  if (this.admin_ids !== null && this.admin_ids !== undefined) {
    output.writeFieldBegin('admin_ids', Thrift.Type.STRUCT, 2);
    this.admin_ids.write(output);
    output.writeFieldEnd();
  }
  if (this.name !== null && this.name !== undefined) {
    output.writeFieldBegin('name', Thrift.Type.STRUCT, 3);
    this.name.write(output);
    output.writeFieldEnd();
  }
  if (this.logo_upload_key !== null && this.logo_upload_key !== undefined) {
    output.writeFieldBegin('logo_upload_key', Thrift.Type.STRUCT, 4);
    this.logo_upload_key.write(output);
    output.writeFieldEnd();
  }
  if (this.shared_patient_page_intro !== null && this.shared_patient_page_intro !== undefined) {
    output.writeFieldBegin('shared_patient_page_intro', Thrift.Type.STRUCT, 5);
    this.shared_patient_page_intro.write(output);
    output.writeFieldEnd();
  }
  if (this.specialties !== null && this.specialties !== undefined) {
    output.writeFieldBegin('specialties', Thrift.Type.STRUCT, 6);
    this.specialties.write(output);
    output.writeFieldEnd();
  }
  if (this.create_cases_sitka !== null && this.create_cases_sitka !== undefined) {
    output.writeFieldBegin('create_cases_sitka', Thrift.Type.STRUCT, 7);
    this.create_cases_sitka.write(output);
    output.writeFieldEnd();
  }
  if (this.create_consults_sitka !== null && this.create_consults_sitka !== undefined) {
    output.writeFieldBegin('create_consults_sitka', Thrift.Type.STRUCT, 8);
    this.create_consults_sitka.write(output);
    output.writeFieldEnd();
  }
  if (this.enable_patient_communications !== null && this.enable_patient_communications !== undefined) {
    output.writeFieldBegin('enable_patient_communications', Thrift.Type.STRUCT, 9);
    this.enable_patient_communications.write(output);
    output.writeFieldEnd();
  }
  if (this.enable_screen_sharing !== null && this.enable_screen_sharing !== undefined) {
    output.writeFieldBegin('enable_screen_sharing', Thrift.Type.STRUCT, 10);
    this.enable_screen_sharing.write(output);
    output.writeFieldEnd();
  }
  if (this.member_roles !== null && this.member_roles !== undefined) {
    output.writeFieldBegin('member_roles', Thrift.Type.MAP, 11);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.STRUCT, Thrift.objectLength(this.member_roles));
    for (var kiter419 in this.member_roles) {
      if (this.member_roles.hasOwnProperty(kiter419)) {
        var viter420 = this.member_roles[kiter419];
        output.writeString(kiter419);
        viter420.write(output);
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdatePartnerRequest = module.exports.UpdatePartnerRequest = function(args) {
  this.partner_id = null;
  this.mutations = null;
  if (args) {
    if (args.partner_id !== undefined && args.partner_id !== null) {
      this.partner_id = args.partner_id;
    }
    if (args.mutations !== undefined && args.mutations !== null) {
      this.mutations = new ttypes.PartnerMutations(args.mutations);
    }
  }
};
UpdatePartnerRequest.prototype = {};
UpdatePartnerRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.partner_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.mutations = new ttypes.PartnerMutations();
        this.mutations.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdatePartnerRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdatePartnerRequest');
  if (this.partner_id !== null && this.partner_id !== undefined) {
    output.writeFieldBegin('partner_id', Thrift.Type.STRING, 1);
    output.writeString(this.partner_id);
    output.writeFieldEnd();
  }
  if (this.mutations !== null && this.mutations !== undefined) {
    output.writeFieldBegin('mutations', Thrift.Type.STRUCT, 3);
    this.mutations.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdatePartnerResponse = module.exports.UpdatePartnerResponse = function(args) {
  this.partner = null;
  if (args) {
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
UpdatePartnerResponse.prototype = {};
UpdatePartnerResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdatePartnerResponse.prototype.write = function(output) {
  output.writeStructBegin('UpdatePartnerResponse');
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 1);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultSummaryDeliveryDestination = module.exports.ConsultSummaryDeliveryDestination = function(args) {
  this.fax_number = null;
  if (args) {
    if (args.fax_number !== undefined && args.fax_number !== null) {
      this.fax_number = args.fax_number;
    }
  }
};
ConsultSummaryDeliveryDestination.prototype = {};
ConsultSummaryDeliveryDestination.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.fax_number = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultSummaryDeliveryDestination.prototype.write = function(output) {
  output.writeStructBegin('ConsultSummaryDeliveryDestination');
  if (this.fax_number !== null && this.fax_number !== undefined) {
    output.writeFieldBegin('fax_number', Thrift.Type.STRING, 1);
    output.writeString(this.fax_number);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultSummaryDeliveryInfo = module.exports.ConsultSummaryDeliveryInfo = function(args) {
  this.destination = null;
  this.documo_message_id = null;
  if (args) {
    if (args.destination !== undefined && args.destination !== null) {
      this.destination = new ttypes.ConsultSummaryDeliveryDestination(args.destination);
    }
    if (args.documo_message_id !== undefined && args.documo_message_id !== null) {
      this.documo_message_id = args.documo_message_id;
    }
  }
};
ConsultSummaryDeliveryInfo.prototype = {};
ConsultSummaryDeliveryInfo.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.destination = new ttypes.ConsultSummaryDeliveryDestination();
        this.destination.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.documo_message_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultSummaryDeliveryInfo.prototype.write = function(output) {
  output.writeStructBegin('ConsultSummaryDeliveryInfo');
  if (this.destination !== null && this.destination !== undefined) {
    output.writeFieldBegin('destination', Thrift.Type.STRUCT, 1);
    this.destination.write(output);
    output.writeFieldEnd();
  }
  if (this.documo_message_id !== null && this.documo_message_id !== undefined) {
    output.writeFieldBegin('documo_message_id', Thrift.Type.STRING, 2);
    output.writeString(this.documo_message_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var ConsultSummary = module.exports.ConsultSummary = function(args) {
  this.consult_id = null;
  this.delivery_info = null;
  this.status = null;
  this.updated_at = null;
  this.consult_request_date = null;
  this.partner_name = null;
  this.pcp_city = null;
  this.failure_reason = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.delivery_info !== undefined && args.delivery_info !== null) {
      this.delivery_info = new ttypes.ConsultSummaryDeliveryInfo(args.delivery_info);
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.updated_at !== undefined && args.updated_at !== null) {
      this.updated_at = args.updated_at;
    }
    if (args.consult_request_date !== undefined && args.consult_request_date !== null) {
      this.consult_request_date = args.consult_request_date;
    }
    if (args.partner_name !== undefined && args.partner_name !== null) {
      this.partner_name = args.partner_name;
    }
    if (args.pcp_city !== undefined && args.pcp_city !== null) {
      this.pcp_city = args.pcp_city;
    }
    if (args.failure_reason !== undefined && args.failure_reason !== null) {
      this.failure_reason = args.failure_reason;
    }
  }
};
ConsultSummary.prototype = {};
ConsultSummary.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.delivery_info = new ttypes.ConsultSummaryDeliveryInfo();
        this.delivery_info.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.I64) {
        this.updated_at = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 5:
      if (ftype == Thrift.Type.I64) {
        this.consult_request_date = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 6:
      if (ftype == Thrift.Type.STRING) {
        this.partner_name = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 7:
      if (ftype == Thrift.Type.STRING) {
        this.pcp_city = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 8:
      if (ftype == Thrift.Type.STRING) {
        this.failure_reason = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

ConsultSummary.prototype.write = function(output) {
  output.writeStructBegin('ConsultSummary');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.delivery_info !== null && this.delivery_info !== undefined) {
    output.writeFieldBegin('delivery_info', Thrift.Type.STRUCT, 2);
    this.delivery_info.write(output);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 3);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  if (this.updated_at !== null && this.updated_at !== undefined) {
    output.writeFieldBegin('updated_at', Thrift.Type.I64, 4);
    output.writeI64(this.updated_at);
    output.writeFieldEnd();
  }
  if (this.consult_request_date !== null && this.consult_request_date !== undefined) {
    output.writeFieldBegin('consult_request_date', Thrift.Type.I64, 5);
    output.writeI64(this.consult_request_date);
    output.writeFieldEnd();
  }
  if (this.partner_name !== null && this.partner_name !== undefined) {
    output.writeFieldBegin('partner_name', Thrift.Type.STRING, 6);
    output.writeString(this.partner_name);
    output.writeFieldEnd();
  }
  if (this.pcp_city !== null && this.pcp_city !== undefined) {
    output.writeFieldBegin('pcp_city', Thrift.Type.STRING, 7);
    output.writeString(this.pcp_city);
    output.writeFieldEnd();
  }
  if (this.failure_reason !== null && this.failure_reason !== undefined) {
    output.writeFieldBegin('failure_reason', Thrift.Type.STRING, 8);
    output.writeString(this.failure_reason);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultSummariesRequest = module.exports.GetConsultSummariesRequest = function(args) {
  this.page_params = null;
  if (args) {
    if (args.page_params !== undefined && args.page_params !== null) {
      this.page_params = new ttypes.PaginationParameters(args.page_params);
    }
  }
};
GetConsultSummariesRequest.prototype = {};
GetConsultSummariesRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_params = new ttypes.PaginationParameters();
        this.page_params.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultSummariesRequest.prototype.write = function(output) {
  output.writeStructBegin('GetConsultSummariesRequest');
  if (this.page_params !== null && this.page_params !== undefined) {
    output.writeFieldBegin('page_params', Thrift.Type.STRUCT, 1);
    this.page_params.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var GetConsultSummariesResponse = module.exports.GetConsultSummariesResponse = function(args) {
  this.consult_summaries = null;
  this.page_markers = null;
  if (args) {
    if (args.consult_summaries !== undefined && args.consult_summaries !== null) {
      this.consult_summaries = Thrift.copyList(args.consult_summaries, [ttypes.ConsultSummary]);
    }
    if (args.page_markers !== undefined && args.page_markers !== null) {
      this.page_markers = new ttypes.PaginationMarkers(args.page_markers);
    }
  }
};
GetConsultSummariesResponse.prototype = {};
GetConsultSummariesResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.consult_summaries = [];
        var _rtmp3422 = input.readListBegin();
        var _size421 = _rtmp3422.size || 0;
        for (var _i423 = 0; _i423 < _size421; ++_i423) {
          var elem424 = null;
          elem424 = new ttypes.ConsultSummary();
          elem424.read(input);
          this.consult_summaries.push(elem424);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.page_markers = new ttypes.PaginationMarkers();
        this.page_markers.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

GetConsultSummariesResponse.prototype.write = function(output) {
  output.writeStructBegin('GetConsultSummariesResponse');
  if (this.consult_summaries !== null && this.consult_summaries !== undefined) {
    output.writeFieldBegin('consult_summaries', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.consult_summaries.length);
    for (var iter425 in this.consult_summaries) {
      if (this.consult_summaries.hasOwnProperty(iter425)) {
        iter425 = this.consult_summaries[iter425];
        iter425.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.page_markers !== null && this.page_markers !== undefined) {
    output.writeFieldBegin('page_markers', Thrift.Type.STRUCT, 2);
    this.page_markers.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateConsultSummaryRequest = module.exports.UpdateConsultSummaryRequest = function(args) {
  this.consult_id = null;
  this.status = null;
  this.destination = null;
  this.send_summary = null;
  if (args) {
    if (args.consult_id !== undefined && args.consult_id !== null) {
      this.consult_id = args.consult_id;
    }
    if (args.status !== undefined && args.status !== null) {
      this.status = args.status;
    }
    if (args.destination !== undefined && args.destination !== null) {
      this.destination = new ttypes.ConsultSummaryDeliveryDestination(args.destination);
    }
    if (args.send_summary !== undefined && args.send_summary !== null) {
      this.send_summary = args.send_summary;
    }
  }
};
UpdateConsultSummaryRequest.prototype = {};
UpdateConsultSummaryRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.consult_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.status = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.destination = new ttypes.ConsultSummaryDeliveryDestination();
        this.destination.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 4:
      if (ftype == Thrift.Type.BOOL) {
        this.send_summary = input.readBool();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateConsultSummaryRequest.prototype.write = function(output) {
  output.writeStructBegin('UpdateConsultSummaryRequest');
  if (this.consult_id !== null && this.consult_id !== undefined) {
    output.writeFieldBegin('consult_id', Thrift.Type.STRING, 1);
    output.writeString(this.consult_id);
    output.writeFieldEnd();
  }
  if (this.status !== null && this.status !== undefined) {
    output.writeFieldBegin('status', Thrift.Type.I32, 2);
    output.writeI32(this.status);
    output.writeFieldEnd();
  }
  if (this.destination !== null && this.destination !== undefined) {
    output.writeFieldBegin('destination', Thrift.Type.STRUCT, 3);
    this.destination.write(output);
    output.writeFieldEnd();
  }
  if (this.send_summary !== null && this.send_summary !== undefined) {
    output.writeFieldBegin('send_summary', Thrift.Type.BOOL, 4);
    output.writeBool(this.send_summary);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var UpdateConsultSummaryResponse = module.exports.UpdateConsultSummaryResponse = function(args) {
  this.consult_summary = null;
  if (args) {
    if (args.consult_summary !== undefined && args.consult_summary !== null) {
      this.consult_summary = new ttypes.ConsultSummary(args.consult_summary);
    }
  }
};
UpdateConsultSummaryResponse.prototype = {};
UpdateConsultSummaryResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.consult_summary = new ttypes.ConsultSummary();
        this.consult_summary.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

UpdateConsultSummaryResponse.prototype.write = function(output) {
  output.writeStructBegin('UpdateConsultSummaryResponse');
  if (this.consult_summary !== null && this.consult_summary !== undefined) {
    output.writeFieldBegin('consult_summary', Thrift.Type.STRUCT, 1);
    this.consult_summary.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaConsultFields = module.exports.AthenaConsultFields = function(args) {
  this.department_id = null;
  if (args) {
    if (args.department_id !== undefined && args.department_id !== null) {
      this.department_id = args.department_id;
    }
  }
};
AthenaConsultFields.prototype = {};
AthenaConsultFields.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.department_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaConsultFields.prototype.write = function(output) {
  output.writeStructBegin('AthenaConsultFields');
  if (this.department_id !== null && this.department_id !== undefined) {
    output.writeFieldBegin('department_id', Thrift.Type.STRING, 1);
    output.writeString(this.department_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaConsultDataFields = module.exports.AthenaConsultDataFields = function(args) {
  this.document_id = null;
  this.document_type = null;
  if (args) {
    if (args.document_id !== undefined && args.document_id !== null) {
      this.document_id = args.document_id;
    }
    if (args.document_type !== undefined && args.document_type !== null) {
      this.document_type = args.document_type;
    }
  }
};
AthenaConsultDataFields.prototype = {};
AthenaConsultDataFields.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.document_id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.document_type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaConsultDataFields.prototype.write = function(output) {
  output.writeStructBegin('AthenaConsultDataFields');
  if (this.document_id !== null && this.document_id !== undefined) {
    output.writeFieldBegin('document_id', Thrift.Type.I64, 1);
    output.writeI64(this.document_id);
    output.writeFieldEnd();
  }
  if (this.document_type !== null && this.document_type !== undefined) {
    output.writeFieldBegin('document_type', Thrift.Type.I32, 2);
    output.writeI32(this.document_type);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaDocument = module.exports.AthenaDocument = function(args) {
  this.document_id = null;
  this.type = null;
  this.description = null;
  if (args) {
    if (args.document_id !== undefined && args.document_id !== null) {
      this.document_id = args.document_id;
    }
    if (args.type !== undefined && args.type !== null) {
      this.type = args.type;
    }
    if (args.description !== undefined && args.description !== null) {
      this.description = args.description;
    }
  }
};
AthenaDocument.prototype = {};
AthenaDocument.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.I64) {
        this.document_id = input.readI64();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.I32) {
        this.type = input.readI32();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRING) {
        this.description = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaDocument.prototype.write = function(output) {
  output.writeStructBegin('AthenaDocument');
  if (this.document_id !== null && this.document_id !== undefined) {
    output.writeFieldBegin('document_id', Thrift.Type.I64, 1);
    output.writeI64(this.document_id);
    output.writeFieldEnd();
  }
  if (this.type !== null && this.type !== undefined) {
    output.writeFieldBegin('type', Thrift.Type.I32, 2);
    output.writeI32(this.type);
    output.writeFieldEnd();
  }
  if (this.description !== null && this.description !== undefined) {
    output.writeFieldBegin('description', Thrift.Type.STRING, 3);
    output.writeString(this.description);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaGetOrCreateCaseRequest = module.exports.AthenaGetOrCreateCaseRequest = function(args) {
  this.athena_patient_id = null;
  this.athena_practice_id = null;
  if (args) {
    if (args.athena_patient_id !== undefined && args.athena_patient_id !== null) {
      this.athena_patient_id = args.athena_patient_id;
    }
    if (args.athena_practice_id !== undefined && args.athena_practice_id !== null) {
      this.athena_practice_id = args.athena_practice_id;
    }
  }
};
AthenaGetOrCreateCaseRequest.prototype = {};
AthenaGetOrCreateCaseRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.athena_patient_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.athena_practice_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaGetOrCreateCaseRequest.prototype.write = function(output) {
  output.writeStructBegin('AthenaGetOrCreateCaseRequest');
  if (this.athena_patient_id !== null && this.athena_patient_id !== undefined) {
    output.writeFieldBegin('athena_patient_id', Thrift.Type.STRING, 1);
    output.writeString(this.athena_patient_id);
    output.writeFieldEnd();
  }
  if (this.athena_practice_id !== null && this.athena_practice_id !== undefined) {
    output.writeFieldBegin('athena_practice_id', Thrift.Type.STRING, 2);
    output.writeString(this.athena_practice_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaGetOrCreateCaseResponse = module.exports.AthenaGetOrCreateCaseResponse = function(args) {
  this.the_case = null;
  this.users = null;
  this.partner = null;
  if (args) {
    if (args.the_case !== undefined && args.the_case !== null) {
      this.the_case = new ttypes.Case(args.the_case);
    }
    if (args.users !== undefined && args.users !== null) {
      this.users = Thrift.copyList(args.users, [ttypes.User]);
    }
    if (args.partner !== undefined && args.partner !== null) {
      this.partner = new ttypes.Partner(args.partner);
    }
  }
};
AthenaGetOrCreateCaseResponse.prototype = {};
AthenaGetOrCreateCaseResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.the_case = new ttypes.Case();
        this.the_case.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.LIST) {
        this.users = [];
        var _rtmp3427 = input.readListBegin();
        var _size426 = _rtmp3427.size || 0;
        for (var _i428 = 0; _i428 < _size426; ++_i428) {
          var elem429 = null;
          elem429 = new ttypes.User();
          elem429.read(input);
          this.users.push(elem429);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.partner = new ttypes.Partner();
        this.partner.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaGetOrCreateCaseResponse.prototype.write = function(output) {
  output.writeStructBegin('AthenaGetOrCreateCaseResponse');
  if (this.the_case !== null && this.the_case !== undefined) {
    output.writeFieldBegin('the_case', Thrift.Type.STRUCT, 1);
    this.the_case.write(output);
    output.writeFieldEnd();
  }
  if (this.users !== null && this.users !== undefined) {
    output.writeFieldBegin('users', Thrift.Type.LIST, 2);
    output.writeListBegin(Thrift.Type.STRUCT, this.users.length);
    for (var iter430 in this.users) {
      if (this.users.hasOwnProperty(iter430)) {
        iter430 = this.users[iter430];
        iter430.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  if (this.partner !== null && this.partner !== undefined) {
    output.writeFieldBegin('partner', Thrift.Type.STRUCT, 3);
    this.partner.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaGetPatientDocumentListRequest = module.exports.AthenaGetPatientDocumentListRequest = function(args) {
  this.case_id = null;
  this.athena_department_id = null;
  if (args) {
    if (args.case_id !== undefined && args.case_id !== null) {
      this.case_id = args.case_id;
    }
    if (args.athena_department_id !== undefined && args.athena_department_id !== null) {
      this.athena_department_id = args.athena_department_id;
    }
  }
};
AthenaGetPatientDocumentListRequest.prototype = {};
AthenaGetPatientDocumentListRequest.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.case_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRING) {
        this.athena_department_id = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaGetPatientDocumentListRequest.prototype.write = function(output) {
  output.writeStructBegin('AthenaGetPatientDocumentListRequest');
  if (this.case_id !== null && this.case_id !== undefined) {
    output.writeFieldBegin('case_id', Thrift.Type.STRING, 1);
    output.writeString(this.case_id);
    output.writeFieldEnd();
  }
  if (this.athena_department_id !== null && this.athena_department_id !== undefined) {
    output.writeFieldBegin('athena_department_id', Thrift.Type.STRING, 2);
    output.writeString(this.athena_department_id);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AthenaGetPatientDocumentListResponse = module.exports.AthenaGetPatientDocumentListResponse = function(args) {
  this.documents = null;
  if (args) {
    if (args.documents !== undefined && args.documents !== null) {
      this.documents = Thrift.copyList(args.documents, [ttypes.AthenaDocument]);
    }
  }
};
AthenaGetPatientDocumentListResponse.prototype = {};
AthenaGetPatientDocumentListResponse.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.documents = [];
        var _rtmp3432 = input.readListBegin();
        var _size431 = _rtmp3432.size || 0;
        for (var _i433 = 0; _i433 < _size431; ++_i433) {
          var elem434 = null;
          elem434 = new ttypes.AthenaDocument();
          elem434.read(input);
          this.documents.push(elem434);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AthenaGetPatientDocumentListResponse.prototype.write = function(output) {
  output.writeStructBegin('AthenaGetPatientDocumentListResponse');
  if (this.documents !== null && this.documents !== undefined) {
    output.writeFieldBegin('documents', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.documents.length);
    for (var iter435 in this.documents) {
      if (this.documents.hasOwnProperty(iter435)) {
        iter435 = this.documents[iter435];
        iter435.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TestObject = module.exports.TestObject = function(args) {
  this.test_base = null;
  if (args) {
    if (args.test_base !== undefined && args.test_base !== null) {
      this.test_base = args.test_base;
    }
  }
};
TestObject.prototype = {};
TestObject.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.test_base = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TestObject.prototype.write = function(output) {
  output.writeStructBegin('TestObject');
  if (this.test_base !== null && this.test_base !== undefined) {
    output.writeFieldBegin('test_base', Thrift.Type.STRING, 1);
    output.writeString(this.test_base);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TestOtherObject = module.exports.TestOtherObject = function(args) {
  this.test_base = null;
  if (args) {
    if (args.test_base !== undefined && args.test_base !== null) {
      this.test_base = args.test_base;
    }
  }
};
TestOtherObject.prototype = {};
TestOtherObject.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRING) {
        this.test_base = input.readString();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TestOtherObject.prototype.write = function(output) {
  output.writeStructBegin('TestOtherObject');
  if (this.test_base !== null && this.test_base !== undefined) {
    output.writeFieldBegin('test_base', Thrift.Type.STRING, 1);
    output.writeString(this.test_base);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TestNestedThrift = module.exports.TestNestedThrift = function(args) {
  this.test_nested = null;
  if (args) {
    if (args.test_nested !== undefined && args.test_nested !== null) {
      this.test_nested = new ttypes.TestObject(args.test_nested);
    }
  }
};
TestNestedThrift.prototype = {};
TestNestedThrift.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.test_nested = new ttypes.TestObject();
        this.test_nested.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TestNestedThrift.prototype.write = function(output) {
  output.writeStructBegin('TestNestedThrift');
  if (this.test_nested !== null && this.test_nested !== undefined) {
    output.writeFieldBegin('test_nested', Thrift.Type.STRUCT, 1);
    this.test_nested.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TestListNestedThrift = module.exports.TestListNestedThrift = function(args) {
  this.test_nested_list = null;
  if (args) {
    if (args.test_nested_list !== undefined && args.test_nested_list !== null) {
      this.test_nested_list = Thrift.copyList(args.test_nested_list, [ttypes.TestObject]);
    }
  }
};
TestListNestedThrift.prototype = {};
TestListNestedThrift.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.LIST) {
        this.test_nested_list = [];
        var _rtmp3437 = input.readListBegin();
        var _size436 = _rtmp3437.size || 0;
        for (var _i438 = 0; _i438 < _size436; ++_i438) {
          var elem439 = null;
          elem439 = new ttypes.TestObject();
          elem439.read(input);
          this.test_nested_list.push(elem439);
        }
        input.readListEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TestListNestedThrift.prototype.write = function(output) {
  output.writeStructBegin('TestListNestedThrift');
  if (this.test_nested_list !== null && this.test_nested_list !== undefined) {
    output.writeFieldBegin('test_nested_list', Thrift.Type.LIST, 1);
    output.writeListBegin(Thrift.Type.STRUCT, this.test_nested_list.length);
    for (var iter440 in this.test_nested_list) {
      if (this.test_nested_list.hasOwnProperty(iter440)) {
        iter440 = this.test_nested_list[iter440];
        iter440.write(output);
      }
    }
    output.writeListEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var TestMapNestedThrift = module.exports.TestMapNestedThrift = function(args) {
  this.test_nested_map = null;
  if (args) {
    if (args.test_nested_map !== undefined && args.test_nested_map !== null) {
      this.test_nested_map = Thrift.copyMap(args.test_nested_map, [ttypes.TestObject]);
    }
  }
};
TestMapNestedThrift.prototype = {};
TestMapNestedThrift.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.MAP) {
        this.test_nested_map = {};
        var _rtmp3442 = input.readMapBegin();
        var _size441 = _rtmp3442.size || 0;
        for (var _i443 = 0; _i443 < _size441; ++_i443) {
          var key444 = null;
          var val445 = null;
          key444 = input.readString();
          val445 = new ttypes.TestObject();
          val445.read(input);
          this.test_nested_map[key444] = val445;
        }
        input.readMapEnd();
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

TestMapNestedThrift.prototype.write = function(output) {
  output.writeStructBegin('TestMapNestedThrift');
  if (this.test_nested_map !== null && this.test_nested_map !== undefined) {
    output.writeFieldBegin('test_nested_map', Thrift.Type.MAP, 1);
    output.writeMapBegin(Thrift.Type.STRING, Thrift.Type.STRUCT, Thrift.objectLength(this.test_nested_map));
    for (var kiter446 in this.test_nested_map) {
      if (this.test_nested_map.hasOwnProperty(kiter446)) {
        var viter447 = this.test_nested_map[kiter446];
        output.writeString(kiter446);
        viter447.write(output);
      }
    }
    output.writeMapEnd();
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var MutationTestStruct = module.exports.MutationTestStruct = function(args) {
  this.some_string = null;
  this.some_string_set = null;
  this.override_field = null;
  if (args) {
    if (args.some_string !== undefined && args.some_string !== null) {
      this.some_string = new ttypes.StringMutation(args.some_string);
    }
    if (args.some_string_set !== undefined && args.some_string_set !== null) {
      this.some_string_set = new ttypes.StringSetMutation(args.some_string_set);
    }
    if (args.override_field !== undefined && args.override_field !== null) {
      this.override_field = new ttypes.StringMutation(args.override_field);
    }
  }
};
MutationTestStruct.prototype = {};
MutationTestStruct.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.some_string = new ttypes.StringMutation();
        this.some_string.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.some_string_set = new ttypes.StringSetMutation();
        this.some_string_set.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 3:
      if (ftype == Thrift.Type.STRUCT) {
        this.override_field = new ttypes.StringMutation();
        this.override_field.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

MutationTestStruct.prototype.write = function(output) {
  output.writeStructBegin('MutationTestStruct');
  if (this.some_string !== null && this.some_string !== undefined) {
    output.writeFieldBegin('some_string', Thrift.Type.STRUCT, 1);
    this.some_string.write(output);
    output.writeFieldEnd();
  }
  if (this.some_string_set !== null && this.some_string_set !== undefined) {
    output.writeFieldBegin('some_string_set', Thrift.Type.STRUCT, 2);
    this.some_string_set.write(output);
    output.writeFieldEnd();
  }
  if (this.override_field !== null && this.override_field !== undefined) {
    output.writeFieldBegin('override_field', Thrift.Type.STRUCT, 3);
    this.override_field.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

ttypes.SPECIALTY = {
  1 : {
    1 : 'Orthopedics',
    2 : 'Endocrinology',
    3 : 'Rheumatology',
    4 : 'Cardiology',
    5 : 'Allergy & Immunology',
    6 : 'Pharmacy',
    7 : 'Dentistry',
    8 : 'Dermatology',
    9 : 'Emergency Medicine',
    10 : 'Functional Medicine',
    11 : 'Gastroenterology',
    12 : 'Geriatric Medicine',
    13 : 'Hematology / Oncology',
    14 : 'Infectious Diseases',
    15 : 'Integrative / Holistic Medicine',
    16 : 'Nephrology',
    17 : 'Neurology',
    18 : 'Neurosurgery',
    19 : 'Nutrition',
    20 : 'Obesity Medicine',
    21 : 'Obstetrics / Gynecology',
    22 : 'Occupational Medicine',
    23 : 'Ophthalmology',
    24 : 'Otolaryngology - Ear, Nose and Throat',
    25 : 'Pain Medicine',
    26 : 'Palliative Care',
    27 : 'Pathology',
    28 : 'Plastic Surgery',
    29 : 'Physical Medicine and Rehabilitation / Sports Medicine',
    30 : 'Psychiatry',
    31 : 'Psychology',
    32 : 'Pulmonology',
    33 : 'Sleep Medicine',
    34 : 'Surgery',
    35 : 'Transgender and Gender Non-Conforming Health',
    36 : 'Urology',
    37 : 'Women\'s Health',
    38 : 'Other',
    39 : 'Diabetes Education',
    40 : 'Wound/Vascular',
    41 : 'Podiatry',
    42 : 'Fast Track - Psychiatry',
    43 : 'Pediatrics',
    44 : 'Foot and Ankle Care'
  }
};
ttypes.SPECIALTIES_INACTIVE = [6,7,9,10,15,22,27,35,37,42];
ttypes.SEX = {
  1 : {
    1 : 'Male',
    2 : 'Female'
  }
};
ttypes.CONSULT_OPEN_STATES = [2,11,8,10,1,13,14];
ttypes.CONSULT_CLOSED_STATES = [5,3,4,6,9];
ttypes.CONSULT_STATE = {
  1 : {
    1 : 'Submitted',
    2 : 'Active',
    3 : 'Closed - inappropriate referral',
    4 : 'Closed - incomplete referral',
    5 : 'Closed - duplicate referral',
    6 : 'Closed',
    8 : 'Pending Requestor Action',
    9 : 'Closed - PCP initiated',
    10 : 'Responded',
    11 : 'Draft',
    12 : 'Draft - Deleted',
    13 : 'Awaiting Specialist Response',
    14 : 'Under Review'
  }
};
ttypes.CONSULT_STATE_SIMPLIFIED = {
  1 : {
    1 : 'Draft',
    2 : 'Not Responded',
    3 : 'Responded',
    4 : 'Pending Requestor Action',
    5 : 'Closed'
  }
};
ttypes.SUPPORTED_FILE_EXTENSIONS = ['PDF','JPG','JPEG','PNG','TIFF','TIF','MP4','MOV','DOCX','XLSX','PPTX','MPEG4'];
ttypes.CUSTOM_FILTERS = {
  9 : {
    3 : 'actor_or_contributor',
    17 : 'patient_external_id_case_insensitive',
    16 : 'patient_last_name_case_insensitive',
    4 : 'patient_name',
    15 : 'patient_profile'
  },
  4 : {
    3 : 'actor_or_contributor',
    19 : 'consults_by_clinical_review',
    17 : 'patient_external_id_case_insensitive',
    16 : 'patient_last_name_case_insensitive',
    4 : 'patient_name',
    18 : 'previous_consults_at_case',
    15 : 'patient_profile'
  },
  14 : {
    23 : 'consult_requestor_city'
  },
  1 : {
    14 : 'admin_at_partner',
    12 : 'assignable_as_partner_admin',
    11 : 'assignable_as_partner_member',
    13 : 'member_at_partner',
    8 : 'not_admin_at_partner',
    9 : 'not_member_at_partner',
    10 : 'not_patient',
    7 : 'requestor_for_consult',
    21 : 'sma_daily_consults_limit_available',
    20 : 'sma_licensed_for_consult',
    22 : 'sma_schedule_available_for_consult',
    6 : 'staff_at_partner',
    2 : 'user_name',
    15 : 'user_profile'
  }
};
ttypes.REDACTED_REASON = {
  1 : {
    1 : 'Unknown',
    2 : 'Clinical accuracy',
    3 : 'HIPAA related issue',
    4 : 'Other error'
  }
};
ttypes.FAST_TRACK_SPECIALTIES = [42];
ttypes.STATE = {
  1 : {
    2 : 'Alabama',
    3 : 'Alaska',
    4 : 'Arizona',
    5 : 'Arkansas',
    6 : 'California',
    7 : 'Colorado',
    8 : 'Connecticut',
    9 : 'Delaware',
    10 : 'District of Columbia',
    11 : 'Florida',
    12 : 'Georgia',
    13 : 'Hawaii',
    14 : 'Idaho',
    15 : 'Illinois',
    16 : 'Indiana',
    17 : 'Iowa',
    18 : 'Kansas',
    19 : 'Kentucky',
    20 : 'Louisiana',
    21 : 'Maine',
    22 : 'Maryland',
    23 : 'Massachusetts',
    24 : 'Michigan',
    25 : 'Minnesota',
    26 : 'Mississippi',
    27 : 'Missouri',
    28 : 'Montana',
    29 : 'Nebraska',
    30 : 'Nevada',
    31 : 'New Hampshire',
    32 : 'New Jersey',
    33 : 'New Mexico',
    34 : 'New York',
    35 : 'North Carolina',
    36 : 'North Dakota',
    37 : 'Ohio',
    38 : 'Oklahoma',
    39 : 'Oregon',
    40 : 'Pennsylvania',
    41 : 'Rhode Island',
    42 : 'South Carolina',
    43 : 'South Dakota',
    44 : 'Tennessee',
    45 : 'Texas',
    46 : 'Utah',
    47 : 'Vermont',
    48 : 'Virginia',
    49 : 'Washington',
    50 : 'West Virginia',
    51 : 'Wisconsin',
    52 : 'Wyoming'
  }
};
ttypes.MULTIPLE_REDEMPTION_TOKENS = [8,9];
ttypes.RESENDABLE_TOKENS = [8];
ttypes.CONSULT_MISSING_INFO_REASON = {
  1 : {
    7 : 'Documents failed to load/process',
    8 : 'Inappropriate request',
    5 : 'Not enough information',
    10 : 'Missing documents',
    9 : 'No clear clinical question',
    6 : 'No photos/images',
    2 : 'No video or text request',
    4 : 'Poor audio quality',
    11 : 'Poor Photo Quality/Unable to Decipher',
    1 : 'Unknown requestor/user',
    3 : 'Video failed to load/process'
  }
};
ttypes.USER_CREDENTIAL = {
  1 : {
    12 : 'DC',
    6 : 'DNP',
    5 : 'DO',
    11 : 'LCSW',
    1 : 'MD',
    9 : 'Non-clinical',
    2 : 'NP',
    10 : 'OT',
    4 : 'Other Clinical',
    13 : 'PA',
    3 : 'PhD',
    8 : 'PT',
    7 : 'RN'
  }
};
ttypes.USER_TITLE = {
  1 : {
    2 : 'Dr.',
    3 : 'Mr.',
    4 : 'Mrs.',
    5 : 'Ms.',
    1 : '<None>'
  }
};
ttypes.SPECIALIST_RESPONSE_TYPE = {
  1 : {
    1 : 'Diagnostic + Management',
    2 : 'Management'
  }
};
ttypes.SPECIALIST_RESPONSE_INTERVENTION = {
  1 : {
    5 : 'Additional Assessment',
    8 : 'Emergency Care',
    3 : 'Imaging',
    2 : 'Labs',
    1 : 'Medication Management (stop, start, alter, agree)',
    12 : 'N/A',
    11 : 'No treatment necessary',
    10 : 'Other (specify in response to PCP)',
    6 : 'Patient Education',
    4 : 'PCP In-Office Procedure',
    7 : 'Refer Out to In-Person Specialist',
    9 : 'Therapy (PT, OT, Counseling)'
  }
};
ttypes.SPECIALIST_RESPONSE_RECOMMENDATION = {
  1 : {
    1 : 'Manage by PCP',
    3 : 'Manage by PCP and Refer Out',
    4 : 'PCP attempt to manage prior to referring',
    2 : 'Refer Out'
  }
};
ttypes.CONSULT_SUMMARY_STATUS = {
  1 : {
    1 : 'Unsent',
    2 : 'Delivered',
    3 : 'Failed',
    4 : 'In-Transit',
    5 : 'Manual'
  }
};
