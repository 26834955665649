import * as React from 'react';
import { EventType } from 'thriftgen/api_types';

interface RecordingProps {
	minimized: boolean;
	onRecordingData: (video: Blob) => void;
	onRecordingEnd: (video: Blob | null, audioError?: Error) => void;
	onRecorderMinimize?: React.MouseEventHandler;
	onRecordingStart: (recordingOption: RecordingOption) => void;
	canRecordScreen: boolean;
}

interface RecordingMouseEvents {
	onMinimize?: React.MouseEventHandler;
	onPause: React.MouseEventHandler;
	onResume: React.MouseEventHandler;
	onOpenSettings: React.MouseEventHandler;
	onStart: React.MouseEventHandler;
	onStop: React.MouseEventHandler;
}

interface EmitEventArgs {
	eventType: EventType;
	data?: {
		[key: string]: any;
	};
}

interface RecorderStateMachineProps {
	emitEvent: (event: EmitEventArgs) => void;
	onRecordingComplete: (video: Blob, recordingId: string) => void;
	onRecordingReject: () => void;
	onRecordingStart: () => void;
	onRecorderMinimize?: React.MouseEventHandler;
	minimized: boolean;
	canRecordScreen: boolean;
	recordingPreviewAction?: JSX.Element;
	video: Blob;
	recordingId: string;
	hideVideoPreview?: boolean;
}

enum RecordingStatus {
	IDLE,
	PAUSED,
	RECORDING
}

enum RecordingOption {
	CAMERA_ONLY = 'camera_only',
	SCREENSHARE = 'screenshare'
}

export {
	RecorderStateMachineProps,
	RecordingMouseEvents,
	RecordingProps,
	RecordingOption,
	RecordingStatus
};
