import { cx } from 'emotion';
import * as React from 'react';
import {
	Icon as SemanticIcon,
	Button as SemanticButton,
	StrictButtonProps as SemanticStrictButtonProps,
	StrictIconProps as SemanticStrictIconProps
} from 'semantic-ui-react';
import { SEMANTIC_COLOR_STRINGS } from 'styles';
import AsyncButton, { AsyncButtonProps } from './AsyncButton';
import { ButtonGroup, ButtonInlineGroup } from './ButtonGroup';
import buttonstyles from './buttonStyles';

const TEXT_BUTTON = 'text-button';

export enum ButtonType {
	DESTRUCTIVE = 'Destructive',
	MINIMAL = 'Minimal',
	OUTLINE = 'Outline',
	PRIMARY = 'Primary',
	SECONDARY = 'Secondary',
	TEXT_PRIMARY = 'TextPrimary'
}

enum ButtonSize {
	MINI = 'mini',
	TINY = 'tiny',
	SMALL = 'small',
	MEDIUM = 'medium',
	LARGE = 'large',
	BIG = 'big',
	HUGE = 'huge',
	MASSIVE = 'massive'
}

enum IconSize {
	MINI = 'mini',
	TINY = 'tiny',
	SMALL = 'small',
	LARGE = 'large',
	BIG = 'big',
	HUGE = 'huge',
	MASSIVE = 'massive'
}

interface IconButtonProps {
	backgroundColor?: SemanticStrictButtonProps['color'] | 'white';
	color?: SemanticStrictIconProps['color'] | 'white';
	disabled?: SemanticStrictButtonProps['disabled'];
	icon: SemanticStrictIconProps['name'];
	inverted?: SemanticStrictButtonProps['inverted'];
	iconInverted?: SemanticStrictIconProps['inverted'];
	onClick?: SemanticStrictButtonProps['onClick'];
	onClickAsync?: AsyncButtonProps['onClickAsync'];
	size?: SemanticStrictIconProps['size'];
	className?: SemanticStrictButtonProps['className'];
	title?: string;
}

export interface ButtonProps extends SemanticStrictButtonProps {
	onClickAsync?: AsyncButtonProps['onClickAsync'];
	responsive?: boolean;
	title?: string;
}

function ShimmedButton({
	className,
	responsive = true,
	onClickAsync: _onClickAsyncIgnored,
	...props
}: ButtonProps): JSX.Element {
	const classNames = [buttonstyles.base, className];

	if (responsive) {
		classNames.push(buttonstyles.responsive);
	}

	return <SemanticButton className={cx(...classNames)} {...props} />;
}

function makeButton({
	className: shimClassName,
	...shimProps
}: ButtonProps): React.ComponentType<ButtonProps> {
	return function Button({
		className: consumerClassName,
		...consumerProps
	}: ButtonProps): JSX.Element {
		const props = { ...consumerProps, ...shimProps };
		const className = cx(shimClassName, consumerClassName);

		if (typeof props.onClick === 'function' && typeof props.onClickAsync === 'function') {
			throw new Error('Button cannot have both onClick and onClickAsync handlers');
		}

		if (props.onClickAsync) {
			return (
				<AsyncButton
					as={ShimmedButton}
					className={className}
					onClickAsync={props.onClickAsync}
					{...props}
				/>
			);
		}

		return <ShimmedButton className={className} {...props} />;
	};
}

function IconButton({
	backgroundColor,
	color,
	icon,
	iconInverted,
	size,
	className,
	...props
}: IconButtonProps): JSX.Element {
	const classNames = ['icon'];
	if (className) {
		classNames.push(className);
	}

	if (backgroundColor) {
		classNames.push(buttonstyles.iconBackgroundColor);
	}

	const baseClassName = cx(...classNames);
	const Base = makeButton({
		className: baseClassName,
		color: backgroundColor as SemanticStrictButtonProps['color'],
		responsive: false
	});

	return (
		<Base {...props}>
			<SemanticIcon
				name={icon}
				color={color as SemanticStrictIconProps['color']}
				size={size}
				inverted={iconInverted}
			/>
		</Base>
	);
}

// Enums
ShimmedButton.Size = ButtonSize;
ShimmedButton.IconSize = IconSize;

// Styles
ShimmedButton.responsiveStyling = buttonstyles.responsive;
ShimmedButton.variations = buttonstyles.variations;

// Regular button variations
ShimmedButton.Primary = makeButton({ className: buttonstyles.variations.primary });
ShimmedButton.Secondary = makeButton({ className: buttonstyles.variations.secondary });
ShimmedButton.Minimal = makeButton({ className: buttonstyles.variations.minimal });
ShimmedButton.Outline = makeButton({ className: buttonstyles.variations.outline });
ShimmedButton.Destructive = makeButton({ className: buttonstyles.variations.destructive });

// Icon Button
ShimmedButton.Icon = IconButton;

// Text-only buttons
ShimmedButton.Text = makeButton({
	className: TEXT_BUTTON,
	responsive: false
});

ShimmedButton.TextPrimary = makeButton({
	color: SEMANTIC_COLOR_STRINGS.PRIMARY,
	className: TEXT_BUTTON,
	responsive: false
});

// Button containers
ShimmedButton.Group = ButtonGroup;
ShimmedButton.InlineGroup = ButtonInlineGroup;

export default ShimmedButton;
