import { DataTableColumn } from 'components/DataTable/DataTable';
import { DataTableCheckbox } from 'components/DataTable/DataTableEditableFields/DataTableCheckbox';
import { UserMutators } from 'hooks/queries/user/types';
import * as React from 'react';
import { Partner, User } from 'thriftgen/api_types';

interface BuildToggleMemberRoleCheckboxProps extends Pick<UserMutators, 'setPartnerMemberRoles'> {
	partnerId: Partner['partner_id'];
	canEdit: boolean;
	headerLabel: string;
	roleKey: string;
}

const buildToggleMemberRoleCheckbox = ({
	partnerId,
	setPartnerMemberRoles,
	canEdit,
	headerLabel,
	roleKey
}: BuildToggleMemberRoleCheckboxProps): DataTableColumn<User> => ({
	header: headerLabel,
	render: ({ partner_member_roles: partnerMemberRoles, user_id: userId }: User): JSX.Element => {
		const roles = partnerMemberRoles[partnerId];
		const isChecked = roles?.[roleKey];

		function handleClick() {
			const newRoles: PartnerMemberRoles = {
				...roles,
				[roleKey]: !isChecked
			};
			setPartnerMemberRoles(partnerId, userId, newRoles);
		}

		const dataTestId = `toggleAs${headerLabel}-${userId}`;

		function renderCheckbox(): JSX.Element {
			return (
				<DataTableCheckbox
					onClick={handleClick}
					checked={isChecked}
					dataTestId={dataTestId}
				/>
			);
		}

		function renderNonEditableLabel(): JSX.Element {
			return isChecked ? <div>Yes</div> : <React.Fragment />;
		}

		return canEdit ? renderCheckbox() : renderNonEditableLabel();
	}
});

export { buildToggleMemberRoleCheckbox };
