import WebViewer, { WebViewerInstance } from '@pdftron/webviewer';

import { VIEWER_ELEMENTS_TO_DISABLE, VIEWER_FEATURES_TO_DISABLE } from './constants';
import { PdfViewerDocument } from './types';

// Serve PDFTron assets from the webserver rather than CDN
// due to CORS limitations. REACT_APP_PDFTRON_SERVE_PATH is defined
// and replaced at build time.
const PDFTRON_ABS_SERVE_PATH = getPDFTronAbsoluteServePath();
const PDFTRON_LIB_PATH = PDFTRON_ABS_SERVE_PATH + '/';
const PDFTRON_CSS_PATH = PDFTRON_ABS_SERVE_PATH + '/ui/style.css';

function getPDFTronAbsoluteServePath(): string {
	return (process.env.REACT_APP_PDFTRON_SERVE_PATH || '').replace(/^\./, '');
}

async function loadDocument({
	data,
	onError,
	onLoad,
	webViewer
}: {
	data: PdfViewerDocument;
	onError: () => void;
	onLoad: () => void;
	webViewer: WebViewerInstance;
}): Promise<() => void> {
	webViewer.Core.documentViewer.addEventListener('documentLoaded', onLoad);

	function unloadDocument() {
		webViewer.Core.documentViewer.removeEventListener('documentLoaded', onLoad);
	}

	try {
		await webViewer.Core.documentViewer.loadDocument(data.source, {
			docId: data.id,
			filename: data.name,
			onError
		});
		return Promise.resolve(unloadDocument);
	} catch (e) {
		webViewer.Core.documentViewer.removeEventListener('documentLoaded', onLoad);
		return Promise.reject(e);
	}
}

async function setupWebViewer(container: HTMLElement): Promise<WebViewerInstance> {
	const defaultConfig = {
		forceClientSideInit: true,
		fullAPI: true,
		path: PDFTRON_LIB_PATH,
		licenseKey: process.env.REACT_APP_PDFTRON_LICENSE_KEY,
		css: PDFTRON_CSS_PATH,
		disabledElements: VIEWER_ELEMENTS_TO_DISABLE,
		enableAnnotations: false
	};

	try {
		const instance: WebViewerInstance = await WebViewer(defaultConfig, container);
		instance.UI.disableFeatures(VIEWER_FEATURES_TO_DISABLE);
		return instance;
	} catch (e) {
		return e;
	}
}

export { getPDFTronAbsoluteServePath, loadDocument, setupWebViewer };
