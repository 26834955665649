import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

interface MediaDevicesList {
	audio: MediaDeviceInfo[];
	video: MediaDeviceInfo[];
}

interface MediaDeviceSource {
	name: keyof MediaDeviceSources;
	value: MediaDeviceInfo['deviceId'];
}

interface MediaDeviceSources {
	audio: MediaDeviceInfo['deviceId'] | null;
	video: MediaDeviceInfo['deviceId'] | null;
}

interface RecordingStreamData {
	mediaDevices: MediaDevicesList;
	sources: MediaDeviceSources;
	streams: {
		screen: MediaStream | null;
		webcam: MediaStream | null;
	};
}

class MediaRetrievalException extends Error {
	public type: MediaDeviceAccessError;
	public name: string;

	constructor(message: string, name: string, type: MediaDeviceAccessError) {
		super(message);
		this.name = name;
		this.type = type;
	}
}

enum MediaDeviceAccessError {
	BROWSER_PERMISSION_DENIED = 'browser_permission_denied',
	DEVICE_NOT_FOUND = 'device_not_found',
	FALLBACK = 'fallback',
	SCREEN_SELECTION_CANCELED = 'screen_selection_canceled',
	SCREEN_SYSTEM_NOT_ALLOWED = 'screen_system_not_allowed',
	WEBCAM_SYSTEM_NOT_ALLOWED = 'webcam_system_not_allowed',
	SOURCE_NOT_READABLE = 'source_not_readable'
}

function clearMediaStreams(streams: MediaStream[]): void {
	streams.forEach(stream => {
		stream.getTracks().forEach(track => {
			try {
				track.stop();
			} catch (e) {
				SitkaLogger.logMessage(
					`Failed to stop MediaStreamTrack: ${e.message}`,
					LogEventName.VIDEO
				);
			}
		});
	});
}

function isDeviceVideoInput(device: MediaDeviceInfo): boolean {
	return device.kind === 'videoinput';
}

function isDeviceAudioInput(device: MediaDeviceInfo): boolean {
	return device.kind === 'audioinput';
}

function findDeviceByLabel(
	deviceList: MediaDeviceInfo[],
	label: MediaDeviceInfo['label']
): MediaDeviceInfo | undefined {
	return deviceList.find((device: MediaDeviceInfo) => device.label === label);
}

function getDevices(): Promise<MediaDeviceInfo[]> {
	return window.navigator.mediaDevices.enumerateDevices();
}

function getDeviceIdFromMediaTrack(
	tracks: MediaStreamTrack[],
	mediaDevices: MediaDeviceInfo[]
): MediaDeviceInfo['deviceId'] | null {
	if (tracks.length === 0 || mediaDevices.length === 0) {
		return null;
	}

	const label: MediaDeviceInfo['label'] = tracks[0].label;
	const device = findDeviceByLabel(mediaDevices, label);

	if (device !== undefined) {
		return device.deviceId;
	}

	return null;
}

function isSystemAccessError(error: DOMException): boolean {
	return error.name === 'NotAllowedError' && error.message === 'Permission denied by system';
}

function isBrowserAccessError(error: DOMException): boolean {
	return error.name === 'NotAllowedError' && error.message === 'Permission denied';
}

function isDeviceNotFoundError(error: DOMException): boolean {
	return error.name === 'NotFoundError';
}

function isReadableError(error: DOMException): boolean {
	return error.name === 'NotReadableError' && error.message === 'Could not start video source';
}

function getMediaDeviceErrorForWebcamException(error: DOMException): MediaDeviceAccessError {
	if (isSystemAccessError(error)) {
		return MediaDeviceAccessError.WEBCAM_SYSTEM_NOT_ALLOWED;
	}

	if (isBrowserAccessError(error)) {
		return MediaDeviceAccessError.BROWSER_PERMISSION_DENIED;
	}

	if (isReadableError(error)) {
		return MediaDeviceAccessError.SOURCE_NOT_READABLE;
	}

	if (isDeviceNotFoundError(error)) {
		return MediaDeviceAccessError.DEVICE_NOT_FOUND;
	}

	return MediaDeviceAccessError.FALLBACK;
}

function getMediaDeviceErrorForScreenException(
	error: DOMException | Error
): MediaDeviceAccessError {
	if (error instanceof DOMException) {
		if (isSystemAccessError(error)) {
			return MediaDeviceAccessError.SCREEN_SYSTEM_NOT_ALLOWED;
		}

		if (isBrowserAccessError(error)) {
			return MediaDeviceAccessError.SCREEN_SELECTION_CANCELED;
		}
	}

	return MediaDeviceAccessError.FALLBACK;
}

function getFirstDeviceIdFromList(
	mediaDevices: MediaDeviceInfo[]
): MediaDeviceInfo['deviceId'] | null {
	if (mediaDevices.length === 0) {
		return null;
	}

	return mediaDevices[0].deviceId;
}

function getMediaDevices(): Promise<MediaDevicesList> {
	return getDevices()
		.then(devices => {
			const sources: MediaDevicesList = {
				video: devices.filter(isDeviceVideoInput),
				audio: devices.filter(isDeviceAudioInput)
			};

			return sources;
		})
		.catch((reason: DOMException) => {
			SitkaLogger.logMessage(
				`Failed to get media devices: ` + reason.message,
				LogEventName.VIDEO
			);
			const error = new MediaRetrievalException(
				reason.message,
				reason.name,
				getMediaDeviceErrorForWebcamException(reason)
			);
			return Promise.reject(error);
		});
}

function getWebcamStream(sources?: MediaDeviceSources): Promise<MediaStream> {
	const audio = sources !== undefined ? sources.audio : null;
	const video = sources !== undefined ? sources.video : null;

	return navigator.mediaDevices
		.getUserMedia({
			audio: {
				deviceId: setDeviceConstraint(audio)
			},
			video: {
				deviceId: setDeviceConstraint(video),
				facingMode: 'user',
				height: {
					ideal: 720
				},
				width: {
					ideal: 1280
				}
			}
		})
		.catch((reason: DOMException) => {
			SitkaLogger.logMessage(
				`Failed to get webcam stream: ` + reason.message,
				LogEventName.VIDEO
			);
			const error = new MediaRetrievalException(
				reason.message,
				reason.name,
				getMediaDeviceErrorForWebcamException(reason)
			);
			return Promise.reject(error);
		});
}

function getDefaultRecordingStreamData(): RecordingStreamData {
	return {
		streams: {
			screen: null,
			webcam: null
		},
		sources: {
			audio: null,
			video: null
		},
		mediaDevices: {
			audio: [],
			video: []
		}
	};
}

async function getRecordingStreamData(): Promise<RecordingStreamData> {
	const data = getDefaultRecordingStreamData();

	// Requests permissions to access media devices. We clear it because
	// we only create it to obtain access to enumerate the available
	// devices. The consumer creates the recording stream when ready.
	const webcam = await getWebcamStream();
	clearMediaStreams([webcam]);

	// Get list of available video and audio devices.
	// This has to be retrieved after generating the streams
	// since device access permissions need to be granted first.

	data.mediaDevices = await getMediaDevices();

	if (window.MediaStreamTrack !== undefined && data.streams.webcam !== null) {
		data.sources = {
			...data.sources,
			video: getDeviceIdFromMediaTrack(
				data.streams.webcam.getVideoTracks(),
				data.mediaDevices.video
			),
			audio: getDeviceIdFromMediaTrack(
				data.streams.webcam.getAudioTracks(),
				data.mediaDevices.audio
			)
		};
	} else {
		data.sources = {
			...data.sources,
			audio: getFirstDeviceIdFromList(data.mediaDevices.audio),
			video: getFirstDeviceIdFromList(data.mediaDevices.video)
		};
	}

	return data;
}

function getScreenStream(): Promise<MediaStream> {
	const previousPageTitle = document.title;

	function revertPageTitle(): void {
		document.title = previousPageTitle;
	}

	function handleError(reason: DOMException): Promise<never> {
		SitkaLogger.logMessage(
			`Failed to get screen stream: ` + reason.message,
			LogEventName.VIDEO
		);
		revertPageTitle();
		const error = new MediaRetrievalException(
			reason.message,
			reason.name,
			getMediaDeviceErrorForScreenException(reason)
		);
		return Promise.reject(error);
	}

	function getStream(): Promise<MediaStream> {
		return (navigator.mediaDevices as any).getDisplayMedia().then((response: MediaStream) => {
			revertPageTitle();
			return response;
		});
	}

	document.title = previousPageTitle + ' - Pick me for recording';

	return getStream().catch(handleError);
}

const BROWSER_DEFAULT_SELECTION = undefined;

function setDeviceConstraint(
	deviceId: MediaDeviceInfo['deviceId'] | null
): ConstrainDOMStringParameters | undefined {
	if (deviceId === null) {
		return BROWSER_DEFAULT_SELECTION;
	}

	return {
		exact: deviceId
	};
}

export {
	clearMediaStreams,
	MediaDeviceAccessError,
	MediaRetrievalException,
	MediaDevicesList,
	MediaDeviceSource,
	MediaDeviceSources,
	getDefaultRecordingStreamData,
	getRecordingStreamData,
	getScreenStream,
	getWebcamStream,
	RecordingStreamData
};
