import * as React from 'react';

import { RecordingStatus, RecordingMouseEvents } from 'components/Recorder/types';

import {
	PauseRecordingButton,
	ResumeRecordingButton,
	StartRecordingButton,
	StopRecordingButton
} from 'components/Buttons';

type RecordingControlsProps = Omit<RecordingMouseEvents, 'onMinimize' | 'onOpenSettings'> & {
	status: RecordingStatus;
};

function RecordingControls({
	status,
	onPause,
	onResume,
	onStart,
	onStop
}: RecordingControlsProps): JSX.Element {
	switch (status) {
		case RecordingStatus.RECORDING:
			return (
				<React.Fragment>
					<StopRecordingButton onClick={onStop} />
					<PauseRecordingButton onClick={onPause} />
				</React.Fragment>
			);
		case RecordingStatus.PAUSED:
			return (
				<React.Fragment>
					<StopRecordingButton onClick={onStop} />
					<ResumeRecordingButton onClick={onResume} />
				</React.Fragment>
			);
		case RecordingStatus.IDLE:
			return <StartRecordingButton onClick={onStart} />;
	}
}

export default RecordingControls;
