import { CONSULT_SUMMARY_FILTERS } from 'api/customFilters';
import Strings from 'lib/strings';
import { FilterType } from './types';

const CITY_SEARCH_FILTER = {
	attribute: CONSULT_SUMMARY_FILTERS.CONSULT_REQUESTOR_CITY,
	placeholder: Strings.Labels.SEARCH_CITY_PLACEHOLDER,
	type: FilterType.SEARCH
};

export { CITY_SEARCH_FILTER };
