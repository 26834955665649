import { AudioIssueType } from './types';
import { clearSamples, hasAudibleChannel, processEvent } from './utility';

type AudioIssueCallback = (audioIssue: AudioIssueType) => void;

// code heavily inspired by the WebRTC project and their testing
// implementation found here: https://github.com/webrtc/testrtc
export default class AudioMonitor {
	private listeners: AudioIssueCallback[] = [];
	private audioPoller: number;
	private audioIssueCount = 0;
	private hasProcessedAudio = false;
	private loggedAudioIssue: AudioIssueType;
	// Populated with audio as a 3-dimensional array:
	//   collectedAudio[channels][buffers][samples]
	private collectedAudio: any[] = [];
	private audioContext: AudioContext;
	private audioSource: MediaStreamAudioSourceNode;
	private scriptNode: ScriptProcessorNode;
	private audioTracks: MediaStreamTrack[];
	// How often audio is polled to analyze in ms
	private readonly pollingIntervalMs: number = 1500;
	private readonly inputChannelCount: number = 6;
	private readonly outputChannelCount: number = 2;
	// Buffer size set to 0 to let Chrome choose based on the platform.
	private readonly bufferSize: number = 0;
	// At least one LSB 16-bit data (compare is on absolute value).
	private readonly silentThreshold: number = 1.0 / 32767;
	private readonly lowVolumeThreshold: number = -60;
	// Number of consecutive clipThreshold level samples that indicate clipping.
	private readonly clipCountThreshold: number = 6;
	private readonly clipThreshold: number = 1.0;

	public getAudioTracks(stream: MediaStream): void {
		this.audioTracks = stream.getAudioTracks();
		if (this.audioTracks.length < 1) {
			this.loggedAudioIssue = AudioIssueType.NO_AUDIO_CHANNEL;
		} else {
			this.createAudioBuffer(stream);
		}
	}

	public startMonitoringAudio(): void {
		// even though onaudioprocess is deprecated
		// AudioWorklet is not broadly supported enough to switch to Audio Workers
		if (this.loggedAudioIssue !== AudioIssueType.NO_AUDIO_CHANNEL) {
			this.scriptNode.onaudioprocess = this.collectAudio.bind(this);
		}
		this.audioPoller = window.setInterval(
			() => this.analyzeAudio(this.collectedAudio),
			this.pollingIntervalMs
		);
	}

	public pauseMonitoringAudio(): void {
		window.clearInterval(this.audioPoller);
	}

	public stopMonitoringAudio(): void {
		this.pauseMonitoringAudio();
		if (this.loggedAudioIssue !== AudioIssueType.NO_AUDIO_CHANNEL) {
			this.audioSource.disconnect(this.scriptNode);
			this.scriptNode.disconnect(this.audioContext.destination);
		}
	}

	public addAudioIssueEventListener(callback: AudioIssueCallback): void {
		this.listeners.push(callback);
	}

	public isVideoAudible(): boolean {
		return this.hasProcessedAudio;
	}

	private createAudioBuffer(stream: MediaStream): void {
		this.collectedAudio = clearSamples(this.collectedAudio, this.inputChannelCount);
		const AudioContext: typeof window.AudioContext =
			window.AudioContext || window.webkitAudioContext;
		this.audioContext = new AudioContext();
		this.audioSource = this.audioContext.createMediaStreamSource(stream);
		this.scriptNode = this.audioContext.createScriptProcessor(
			this.bufferSize,
			this.inputChannelCount,
			this.outputChannelCount
		);
		this.audioSource.connect(this.scriptNode);
		this.scriptNode.connect(this.audioContext.destination);
	}

	private collectAudio(event: AudioProcessingEvent): void {
		// Simple silence detection: check first and last sample of each channel in
		// the buffer. If both are below a threshold, the buffer is considered
		// silent.
		this.collectedAudio = processEvent({
			audioEvent: event,
			silentThreshold: this.silentThreshold,
			collectedAudio: this.collectedAudio
		});
	}

	private analyzeAudio(channels: any[]): void {
		const activeChannels: number[] = [];
		for (let c = 0; c < channels.length; c++) {
			const channel = hasAudibleChannel({
				buffers: channels[c],
				clipThreshold: this.clipThreshold,
				silentThreshold: this.silentThreshold,
				lowVolumeThreshold: this.lowVolumeThreshold,
				clipCountThreshold: this.clipCountThreshold
			});
			if (channel.isAudible) {
				if (channel.audioIssue) {
					this.logAudioEvent(channel.audioIssue);
				}
				this.hasProcessedAudio = true;
				activeChannels.push(c);
				break;
			}
		}
		if (activeChannels.length === 0) {
			this.logAudioEvent(AudioIssueType.AUDIO_DROPPED_OUT);
		}
		this.collectedAudio = clearSamples(this.collectedAudio, this.inputChannelCount);
	}

	private logAudioEvent(audioEvent: AudioIssueType): void {
		// message dropped and clean audio immediately, otherwise wait for
		// threshold to be met. Only emit new issues unless no channel is detected.
		switch (audioEvent) {
			case AudioIssueType.NO_AUDIO_CHANNEL:
			case AudioIssueType.AUDIO_DROPPED_OUT:
			case AudioIssueType.CLEAN_AUDIO:
				if (audioEvent !== this.loggedAudioIssue) {
					this.loggedAudioIssue = audioEvent;
					this.emitAudioIssue(this.loggedAudioIssue);
				}
				break;
			case AudioIssueType.AUDIO_TOO_HIGH:
			case AudioIssueType.AUDIO_TOO_LOW:
				if (audioEvent === this.loggedAudioIssue) {
					this.audioIssueCount++;
				} else {
					this.loggedAudioIssue = audioEvent;
					this.audioIssueCount = 0;
				}
				if (this.audioIssueCount === 3) {
					this.emitAudioIssue(this.loggedAudioIssue);
				}
		}
	}

	private emitAudioIssue(audioIssue: AudioIssueType) {
		this.listeners.forEach(callback => callback(audioIssue));
	}
}
