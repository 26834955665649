import * as React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectRequests, clearRequest, updateRequest } from 'store/requests';
import StatusMessage from './StatusMessage';
import * as ACTION_STATUS from 'store/actionStatus';
import debounce from 'lib/debounce';

class RequestContext extends React.Component {
	constructor(props) {
		super(props);
		this.renderStatus = this.renderStatus.bind(this);
		this.clearRequest = this.clearRequest.bind(this);
		this.reset = this.reset.bind(this);
	}

	renderDebounced = debounce(this.forceUpdate, 300);

	componentWillUnmount() {
		this.renderDebounced.cancel();
		this.clearRequest();
	}

	clearRequest() {
		const { id, toast } = this.props.request;
		if (!toast) {
			this.props.clearRequest(id);
		}
	}

	reset() {
		/*
		 * This is a temporary shorthand so that some modals can reset the state
		 * manually. These particular modals don't unmount until the page is
		 * navigated, so the state of the request persists. Solving this means
		 * moving those modals to use the Toast setup, or re-architecting
		 * the design of the modal in a way that the component wrapped by
		 * withApiCall does mount/unmount as part of the modal's lifecycle.
		 * An example of the latter is the AddThirdPartyMRI component.
		 */
		const { id } = this.props.request;
		this.props.updateRequest({
			id,
			status: ACTION_STATUS.INACTIVE
		});
	}

	shouldComponentUpdate() {
		if (this.props.debounced) {
			this.renderDebounced();
			return false;
		}

		return true;
	}

	renderStatus() {
		const { message, status } = this.props.request;
		switch (status) {
			case ACTION_STATUS.SUCCESS:
			case ACTION_STATUS.FAILURE:
				return <StatusMessage message={message} status={status} />;
			default:
				return null;
		}
	}

	render() {
		// eslint-disable-next-line
		const { id, toast, ...requestProps } = this.props.request;

		return this.props.children({
			data: requestProps,
			renderStatus: this.renderStatus,
			reset: this.reset
		});
	}
}

RequestContext.getDefaultProps = {
	debounced: false
};

RequestContext.propTypes = {
	debounced: PropTypes.bool,
	request: PropTypes.shape({
		id: PropTypes.symbol.isRequired,
		status: PropTypes.string,
		message: PropTypes.string,
		inProgress: PropTypes.bool,
		toast: PropTypes.bool
	}).isRequired,
	requestId: PropTypes.symbol.isRequired,
	children: PropTypes.func.isRequired,
	clearRequest: PropTypes.func.isRequired,
	updateRequest: PropTypes.func.isRequired
};

const mapStateToProps = (store, { requestId }) => {
	const requests = selectRequests(store);
	return {
		request: requests.find(({ id }) => id === requestId) || { id: Symbol() }
	};
};

const mapDispatchToProps = dispatch => ({
	clearRequest: (...args) => dispatch(clearRequest(...args)),
	updateRequest: (...args) => dispatch(updateRequest(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(RequestContext);
