import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import { ButtonType } from 'display';
import LinkButton, { LinkButtonProps } from './LinkButton';

function LinkToLoginButton({ responsive }: Pick<LinkButtonProps, 'responsive'>): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			link={RouteUrls.buildUrl(RouteUrls.Key.LOGIN)}
			text="Log in"
			responsive={responsive}
			classNames="loginButton"
		/>
	);
}

export default LinkToLoginButton;
