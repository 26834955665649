import { objectCamelToSnake, objectSnakeToCamel } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { JoinVideoVisitRequest, JoinVideoVisitResponse } from 'thriftgen/api_types';
import {
	JoinVideoVisitRequestCamel,
	JoinVideoVisitResponseCamel
} from 'thriftgen/thriftCamelTypes';

function joinVideoVisit(request: JoinVideoVisitRequestCamel): Promise<JoinVideoVisitResponseCamel> {
	const client = getCommonClient();
	const thriftRequest = new JoinVideoVisitRequest(objectCamelToSnake(request));

	return client
		.join_video_visit(thriftRequest)
		.then((response: JoinVideoVisitResponse) => objectSnakeToCamel(response));
}

export default joinVideoVisit;
