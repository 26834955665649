import { DataTableColumn } from 'components/DataTable/DataTable';
import { Partner, User } from 'thriftgen/api_types';
import Strings from 'lib/strings';

const buildIsAdmin = (partnerId: Partner['partner_id']): DataTableColumn<User> => ({
	header: Strings.Labels.ADMIN,
	render: ({ admin_for_partner_ids: adminForPartnerIds }: User): string | null => {
		if (adminForPartnerIds.includes(partnerId)) {
			return 'Yes';
		}

		return null;
	}
});

export { buildIsAdmin };
