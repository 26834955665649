import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import * as React from 'react';
import { Partner } from 'thriftgen/api_types';
import ChipSpecialties from './ChipSpecialties';
import { ChipSpecialtiesProps } from './types';

type ChipSpecialtiesWrapperProps = Omit<ChipSpecialtiesProps, 'specialties'>;

function buildChipSpecialties({ specialties }: Partner): DataFormFieldComponent {
	function ChipSpecialtiesWrapper(props: ChipSpecialtiesWrapperProps): JSX.Element | null {
		return <ChipSpecialties specialties={specialties} {...props} />;
	}

	ChipSpecialtiesWrapper.FIELD_NAME = ChipSpecialties.FIELD_NAME;
	ChipSpecialtiesWrapper.validate = ChipSpecialties.validate;

	return ChipSpecialtiesWrapper;
}

export { buildChipSpecialties };
