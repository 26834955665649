import * as React from 'react';
import PropTypes from 'prop-types';

import { Button } from 'display';
import Strings from 'lib/strings';

function RecordAgainButton({ onClick }) {
	return (
		<Button.Secondary className="recordAgainButton" onClick={onClick}>
			{Strings.Buttons.RECORD_AGAIN}
		</Button.Secondary>
	);
}

RecordAgainButton.propTypes = {
	onClick: PropTypes.func.isRequired
};

export default RecordAgainButton;
