import { orderSelectGroupItemByText, SelectGroupItem } from 'display';
import { translateThriftEnum } from 'lib/i18n';
import { Specialty, SPECIALTY } from 'thriftgen/api_types';
import { getSpecialtyImage } from './specialtyImages';

function transformSpecialtyToSelectGroupItem(value: Specialty): SelectGroupItem {
	const specialty = translateThriftEnum(value, SPECIALTY);

	return {
		disabled: false,
		images: getSpecialtyImage(value),
		label: specialty,
		text: specialty,
		value
	};
}

function transformSpecialtiesToSelectGroupItems(specialties: Specialty[]): SelectGroupItem[] {
	return specialties.map(transformSpecialtyToSelectGroupItem).sort(orderSelectGroupItemByText);
}

export { transformSpecialtyToSelectGroupItem, transformSpecialtiesToSelectGroupItems };
