import { SubmissionError } from 'components/Messages';
import { useConsultContext, useConversationContext } from 'contexts';
import { Heading } from 'display';
import { Divider, ButtonProps } from 'display';
import { css } from 'emotion';
import debounce from 'lib/debounce';
import { SitkaLogger, LogEventName } from 'lib/sitkaLogger';
import { secureStorage as SecureStorage } from 'lib/Storage';
import React, { useState } from 'react';
import { Accordion, Icon } from 'semantic-ui-react';
import { SEMANTIC_COLOR_STRINGS } from 'styles';
import { ConsultDataState, ConsultDataType, NewConversationMessage } from 'thriftgen/api_types.js';
import { DataFormConditionalField, DataFormFieldComponent } from './DataFormTypes';
import DataFormWrapper from './DataFormWrapper';
import { getFieldsAndSubfieldNames } from './Fields/helpers';
import { scrollToTop } from 'lib/scroll';

interface SMAResponseFormData {
	needsAdditionalInfo?: boolean;
	specialistResponseType?: SpecialistResponseType;
	specialistResponseAssessment?: Partial<SpecialistResponseAssessment>[];
	managementRecommendation?: SpecialistResponseRecommendation;
	referralSpecialty?: Specialty;
	recordVideo?: string[];
	textResponse?: string;
}

interface OnUnsavedChangesChangeProps {
	dataType: ConsultDataType;
	hasUnsavedChanges: boolean;
}

interface SMAResponseFormSubsectionProps {
	fields: DataFormFieldComponent[];
	conditionalFields?: DataFormConditionalField[];
	formatSubmitData: (
		smaResponseFormData: Partial<SMAResponseFormData>
	) => Partial<NewConversationMessage>[];
	dataType: ConsultDataType;
	accordionTitle: string;
	hasDraftData: (draftResponse: SMAResponseFormData) => boolean;
	defaultFormData: Partial<SMAResponseFormData>;
	submitControl: React.FunctionComponent<ButtonProps>;
	onUnsavedChangesChange: (onUnsavedChangesChangeProps: OnUnsavedChangesChangeProps) => void;
	shouldSaveDraft: boolean;
}

function SMAResponseFormSubsection({
	fields,
	conditionalFields,
	formatSubmitData,
	dataType,
	accordionTitle,
	hasDraftData,
	defaultFormData,
	submitControl,
	onUnsavedChangesChange,
	shouldSaveDraft
}: SMAResponseFormSubsectionProps): JSX.Element {
	const { consult } = useConsultContext();
	const { postNewMessage } = useConversationContext();

	const [isAccordionOpen, setIsAccordionOpen] = useState<boolean>(false);
	const [hasResponse, setHasResponse] = useState<boolean>(false);
	const [apiError, setApiError] = useState<Error | null>(null);

	const [hasUnsavedChanges, setHasUnsavedChanges] = useState<boolean>(false);

	const LOCAL_STORAGE_KEY = `draftSMAResponse_${consult.consult_id}`;
	const SUB_FIELDS = getFieldsAndSubfieldNames(fields);
	const DRAFT_RESPONSE: SMAResponseFormData = retrieveDraftData();
	const INITIAL_DATA: SMAResponseFormData = getInitialFormData();

	const styles = {
		completed: css`
			color: ${SEMANTIC_COLOR_STRINGS.GREEN};
		`
	};

	const smaMessages = React.useMemo(() => {
		return consult.conversations?.[0]?.messages.filter(
			msg => msg.sender_id === consult.specialist_id
		);
	}, [consult]);

	React.useEffect(() => {
		const messageOfDataType = smaMessages?.find(msg => msg.content.data_type === dataType);
		const hasResponseOfDataType =
			!!messageOfDataType && messageOfDataType.state !== ConsultDataState.FAILED;
		setHasResponse(hasResponseOfDataType);
	}, [smaMessages, dataType]);

	React.useEffect(() => {
		onUnsavedChangesChange({ dataType, hasUnsavedChanges });
	}, [dataType, hasUnsavedChanges, onUnsavedChangesChange]);

	function retrieveDraftData(): SMAResponseFormData {
		return SecureStorage.get(LOCAL_STORAGE_KEY);
	}

	function saveDraftData(draftData: SMAResponseFormData) {
		try {
			SecureStorage.set(LOCAL_STORAGE_KEY, draftData);
		} catch (e) {
			SitkaLogger.logMessage(e, LogEventName.SMA);
		}
	}

	const saveDraftDebounced = debounce((data: SMAResponseFormData) => {
		const newData = { ...DRAFT_RESPONSE, ...data };
		saveDraftData(newData);
	}, 1000);

	function onChange(data: SMAResponseFormData) {
		if (!hasUnsavedChanges) {
			setHasUnsavedChanges(true);
		}
		if (shouldSaveDraft) {
			saveDraftDebounced(data);
		}
	}

	function getInitialFormData(): Partial<SMAResponseFormData> {
		if (hasDraftData(DRAFT_RESPONSE)) {
			return DRAFT_RESPONSE;
		}
		return defaultFormData;
	}

	async function handleSubmit(smaResponseFormData: Partial<SMAResponseFormData>) {
		const newConversationMessageArgs: Partial<NewConversationMessage>[] =
			formatSubmitData(smaResponseFormData);
		try {
			setApiError(null);
			const newMessagePromises: Promise<void>[] = [];

			newConversationMessageArgs.forEach(function (item) {
				newMessagePromises.push(postNewMessage(new NewConversationMessage(item)));
			});

			await Promise.all(newMessagePromises);
			setHasResponse(true);
			setHasUnsavedChanges(false);
			SecureStorage.remove(LOCAL_STORAGE_KEY);
			scrollToTop(true);
		} catch (caughtApiError) {
			SitkaLogger.logMessage(caughtApiError);
			setApiError(caughtApiError);
		}
	}

	function toggleAccordion() {
		setIsAccordionOpen((): boolean => !isAccordionOpen);
	}

	return (
		<React.Fragment>
			{hasResponse ? (
				<Heading as={Heading.Type.H3} size={Heading.Size.T4} className={styles.completed}>
					<Icon name="check" /> Completed - {accordionTitle}
				</Heading>
			) : (
				<Accordion>
					<Accordion.Title onClick={toggleAccordion} active={isAccordionOpen}>
						<Heading as={Heading.Type.H3} size={Heading.Size.T4}>
							{isAccordionOpen ? (
								<Icon name="minus" color="blue" />
							) : (
								<Icon name="plus" color="blue" />
							)}
							{accordionTitle}
						</Heading>
					</Accordion.Title>
					<Accordion.Content active={isAccordionOpen}>
						<React.Fragment>
							<DataFormWrapper
								data={INITIAL_DATA}
								submitAllFields={hasDraftData(DRAFT_RESPONSE)}
								editableFields={SUB_FIELDS}
								fields={fields}
								requiredFields={SUB_FIELDS}
								conditionalFields={conditionalFields}
								SubmitControl={submitControl}
								onSubmit={handleSubmit}
								onChange={onChange}
								justifyContent={'flex-end'}
								displayActionsDivider={false}
							/>
							{!!apiError && (
								<div data-dd-privacy="allow">
									<SubmissionError message={apiError.message} />
								</div>
							)}
						</React.Fragment>
					</Accordion.Content>
				</Accordion>
			)}
			<Divider section={true} key={`hr-sma-response-${dataType}-accordion`} />
		</React.Fragment>
	);
}

export default SMAResponseFormSubsection;

export { SMAResponseFormData, SMAResponseFormSubsectionProps, OnUnsavedChangesChangeProps };
