import { UserLoadError } from 'components/Messages';
import * as React from 'react';
import { User } from 'thriftgen/api_types';
import { UserInfoCamel } from 'thriftgen/thriftCamelTypes';
import {
	CombinedDateOfBirth,
	SexField,
	UserPatientEmail,
	UserPatientFirstName,
	UserPatientFullName,
	UserPatientId,
	UserPatientLastName,
	UserPatientMedicareBeneficiaryId,
	UserPatientPhone
} from '../Fields';
import { getFieldsAndSubfieldNames } from '../Fields/helpers';
import UserEditor from './UserEditor';

interface EditPatientFormProps {
	patient: User | undefined;
	onSubmit: (data: UserInfoCamel) => Promise<void>;
	onCancel: () => void;
}

const PATIENT_FIELDS = [
	UserPatientFirstName,
	UserPatientLastName,
	UserPatientId,
	CombinedDateOfBirth,
	SexField,
	UserPatientPhone,
	UserPatientEmail,
	UserPatientMedicareBeneficiaryId
];

const REQUIRED_FIELDS = getFieldsAndSubfieldNames([
	UserPatientFullName,
	UserPatientId,
	CombinedDateOfBirth
]);

function EditPatientForm({ patient, onCancel, onSubmit }: EditPatientFormProps): JSX.Element {
	if (!patient) {
		return <UserLoadError message="Patient information not found" />;
	}

	return (
		<UserEditor
			user={patient}
			fields={PATIENT_FIELDS}
			requiredFields={REQUIRED_FIELDS}
			onSubmit={onSubmit}
			onCancel={onCancel}
		/>
	);
}

export default EditPatientForm;
