import * as React from 'react';
import PropTypes from 'prop-types';
import {
	UserTitle as UserTitleType,
	USER_TITLE as USER_TITLE_TRANSLATIONS
} from 'thriftgen/api_types';
import { translateThriftEnum } from 'lib/i18n';

import { Form } from 'display';

const OPTIONS = Object.values(UserTitleType).map(value => ({
	key: value,
	value: value,
	text: translateThriftEnum(value, USER_TITLE_TRANSLATIONS)
}));

const noop = () => {};
const FIELD_NAME = 'title';

class Title extends React.Component {
	static FIELD_NAME = FIELD_NAME;

	canEdit() {
		const { editableFields } = this.props;

		return editableFields.includes(FIELD_NAME);
	}

	hasError() {
		const { errors } = this.props;
		const { fields } = errors;

		return fields.includes(FIELD_NAME);
	}

	getValue() {
		const { data } = this.props;

		return data[FIELD_NAME];
	}

	render() {
		const { label, onChange } = this.props;
		const value = this.getValue();

		if (this.canEdit()) {
			return (
				<Form.Select
					id={FIELD_NAME}
					error={this.hasError()}
					search
					label={label}
					placeholder={translateThriftEnum(UserTitleType.DR, USER_TITLE_TRANSLATIONS)}
					name={FIELD_NAME}
					options={OPTIONS}
					value={value}
					onChange={onChange}
					width={4}
				/>
			);
		}

		if (value) {
			return (
				<Form.Field>
					<label>{label}</label>
					<span>{value}</span>
				</Form.Field>
			);
		}

		return null;
	}
}

Title.defaultProps = {
	data: {},
	editableFields: [],
	errors: {
		fields: []
	},
	label: 'Title',
	onChange: noop
};

Title.propTypes = {
	data: PropTypes.object,
	editableFields: PropTypes.arrayOf(PropTypes.string),
	errors: PropTypes.shape({
		fields: PropTypes.arrayOf(PropTypes.string)
	}),
	label: PropTypes.string.isRequired,
	onChange: PropTypes.func
};

export default Title;
