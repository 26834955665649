import FinalizedTextLogEntry from './FinalizedTextLogEntry';
import ProcessingIterationLogEntry from './ProcessingIterationLogEntry';
import TranscriptionSessionLog from './TranscriptionSessionLog';
import ProcessingStepLogEntry from './ProcessingStepLogEntry';

/**
 * Parent class to handle transcription logging
 */
export default class TranscriptionLogger {
	transcriptionSessionLog: TranscriptionSessionLog;
	finalizedTranscriptLogEntry: FinalizedTextLogEntry;
	processingIterationLogEntry: ProcessingIterationLogEntry;

	constructor() {
		this.prepLoggerForTranscribing();
	}

	prepLoggerForTranscribing = () => {
		this.transcriptionSessionLog = new TranscriptionSessionLog();
		this.processingIterationLogEntry = new ProcessingIterationLogEntry();
		this.finalizedTranscriptLogEntry = new FinalizedTextLogEntry();
	};

	setSessionLogPreExistingText(text: string) {
		this.transcriptionSessionLog.setPreExistingText(text);
	}

	handleNewProcessingIteration = (transcript: string) => {
		// add current processing iteration log to current finalized transcript log entry
		this.finalizedTranscriptLogEntry.addProcessingIterationLogEntry(
			this.processingIterationLogEntry
		);

		// initialize new processing iteration log entry
		this.processingIterationLogEntry = new ProcessingIterationLogEntry();
		this.processingIterationLogEntry.textFromAmazon = transcript;
	};

	handleNewFinalizedTextBlock = (transcript: string, processedText: string) => {
		// update current finalized transcript log entry with current values
		this.finalizedTranscriptLogEntry.textFromAmazon = transcript;
		this.finalizedTranscriptLogEntry.processedTranscript = processedText;

		// save current processing iteration log to current finalized transcript log
		this.finalizedTranscriptLogEntry.addProcessingIterationLogEntry(
			this.processingIterationLogEntry
		);

		// save current finalized transcript log to transcription session log
		this.transcriptionSessionLog.addFinalizedTextBlockLogEntry(
			this.finalizedTranscriptLogEntry
		);

		// initialize new log entries
		this.processingIterationLogEntry = new ProcessingIterationLogEntry();
		this.finalizedTranscriptLogEntry = new FinalizedTextLogEntry();
	};

	addStepLogEntry(entry: ProcessingStepLogEntry) {
		// only log entries when text modifications have occurred
		if (entry.regexSearchInfo) {
			entry.regexSearchInfo.forEach(info => {
				if (info.matches.length > 0) {
					this.processingIterationLogEntry.addStepLogEntry(entry);
				}
			});
		}
	}

	getLog = () => {
		return this.transcriptionSessionLog;
	};
}
