import { ClinicalReviewCamel } from 'thriftgen/thriftCamelTypes';

function isClinicalReviewNew(clinicalReview: ClinicalReviewCamel) {
	return (
		clinicalReview.numPreviousReviewedConsults !== undefined &&
		clinicalReview.numPreviousReviewedConsults <= 2
	);
}

export { isClinicalReviewNew };
