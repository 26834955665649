import { getAuthClient } from 'lib/ThriftClient';

// Endpoints
import checkTokenRequest from './checkTokenRequest';

const CLIENT = getAuthClient();

export const checkToken = checkTokenRequest(CLIENT);

export { default as logout } from './logout';
export { default as oidcLogin } from './oidcLogin';
export * from './tokens';
