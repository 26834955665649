import * as React from 'react';
import { UserCamel } from 'thriftgen/thriftCamelTypes';

import { PhoneNumber } from 'display';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../../DataFormTypes';
import ReadOnlyField from '../base/ReadOnlyField';
import { alwaysValid } from '../helpers';

type DataType = UserCamel['phoneNumber'] | null;
type DisplayPatientPhoneComponent = DataFormFieldComponent<string, DataType>;
type DisplayPatientPhoneComponentProps = DataFormFieldComponentProps<DataType>;

function getContent(data: DisplayPatientPhoneComponentProps['data']): JSX.Element | null {
	const phoneNumber = data[DisplayPatientPhone.FIELD_NAME];

	if (phoneNumber) {
		return <PhoneNumber number={phoneNumber} />;
	}

	return null;
}

const DisplayPatientPhone: DisplayPatientPhoneComponent = ({
	data
}: DisplayPatientPhoneComponentProps): JSX.Element => {
	return <ReadOnlyField label="Mobile">{getContent(data)}</ReadOnlyField>;
};

DisplayPatientPhone.FIELD_NAME = 'phoneNumber';
DisplayPatientPhone.validate = alwaysValid;

export default DisplayPatientPhone;
