import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent } from '../DataFormTypes';
import { getRoleReadableForm, Role } from 'lib/userHelpers';

const FIELD_NAME = 'isSitkaMedicalAssociate';
const LABEL = getRoleReadableForm(Role.SMA);

const SitkaMedicalAssociate: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default SitkaMedicalAssociate;
