import * as React from 'react';
import ReactDOM from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { css } from 'emotion';

import { selectToastRequests, clearRequest } from 'store/requests';

import { zIndexLayer } from 'styles';
import StatusMessage from 'components/withApiCall/StatusMessage';

const styles = {
	container: css`
		max-width: 450px;
		width: 95%;
		display: block;
		margin: 0 auto;
		position: fixed;
		top: 20px;
		right: 0;
		left: 0;
		z-index: ${zIndexLayer.GLOBAL};
	`
};

class ToastNotifications extends React.Component {
	constructor() {
		super();
		this.root = document.getElementById('toast-root');
	}

	renderRequest(request, index) {
		const { message, status, id } = request;

		return (
			<StatusMessage
				status={status}
				message={message}
				key={`${index}_${id.toString()}`}
				floating
				onDismiss={() => this.props.clearRequest(id)}
			/>
		);
	}

	renderToast() {
		const { requests } = this.props;

		if (!requests.length) {
			return null;
		}

		return (
			<div className={styles.container}>{requests.map(this.renderRequest.bind(this))}</div>
		);
	}

	render() {
		return ReactDOM.createPortal(this.renderToast(), this.root);
	}
}

ToastNotifications.propTypes = {
	requests: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.symbol.isRequired,
			status: PropTypes.string,
			message: PropTypes.string
		})
	).isRequired,
	clearRequest: PropTypes.func.isRequired
};

const mapStateToProps = store => ({
	requests: selectToastRequests(store)
});

const mapDispatchToProps = dispatch => ({
	clearRequest: (...args) => dispatch(clearRequest(...args))
});

export default connect(mapStateToProps, mapDispatchToProps)(ToastNotifications);
