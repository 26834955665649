import * as React from 'react';
import { useRecorderContext } from 'contexts/RecorderContext';
import { Button, Icon } from 'display';

function TriggerRecorderIconButton(): JSX.Element | null {
	const { isOpen, openRecorder } = useRecorderContext();
	const buttonIconProps = {
		disabled: isOpen,
		title: 'Record Video',
		size: Button.Size.MINI,
		className: 'recordVideoIconButton'
	};

	return (
		<>
			<Button.Primary {...buttonIconProps} onClick={openRecorder}>
				<Icon name="record" />
				Record Video
			</Button.Primary>
		</>
	);
}

export default TriggerRecorderIconButton;
