import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

interface LogoutButtonProps {
	disabled?: ButtonProps['disabled'];
	onClickAsync: ButtonProps['onClickAsync'];
}

function LogoutButton({ disabled = false, onClickAsync }: LogoutButtonProps): JSX.Element {
	return (
		<Button.Minimal disabled={disabled} onClickAsync={onClickAsync}>
			{Strings.Buttons.LOG_OUT}
		</Button.Minimal>
	);
}

export default LogoutButton;
