import { MarkerActionType, MarkerActions, Markers, SetNeighborPageCursors } from './types';

function hasNextPage(state: Markers): boolean {
	return state.nextPageCursor !== null;
}

function hasPrevPage(state: Markers): boolean {
	return state.prevPageCursor !== null;
}

function setNeighborPageCursors({
	nextPageCursor,
	prevPageCursor
}: Markers): SetNeighborPageCursors {
	return {
		type: MarkerActionType.SET_NEIGHBOR_PAGE_CURSORS,
		nextPageCursor,
		prevPageCursor
	};
}

const markerActionCreators = {
	setNeighborPageCursors
};

function markersReducer(state: Markers, action: MarkerActions): Markers {
	switch (action.type) {
		case MarkerActionType.SET_NEIGHBOR_PAGE_CURSORS:
			return {
				...state,
				nextPageCursor: action.nextPageCursor,
				prevPageCursor: action.prevPageCursor
			};
		default:
			throw new Error('Unknown action type');
	}
}

export { markerActionCreators, hasNextPage, hasPrevPage, markersReducer };
