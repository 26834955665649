import { createContext, useContext } from 'react';

interface RecorderContextValue {
	isOpen: boolean;
	openRecorder: () => void;
	closeRecorder: () => void;
	enableScreenSharing?: boolean;
}

const EMPTY_RECORDER_CONTEXT = undefined;
const RecorderContext = createContext<RecorderContextValue | typeof EMPTY_RECORDER_CONTEXT>(
	EMPTY_RECORDER_CONTEXT
);

export function useRecorderContext(): RecorderContextValue {
	const context = useContext(RecorderContext);

	if (context === undefined) {
		throw new Error(
			'Recorder context can only be consumed if used within a RecorderContextProvider. Make sure an instance of RecorderContextProvider has been set upstream.'
		);
	}

	return context;
}

export const RecorderProvider = RecorderContext.Provider;

export { RecorderContext };
