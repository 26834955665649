import CredentialField from './Credential';
import { buildFieldVariation } from '../helpers';

const FIELD_NAME = 'credential';
const LABEL = 'Your credential';

export default buildFieldVariation({
	Field: CredentialField,
	fieldName: FIELD_NAME,
	label: LABEL
});
