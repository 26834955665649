import * as React from 'react';
import { Dropdown, DropdownProps } from 'display';
import { compareAscending } from 'lib/sort';
import { Partner } from 'thriftgen/api_types';

interface PartnerSelectProps {
	partners: Partner[];
	partnerId: Partner['partner_id'] | undefined;
	onChange: DropdownProps['onChange'];
	id: string;
	name: string;
}

function PartnerSelect({
	partners,
	partnerId,
	onChange,
	name,
	id
}: PartnerSelectProps): JSX.Element {
	const options = partners
		.map(partner => ({
			key: partner.partner_id,
			value: partner.partner_id,
			text: partner.name
		}))
		.sort((a, b) => {
			return compareAscending(a.text, b.text);
		});

	return (
		<Dropdown
			id={id}
			search
			selection={true}
			options={options}
			value={partnerId}
			placeholder="None"
			onChange={onChange}
			name={name}
			data-testid="partnerSelect"
		/>
	);
}

export default PartnerSelect;
