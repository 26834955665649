import { ApiResponse } from 'api/types';
import { ThunkAction } from 'store/types';
import { User } from 'thriftgen/api_types';
import { LOGIN, LOGOUT, REMAINING_ATTEMPTS } from './auth.reducers';
import { LoginAction, LogoutAction, RemainingAttemptsAction } from './auth.types';

export function handleAuthResponse(response: ApiResponse): ThunkAction<void> {
	return dispatch => {
		if (response.user) {
			dispatch(setLogin(response.user));
		}
		if (response.remaining_attempts) {
			dispatch(setRemainingAttempts(response.remaining_attempts));
		}
	};
}

export function handleLogoutResponse(_: ApiResponse): ThunkAction<void> {
	return dispatch => {
		dispatch(setLogout());
	};
}

export function setLogin(user: User): LoginAction {
	return {
		type: LOGIN,
		user
	};
}

export function setLogout(): LogoutAction {
	return {
		type: LOGOUT
	};
}

function setRemainingAttempts(remainingAttempts: number): RemainingAttemptsAction {
	return {
		type: REMAINING_ATTEMPTS,
		remainingAttempts
	};
}
