import { DataTableColumn } from 'components/DataTable/DataTable';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import { getDoctorName } from 'lib/userHelpers';
import * as React from 'react';
import { colors, fontSize } from 'styles';

const styles = {
	mobile: css`
		text-transform: uppercase;
		color: ${colors.primary.base};
		font-size: ${fontSize[0]};
		margin-bottom: 1em;
	`
};

const header = 'Requestor';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ requestor }: ConsultComposite) => getDoctorName(requestor, true),
	renderMobile: ({ requestor }: ConsultComposite) => (
		<div className={styles.mobile}>{getDoctorName(requestor, true)}</div>
	)
});
