import * as React from 'react';

import { buildNameValidator, FullName } from './FullName';
import UserPatientFirstName from './UserPatientFirstName';
import UserPatientLastName from './UserPatientLastName';

const FIELD_NAME = 'userPatientFullName';

function UserPatientFullName(props) {
	return (
		<FullName
			{...props}
			fieldName={FIELD_NAME}
			FirstName={UserPatientFirstName}
			LastName={UserPatientLastName}
		/>
	);
}

UserPatientFullName.FIELD_NAME = FIELD_NAME;
UserPatientFullName.SUB_FIELD_NAMES = [
	UserPatientFirstName.FIELD_NAME,
	UserPatientLastName.FIELD_NAME
];
UserPatientFullName.validate = buildNameValidator(
	UserPatientFirstName.FIELD_NAME,
	UserPatientLastName.FIELD_NAME
);

export default UserPatientFullName;
