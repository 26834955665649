import { createContext, useContext } from 'react';
import {
	Parameters,
	ParameterActionCreators,
	MarkerActionCreators,
	Filter
} from 'hooks/pagination/types';

type PaginationContextValue = ParameterActionCreators &
	MarkerActionCreators & {
		parameters: Parameters;
		hasNextPage: () => boolean;
		hasPrevPage: () => boolean;
		getFilter: (attribute: string) => Filter | undefined;
		setNextPage: () => void;
		setPrevPage: () => void;
	};

const EMPTY_PAGINATION_CONTEXT = undefined;
const PaginationContext = createContext<PaginationContextValue | typeof EMPTY_PAGINATION_CONTEXT>(
	EMPTY_PAGINATION_CONTEXT
);

function usePaginationContext(): PaginationContextValue {
	const context = useContext(PaginationContext);

	if (context === EMPTY_PAGINATION_CONTEXT) {
		throw new Error(
			'Pagination context can only be consumed within a PaginationContextProvider.'
		);
	}

	return context;
}

const PaginationProvider = PaginationContext.Provider;

export { PaginationContextValue, PaginationProvider, usePaginationContext };
