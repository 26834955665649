import { objectCamelToSnake } from 'lib/objectConverter';
import {
	BoolMutation,
	PartnerMutations,
	StringMutation,
	StringSetMutation
} from 'thriftgen/api_types';
import { PartnerMutationsCamel } from 'thriftgen/thriftCamelTypes';

function transformMutations({
	createCasesSitka,
	createConsultsSitka,
	enableScreenSharing,
	enablePatientCommunications,
	name,
	adminIds,
	logoUploadKey,
	memberIds,
	sharedPatientPageIntro,
	specialties,
	memberRoles
}: Partial<PartnerMutationsCamel>): Partial<PartnerMutations> {
	return {
		create_cases_sitka: createCasesSitka ? new BoolMutation(createCasesSitka) : undefined,
		create_consults_sitka: createConsultsSitka
			? new BoolMutation(createConsultsSitka)
			: undefined,
		enable_screen_sharing: enableScreenSharing
			? new BoolMutation(enableScreenSharing)
			: undefined,
		enable_patient_communications: enablePatientCommunications
			? new BoolMutation(enablePatientCommunications)
			: undefined,
		name: name ? new StringMutation(name) : undefined,
		admin_ids: adminIds ? new StringSetMutation(objectCamelToSnake(adminIds)) : undefined,
		logo_upload_key: logoUploadKey ? new StringMutation(logoUploadKey) : undefined,
		member_ids: memberIds ? new StringSetMutation(objectCamelToSnake(memberIds)) : undefined,
		specialties: specialties ? objectCamelToSnake(specialties) : undefined,
		shared_patient_page_intro: sharedPatientPageIntro
			? new StringMutation(sharedPatientPageIntro)
			: undefined,
		member_roles: memberRoles
	};
}

export { transformMutations };
