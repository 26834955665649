export enum AudioIssueType {
	CLEAN_AUDIO = 'audioClean',
	NO_AUDIO_CHANNEL = 'noAudioChannel',
	AUDIO_DROPPED_OUT = 'audioDroppedOut',
	AUDIO_TOO_LOW = 'audioTooLow',
	AUDIO_TOO_HIGH = 'audioTooHigh'
}

export interface AudioChannelOptions {
	buffers: AudioBuffer;
	clipThreshold: number;
	silentThreshold: number;
	lowVolumeThreshold: number;
	clipCountThreshold: number;
}

export interface AudioEventOptions {
	audioEvent: AudioProcessingEvent;
	silentThreshold: number;
	collectedAudio: any[];
}

export interface AudibleInterface {
	isAudible: boolean;
	audioIssue?: AudioIssueType | null;
}
