import Link, { LinkProps } from 'components/Link';
import { Button, ButtonProps, ButtonType, Icon, SemanticICONS } from 'display';
import * as React from 'react';

type LinkButtonProps = Pick<LinkProps, 'replace'> &
	Pick<ButtonProps, 'size' | 'responsive' | 'fluid'> & {
		buttonType?: ButtonType;
		link: LinkProps['to'];
		text?: string;
		icon?: SemanticICONS;
		classNames?: string;
	};

function LinkButton({
	buttonType = ButtonType.PRIMARY,
	fluid,
	link,
	replace,
	responsive = false,
	size,
	text,
	icon,
	classNames = ''
}: LinkButtonProps): JSX.Element {
	const ButtonVariation = Button[buttonType];

	return (
		<Link to={link} replace={replace}>
			<ButtonVariation
				size={size}
				responsive={responsive}
				fluid={fluid}
				className={`${classNames}`}>
				{icon && <Icon name={icon} />}
				{text}
			</ButtonVariation>
		</Link>
	);
}

export default LinkButton;
export { LinkButtonProps };
