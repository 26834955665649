import LastName from './LastName';
import { buildFieldVariation } from '../helpers';

const FIELD_NAME = 'lastName';
const LABEL = 'Your last name';

export default buildFieldVariation({
	Field: LastName,
	fieldName: FIELD_NAME,
	label: LABEL
});
