import { getCommonClient } from 'lib/ThriftClient';
import { objectSnakeToCamel } from 'lib/objectConverter';
import { GetServiceAccessParamsResponseCamel } from 'thriftgen/thriftCamelTypes';
import { SitkaLogger } from 'lib/sitkaLogger';

async function getServiceAccessParams(
	serviceType: ServiceType
): Promise<GetServiceAccessParamsResponseCamel> {
	const client = getCommonClient();
	let response = {};
	try {
		response = await client.get_service_access_params({ service_type: serviceType });
	} catch (err) {
		SitkaLogger.logMessage(err);
	}
	return objectSnakeToCamel(response);
}

export default getServiceAccessParams;
