import { Feature, isFeatureEnabled } from 'lib/feature';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';

enum FileSignatures {
	PNG = '89504E47',
	PDF = '25504446',
	JPG1 = 'FFD8FFDB',
	JPG2 = 'FFD8FFE0',
	JPG3 = 'FFD8FFE1',
	JPG4 = 'FFD8FFE2',
	JPG5 = 'FFD8FFEA',
	JPG6 = 'FFD8FFEE',
	TIF = '4D4D02A',
	TIFF = '49492A0',
	MP4MOV = '66747970',
	OFFICE1 = '504B34',
	OFFICE2 = '504B56',
	OFFICE3 = '504B78'
}

enum FileTypes {
	PNG = 'image/png',
	PDF = 'application/pdf',
	JPG = 'image/jpeg',
	TIFF = 'image/tiff',
	MP4 = 'video/mp4',
	MOV = 'video/quicktime',
	OFFICE = 'application/vnd.openxmlformats-officedocument'
}

interface ValidatedFiles {
	accepted: File[];
	rejectedExtension: File[];
	rejectedMimeType: File[];
}

function parseExtensionsFromString(extensions?: string): string[] {
	let extensionsList: string[] = [];
	if (extensions) {
		// split the comma separated list of file extensions
		// filter the list so that it only includes extensions and removes mimetypes
		// normalize casing and remove the leading dot
		extensionsList = extensions
			.split(',')
			.filter(extension => extension.startsWith('.'))
			.map(extension => extension.toUpperCase().slice(1));
	}
	return extensionsList;
}

function isAllowedExtension(fileName: string, acceptedFileExtensions: string[]) {
	const fileExtension = fileName
		.substring(fileName.lastIndexOf('.') + 1, fileName.length)
		.toUpperCase();
	return acceptedFileExtensions.includes(fileExtension);
}

async function validateFiles(
	files: FileList | File[] | null,
	acceptedFileExtensions?: string
): Promise<ValidatedFiles> {
	const acceptedFileExtensionsList: string[] = parseExtensionsFromString(acceptedFileExtensions);
	const processedFiles: ValidatedFiles = {
		accepted: [],
		rejectedExtension: [],
		rejectedMimeType: []
	};

	if (files) {
		if (acceptedFileExtensionsList.length) {
			processedFiles.rejectedExtension = [];
			processedFiles.rejectedMimeType = [];
			await Promise.all(
				Array.from(files).map(async file => {
					const fileParts = file.name.split('.');
					const fileExtension = fileParts[fileParts.length - 1].toUpperCase();
					const isValidExtension = acceptedFileExtensionsList.includes(fileExtension);
					let isValidMimeType;
					if (isValidExtension) {
						isValidMimeType = await validateMimeTypes(file);
					}
					if (!isValidExtension) {
						processedFiles.rejectedExtension.push(file);
					} else if (!isValidMimeType) {
						processedFiles.rejectedMimeType.push(file);
					} else {
						processedFiles.accepted.push(file);
					}
				})
			);
		} else {
			processedFiles.accepted = [...files];
		}
	}
	return processedFiles;
}

function getHeaderByteOffset(fileType: string) {
	const specialOffsets = {
		'video/mp4': 4,
		'video/quicktime': 4
	};
	return specialOffsets[fileType] ? specialOffsets[fileType] : 0;
}

function isValidMimeType(signature: string, fileType: string): boolean {
	let isValid;
	switch (signature) {
		case FileSignatures.PNG:
			isValid = fileType === FileTypes.PNG;
			break;
		case FileSignatures.PDF:
			isValid = fileType === FileTypes.PDF;
			break;
		case FileSignatures.JPG1:
		case FileSignatures.JPG2:
		case FileSignatures.JPG3:
		case FileSignatures.JPG4:
		case FileSignatures.JPG5:
		case FileSignatures.JPG6:
			isValid = fileType === FileTypes.JPG;
			break;
		case FileSignatures.TIF:
		case FileSignatures.TIFF:
			isValid = fileType === FileTypes.TIFF;
			break;
		case FileSignatures.MP4MOV:
			isValid = fileType === FileTypes.MP4 || fileType === FileTypes.MOV;
			break;
		case FileSignatures.OFFICE1:
		case FileSignatures.OFFICE2:
		case FileSignatures.OFFICE3:
			isValid = fileType.startsWith(FileTypes.OFFICE);
			break;
		default:
			isValid = false;
	}
	if (!isValid) {
		const errorMessage = new Error(
			`Invalid mime type - attached file has a signature of ${signature} but a file type of ${fileType}`
		);
		SitkaLogger.logMessage(errorMessage, LogEventName.UPLOAD);
	}
	return isValid;
}

async function validateMimeTypes(file: File): Promise<boolean> {
	if (isFeatureEnabled(Feature.CWA_1098_OVERRIDE_FILE_UPLOAD_MIME_TYPE_CHECK)) {
		return Promise.resolve(true);
	} else {
		return new Promise<boolean>((resolve, reject) => {
			const filereader = new FileReader();

			filereader.onloadend = function (event) {
				if (event?.target?.readyState === FileReader.DONE) {
					const uint = new Uint8Array(event?.target?.result as ArrayBufferLike);
					const bytes: string[] = [];
					uint.forEach(byte => {
						bytes.push(byte.toString(16));
					});
					const hex = bytes.join('').toUpperCase();
					resolve(isValidMimeType(hex, file.type));
				}
			};
			filereader.onerror = reject;

			const BYTE_OFFSET = getHeaderByteOffset(file.type);
			const BUFFER_LENGTH = 4;
			const blob = file.slice(BYTE_OFFSET, BYTE_OFFSET + BUFFER_LENGTH);
			filereader.readAsArrayBuffer(blob);
		});
	}
}

export { parseExtensionsFromString, validateFiles, isAllowedExtension };
