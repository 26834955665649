import { Video } from 'display';
import { isIOS } from 'lib/userAgent';
import React, { useMemo } from 'react';

interface PreviewVideoProps {
	video: Blob | null;
}

function PreviewVideo({ video }: PreviewVideoProps): JSX.Element | null {
	const url = useMemo((): string => (video ? URL.createObjectURL(video) : ''), [video]);

	if (!video) {
		return null;
	}

	return <Video data-testid="previewVideo" src={url} autoPlay={isIOS()} controls={true} />;
}

export default PreviewVideo;
