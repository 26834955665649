import { Specialty } from 'thriftgen/api_types.js';

const DEFAULT_SPECIALTIES = [
	Specialty.ALLERGY,
	Specialty.CARDIOLOGY,
	Specialty.DERMATOLOGY,
	Specialty.ENDOCRINOLOGY,
	Specialty.FOOT_AND_ANKLE_CARE,
	Specialty.GASTROENTEROLOGY,
	Specialty.HEMATOLOGY_ONCOLOGY,
	Specialty.INFECTIOUS_DISEASES,
	Specialty.NEPHROLOGY,
	Specialty.NEUROLOGY,
	Specialty.OBSTETRICS_GYNECOLOGY,
	Specialty.ORTHOPEDICS,
	Specialty.OTOLARYNGOLOGY,
	Specialty.PAIN_MEDICINE,
	Specialty.PHYSICAL_MEDICINE_AND_REHABILITATION,
	Specialty.PSYCHIATRY,
	Specialty.PULMONOLOGY,
	Specialty.RHEUMATOLOGY,
	Specialty.UROLOGY,
	Specialty.WOUND_VASCULAR
];

export default DEFAULT_SPECIALTIES;
