import * as React from 'react';
import { css } from 'emotion';

import { Paragraph } from 'display';
import { colors } from 'styles';

const styles = {
	container: css`
		color: ${colors.problem.base};
		padding-top: 0.5em;
	`
};

function ConsultUpdateError({ message }: { message: string }): JSX.Element {
	return (
		<div className={styles.container}>
			<Paragraph size={Paragraph.Size.T4}>Unable to update consult: {message}</Paragraph>
		</div>
	);
}

export default ConsultUpdateError;
