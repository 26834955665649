import { DataTableColumn } from 'components/DataTable/DataTable';
import { translateSpecialty } from 'components/Forms/Fields/Specialty/helpers';
import { css } from 'emotion';
import { ConsultComposite } from 'hooks';
import Strings from 'lib/strings';
import * as React from 'react';
import { colors, fontSize } from 'styles';

const styles = {
	mobile: css`
		text-transform: uppercase;
		color: ${colors.primary.base};
		font-size: ${fontSize[0]};
		margin-bottom: 1em;
	`
};

const header = 'Specialty Type';

export default (): DataTableColumn<ConsultComposite> => ({
	header,
	render: ({ specialty }: ConsultComposite) => (
		<div>{translateSpecialty(specialty, Strings.PLACEHOLDERS.NO_VALUE)}</div>
	),
	renderMobile: ({ specialty }: ConsultComposite) => (
		<div className={styles.mobile}>
			{translateSpecialty(specialty, Strings.PLACEHOLDERS.NO_VALUE)}
		</div>
	),
	sortKeys: ['specialty_name']
});
