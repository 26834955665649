import Link from 'components/Link';
import { Icon } from 'display';
import * as React from 'react';
import RouteUrls from 'routing/RouteUrls';
import { Case } from 'thriftgen/api_types';
import Strings from 'lib/strings';

function ReturnToCaseButton({ caseId }: { caseId: Case['case_id'] }): JSX.Element {
	const url = RouteUrls.buildUrl(RouteUrls.Key.CASE_DETAIL, { caseId });

	return (
		<Link to={url}>
			<Icon name="arrow left" />
			{` ${Strings.Buttons.RETURN_TO_PATIENT}`}
		</Link>
	);
}

export default ReturnToCaseButton;
