import { Feature, isFeatureEnabled } from 'lib/feature';
import { selectAuthUser } from 'store/auth';
import { connect } from 'react-redux';
import AwsDictationButton from 'display/SemanticUI/Dictation/AwsDictationButton';
import DictationTipsButton from 'display/SemanticUI/Dictation/DictationTipsButton';
import React from 'react';
import { AppState } from 'store/types';
import { reportEvent } from 'api/common';
import { EventType, ObjectType } from 'thriftgen/api_types';
import { useConsultContext } from 'contexts';
import { useDictationContext } from 'contexts/DictationContext';

interface StoreProps {
	isSitkaMedicalAssociate: boolean;
	isSitkaOperations: boolean;
}

interface ConsumerProps {
	onTranscribeStart: () => void;
	onTranscribeStop: () => void;
}

type DictationButtonSectionProps = ConsumerProps & StoreProps;

function DictationButtonSection({
	onTranscribeStart,
	onTranscribeStop,
	isSitkaMedicalAssociate,
	isSitkaOperations
}: DictationButtonSectionProps) {
	const { dictationResults } = useDictationContext();
	let onTranscriptionStarted: () => void;
	let onTranscriptionStopped: () => void;

	try {
		const consultContextValue = useConsultContext();
		const { consult } = consultContextValue;
		onTranscriptionStarted = () => {
			reportEvent({
				event: {
					eventType: EventType.DICTATION_STARTED
				},
				objectId: consult.consult_id,
				objectType: ObjectType.CONSULT
			});
			onTranscribeStart();
		};

		onTranscriptionStopped = () => {
			reportEvent({
				event: {
					action: { transcript: dictationResults?.transcript },
					eventType: EventType.DICTATION_STOPPED
				},
				objectId: consult.consult_id,
				objectType: ObjectType.CONSULT
			});
			onTranscribeStop();
		};
	} catch (e) {
		onTranscriptionStarted = () => {
			onTranscribeStart();
		};

		onTranscriptionStopped = () => {
			onTranscribeStop();
		};
	}

	function getButtons(): JSX.Element[] {
		const components: JSX.Element[] = [];

		if (
			isFeatureEnabled(Feature.CWA_1051_IMPLEMENT_DICTATION_SPEECH_TO_TEXT) &&
			(isSitkaMedicalAssociate || isSitkaOperations)
		) {
			components.push(
				<AwsDictationButton
					key="dictation"
					onTranscribeStart={onTranscriptionStarted}
					onTranscribeStop={onTranscriptionStopped}
				/>
			);
			components.push(<DictationTipsButton key="dictationTips" />);
		}

		return components;
	}

	return <>{getButtons()}</>;
}

const mapStoreToProps = (store: AppState): StoreProps => {
	const actor = selectAuthUser(store);
	return {
		isSitkaMedicalAssociate: actor.is_sitka_medical_associate,
		isSitkaOperations: actor.is_sitka_operations
	};
};

export default connect(mapStoreToProps)(DictationButtonSection);
