import {
	ActionComponent,
	ActionMenuDropdown,
	DocumentDownloadAction,
	DocumentRedactAction,
	DocumentRestoreAction
} from 'components/ActionMenuDropdown';
import { useFileViewerModalContext } from 'contexts';
import { DocumentDataAsset } from 'lib/documentAssets';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import DocumentAssetItem from './DocumentAssetItem';

interface StoreProps {
	isSitkaOperations: boolean;
}

interface ConsumerProps {
	assets: DocumentDataAsset[];
	canRedactDocuments: boolean;
}

type DocumentAssetListProps = StoreProps & ConsumerProps;

function DocumentAssetList({
	assets,
	canRedactDocuments,
	isSitkaOperations
}: DocumentAssetListProps): JSX.Element {
	const { setDefaultDocumentById } = useFileViewerModalContext();

	function getAction(asset: DocumentDataAsset) {
		const actionComponents: ActionComponent[] = [];
		if (asset.downloadUrl) {
			actionComponents.push(DocumentDownloadAction);
		}
		if (isSitkaOperations && canRedactDocuments) {
			if (asset.redacted_reason) {
				actionComponents.push(DocumentRestoreAction);
			} else {
				actionComponents.push(DocumentRedactAction);
			}
		}
		return <ActionMenuDropdown actionComponents={actionComponents} data={asset} />;
	}

	function renderAsset(asset: DocumentDataAsset): JSX.Element {
		return (
			<DocumentAssetItem
				key={asset.id}
				asset={asset}
				action={getAction(asset)}
				onClick={setDefaultDocumentById}
			/>
		);
	}

	return <React.Fragment>{assets.map(renderAsset)}</React.Fragment>;
}

const mapStoreToProps = (store: AppState): StoreProps => {
	const actor = selectAuthUser(store);
	return {
		isSitkaOperations: actor.is_sitka_operations
	};
};

export default connect(mapStoreToProps)(DocumentAssetList);
