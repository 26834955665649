import reducerRegistry from 'store/registry';
import { Consult } from 'thriftgen/api_types';
import { ConsultActions, ConsultActionType, ConsultsById, ConsultsState } from './consults.types';

const INITIAL_STATE: ConsultsState = {};

function consultsById(consults: Consult[]): ConsultsById {
	const byId = {};

	consults.forEach((consult: Consult) => {
		byId[consult.consult_id] = consult;
	});

	return byId;
}

function setConsults(_: ConsultsState, consults: Consult[]): ConsultsById {
	return consultsById(consults);
}

export function consultsReducer(
	state: ConsultsState = INITIAL_STATE,
	action: ConsultActions
): ConsultsById {
	switch (action.type) {
		case ConsultActionType.SET_CONSULTS:
			return setConsults(state, action.consults);

		default:
			return state;
	}
}

reducerRegistry.register('consults', consultsReducer);
