import * as React from 'react';
import { DataFormFieldComponent } from 'components/Forms/DataFormTypes';
import buildCheckboxField from './base/buildCheckboxField';

const TERMS_URL = 'https://www.trustsitka.com/vmessages-terms-of-use';
const FIELD_NAME = 'termsVMessages';
const FIELD_LABEL: JSX.Element = (
	<label>
		I understand and agree to the{' '}
		<a
			href={TERMS_URL}
			onClick={event => {
				event.stopPropagation();
			}}
			target="_blank"
			rel="noreferrer">
			Terms and Conditions
		</a>
		.
	</label>
);

function validate(value: string | undefined, required: boolean): string[] | null {
	if (required && !value) {
		return ['Please accept the terms and conditions.'];
	}

	return null;
}

const TermsAndConditionsVMessages: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: FIELD_LABEL,
	validate
});

export default TermsAndConditionsVMessages;
