import { useOktaAuth } from '@okta/okta-react';
import { useSessionIntegrationSettings } from 'hooks/sessionIntegrationSettings';
import { sitkaOktaAuthRedirectPathStorageKey } from 'lib/okta';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import { localStorage as LocalStorage } from 'lib/Storage';
import React, { useCallback, useMemo } from 'react';
import RouteUrls from 'routing/RouteUrls';
import { IntegrationType } from 'thriftgen/api_types';

const REDIRECT_URL_DEFAULT = '/';

interface UseSSORedirectPathProps {
	referrerUrlPathIgnore?: string;
}
interface UseSSORedirectPathResponse {
	redirectPath: string;
	forceReauthentication?: boolean;
}

function useSSORedirectPath({
	referrerUrlPathIgnore
}: UseSSORedirectPathProps): UseSSORedirectPathResponse {
	const { oktaAuth } = useOktaAuth();

	const sessionIntegrationSettings = useSessionIntegrationSettings();

	React.useEffect(() => {
		SitkaLogger.logMessage(
			`useSSORedirectPath - sessionIntegrationSettings: ${JSON.stringify(
				sessionIntegrationSettings
			)}`,
			LogEventName.SSO
		);
	}, [sessionIntegrationSettings]);

	const isAthenaSSOLogin = useCallback((): boolean => {
		return sessionIntegrationSettings?.integrationType === IntegrationType.ATHENA;
	}, [sessionIntegrationSettings]);

	const useSSORedirectPathResponse: UseSSORedirectPathResponse = useMemo(() => {
		SitkaLogger.logMessage(
			`useSSORedirectPath - OriginalUri: ${oktaAuth.getOriginalUri()}`,
			LogEventName.SSO
		);

		const redirectPathFromForcedReauthentiation = LocalStorage.get(
			sitkaOktaAuthRedirectPathStorageKey
		);

		const referrerUrlPath =
			(oktaAuth &&
				oktaAuth.getOriginalUri()?.replace(window.location.origin.toString(), '')) ||
			redirectPathFromForcedReauthentiation;

		LocalStorage.remove(sitkaOktaAuthRedirectPathStorageKey);

		SitkaLogger.logMessage(
			`useSSORedirectPath - referrerUrlPath: ${referrerUrlPath}`,
			LogEventName.SSO
		);

		if (isAthenaSSOLogin()) {
			const athenaConsultRequestUrl = RouteUrls.buildUrl(
				RouteUrls.Key.SSO_ATHENA_CONSULT_REQUEST
			);
			SitkaLogger.logMessage(
				`useSSORedirectPath - is Athena Login, redirect to: ${athenaConsultRequestUrl}`,
				LogEventName.SSO
			);
			return {
				redirectPath: athenaConsultRequestUrl,
				forceReauthentication: true
			};
		} else if (referrerUrlPath && referrerUrlPath !== referrerUrlPathIgnore) {
			SitkaLogger.logMessage(
				`useSSORedirectPath - referrerUrlPath Set: ${referrerUrlPath}`,
				LogEventName.SSO
			);
			return {
				redirectPath: referrerUrlPath,
				forceReauthentication: sessionIntegrationSettings?.forceReauthentication
			};
		} else {
			SitkaLogger.logMessage(
				`useSSORedirectPath - Redirect to default homepage: ${REDIRECT_URL_DEFAULT}`,
				LogEventName.SSO
			);
			return {
				redirectPath: REDIRECT_URL_DEFAULT,
				forceReauthentication: sessionIntegrationSettings?.forceReauthentication
			};
		}
	}, [
		isAthenaSSOLogin,
		oktaAuth,
		referrerUrlPathIgnore,
		sessionIntegrationSettings?.forceReauthentication
	]);

	return useSSORedirectPathResponse;
}

export { useSSORedirectPath };
