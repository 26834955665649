import * as React from 'react';

import { Button } from 'display';

function StartRecordingButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Primary className="startRecordingButton" onClick={onClick}>
			Start
		</Button.Primary>
	);
}

export default StartRecordingButton;
