import * as React from 'react';
import { Button, ButtonType } from 'display';
import RouteUrls from 'routing/RouteUrls';
import LinkButton from './LinkButton';
import Strings from 'lib/strings';

interface LinkToAddCaseDataButtonProps {
	caseId: string;
}

function LinkToAddCaseDataButton({ caseId }: LinkToAddCaseDataButtonProps): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.OUTLINE}
			link={RouteUrls.buildUrl(RouteUrls.Key.CASE_ADD_DATA, { caseId })}
			text={Strings.Buttons.ADD_DOCUMENT}
			size={Button.Size.TINY}
			classNames="addCaseDataButton"
		/>
	);
}

export default LinkToAddCaseDataButton;
