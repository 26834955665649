import { PartnerLoadError, PartnersLoadError } from 'components/Messages';
import withApiCall from 'components/withApiCall/withApiCall';
import { Loader } from 'display';
import * as React from 'react';
import { FAILURE, INACTIVE, IN_PROGRESS, SUCCESS } from 'store/actionStatus';
import { SendGetPartnersAction } from 'store/actionTypes';
import { sendGetPartners } from 'store/admin';
import { GetPartnersResponse, Partner } from 'thriftgen/api_types';

interface RequestPartnersProps {
	children: JSX.Element;
	/**
	 * Partner ID to request specific partner, otherwise
	 * fetches all.
	 */
	partnerId?: Partner['partner_id'];
	sendGetPartners: (args?: SendGetPartnersAction) => Promise<GetPartnersResponse>;
}

function RequestPartners({
	children,
	partnerId,
	request,
	...props
}: RequestPartnersProps & WithApiCallProps): JSX.Element {
	React.useEffect(() => {
		if (partnerId) {
			props.sendGetPartners({ partnerIds: [partnerId] });
		} else {
			props.sendGetPartners();
		}
	}, [partnerId]);

	const { status, message } = request.data;

	switch (status) {
		case FAILURE:
			if (partnerId) {
				return <PartnerLoadError message={message} />;
			}

			return <PartnersLoadError message={message} />;

		case SUCCESS:
			return children;

		case INACTIVE:
		case IN_PROGRESS:
		default:
			return <Loader active={true} />;
	}
}

export default withApiCall({ sendGetPartners }, RequestPartners);
