import { usePaginationContext } from 'contexts/PaginationContext';
import { FilterField, FilterPopoverDropdown } from 'display';
import * as React from 'react';

interface PaginationFilterPopoverDropdownProps {
	filters: FilterField[];
}

function PaginationFilterPopoverDropdown({
	filters
}: PaginationFilterPopoverDropdownProps): JSX.Element {
	const { parameters, setAllFilters } = usePaginationContext();

	return (
		<FilterPopoverDropdown
			filters={filters}
			selectedFilters={parameters.filters}
			onChange={setAllFilters}
		/>
	);
}

export default PaginationFilterPopoverDropdown;
