import Title from './Title';
import { buildFieldVariation } from '../helpers';

const FIELD_NAME = 'title';
const LABEL = 'Your title';

export default buildFieldVariation({
	Field: Title,
	fieldName: FIELD_NAME,
	label: LABEL
});
