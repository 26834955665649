import * as React from 'react';

import { Button } from 'display';

interface SettingsIconButtonProps {
	onClick: React.MouseEventHandler;
}

function SettingsIconButton({ onClick }: SettingsIconButtonProps): JSX.Element {
	return (
		<Button.Icon
			icon="settings"
			size={Button.IconSize.LARGE}
			inverted={true}
			onClick={onClick}
			title="Settings"
		/>
	);
}

export default SettingsIconButton;
