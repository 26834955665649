import { CheckboxProps, Form, Tooltip } from 'display';
import { css } from 'emotion';
import { translateThriftEnum } from 'lib/i18n';
import noop from 'lib/noop';
import * as React from 'react';
import { SpecialistResponseType, SPECIALIST_RESPONSE_TYPE } from 'thriftgen/api_types.js';

const FIELD_NAME = 'specialistResponseType';
const LABEL = 'Consult Type';

function translateResponseType(responseType: string | SpecialistResponseType): string {
	return translateThriftEnum(responseType, SPECIALIST_RESPONSE_TYPE);
}

const OPTIONS: Array<[string | SpecialistResponseType, string]> = Object.values(
	SpecialistResponseType
).map(responseType => {
	return [responseType, translateResponseType(responseType)];
});

const styles = {
	tooltipContent: css`
		white-space: nowrap;
	`,
	radioButton: css`
		margin-bottom: 0 !important;
	`
};

interface SpecialistResponseTypeProps {
	data: {
		[FIELD_NAME]?: SpecialistResponseType;
	};
	editableFields: string[];
	errors: {
		fields: string[];
		messages: string[];
	};
	onChange: (event: React.SyntheticEvent<HTMLInputElement>, data: CheckboxProps) => void;
}

function SpecialistResponseTypeRadio({
	data,
	editableFields,
	errors,
	onChange = noop
}: SpecialistResponseTypeProps): JSX.Element | null {
	const value = data[FIELD_NAME];
	const hasError = errors.fields.includes(FIELD_NAME);
	const canEdit = editableFields.includes(FIELD_NAME);

	function onValueChange(
		event: React.FormEvent<HTMLInputElement>,
		eventData: CheckboxProps
	): void {
		if (eventData.checked) {
			onChange(event, {
				name: FIELD_NAME,
				value: Number(eventData.value)
			});
		}
	}

	if (canEdit) {
		return (
			<Form.Field error={hasError}>
				<label>
					{LABEL}
					<Tooltip>
						<div className={styles.tooltipContent}>
							Diagnostic = identification of a new condition(s)
						</div>
						<div className={styles.tooltipContent}>
							Management = intervention(s) for an existing condition
						</div>
					</Tooltip>
				</label>
				{OPTIONS.map(([option, label]) => {
					const id = `${FIELD_NAME}_${option}`;
					return (
						<Form.Radio
							id={id}
							label={label}
							name={FIELD_NAME}
							key={id}
							value={option}
							checked={value === option}
							onChange={onValueChange}
							error={hasError}
							className={styles.radioButton}
						/>
					);
				})}
			</Form.Field>
		);
	}

	if (value !== undefined) {
		return (
			<Form.Field>
				<label>{LABEL}</label>
				<span>{translateResponseType(value)}</span>
			</Form.Field>
		);
	}

	return null;
}

function validate(data: SpecialistResponseTypeProps['data'], required: boolean) {
	const nameField = data[FIELD_NAME];

	if (required && !nameField) {
		return {
			fields: [FIELD_NAME],
			messages: ['Please choose a consult type.']
		};
	}

	return null;
}

SpecialistResponseTypeRadio.FIELD_NAME = FIELD_NAME;
SpecialistResponseTypeRadio.validate = validate;

export default SpecialistResponseTypeRadio;
