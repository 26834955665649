import * as React from 'react';

import { Message, Paragraph } from 'display';

function UnchangedFormMessage(): JSX.Element {
	return (
		<Message info={true}>
			<Paragraph>Looks like you tried to submit without making any changes.</Paragraph>
		</Message>
	);
}

export default UnchangedFormMessage;
