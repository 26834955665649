import * as React from 'react';

import { Button, ButtonProps } from 'display';
import Strings from 'lib/strings';

interface ShareWithPatientButtonProps {
	disabled?: ButtonProps['disabled'];
	onClickAsync: ButtonProps['onClickAsync'];
}

function ShareWithPatientButton({
	disabled = false,
	onClickAsync
}: ShareWithPatientButtonProps): JSX.Element {
	return (
		<Button.Primary disabled={disabled} onClickAsync={onClickAsync}>
			{Strings.Buttons.SHARE_WITH_PATIENT}
		</Button.Primary>
	);
}

export default ShareWithPatientButton;
