//
// Autogenerated by Thrift Compiler (0.12.0)
//
// DO NOT EDIT UNLESS YOU ARE SURE THAT YOU KNOW WHAT YOU ARE DOING
//
"use strict";

var thrift = require('thrift');
var Thrift = thrift.Thrift;
var Q = thrift.Q;


var ttypes = require('./api_types');
//HELPER FUNCTIONS AND STRUCTURES

var AdminService_update_partner_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdatePartnerRequest(args.request);
    }
  }
};
AdminService_update_partner_args.prototype = {};
AdminService_update_partner_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdatePartnerRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_update_partner_args.prototype.write = function(output) {
  output.writeStructBegin('AdminService_update_partner_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_update_partner_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.UpdatePartnerResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AdminService_update_partner_result.prototype = {};
AdminService_update_partner_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.UpdatePartnerResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_update_partner_result.prototype.write = function(output) {
  output.writeStructBegin('AdminService_update_partner_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_create_partner_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreatePartnerRequest(args.request);
    }
  }
};
AdminService_create_partner_args.prototype = {};
AdminService_create_partner_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreatePartnerRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_create_partner_args.prototype.write = function(output) {
  output.writeStructBegin('AdminService_create_partner_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_create_partner_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  this.name_already_registered = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args instanceof ttypes.NameAlreadyRegistered) {
    this.name_already_registered = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CreatePartnerResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
    if (args.name_already_registered !== undefined && args.name_already_registered !== null) {
      this.name_already_registered = args.name_already_registered;
    }
  }
};
AdminService_create_partner_result.prototype = {};
AdminService_create_partner_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CreatePartnerResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.name_already_registered = new ttypes.NameAlreadyRegistered();
        this.name_already_registered.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_create_partner_result.prototype.write = function(output) {
  output.writeStructBegin('AdminService_create_partner_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  if (this.name_already_registered !== null && this.name_already_registered !== undefined) {
    output.writeFieldBegin('name_already_registered', Thrift.Type.STRUCT, 2);
    this.name_already_registered.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_create_user_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.CreateUserRequest(args.request);
    }
  }
};
AdminService_create_user_args.prototype = {};
AdminService_create_user_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.CreateUserRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_create_user_args.prototype.write = function(output) {
  output.writeStructBegin('AdminService_create_user_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_create_user_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  this.email_already_registered = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args instanceof ttypes.EmailAlreadyRegistered) {
    this.email_already_registered = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.CreateUserResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
    if (args.email_already_registered !== undefined && args.email_already_registered !== null) {
      this.email_already_registered = args.email_already_registered;
    }
  }
};
AdminService_create_user_result.prototype = {};
AdminService_create_user_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.CreateUserResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 2:
      if (ftype == Thrift.Type.STRUCT) {
        this.email_already_registered = new ttypes.EmailAlreadyRegistered();
        this.email_already_registered.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_create_user_result.prototype.write = function(output) {
  output.writeStructBegin('AdminService_create_user_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  if (this.email_already_registered !== null && this.email_already_registered !== undefined) {
    output.writeFieldBegin('email_already_registered', Thrift.Type.STRUCT, 2);
    this.email_already_registered.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_merge_cases_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.MergeCasesRequest(args.request);
    }
  }
};
AdminService_merge_cases_args.prototype = {};
AdminService_merge_cases_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.MergeCasesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_merge_cases_args.prototype.write = function(output) {
  output.writeStructBegin('AdminService_merge_cases_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_merge_cases_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.MergeCasesResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AdminService_merge_cases_result.prototype = {};
AdminService_merge_cases_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.MergeCasesResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_merge_cases_result.prototype.write = function(output) {
  output.writeStructBegin('AdminService_merge_cases_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_get_consult_summaries_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.GetConsultSummariesRequest(args.request);
    }
  }
};
AdminService_get_consult_summaries_args.prototype = {};
AdminService_get_consult_summaries_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.GetConsultSummariesRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_get_consult_summaries_args.prototype.write = function(output) {
  output.writeStructBegin('AdminService_get_consult_summaries_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_get_consult_summaries_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.GetConsultSummariesResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AdminService_get_consult_summaries_result.prototype = {};
AdminService_get_consult_summaries_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.GetConsultSummariesResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_get_consult_summaries_result.prototype.write = function(output) {
  output.writeStructBegin('AdminService_get_consult_summaries_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_update_consult_summary_args = function(args) {
  this.request = null;
  if (args) {
    if (args.request !== undefined && args.request !== null) {
      this.request = new ttypes.UpdateConsultSummaryRequest(args.request);
    }
  }
};
AdminService_update_consult_summary_args.prototype = {};
AdminService_update_consult_summary_args.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.request = new ttypes.UpdateConsultSummaryRequest();
        this.request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 0:
        input.skip(ftype);
        break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_update_consult_summary_args.prototype.write = function(output) {
  output.writeStructBegin('AdminService_update_consult_summary_args');
  if (this.request !== null && this.request !== undefined) {
    output.writeFieldBegin('request', Thrift.Type.STRUCT, 1);
    this.request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminService_update_consult_summary_result = function(args) {
  this.success = null;
  this.invalid_request = null;
  if (args instanceof ttypes.InvalidRequest) {
    this.invalid_request = args;
    return;
  }
  if (args) {
    if (args.success !== undefined && args.success !== null) {
      this.success = new ttypes.UpdateConsultSummaryResponse(args.success);
    }
    if (args.invalid_request !== undefined && args.invalid_request !== null) {
      this.invalid_request = args.invalid_request;
    }
  }
};
AdminService_update_consult_summary_result.prototype = {};
AdminService_update_consult_summary_result.prototype.read = function(input) {
  input.readStructBegin();
  while (true) {
    var ret = input.readFieldBegin();
    var ftype = ret.ftype;
    var fid = ret.fid;
    if (ftype == Thrift.Type.STOP) {
      break;
    }
    switch (fid) {
      case 0:
      if (ftype == Thrift.Type.STRUCT) {
        this.success = new ttypes.UpdateConsultSummaryResponse();
        this.success.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      case 1:
      if (ftype == Thrift.Type.STRUCT) {
        this.invalid_request = new ttypes.InvalidRequest();
        this.invalid_request.read(input);
      } else {
        input.skip(ftype);
      }
      break;
      default:
        input.skip(ftype);
    }
    input.readFieldEnd();
  }
  input.readStructEnd();
  return;
};

AdminService_update_consult_summary_result.prototype.write = function(output) {
  output.writeStructBegin('AdminService_update_consult_summary_result');
  if (this.success !== null && this.success !== undefined) {
    output.writeFieldBegin('success', Thrift.Type.STRUCT, 0);
    this.success.write(output);
    output.writeFieldEnd();
  }
  if (this.invalid_request !== null && this.invalid_request !== undefined) {
    output.writeFieldBegin('invalid_request', Thrift.Type.STRUCT, 1);
    this.invalid_request.write(output);
    output.writeFieldEnd();
  }
  output.writeFieldStop();
  output.writeStructEnd();
  return;
};

var AdminServiceClient = exports.Client = function(output, pClass) {
  this.output = output;
  this.pClass = pClass;
  this._seqid = 0;
  this._reqs = {};
};
AdminServiceClient.prototype = {};
AdminServiceClient.prototype.seqid = function() { return this._seqid; };
AdminServiceClient.prototype.new_seqid = function() { return this._seqid += 1; };

AdminServiceClient.prototype.update_partner = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_update_partner(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_update_partner(request);
  }
};

AdminServiceClient.prototype.send_update_partner = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AdminService_update_partner_args(params);
  try {
    output.writeMessageBegin('update_partner', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AdminServiceClient.prototype.recv_update_partner = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AdminService_update_partner_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('update_partner failed: unknown result');
};

AdminServiceClient.prototype.create_partner = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_create_partner(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_create_partner(request);
  }
};

AdminServiceClient.prototype.send_create_partner = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AdminService_create_partner_args(params);
  try {
    output.writeMessageBegin('create_partner', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AdminServiceClient.prototype.recv_create_partner = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AdminService_create_partner_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.name_already_registered) {
    return callback(result.name_already_registered);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('create_partner failed: unknown result');
};

AdminServiceClient.prototype.create_user = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_create_user(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_create_user(request);
  }
};

AdminServiceClient.prototype.send_create_user = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AdminService_create_user_args(params);
  try {
    output.writeMessageBegin('create_user', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AdminServiceClient.prototype.recv_create_user = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AdminService_create_user_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.email_already_registered) {
    return callback(result.email_already_registered);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('create_user failed: unknown result');
};

AdminServiceClient.prototype.merge_cases = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_merge_cases(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_merge_cases(request);
  }
};

AdminServiceClient.prototype.send_merge_cases = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AdminService_merge_cases_args(params);
  try {
    output.writeMessageBegin('merge_cases', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AdminServiceClient.prototype.recv_merge_cases = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AdminService_merge_cases_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('merge_cases failed: unknown result');
};

AdminServiceClient.prototype.get_consult_summaries = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_get_consult_summaries(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_get_consult_summaries(request);
  }
};

AdminServiceClient.prototype.send_get_consult_summaries = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AdminService_get_consult_summaries_args(params);
  try {
    output.writeMessageBegin('get_consult_summaries', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AdminServiceClient.prototype.recv_get_consult_summaries = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AdminService_get_consult_summaries_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('get_consult_summaries failed: unknown result');
};

AdminServiceClient.prototype.update_consult_summary = function(request, callback) {
  this._seqid = this.new_seqid();
  if (callback === undefined) {
    var _defer = Q.defer();
    this._reqs[this.seqid()] = function(error, result) {
      if (error) {
        _defer.reject(error);
      } else {
        _defer.resolve(result);
      }
    };
    this.send_update_consult_summary(request);
    return _defer.promise;
  } else {
    this._reqs[this.seqid()] = callback;
    this.send_update_consult_summary(request);
  }
};

AdminServiceClient.prototype.send_update_consult_summary = function(request) {
  var output = new this.pClass(this.output);
  var params = {
    request: request
  };
  var args = new AdminService_update_consult_summary_args(params);
  try {
    output.writeMessageBegin('update_consult_summary', Thrift.MessageType.CALL, this.seqid());
    args.write(output);
    output.writeMessageEnd();
    return this.output.flush();
  }
  catch (e) {
    delete this._reqs[this.seqid()];
    if (typeof output.reset === 'function') {
      output.reset();
    }
    throw e;
  }
};

AdminServiceClient.prototype.recv_update_consult_summary = function(input,mtype,rseqid) {
  var callback = this._reqs[rseqid] || function() {};
  delete this._reqs[rseqid];
  if (mtype == Thrift.MessageType.EXCEPTION) {
    var x = new Thrift.TApplicationException();
    x.read(input);
    input.readMessageEnd();
    return callback(x);
  }
  var result = new AdminService_update_consult_summary_result();
  result.read(input);
  input.readMessageEnd();

  if (null !== result.invalid_request) {
    return callback(result.invalid_request);
  }
  if (null !== result.success) {
    return callback(null, result.success);
  }
  return callback('update_consult_summary failed: unknown result');
};
var AdminServiceProcessor = exports.Processor = function(handler) {
  this._handler = handler;
};
AdminServiceProcessor.prototype.process = function(input, output) {
  var r = input.readMessageBegin();
  if (this['process_' + r.fname]) {
    return this['process_' + r.fname].call(this, r.rseqid, input, output);
  } else {
    input.skip(Thrift.Type.STRUCT);
    input.readMessageEnd();
    var x = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN_METHOD, 'Unknown function ' + r.fname);
    output.writeMessageBegin(r.fname, Thrift.MessageType.EXCEPTION, r.rseqid);
    x.write(output);
    output.writeMessageEnd();
    output.flush();
  }
};
AdminServiceProcessor.prototype.process_update_partner = function(seqid, input, output) {
  var args = new AdminService_update_partner_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.update_partner.length === 1) {
    Q.fcall(this._handler.update_partner.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AdminService_update_partner_result({success: result});
      output.writeMessageBegin("update_partner", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AdminService_update_partner_result(err);
        output.writeMessageBegin("update_partner", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_partner", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.update_partner(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AdminService_update_partner_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("update_partner", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_partner", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AdminServiceProcessor.prototype.process_create_partner = function(seqid, input, output) {
  var args = new AdminService_create_partner_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.create_partner.length === 1) {
    Q.fcall(this._handler.create_partner.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AdminService_create_partner_result({success: result});
      output.writeMessageBegin("create_partner", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest || err instanceof ttypes.NameAlreadyRegistered) {
        result = new AdminService_create_partner_result(err);
        output.writeMessageBegin("create_partner", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_partner", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.create_partner(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest || err instanceof ttypes.NameAlreadyRegistered) {
        result_obj = new AdminService_create_partner_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("create_partner", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_partner", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AdminServiceProcessor.prototype.process_create_user = function(seqid, input, output) {
  var args = new AdminService_create_user_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.create_user.length === 1) {
    Q.fcall(this._handler.create_user.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AdminService_create_user_result({success: result});
      output.writeMessageBegin("create_user", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest || err instanceof ttypes.EmailAlreadyRegistered) {
        result = new AdminService_create_user_result(err);
        output.writeMessageBegin("create_user", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_user", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.create_user(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest || err instanceof ttypes.EmailAlreadyRegistered) {
        result_obj = new AdminService_create_user_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("create_user", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("create_user", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AdminServiceProcessor.prototype.process_merge_cases = function(seqid, input, output) {
  var args = new AdminService_merge_cases_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.merge_cases.length === 1) {
    Q.fcall(this._handler.merge_cases.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AdminService_merge_cases_result({success: result});
      output.writeMessageBegin("merge_cases", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AdminService_merge_cases_result(err);
        output.writeMessageBegin("merge_cases", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("merge_cases", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.merge_cases(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AdminService_merge_cases_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("merge_cases", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("merge_cases", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AdminServiceProcessor.prototype.process_get_consult_summaries = function(seqid, input, output) {
  var args = new AdminService_get_consult_summaries_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.get_consult_summaries.length === 1) {
    Q.fcall(this._handler.get_consult_summaries.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AdminService_get_consult_summaries_result({success: result});
      output.writeMessageBegin("get_consult_summaries", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AdminService_get_consult_summaries_result(err);
        output.writeMessageBegin("get_consult_summaries", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consult_summaries", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.get_consult_summaries(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AdminService_get_consult_summaries_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("get_consult_summaries", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("get_consult_summaries", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
AdminServiceProcessor.prototype.process_update_consult_summary = function(seqid, input, output) {
  var args = new AdminService_update_consult_summary_args();
  args.read(input);
  input.readMessageEnd();
  if (this._handler.update_consult_summary.length === 1) {
    Q.fcall(this._handler.update_consult_summary.bind(this._handler),
      args.request
    ).then(function(result) {
      var result_obj = new AdminService_update_consult_summary_result({success: result});
      output.writeMessageBegin("update_consult_summary", Thrift.MessageType.REPLY, seqid);
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    }).catch(function (err) {
      var result;
      if (err instanceof ttypes.InvalidRequest) {
        result = new AdminService_update_consult_summary_result(err);
        output.writeMessageBegin("update_consult_summary", Thrift.MessageType.REPLY, seqid);
      } else {
        result = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_consult_summary", Thrift.MessageType.EXCEPTION, seqid);
      }
      result.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  } else {
    this._handler.update_consult_summary(args.request, function (err, result) {
      var result_obj;
      if ((err === null || typeof err === 'undefined') || err instanceof ttypes.InvalidRequest) {
        result_obj = new AdminService_update_consult_summary_result((err !== null || typeof err === 'undefined') ? err : {success: result});
        output.writeMessageBegin("update_consult_summary", Thrift.MessageType.REPLY, seqid);
      } else {
        result_obj = new Thrift.TApplicationException(Thrift.TApplicationExceptionType.UNKNOWN, err.message);
        output.writeMessageBegin("update_consult_summary", Thrift.MessageType.EXCEPTION, seqid);
      }
      result_obj.write(output);
      output.writeMessageEnd();
      output.flush();
    });
  }
};
