import * as React from 'react';

import { Button, ButtonProps, ButtonType } from 'display';
import Strings from 'lib/strings';

interface CancelButtonProps {
	buttonType?: ButtonType;
	disabled?: ButtonProps['disabled'];
	onClick: ButtonProps['onClick'];
}

function CancelButton({
	buttonType = ButtonType.MINIMAL,
	disabled = false,
	onClick
}: CancelButtonProps): JSX.Element {
	const ButtonVariation = Button[buttonType];

	return (
		<ButtonVariation disabled={disabled} onClick={onClick}>
			{Strings.Buttons.CANCEL}
		</ButtonVariation>
	);
}

export default CancelButton;
