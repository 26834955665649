import { css, cx } from 'emotion';
import * as React from 'react';
import { Segment as SemanticSegment, SegmentGroupProps } from 'semantic-ui-react';

const styles = {
	basicGroup: css`
		border: 0 !important;
		box-shadow: none !important;
	`
};

interface OwnGroupProps {
	basic?: boolean;
}
type ShimGroupProps = SegmentGroupProps & OwnGroupProps;

function SegmentGroup({ className, basic, ...rest }: ShimGroupProps): JSX.Element {
	const classNames = [];

	if (basic) {
		classNames.push(styles.basicGroup);
	}

	return (
		<SemanticSegment.Group
			className={cx(...classNames, className)}
			{...rest}></SemanticSegment.Group>
	);
}

export default SegmentGroup;
