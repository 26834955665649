import { AudioChannelOptions, AudioEventOptions, AudibleInterface, AudioIssueType } from './types';

function getAudioIssue(
	options: AudioChannelOptions,
	maxClipCount: number,
	maxRms: number
): AudioIssueType {
	if (getdBFS(maxRms) < options.lowVolumeThreshold) {
		return AudioIssueType.AUDIO_TOO_LOW;
	} else if (maxClipCount > options.clipCountThreshold) {
		return AudioIssueType.AUDIO_TOO_HIGH;
	} else {
		return AudioIssueType.CLEAN_AUDIO;
	}
}

function getdBFS(gain: number): number {
	const dB = (20 * Math.log(gain)) / Math.log(10);
	return Math.round(dB * 10) / 10;
}

// inspired by https://github.com/webrtc/testrtc/blob/master/src/js/mictest.js#L156
function hasAudibleChannel(options: AudioChannelOptions): AudibleInterface {
	let maxPeak = 0.0;
	let maxRms = 0.0;
	let clipCount = 0;
	let maxClipCount = 0;

	for (let j = 0; j < options.buffers.length; j++) {
		const samples: number[] = options.buffers[j];

		if (samples && samples.length > 0) {
			let s = 0;
			let rms = 0.0;

			for (let i = 0; i < samples.length; i++) {
				s = Math.abs(samples[i]);
				maxPeak = Math.max(maxPeak, s);
				rms += s * s;
				if (maxPeak >= options.clipThreshold) {
					clipCount++;
					maxClipCount = Math.max(maxClipCount, clipCount);
				}
			}

			// RMS is calculated over each buffer, meaning the integration time will
			// be different depending on sample rate and buffer size. In practice
			// this should be a small problem.
			rms = Math.sqrt(rms / samples.length);
			maxRms = Math.max(maxRms, rms);
		}
	}

	if (maxPeak > options.silentThreshold) {
		return {
			isAudible: true,
			audioIssue: getAudioIssue(options, maxClipCount, maxRms)
		};
	}

	return { isAudible: false };
}

function processEvent(options: AudioEventOptions): any[] {
	const sampleCount: number = options.audioEvent.inputBuffer.length;
	for (let c = 0; c < options.audioEvent.inputBuffer.numberOfChannels; c++) {
		const data: Float32Array = options.audioEvent.inputBuffer.getChannelData(c);
		const first: number = Math.abs(data[0]);
		const last: number = Math.abs(data[sampleCount - 1]);
		let newBuffer: Float32Array;
		if (first > options.silentThreshold || last > options.silentThreshold) {
			// Non-silent buffers are copied for analysis. Note that the silent
			// detection will likely cause the stored stream to contain discontinu-
			// ities, but that is ok for our needs here (just looking at levels).
			newBuffer = new Float32Array(sampleCount);
			newBuffer.set(data);
		} else {
			// Silent buffers are not copied, but we store empty buffers so that the
			// analysis doesn't have to care.
			newBuffer = new Float32Array();
		}
		options.collectedAudio[c].push(newBuffer);
	}
	return options.collectedAudio;
}

function clearSamples(audioArray: any[], inputChannelCount: number): any[] {
	for (let i = 0; i < inputChannelCount; ++i) {
		audioArray[i] = [];
	}
	return audioArray;
}

export { clearSamples, getAudioIssue, getdBFS, hasAudibleChannel, processEvent };
