import { camelToSnake, snakeToCamel } from './stringConverter';
import isObject from './isObject';

function traverse(object, converter) {
	let result = Object.create(object);

	Object.entries(object).forEach(([key, value]) => {
		if (isObject(value)) {
			value = traverse(value, converter);
		} else if (Array.isArray(value)) {
			value = value.map(item => (isObject(item) ? traverse(item, converter) : item));
		}

		result[converter(key)] = value;
	});

	return result;
}

function objectSnakeToCamel(object) {
	return traverse(object, snakeToCamel);
}

function objectCamelToSnake(object) {
	return traverse(object, camelToSnake);
}

export { objectSnakeToCamel, objectCamelToSnake };
