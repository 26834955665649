import Strings from 'lib/strings';
import { DataFormFieldComponent } from '../DataFormTypes';
import buildDatePickerField from './base/buildDatePickerField';

const FIELD_NAME = 'endDate';
const LABEL = Strings.Labels.END_DATE;

const EndDate: DataFormFieldComponent = buildDatePickerField({
	fieldName: FIELD_NAME,
	label: LABEL
});

export default EndDate;
