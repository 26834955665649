import * as React from 'react';

import RouteUrls from 'routing/RouteUrls';

import { ButtonType } from 'display';
import LinkButton, { LinkButtonProps } from './LinkButton';
import Strings from 'lib/strings';

function LinkToLogoutButton({
	responsive,
	size
}: Pick<LinkButtonProps, 'responsive' | 'size'>): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.MINIMAL}
			link={RouteUrls.buildUrl(RouteUrls.Key.LOGOUT)}
			replace={true}
			responsive={responsive}
			size={size}
			text={Strings.Buttons.LOG_OUT}
			classNames="logoutButton"
		/>
	);
}

export default LinkToLogoutButton;
