import * as React from 'react';
import { css, cx } from 'emotion';
import Draggable, { ControlPosition } from 'react-draggable';

import { CloseIconButton } from 'components/Buttons';
import { zIndexLayer, mediaQueries } from 'styles';

interface MinimizableModalProps {
	children: React.ReactNode;
	/**
	 * The id of an element whose pointer events are disabled
	 * while the recorder is being dragged. This ensures that
	 * the drag's events are not swallowed.
	 */
	disabledElementId: string;
	isModalMinimized: boolean;
	onClose?: () => void;
}

const styles = {
	closeButton: css`
		position: relative;
		& > * {
			position: absolute;
			top: 0.5em;
			right: 0.5em;
			z-index: ${zIndexLayer.GLOBAL};
		}
	`,
	dimmer: css`
		height: 100%;
		width: 100%;
		position: fixed;
		background-color: rgba(0, 0, 0, 0.8);
		top: 0;
		left: 0;
		z-index: ${zIndexLayer.FIXED};
	`,
	modal: css`
		z-index: ${zIndexLayer.FIXED};
		display: block;
		position: fixed;
	`,
	modalContent: css`
		max-height: 90vh;
		position: relative;
	`,
	modalMaximized: css`
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%) !important;
		padding: 0;
		${mediaQueries.desktop} {
			padding: 5em;
		}
		width: 100%;
		overflow: auto;
		-webkit-overflow-scrolling: touch;
	`,
	modalMinimized: css`
		cursor: move;
	`
};

function getDefaultDragPosition(): ControlPosition {
	return {
		x: window.innerWidth / 2,
		y: window.innerHeight / 2
	};
}

function getElementToDisable(elementId: string): HTMLElement | null {
	return document.getElementById(elementId);
}

function CloseButton({ onClose }: Pick<MinimizableModalProps, 'onClose'>): JSX.Element {
	return (
		<div className={styles.closeButton}>
			<CloseIconButton onClick={onClose} />
		</div>
	);
}

function getModalClassName(isModalMinimized: boolean): string {
	const classNames = [styles.modal];

	if (isModalMinimized) {
		classNames.push(styles.modalMinimized);
	} else {
		classNames.push(styles.modalMaximized);
	}

	return cx(...classNames);
}

function MinimizableModal({
	children,
	disabledElementId,
	isModalMinimized,
	onClose
}: MinimizableModalProps): JSX.Element {
	const defaultDragPosition = isModalMinimized ? undefined : getDefaultDragPosition();
	const modalClassName = getModalClassName(isModalMinimized);

	function disableElementPointerEvents(): void {
		const elem = getElementToDisable(disabledElementId);

		if (elem) {
			elem.style.pointerEvents = 'none';
		}
	}

	function enableElementPointerEvents(): void {
		const elem = getElementToDisable(disabledElementId);

		if (elem) {
			elem.style.pointerEvents = 'all';
		}
	}

	React.useLayoutEffect((): (() => void) => {
		const body = document.querySelector('body');

		if (body) {
			// Locks the underlying page scrolling
			body.style.overflow = isModalMinimized ? 'unset' : 'hidden';
		}

		return (): void => {
			if (body) {
				body.style.overflow = 'unset';
			}
		};
	}, [isModalMinimized]);

	return (
		<React.Fragment>
			{!isModalMinimized && <div className={styles.dimmer} />}
			<Draggable
				bounds="body"
				defaultPosition={defaultDragPosition}
				disabled={!isModalMinimized}
				onStart={disableElementPointerEvents}
				onStop={enableElementPointerEvents}>
				<div className={modalClassName}>
					<div className={styles.modalContent}>
						{onClose && <CloseButton onClose={onClose} />}
						{children}
					</div>
				</div>
			</Draggable>
		</React.Fragment>
	);
}

export default MinimizableModal;
