import { objectCamelToSnake } from 'lib/objectConverter';
import { getCommonClient } from 'lib/ThriftClient';
import { ReportEventRequest, Event as ThriftEvent } from 'thriftgen/api_types.js';
import { ReportEventRequestCamel, EventCamel } from 'thriftgen/thriftCamelTypes';

type Event = Omit<EventCamel, 'action'> & { action?: Record<string, unknown> };
type ReportEventParams = Omit<ReportEventRequestCamel, 'event'> & { event: Event };

async function reportEvent(params: ReportEventParams): Promise<ReportEventResponse> {
	const client = getCommonClient();
	const { event, objectId, objectType } = params;
	const { action = {}, eventType } = event;

	const request = new ReportEventRequest({
		event: new ThriftEvent({
			event_type: eventType,
			action: JSON.stringify(objectCamelToSnake(action))
		}),
		object_id: objectId,
		object_type: objectType
	});

	return client.report_event(request);
}

export { reportEvent, ReportEventParams };
