import * as React from 'react';

export interface UploadContextValue {
	// TODO: Replace type when uploader lib is written in TS
	uploader: PendingAny;
}

const UploadContext = React.createContext<UploadContextValue>({ uploader: undefined });

export default UploadContext;
