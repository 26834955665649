import { athenaGetPatientDocumentList } from 'api/athena';
import useQuery from 'swr';
import {
	AthenaDocumentCamel,
	AthenaGetPatientDocumentListRequestCamel,
	AthenaGetPatientDocumentListResponseCamel
} from 'thriftgen/thriftCamelTypes';

interface AthenaGetPatientDocumentListQuery {
	documents: AthenaDocumentCamel[] | undefined;
	error: Error | undefined;
	revalidate: () => Promise<void>;
}

function useAthenaGetPatientDocumentListQuery(
	props: AthenaGetPatientDocumentListRequestCamel
): AthenaGetPatientDocumentListQuery {
	const keyValues = Object.entries(props).map(item => item.join('/'));
	const key = `athenaGetPatientDocumentList/${keyValues}`;

	const { data, error, mutate } = useQuery<AthenaGetPatientDocumentListResponseCamel>(key, () => {
		const response = athenaGetPatientDocumentList(props);
		return response;
	});

	async function revalidate(): Promise<void> {
		await mutate();
	}

	return {
		documents: data ? data.documents : undefined,
		error,
		revalidate
	};
}

export default useAthenaGetPatientDocumentListQuery;
export { AthenaGetPatientDocumentListQuery };
