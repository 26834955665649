import * as React from 'react';

import buildCheckboxField from './base/buildCheckboxField';
import { DataFormFieldComponent, DataFormFieldComponentProps } from '../DataFormTypes';

const FIELD_NAME = 'createConsultsSitka';
const LABEL = 'Create vConsults in Sitka';

const CreateConsultsSitkaElement: DataFormFieldComponent = buildCheckboxField({
	fieldName: FIELD_NAME,
	label: LABEL
});

function CreateConsultsSitka(props: DataFormFieldComponentProps): JSX.Element {
	return <CreateConsultsSitkaElement key={FIELD_NAME} {...props} />;
}

CreateConsultsSitka.FIELD_NAME = FIELD_NAME;
CreateConsultsSitka.validate = () => null;

export default CreateConsultsSitka;
