import { updateClinicalReview } from 'api/common';
import { SubmitButton } from 'components/Buttons';
import { useConsultContext } from 'contexts';
import React, { useMemo, useState } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RouteUrls from 'routing/RouteUrls';
import { ClinicalReviewAnswerType, ClinicalReviewYesNoAnswer } from 'thriftgen/api_types';
import { ClinicalReviewCamel, UpdateClinicalReviewRequestCamel } from 'thriftgen/thriftCamelTypes';
import { DataFormFieldComponent } from './DataFormTypes';
import DataFormWrapper from './DataFormWrapper';
import { CorrectiveActionNeeded, CorrectiveActionText } from './Fields';
import buildRadioFieldSet from './Fields/base/buildRadioFieldSet';
import { getFieldsAndSubfieldNames } from './Fields/helpers';

interface ClinicalReviewFormData {
	correctiveActionNeeded: string | null;
	correctiveActionText: string;
}

const FIELDS: DataFormFieldComponent[] = [CorrectiveActionNeeded, CorrectiveActionText];

const INITIAL_DATA: ClinicalReviewFormData = {
	correctiveActionNeeded: '',
	correctiveActionText: ''
};

interface ConsumerProps {
	clinicalReview: ClinicalReviewCamel;
}

type ClinicalReviewFormProps = ConsumerProps & RouteComponentProps;

function ClinicalReviewForm({ clinicalReview, history }: ClinicalReviewFormProps): JSX.Element {
	const { consult } = useConsultContext();
	const [formData, setFormData] = useState<ClinicalReviewFormData>();

	const fields: DataFormFieldComponent[] = useMemo(
		() => [
			...clinicalReview.questions.map(question => {
				const { questionId, questionText, answerType, scaleAnswerOptions } = question;

				return buildRadioFieldSet({
					fieldName: `${questionId}`,
					label: questionText || '',
					options: answerType ? getRadioOptions(answerType, scaleAnswerOptions) : [],
					isInline: answerType !== ClinicalReviewAnswerType.SCALE
				});
			}),
			...FIELDS
		],
		[clinicalReview]
	);

	const isEditable: boolean = useMemo(() => {
		return (
			!clinicalReview.questions.some(question => !!question.answer) &&
			(clinicalReview.correctiveActionNeeded ?? null) === null
		);
	}, [clinicalReview]);

	const editableFields: string[] = useMemo(() => {
		return isEditable ? getFieldsAndSubfieldNames(fields) : [];
	}, [fields, isEditable]);

	const requiredFields: string[] = useMemo(() => {
		if (formData?.correctiveActionNeeded === 'true') {
			return editableFields;
		} else {
			return editableFields.filter(field => field !== CorrectiveActionText.FIELD_NAME);
		}
	}, [editableFields, formData?.correctiveActionNeeded]);

	const initialData: ClinicalReviewFormData = useMemo(() => {
		if (isEditable) {
			return INITIAL_DATA;
		} else {
			const data: ClinicalReviewFormData = {
				correctiveActionNeeded: clinicalReview.correctiveActionNeeded ? 'true' : 'false',
				correctiveActionText: clinicalReview.correctiveActionText || ''
			};
			clinicalReview.questions
				.filter(({ answer }) => answer)
				.forEach(({ questionId, answer }) => {
					data[questionId] = answer;
				});
			return data;
		}
	}, [isEditable, clinicalReview]);

	function handleChange(data: ClinicalReviewFormData) {
		setFormData(data);
	}

	async function handleSubmit(data: ClinicalReviewFormData) {
		const { correctiveActionNeeded, correctiveActionText, ...questions } = data;

		const updateClinicalReviewRequestCamel: UpdateClinicalReviewRequestCamel = {
			review: {
				consultId: consult.consult_id,
				correctiveActionNeeded: correctiveActionNeeded === 'true',
				correctiveActionText,
				questions: Object.keys(questions).map(questionId => ({
					questionId: +questionId,
					answer: questions[questionId]
				}))
			}
		};
		await updateClinicalReview(updateClinicalReviewRequestCamel);

		history.push(RouteUrls.buildUrl(RouteUrls.Key.DASHBOARD_CLINICAL));
	}

	function getRadioOptions(
		answerType: ClinicalReviewAnswerType,
		options?: ClinicalReviewAnswerOption[]
	): Array<[ClinicalReviewYesNoAnswer | string, string]> {
		if (
			answerType === ClinicalReviewAnswerType.YES_NO ||
			answerType === ClinicalReviewAnswerType.YES_NO_NA
		) {
			const options: Array<[ClinicalReviewYesNoAnswer, string]> = [
				[ClinicalReviewYesNoAnswer.YES, 'Yes'],
				[ClinicalReviewYesNoAnswer.NO, 'No']
			];
			if (answerType === ClinicalReviewAnswerType.YES_NO_NA) {
				options.push([ClinicalReviewYesNoAnswer.NA, 'N/A']);
			}
			return options;
		} else if (answerType === ClinicalReviewAnswerType.SCALE && options) {
			return options.map(option => [option.value, option.description]);
		}

		return [];
	}

	return (
		<DataFormWrapper
			data={initialData}
			editableFields={editableFields}
			fields={fields}
			requiredFields={requiredFields}
			SubmitControl={SubmitButton}
			onSubmit={handleSubmit}
			onChange={handleChange}
		/>
	);
}

export default withRouter(ClinicalReviewForm);
