import { selectLoggedIn, selectAuthUser } from 'store/auth';
import { AppState } from 'store/types';
import { isUserAdmin, isUserMember, isUserPatient } from 'lib/userHelpers';
import { RouteRestriction } from './types';

function and(...clauses: RouteRestriction[]): RouteRestriction {
	return (state: AppState) => clauses.every((clause: RouteRestriction): boolean => clause(state));
}

function isAdmin(state: AppState): boolean {
	if (!isLoggedIn(state)) {
		return false;
	}

	const actor = selectAuthUser(state);

	return isUserAdmin(actor);
}

function isAnyAdmin(state: AppState): boolean {
	return or(isAdmin, isSitkaOperations)(state);
}

function isNotPatient(state: AppState): boolean {
	return or(isPartnerStaff, isSitkaMedicalAssociate, isSitkaOperations)(state);
}

function isLoggedIn(state: AppState): boolean {
	return selectLoggedIn(state);
}

function isPartnerStaff(state: AppState): boolean {
	return or(isAdmin, isMember)(state);
}

function isPatient(state: AppState): boolean {
	if (!isLoggedIn(state)) {
		return false;
	}

	const actor = selectAuthUser(state);

	return isUserPatient(actor);
}

function isMember(state: AppState): boolean {
	if (!isLoggedIn(state)) {
		return false;
	}

	const actor = selectAuthUser(state);

	return isUserMember(actor);
}

function isSitkaMedicalAssociate(state: AppState): boolean {
	if (!isLoggedIn(state)) {
		return false;
	}

	const actor = selectAuthUser(state);

	return Boolean(actor.is_sitka_medical_associate);
}

function isSitkaOperations(state: AppState): boolean {
	if (!isLoggedIn(state)) {
		return false;
	}

	const actor = selectAuthUser(state);

	return Boolean(actor.is_sitka_operations);
}

function or(...clauses: RouteRestriction[]): RouteRestriction {
	return (state: AppState) => clauses.some((clause: RouteRestriction): boolean => clause(state));
}

export {
	and,
	isAdmin,
	isAnyAdmin,
	isLoggedIn,
	isMember,
	isNotPatient,
	isPartnerStaff,
	isPatient,
	isSitkaMedicalAssociate,
	isSitkaOperations,
	or
};
