import React, { useState } from 'react';
import { Avatar, Link, styled } from '@mui/material';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { css } from 'emotion';
import { borderRadius, colors, shadows } from 'styles';
import { getFirstNameLastName } from 'lib/userHelpers';
import { LogEventName, SitkaLogger } from 'lib/sitkaLogger';
import { Feature, isFeatureEnabled } from 'lib/feature';
import SMAUserBioInfoPopupContent from 'components/User/SMAUserBioInfoPopupContent';

interface UserNameAndAvatarProps {
	user: User;
	displayNameFunc: (user: User) => string;
}

const styles = {
	lists: css`
		margin: 0;
	`,
	wrapper: css`
		display: flex;
		align-items: center;

		& > div:first-child {
			margin-right: 8px;
		}
	`
};

function UserNameAndAvatar({ user, displayNameFunc }: UserNameAndAvatarProps): JSX.Element {
	const [open, setOpen] = useState(false);

	const displayName = displayNameFunc(user);
	const specialistBioInfo = user.specialist_data?.specialist_bio_info;

	const HtmlTooltip = styled(({ className, ...props }: TooltipProps) => (
		<Tooltip {...props} classes={{ popper: className }} />
	))(({ theme }) => ({
		[`& .${tooltipClasses.tooltip}`]: {
			display: 'flex',
			flexDirection: 'row',
			backgroundColor: '#fff',
			padding: '1em',
			color: 'rgba(0, 0, 0, 0.87)',
			maxWidth: '600px',
			maxHeight: '400px',
			fontSize: theme.typography.pxToRem(14),
			boxShadow: shadows.l1,
			borderRadius: borderRadius.l2
		}
	}));

	function handleClose() {
		SitkaLogger.logMessage('SMA Bio popup closed', LogEventName.SMA_BIO);
		setOpen(false);
	}

	function handleOpen() {
		SitkaLogger.logMessage('SMA Bio popup opened', LogEventName.SMA_BIO);
		setOpen(true);
	}

	// this function comes from the Material UI documentation examples for the Avatar component
	function stringAvatar(name: string) {
		return {
			sx: {
				bgcolor: colors.primary[500]
			},
			children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`
		};
	}

	const body = (
		<SMAUserBioInfoPopupContent
			displayName={displayName}
			user={user}
			specialistBioInfo={specialistBioInfo}
		/>
	);

	return (
		<>
			{user &&
				(isFeatureEnabled(Feature.CWA_1567_SMA_BIO_POPUP) &&
				user.is_sitka_medical_associate &&
				specialistBioInfo &&
				Object.keys(specialistBioInfo).length > 0 ? (
					<div className={styles.wrapper}>
						<Avatar
							alt={displayName}
							src={user.specialist_data?.profile_pic_url}
							{...stringAvatar(getFirstNameLastName(user))}
						/>
						<HtmlTooltip
							title={body}
							placement="top"
							open={open}
							onOpen={handleOpen}
							onClose={handleClose}
							data-testid={'nameTooltip'}>
							<Link component="button">{displayName}</Link>
						</HtmlTooltip>
					</div>
				) : (
					<div className={styles.wrapper}>
						<Avatar {...stringAvatar(getFirstNameLastName(user))} />
						<div>{displayName}</div>
					</div>
				))}
		</>
	);
}

export default UserNameAndAvatar;
