import { Sort } from 'thriftgen/api_types';
import { NonNullableFilterValue } from 'components/Filters';

enum ParameterActionType {
	CLEAR_FILTERS = 'clearFilters',
	REMOVE_FILTER = 'removeFilter',
	REMOVE_FILTER_VALUE = 'removeFilterValue',
	SET_FILTER = 'setFilter',
	SET_ALL_FILTERS = 'setAllFilters',
	SET_PAGE_CURSOR = 'setPageCursor',
	SET_PAGE_SIZE = 'setPageSize',
	SET_SORTS = 'setSorts'
}

enum MarkerActionType {
	SET_NEIGHBOR_PAGE_CURSORS = 'setNeighborPageCursors'
}

interface Filter {
	attribute: string;
	value: NonNullableFilterValue;
}

interface Parameters {
	sorts: Sort[];
	filters: Filter[];
	pageSize: number | undefined;
	pageCursor: string | null;
}

interface Markers {
	nextPageCursor: string | null;
	prevPageCursor: string | null;
}

interface RemoveFilter {
	type: ParameterActionType.REMOVE_FILTER;
	attribute: Filter['attribute'];
}

interface RemoveFilterValue {
	type: ParameterActionType.REMOVE_FILTER_VALUE;
	attribute: Filter['attribute'];
	value: Filter['value'];
}

interface ClearFilters {
	type: ParameterActionType.CLEAR_FILTERS;
}

interface SetFilter {
	type: ParameterActionType.SET_FILTER;
	filter: Filter;
}

interface SetAllFilters {
	type: ParameterActionType.SET_ALL_FILTERS;
	filters: Filter[];
}

interface SetPageCursor {
	type: ParameterActionType.SET_PAGE_CURSOR;
	pageCursor: Parameters['pageCursor'];
}

interface SetPageSize {
	type: ParameterActionType.SET_PAGE_SIZE;
	pageSize: Parameters['pageSize'];
}

interface SetNeighborPageCursors {
	type: MarkerActionType.SET_NEIGHBOR_PAGE_CURSORS;
	nextPageCursor: Markers['nextPageCursor'];
	prevPageCursor: Markers['prevPageCursor'];
}

interface SetSorts {
	type: ParameterActionType.SET_SORTS;
	sorts: Sort[];
}

type ParameterActions =
	| SetFilter
	| SetAllFilters
	| SetPageCursor
	| RemoveFilter
	| RemoveFilterValue
	| ClearFilters
	| SetPageSize
	| SetSorts;

type MarkerActions = SetNeighborPageCursors;

type MarkersReducer = (state: Markers, actions: MarkerActions) => Markers;

interface MarkerActionCreators {
	setNeighborPageCursors: (cursors: Markers) => SetNeighborPageCursors;
}

interface ParameterActionCreators {
	clearFilters: () => ClearFilters;
	removeFilter: (attribute: Filter['attribute']) => RemoveFilter;
	removeFilterValue: (
		attribute: Filter['attribute'],
		value: Filter['value']
	) => RemoveFilterValue;
	setFilter: (filter: Filter) => SetFilter;
	setAllFilters: (filters: Filter[]) => SetAllFilters;
	setPageSize: (pageSize: Parameters['pageSize']) => SetPageSize;
	setPageCursor: (pageCursor: Parameters['pageCursor']) => SetPageCursor;
	setSorts: (sorts: Sort[]) => SetSorts;
}

type ParametersReducer = (state: Parameters, actions: ParameterActions) => Parameters;

export {
	ClearFilters,
	Filter,
	MarkerActions,
	MarkerActionCreators,
	MarkerActionType,
	Markers,
	MarkersReducer,
	ParameterActions,
	ParameterActionCreators,
	ParameterActionType,
	Parameters,
	ParametersReducer,
	RemoveFilter,
	RemoveFilterValue,
	SetFilter,
	SetAllFilters,
	SetNeighborPageCursors,
	SetPageCursor,
	SetPageSize,
	SetSorts
};
