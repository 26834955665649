import * as React from 'react';
import { css } from 'emotion';

import { Paragraph } from 'display';
import { colors } from 'styles';

const styles = {
	container: css`
		color: ${colors.problem.base};
	`
};

function SharingStatusErrorMessage(): JSX.Element {
	return (
		<div className={styles.container}>
			<Paragraph size={Paragraph.Size.T4}>Unable to retrieve sharing status</Paragraph>
		</div>
	);
}

export default SharingStatusErrorMessage;
