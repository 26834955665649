import { useUnleashClient, useFlagsStatus, useFlag } from '@unleash/proxy-client-react';
import { Feature, setFeatureEnabled } from 'lib/feature';
import * as React from 'react';
import { connect } from 'react-redux';
import { selectAuthUser, selectHasAuthUser } from 'store/auth';
import { AppState } from 'store/types';

interface StoreProps {
	actor: User | null;
}

function UnleashConsumer({ actor }: StoreProps) {
	const client = useUnleashClient();
	const { flagsReady } = useFlagsStatus();

	const isEnabledClinicalReview = useFlag(Feature.CWA_597_CLINICAL_REVIEW);
	const isEnabledDictation = useFlag(Feature.CWA_1051_IMPLEMENT_DICTATION_SPEECH_TO_TEXT);
	const isEnabledDictationLogging = useFlag(Feature.CWA_1051_ENABLE_DICTATION_LOGGING);
	const isMimeTypeCheckOverridden = useFlag(
		Feature.CWA_1098_OVERRIDE_FILE_UPLOAD_MIME_TYPE_CHECK
	);
	const isEnabledAwsSdkUpgrade = useFlag(Feature.CWA_1384_AWS_SDK_UPGRADE);
	const isEnabledSMALicenseTab = useFlag(Feature.CWA_1378_SMA_LICENSES);
	const isEnabledSMASchedule = useFlag(Feature.CWA_1355_SMA_SCHEDULE);
	const isEnabledAthenaAttachmentHelp = useFlag(Feature.CWA_1537_ATHENA_ATTACHMENT_HELP);
	const isEnabledSMABioFields = useFlag(Feature.CWA_1567_SMA_BIO_POPUP);
	const isEnabledTeamsAdministration = useFlag(
		Feature.CWA_1700_ADD_TEAMS_ADMIN_TABLE_TO_PARTNER_DETAILS
	);

	React.useEffect(() => {
		if (actor) {
			//Update the Unleash context to specify to the user id for user id based feature flag
			client.updateContext({ userId: actor.user_id });
		}
		if (flagsReady) {
			// TODO: Look into enabling this code in the future
			// const toggles = client.getAllToggles();
			// Object.keys(Feature).forEach(featureKey => {
			// 	const isFeatureEnabled = !!toggles.find(({ name }) => name === featureKey)?.enabled;
			// 	setFeatureEnabled(Feature[featureKey], isFeatureEnabled);

			// });

			setFeatureEnabled(Feature.CWA_597_CLINICAL_REVIEW, isEnabledClinicalReview);
			setFeatureEnabled(
				Feature.CWA_1051_IMPLEMENT_DICTATION_SPEECH_TO_TEXT,
				isEnabledDictation
			);
			setFeatureEnabled(Feature.CWA_1051_ENABLE_DICTATION_LOGGING, isEnabledDictationLogging);
			setFeatureEnabled(
				Feature.CWA_1098_OVERRIDE_FILE_UPLOAD_MIME_TYPE_CHECK,
				isMimeTypeCheckOverridden
			);
			setFeatureEnabled(Feature.CWA_1384_AWS_SDK_UPGRADE, isEnabledAwsSdkUpgrade);
			setFeatureEnabled(Feature.CWA_1378_SMA_LICENSES, isEnabledSMALicenseTab);
			setFeatureEnabled(Feature.CWA_1355_SMA_SCHEDULE, isEnabledSMASchedule);
			setFeatureEnabled(
				Feature.CWA_1537_ATHENA_ATTACHMENT_HELP,
				isEnabledAthenaAttachmentHelp
			);
			setFeatureEnabled(Feature.CWA_1567_SMA_BIO_POPUP, isEnabledSMABioFields);
			setFeatureEnabled(
				Feature.CWA_1700_ADD_TEAMS_ADMIN_TABLE_TO_PARTNER_DETAILS,
				isEnabledTeamsAdministration
			);
		}
	}, [
		actor,
		client,
		flagsReady,
		isEnabledAthenaAttachmentHelp,
		isEnabledAwsSdkUpgrade,
		isEnabledClinicalReview,
		isEnabledDictation,
		isEnabledDictationLogging,
		isEnabledSMALicenseTab,
		isEnabledSMASchedule,
		isEnabledSMABioFields,
		isEnabledTeamsAdministration,
		isMimeTypeCheckOverridden
	]);

	return null;
}

const mapStoreToProps = (state: AppState): StoreProps => ({
	actor: selectHasAuthUser(state) ? selectAuthUser(state) : null
});

export default connect(mapStoreToProps)(UnleashConsumer);
