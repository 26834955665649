import {
	SpecialistBioInfoCamel,
	SpecialistDataUpdatesCamel,
	UserInfoCamel
} from 'thriftgen/thriftCamelTypes';

type UserData = UserInfoCamel & SpecialistDataUpdatesCamel & SpecialistBioInfoCamel;

function rebuildUserObject(data: UserData): UserData {
	if (data.profilePicUploadKey) {
		data.specialistData = {
			profilePicUploadKey: data.profilePicUploadKey
		};

		delete data.profilePicUploadKey;
	}

	if (
		'medSchool' in data ||
		'residency' in data ||
		'boardCertification' in data ||
		'fellowship' in data ||
		'specialty' in data ||
		'funFacts' in data
	) {
		if (!data.specialistData) {
			data.specialistData = {};
		}

		const result: SpecialistBioInfoCamel = {};

		Object.keys(data).forEach(key => {
			switch (key) {
				case 'medSchool':
				case 'residency':
				case 'fellowship':
				case 'boardCertification':
				case 'specialty':
				case 'funFacts':
					result[key] = data[key];
					delete data[key];
			}
		});

		data.specialistData = {
			...data.specialistData,
			specialistBioInfo: result
		};
	}

	return data;
}

export { rebuildUserObject, UserData };
