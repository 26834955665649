import * as React from 'react';

import { Message, Paragraph } from 'display';

function CallToAction(): JSX.Element {
	return (
		<Paragraph>
			Email us at <a href="mailto:support@trustsitka.com">support@trustsitka.com</a> and
			we&apos;ll resolve the issue.
		</Paragraph>
	);
}

function AuthenticationError({
	message,
	showCallToAction = false
}: {
	message: string;
	showCallToAction?: boolean;
}): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>Authentication Error</Message.Header>
			<Paragraph>{message}</Paragraph>
			{showCallToAction && <CallToAction />}
		</Message>
	);
}

export default AuthenticationError;
