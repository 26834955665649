import * as React from 'react';
import { PageDisplayOptions } from 'routing/types';

import Page from 'components/Page/Page';
import getNavItems from 'components/Navbar/helpers';

interface AppPageProps {
	authenticated?: boolean;
	children: React.ReactNode;
	pageOptions?: PageDisplayOptions;
}

function AppPage({ authenticated = false, children, pageOptions = {} }: AppPageProps): JSX.Element {
	return (
		<Page pageOptions={pageOptions} menuItems={authenticated ? getNavItems() : undefined}>
			{children}
		</Page>
	);
}

export default AppPage;
