import * as React from 'react';

import { Message, Paragraph } from 'display';

function ConsultDataNotFound(): JSX.Element {
	return (
		<Message negative={true}>
			<Message.Header>The requested data was not found</Message.Header>
			<Paragraph>
				Please try again or contact support at{' '}
				<a href="mailto:support@trustsitka.com">support@trustsitka.com</a> if you need help.
			</Paragraph>
		</Message>
	);
}

export default ConsultDataNotFound;
