import * as React from 'react';
import { css } from 'emotion';

import { Segment } from 'display';
import { borderRadius } from 'styles';

const styles = {
	actions: css`
		max-width: 640px;
		margin-left: auto;
		margin-right: auto;
	`,
	wrapper: css`
		position: relative;
	`,
	top: css`
		video {
			border-top-right-radius: ${borderRadius.l1} !important;
			border-top-left-radius: ${borderRadius.l1} !important;
			border-bottom-left-radius: 0px !important;
			border-bottom-right-radius: 0px !important;
		}
	`,
	bottom: css`
		border-top-right-radius: 0px !important;
		border-top-left-radius: 0px !important;
		border-bottom-left-radius: ${borderRadius.l1} !important;
		border-bottom-right-radius: ${borderRadius.l1} !important;
	`
};

interface VideoWithActionsProps {
	children: React.ReactNode;
}

function VideoWithActions({ children }: VideoWithActionsProps): JSX.Element {
	return <div className={styles.wrapper}>{children}</div>;
}

function Actions({ children }: VideoWithActionsProps): JSX.Element {
	return (
		<Segment className={styles.bottom} inverted={true} noMargins={true}>
			<div className={styles.actions}>{children}</div>
		</Segment>
	);
}

function Video({ children }: VideoWithActionsProps): JSX.Element {
	return <div className={styles.top}>{children}</div>;
}

VideoWithActions.Actions = Actions;
VideoWithActions.Video = Video;

export default VideoWithActions;
