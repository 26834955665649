import { FilterField } from 'display';
import { translateThriftEnum } from 'lib/i18n';
import { ConsultStateSimplified, CONSULT_STATE_SIMPLIFIED } from 'thriftgen/api_types';

export default (): FilterField => {
	const stateFilter: FilterField = {
		label: 'Status',
		attribute: 'simplified_state',
		values: []
	};

	Object.keys(ConsultStateSimplified).forEach(consultStateSimplified => {
		stateFilter.values.push({
			label: translateThriftEnum(
				ConsultStateSimplified[consultStateSimplified],
				CONSULT_STATE_SIMPLIFIED
			),
			value: ConsultStateSimplified[consultStateSimplified]
		});
	});

	stateFilter.values.sort((a, b) => {
		return a.label < b.label ? -1 : 1;
	});

	return stateFilter;
};
