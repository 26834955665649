import * as React from 'react';
import { SUPPORT_EMAIL } from 'lib/supportContacts';

import { Paragraph } from 'display';

function RequestNotSupportedMessage(): JSX.Element {
	return (
		<Paragraph>
			Your organization does not support this request. Please refer back to your training
			materials for how to use Sitka. Contact{' '}
			<a href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</a> if you have any questions.
		</Paragraph>
	);
}

export default RequestNotSupportedMessage;
