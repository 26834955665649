import * as React from 'react';

import { Button } from 'display';
import Strings from 'lib/strings';

function ResumeRecordingButton({ onClick }: { onClick: React.MouseEventHandler }): JSX.Element {
	return (
		<Button.Primary className="resumeRecordingButton" onClick={onClick}>
			{Strings.Buttons.RESUME}
		</Button.Primary>
	);
}

export default ResumeRecordingButton;
