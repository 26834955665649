import * as React from 'react';

import LinkButton, { LinkButtonProps } from '../LinkButton';
import { Button, ButtonType } from 'display';
import Strings from 'lib/strings';

function LinkToEditPageButton(props: Omit<LinkButtonProps, 'text' | 'buttonType'>): JSX.Element {
	return (
		<LinkButton
			buttonType={ButtonType.PRIMARY}
			text={Strings.Buttons.EDIT}
			size={Button.Size.TINY}
			{...props}
		/>
	);
}

export default LinkToEditPageButton;
