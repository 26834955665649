function isObject(obj: unknown): obj is Record<string, unknown> {
	if (!obj) {
		return false;
	}

	return !Array.isArray(obj) && typeof obj === 'object';
}

function isPromise(result: unknown | Promise<unknown>): result is Promise<unknown> {
	return isObject(result) && typeof result.then === 'function';
}

export default isObject;

export { isPromise };
