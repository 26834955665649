import { AppState } from 'store/types';
import * as ACTION_STATUS from 'store/actionStatus';
import { createSelector } from 'reselect';
import { Request, RequestsState } from './requests.types';

export const selectRequests = (state: AppState<{ requests: RequestsState }>): Request[] =>
	state.requests || [];
export const selectToastRequests = createSelector(
	selectRequests,
	(requests: Request[]): Request[] => {
		return requests.filter(
			({ toast, status, message }) =>
				toast &&
				message &&
				status !== ACTION_STATUS.INACTIVE &&
				status !== ACTION_STATUS.IN_PROGRESS
		);
	}
);

export const selectActiveRequests = createSelector(
	selectRequests,
	(requests: Request[]): Request[] => {
		return requests.filter(({ status }) => status !== ACTION_STATUS.INACTIVE);
	}
);
