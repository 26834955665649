import { sendVideoVisitLink } from 'api/common';
import { CancelButton, SendButton } from 'components/Buttons';
import { SendLinkErrorMessage } from 'components/Messages';
import { ButtonType } from 'display';
import * as React from 'react';
import { SendVideoVisitLinkRequestCamel } from 'thriftgen/thriftCamelTypes';
import DataFormWrapper from './DataFormWrapper';
import { UserContact } from './Fields';
import { getFieldsAndSubfieldNames } from './Fields/helpers';

interface SendVideoVisitLinkFormProps {
	link: string;
	onDone: () => void;
}

const FIELDS = [UserContact];
const EDITABLE_FIELDS = getFieldsAndSubfieldNames(FIELDS);

function SendVideoVisitLinkForm({ link, onDone }: SendVideoVisitLinkFormProps): JSX.Element {
	const [apiError, setApiError] = React.useState<Error | null>(null);

	function onSubmit(
		data: Pick<SendVideoVisitLinkRequestCamel, 'phoneNumber' | 'email'>
	): Promise<void> {
		setApiError(null);

		return sendVideoVisitLink({
			link,
			...data
		})
			.then(onDone)
			.catch(setApiError);
	}

	function onCancel(): void {
		setApiError(null);
		onDone();
	}

	function CancelControl(): JSX.Element {
		return <CancelButton buttonType={ButtonType.SECONDARY} onClick={onCancel} />;
	}

	return (
		<React.Fragment>
			{apiError && <SendLinkErrorMessage message={apiError.message} />}
			<DataFormWrapper
				editableFields={EDITABLE_FIELDS}
				fields={FIELDS}
				onSubmit={onSubmit}
				CancelControl={CancelControl}
				SubmitControl={SendButton}
				resetOnSubmit={true}
			/>
		</React.Fragment>
	);
}

export default SendVideoVisitLinkForm;
