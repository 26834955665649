import { getAuthClient } from 'lib/ThriftClient';
import {
	PatientVerificationPayload,
	RedeemTokenPayload,
	RedeemTokenRequest,
	RedeemTokenResponse
} from 'thriftgen/api_types';

interface Token {
	token_id: string;
}

type Payload = {
	patient_verification: PatientVerificationPayload;
};

function redeemToken(token: Token, payload?: Payload): Promise<RedeemTokenResponse> {
	const client = getAuthClient();
	const request = new RedeemTokenRequest({
		token_id: token.token_id,
		payload: payload ? new RedeemTokenPayload(payload) : undefined
	});

	return client.redeem_token(request);
}

export default redeemToken;
export { Token, Payload };
